import React, { useState, useEffect } from 'react';
import BemHelper from '@flowio/bem-helper';

if (process.browser) {
  require('./countdown-timer.css'); // eslint-disable-line
}

const bem = new BemHelper('countdown-timer');

function formatValues(remainingTime: number): string {
  const seconds = (remainingTime / 1000) % 60;
  const minutes = ((remainingTime / 1000) - seconds) / 60;

  return `${(minutes < 10 ? `0${minutes}` : minutes)}:${(seconds < 10 ? `0${seconds}` : seconds)}`;
}

interface Props {
  timeout: number;
  onTimeout: () => void;
}

const Timer: React.FunctionComponent<Props> = (props: Props) => {
  const { timeout, onTimeout } = props;

  const [remainingTime, setRemainingTime] = useState(timeout);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((time) => time - 1000);
    }, 1000);

    if (remainingTime <= 0) {
      clearInterval(interval);
      setTimeout(() => onTimeout(), 1000);
    }
    return (): void => clearInterval(interval);
  }, [remainingTime]);

  return (
    <time className={bem.element('timer')}>{formatValues(remainingTime)}</time>
  );
};

export default Timer;
