import assign from 'lodash/assign';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

const defaultHttpStatusMessages = {
  401: 'You are not authorized to perform this action at this time.',
  404: 'We could not find the resource you requested.',
  500: 'A server error has occurred, please try again later.',
};

const normalizeMessages = (messages) => (isArray(messages) ? messages : [messages]);

/**
 * An utility that maps a non 2XX response from our Node SDK into an object suitable for the
 * application. It simply ensures that a `messages` property is available when one cannot be
 * derived from the response for display purposes.
 * @param {Object} response A response object from Node SDK
 * @param {Object} [options.messages] An object literal used to override default messages based on
 * status code.
 * @return {Object}
 */
const formatErrorResponse = (response, options = {}) => {
  const { status } = response;
  const httpStatusMessages = { ...defaultHttpStatusMessages, ...options.messages };

  let messages = [];

  // Use message matching HTTP status code.
  if (httpStatusMessages[status]) {
    messages = [httpStatusMessages[status]];
  // Use response result from Node SDK. Flow API only provides messages for 422 responses and Node
  // SDK adds a message to 5XX responses, we will honor both.
  } else if (response.result) {
    if (response.result.messages) {
      messages = normalizeMessages(response.result.messages);
    // This is the message format from Hapi server errors:
    // {
    //  error, // string
    //  message, // string
    //  statusCode, // number
    // }
    } else if (!isEmpty(response.result.message)) {
      messages = normalizeMessages(response.result.message);
    } else if (!isEmpty(response.result.errors) && !isEmpty(response.result.errors[0])) {
      // This is a hack for bundle order submission. When there is an error, this shape is returned:
      // {
      //   errors, // array[Object]
      // }
      // Where errors will be the generic_error model shape: { code, messages }
      messages = normalizeMessages(response.result.errors[0].messages);
    } else {
      messages = normalizeMessages(response.result);
    }
  }

  // This normalizes the shape to something more expected by the application
  // Only applies to bundle order submission
  if (isPlainObject(response.result)
      && !isEmpty(response.result.errors)
      && !isEmpty(response.result.errors[0])) {
    return { status, result: assign({}, response.result.errors[0], { messages }) };
  }

  // An object that has the following shape:
  //  {
  //    status: String,
  //    result: {
  //      ...response.result
  //      code: String
  //      messages: String[],
  //    }
  // }
  if (isPlainObject(response.result)) {
    return { status, result: assign({}, response.result, { messages }) };
  }

  return { status, result: { messages } };
};

export default formatErrorResponse;
