import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isElementOfType } from '@flowio/react-helpers';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import noop from 'lodash/noop';

import Panel from './panel';

if (process.browser) {
  require('./panel-group.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('panel-group');

class PanelGroup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { activeKey, defaultActiveKey } = this.props;
    return {
      activeKey: activeKey || defaultActiveKey,
      controlled: !isNil(activeKey),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeKey: prevActiveKey } = this.props;
    const { activeKey: nextActiveKey } = nextProps;

    if (!isNil(nextActiveKey) && prevActiveKey !== nextActiveKey) {
      this.setState({ activeKey: nextActiveKey, controlled: true });
    }
  }

  handlePanelSelection = (event, eventKey) => {
    const { onChange } = this.props;
    const { controlled, activeKey } = this.state;

    if (!controlled) {
      // Currently active panel is being collapsed.
      // Reset active key since all panels should be collapsed.
      if (activeKey === eventKey) {
        this.setState({ activeKey: null });
      } else {
        this.setState({ activeKey: eventKey });
      }
    }

    onChange(event, eventKey);
  };

  renderChildren() {
    const { accordion, children } = this.props;
    const { activeKey } = this.state;

    return React.Children.map(children, (child, index) => {
      // Make sure child is a valid component.
      if (!React.isValidElement(child)) {
        return child;
      }

      if (!isElementOfType(child, Panel)) {
        return child;
      }

      const props = {
        key: child.key ? child.key : index,
        ref: child.ref,
      };

      // Control panels when component is meant to behave as an accordion.
      if (accordion) {
        props.collapsible = true;
        props.expanded = child.props.eventKey === activeKey;
        props.onSelect = this.handlePanelSelection;
      }

      return React.cloneElement(child, props);
    });
  }

  renderPanels() {
    const { accordion, panels } = this.props;
    const { activeKey } = this.state;

    return map(panels, ({ content, eventKey, title }, index) => (
      <Panel
        collapsible={accordion}
        content={content}
        eventKey={eventKey || index}
        expanded={(eventKey || index) === activeKey}
        key={eventKey || index}
        onSelect={this.handlePanelSelection}
        title={title}
      />
    ));
  }

  render() {
    const { children, className } = this.props;

    if (!isNil(children)) {
      return (
        <div className={bem.block(className)}>
          {this.renderChildren()}
        </div>
      );
    }

    return (
      <div className={bem.block(className)}>
        {this.renderPanels()}
      </div>
    );
  }
}

PanelGroup.Panel = Panel;

PanelGroup.displayName = 'PanelGroup';

PanelGroup.propTypes = {
  accordion: PropTypes.bool,
  activeKey: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  defaultActiveKey: PropTypes.string,
  onChange: PropTypes.func,
  panels: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    eventKey: PropTypes.string,
    title: PropTypes.string,
  })),
};

PanelGroup.defaultProps = {
  accordion: false,
  activeKey: undefined,
  className: '',
  children: undefined,
  defaultActiveKey: undefined,
  onChange: noop,
  panels: [],
};

export default PanelGroup;
