import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import ReadyState from '../../../utilities/redux/ready-state';
import type { AppReducer } from '../../types';
import type { ReferenceStatusState } from '../types';

const initialState: ReferenceStatusState = {
  countries: ReadyState.Pending,
};

const statusesReducer: AppReducer<ReferenceStatusState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRIES_REQUEST:
      return update(previousState, {
        countries: { $set: ReadyState.Loading },
      });
    case actionTypes.FETCH_COUNTRIES_FAILURE:
      return update(previousState, {
        countries: { $set: ReadyState.Rejected },
      });
    case actionTypes.FETCH_COUNTRIES_SUCCESS:
      return update(previousState, {
        countries: { $set: ReadyState.Fulfilled },
      });
    default:
      return previousState;
  }
};

export default statusesReducer;
