/* eslint-disable react/prefer-stateless-function */

import ApiPropTypes from '@flowio/api-prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import noop from 'lodash/noop';
import filter from 'lodash/filter';
import get from 'lodash/get';

import { COLLAPSED_ORDER_SUMMARY, COUNTDOWN_TIMER } from '../../../common/constants/feature-keys';
import CountdownTimer from '../countdown-timer';
import configurePixel from '../../utilities/pixel/configure-pixel';
import FeatureToggle from '../feature-toggle';
import Breadcrumb from '../../containers/breadcrumb';
import MobileOrderSummary from '../../containers/mobile-order-summary';
import OrderSummary from '../../containers/order-summary';
import Steps from '../../constants/steps';
import { isStepCompleted } from '../../utilities/step';
import PaymentTypes from '../../constants/payment-types';
import RestrictedItemsModal from '../../containers/restricted-items-modal';
import { setCatchpointRumOrganization } from '../../store/checkout/actions';
import MobileOrderSummaryV2 from '../mobile-order-summary-v2';

if (process.browser) {
  require('./checkout-layout.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('checkout-layout');

// To avoid initializing pixel multiple times when customers navigate into
// other pages that are not in the component tree below.
let didConfigurePixel = false;

class CheckoutLayout extends Component {
  componentDidMount() {
    const {
      checkoutConfiguration,
      triggerInitialized,
      isCatchpointRumEnabled,
      isLiquid,
      organization,
      canTriggerInitialized,
    } = this.props;

    if (!didConfigurePixel) {
      didConfigurePixel = true;
      configurePixel(isLiquid, checkoutConfiguration);
    }

    if (canTriggerInitialized()) {
      triggerInitialized();
    }

    if (isCatchpointRumEnabled) {
      setCatchpointRumOrganization(organization);
    }
  }

  render() {
    const {
      activeStep,
      children,
      forceEnableShopifyDiscounts,
      isDutyIncluded,
      isTaxIncluded,
      isLoyaltyEnabled,
      isDiscountsEnabled,
      isOrderSummaryVisible,
      itemContentsMap,
      isShopifyIntegration,
      isReviewStepEnabled,
      onRequestHideOrderSummary,
      onRequestShowOrderSummary,
      order,
      organization,
      isGiftCardsEnabled,
      isDeliveredDutyPaidMessageEnabled,
    } = this.props;
    const onRequestToggleOrderSummary = isOrderSummaryVisible
      ? onRequestHideOrderSummary
      : onRequestShowOrderSummary;

    const creditPayments = filter(get(order, 'payments', []), { type: PaymentTypes.Credit });

    const isConfirmationStep = isStepCompleted(activeStep, Steps.Confirmation);

    let promotionCode;

    if (order.attributes != null) {
      if (order.attributes.shopify_discount_code != null) {
        promotionCode = order.attributes.shopify_discount_code;
      } else if (order.attributes.discount_code_label != null) {
        promotionCode = order.attributes.discount_code_label;
      }
    }

    return (
      <FeatureToggle
        featureKey={COLLAPSED_ORDER_SUMMARY}
        render={({ isFeatureEnabled }) => (
          <div className={bem.block()}>
            {isFeatureEnabled ? (
              <MobileOrderSummaryV2 />
            ) : (
              <MobileOrderSummary
                creditPayments={creditPayments}
                forceEnableShopifyDiscounts={forceEnableShopifyDiscounts}
                order={order}
                onRequestToggleOrderSummary={onRequestToggleOrderSummary}
                orderNumber={order.number}
                organization={organization}
                isOrderSummaryVisible={isOrderSummaryVisible}
                itemContentsMap={itemContentsMap}
                isDiscountsEnabled={isDiscountsEnabled}
                isShopifyIntegration={isShopifyIntegration}
                isConfirmationStep={isConfirmationStep}
                isDutyIncluded={isDutyIncluded}
                isTaxIncluded={isTaxIncluded}
                isDeliveredDutyPaidMessageEnabled={isDeliveredDutyPaidMessageEnabled}
              />
            )}

            {!isConfirmationStep && (
              <div className={bem.element('breadcrumb-container')}>
                <Breadcrumb isReviewStepEnabled={isReviewStepEnabled} activeStep={activeStep} />
              </div>
            )}

            <div className={bem.element('checkout-content')}>
              <div className={bem.element('checkout-content-order-summary')}>
                <OrderSummary
                  creditPayments={creditPayments}
                  forceEnableShopifyDiscounts={forceEnableShopifyDiscounts}
                  isConfirmationStep={isConfirmationStep}
                  // Dirty hack should come from the type of discount
                  isDiscountClearable={!isConfirmationStep && !isLoyaltyEnabled}
                  isDutyIncluded={isDutyIncluded}
                  isTaxIncluded={isTaxIncluded}
                  itemContentsMap={itemContentsMap}
                  isShopifyIntegration={isShopifyIntegration}
                  isDiscountsEnabled={isDiscountsEnabled}
                  order={order}
                  orderNumber={order.number}
                  organization={organization}
                  prices={order.prices}
                  promotionCode={promotionCode}
                  total={isConfirmationStep && !isGiftCardsEnabled
                    ? order.total : order.balance}
                  isDeliveredDutyPaidMessageEnabled={isDeliveredDutyPaidMessageEnabled}
                />
              </div>
              <div className={bem.element('checkout-content-container')}>
                <FeatureToggle featureKey={COUNTDOWN_TIMER}>
                  <CountdownTimer timeout={1000 * 60 * 10} currentStep={activeStep} />
                </FeatureToggle>
                {children}
              </div>
            </div>
            <RestrictedItemsModal />
          </div>
        )}
      />

    );
  }
}

CheckoutLayout.displayName = 'CheckoutLayout';

CheckoutLayout.propTypes = {
  activeStep: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  checkoutConfiguration: ApiInternalPropTypes.checkoutConfiguration,
  forceEnableShopifyDiscounts: PropTypes.bool,
  isDutyIncluded: PropTypes.bool.isRequired,
  isOrderSummaryVisible: PropTypes.bool.isRequired,
  isLiquid: PropTypes.bool.isRequired,
  isTaxIncluded: PropTypes.bool.isRequired,
  itemContentsMap: PropTypes.objectOf(ApiPropTypes.checkoutItemContent).isRequired,
  isShopifyIntegration: PropTypes.bool.isRequired,
  onRequestHideOrderSummary: PropTypes.func,
  onRequestShowOrderSummary: PropTypes.func,
  order: ApiPropTypes.order.isRequired,
  organization: PropTypes.string.isRequired,
  isLoyaltyEnabled: PropTypes.bool,
  isDiscountsEnabled: PropTypes.bool,
  isReviewStepEnabled: PropTypes.bool,
  isGiftCardsEnabled: PropTypes.bool,
  isCatchpointRumEnabled: PropTypes.bool,
  canTriggerInitialized: PropTypes.func.isRequired,
  triggerInitialized: PropTypes.func.isRequired,
  isDeliveredDutyPaidMessageEnabled: PropTypes.bool,
};

CheckoutLayout.defaultProps = {
  checkoutConfiguration: undefined,
  forceEnableShopifyDiscounts: false,
  onRequestHideOrderSummary: noop,
  onRequestShowOrderSummary: noop,
  isLoyaltyEnabled: false,
  isDiscountsEnabled: false,
  isReviewStepEnabled: false,
  isGiftCardsEnabled: false,
  isCatchpointRumEnabled: false,
  isDeliveredDutyPaidMessageEnabled: false,
};

export default CheckoutLayout;
