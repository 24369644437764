/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const Close = (props) => (
  <SvgIcon viewBox="0 0 12 16" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="7.48 8 11.23 11.75 9.75 13.23 6 9.48 2.25 13.23 0.77 11.75 4.52 8 0.77 4.25 2.25 2.77 6 6.52 9.75 2.77 11.23 4.25" />
    </g>
  </SvgIcon>
);

Close.displayName = 'Close';

export default Close;
