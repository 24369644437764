/**
 * @fileOverview The external interface to checkout accessible via
 * `flow.checkout.*`
 *
 * Tight coupling with with redux implementation to keep application state in
 * sync with commands issued externally.
 */
import get from 'lodash/get';
import { Store } from 'redux';
import ChannelEventEmitter from '@flowio/events/lib/channel-event-emitter';

import ExternalOrderClient from './order';
import PageViews from '../constants/pageviews';
import ExternalEvents from './constants/external-events';
import {
  onPageView,
  onOrderUpserted,
  onTransaction,
  onInitialized,
} from './agnostic-tags';
import {
  getItemContents as selectItemContents,
  getFeatureValueByKey,
  getInitialPaymentMethodIdForGtagTracker,
  getIsBlazeCheckout,
} from '../store/checkout/selectors';
import ItemContentHelper from '../utilities/item-content-helper';
import { getSession } from '../utilities/session';
import { RootState } from '../store/types';
import { ApplicationVersion } from '../types';

/**
 * @typedef {import('./order')} ExternalOrderClient
 */

export interface CheckoutClientEnums {
  pageView: object;
  events: object;
}

export type OnErrorCallback = (error: Error) => void

export interface EventData {
  order: io.flow.v0.models.Order;
  getOrderPrices: () => { string: io.flow.v0.models.OrderPriceDetail };
  content: ItemContentHelper;
}

export interface CheckoutClient {
  getAppVersion: () => ApplicationVersion;
  getBooleanFeatureEnabled: (featureKey: string) => boolean;
  getOrder: () => ExternalOrderClient | undefined;
  getItemContents: () => io.flow.v0.models.CheckoutItemContent[];
  getDefaultPayment: () => string | undefined;
  onError: (callback: OnErrorCallback) => void;
  onPageView: (data: EventData) => void;
  onOrderUpserted: (data: io.flow.v0.models.Order) => void;
  onTransaction: (data: EventData) => void;
  onInitialized: (data: EventData) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerEvent: (event: string, data: any) => void;
  getSession: () => io.flow.v0.models.OrganizationSession | undefined;
  enums: CheckoutClientEnums;
}

const emitter = new ChannelEventEmitter();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function triggerEvent(event: string, data: any): void {
  emitter.trigger(event, data);
}

export default function ExternalCheckoutClient(
  organization: string,
  store: Store<RootState>,
): CheckoutClient {
  function getOrder(): ExternalOrderClient | undefined {
    const state = store.getState();
    const order = get(state, 'checkout.entities.order');

    if (order) {
      return new ExternalOrderClient(order, organization, store);
    }

    return undefined;
  }

  function getAppVersion(): ApplicationVersion {
    const state = store.getState();
    const isBlaze = getIsBlazeCheckout(state);
    if (isBlaze) {
      return {
        family: 'blaze',
        stage: 'alpha',
      };
    }

    return {
      family: 'legacy',
      stage: 'release',
    };
  }

  function getBooleanFeatureEnabled(featureKey: string): boolean {
    const state = store.getState();
    const featureValue = getFeatureValueByKey(
      featureKey,
    )(state) as io.flow.internal.v0.models.BooleanFeatureValue;
    return (featureValue && featureValue.value) || false;
  }

  function getDefaultPayment(): string | undefined {
    const state = store.getState();
    const defaultPayment = getInitialPaymentMethodIdForGtagTracker(state);

    return defaultPayment;
  }

  function getItemContents(): io.flow.v0.models.CheckoutItemContent[] {
    const state = store.getState();
    const itemContents = selectItemContents(state);

    return itemContents || [];
  }

  function onError(callback: Function): void {
    emitter.on('error', callback);
  }

  return {
    getAppVersion,
    getBooleanFeatureEnabled,
    getOrder,
    getItemContents,
    getDefaultPayment,
    onError,
    onPageView,
    onOrderUpserted,
    onTransaction,
    onInitialized,
    getSession,
    triggerEvent,
    enums: {
      pageView: {
        ...PageViews,
      },
      events: {
        ...ExternalEvents,
      },
    },
  };
}
