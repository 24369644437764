/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./dialog-footer.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('dialog-footer');

const DialogFooter = ({ children, ...otherProps }) => (
  <div {...otherProps} className={bem.block()}>
    {children}
  </div>
);

DialogFooter.displayName = 'DialogFooter';

DialogFooter.propTypes = {
  children: PropTypes.node,
};

DialogFooter.defaultProps = {
  children: undefined,
};

export default DialogFooter;
