/**
 * TODO: Treat everything related to external events as a single package and
 * move this module to a documented directory dedicated to that feature.
 */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ExternalEvents from '../external/constants/external-events';
import actionTypes from '../store/constants/action-types';
import { getOrderDenormalized } from '../store/checkout/selectors';
import ItemContentHelper from '../utilities/item-content-helper';
import events from '../utilities/events';
import { getCartItemByVariantId } from '../store/cart';
import { shopifyCartItemToPixelOrderItem } from '../utilities/pixel/converters';

export function shouldTriggerOrderUpserted(action) {
  switch (action.type) {
  // BEGIN: LEGACY CHECKOUT FLOW
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS: // END
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.ADD_PROMOTION_CODE_SUCCESS:
    case actionTypes.CREATE_SHOPIFY_ORDER_SUCCESS:
    case actionTypes.FETCH_ORDER_SUCCESS:
    case actionTypes.REMOVE_PROMOTION_CODE_SUCCESS:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_SUCCESS:
    case actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS:
      /* BEGIN: LEGACY CHECKOUT FLOW */ case actionTypes.CREATE_ORDER_BUILDER_SUCCESS: /* END: LEGACY CHECKOUT FLOW */
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS:
    case actionTypes.SET_ORDER_ATTRIBUTES_SUCCESS:
    case actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_ORDER_BUILDER_SUCCESS:
      return true;
      // For consistency, we will update the order entity when the payment authorization is successful,
      // otherwise maintain the current order state.
    case actionTypes.SUBMIT_ORDER_SUCCESS: {
    // When an order is submitted and the authorization is successful, the response will contain
    // the updated flow order in its `detail` property.
      if (!isEmpty(action.payload.detail)) {
        return true;
      }
      return false;
    }
    default:
      return false;
  }
}

export function shouldTriggerTransaction(action) {
  return action.type === actionTypes.TRIGGER_TRANSACTION_EVENT;
}

export function shouldTriggerInitialized(action) {
  return action.type === actionTypes.TRIGGER_INITIALIZED_EVENT;
}

export function shouldTriggerPaymentSubmitted(action) {
  return action.type === actionTypes.TRIGGER_PAYMENT_SUBMITTED_EVENT;
}

export function shouldTriggerShippingMethodChanged(action) {
  return action.type === actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS;
}

export function shouldTriggerPaymentMethodChanged(action) {
  return action.type === actionTypes.CHANGE_CARD;
}

export function getEventOrderData() {
  /**
   * @type {import('../external/checkout').CheckoutClient}
   */
  const externalClient = get(window, 'flow.checkout');

  if (!externalClient) {
    return undefined;
  }

  const experiment = externalClient.getSession() != null && externalClient.getSession().experiment;

  return {
    experiment,
    organization: externalClient.getOrder().organization,
    order: externalClient.getOrder().order,
    getOrderPrices: () => externalClient.getOrder().getOrderPrices(),
    content: new ItemContentHelper(externalClient.getItemContents()),
    appVersion: externalClient.getAppVersion(),
  };
}

/**
 * @param {import('../store/types').RootState} previousState
 * @param {import('../store/types').RootState} state
 * @param {number} cartItemVariantId
 * @param {string} transactionId
 */
function triggerCartItemEvent(
  previousState,
  state,
  cartItemVariantId,
  transactionId,
) {
  // Use the previous state stored in the optimist subtree to determine
  // in which direction the item quantity was changed.
  const previousEntry = previousState.optimist.find((entry) => (
    entry.action != null
    && entry.action.optimist != null
    && entry.action.optimist.transactionId === transactionId
  ));

  if (previousEntry == null) {
    return;
  }

  const previousBeforeState = previousEntry.beforeState;

  if (previousBeforeState == null) {
    return;
  }

  const previousItem = getCartItemByVariantId(cartItemVariantId)(previousBeforeState);

  if (previousItem == null) {
    return;
  }

  const item = getCartItemByVariantId(cartItemVariantId)(state);

  // IMPORTANT: quantity needs to reflect the number of items that were added or
  // removed from the cart, and not the final tally of each item in the cart.

  if (item == null) {
    events.trigger(
      ExternalEvents.REMOVE_FROM_CART,
      shopifyCartItemToPixelOrderItem(previousItem, previousItem.quantity),
    );
  } else if (item.quantity > previousItem.quantity) {
    events.trigger(
      ExternalEvents.ADD_TO_CART,
      shopifyCartItemToPixelOrderItem(item, item.quantity - previousItem.quantity),
    );
  } else if (item.quantity < previousItem.quantity) {
    events.trigger(
      ExternalEvents.REMOVE_FROM_CART,
      shopifyCartItemToPixelOrderItem(item, previousItem.quantity - item.quantity),
    );
  }
}

const externalEventEmitter = (store) => (next) => (action) => {
  const previousState = store.getState();
  const result = next(action);
  const state = store.getState();

  if (shouldTriggerOrderUpserted(action)) {
    events.trigger(ExternalEvents.ORDER_UPSERTED, getOrderDenormalized(state));
  }

  if (shouldTriggerTransaction(action)) {
    events.trigger(ExternalEvents.TRANSACTION, getEventOrderData());
  }

  if (shouldTriggerInitialized(action)) {
    events.trigger(ExternalEvents.INITIALIZED, getEventOrderData());
  }

  if (shouldTriggerPaymentSubmitted(action)) {
    events.trigger(ExternalEvents.PAYMENT_INFO_SUBMITTED, getEventOrderData());
  }

  if (shouldTriggerShippingMethodChanged(action)) {
    events.trigger(ExternalEvents.SHIPPING_METHOD_CHANGE, getEventOrderData());
  }

  if (shouldTriggerPaymentMethodChanged(action)) {
    events.trigger(ExternalEvents.PAYMENT_METHOD_CHANGE, {
      ...getEventOrderData(),
      payment: action.payload,
    });
  }

  if (action.type === actionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS) {
    triggerCartItemEvent(
      previousState,
      state,
      action.params.cartItemVariantId,
      action.optimist.transactionId,
    );
  } else if (action.type === actionTypes.REMOVE_CART_ITEM_SUCCESS) {
    triggerCartItemEvent(
      previousState,
      state,
      action.params.cartItemVariantId,
      action.optimist.transactionId,
    );
  }

  return result;
};

export default externalEventEmitter;
