import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import React, { useContext, useEffect, useState } from 'react';
import * as featureKeys from '../../common/constants/feature-keys';

import { RootState } from '../store/types';
import { setFeatureValue } from '../store/checkout';

interface FeatureOverride {
  featureKey: string;
  featureValue: boolean;
}

// eslint-disable-next-line @typescript-eslint/unbound-method
const keys = Object.keys as <T>(o: T) => (Extract<keyof T, string>)[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFeatureKeySupported = (featureKey: any): boolean => (
  keys(featureKeys).some((key) => featureKey === featureKeys[key])
);

const getFeatureOverrides = (): FeatureOverride[] => {
  const queryString = window.location.search.substr(1);
  const query = qs.parse(queryString);

  let features: string[];

  if (typeof query.flow_feature === 'string') {
    features = [query.flow_feature];
  } else if (Array.isArray(query.flow_feature)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    features = query.flow_feature.filter((feature: any): feature is string => typeof feature === 'string');
  } else {
    features = [];
  }

  return features.map((feature): FeatureOverride => {
    const featureKey = feature.replace('!', '');
    const featureValue = feature.startsWith('!') === false;
    return { featureKey, featureValue };
  }).filter(({ featureKey }) => isFeatureKeySupported(featureKey));
};

const Context = React.createContext<FeatureOverride[]>([]);

/**
 * Responsible for parsing feature overrides from query parameters, injecting
 * the computed feature overrides into the tree below, and maintaining that
 * state across route transitions.
 */
export const FeatureOverrideProvider: React.FunctionComponent = ({
  children,
}) => {
  const [overrides, setOverrides] = useState<FeatureOverride[]>([]);

  // Read feature overrides from URL once per checkout session.
  useEffect(() => {
    setOverrides(getFeatureOverrides());
  }, []);

  return (
    <Context.Provider value={overrides}>
      {children}
    </Context.Provider>
  );
};

/**
 * A convenience hook to read feature overrides in context.
 */
export const useFeatureOverrides = (): FeatureOverride[] => useContext(Context);

/**
 * Responsible for synchronizing feature overrides in context into application
 * state on every render.
 */
export const FeatureOverrideSynchronizer: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  // Updates to feature values will cause this component to re-render, which
  // executes the side effect to override feature values.
  const featureValues = useSelector<RootState, io.flow.internal.v0.unions.FeatureValue[]>(
    (state) => state.checkout.features.values,
  );

  const featureOverrides = useFeatureOverrides();

  useEffect(() => {
    // Update any feature value that has changed
    featureOverrides.filter((featureOverride) => (
      featureValues.some((featureValue) => (
        featureValue.feature.key === featureOverride.featureKey
        && featureValue.value !== featureOverride.featureValue
      ))
    )).forEach(({ featureKey, featureValue }) => {
      dispatch(setFeatureValue(featureKey, featureValue));
    });
  });

  return null;
};
