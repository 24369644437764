import React from 'react';
import type Rollbar from 'rollbar';
import RollbarContext from '../contexts/rollbar';

const useRollbar = (): Rollbar => {
  const rollbar = React.useContext(RollbarContext);

  if (!rollbar) {
    throw new Error(
      'Could not find Rollbar context value. '
      + 'Please ensure the component is wrapped in Rollbar context provider.',
    );
  }

  return rollbar;
};

export default useRollbar;
