import { AuthorizationsPostParametersResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { FetchPaymentMethodParamsError } from '../types';
import type { LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<AuthorizationsPostParametersResponse>>;

const fetchPaymentMethodParamsRequest = (): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_PARAMS_REQUEST,
});

const fetchPaymentMethodParamsSuccess = (
  authorizationParameters: io.flow.internal.v0.models.AuthorizationParameters[],
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_PARAMS_SUCCESS,
  payload: authorizationParameters,
});

const fetchPaymentMethodParamsFailure = (
  error: FetchPaymentMethodParamsError,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_PARAMS_FAILURE,
  payload: error,
});

const fetchPaymentMethodParams = (
  organizationId: string,
  authorizationParametersForm: io.flow.internal.v0.models.AuthorizationParametersForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(fetchPaymentMethodParamsRequest());
  return extra.apiInternal.authorizations.postParameters({
    body: authorizationParametersForm,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchPaymentMethodParamsSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(fetchPaymentMethodParamsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchPaymentMethodParams;
