import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { Grid, Row, Column } from '../grid';
import DeliveredDuties from '../../constants/delivered-duties';
import PaymentMethodSummary from '../payment-method-summary';
import PaymentMethodReviewSummary from '../payment-method-review-summary';
import AddressSummary from '../address-summary';
import Section from '../section';
import ShippingMethodSummary from '../shipping-method-summary';
import { AddressType } from '../../constants/address-type';
import FeatureToggle from '../feature-toggle';
import Features from '../../constants/features';
import MobileAddressSummary from '../mobile-address-summary';
import isSmallScreen from '../../utilities/is-small-screen';

if (process.browser) {
  require('./customer-info-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('customer-info-summary');

const CustomerInfoSummary = ({
  allowInvoiceAddress,
  invoiceAddress,
  authorizations,
  billingAddress,
  countries,
  deliveryOptions,
  deliveredDuty,
  destination,
  giftMessage,
  isDeliveredDutyCustomerChoice,
  orderPayments,
  contactInfoUrl,
  shippingMethodUrl,
  paymentInfoUrl,
  vrn,
  isReview,
  paymentMethodId,
  paymentSources,
  card,
  nationalIdNumber,
}) => (
  <FeatureToggle
    featureKey={Features.MOBILE_UX_SPACING}
    render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
      <section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
        <h1 className={bem.element('title')}>
          {!isReview && (
            <FormattedMessage
              id="checkout_confirmation_summary_heading"
              defaultMessage="Confirmed Order Information"
              description="Title for the section in the confirmation page containing a summary of the collected data during the checkout process"
            />
          )}
          {isReview && (
            <FormattedMessage
              id="checkout_order_review_heading"
              defaultMessage="Review your Order"
              description="Title for the section in the confirmation page containing a summary of the collected data during the checkout process"
            />
          )}
        </h1>
        <div className={bem.element('content')}>
          <Grid>
            <Row>
              <Column xs={12} sm={6}>
                {isSmallScreen() ? (
                  <MobileAddressSummary
                    editUrl={contactInfoUrl}
                    countries={countries}
                    address={destination}
                    type={AddressType.SHIPPING}
                  />
                ) : (
                  <AddressSummary
                    editUrl={contactInfoUrl}
                    countries={countries}
                    address={destination}
                    type={AddressType.SHIPPING}
                  />
                )}
              </Column>
              <Column xs={12} sm={6}>
                {billingAddress && isSmallScreen() && (
                  <MobileAddressSummary
                    editUrl={paymentInfoUrl}
                    countries={countries}
                    address={billingAddress}
                    type={AddressType.BILLING}
                  />
                )}
                {billingAddress && !isSmallScreen() && (
                  <AddressSummary
                    editUrl={paymentInfoUrl}
                    countries={countries}
                    address={billingAddress}
                    type={AddressType.BILLING}
                  />
                )}
              </Column>
            </Row>
            <Row>
              {/* Add check here for invoice address, eventually it will be in the order */}
              {!isUndefined(invoiceAddress) && !isEmpty(invoiceAddress) && allowInvoiceAddress
                && isSmallScreen() && (
                <Column xs={12} sm={6}>
                  <MobileAddressSummary
                    editUrl={paymentInfoUrl}
                    countries={countries}
                    address={invoiceAddress}
                    vrn={vrn}
                    type={AddressType.INVOICE}
                  />
                </Column>
              )}
              {!isUndefined(invoiceAddress) && !isEmpty(invoiceAddress) && allowInvoiceAddress
                && !isSmallScreen() && (
                <Column xs={12} sm={6}>
                  <AddressSummary
                    editUrl={paymentInfoUrl}
                    countries={countries}
                    address={invoiceAddress}
                    vrn={vrn}
                    type={AddressType.INVOICE}
                  />
                </Column>
              )}
              {isDeliveredDutyCustomerChoice && (
                <Column xs={12} sm={6}>
                  <Section fitted={isMobileUXSpacingEnabled}>
                    <Section.Header>
                      <Section.Title>
                        <FormattedMessage
                          id="checkout_landed_cost_summary_heading"
                          defaultMessage="Duties & Taxes"
                          description="Title for the section containing a summary of the delivery duties selected in the order"
                        />
                      </Section.Title>
                    </Section.Header>
                    <Section.Content>
                      {deliveredDuty === DeliveredDuties.Paid && (
                        <p className={bem.element('delivered-duty')}>
                          <FormattedMessage
                            id="checkout_delivered_duties_paid"
                            defaultMessage="All taxes and duties are included with your order"
                            description="Text describing that duties and taxes are included in the order"
                          />
                        </p>
                      )}
                      {deliveredDuty === DeliveredDuties.Unpaid && (
                        <p className={bem.element('delivered-duty')}>
                          <FormattedMessage
                            id="checkout_delivered_duties_unpaid"
                            defaultMessage="No tax nor duty will be paid with your order. Your local customs authority may ask you to pay applicable duties and/or taxes."
                            description="Text describing that duties and taxes are not included in the order"
                          />
                        </p>
                      )}
                    </Section.Content>
                  </Section>
                </Column>
              )}
            </Row>
            {nationalIdNumber && (
              <Row>
                <Column xs={12} sm={6}>
                  <Section>
                    <Section.Header>
                      <Section.Title>
                        <FormattedMessage
                          id="checkout_national_id_card_number"
                          defaultMessage="National ID Card Number"
                          description="Title for the section containing the national ID card number in the order"
                        />
                      </Section.Title>
                    </Section.Header>
                    <Section.Content>
                      <p className={bem.element('national-id-number')}>
                        {nationalIdNumber}
                      </p>
                    </Section.Content>
                  </Section>
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={12} sm={6}>
                <ShippingMethodSummary
                  editUrl={shippingMethodUrl}
                  deliveryOptions={deliveryOptions}
                />
              </Column>
              <Column xs={12} sm={6}>
                {isReview ? (
                  <PaymentMethodReviewSummary
                    paymentMethodId={paymentMethodId}
                    paymentSources={paymentSources}
                    card={card}
                    editUrl={paymentInfoUrl}
                  />
                ) : (
                  <PaymentMethodSummary
                    authorizations={authorizations}
                    orderPayments={orderPayments}
                  />
                )}
              </Column>
            </Row>
            <FeatureToggle
              featureKey={Features.GIFT_MESSAGING}
              render={({ isFeatureEnabled }) => {
                if (isFeatureEnabled && giftMessage && giftMessage.length > 0) {
                  return (
                    <Row>
                      <Column xs={12} sm={6}>
                        <Section fitted={isMobileUXSpacingEnabled}>
                          <Section.Header>
                            <Section.Title>
                              <FormattedMessage
                                id="checkout_gift_message_summary_title"
                                defaultMessage="Gift Message"
                                description="Text describing the gift message title"
                              />
                            </Section.Title>
                          </Section.Header>
                          <Section.Content>
                            <p className={bem.element('gift-message')}>
                              {giftMessage}
                            </p>
                          </Section.Content>
                        </Section>
                      </Column>
                    </Row>
                  );
                }
                return (<div />);
              }}
            />
          </Grid>
        </div>
      </section>
    )}
  />
);

CustomerInfoSummary.displayName = 'CustomerInfoSummary';

CustomerInfoSummary.propTypes = {
  allowInvoiceAddress: PropTypes.bool,
  authorizations: PropTypes.arrayOf(ApiPropTypes.authorization),
  billingAddress: ApiPropTypes.billingAddress,
  invoiceAddress: ApiPropTypes.billingAddress,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  deliveryOptions: PropTypes.arrayOf(ApiPropTypes.deliveryOption).isRequired,
  deliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
  destination: ApiPropTypes.orderAddress.isRequired,
  giftMessage: PropTypes.string,
  isDeliveredDutyCustomerChoice: PropTypes.bool.isRequired,
  orderPayments: PropTypes.arrayOf(ApiPropTypes.orderPayment),
  vrn: PropTypes.string,
  contactInfoUrl: PropTypes.string,
  shippingMethodUrl: PropTypes.string,
  paymentInfoUrl: PropTypes.string,
  isReview: PropTypes.bool,
  paymentMethodId: PropTypes.string,
  card: ApiPropTypes.card,
  paymentSources: PropTypes.arrayOf(ApiPropTypes.paymentSource),
  nationalIdNumber: PropTypes.string,
};

CustomerInfoSummary.defaultProps = {
  authorizations: [],
  orderPayments: [],
  billingAddress: undefined,
  invoiceAddress: undefined,
  giftMessage: undefined,
  vrn: undefined,
  paymentMethodId: undefined,
  card: undefined,
  allowInvoiceAddress: false,
  isReview: false,
  contactInfoUrl: undefined,
  shippingMethodUrl: undefined,
  paymentInfoUrl: undefined,
  paymentSources: [],
  nationalIdNumber: undefined,
};

export default CustomerInfoSummary;
