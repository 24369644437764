/* eslint-disable react/jsx-props-no-spreading */

import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import hoistNonReactStatics from 'hoist-non-react-statics';

import Checkbox from '../checkbox';

class ReduxFormCheckbox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { input } = this.props;
    input.onChange(event);
  }

  render() {
    const { input, meta } = this.props;
    const { touched, error, warning } = meta;
    const unhandledProps = getUnhandledProps(ReduxFormCheckbox, this.props);

    let errorText = error || warning;

    if (!touched) {
      errorText = undefined;
    }

    return (
      <Checkbox
        {...unhandledProps}
        checked={input.checked}
        errorText={errorText}
        name={input.name}
        onChange={this.handleChange}
        value={input.value.toString()}
      />
    );
  }
}

ReduxFormCheckbox.displayName = `ReduxForm(${getDisplayName(Checkbox)})`;

ReduxFormCheckbox.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default hoistNonReactStatics(ReduxFormCheckbox, Checkbox, {
  handledProps: true,
});
