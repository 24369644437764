/* eslint-disable react/jsx-props-no-spreading */

import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import Badge from '../badge';
import Image from '../image';

if (process.browser) {
  require('../order-item/order-item.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-item');

/**
 * Create map of data attributes to attach to root element of OrderItem
 *
 * @param {Object} item - An order item
 */
const getDataAttributes = (content) => {
  const attributes = {
    'data-item-number': get(content, 'item.number'),
  };

  attributes['data-shopify-product-type'] = kebabCase(get(content, 'item.attributes.product_type'));
  attributes['data-shopify-variant-id'] = kebabCase(get(content, 'item.attributes.variant_id'));
  attributes['data-shopify-product-id'] = kebabCase(get(content, 'item.attributes.product_id'));

  return attributes;
};

const OrderShopifyItem = ({
  quantity,
  priceLabel,
  content,
  isWrapCartItemEnabled,
}) => (
  <div className={bem.block()} {...getDataAttributes(content)}>
    <div className={bem.element('media')}>
      <Badge className={bem.element('quantity')} color="tertiary" content={quantity} floating>
        <Image
          className={bem.element('image')}
          bordered
          rounded
          fit="fill"
          source={get(content, 'image.url')}
          accessibilityLabel={get(content, 'item.attributes.product_title')}
        />
      </Badge>
    </div>
    <div className={bem.element('details')}>
      <div className={bem.element('name', { wrapping: isWrapCartItemEnabled })}>
        {get(content, 'item.attributes.product_title')}
      </div>
      {get(content, 'item.attributes.display_variant_title') && (
        <div className={bem.element('variant')}>
          {get(content, 'item.attributes.display_variant_title')}
        </div>
      )}
    </div>
    {priceLabel != null && (
      <p className={bem.element('price')}>
        {priceLabel}
      </p>
    )}
  </div>
);

OrderShopifyItem.displayName = 'OrderShopifyItem';

OrderShopifyItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  priceLabel: PropTypes.string,
  isWrapCartItemEnabled: PropTypes.bool,
  content: ApiPropTypes.checkoutItemContent,
};

OrderShopifyItem.defaultProps = {
  content: {},
  priceLabel: undefined,
  isWrapCartItemEnabled: false,
};

export default OrderShopifyItem;
