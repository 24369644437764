import HttpStatusError from './http-status-error';
import isResponseOk from './is-response-ok';
import { Response, Success } from '../services/types';

/**
 * A simple utility that rejects a request on any non 2XX response.
 * @param {Object} response - Response object from `flowcommerce/node-sdk` package.
 */
export default function checkHttpStatus<T extends Response>(
  response: T,
): Success<T> {
  if (!isResponseOk(response)) {
    throw new HttpStatusError(response);
  } else {
    return response;
  }
}
