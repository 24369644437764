import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function openRestrictedItemsModal(
  itemNumbers: string[],
): RootAction {
  return {
    type: actionTypes.OPEN_RESTRICTED_ITEMS_MODAL,
    payload: {
      numbers: itemNumbers,
    },
  };
}
