import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = string;

const initialState: State = 'en';

const localeReducer: AppReducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CHANGE_LOCALE:
      return action.payload;
    default:
      return state;
  }
};

export default localeReducer;
