import { parse, format } from 'url';
import { stringify, ParsedUrlQueryInput } from 'querystring';

export default function appendQuery(
  url: string,
  query: ParsedUrlQueryInput,
): string {
  const parts = parse(url, true);
  parts.query = Object.assign(parts.query, query);
  parts.search = stringify(parts.query);
  return format(parts);
}
