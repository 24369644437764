import { Pixel } from '@flowio/pixel';
import storage from '@flowio/storage';
import ExponentialBackoff from '../exponential-backoff';

/**
 * Queues a function that will be invoked with the pixel instance derived from
 * the current runtime environment.
 */
export default function requestGlobalPixelCallback(
  callback: (pixel: Pixel | undefined) => void,
  maxDuration?: number,
): void {
  if (maxDuration === 0) {
    callback(undefined);
    return;
  }

  const backoff = new ExponentialBackoff({
    maxDuration,
    delay: 200,
  });

  function findPixel(): void {
    let pixel: Pixel | undefined;

    // Check Pixel in Shopify.js
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Flow != null && window.Flow.pixel != null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pixel = window.Flow.pixel;
    // Check pixel configuration in memory.
    // Typically, set in client custom JavaScript.
    } else if (storage.memory.get('pixelConfig')) {
      pixel = new Pixel(storage.memory.get('pixelConfig'));
    }

    const timeout = backoff.next();
    if (pixel != null || timeout != null) {
      callback(pixel);
    } else {
      setTimeout(findPixel, timeout);
    }
  }

  findPixel();
}
