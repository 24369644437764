import React from 'react';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import { Dialog, DialogBody, DialogHeader } from '../dialog';
import Button from '../button';
import ContentElementType from '../../constants/content-element-type';
import LocalizedContentElement from '../localized-content-element';

if (process.browser) {
  require('./learn-more.css'); // eslint-disable-line
}

const bem = new BemHelper('learn-more');

export interface Props {
  isOpen: boolean;
  handleRequestClose: () => void;
}

const infoLink = {
  type: ContentElementType.HREF,
  value: 'https://www.cbsa-asfc.gc.ca/import/postal-postale/dtytx-drttx-eng.html',
};

const LearnMoreModal: React.FunctionComponent<Props> = ({ isOpen, handleRequestClose }) => (
  <Dialog
    isOpen={isOpen}
    showBackdrop
    enableBackdropDismiss
    onRequestClose={handleRequestClose}
  >
    <DialogHeader
      text={(
        <FormattedMessage
          id="checkout_learn-more_modal_header"
          description="Learn more modal header"
          defaultMessage="Customs and Duties"
        />
      )}
    />
    <DialogBody>
      <FormattedMessage
        id="checkout_learn_more_modal_content"
        description="Learn more modal content"
        defaultMessage="Your package may be subject to duties, which will need to be paid by you prior to the package either entering Canada or being delivered to you. There are several considerations on what the calculation is, and whether it will be collected. More information can be found at <a>https://www.cbsa-asfc.gc.ca/import/postal-postale/dtytx-drttx-eng.html</a>."
        values={{
          a: (msg: string): React.ReactElement => (
            <LocalizedContentElement
              inline
              element={infoLink}
            >
              {msg}
            </LocalizedContentElement>
          ),
        }}
      />
      <Button
        className={bem.element('dismiss-button')}
        onClick={handleRequestClose}
      >
        <FormattedMessage
          id="checkout_learn_more_modal_dismiss"
          description="Dismiss button in Learn more modal"
          defaultMessage="Dismiss"
        />
      </Button>
    </DialogBody>
  </Dialog>
);

LearnMoreModal.displayName = 'LearnMoreModal';

export default LearnMoreModal;
