/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect';
import fromPairs from 'lodash/fromPairs';

import { getOrder, getOrderNumber } from '../../store/checkout/selectors';
import { getOrganization } from '../../store/flow/selectors';
import { getOptinPrompts } from '../../store/optin';
import mapOptinPromptsToValuePairs from '../../utilities/map-optin-prompts-to-value-pairs';

export const getInitialValues = createSelector(
  getOrder,
  getOrderNumber,
  getOrganization,
  getOptinPrompts,
  (order, orderNumber, organizationId, optinPrompts) => {
    const initialValues = {
      optinPrompts: fromPairs(mapOptinPromptsToValuePairs(optinPrompts, order)),
      orderNumber,
      organizationId,
    };
    return initialValues;
  },
);
