/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import KeyCodes from '@flowio/browser-helpers/lib/KeyCodes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getKeyCode from '@flowio/browser-helpers/lib/getKeyCode';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import includes from 'lodash/includes';
import handleEnterKeyboardEvent from '../../utilities/handle-enter-keyboard-event';

import Close from '../svg-icons/close';

if (process.browser) {
  require('./chip.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('chip');

class Chip extends Component {
  handleKeyDownChip = (event) => {
    const {
      deleteKeyCodes, disabled, onRequestDelete, onKeyDown, value,
    } = this.props;

    if (includes(deleteKeyCodes, getKeyCode(event))) {
      event.preventDefault();

      if (!disabled) {
        if (onRequestDelete != null) {
          onRequestDelete(value);
        }
      }
    }

    if (onKeyDown != null) {
      onKeyDown(event);
    }
  }

  handleClickClearIcon = () => {
    const { disabled, onRequestDelete, value } = this.props;

    if (!disabled) {
      if (onRequestDelete != null) {
        onRequestDelete(value);
      }
    }
  }

  render() {
    const {
      className, disabled, dismissible, text, color, size,
    } = this.props;
    const unhandledProps = getUnhandledProps(Chip, this.props);

    const buttonModifiers = {
      [color]: true,
      [size]: true,
      dismissible,
      disabled,
    };

    return (
      // TODO: Chips can't be buttons, because then the dismiss <span> is not clickable
      <button
        {...unhandledProps}
        type="button"
        className={bem.block(buttonModifiers, className)}
        onKeyDown={this.handleKeyDownChip}
      >
        <span className={bem.element('text')}>
          {text}
        </span>
        {dismissible && (
          <span
            className={bem.element('clear')}
            onClick={this.handleClickClearIcon}
            onKeyPress={handleEnterKeyboardEvent(this.handleClickClearIcon)}
            role="button"
            tabIndex={0}
          >
            <Close />
          </span>
        )}
      </button>
    );
  }
}

Chip.displayName = 'Chip';

Chip.propTypes = {
  className: PropTypes.string,
  deleteKeyCodes: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  dismissible: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onRequestDelete: PropTypes.func,
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['negative', 'default', 'positive', 'primary', 'light']),
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.string,
};

Chip.defaultProps = {
  className: '',
  deleteKeyCodes: [KeyCodes.Backspace],
  disabled: false,
  dismissible: false,
  onKeyDown: undefined,
  onRequestDelete: undefined,
  color: 'default',
  size: 'medium',
  value: undefined,
};

export default Chip;
