/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getOuterHeight from '@flowio/browser-helpers/lib/getOuterHeight';
import getOuterWidth from '@flowio/browser-helpers/lib/getOuterWidth';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import noop from 'lodash/noop';

import Transition from '../transition';

if (process.browser) {
  require('./collapse.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('flow-collapse');

// Reading a dimension property will cause the browser to recalculate,
// which will let our animations work.
function triggerBrowserReflow(element) {
  element.offsetHeight; // eslint-disable-line no-unused-expressions
}

function defaultGetDimensionValue(dimension, element) {
  return (dimension === 'height') ? getOuterHeight(element, true) : getOuterWidth(element, true);
}

function getScrollDimensionValue(dimension, element) {
  return (dimension === 'height') ? element.scrollHeight : element.scrollWidth;
}

class Collapse extends Component {
  getDimension() {
    const { dimension } = this.props;
    return typeof dimension === 'function' ? dimension() : dimension;
  }

  /* Expanding */
  handleEnter = (element) => {
    const { onBeforeExpand } = this.props;
    const dimension = this.getDimension();
    element.style[dimension] = '0'; // eslint-disable-line no-param-reassign
    onBeforeExpand(element);
  }

  handleEntering = (element) => {
    const { onExpand } = this.props;
    const dimension = this.getDimension();
    const value = getScrollDimensionValue(dimension, element);
    element.style[dimension] = `${value}px`; // eslint-disable-line no-param-reassign
    onExpand(element);
  }

  handleEntered = (element) => {
    const { onAfterExpand } = this.props;
    const dimension = this.getDimension();
    element.style[dimension] = null; // eslint-disable-line no-param-reassign
    onAfterExpand(element);
  }

  /* Collapsing */
  handleExit = (element) => {
    const { getDimensionValue, onBeforeCollapse } = this.props;
    const dimension = this.getDimension();
    const value = getDimensionValue(dimension, element);
    element.style[dimension] = `${value}px`; // eslint-disable-line no-param-reassign
    triggerBrowserReflow(element);
    onBeforeCollapse(element);
  }

  handleExiting = (element) => {
    const { onCollapse } = this.props;
    const dimension = this.getDimension();
    element.style[dimension] = '0'; // eslint-disable-line no-param-reassign
    onCollapse(element);
  }

  handleExited = (element) => {
    const { onAfterCollapse } = this.props;
    onAfterCollapse(element);
  }

  render() {
    const {
      className, in: isIn, role, timeout, transitionAppear, unmountOnExit,
    } = this.props;
    const unhandledProps = getUnhandledProps(Collapse, this.props);
    const modifiers = {
      width: this.getDimension() === 'width',
    };

    return (
      <Transition
        {...unhandledProps}
        in={isIn}
        role={role}
        aria-expanded={role ? isIn : null}
        className={bem.block(modifiers, className)}
        exitedClassName="flow-collapse--collapsed"
        exitingClassName="flow-collapse--collapsing"
        enteredClassName="flow-collapse--expanded"
        enteringClassName="flow-collapse--expanding"
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        onExited={this.handleExited}
        timeout={timeout}
        transitionAppear={transitionAppear}
        unmountOnExit={unmountOnExit}
      />
    );
  }
}

Collapse.displayName = 'Collapse';

Collapse.propTypes = {
  /**
   * Additional CSS classes to apply to the root element.
   */
  className: PropTypes.string,

  /**
   * Show the component; triggers the expand or collapse animation.
   */
  in: PropTypes.bool,

  /**
   * Unmount the component (remove it from the DOM) when it is collapsed.
   */
  unmountOnExit: PropTypes.bool,

  /**
   * Run the expand animation when the component mounts, if it is
   * initially shown.
   */
  transitionAppear: PropTypes.bool,

  /**
   * Duration of the collapse animation in milliseconds, to ensure that
   * finishing callbacks are fired even if the original browser transition
   * end events are canceled.
   */
  timeout: PropTypes.number,

  /**
   * Callback fired before the component expands.
   */
  onBeforeExpand: PropTypes.func,

  /**
   * Callback fired after the component starts to expand.
   */
  onExpand: PropTypes.func,

  /**
   * Callback fired after the component has expanded.
   */
  onAfterExpand: PropTypes.func,

  /**
   * Callback fired before the component collapses.
   */
  onBeforeCollapse: PropTypes.func,

  /**
   * Callback fired after the component starts to collapse.
   */
  onCollapse: PropTypes.func,

  /**
   * Callback fired after the component has collapsed.
   */
  onAfterCollapse: PropTypes.func,

  /**
   * The dimension used when collapsing, or a function that returns the
   * dimension
   *
   * Note: Only partially supports 'width'!
   * You will need to supply your own CSS animation for the `.width` CSS class.
   */
  dimension: PropTypes.oneOfType([
    PropTypes.oneOf(['height', 'width']),
    PropTypes.func,
  ]),

  /**
   * Function that returns the height or width of the animating DOM node.
   *
   * Allows for providing some custom logic for how much the Collapse
   * component should animate in its specified dimension. Called with the
   * current dimension prop value and the DOM node.
   */
  getDimensionValue: PropTypes.func,

  /**
   * ARIA role of collapsible element
   */
  role: PropTypes.string,
};

Collapse.defaultProps = {
  className: '',
  in: false,
  timeout: 300,
  unmountOnExit: false,
  transitionAppear: false,
  dimension: 'height',
  onBeforeExpand: noop,
  onExpand: noop,
  onAfterExpand: noop,
  onBeforeCollapse: noop,
  onCollapse: noop,
  onAfterCollapse: noop,
  getDimensionValue: defaultGetDimensionValue,
  role: undefined,
};

export default Collapse;
