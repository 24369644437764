import type { OrdersPutSubmissionsByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrdersPutSubmissionsByNumberResponse>>;

const submitOrderRequest = (): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_REQUEST,
});

const submitOrderSuccess = (
  order: io.flow.v0.models.Order,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_SUCCESS,
  payload: order,
});

const submitOrderFailure = (
  error: io.flow.v0.models.OrderError | LegacyError,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_FAILURE,
  payload: error,
});

const submitOrder = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(submitOrderRequest());
  return extra.api.orders.putSubmissionsByNumber({
    body: {},
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(submitOrderSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(submitOrderFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default submitOrder;
