/* eslint-disable react/jsx-props-no-spreading */

// @ts-check

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

const bem = new BemHelper('section');

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

/**
 * @typedef {PropTypes.InferProps<typeof propTypes>} SectionTitleOwnProps
 */

/**
 * @typedef {Pick<React.AllHTMLAttributes<HTMLElement>, Exclude<keyof React.AllHTMLAttributes<HTMLElement>, keyof SectionTitleOwnProps>>} HTMLAttributes
 */

/**
 * @typedef {HTMLAttributes & SectionTitleOwnProps} SectionTitleProps
 */

/**
 * @type {React.FunctionComponent<SectionTitleProps>}
 */
const SectionTitle = ({
  children,
  className,
  text,
  ...unhandledProps
}) => (
  <h1 {...unhandledProps} className={bem.element('title', className)}>
    {children || text}
  </h1>
);

SectionTitle.displayName = 'SectionTitle';

SectionTitle.propTypes = propTypes;

SectionTitle.defaultProps = {
  children: undefined,
  className: '',
  text: '',
};

export default SectionTitle;
