import update from 'immutability-helper';
import head from 'lodash/head';
import actionTypes from '../../constants/action-types';
import { AppReducer } from '../../types';
import { CheckoutErrorsState } from '../types';

const initialState: CheckoutErrorsState = {
  order: undefined,
  checkout: undefined,
  loyaltyRemove: undefined,
  giftCard: undefined,
};

const errorsReducer: AppReducer<CheckoutErrorsState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_FAILURE:
    case actionTypes.ADD_PROMOTION_CODE_FAILURE:
    case actionTypes.CREATE_ORDER_BUILDER_FAILURE:
    case actionTypes.CREATE_SHOPIFY_ORDER_FAILURE:
    case actionTypes.FETCH_ORDER_FAILURE:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_FAILURE:
    case actionTypes.REMOVE_PROMOTION_CODE_FAILURE:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_FAILURE:
    case actionTypes.SUBMIT_ORDER_BUNDLE_FAILURE:
    case actionTypes.UPDATE_BILLING_ADDRESS_FAILURE:
    case actionTypes.UPDATE_DELIVERED_DUTY_FAILURE:
    case actionTypes.UPDATE_ORDER_BUILDER_FAILURE:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE:
    case actionTypes.UPDATE_SHIPPING_METHOD_FAILURE:
      return update(previousState, {
        order: { $set: action.payload },
      });
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return update(previousState, {
        order: { $set: head(action.payload.order_errors) },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        order: { $set: head(action.payload.builder.errors) },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_FAILURE:
      return update(previousState, {
        checkout: { $set: action.payload },
      });
    case actionTypes.CREATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.FINALIZE_ORDER_SUCCESS:
    case actionTypes.SUBMIT_ORDER_BUNDLE_SUCCESS:
    case actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS:
      return update(previousState, {
        order: { $set: head(action.payload.errors) },
      });
    case actionTypes.REMOVE_GIFT_CARD_FAILURE:
    case actionTypes.ADD_GIFT_CARD_V2_FAILURE:
      return {
        ...previousState,
        giftCard: action.payload,
      };

    case actionTypes.ADD_GIFT_CARD_V2_SUCCESS:
    case actionTypes.REMOVE_GIFT_CARD_SUCCESS:
      return {
        ...previousState,
        giftCard: head(action.payload.errors),
      };
    case actionTypes.ADD_GIFT_CARD_V2_REQUEST:
    case actionTypes.REMOVE_GIFT_CARD_REQUEST:
      return update(previousState, {
        giftCard: { $set: undefined },
      });
    case actionTypes.REMOVE_LOYALTY_REWARD_FAILURE:
      return {
        ...previousState,
        loyaltyRemove: action.payload,
      };
    case actionTypes.REMOVE_LOYALTY_REWARD_REQUEST:
      return update(previousState, {
        loyaltyRemove: { $set: undefined },
      });
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_REQUEST:
    case actionTypes.ADD_PROMOTION_CODE_REQUEST:
    case actionTypes.CREATE_CHECKOUT_ORDER_REQUEST:
    case actionTypes.CREATE_ORDER_BUILDER_REQUEST:
    case actionTypes.CREATE_SHOPIFY_ORDER_REQUEST:
    case actionTypes.FETCH_ORDER_REQUEST:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_REQUEST:
    case actionTypes.REMOVE_PROMOTION_CODE_REQUEST:
    case actionTypes.SUBMIT_ORDER_BUNDLE_REQUEST:
    case actionTypes.UPDATE_BILLING_ADDRESS_REQUEST:
    case actionTypes.UPDATE_DELIVERED_DUTY_REQUEST:
    case actionTypes.UPDATE_ORDER_BUILDER_REQUEST:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_REQUEST:
    case actionTypes.UPDATE_SHIPPING_METHOD_REQUEST:
      return update(previousState, {
        order: { $set: undefined },
      });
    default:
      return previousState;
  }
};

export default errorsReducer;
