import React from 'react';
import SvgIcon from '../svg-icon';

const Plus = () => (
  <SvgIcon viewBox="0 0 12 16">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="12 9 7 9 7 14 5 14 5 9 0 9 0 7 5 7 5 2 7 2 7 7 12 7" />
    </g>
  </SvgIcon>
);

Plus.displayName = 'Plus';

export default Plus;
