import HttpStatusError from './http-status-error';

/**
 * A simple utility that rejects a request on any non 2XX response.
 * @param {any} response - Response object from `@flowio/node-sdk` package.
 */
export default function checkHttpStatus(response) {
  if (response.ok) {
    return response;
  }
  if (response.status < 200 || response.status >= 300) {
    throw new HttpStatusError(response);
  } else {
    return response;
  }
}
