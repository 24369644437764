/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';

const ExternalLink = ({ children, href, ...unhandledProps }) => (
  <a {...unhandledProps} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

ExternalLink.displayName = 'ExternalLink';

ExternalLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
};

ExternalLink.defaultProps = {
  children: undefined,
};

export default ExternalLink;
