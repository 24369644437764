import { Dispatch, Store } from 'redux';
import noop from 'lodash/noop';
import find from 'lodash/find';
import isError from 'lodash/isError';
import { addPromotionCodeAndUpdatePaymentMethods, removePromotionCodeAndUpdatePaymentMethods, setOrderAttributes } from '../store/checkout/actions';

import { getOrderDenormalized } from '../store/checkout/selectors';
import { ClientRequestOptions, OrderPrices } from '../types';
import { RootState, RootActionTypes } from '../store/types';

export default class ExternalOrderClient {
  public order: io.flow.v0.models.Order;

  /**
 * @fileOverview The external interface for safely interacting with orders.
 *
 * Tight coupling with with redux implementation to keep application state in
 + sync with commands issued externally.
 */

  public organization: string;

  private dispatch: Dispatch<RootActionTypes>;

  private getDenormalizedOrder: () => io.flow.v0.models.Order

  public constructor(
    order: io.flow.v0.models.Order,
    organization: string,
    store: Store<RootState, RootActionTypes>,
  ) {
    this.order = order;
    this.organization = organization;

    // Since there are no private properties in JS Classes, these are here to prevent exposing the `store` object.
    this.dispatch = store.dispatch;
    this.getDenormalizedOrder = (): io.flow.v0.models.Order => getOrderDenormalized(
      store.getState(),
    );
  }

  /**
   * Sets an attribute on an order.
   */
  public setAttribute(
    key: string,
    value: string,
    options: ClientRequestOptions = {},
  ): Promise<io.flow.v0.models.OrderBuilder | undefined> {
    const {
      onError = noop,
      onSuccess = noop,
    } = options;

    return this.dispatch(setOrderAttributes(this.organization, { [key]: value }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any): Promise<io.flow.v0.models.OrderBuilder | undefined> => {
        if (response.ok) {
          // Order Builder API returns errors with a 2xx response
          if (response.result.errors) {
            onError(response.result);
            return Promise.reject(response.result);
          }

          onSuccess(response.result);
          return Promise.resolve(response.result);
        }

        if (response.status === 422) {
          // Generic API / Service error
          onError(response.result);
          return Promise.reject(response.result);
        }

        const err = new Error('Failed to update order attribute');
        onError(err);
        return Promise.reject(err);
      });
  }

  /**
   * Returns a simple view of order pricing.
   */
  public getOrderPrices(): OrderPrices | {} {
    if (this.order) {
      return {
        subtotal: find(this.order.prices, (price): boolean => price.key === 'subtotal'),
        duty: find(this.order.prices, (price): boolean => price.key === 'duty'),
        shipping: find(this.order.prices, (price): boolean => price.key === 'shipping'),
        surcharges: find(this.order.prices, (price): boolean => price.key === 'surcharges'),
      };
    }

    return {};
  }

  /**
   * Add a promotion code to an order.
   */
  public addPromotion(code: string, opts: ClientRequestOptions): Promise<io.flow.v0.models.Order> {
    const options = { onSuccess: noop, onError: noop, ...opts };
    return this.dispatch(
      addPromotionCodeAndUpdatePaymentMethods(this.organization, this.order.number, code),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ).then((response: any): Promise<io.flow.v0.models.Order> => {
      if (isError(response)) {
        options.onError(response);
        return Promise.reject(response);
      }

      const denormalizedOrder = this.getDenormalizedOrder();

      options.onSuccess(denormalizedOrder);
      return Promise.resolve(denormalizedOrder);
    });
  }

  /**
   * Remove a promotion code from an order.
   */
  public removePromotion(opts: ClientRequestOptions): Promise<io.flow.v0.models.Order> {
    const options = { onSuccess: noop, onError: noop, ...opts };
    return this.dispatch(
      removePromotionCodeAndUpdatePaymentMethods(this.organization, this.order.number, null),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ).then((response: any): Promise<io.flow.v0.models.Order> => {
      if (isError(response)) {
        options.onError(response);
        return Promise.reject(response);
      }

      const denormalizedOrder = this.getDenormalizedOrder();

      options.onSuccess(denormalizedOrder);
      return Promise.resolve(denormalizedOrder);
    });
  }
}
