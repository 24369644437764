import { CancellationToken } from '@flowio/cancellation-token';
import type { OrderBuildersPutTaxAndRegistrationByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { CheckVrnNumberActionMetadata } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<OrderBuildersPutTaxAndRegistrationByNumberResponse>
>;

interface Props {
  organizationId: string;
  orderNumber: string;
  vrn: string;
  companyName: string;
  cancellationToken: CancellationToken;
}

const checkVrnAndUpdateOrderRequest = (
  metadata: CheckVrnNumberActionMetadata,
): RootAction => ({
  type: actionTypes.CHECK_VRN_NUMBER_REQUEST,
  meta: metadata,
});

const checkVrnAndUpdateOrderSuccess = (
  metadata: CheckVrnNumberActionMetadata,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.CHECK_VRN_NUMBER_SUCCESS,
  meta: metadata,
  payload: orderBuilder,
});

const checkVrnAndUpdateOrderFailure = (
  metadata: CheckVrnNumberActionMetadata,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.CHECK_VRN_NUMBER_FAILURE,
  meta: metadata,
  payload: error,
});

const checkVrnAndUpdateOrderCancelled = (): RootAction => ({
  type: actionTypes.CANCELLED_CHECK_VRN_REQUEST,
});

const checkVrnAndUpdateOrder = ({
  organizationId,
  orderNumber,
  vrn,
  companyName,
  cancellationToken = CancellationToken.none,
}: Props): ThunkResult<Result> => (dispatch, getState, extra) => {
  const metadata: CheckVrnNumberActionMetadata = {
    companyName,
    orderNumber,
    organizationId,
    vrn,
  };

  dispatch(checkVrnAndUpdateOrderRequest(metadata));

  return extra.api.orderBuilders.putTaxAndRegistrationByNumber({
    body: {
      number: vrn,
      company_name: companyName,
    },
    expand: ['experience'],
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          if (!cancellationToken.cancellationRequested) {
            dispatch(checkVrnAndUpdateOrderSuccess(metadata, response.result));
          } else {
            dispatch(checkVrnAndUpdateOrderCancelled());
          }
          break;
        case 401:
        case 404:
          dispatch(checkVrnAndUpdateOrderFailure(metadata, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default checkVrnAndUpdateOrder;
