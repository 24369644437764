/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import React from 'react';

import './button.css';

const bem = new BemHelper('button');

export type ButtonColor =
  | 'amazon'
  | 'apple'
  | 'default'
  | 'negative'
  | 'paypal'
  | 'primary'
  | 'positive'
  | 'secondary'
  | 'tertiary';

export type ButtonSize =
  | 'small'
  | 'medium'
  | 'large';

export interface ButtonProps {
  as: React.ElementType;
  automationId?: string;
  children?: React.ReactNode;
  className?: string;
  color: ButtonColor;
  flat: boolean;
  fluid: boolean;
  icon?: boolean | React.ReactNode;
  link: boolean;
  outline: boolean;
  size: ButtonSize;
  text?: React.ReactNode;
}

type HtmlAttributes = Pick<
React.AllHTMLAttributes<HTMLElement>,
Exclude<keyof React.AllHTMLAttributes<HTMLElement>, keyof ButtonProps>
>;

class Button extends React.PureComponent<ButtonProps & HtmlAttributes> {
  public static displayName = 'Button';

  public static defaultProps = {
    as: 'button',
    color: 'default',
    flat: false,
    fluid: false,
    link: false,
    outline: false,
    size: 'medium',
  };

  public render(): React.ReactNode {
    const {
      as,
      automationId,
      children,
      className,
      color,
      flat,
      fluid,
      icon,
      link,
      outline,
      size,
      text,
      ...unhandledProps
    } = this.props;

    const solid = !flat && !outline && !link;

    const modifiers = bem.block({
      [color]: solid,
      [`flat-${color}`]: flat,
      [`outline-${color}`]: outline,
      [`link-${color}`]: link,
      [size]: true,
      icon: icon === true,
      fluid,
    });

    let ElementType = as;

    if (ElementType === 'button' && unhandledProps.href != null) {
      ElementType = 'a';
    }

    if (children != null) {
      return (
        <ElementType
          {...unhandledProps}
          className={bem.block(modifiers, className)}
          data-automation-id={automationId}
        >
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType
        {...unhandledProps}
        className={bem.block(modifiers, className)}
        data-automation-id={automationId}
      >
        {typeof icon !== 'boolean' && icon}
        {text}
      </ElementType>
    );
  }
}

export default Button;
