import type { $HttpOk, $HttpUnauthorized, $HttpNotFound } from '@flowio/api-internal-sdk';
import { getOrderDestinationCountryCode, getOrderExperienceRegionId } from '../../checkout/selectors';
import { ThunkResult, RootAction } from '../../types';
import actionTypes from '../../constants/action-types';
import formatError from '../../../utilities/format-error';

type Response = $HttpOk<io.flow.internal.v0.models.OptinPrompt[]>
| $HttpUnauthorized<void>
| $HttpNotFound<void>;

interface Parameters {
  locale: string;
  organizationId: string;
  promptTarget: io.flow.internal.v0.enums.PromptTarget;
  // TODO: This anti-pattern carried from prior implementation.
  // This required property should be provided by caller.
  updatedRegion?: string;
}

function fetchOptinPromptsRequest(): RootAction {
  return {
    type: actionTypes.FETCH_OPTIN_PROMPTS_REQUEST,
  };
}

function fetchOptinPromptsSuccess(
  optinPrompts: io.flow.internal.v0.models.OptinPrompt[],
): RootAction {
  return {
    type: actionTypes.FETCH_OPTIN_PROMPTS_SUCCESS,
    payload: optinPrompts,
  };
}

function fetchOptinPromptsFailure(error: any): RootAction {
  return {
    type: actionTypes.FETCH_OPTIN_PROMPTS_FAILURE,
    payload: error,
  };
}

/**
 * Creates an asynchronous action responsible for fetching prompts to be
 * displayed for the specified target.
 */
function fetchOptinPrompts(
  parameters: Parameters,
): ThunkResult<Promise<Response>> {
  return function fetchOptinPromptsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<Response> {
    const {
      locale,
      organizationId,
      promptTarget,
      updatedRegion,
    } = parameters;
    // TODO: This anti-pattern carried from prior implementation.
    // Required prompt region should be provided by caller.
    const destinationCountryCode = getOrderDestinationCountryCode(getState());
    const experienceRegionId = getOrderExperienceRegionId(getState());

    let promptRegion: string;

    if (updatedRegion != null) {
      promptRegion = updatedRegion;
    } else if (destinationCountryCode != null) {
      promptRegion = destinationCountryCode;
    } else {
      promptRegion = experienceRegionId;
    }

    dispatch(fetchOptinPromptsRequest());
    return extra.apiInternal.optinPrompts.getTargetsByTargetAndRegion({
      expand: ['content'],
      locale,
      organization: organizationId,
      region: promptRegion,
      target: promptTarget,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchOptinPromptsSuccess(response.body));
      } else {
        dispatch(fetchOptinPromptsFailure(formatError(response)));
      }
      return response;
    });
  };
}

export default fetchOptinPrompts;
