import { combineReducers } from 'redux';
import administrativeDivisions from './administrative-divisions';
import countries from './countries';
import statuses from './statuses';
import type { ReferenceState, ReferenceEntities } from '../types';

export default combineReducers<ReferenceState>({
  entities: combineReducers<ReferenceEntities>({
    administrativeDivisions,
    countries,
  }),
  statuses,
});
