import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';

import FormName from '../constants/form-name';
import GiftMessaging from '../components/gift-messaging';
import { getIsGiftMessagingContentOpen, setGiftMessagingContentState } from '../store/checkout';

const formName = FormName.PAYMENT_INFORMATION;

const formSelector = formValueSelector(formName);

const mapDispatchToProps = (dispatch) => ({
  onChange: (event) => {
    const { value } = event.target;
    dispatch(change(formName, 'giftMessage', value, true));
  },
  onHeaderClick: (isOpen) => {
    if (!isOpen) {
      dispatch(change(formName, 'giftMessage', null, true));
    }

    dispatch(setGiftMessagingContentState(isOpen));
  },
});

const mapStateToProps = (state) => ({
  giftMessage: formSelector(state, 'giftMessage'),
  isOpen: getIsGiftMessagingContentOpen(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(GiftMessaging);
