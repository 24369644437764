import React from 'react';
import PropTypes from 'prop-types';

class MediaQuery extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.updateMatches = this.updateMatches.bind(this);
  }

  getInitialState() {
    return {
      matches: false,
      mql: null,
      query: '',
    };
  }

  componentDidMount() {
    const { mql } = this.state;
    mql.addListener(this.updateMatches);
    // Ensure match is correct since status could have
    // changed between first render and now.
    this.updateMatches();
  }

  componentDidUpdate(prevProps, prevState) {
    const { mql: nextMediaQueryList } = this.state;
    const { mql: prevMediaQueryList } = prevState;
    if (nextMediaQueryList !== prevMediaQueryList) {
      this.cleanupMediaQueryList(prevMediaQueryList);
      nextMediaQueryList.addListener(this.updateMatches);
      // We don't need to call updateMatches here because
      // even if the old mql fired before we could safely remove it,
      // updateMatches refers to the new mql instance and
      // it will be accurate.
    }
  }

  componentWillUnmount() {
    const { mql } = this.state;
    this.unmounted = true;
    this.cleanupMediaQueryList(mql);
  }

  cleanupMediaQueryList(mql) {
    if (mql != null) {
      mql.removeListener(this.updateMatches);
    }
  }

  updateMatches() {
    const { matches, mql } = this.state;
    if (this.unmounted) return;
    if (mql == null || mql.matches === matches) return;
    this.setState({ matches: mql.matches });
  }

  render() {
    const { children } = this.props;
    const { matches } = this.state;

    if (typeof children === 'function') {
      return children(matches);
    }

    return matches ? children : null;
  }
}

MediaQuery.displayName = 'MediaQuery';

MediaQuery.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  query: PropTypes.string.isRequired,
};

MediaQuery.getDerivedStateFromProps = function getDerivedStateFromProps(props, state) {
  const { query: nextQuery } = props;
  const { query: prevQuery } = state;

  if (nextQuery === prevQuery) {
    return null;
  }

  const mql = window.matchMedia(nextQuery);

  return {
    matches: mql.matches,
    mql,
    query: nextQuery,
  };
};

export default MediaQuery;
