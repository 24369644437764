import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isRestrictedItemsModalEnabled, getIsShopifyCartV2Enabled } from '../store/checkout/selectors';
import { getIsShopifyIntegration } from '../store/flow/selectors';
import { openRestrictedItemsModal, showDomesticShippingUnavailableDialog } from '../store/checkout/actions';
import OrderError from '../components/order-error';

const mapStateToProps = createStructuredSelector({
  isRestrictedItemsModalEnabled,
  isShopifyCartV2Enabled: getIsShopifyCartV2Enabled,
  isShopifyIntegration: getIsShopifyIntegration,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onShowRestrictedItemsModal: openRestrictedItemsModal,
  onShowDomesticShippingUnavailableDialog: showDomesticShippingUnavailableDialog,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderError);
