import url from 'url';

import { getContinueShoppingUrl } from '../selectors';
import { redirect } from '../../application/actions';

/**
 * Creates a thunk action responsible for redirecting customer back
 * to the merchant's store.
 * @returns {ThunkAction<void, object, void>}
 */
export default function continueShopping(parameters) {
  return function continueShoppingEffect(dispatch, getState) {
    const continueShoppingUrl = getContinueShoppingUrl(getState());

    if (continueShoppingUrl == null) {
      throw new Error('Continue shopping URL must be defined');
    }

    const parsed = url.parse(continueShoppingUrl, true);

    const formatted = url.format({
      ...parsed,
      query: {
        ...parsed.query,
        ...parameters,
      },
    });

    return dispatch(redirect(formatted));
  };
}
