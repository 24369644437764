import { CheckoutContentSummariesGetCheckoutResponse } from '@flowio/api-internal-sdk';
import { getIsContentFulfilled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyErrorConverter from '../../../utilities/converters/legacy-error-converter';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponse } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponse<CheckoutContentSummariesGetCheckoutResponse> | void>;

type Options = {
  params?: {
    locale?: string,
  },
};

const fetchContentRequest = (): RootAction => ({
  type: actionTypes.FETCH_CONTENT_REQUEST,
});

const fetchContentSuccess = (
  checkoutContentSummary: io.flow.internal.v0.models.CheckoutContentSummary,
): RootAction => ({
  type: actionTypes.FETCH_CONTENT_SUCCESS,
  payload: checkoutContentSummary,
});

const fetchContentFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_CONTENT_FAILURE,
  payload: error,
});

const fetchContent = (
  organizationId: string,
  options: Options = {},
): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (getIsContentFulfilled(getState())) {
    return Promise.resolve();
  }

  dispatch(fetchContentRequest());
  return extra.apiInternal.checkoutContentSummaries.getCheckout({
    organization: organizationId,
    locale: options.params?.locale,
  }).then((response) => {
    switch (response.status) {
      case 200:
        dispatch(fetchContentSuccess(response.body));
        break;
      case 401:
      case 404:
        dispatch(fetchContentFailure(legacyErrorConverter.fromStatusCode(response.status)));
        break;
      default:
        exhaustiveCheck(response);
    }
    return legacyResponseConverter.fromHttpResponse(response);
  });
};

export default fetchContent;
