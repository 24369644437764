import { replace } from 'react-router-redux';
import { getIsOrderSubmitted } from '../selectors';
import { getConfirmationUrlV2 } from '../../navigation/selectors';
import type { ThunkResult } from '../../types';

// Call this function after the order state has been fetched to redirect
// to confirmation page when the order has already been submitted.
export default function redirectSubmittedOrder(): ThunkResult<void> {
  return function redirectSubmittedOrderEffect(dispatch, getState) {
    if (getIsOrderSubmitted(getState())) {
      dispatch(replace(getConfirmationUrlV2(getState())));
    }
  };
}
