import ExtendableError from 'es6-error';

export default class ThreedsError extends ExtendableError {
  constructor(message: string) {
    super('3DS authorization failed');
    this.name = 'ThreedsError';
    this.message = message;
  }

  toJSON(): Omit<ThreedsError, 'toJSON'> {
    return {
      name: 'ThreedsError',
      message: this.message,
      stack: this.stack,
    };
  }
}
