/* global paypal */
/* eslint-disable react/no-find-dom-node */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { getEnvironment } from '../../utilities/session';
import { injectScript } from '../../utilities/script-cache';
import createPayPalPayment from './create-paypal-payment';

const bem = new BemHelper('flowio-paypal-button');

if (process.browser) {
  require('./paypal-button.css'); // eslint-disable-line global-require
}

class PayPalButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handlePayPalScriptError = this.handlePayPalScriptError.bind(this);
    this.handlePayPalScriptLoaded = this.handlePayPalScriptLoaded.bind(this);
  }

  componentDidMount() {
    this.stopObservingScript = injectScript('https://www.paypalobjects.com/api/checkout.min.js', {
      onError: this.handlePayPalScriptError,
      onLoad: this.handlePayPalScriptLoaded,
    });
  }

  componentWillUnmount() {
    this.stopObservingScript();
  }

  handleAuthorize(data, actions) {
    const { onSuccess } = this.props;
    if (data != null && data.payerID != null && data.paymentID != null) {
      onSuccess(data, actions);
    } else {
      // Handle ocassional misbehavior where PayPal invokes the onAuthorize
      // callback without providing any payment data.
      // FIXME: This message should be translated.
      this.triggerError('An error has occurred while attempting to authorize PayPal payment. Please try again.');
    }
  }

  handleCancel(data) {
    const { onCancel } = this.props;
    onCancel(data);
  }

  handleError(error) {
    if (isEmpty(error) || error.code === 'form_error') {
      return;
    }
    // FIXME: This message should be translated.
    this.triggerError('An error has occurred while attempting to create PayPal payment. Please try again.');
  }

  handlePayment(resolve, reject) {
    const {
      total,
      balance,
      isGiftCardsEnabled,
      currency,
      organizationId,
      orderNumber,
      onOrderCheck,
    } = this.props;
    const formErrors = onOrderCheck();
    if (isEmpty(formErrors)) {
      const amount = isGiftCardsEnabled ? balance : total;
      createPayPalPayment(organizationId, orderNumber, amount, currency)
        .then((payment) => {
          resolve(payment.paypal.payment_id);
        })
        .catch((error) => {
          if (error.name === 'PaymentError') {
            this.triggerError(error.response.result.messages.join(' '));
            reject();
            return;
          }
          reject(error);
        });
    } else {
      reject({ code: 'form_error', messages: [] });
    }
  }

  handlePayPalScriptLoaded() {
    this.createButton();
  }

  handlePayPalScriptError() {
    // FIXME: This message should be translated.
    this.triggerError('An error has occurred while attempting to load PayPal. Please try again.');
  }

  triggerError(error) {
    const { onError } = this.props;

    if (isString(error)) {
      onError({ code: 'generic_error', messages: [error] });
    } else {
      onError(error);
    }
  }

  createButton() {
    const { commit } = this.props;
    const element = ReactDOM.findDOMNode(this);
    paypal.Button.render({
      commit,
      env: getEnvironment(),
      style: {
        label: 'checkout',
        size: 'responsive',
        tagline: false,
        shape: 'rect',
        color: 'gold',
      },
      payment: this.handlePayment,
      onAuthorize: this.handleAuthorize,
      onCancel: this.handleCancel,
      onError: this.handleError,
    }, element);
  }

  render() {
    return (
      <div className={bem.block()} />
    );
  }
}

PayPalButton.displayName = 'PayPalButton';

PayPalButton.propTypes = {
  total: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  commit: PropTypes.bool,
  isGiftCardsEnabled: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  onOrderCheck: PropTypes.func,
};

PayPalButton.defaultProps = {
  commit: true,
  onCancel: noop,
  onError: noop,
  onSuccess: noop,
  onOrderCheck: noop,
};

export default PayPalButton;
