import { FormattedMessage } from 'react-intl';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LearnMoreContent from '../learn-more-content';
import { Grid, Column, Row } from '../grid';
import CouponForm from '../../containers/coupon-form';
import ContentItem from '../content-item';
import Divider from '../divider';
import DeliveredDutyMessaging from '../delivered-duties-messaging';
import OrderDeliveryItems from '../order-delivery-items';
import OrderPrices from '../order-prices';
import OrderTotal from '../order-total';
import Section from '../section';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING, LEARN_MORE_CONTENT } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./order-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-summary');

class OrderSummary extends Component {
  handleRequestRemoveDiscount = (promoCode) => {
    const { onRequestRemoveDiscount, orderNumber, organization } = this.props;
    onRequestRemoveDiscount(organization, orderNumber, promoCode);
  }

  render() {
    const {
      discounts,
      forceEnableShopifyDiscounts,
      isConfirmationStep,
      isDiscountClearable,
      isDutyIncluded,
      isDiscountsEnabled,
      isTaxIncluded,
      itemContentsMap,
      isShopifyIntegration,
      onRemoveGiftCard,
      order,
      orderSummaryMessage,
      prices,
      promotionCode,
      isDeliveredDutyPaidMessageEnabled,
    } = this.props;

    const shouldRenderDiscountForm = !isConfirmationStep && (
      isShopifyIntegration || isDiscountsEnabled || forceEnableShopifyDiscounts
    );

    return (
      <>
        <FeatureToggle
          featureKey={MOBILE_UX_SPACING}
          render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
            <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
              <Section.Header divided>
                <Section.Title>
                  <FormattedMessage
                    id="checkout_order_summary_cart_heading"
                    description="A text describing a section of the page giving you information about the collection of items the user has selected"
                    defaultMessage="Your Cart"
                  />
                </Section.Title>
              </Section.Header>
              <Section.Content>
                <Grid>
                  <Row>
                    <Column>
                      <OrderDeliveryItems
                        order={order}
                        itemContentsMap={itemContentsMap}
                        isShopifyIntegration={isShopifyIntegration}
                      />
                      {shouldRenderDiscountForm && (
                        <>
                          <Divider />
                          <CouponForm />
                        </>
                      )}
                      <Divider />
                      <OrderPrices
                        isDiscountClearable={isDiscountClearable}
                        isDutyIncluded={isDutyIncluded}
                        isTaxIncluded={isTaxIncluded}
                        onRequestRemoveDiscount={this.handleRequestRemoveDiscount}
                        prices={prices}
                        discounts={discounts}
                        promotionCode={promotionCode}
                      />
                      <Divider />
                      <OrderTotal
                        isConfirmationStep={isConfirmationStep}
                        order={order}
                        onRemoveCreditPayment={onRemoveGiftCard}
                      />
                      {orderSummaryMessage && (
                        <ContentItem
                          className={bem.element('order-summary-message')}
                          content={orderSummaryMessage}
                        />
                      )}
                    </Column>
                  </Row>
                </Grid>
              </Section.Content>
            </Section>
          )}
        />
        {isDeliveredDutyPaidMessageEnabled && (
          <Section>
            <Grid>
              <Row>
                <Column>
                  <DeliveredDutyMessaging />
                </Column>
              </Row>
            </Grid>
          </Section>
        )}
        <FeatureToggle featureKey={LEARN_MORE_CONTENT}>
          <LearnMoreContent />
        </FeatureToggle>
      </>
    );
  }
}

OrderSummary.displayName = 'OrderSummary';

OrderSummary.propTypes = {
  discounts: PropTypes.arrayOf(ApiPropTypes.orderPriceDetailComponent).isRequired,
  forceEnableShopifyDiscounts: PropTypes.bool,
  isConfirmationStep: PropTypes.bool.isRequired,
  // Whether customer can update order from any control in this component.
  isDiscountClearable: PropTypes.bool,
  isDutyIncluded: PropTypes.bool.isRequired,
  isDiscountsEnabled: PropTypes.bool.isRequired,
  isTaxIncluded: PropTypes.bool.isRequired,
  itemContentsMap: PropTypes.objectOf(ApiPropTypes.checkoutItemContent),
  isShopifyIntegration: PropTypes.bool.isRequired,
  orderSummaryMessage: ApiInternalPropTypes.label,
  onRequestRemoveDiscount: PropTypes.func.isRequired,
  order: ApiPropTypes.order.isRequired,
  orderNumber: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  prices: PropTypes.arrayOf(ApiPropTypes.orderPriceDetail).isRequired,
  promotionCode: PropTypes.string,
  onRemoveGiftCard: PropTypes.func.isRequired,
  isDeliveredDutyPaidMessageEnabled: PropTypes.bool.isRequired,
};

OrderSummary.defaultProps = {
  forceEnableShopifyDiscounts: false,
  isDiscountClearable: true,
  orderSummaryMessage: undefined,
  promotionCode: undefined,
  itemContentsMap: {},
};

export default OrderSummary;
