const OrderErrorTypes = {
  AUTHORIZATION_INVALID: 'authorization_invalid',
  DOMESTIC_SHIPPING_UNAVAILABLE: 'domestic_shipping_unavailable',
  GENERIC: 'generic_error',
  // manually created when attempting to submit an order with a pending balance...
  ORDER_BALANCE: 'order_balance_error',
  ORDER_ITEM_NOT_AVAILABLE: 'order_item_not_available',
  ORDER_IDENTIFIER: 'order_identifier_error',
  SHIPPING_UNAVAILABLE: 'shipping_unavailable',
  VALUE_THRESHOLD_EXCEEDED: 'value_threshold_exceeded',
  VRN_VALIDATION_ERROR: 'vrn_validation_error',
};

export default OrderErrorTypes;
