import type { CheckoutSubmitOrderBundlesPutResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CheckoutSubmitOrderBundlesPutResponse>>;

type Money = {
  amount: number;
  currency: string;
}

const submitOrderBundleRequest = (): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_BUNDLE_REQUEST,
});

const submitOrderBundleSuccess = (
  checkoutSubmitOrderBundle: io.flow.internal.v0.models.CheckoutSubmitOrderBundle,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_BUNDLE_SUCCESS,
  payload: checkoutSubmitOrderBundle,
});

const submitOrderBundleFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_BUNDLE_FAILURE,
  payload: error,
});

const submitOrderBundle = (
  organizationId: string,
  orderNumber: string,
  authorizationForm?: io.flow.v0.unions.AuthorizationForm,
  total?: Money,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  let checkoutSubmitOrderForm: io.flow.internal.v0.models.CheckoutSubmitOrderForm = {
    discriminator: 'checkout_submit_order_form',
  };

  if (authorizationForm != null) {
    checkoutSubmitOrderForm = {
      ...checkoutSubmitOrderForm,
      authorization_form: authorizationForm,
    };
  }

  if (total != null) {
    checkoutSubmitOrderForm = {
      ...checkoutSubmitOrderForm,
      expected_order_summary: {
        total: {
          amount: total.amount,
          currency: total.currency,
        },
      },
    };
  }

  dispatch(submitOrderBundleRequest());

  return extra.apiInternal.checkoutSubmitOrderBundles.put({
    body: checkoutSubmitOrderForm,
    number: orderNumber,
    organization_id: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(submitOrderBundleSuccess(response.result));
          break;
        case 401:
        case 404:
          dispatch(submitOrderBundleFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default submitOrderBundle;
