/* eslint-disable react/jsx-props-no-spreading */

import { FormattedMessage } from 'react-intl';
import React from 'react';
import apiPropTypes from '@flowio/api-prop-types';

import AuthorizationDeclineCodes from '../../constants/authorization-decline-codes';
import Message from '../message';

const AuthorizationDeclinedStatusError = ({ declineCode, ...unhandledProps }) => (
  <Message {...unhandledProps} color="negative">
    <Message.Text>
      {(() => {
        switch (declineCode) {
          case AuthorizationDeclineCodes.Avs:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_avs"
                description="Error message displayed when a card is declined because of an invalid billing address"
                defaultMessage="Your card was declined because of an invalid billing address. Please update your card and try again."
              />
            );
          case AuthorizationDeclineCodes.Cvv:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_cvv"
                description="Error message displayed when a card is declined because of an invalid card verification value (CVV)"
                defaultMessage="Your card was declined because of an invalid card verification value (CVV). Please update your CVV and try again."
              />
            );
          case AuthorizationDeclineCodes.Duplicate:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_duplicate"
                description="Error message displayed when a card is declined because the transaction is suspected of being a duplicate"
                defaultMessage="Your payment has been declined because it is suspected to be a duplicate. Please try a different payment method."
              />
            );
          case AuthorizationDeclineCodes.Expired:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_expired"
                description="Error message displayed when a card is declined because it is expired."
                defaultMessage="Your card was declined because it is expired. Please update your expiration date or try another card."
              />
            );
          case AuthorizationDeclineCodes.Fraud:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_fraud"
                description="Error message displayed when a card transation is suspected to be fraudulent"
                defaultMessage="Your payment has been declined. Please try a different payment method."
              />
            );
          case AuthorizationDeclineCodes.InvalidExpiration:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_invalid_expiration"
                description="Error message displayed when a card has an invalid expiration date."
                defaultMessage="Your card was declined because it is expired. Please update your expiration date or try another card."
              />
            );
          case AuthorizationDeclineCodes.InvalidName:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_invalid_name"
                description="Error message displayed when a card has an invalid cardholder name."
                defaultMessage="Your card was declined because the name you entered does not match your name on file with the bank. Please update your name or try another card."
              />
            );
          case AuthorizationDeclineCodes.InvalidNumber:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_invalid_number"
                description="Error message displayed when a card has an invlid card number."
                defaultMessage="Invalid card number. Please update your card number and try again."
              />
            );
          case AuthorizationDeclineCodes.NoAccount:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_no_account"
                description="Error message displayed when the payment method is not known by the issuing bank. (the account does not exist)"
                defaultMessage="Your card could not be verified by the issuing bank. Please try a different payment method."
              />
            );
          case AuthorizationDeclineCodes.NotSupported:
            return (
              <FormattedMessage
                id="checkout_authorization_decline_message_code_not_supported"
                description="Error message displayed when the payment method is not supported by the issuing bank."
                defaultMessage="This payment type is not supported. Please try a different payment method."
              />
            );
          case AuthorizationDeclineCodes.Unknown:
          default:
          // TODO: This message should be localized!
            return 'Your payment has been declined. Please try a different payment method.';
        }
      })()}
    </Message.Text>
  </Message>
);

AuthorizationDeclinedStatusError.displayName = 'AuthorizationDeclinedStatusError';

AuthorizationDeclinedStatusError.propTypes = {
  declineCode: apiPropTypes.authorizationDeclineCode.isRequired,
};

export default AuthorizationDeclinedStatusError;
