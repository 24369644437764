import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import TierEstimateType from '../constants/tier-estimate-type';
import getTierEstimateType from './get-tier-estimate-type';

export default function getFormattedDeliveryOptionLabel(deliveryOption) {
  const tierName = get(deliveryOption, 'tier.name');
  const tierEstimateType = getTierEstimateType(deliveryOption);
  const deliveryWindowLabel = (tierEstimateType === TierEstimateType.Custom)
    ? get(deliveryOption, 'tier.display.estimate.label')
    : get(deliveryOption, 'window.label');
  return !(isUndefined(deliveryWindowLabel) || isEmpty(deliveryWindowLabel))
    ? `${tierName} (${deliveryWindowLabel})`
    : tierName;
}
