import { Plugin } from '@flowio/browser-metrics';

import { getSession } from '../../session';
import events from '../../events';

const sessionPlugin: Plugin = ({
  addVariable,
  sendBeacon,
}) => {
  let isComplete = false;

  events.on('afterBootstrap', () => {
    try {
      const session = getSession();

      if (session != null) {
        addVariable('organization', session.organization);
        addVariable('visitorId', session.visitor.id);

        if (session.geo != null) {
          addVariable('countryCode', session.geo.country.iso_3166_3);
        }
      }
    } catch (error) {
      // Do nothing
    }

    isComplete = true;
    sendBeacon();
  });

  return {
    // Blocks beacon request until this plugin is complete to ensure an
    // organization, visitor identifier, and country code is present on
    // every beacon.
    beaconShouldSendData(): boolean {
      return isComplete;
    },
  };
};

export default sessionPlugin;
