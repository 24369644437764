import type { CustomerAddressBookContactsDeleteByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RemoveAddressBookContactActionMetadata } from '../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CustomerAddressBookContactsDeleteByIdResponse>>;

const removeAddressBookContactRequest = (
  metadata: RemoveAddressBookContactActionMetadata,
): RootAction => ({
  type: actionTypes.REMOVE_ADDRESS_BOOK_CONTACT_REQUEST,
  meta: metadata,
});

const removeAddressBookContactSuccess = (
  metadata: RemoveAddressBookContactActionMetadata,
): RootAction => ({
  type: actionTypes.REMOVE_ADDRESS_BOOK_CONTACT_SUCCESS,
  meta: metadata,
});

const removeAddressBookContactFailure = (
  metadata: RemoveAddressBookContactActionMetadata,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.REMOVE_ADDRESS_BOOK_CONTACT_FAILURE,
  meta: metadata,
  payload: error,
});

const removeAddressBookContact = (
  organizationId: string,
  customerNumber: string,
  contactId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const metadata: RemoveAddressBookContactActionMetadata = {
    contactId,
    customerNumber,
    organizationId,
  };

  dispatch(removeAddressBookContactRequest(metadata));

  return extra.api.customerAddressBookContacts.deleteById({
    id: contactId,
    number: customerNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 204:
          dispatch(removeAddressBookContactSuccess(metadata));
          break;
        case 401:
        case 404:
          dispatch(removeAddressBookContactFailure(metadata, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }

      return response;
    });
};

export default removeAddressBookContact;
