import PropTypes from 'prop-types';
import React from 'react';
import addClass from '@flowio/browser-helpers/lib/addClass';
import removeClass from '@flowio/browser-helpers/lib/removeClass';

if (process.browser) {
  require('./auto-lock-scrolling.css'); // eslint-disable-line global-require
}

function lockScrolling() {
  addClass(document.body, 'no-scrolling');
}

function unlockScrolling() {
  removeClass(document.body, 'no-scrolling');
}

class AutoLockScrolling extends React.Component {
  componentDidMount() {
    const { isLocked } = this.props;
    if (isLocked) {
      lockScrolling();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isLocked } = this.props;
    if (isLocked !== nextProps.isLocked) {
      if (nextProps.isLocked) {
        lockScrolling();
      } else {
        unlockScrolling();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    unlockScrolling();
  }

  render() {
    return null;
  }
}

AutoLockScrolling.displayName = 'AutoLockScrolling';

AutoLockScrolling.propTypes = {
  isLocked: PropTypes.bool,
};

AutoLockScrolling.defaultProps = {
  isLocked: false,
};

export default AutoLockScrolling;
