import keyBy from 'lodash/keyBy';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AuthorizationState } from '../types';
import type { AppReducer } from '../../types';

const initialState: AuthorizationState = {
  // TODO: Shouldn't this just be undefined or null initially?
  klarnaToken: '',
};

const updateKlarnaToken = (
  previousState: AuthorizationState,
  authorization: io.flow.v0.unions.Authorization,
): AuthorizationState => {
  if (authorization.discriminator === 'online_authorization') {
    if (authorization.details != null && authorization.details.discriminator === 'inline_authorization_details') {
      // TODO: Prior to this refactor, the logic used to simply apply any
      // client token that came in from an inline authorization detail, but
      // couldn't that be the client token for a different type of payment?
      if (authorization.details.client_token != null) {
        return update(previousState, {
          klarnaToken: {
            $set: authorization.details.client_token,
          },
        });
      }
    }
  }

  return previousState;
};

const authorizationsReducer: AppReducer<AuthorizationState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CREATE_AUTHORIZATION_SUCCESS:
    case actionTypes.FETCH_AUTHORIZATION_SUCCESS:
      return update(previousState, {
        $merge: {
          [action.payload.id]: action.payload,
        },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        $merge: keyBy(action.payload.payment.authorizations, (_) => _.id),
      });
    case actionTypes.SUBMIT_AUTHORIZATION_SUCCESS:
      // TODO: Prior to this refactor, the authorization in the action was not
      // added to the index, shouldn't it be added?
      return updateKlarnaToken(previousState, action.payload);
    default:
      return previousState;
  }
};

export default authorizationsReducer;
