import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { getLoyaltyRemoveError, getOrderNumber } from '../store/checkout/selectors';
import { getOrganization } from '../store/flow/selectors';
import FormName from '../constants/form-name';
import { LoyaltyForm } from '../components/rewards/forms';
import {
  fetchOrder,
  removeLoyaltyReward,
  submitLoyaltyRewards,
} from '../store/checkout/actions';
import checkHttpStatus from '../utilities/check-http-status';

function handleSubmit(values, dispatch) {
  return dispatch(submitLoyaltyRewards(values));
}

function handleRemoveLoyalty(rewardsId) {
  return function handleRemoveLoyaltySideEffects(dispatch, getState) {
    const state = getState();
    const organization = getOrganization(state);
    const orderNumber = getOrderNumber(state);

    dispatch(removeLoyaltyReward(organization, orderNumber, rewardsId))
      .then(checkHttpStatus)
      .then(() => dispatch(fetchOrder(organization, orderNumber)))
      .then(checkHttpStatus)
      .catch((error) => {
        if (error.response) return error.response;
        throw error;
      });
  };
}

const mapStateToProps = createStructuredSelector({
  removeError: getLoyaltyRemoveError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRemoveLoyalty: handleRemoveLoyalty,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FormName.LOYALTY_FORM,
    onSubmit: handleSubmit,
  }),
)(LoyaltyForm);
