import get from 'lodash/get';

import { toOrderPutForm } from '../utilities/order-converters';

/**
 * A helpers that takes the current order state and returns an order put form
 * copying over data collected during the checkout process to order fields that
 * are required but not entered by customers.
 * @param {Order} previousOrder
 * @returns {OrderPutForm}
 */
export default function patchOrderPutForm(previousOrder) {
  const nextOrder = {
    ...previousOrder,
    customer: {
      ...get(previousOrder, 'customer'),
      name: get(previousOrder, 'destination.contact.name'),
      phone: get(previousOrder, 'destination.contact.phone'),
    },
    destination: {
      ...get(previousOrder, 'destination'),
      contact: {
        ...get(previousOrder, 'destination.contact'),
        email: get(previousOrder, 'customer.email'),
      },
    },
  };
  const orderPutForm = toOrderPutForm(nextOrder);
  return orderPutForm;
}
