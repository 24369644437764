import { BaseFieldProps, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import ReduxFormTextField, {
  BaseProps as ReduxFormTextFieldBaseProps,
} from '../redux-form/text-field';

type Props = {
  addressType?: AddressType;
  children?: never;
  required?: boolean;
};

const LastName: React.FunctionComponent<Props> = ({
  addressType,
  required = false,
}) => (
  <Field<BaseFieldProps<ReduxFormTextFieldBaseProps>>
    component={ReduxFormTextField}
    props={{
      autoComplete: autoCompleteToken('family-name', addressType),
      automationId: automationToken('family-name', addressType),
      labelText: (
        <FormattedMessage
          id="checkout_address_field_label_last_name"
          description="Content for last name field label in address forms"
          defaultMessage="Last Name"
        />
      ),
      required,
    }}
    name="lastName"
  />
);

export default LastName;
