// @ts-check

/**
 * @typedef {io.flow.internal.v0.models.CheckoutSubmission} CheckoutSubmission
 */

/**
 * @typedef {import('../../common/services/types').Response<T>} Response
 * @template T
 */

/**
* @param {Response<CheckoutSubmission>} response
*/
export default function CheckoutSubmissionError(response) {
  const error = new Error('CheckoutSubmission response contained errors');
  error.name = 'CheckoutSubmissionError';
  // @ts-ignore
  error.response = response;
  return error;
}
