import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';

import Section from '../section';
import { translatePaymentMethodName } from '../../utilities/hacks';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./payment-method-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-method-summary');

const hasMethodName = (authorization) => !!get(authorization, 'method.name');

const getAuthorizationMethodNames = (authorizations) => map(
  filter(authorizations, hasMethodName), (authorization) => ({
    id: get(authorization, 'id'),
    name: get(authorization, 'method.name'),
  }),
);

const PaymentMethodSummary = ({ authorizations, orderPayments }) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
      <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
        <Section.Header>
          <Section.Title>
            <FormattedMessage
              id="checkout_payment_method_subheading"
              defaultMessage="Payment Method"
              description="Title for the section containing a summary of the payment methods in the order"
            />
          </Section.Title>
        </Section.Header>
        <Section.Content>
          <div className={bem.element('content')}>
            <ul className={bem.element('list')}>
              {map(orderPayments, (orderPayment) => (
                <li className={bem.element('item')} key={orderPayment.id}>
                  {orderPayment.description}
                </li>
              ))}
              {!orderPayments.length
                && map(getAuthorizationMethodNames(authorizations), ({ id, name }) => (
                  <li className={bem.element('item')} key={id}>
                    {translatePaymentMethodName(name)}
                  </li>
                ))}
            </ul>
          </div>
        </Section.Content>
      </Section>
    )}
  />
);

PaymentMethodSummary.displayName = 'PaymentMethodSummary';

PaymentMethodSummary.propTypes = {
  authorizations: PropTypes.arrayOf(ApiPropTypes.authorization),
  orderPayments: PropTypes.arrayOf(ApiPropTypes.orderPayment).isRequired,
};

PaymentMethodSummary.defaultProps = {
  authorizations: [],
};

export default PaymentMethodSummary;
