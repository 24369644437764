/**
 * @see https://app.apibuilder.io/flow/content-internal/latest#enum-content_element_type
 */
const ContentElementType = {
  MARKDOWN: 'markdown',
  HREF: 'href',
  HTML: 'html',
  PLAIN_TEXT: 'plain_text',
};

export default ContentElementType;
