import { Pixel } from '@flowio/pixel';
import ExponentialBackoff from '../exponential-backoff';

export default function requestPixelReadyCallback(
  pixel: Pixel,
  callback: () => void,
): void {
  if (typeof pixel.requestReadyCallback === 'function') {
    pixel.requestReadyCallback(callback);
    return;
  }

  const backoff = new ExponentialBackoff({
    delay: 200,
  });

  // The version of Pixel prior to Shopify Connect version 0.3.57 does not
  // support the "requestReadyCallback". We need to fallback in case the
  // application is rolled back to an incompatible version.
  function checkReadyState(): void {
    if (pixel.readyState === 'complete') {
      callback();
    } else {
      setTimeout(checkReadyState, backoff.next());
    }
  }

  checkReadyState();
}
