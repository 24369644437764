import { resetSection, SubmissionError } from 'redux-form';
import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

import { getOrderNumber } from '../selectors';
import { getOrganization } from '../../flow/selectors';
import addGiftCardV2 from './add-gift-card-v2';
// import fetchOrder from './fetch-order';
import FormName from '../../../constants/form-name';
import checkHttpStatus from '../../../utilities/check-http-status';
import { showLoadingIndicator, hideLoadingIndicator } from '../../application/actions';
import { GiftCardV2FormData } from '../../../components/gift-card-v2-form/gift-card-v2-form';
import { RootState, RootActionTypes } from '../../types';
import updatePaymentMethods from './update-payment-methods';

export default function submitGiftCardV2(
  values: GiftCardV2FormData,
): ThunkAction<Promise<void>, RootState, void, RootActionTypes> {
  return function submitGiftCardSideEffects(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    getState,
  ): Promise<void> {
    const state = getState();
    const orderNumber = getOrderNumber(state);
    const organization = getOrganization(state);

    dispatch(showLoadingIndicator());
    return dispatch(addGiftCardV2(organization, orderNumber, values))
      .then(checkHttpStatus)
      .then(() => dispatch(updatePaymentMethods(organization)))
      .then(() => dispatch(resetSection(FormName.PAYMENT_INFORMATION, 'giftCard')))
      .then(() => {
        dispatch(hideLoadingIndicator());
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: Error | any) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          default:
            throw new SubmissionError({
              _error: error,
            });
        }
      });
  };
}
