import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.internal.v0.models.AuthorizationParameters[];

const initialState: State = [];

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_PARAMS_SUCCESS:
      return update(previousState, {
        $set: action.payload,
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        $set: action.payload.payment.authorization_parameters,
      });
    default:
      return previousState;
  }
};

export default reducer;
