import { api } from '../../utilities/clients';
import PaymentError from '../../utilities/payment-error';

export default function createPayPalPayment(organizationId, orderNumber, amount, currency) {
  return api.payments().post(organizationId, {
    body: JSON.stringify({
      discriminator: 'merchant_of_record_payment_form',
      method: 'paypal',
      order_number: orderNumber,
      amount,
      currency,
    }),
  })
    .then((response) => {
      switch (response.status) {
        case 201:
          return response.result;
        case 422:
          if (response.result.code === 'payment_error') {
            throw new PaymentError(response);
          }
          throw new Error(response.result.messages.join(', '));
        default:
          throw new Error('Unknown error while attempting to create PayPal payment. Please try again.');
      }
    });
}
