/* eslint-disable react/jsx-props-no-spreading */

import { FormattedMessage } from 'react-intl';
import React from 'react';

import Message from '../message';

const AuthorizationReversedStatusError = ({ ...unhandledProps }) => (
  <Message {...unhandledProps} color="negative">
    <Message.Text>
      <FormattedMessage
        id="checkout_authorization_decline_message_code_reversed"
        description="Error message displayed when the payment is canceled (with authorization status 'reversed'). For example, trying to pay with a redirect-based APM and clicking 'Back to merchant' will cancel an authorization."
        defaultMessage="Your payment has been canceled. Please try a different payment method."
      />
    </Message.Text>
  </Message>
);

AuthorizationReversedStatusError.displayName = 'AuthorizationReversedStatusError';

export default AuthorizationReversedStatusError;
