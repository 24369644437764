import { Position } from './position';

const attachments = {
  [Position.TopLeft]: 'left bottom',
  [Position.Top]: 'bottom',
  [Position.TopRight]: 'right bottom',
  [Position.RightTop]: 'left top',
  [Position.Right]: 'left',
  [Position.RightBottom]: 'left bottom',
  [Position.BottomRight]: 'right top',
  [Position.Bottom]: 'top',
  [Position.BottomLeft]: 'left top',
  [Position.LeftBottom]: 'right bottom',
  [Position.Left]: 'right',
  [Position.LeftTop]: 'right top',
};

export default function getPopoverAttachment(
  position: Position,
): string {
  return attachments[position];
}
