/* eslint-disable react/jsx-props-no-spreading */

import ApiPropTypes from '@flowio/api-prop-types';
import React from 'react';
import map from 'lodash/map';

import Message from '../message';

const CardError = ({ error, ...unhandledProps }) => (
  <Message {...unhandledProps} data-automation-id="card-error" color="negative">
    {map(error.messages, (message) => (
      <Message.Text key={message}>{message}</Message.Text>
    ))}
  </Message>
);

CardError.displayName = 'CardError';

CardError.propTypes = {
  error: ApiPropTypes.cardError.isRequired,
};

export default CardError;
