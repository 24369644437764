import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.v0.models.CheckoutItemContent[];

const initialState: State = [];

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_ITEM_CONTENT_SUCCESS:
      return update(previousState, {
        $set: action.payload,
      });
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return update(previousState, {
        $set: action.payload.checkout_items,
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        $set: action.payload.content.items,
      });
    default:
      return previousState;
  }
};

export default reducer;
