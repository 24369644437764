// @ts-check
import get from 'lodash/get';

import checkHttpStatus from '../../../utilities/check-http-status';
import checkCheckoutSubmissionStatus from '../../../utilities/check-checkout-submission-status';
import submitOrderCheckouts from './submit-order-checkouts';
import { authorizeRedirect } from './index';
import handleAuthResultActionDetail from '../../../utilities/helpers/handle-auth-result-action-detail';
import { fetchAuthorization } from '..';
import { trackHeapEvent } from '../../../utilities/heap';

/**
 * @typedef {io.flow.internal.v0.models.CheckoutSubmission} CheckoutSubmission
 */

/**
 * @typedef {io.flow.v0.models.LocalizedTotal} LocalizedTotal
 */

/**
 * @typedef {io.flow.v0.unions.AuthorizationForm} AuthorizationForm
 */

/**
 * @typedef {import('../../../../common/services/types').Response<T>} Response
 * @template T
 */

/**
 * @typedef {io.flow.v0.unions.AuthorizationResultAction} AuthorizationResultAction
 */

/**
 * @typedef {import('redux').Action} Action
 */

/**
 * @typedef {import('redux').Dispatch<Action>} Dispatch
 */

/**
  * @param {Dispatch} dispatch
  * @param {string} organizationId
  * @param {string} checkoutId
  * @param {string} orderNumber
  * @param {AuthorizationForm} authorizationForm
  * @param {LocalizedTotal} total
  * @param {string} locale
  */
export default function submitCheckoutsPayment(
  dispatch,
  organizationId,
  checkoutId,
  orderNumber,
  authorizationForm,
  total,
  locale = 'en',
) {
  return dispatch(submitOrderCheckouts(
    checkoutId,
    authorizationForm,
    total,
  ))
    .then(checkHttpStatus)
    .then(checkCheckoutSubmissionStatus)
    .then(
      /** @type {(response: Response<CheckoutSubmission>) => Promise<any>} */
      (response) => {
        const checkoutSubmitOrderBundle = response.result;
        /** @type {AuthorizationResultAction | undefined} */
        const authorizationResultAction = checkoutSubmitOrderBundle.action;

        if (authorizationResultAction == null) {
          return Promise.resolve();
        }

        if (authorizationResultAction.type === 'native') {
          const authorizationResultActionDetail = get(authorizationResultAction, 'details');
          const authKey = get(authorizationResultAction, 'authorization.key');
          const handleAuthResultActionDetailPromise = handleAuthResultActionDetail(
            authorizationResultActionDetail,
            organizationId,
            authKey,
            dispatch,
            locale,
          );

          /* PMNT-967: Add tracking for when a user is still in a pending state after authorization
          actions are theoretically handled. */
          handleAuthResultActionDetailPromise.then(() => {
            dispatch(fetchAuthorization(organizationId, authKey)).then((resp) => {
              if (resp.ok && resp.result.result.status === 'pending') {
                trackHeapEvent('payment_authorization_progress', {
                  organization_id: organizationId,
                  authorization_key: authKey,
                  authorization_result_status: resp.result.result.status,
                  authorization_result_decline_code: resp.result.result.decline_code,
                  authorization_result_action_type: get(resp.result.result, ['action', 'discriminator']),
                  authorization_result_action_detail: get(resp.result.result, ['action', 'details', 'discriminator']),
                  authorization_summary: 'pending_after_actions_handled',
                });
              }
            });
          });

          return handleAuthResultActionDetailPromise;
        }

        // Right now the only action we have is a redirect. Even the stripe 3ds 2.0 stuff comes back as a redirect technically.
        // There will be another type of action added in the future with the Adyen 3ds work
        return authorizeRedirect(dispatch, authorizationResultAction);
      },
    );
}
