import { BaseFieldProps, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import ReduxFormTextField, {
  BaseProps as ReduxFormTextFieldBaseProps,
} from '../redux-form/text-field';

type Props = {
  addressType?: AddressType;
  required?: boolean;
};

const FirstName: React.FunctionComponent<Props> = ({
  addressType,
  required = false,
}) => (
  <Field<BaseFieldProps<ReduxFormTextFieldBaseProps>>
    component={ReduxFormTextField}
    props={{
      autoComplete: autoCompleteToken('given-name', addressType),
      automationId: automationToken('given-name', addressType),
      labelText: (
        <FormattedMessage
          id="checkout_address_field_label_first_name"
          description="Content for first name field label in address forms"
          defaultMessage="First Name"
        />
      ),
      required,
    }}
    name="firstName"
  />
);

export default FirstName;
