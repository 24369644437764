import assign from 'lodash/assign';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

export const createError = (missingRequiredOptIns) => {
  const error = new Error(`OptInAttributeError: The following opt ins are required: ${missingRequiredOptIns.join(', ')}`);
  error.name = 'OptInAttributeError';
  error.requiredOptIns = reduce(missingRequiredOptIns, (result, optInName) => assign(result, {
    [optInName]: 'This field is required.',
  }), {});
  return error;
};

export const isAccepted = (value) => value === true || value === 'true';

/**
 *
 * @param {PaymentFormValues} values
 * @param {Checkbox[]} marketingOptIns
 */
export const validateRequiredOptIns = (values, marketingOptIns) => (
  new Promise((resolve, reject) => {
    const missingRequiredOptIns = reduce(marketingOptIns, (missing, marketingOptIn) => {
      const value = get(values, marketingOptIn.name);

      if (marketingOptIn.required && !isAccepted(value)) {
        missing.push(marketingOptIn.name);
      }

      return missing;
    }, []);

    if (missingRequiredOptIns.length) {
      return reject(createError(missingRequiredOptIns));
    }

    return resolve();
  })
);

export const getOptInsWithValues = (values, marketingOptIns) => {
  const optInsWithValues = map(marketingOptIns, (optIn) => {
    const optInName = optIn.name;
    const value = values[optInName];

    if (value) {
      return { [optInName]: value };
    }

    return { [optInName]: optIn.value };
  });

  return reduce(optInsWithValues, (result, optIn) => assign(result, optIn), {});
};
