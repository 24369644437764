/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const InfoCircle = ({ ...unhandledProps }) => (
  <SvgIcon {...unhandledProps} viewBox="0 0 16 16">
    <g strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-4.000000, -4.000000)" fillRule="nonzero" fill="#BFBFBF">
        <path d="M20,12.0000002 C20,16.4182783 16.418278,20.0000003 12,20.0000003 C7.581722,20.0000003 4,16.4182783 4,12.0000002 C4,7.58172208 7.581722,4 12,4 C16.418278,4 20,7.58172208 20,12.0000002 L20,12.0000002 Z M13.0194684,6.6965087 C12.7146239,6.69651936 12.4560088,6.80396292 12.2436221,7.01884114 C12.0312301,7.23373892 11.9250355,7.4936038 11.925038,7.7984358 C11.9250355,8.10328558 12.0324796,8.36315047 12.2473702,8.57803047 C12.4622554,8.79292647 12.7196213,8.90037002 13.0194684,8.90036291 C13.3243061,8.90037002 13.5841706,8.79292647 13.7990627,8.57803047 C14.0139464,8.36315047 14.1213905,8.10328558 14.1213949,7.7984358 C14.1213905,7.4936038 14.0151958,7.23373892 13.8028108,7.01884114 C13.5904173,6.80396292 13.3293034,6.69651936 13.0194684,6.6965087 Z M13.4842266,10.1447149 L13.0344605,10.1447202 L10.493283,10.5570047 L10.3958337,10.85685 C10.5007781,10.8518545 10.5907311,10.8493549 10.6656933,10.8493478 C10.8306062,10.8493549 10.9605384,10.8918313 11.0554904,10.9767825 C11.1504394,11.0617442 11.1979146,11.1591931 11.1979162,11.2691291 C11.1979146,11.4440447 11.1479407,11.7039149 11.0479942,12.0487327 L10.0060366,15.6318616 C9.92108029,15.9167149 9.87860242,16.1515949 9.87860278,16.3365015 C9.87860242,16.6163415 9.97480242,16.8474704 10.1672026,17.0298882 C10.3596017,17.2122882 10.6182168,17.3034882 10.9430488,17.3034882 C11.7926046,17.3034882 12.5946865,16.6463327 13.3492968,15.3320216 L13.0944294,15.1821016 C12.77959,15.6668482 12.5097308,16.0016749 12.2848506,16.1865638 C12.1998922,16.2615327 12.1149366,16.2990082 12.0299832,16.2990082 C11.9800069,16.2990082 11.9325317,16.2752749 11.8875574,16.2277904 C11.8425786,16.1803238 11.8200902,16.129106 11.8200926,16.0741371 C11.8200902,15.979186 11.8625681,15.7792927 11.9475262,15.4744393 L13.4842266,10.1447149 Z" />
      </g>
    </g>
  </SvgIcon>
);

InfoCircle.displayName = 'InfoCircle';

export default InfoCircle;
