import { BaseFieldProps, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import noop from 'lodash/noop';

import './billing-address.css';

import { MOBILE_UX_SPACING, RETURNING_CUSTOMERS } from '../../../common/constants/feature-keys';
import AddressPicker, { OwnProps as AddressPickerOwnProps } from '../address-picker';
import { AddressType } from '../../constants/address-type';
import { Grid, Row, Column } from '../grid';
import FeatureToggle from '../feature-toggle';
import Address from '../../containers/address';
import RadioPanelGroup from '../redux-form/radio-panel-group';
import RadioPanel from '../radio-panel';
import Section from '../section';

const bem = new BemHelper('billing-address');

interface BillingAddressProps {
  addressBook?: io.flow.v0.models.CustomerAddressBook;
  addressConfiguration: io.flow.v0.models.AddressConfiguration;
  countries: io.flow.v0.models.Country[];
  countryCode: string;
  locale: string;
  onAutoCompleteAddress: (place: unknown, addressType?: AddressType) => void;
  onPostalAutoComplete: (address: io.flow.v0.models.Address) => void;
  returningCustomer?: io.flow.v0.models.Customer;
}

const BillingAddress: React.FunctionComponent<BillingAddressProps> = ({
  addressBook,
  addressConfiguration,
  countries,
  countryCode,
  locale,
  onAutoCompleteAddress,
  onPostalAutoComplete,
  returningCustomer,
}) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({
      isFeatureEnabled: isMobileUXSpacingEnabled,
    }): React.ReactElement => (
      <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
        <Section.Header>
          <Section.Title>
            <FormattedMessage
              id="checkout_billing_address_heading"
              description="A message used as the subheading for the billing address section"
              defaultMessage="Billing Address"
            />
          </Section.Title>
        </Section.Header>
        <Section.Content>
          <Grid parent={isMobileUXSpacingEnabled}>
            <Row>
              <Column xs={12}>
                <FeatureToggle
                  featureKey={RETURNING_CUSTOMERS}
                  render={({
                    isFeatureEnabled: isReturningCustomersEnabled,
                  }): React.ReactElement => {
                    if (isReturningCustomersEnabled
                      && returningCustomer != null
                      && addressBook != null) {
                      return (
                        <Field<BaseFieldProps<AddressPickerOwnProps>>
                          component={AddressPicker}
                          name="addressToUse"
                          props={{
                            addressBook,
                            addressConfiguration,
                            addressType: AddressType.BILLING,
                            countries,
                            customer: returningCustomer,
                            onAutoCompleteAddress,
                            selectedCountryCode: countryCode,
                          }}
                        />
                      );
                    }

                    return (
                      <Field component={RadioPanelGroup} name="addressToUse">
                        <RadioPanel
                          name="addressToUse"
                          value="sameAsShippingAddress"
                          label={(
                            <FormattedMessage
                              id="checkout_invoice_address_use_shipping_address"
                              description="A message used for the action to be selected when customers intent to use the provided shipping address as the billing address"
                              defaultMessage="Same As Shipping Address"
                            />
                          )}
                        />
                        <RadioPanel
                          name="addressToUse"
                          value="newAddress"
                          label={(
                            <FormattedMessage
                              id="checkout_invoice_address_use_different_address"
                              description="A message used for the action to be selected when customers intent to use a invoice address that is different than the provided shipping address"
                              defaultMessage="Use A Different Address"
                            />
                          )}
                        >
                          <Address
                            addressType={AddressType.BILLING}
                            addressConfiguration={addressConfiguration}
                            countries={countries}
                            countryCode={countryCode}
                            onAutoCompleteAddress={onAutoCompleteAddress}
                            onPostalAutoComplete={onPostalAutoComplete}
                            locale={locale}
                            showSaveAddress={isReturningCustomersEnabled
                              && returningCustomer != null}
                          />
                        </RadioPanel>
                      </Field>
                    );
                  }}
                />
              </Column>
            </Row>
          </Grid>
        </Section.Content>
      </Section>
    )}
  />
);

BillingAddress.displayName = 'BillingAddress';

BillingAddress.defaultProps = {
  onAutoCompleteAddress: noop,
};

export default BillingAddress;
