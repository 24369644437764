// Enums for Flow payment method types:
// https://docs.flow.io/type/payment-method-type

const PaymentMethodTypes = {
  // Represents all form of card payment (e.g. credit, debit, etc.)
  Card: 'card',
  // Represents the most common form of alternative payment methods which require some degree of
  // integration online (e.g. a redirect) to complete payment.
  Online: 'online',
  // Offline payment method types represent payments like Cash On Delivery which require
  // offline collection
  Offline: 'offline',
};

export default PaymentMethodTypes;
