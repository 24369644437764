/* eslint-disable react/prop-types */

import { LocationAction } from 'react-router-redux';
import React, { useEffect } from 'react';

interface Props {
  checkoutId?: string;
  orderNumber?: string;
  organizationId?: string;
  replace: LocationAction;
}

const CheckoutToken: React.FunctionComponent<Props> = ({
  checkoutId,
  orderNumber,
  organizationId,
  replace,
}): React.ReactElement => {
  useEffect((): void => {
    if (checkoutId != null) {
      replace(`/checkouts/${checkoutId}/contact-info`);
    } else if (orderNumber != null && organizationId != null) {
      replace(`/${organizationId}/checkout/${orderNumber}/contact-info`);
    } else {
      replace(`/${organizationId}/error-pages/500`);
    }
  }, []);

  return (<div />);
};

CheckoutToken.displayName = 'CheckoutToken';

export default CheckoutToken;
