import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import uniqueId from 'lodash/uniqueId';
import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

type Parameters = {
  organization: string;
  selections: string[];
};

const updateShippingMethodRequest = (
  parameters: Parameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_METHOD_REQUEST,
  params: parameters,
  optimist: {
    type: BEGIN,
    transactionId,
  },
});

const updateShippingMethodSuccess = (
  orderBuilder: io.flow.v0.models.OrderBuilder,
  parameters: Parameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS,
  payload: orderBuilder,
  params: parameters,
  optimist: {
    type: COMMIT,
    transactionId,
  },
});

const updateShippingMethodFailure = (
  error: LegacyError,
  parameters: Parameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_METHOD_FAILURE,
  payload: error,
  params: parameters,
  optimist: {
    type: REVERT,
    transactionId,
  },
});

const updateShippingMethod = (
  organizationId: string,
  selections: string[],
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const transactionId = uniqueId('transaction');

  const parameters = {
    organization: organizationId,
    selections,
  };

  const order = getOrderDenormalized(getState());

  const orderPutForm = {
    ...orderPutFormConverter.fromOrder(order),
    selections,
  };

  dispatch(updateShippingMethodRequest(parameters, transactionId));

  return extra.api.orderBuilders.putByNumber({
    body: orderPutForm,
    number: order.number,
    organization: organizationId,
    expand: ['experience'],
    language: getLanguage(getState()),
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateShippingMethodSuccess(response.result, parameters, transactionId));
          break;
        case 401:
        case 404:
          dispatch(updateShippingMethodFailure(response.result, parameters, transactionId));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateShippingMethod;
