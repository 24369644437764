import upperFirst from 'lodash/upperFirst';
import type { $HttpHeaders } from '@flowio/api-sdk';

export default function patchHeaders(headers: $HttpHeaders): $HttpHeaders {
  return Object.keys(headers).reduce((memo, key) => {
    const value = headers[key];
    const newKey = key.split('-').map(upperFirst).join('-');
    return {
      ...memo,
      [newKey]: value,
    };
  }, {} as $HttpHeaders);
}
