import keyBy from 'lodash/keyBy';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import { AppReducer } from '../../types';

type State = Partial<Record<string, io.flow.experience.v0.models.Line>>;

const initialState: State = {};

function keyLinesInOrderByNumber(order: io.flow.experience.v0.models.Order) {
  return keyBy(order.lines, (_) => _.item_number);
}

const lineByItemNumberReducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.ADD_PROMOTION_CODE_SUCCESS:
    case actionTypes.CREATE_SHOPIFY_ORDER_SUCCESS:
    case actionTypes.FETCH_ORDER_SUCCESS:
    case actionTypes.REMOVE_PROMOTION_CODE_SUCCESS:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_SUCCESS:
    case actionTypes.SUBMIT_ORDER_SUCCESS:
    case actionTypes.UPDATE_ORDER:
      return update(previousState, { $merge: keyLinesInOrderByNumber(action.payload) });
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.CREATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
    case actionTypes.FINALIZE_ORDER_SUCCESS:
    case actionTypes.PATCH_ORDER_SUCCESS:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.SET_ORDER_ATTRIBUTES_SUCCESS:
    case actionTypes.SUBMIT_ORDER_BUNDLE_SUCCESS:
    case actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS:
    case actionTypes.UPDATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS:
      return action.payload.order != null
        ? update(previousState, { $merge: keyLinesInOrderByNumber(action.payload.order) })
        : previousState;
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.SUBMIT_ORDER_CHECKOUT_SUCCESS:
      return action.payload.builder.order != null
        ? update(previousState, { $merge: keyLinesInOrderByNumber(action.payload.builder.order) })
        : previousState;
    default:
      return previousState;
  }
};

export default lineByItemNumberReducer;
