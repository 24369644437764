/* eslint-disable global-require */

import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import size from 'lodash/size';

import DeliveryItemsSummary from '../delivery-items-summary';
import DeliveryOptions from '../delivery-options';

if (process.browser) {
  require('./deliveries.css');
}

// Base class name differs from component name for backward compatibility.
const bem = new BemHelper('order-delivery-options');

const Deliveries = ({
  deliveredDuty,
  deliveredDutyDisplayType,
  deliveredDutySetting,
  deliveries,
  onChangeDeliveryOption,
}) => {
  const deliveryCount = size(deliveries);
  const hasMultipleDeliveries = deliveryCount > 1;
  return (
    <div className={bem.block()}>
      {(hasMultipleDeliveries) && (
        <p className={bem.element('multi-delivery-message')}>
          <FormattedMessage
            id="checkout_multiple_delivery_guidance"
            defaultMessage="Your items will be packed and shipped in {deliveryCount} deliveries from multiple manufacturer and(or) warehouse locations."
            description="Text describing to customers that the items will be packed and shipped from multiple manufacturer and/or warehouse locations"
            values={{ deliveryCount }}
          />
        </p>
      )}
      {map(deliveries, (delivery, deliveryIndex) => (
        <div key={delivery.id} className={bem.element('delivery')}>
          {hasMultipleDeliveries && (
            <div className={bem.element('shipment')}>
              <h3 className={bem.element('shipment-title')}>
                <FormattedMessage
                  id="checkout_multiple_delivery_shipment_title"
                  description="A line showing the delivery number in a list of deliveries"
                  defaultMessage="Shipment {deliveryIndex} of {deliveryCount}"
                  values={{
                    deliveryIndex: deliveryIndex + 1,
                    deliveryCount,
                  }}
                />
              </h3>
              <DeliveryItemsSummary delivery={delivery} />
            </div>
          )}
          <DeliveryOptions
            delivery={delivery}
            deliveredDuty={deliveredDuty}
            deliveredDutyDisplayType={deliveredDutyDisplayType}
            deliveredDutySetting={deliveredDutySetting}
            name={delivery.id}
            onChangeValue={onChangeDeliveryOption}
            value={delivery.selection}
          />
        </div>
      ))}
    </div>
  );
};

Deliveries.displayName = 'Deliveries';

Deliveries.propTypes = {
  /**
   * @property {Delivery[]} This property holds the delivery models represented
   * by this component.
   * @see https://docs.flow.io/type/delivery
   */
  deliveries: PropTypes.arrayOf(ApiPropTypes.delivery).isRequired,
  /**
   * @property {DeliveredDuty} This property holds which delivered duty should
   * be default selected.
   * @see https://docs.flow.io/type/delivered-duty
   */
  deliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
  /**
   * @property {DeliveredDutyDisplayType} This property holds the preferred
   * rendering display type for this component.
   *
   * TODO: Once we fix the discrepancies with delivered duties we should
   * be able to rely solely on the delivered duty setting property and
   * this property can be removed.
   *
   * @see https://docs.flow.io/type/delivered-duty
   */
  deliveredDutyDisplayType: ApiPropTypes.deliveredDutyDisplayType.isRequired,
  /**
   * @property {DeliveryDutySetting} This property holds the landed cost display
   * preferences for the current experience.
   * @see https://docs.flow.io/type/delivered-duty-setting
   */
  deliveredDutySetting: ApiPropTypes.deliveredDutySetting.isRequired,
  /**
   * @property {Function} A callback property that is invoked with the delivery
   * identifier and selected delivery option idenfifier when a delivery option
   * is selected.
   */
  onChangeDeliveryOption: PropTypes.func.isRequired,
};

export default Deliveries;
