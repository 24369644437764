/* eslint-disable global-require */

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogActions,
} from '../dialog';
import { RadioButtonGroup, RadioButton } from '../radio-button';
import {
  disableDeliveredDutyDialog,
  enableDeliveredDutyDialog,
  hideDeliveredDutyDialog,
  showDeliveredDutyDialog,
  updateDeliveredDuty,
} from '../../store/checkout/actions';
import {
  getDeliveredDutyDialogError,
  getOrderDeliveredDuty,
  getIsDeliveredDutyDialogVisible,
  getIsDeliveredDutyDialogEnabled,
} from '../../store/checkout/selectors';
import { getOrganization } from '../../store/flow/selectors';
import checkHttpStatus from '../../utilities/check-http-status';
import Button from '../button';
import DeliveredDuties from '../../constants/delivered-duties';
import OrderError from '../../containers/order-error';

if (process.browser) {
  require('./delivered-duty-setting.css');
}

// Base class name differs from component name for backward compatibility.
const bem = new BemHelper('landed-cost-settings');

export class DeliveredDutySetting extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleChangeDeliveredDuty = this.handleChangeDeliveredDuty.bind(this);
    this.handleClickSaveButton = this.handleClickSaveButton.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { defaultDeliveredDuty } = this.props;
    return {
      deliveredDuty: defaultDeliveredDuty,
    };
  }

  handleChangeDeliveredDuty(event, value) {
    this.setState({ deliveredDuty: value });
  }

  handleClickSaveButton() {
    const { onSave, organizationId } = this.props;
    const { deliveredDuty } = this.state;
    onSave(organizationId, deliveredDuty);
  }

  render() {
    const {
      enabled,
      error,
      isOpen,
      onOpen,
      onClose,
    } = this.props;

    const {
      deliveredDuty,
    } = this.state;

    return (
      <div>
        <Button link size="small" color="secondary" onClick={onOpen}>
          <FormattedMessage
            id="checkout_landed_cost_edit_action"
            description="Text for an action to edit customer preferences for taxes and duties"
            defaultMessage="Edit Duties and Taxes Preferences"
          />
        </Button>
        <Dialog isOpen={isOpen} showBackdrop onRequestClose={onClose}>
          <DialogHeader text={(
            <FormattedMessage
              id="checkout_landed_cost_preferences_subheader"
              description="Title for section for customer to select duty and tax preferences"
              defaultMessage="Duties and Taxes"
            />
          )}
          />
          <DialogBody>
            <p>
              <FormattedMessage
                id="checkout_landed_cost_please_choose_duty"
                description="Text to prompt the user to choose between including duty during order or paying duty when applicable"
                defaultMessage="Please choose whether you would like to include the duties and taxes in the order payment."
              />
            </p>
            <RadioButtonGroup
              bordered
              name="deliveredDuty"
              onChange={this.handleChangeDeliveredDuty}
              value={deliveredDuty}
            >
              <RadioButton value={DeliveredDuties.Paid}>
                <span className={bem.element('option-title')}>
                  <FormattedMessage
                    id="checkout_landed_cost_duty_option_paid"
                    description="Text labelling the option for the user to pay shipping duties when placing an order"
                    defaultMessage="Paid"
                  />
                </span>
                <span className={bem.element('option-description')}>
                  <FormattedMessage
                    id="checkout_landed_cost_duty_option_paid_description"
                    description="Text describing the Paid duty option, which means the user will pay applicable duties and taxes when placing an order"
                    defaultMessage="You will pay duties and taxes with your order."
                  />
                </span>
              </RadioButton>
              <RadioButton value={DeliveredDuties.Unpaid}>
                <span className={bem.element('option-title')}>
                  <FormattedMessage
                    id="checkout_landed_cost_duty_option_unpaid"
                    description="Text labeling the option for the user to waive duties and taxes when paying for an order and will be responsible for them during shipping if applicable."
                    defaultMessage="Unpaid"
                  />
                </span>
                <span className={bem.element('option-description')}>
                  <FormattedMessage
                    id="checkout_landed_cost_duty_option_unpaid_description"
                    description="Text describing the option to not pay duties and taxes during shipping but instead bear responsibility for them during shipping."
                    defaultMessage="You will not pay duties and taxes with your order now but will have to pay them, if applicable, with your ship-to country’s customs agency when you pick up your order."
                  />
                </span>
              </RadioButton>
            </RadioButtonGroup>
            {error && (
              <OrderError className={bem.element('error')} error={error} />
            )}
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button disabled={!enabled} color="tertiary" onClick={onClose}>
                <FormattedMessage
                  id="checkout_landed_cost_duty_option_cancel"
                  description="Text describing the action to dismiss the choice to change landed costs"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button disabled={!enabled} color="positive" onClick={this.handleClickSaveButton}>
                <FormattedMessage
                  id="checkout_landed_cost_duty_option_save"
                  description="Text describing the action to capture the users choice regarding landed costs"
                  defaultMessage="Save"
                />
              </Button>
            </DialogActions>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}

DeliveredDutySetting.displayName = 'DeliveredDutySetting';

DeliveredDutySetting.propTypes = {
  // Initial delivered duty selected.
  defaultDeliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
  enabled: PropTypes.bool.isRequired,
  error: ApiPropTypes.genericError,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};

DeliveredDutySetting.defaultProps = {
  error: undefined,
};

const mapStateToProps = createStructuredSelector({
  defaultDeliveredDuty: getOrderDeliveredDuty,
  enabled: getIsDeliveredDutyDialogEnabled,
  error: getDeliveredDutyDialogError,
  isOpen: getIsDeliveredDutyDialogVisible,
  organizationId: getOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  onClose() {
    dispatch(hideDeliveredDutyDialog());
  },
  onOpen() {
    dispatch(showDeliveredDutyDialog());
  },
  onSave(organizationId, deliveredDuty) {
    dispatch(disableDeliveredDutyDialog());
    dispatch(updateDeliveredDuty(organizationId, deliveredDuty)).then(checkHttpStatus).then(() => {
      dispatch(enableDeliveredDutyDialog());
      dispatch(hideDeliveredDutyDialog());
    }).catch(() => {
      dispatch(enableDeliveredDutyDialog());
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredDutySetting);
