export const Position = {
  TopLeft: 'top left',
  Top: 'top',
  TopRight: 'top right',
  RightTop: 'right top',
  Right: 'right',
  RightBottom: 'right bottom',
  BottomRight: 'bottom right',
  Bottom: 'bottom',
  BottomLeft: 'bottom left',
  LeftBottom: 'left bottom',
  Left: 'left',
  LeftTop: 'left top',
} as const;

export type Position = ValueOf<typeof Position>;
