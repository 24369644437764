import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import identity from 'lodash/identity';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import { appendQueryParameters, parseQueryString } from '@flowio/web-sdk';

import { createOrder, createCheckoutOrder } from '../store/order/actions';
import {
  checkFeatureValues,
  setCheckoutPreloaded,
  submitCoupon,
} from '../store/checkout/actions';
import {
  getDeviceDetails,
  getIsOrderFulfilled,
  getIsOrderRejected,
  getOrder,
  getOrderError,
  isCheckoutResourceFeatureEnabled,
} from '../store/checkout/selectors';
import { getContactInfoUrlV2 } from '../store/navigation/selectors';
import { getIsShopifyIntegration, getReturnUrl, getOrganization } from '../store/flow/selectors';
import { getCartId } from '../store/cart/selectors';
import Application from './application';
import OrderAttributes from '../constants/order-attributes';
import withRedirect from '../components/with-redirect';
import getFeatureKeys from '../utilities/get-feature-keys';

const fetchAsyncState = (dispatch, getState, { params, location }) => {
  const { organization } = params;
  const data = {};
  const returnUrl = getReturnUrl(getState());

  // Use set to convert items[0][number]=40398686663 into { items: [{ number: 40398686663 }] }
  map(location.query, (value, key) => set(data, key, value));

  // hydrate with device details
  const state = getState();
  const deviceDetails = getDeviceDetails(state);
  data.device_details = deviceDetails;

  if (returnUrl) {
    set(data, `attributes.${OrderAttributes.RETURN_URL}`, decodeURIComponent(returnUrl));
  }

  const {
    experience,
    country,
    currency,
    ip,
    language,
    flow_discount: discount,
    ...form
  } = data;

  const apiParams = pickBy({
    experience, country, currency, ip, language,
  }, identity);

  return dispatch(checkFeatureValues(organization, { })).then(() => {
    const checkoutResourceEnabled = isCheckoutResourceFeatureEnabled(getState());

    if (checkoutResourceEnabled) {
      return dispatch(createCheckoutOrder({
        discriminator: 'order',
        organization,
        order: form,
        feature_keys: getFeatureKeys(),
        order_parameters: {
          experience,
          country,
          currency,
          ip,
          language,
        },
      })).then((response) => {
        if (response.ok && discount != null) {
          return dispatch(submitCoupon({
            orderNumber: response.result.builder.order.number,
            organizationId: organization,
            promotionCode: discount,
          })).catch(() => {
            // TODO: how should we handle auto apply failures?
            getRollbar((rollbar) => {
              rollbar.warn(`Failed to auto apply discount code ${discount} when generating order.`);
            });
            return Promise.resolve();
          });
        }
        return Promise.resolve();
      }).then(() => dispatch(setCheckoutPreloaded())); // Prevent re-fetching of checkout data on next URL change
    }

    return dispatch(createOrder(organization, apiParams, form));
  });
};

/**
 * Generate URL with allowlisted params appended
 * @param {*} state - Redux state
 */
const getContactInfoUrlWithParams = (state) => {
  const queryParams = parseQueryString(window.location.search);
  const baseUrl = getContactInfoUrlV2(state);
  const {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  } = queryParams;
  const params = {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  };

  return appendQueryParameters(baseUrl, pickBy(params, identity));
};

export default compose(
  withFetch(fetchAsyncState),
  withRedirect(getContactInfoUrlWithParams, getIsOrderFulfilled, { replace: true }),
  withRedirect((state) => {
    const cartId = getCartId(state);
    const error = getOrderError(state);
    const isShopify = getIsShopifyIntegration(state);
    const organizationId = getOrganization(state);
    const order = getOrder(state);

    let redirectUrl;

    /**
     * An `order_builder` may contain both errors and an order. If the order is created, we want to
     * enter checkout with the errors and give customers an opportunity to with deal them.
     */
    if (order != null && order.items != null && order.items.length > 0) {
      redirectUrl = `/${organizationId}/checkout/${order.number}/contact-info`;
    /**
     * Return to cart page in Shopify integrations when the cart is in state.
     */
    } else if (isShopify && cartId != null) {
      redirectUrl = `/${organizationId}/cart/${cartId}`;
    } else {
      getRollbar((rollbar, extra = {}) => {
        rollbar.error('Failed to create order from generic order redirect', { ...extra, error });
      });

      redirectUrl = `/${organizationId}/error-pages/404`;
    }

    return redirectUrl;
  }, getIsOrderRejected, { replace: true }),
)(Application);
