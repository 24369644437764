import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import get from 'lodash/get';

import { visitConfirmationStep, visitPaymentInfoStep } from '../../navigation/actions';
import { checkAuthorization } from '../../../utilities/authorization-utilities';
import actionTypes from '../../constants/action-types';
import checkHttpStatus from '../../../utilities/check-http-status';
import { throwIfPendingBalance } from '../../../utilities/check-order-balance';
import fetchAuthorization from './fetch-authorization';
import pollResultActionPendingAuthorization from './poll-result-action-pending-authorization';
import fetchOrder from './fetch-order';
import createTransactionEvents from './create-transaction-events';
import unwrapResponseInto from '../../../utilities/unwrap-response-into';
import updateOrderAuthorizationKeys from './update-order-authorization-keys';
import { getOrganization } from '../../flow/selectors';
import { getOrderNumber, getCheckoutId } from '../selectors';
import submitOrderCheckouts from './submit-order-checkouts';
import checkCheckoutSubmissionStatus from '../../../utilities/check-checkout-submission-status';
import CheckoutSubmissionError from '../../../utilities/checkout-submission-error';
import retry from '../../../utilities/promises/retry';

export function checkoutsProcessOnlineAuthorizationFailure(error) {
  return {
    type: actionTypes.PROCESS_ONLINE_AUTHORIZATION_FAILURE,
    payload: error,
  };
}

export function checkoutsProcessOnlineAuthorizationSuccess() {
  return {
    type: actionTypes.PROCESS_ONLINE_AUTHORIZATION_SUCCESS,
  };
}

export default function checkoutsProcessOnlineAuthorization(_, authorizationKey) {
  return function checkoutsProcessOnlineAuthorizationSideEffects(dispatch, getState) {
    // Fetch online authorization and ensure its state is valid.
    const state = getState();
    const organizationId = getOrganization(state);
    const orderNumber = getOrderNumber(state);
    const checkoutId = getCheckoutId(state);

    function logError(error) {
      getRollbar((rollbar, extra = {}) => {
        rollbar.warning('Exception raised while processing online authorization', error, {
          ...extra,
          organizationId,
          orderNumber,
        });
      });
    }

    return dispatch(fetchAuthorization(organizationId, authorizationKey))
      .then(checkHttpStatus)
      .then((response) => (
        dispatch(pollResultActionPendingAuthorization(organizationId, response))
      ))
      .then(unwrapResponseInto(checkAuthorization))
      // Fetch order details
      .then(() => dispatch(fetchOrder(organizationId, orderNumber)))
      .then(checkHttpStatus)
      // Associate online authorization with order to ensure it is updated synchronously. Otherwise,
      // we have to wait until the payment event for the online authorization is consumed by
      // experience before proceeding. This is likely a nasty hack.
      .then(() => dispatch(updateOrderAuthorizationKeys(organizationId, orderNumber, [
        authorizationKey,
      ])))
      .then(checkHttpStatus)
      // Retry up to 2 seconds until the order no longer has a pending balance.
      .then((response) => {
        const orderBuilder = response.result;
        const balanceAmount = get(orderBuilder, 'order.balance.amount', 0);

        if (balanceAmount === 0) {
          return Promise.resolve(response);
        }

        const operation = () => {
          getRollbar((rollbar, extra) => {
            rollbar.warning('Order has pending balance, retrying.', extra);
          });

          return dispatch(fetchOrder(organizationId, orderNumber))
            .then(checkHttpStatus)
            .then(unwrapResponseInto(throwIfPendingBalance));
        };

        return retry(operation, 1000, 5)
          .catch((error) => {
            getRollbar((rollbar, extra) => {
              rollbar.warning('Maximum order pending balance retries exceeded', extra, error);
            });
          });
      })
      // .then(unwrapResponseInto(checkOrderBalance))
      // Submit order and create a beacon transaction on success.
      .then(() => dispatch(submitOrderCheckouts(checkoutId)))
      .then(checkHttpStatus)
      .then(checkCheckoutSubmissionStatus)
      .then((response) => {
        if (response.result != null && response.result.action != null) {
          getRollbar((rollbar, extra = {}) => {
            rollbar.warning('Order submission not accepted due to pending authorization action', {
              ...extra,
              checkoutId,
              organizationId,
              orderNumber,
            });
          });
          throw new CheckoutSubmissionError(response);
        }

        return response;
      })
      .then(() => dispatch(createTransactionEvents(organizationId)))
      .then(() => dispatch(checkoutsProcessOnlineAuthorizationSuccess()))
      // Navigate to confirmation page.
      .then(() => dispatch(visitConfirmationStep()))
      .catch((error) => {
        // Any error, with the exception of JavaScript runtime errors, will transition customers to
        // the payment step where the error will be displayed.
        switch (error.name) {
          case 'AuthorizationError':
            logError({
              authorizationResult: get(error, 'authorization.result'),
            });
            dispatch(checkoutsProcessOnlineAuthorizationFailure(error));
            dispatch(visitPaymentInfoStep());
            break;
          case 'HttpStatusError':
          case 'OrderBalanceError':
            logError(get(error, 'response.result'));
            dispatch(checkoutsProcessOnlineAuthorizationFailure(error.response.result));
            dispatch(visitPaymentInfoStep());
            break;
          case 'CheckoutSubmissionError':
            logError(get(error, 'response.result.builder.errors'));
            dispatch(checkoutsProcessOnlineAuthorizationFailure(error.response.result));
            dispatch(visitPaymentInfoStep());
            break;
          default:
            logError(error);
            throw error;
        }
      });
  };
}
