/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

if (process.browser) {
  require('./row.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('row');

class Row extends PureComponent {
  render() {
    const { children, className } = this.props;
    const ElementType = getElementType(Row, this.props);
    const unhandledProps = getUnhandledProps(Row, this.props);

    return (
      <ElementType {...unhandledProps} className={bem.block(className)}>
        {children}
      </ElementType>
    );
  }
}

Row.displayName = 'Row';

Row.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

Row.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
};

export default Row;
