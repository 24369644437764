import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import Hapi from 'hapi';
import cookies from 'cookies-js';
import isomorphicFetch from 'isomorphic-fetch';
import debugInit from 'debug';

import type { $FetchFunction, $FetchOptions, $HttpHeaders } from '@flowio/api-sdk';
import { BEARER_COOKIE, SESSION_COOKIE } from '../constants/cookie';
// eslint-disable-next-line import/no-restricted-paths
import { getSessionId } from '../../client/utilities/session';
import randomString from './random-string';
import appendQuery from './append-query';
import patchHeaders from '../hacks/patch-headers';

const debugLog = debugInit('checkout:fetch');

function getServerAuthorization(
  request: Hapi.Request,
): string | null {
  const jsonWebToken = request.state[BEARER_COOKIE];

  if (jsonWebToken != null) {
    return `Bearer ${jsonWebToken}`;
  }

  const sessionId = request.state[SESSION_COOKIE];

  if (sessionId != null) {
    return `Session ${sessionId}`;
  }

  return null;
}

function getBrowserAuthorization(): string | null {
  const jsonWebToken = cookies.get(BEARER_COOKIE);

  if (jsonWebToken != null) {
    return `Bearer ${jsonWebToken}`;
  }

  const sessionId = getSessionId();

  if (sessionId != null) {
    return `Session ${sessionId}`;
  }

  return null;
}

// Resolve authorization token from cookies
function getAuthorization(
  request?: Hapi.Request,
): string | null {
  return (request != null)
    ? getServerAuthorization(request)
    : getBrowserAuthorization();
}

// Generate a random request identifier for logging
function getRequestId(): string {
  return `chk${randomString(20)}`;
}

export default function createFetch(
  request?: Hapi.Request,
): $FetchFunction {
  return function fetch(
    url: string,
    options: $FetchOptions = {},
  ): Promise<Response> {
    const {
      method = 'GET',
      headers = {},
      body,
    } = options;

    const authorization = getAuthorization(request);

    const newUrl: string = appendQuery(url, {
      envelope: 'request',
    });

    const newHeaders: $HttpHeaders = {
      ...headers,
      'x-flow-request-id': getRequestId(),
    };

    if (authorization != null) {
      newHeaders.authorization = authorization;
    } else {
      // Log when a request is made without authentication headers.
      // Good indication that something went awry.
      getRollbar((rollbar, extra = {}) => {
        rollbar.warning('Missing authentication for Request', { ...extra, url });
      });
    }

    const newBody = body != null ? JSON.parse(body) : undefined;

    debugLog(`fetch: ${newUrl}`);
    debugLog(JSON.stringify({
      method,
      headers: newHeaders,
      body: newBody,
    }));

    return isomorphicFetch(newUrl, {
      method: 'POST',
      body: JSON.stringify({
        method,
        headers: patchHeaders(newHeaders),
        body: newBody,
      }),
    });
  };
}
