/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import bem from './bem';

class StepGroup extends PureComponent {
  render() {
    const { children, className } = this.props;
    const unhandledProps = getUnhandledProps(StepGroup, this.props);
    const ElementType = getElementType(StepGroup, this.props);

    // Whitespace needed for CSS justification for steps to work properly.
    // For now assumes that no other element other than a Step is rendered
    // inside a StepGroup.
    return (
      <ElementType {...unhandledProps} className={bem.element('group', className)}>
        {React.Children.map(children, (child) => (
          <>
            {child}
            {' '}
          </>
        ))}
      </ElementType>
    );
  }
}

StepGroup.displayName = 'StepGroup';

StepGroup.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
};

StepGroup.defaultProps = {
  as: 'ol',
  children: undefined,
  className: '',
};

export default StepGroup;
