/* eslint-disable react/jsx-props-no-spreading */

import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';

import { trackHeapEvent } from '../../utilities/heap';
import Option from './option';
import TriangleDown from '../svg-icons/triangle-down';

if (process.browser) {
  require('./select-field.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('select-field');

class SelectField extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.cid = `input.${uniqueId()}`;
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      errorText,
      name,
    } = nextProps;

    const {
      errorText: previousErrorText,
    } = this.props;

    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: name,
      });
    }
  }

  handleBlur(event) {
    const { name, onBlur } = this.props;

    trackHeapEvent('field_blur', {
      field: name,
    });

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  handleFocus(event) {
    const { name, onFocus } = this.props;

    trackHeapEvent('field_focus', {
      field: name,
    });

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  renderOptions() {
    const { children, options } = this.props;

    if (!isUndefined(children)) {
      return children;
    }

    return map(options, (option, index) => (
      <Option key={index} text={option.text} value={option.value} />
    ));
  }

  render() {
    const {
      automationId,
      className,
      errorText,
      id = this.cid,
      labelText,
      name,
      required,
      size,
    } = this.props;

    const ElementType = getElementType(SelectField, this.props);

    const unhandledProps = getUnhandledProps(SelectField, this.props);

    const modifiers = {
      input: {
        [size]: true,
        invalid: !isUndefined(errorText),
        'with-floating-label': !isUndefined(labelText),
      },
    };

    return (
      <ElementType
        className={bem.block(className)}
        data-automation-id={automationId}
      >
        <div className={bem.element('input-container')}>
          {labelText && (
            <label
              htmlFor={id}
              className={bem.element('label')}
            >
              {labelText}
              {required ? null : (
                <span className={bem.element('optional')}>
                  &#40;
                  <FormattedMessage
                    id="checkout_field_optional"
                    description="A message added to form field labels to denote a form field is optional"
                    defaultMessage="Optional"
                  />
                  &#41;
                </span>
              )}
            </label>
          )}
          <select
            {...unhandledProps}
            aria-required={required}
            className={bem.element('input', modifiers.input)}
            id={id}
            name={name}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            required={required}
          >
            {this.renderOptions()}
          </select>
          <div className={bem.element('triangle')}>
            <TriangleDown />
          </div>
        </div>
        {errorText && (
          <div className={bem.element('error-container')}>
            <p className={bem.element('error-text')}>{errorText}</p>
          </div>
        )}
      </ElementType>
    );
  }
}

SelectField.Option = Option;

SelectField.displayName = 'SelectField';

SelectField.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  automationId: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  labelText: PropTypes.node,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })),
  size: PropTypes.oneOf(['medium', 'large']),
  required: PropTypes.bool,
};

SelectField.defaultProps = {
  as: 'div',
  automationId: undefined,
  children: undefined,
  className: '',
  errorText: undefined,
  id: undefined,
  labelText: undefined,
  name: undefined,
  onBlur: undefined,
  onFocus: undefined,
  options: [],
  size: 'large',
  required: false,
};

export default SelectField;
