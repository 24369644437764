import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import OperationTypes from '../../../utilities/redux/operation-types';
import ReadyState from '../../../utilities/redux/ready-state';
import type { AppReducer } from '../../types';
import type { CartStateStatuses } from '../types';

const initialState: CartStateStatuses = {
  cart: {},
};

const reducer: AppReducer<CartStateStatuses> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CART_REQUEST:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.FETCH_CART_SUCCESS:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.FETCH_CART_FAILURE:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_REQUEST:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_FAILURE:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.REMOVE_CART_ITEM_REQUEST:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.REMOVE_CART_ITEM_SUCCESS:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.REMOVE_CART_ITEM_FAILURE:
      return update(previousState, {
        cart: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Rejected,
          },
        },
      });
    default:
      return previousState;
  }
};

export default reducer;
