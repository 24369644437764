import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CartStateParameters } from '../types';

const initialState: CartStateParameters = {};

const paramsReducer: AppReducer<CartStateParameters> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CART_REQUEST:
      return update(previousState, { cart: { $set: action.params } });
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_REQUEST:
      return update(previousState, { cart: { $set: action.params } });
    case actionTypes.REMOVE_CART_ITEM_REQUEST:
      return update(previousState, { cart: { $set: action.params } });
    default:
      return previousState;
  }
};

export default paramsReducer;
