import type { ShopifyCartsPostOrdersByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<ShopifyCartsPostOrdersByIdResponse>>;

type Parameters = {
  cartId: string,
};

const createShopifyOrderRequest = (
  params: Parameters,
): RootAction => ({
  type: actionTypes.CREATE_SHOPIFY_ORDER_REQUEST,
  params,
});

const createShopifyOrderSuccess = (
  params: Parameters,
  order: io.flow.v0.models.Order,
): RootAction => ({
  type: actionTypes.CREATE_SHOPIFY_ORDER_SUCCESS,
  params,
  payload: order,
});

const createShopifyOrderFailure = (
  params: Parameters,
  error: LegacyError | io.flow.v0.models.GenericError,
): RootAction => ({
  type: actionTypes.CREATE_SHOPIFY_ORDER_FAILURE,
  params,
  payload: error,
});

const createShopifyOrder = (
  cartId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const parameters: Parameters = { cartId };
  dispatch(createShopifyOrderRequest(parameters));
  return extra.api.shopifyCarts.postOrdersById({
    id: cartId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(createShopifyOrderSuccess(parameters, response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(createShopifyOrderFailure(parameters, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default createShopifyOrder;
