import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';

import SectionTitle from './section-title';

const bem = new BemHelper('section');

class SectionHeader extends PureComponent {
  render() {
    const {
      children,
      className,
      divided,
      title,
    } = this.props;

    const classNames = bem.element('header', {
      divided,
    }, className);

    if (!isNil(children)) {
      return (
        <div className={classNames}>
          {children}
        </div>
      );
    }

    return (
      <div className={classNames}>
        <SectionTitle text={title} />
      </div>
    );
  }
}

SectionHeader.displayName = 'SectionHeader';

SectionHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  divided: PropTypes.bool,
  title: PropTypes.string,
};

SectionHeader.defaultProps = {
  children: undefined,
  className: '',
  divided: false,
  title: '',
};

export default SectionHeader;
