import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getOrderDiscounts, handleRemoveGiftCard, removePromotionCodeAndUpdatePaymentMethods } from '../store/checkout';
import { getOrderSummaryMessage } from '../store/content';
import OrderSummary from '../components/order-summary';

const mapStateToProps = createStructuredSelector({
  discounts: getOrderDiscounts,
  orderSummaryMessage: getOrderSummaryMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRequestRemoveDiscount: removePromotionCodeAndUpdatePaymentMethods,
  onRemoveGiftCard: handleRemoveGiftCard,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
