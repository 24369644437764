import { connect, MapStateToProps } from 'react-redux';

import { StateProps, OwnProps } from '../types';
import { RootState } from '../../../store/types';
import { getFeatureValueByKey } from '../../../store/checkout';
import FeatureToggle from '../components/feature-toggle';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state, props) => {
  const { featureKey } = props;
  return {
    featureValue: getFeatureValueByKey(featureKey)(state),
  };
};

export default connect(mapStateToProps)(FeatureToggle);
