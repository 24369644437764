/* eslint-disable global-require */

import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import BemHelper from '@flowio/bem-helper';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import React from 'react';

import ContentElementType from '../../constants/content-element-type';
import ExternalLink from './external-link';

if (process.browser) {
  require('./localized-content-element.css');
}

const bem = new BemHelper('flowio-localized-content-element');

class LocalizedContentElement extends React.Component {
  renderMarkdown() {
    const { element, inline } = this.props;

    const renderers = {
      root: inline ? 'span' : 'div',
      paragraph: inline ? 'span' : 'p',
      // Using ExternalLink to prevent users from leaving Checkout.
      link: ExternalLink,
    };

    // HTML will be escaped while parsing markdown.
    // It's pointless to disabled this since HTML with inline attributes are not supported.
    // It can also cause security issues.
    // https://github.com/rexxars/react-markdown#inline-html-is-broken
    return (
      <Markdown
        className={bem.element('markdown')}
        escapeHtml
        source={element.value}
        renderers={renderers}
      />
    );
  }

  renderHref() {
    const { element } = this.props;
    return (
      <ExternalLink href={element.value}>
        {element.value}
      </ExternalLink>
    );
  }

  renderPlainText() {
    const { element } = this.props;
    return (
      <span className={bem.element('text')}>
        {element.value}
      </span>
    );
  }

  renderChildren() {
    const { element } = this.props;
    switch (element.type) {
      case ContentElementType.MARKDOWN:
        return this.renderMarkdown();
      case ContentElementType.HREF:
        return this.renderHref();
      default:
        return this.renderPlainText();
    }
  }

  render() {
    const { className, inline } = this.props;
    const ElementType = inline ? 'span' : 'div';
    // TODO: We can optimize the DOM output for markdown content. Need to
    // investigate impact since it would be breaking change for consumers
    // relying on the DOM structure to apply fancy styling.
    return (
      <ElementType className={bem.block(className)}>
        {this.renderChildren()}
      </ElementType>
    );
  }
}

LocalizedContentElement.displayName = 'ContentElement';

LocalizedContentElement.propTypes = {
  className: PropTypes.string,
  element: ApiInternalPropTypes.localizedContentElement.isRequired,
  inline: PropTypes.bool,
};

LocalizedContentElement.defaultProps = {
  className: '',
  inline: false,
};

export default LocalizedContentElement;
