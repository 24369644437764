export function autoCompleteToken(
  token: string,
  scope?: string,
): string {
  return scope != null ? `${scope} ${token}` : token;
}

export function automationToken(
  token: string,
  scope?: string,
): string {
  return scope != null ? `${scope}-${token}` : token;
}
