import defaultTo from 'lodash/defaultTo';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import { AppReducer } from '../../types';

type State = io.flow.internal.v0.models.GiftCardProgram | null;

const initialState: State = null;

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.ADD_GIFT_CARD_SUCCESS:
    case actionTypes.AUTHORIZE_GIFT_CARDS_SUCCESS:
    case actionTypes.FETCH_GIFT_CARDS_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return defaultTo(action.payload.gift_card_program, previousState);
    case actionTypes.FETCH_GIFT_CARDS_FAILURE:
      return null;
    case actionTypes.AUTHORIZE_GIFT_CARDS_FAILURE:
      // What used to be here did not make sense!
      return previousState;
    case actionTypes.REMOVE_GIFT_CARD_SUCCESS:
      return previousState == null ? previousState : update(previousState, {
        cards: {
          $set: previousState.cards.filter((_) => _.id !== action.params.id),
        },
      });
    default:
      return previousState;
  }
};

export default reducer;
