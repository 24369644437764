import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import matches from 'lodash/matches';
import isNil from 'lodash/isNil';

import {
  getOrderDestination,
  getOrder,
  getCheckoutId,
  getCustomerAddressBookContacts,
  isReviewStepEnabled,
  getCustomerPaymentSources,
} from '../selectors';
import {
  isKlarna,
  isCard,
  isApplePay,
} from '../../../containers/payment-form/utilities';
import checkHttpStatus from '../../../utilities/check-http-status';
import { hideLoadingIndicator } from '../../application/actions';
import updateOrder from './update-order';
import createTransactionEvents from './create-transaction-events';
import { visitConfirmationStep } from '../../navigation/actions';
import submitCheckoutsPayment from './submit-checkouts-payment';
import submitBundlePayment from './submit-bundle-payment';
import generateAuthorizationForm from '../../../utilities/generate-authorization-form';
import createCardToken from '../../../utilities/create-card-token';
import clearReviewState from './clear-review-state';
import createCardPaymentSource from './create-card-payment-source';
import submitOrder from './submit-order';
import { isCardNumberConfirmationRequired, isCvvConfirmationRequired } from '../../../utilities/payment-source-utilities';
import { updateCardPaymentSource } from '.';
import { getLocale } from '../../intl';

/**
 * Creates an action responsible for placing an order with the provided
 * payment information.
 *
 * If any Flow Commerce API response falls outside the 2xx status code range,
 * the promise returned by the Redux Thunk middleware will be rejected with a
 * SubmissionError containing the response result.
 *
 * If errors are detected from the order builder response, the promise returned
 * by the Redux Thunk middleware will be rejected with a SubmissionError
 * containing the response result errors.
 *
 * @throws {SubmissionError}
 * @param {import('../types/forms').PaymentFormValues} values
 * @param {any} flow
 * @param {boolean} isCheckoutResourceEnabled
 * @param {boolean} [isKlarnaRedirectEnabled]
 */
export default function submitPaymentInformation({
  values,
  flow,
  isCheckoutResourceEnabled,
  isKlarnaRedirectEnabled,
} = {}) {
  return function submitPaymentInformationEffects(dispatch, getState) {
    const state = getState();

    const {
      customerNumber,
      orderNumber,
      organizationId,
      paymentMethodId,
      saveCard,
    } = values;

    const addressBookContacts = getCustomerAddressBookContacts(state);
    const orderDestination = getOrderDestination(state);
    const checkoutId = getCheckoutId(state);
    const paymentSources = getCustomerPaymentSources(state);

    let selectedPaymentSource;

    if (!isNil(paymentSources)) {
      selectedPaymentSource = paymentSources.find(matches({
        id: paymentMethodId,
      }));
    }

    const isPaymentSourceUpdateRequired = !isNil(selectedPaymentSource)
      && (isCardNumberConfirmationRequired(selectedPaymentSource)
        || isCvvConfirmationRequired(selectedPaymentSource));

    return Promise.resolve()
      .then(() => {
        if (!isNil(values.card)) {
          return Promise.resolve(values.card);
        }

        if (isCard(values) || isPaymentSourceUpdateRequired) {
          return createCardToken(values, flow, orderDestination, addressBookContacts);
        }

        return Promise.resolve();
      })
      .then((card) => {
        /** @type {io.flow.v0.models.AuthenticationForm | undefined} */
        let authorizationForm;

        if (isKlarna(values)) {
          if (isKlarnaRedirectEnabled) {
            authorizationForm = generateAuthorizationForm(
              state,
              values,
              card,
              isKlarnaRedirectEnabled,
            );
          }
        } else if (!isApplePay(values)) {
          authorizationForm = generateAuthorizationForm(
            state,
            values,
            card,
            isKlarnaRedirectEnabled,
          );
        }

        // Fork for two different types of checkouts order submission actions
        // One for checkout bundle, the other for checkouts object
        const submissionPromise = isCheckoutResourceEnabled
          ? submitCheckoutsPayment(
            dispatch,
            organizationId,
            checkoutId,
            orderNumber,
            authorizationForm,
            get(getOrder(state), 'total'),
            getLocale(state),
          )
          : submitBundlePayment(
            dispatch,
            organizationId,
            orderNumber,
            authorizationForm,
            get(getOrder(state), 'total'),
            getLocale(state),
          );

        // Create or update payment source if necessary.
        return submissionPromise.then(() => {
          if (isNil(card) || isNil(customerNumber)) {
            return Promise.resolve();
          }

          if (saveCard) {
            return dispatch(createCardPaymentSource(organizationId, {
              card_id: card.id,
              customer_number: customerNumber,
            }));
          }

          if (isPaymentSourceUpdateRequired) {
            return dispatch(updateCardPaymentSource(organizationId, selectedPaymentSource.id, {
              card_id: card.id,
              customer_number: customerNumber,
            }));
          }

          return Promise.resolve();
        });
      })
      // Double submit is not actually "double"
      // it is only double if there is no resulting action
      .then(() => (dispatch(submitOrder(organizationId, orderNumber))))
      .then(checkHttpStatus)
      .then(() => dispatch(createTransactionEvents(organizationId)))
      .then(() => {
        if (isReviewStepEnabled(state)) {
          return dispatch(clearReviewState());
        }
        return Promise.resolve();
      })
      .then(() => dispatch(visitConfirmationStep()))
      .then((response) => {
        // PATCH: Guard to avoid error thrown because a response object is not
        // available on successful chain of previously dispatched action.
        // The last dispatched action on a successful chain is a transition to
        // the order confirmation page, which is resolved with an undefined value.
        // The unhandled error changes the state of the RF form from succeeded
        // to failed, thus the submit order button is re-enabled allowing customers
        // to resubmit the order.
        // https://app.clubhouse.io/flow/story/11614
        if (response != null) {
          checkHttpStatus(response);
        }
      })
      .then(() => dispatch(hideLoadingIndicator()))
      .catch((error) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          case 'OrderBuilderError':
            throw new SubmissionError({
              _error: error.response.result.errors[0],
            });
          case 'CheckoutSubmissionError':
            throw new SubmissionError({
              _error: error.response.result.builder.errors[0],
            });
          case 'OrderBundleError':
            if (error.response.result.order != null) {
              dispatch(updateOrder(error.response.result.order));
            }

            throw new SubmissionError({
              _error: error.response.result.errors[0],
            });
          case 'OptInAttributeError':
            throw new SubmissionError(error.requiredOptIns);
          case 'ThreedsError':
            // eslint-disable-next-line no-console
            console.log('CHEC-2989_DEBUG: 3DS Error Catch', { organizationId, error });
            throw new SubmissionError({
              _error: [error.message],
            });
          default:
            throw error;
        }
      });
  };
}
