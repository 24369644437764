import {
  Pixel,
  PixelOrderItem,
  CheckoutPaymentOptionChangedEventData,
} from '@flowio/pixel';

import requestGlobalPixelCallback from './request-global-pixel-callback';
import requestPixelReadyCallback from './request-pixel-ready-callback';
import { CheckoutEventData } from '../../types';
import events from '../events';
import ExternalEvents from '../../external/constants/external-events';
import PageViews from '../../constants/pageviews';

function listenToEvents(
  pixel: Pixel,
): void {
  events.on(ExternalEvents.INITIALIZED, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'checkout_init',
      data: {
        order: data.order,
        organization: data.organization,
        experiment: data.experiment,
      },
    });

    pixel.event({
      type: 'google_custom_event',
      data: {
        order: data.order,
        category: 'checkout_app_version',
        action: data.appVersion.family,
        label: data.appVersion.stage,
      },
    });
  });

  events.on(PageViews.CONTACT_INFO, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'page_view',
      data: {
        value: {
          page: 'contact-info',
          title: 'Checkout - Contact',
          location: '/contact-info',
        },
      },
    });

    pixel.event({
      type: 'checkout_customer_info',
      data: {
        order: data.order,
      },
    });
  });

  events.on(PageViews.SHIPPING_METHOD, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'page_view',
      data: {
        value: {
          page: 'shipping-method',
          title: 'Checkout - Shipping',
          location: '/shipping-method',
        },
      },
    });

    pixel.event({
      type: 'checkout_shipping',
      data: {
        order: data.order,
      },
    });
  });

  events.on(PageViews.PAYMENT_INFO, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'page_view',
      data: {
        value: {
          page: 'payment-info',
          title: 'Checkout - Payment',
          location: '/payment-info',
        },
      },
    });

    pixel.event({
      type: 'checkout_payment',
      data: {
        order: data.order,
        defaultPayment: data.defaultPayment,
      },
    });
  });

  events.on(PageViews.CONFIRMATION, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'page_view',
      data: {
        value: {
          page: 'confirmation',
          title: 'Checkout - Confirmation',
          location: '/confirmation',
        },
      },
    });

    pixel.event({
      type: 'checkout_confirmation',
      data: {
        order: data.order,
      },
    });
  });

  events.on(ExternalEvents.PAYMENT_INFO_SUBMITTED, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'checkout_payment_submitted',
      data: {
        order: data.order,
      },
    });
  });

  events.on(ExternalEvents.TRANSACTION, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'purchase',
      data: {
        order: data.order,
      },
    });
  });

  events.on(ExternalEvents.PAYMENT_METHOD_CHANGE, (data:
  CheckoutPaymentOptionChangedEventData): void => {
    pixel.event({
      data,
      type: 'checkout_payment_option_changed',
    });
  });

  events.on(ExternalEvents.SHIPPING_METHOD_CHANGE, (data: CheckoutEventData): void => {
    pixel.event({
      type: 'checkout_shipping_option_changed',
      data: {
        order: data.order,
      },
    });
  });

  events.on(ExternalEvents.ADD_TO_CART, (item: PixelOrderItem): void => {
    pixel.event({
      type: 'add_to_cart',
      data: { item },
    });
  });

  events.on(ExternalEvents.REMOVE_FROM_CART, (item: PixelOrderItem): void => {
    pixel.event({
      type: 'remove_from_cart',
      data: { item },
    });
  });
}

// IMPORTANT: Pixel is initialized asynchronously and the only reason race
// conditions are not a problem is because events in Checkout are fire once
// events. Perhaps, we should revisit pixel to operate similarly to other
// third-party tracking tools where events are queued until the library is
// ready to flush them.
export default function configurePixel(
  isLiquid: boolean,
  checkoutConfiguration?: io.flow.internal.v0.models.CheckoutConfiguration,
): void {
  const analytics = checkoutConfiguration != null ? checkoutConfiguration.analytics : undefined;
  const timeout = isLiquid ? 30000 : 0;
  requestGlobalPixelCallback((globalPixel) => {
    let localPixel: Pixel | undefined;

    if (globalPixel != null) {
      localPixel = globalPixel;

      if (analytics != null) {
        localPixel.addTrackers(
          analytics.trackers,
          analytics.use_base_currency,
        );
      }
    } else if (analytics != null) {
      localPixel = Pixel.create({
        trackers: analytics.trackers,
        useBaseCurrency: analytics.use_base_currency,
      });
    }

    if (localPixel != null) {
      requestPixelReadyCallback(localPixel, () => {
        // TypeScript thinks that localPixel is null at this point.
        // https://github.com/microsoft/TypeScript/issues/8552
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        listenToEvents(localPixel!);
      });
    }
  }, timeout);
}
