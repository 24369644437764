/* eslint-disable max-len */

import { showLoadingIndicator, hideLoadingIndicator } from '../../application/actions/index';
import addPromotionCode from './add-promotion-code';
import checkHttpStatus from '../../../utilities/check-http-status';
import updatePaymentMethods from './update-payment-methods';
import { isDiscountsEnabled } from '../selectors';
import addNonShopifyPromoCode from './add-non-shopify-promo-code';

/**
 * Returns an action responsible for applying a promotion code and updating
 * the available payment methods. An activity indicator will be rendered while
 * this action is being processed.
 *
 * Since applying a promotion code may change the order total, we need to make
 * sure the payment method rules are updated to reflect the new amount.
 *
 * NOTE: Promotion codes are a Shopify specific feature and only a single
 * promotion code can be applied per order. Applying another promotion code
 * replaces any existing promotion code.
 *
 * @param {string} organizationId - The unique organization identifier
 * @param {string} orderNumber - The order number
 * @param {string} promotionCode The promotion code being applied.
 *
 * @returns {import('../../types').ThunkResult<Promise<any>>}
 */
export default function addPromotionCodeAndUpdatePaymentMethods(organizationId, orderNumber, promotionCode) {
  return function addPromotionCodeAndUpdatePaymentMethodsSideEffects(dispatch, getState) {
    const isNonShopifyDiscounts = isDiscountsEnabled(getState());
    const action = isNonShopifyDiscounts ? addNonShopifyPromoCode : addPromotionCode;

    dispatch(showLoadingIndicator());
    return dispatch(action(organizationId, orderNumber, promotionCode))
      .then(checkHttpStatus)
      .then(() => dispatch(updatePaymentMethods(organizationId)))
      .then(() => dispatch(hideLoadingIndicator()))
      .catch((error) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
          // GenericError
          // PermissionError
          // void
            return error.response;
          default:
            throw error;
        }
      });
  };
}
