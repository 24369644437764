import { FormattedMessage } from 'react-intl';
import React from 'react';

import { Props } from '../types';
import ConfirmDialog from '../../confirm-dialog';

const SHOPIFY_CHECKOUT_URL = '/checkout';

const ShopifyDomesticShippingUnavailableDialog: React.FunctionComponent<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
  orderError,
}) => {
  const destinationCountry = orderError != null ? orderError.destination_country : undefined;
  const countryName = destinationCountry != null ? destinationCountry.name : undefined;

  const handleConfirm = React.useCallback<React.MouseEventHandler>((event) => {
    if (onConfirm != null) {
      onConfirm(event);
    }

    window.location.assign(SHOPIFY_CHECKOUT_URL);
  }, []);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      {{
        content: (
          <>
            <p>
              <FormattedMessage
                id="checkout_shopify_us_store_redirect_content1"
                description="Line #1 of message in dialog that is displayed when the a US ship address is entered at Shopify intl checkout"
                defaultMessage="You have selected {countryName} as your shipping destination country."
                values={{ countryName }}
              />
            </p>
            <p>
              <FormattedMessage
                id="checkout_shopify_us_store_redirect_content2"
                description="Line #2 of message in dialog that is displayed when the a US ship address is entered at Shopify intl checkout"
                defaultMessage="You will be redirected to our {countryName} store to continue checkout"
                values={{ countryName }}
              />
            </p>
          </>
        ),
        header: (
          <FormattedMessage
            id="checkout_shopify_us_store_redirect_header"
            description="Header of dialog that is displayed when the a US ship address is entered at Shopify intl checkout"
            defaultMessage="Shipping to {countryName}?"
            values={{ countryName }}
          />
        ),

      }}
    </ConfirmDialog>
  );
};

export default ShopifyDomesticShippingUnavailableDialog;
