import { connect, MapStateToProps } from 'react-redux';
import { getOrganization } from '../../../store/flow';
import { RootState } from '../../../store/types';
import ConfirmationPageV2 from '../components/confirmation-page-v2';
import { OwnProps, StateProps } from '../types';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  organization: getOrganization(state),
});

export default connect<StateProps, OwnProps, {}, RootState>(mapStateToProps)(ConfirmationPageV2);
