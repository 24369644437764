import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cart: {
    id: 'checkout_breadcrumbs_label_cart',
    description: 'A title identifying the section of the checkout flow that shows the items in the order',
    defaultMessage: 'Cart',
  },
  customerInfo: {
    id: 'checkout_breadcrumbs_label_customer_info',
    description: 'A title denoting a section of the checkout experience for capturing customer information',
    defaultMessage: 'Customer Info',
  },
  shippingMethod: {
    id: 'checkout_breadcrumbs_label_shipping_method',
    description: 'A title denoting a section of the checkout experience for capturing customer preferences for shipping the order',
    defaultMessage: 'Shipping Method',
  },
  paymentInfo: {
    id: 'checkout_breadcrumbs_label_payment_info',
    description: 'A title denoting a section of the checkout experience for collecting payment information for the order',
    defaultMessage: 'Payment Info',
  },
  review: {
    id: 'checkout_breadcrumbs_label_review',
    description: 'A title denoting a section of the checkout experience for reviewing order details',
    defaultMessage: 'Review Order',
  },
});

export default messages;
