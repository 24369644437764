/* eslint-disable react/no-danger, @typescript-eslint/unbound-method */

import BemHelper from '@flowio/bem-helper';
import React from 'react';
import DOMPurify from 'dompurify';

import { Dialog, DialogHeader, DialogBody } from '../dialog';
import ContentLabel from '../content-label';
import Message from '../message';
import Spinner from '../spinner';
import checkHttpStatus from '../../utilities/check-http-status';

import './load.css';

interface Props {
  className?: string;
  content: io.flow.internal.v0.models.Load;
}

const bem = new BemHelper('load');

const initialState = {
  html: '',
  open: false,
  loading: false,
  error: false,
};

type State = typeof initialState;

class Load extends React.Component<Props, State> {
  public static displayName = 'Load';

  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = initialState;
    this.handleClick = this.handleClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  public handleRequestClose(): void {
    this.close();
  }

  public handleClick(event: React.MouseEvent): void {
    event.preventDefault();
    this.open();
    this.fetch();
  }

  public open(): void {
    this.setState({ open: true });
  }

  public close(): void {
    this.setState({ open: false });
  }

  public fetch(): void {
    const { content } = this.props;
    const { loading, html } = this.state;

    if (loading || html) return;

    this.setState({
      error: false,
      loading: true,
    }, (): void => {
      fetch(content.location.url)
        .then(checkHttpStatus)
        .then((response: Response): Promise<string> => response.text())
        .then((response): void => {
          this.setState({ loading: false, html: response });
        })
        .catch((): void => {
          this.setState({ loading: false, error: true });
        });
    });
  }

  public render(): React.ReactNode {
    const { className, content } = this.props;

    const {
      error, html, loading, open,
    } = this.state;

    let children: React.ReactNode;

    if (error) {
      children = (
        <Message color="negative">
          <Message.Text>
            An error has occurred. Please try again later.
          </Message.Text>
        </Message>
      );
    } else if (loading) {
      children = (
        <Spinner className={bem.element('spinner')} />
      );
    } else {
      children = (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
      );
    }

    return (
      <a
        className={bem.block(className)}
        href={content.location.url}
        onClick={this.handleClick}
      >
        <ContentLabel content={content.label} />
        <Dialog
          className={bem.element('dialog')}
          isOpen={open}
          showBackdrop
          enableBackdropDismiss
          onRequestClose={this.handleRequestClose}
        >
          <DialogHeader
            text={(
              <ContentLabel content={content.label} />
            )}
          />
          <DialogBody>
            {children}
          </DialogBody>
        </Dialog>
      </a>
    );
  }
}

export default Load;
