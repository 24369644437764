import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Row, Column } from '../grid';
import Button from '../button';
import Section from '../section';
import FeatureToggle from '../feature-toggle';
import { OPTIMIZE_MOBILE_CTAS } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./complete-order.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('complete-order');

const CompleteOrder = ({
  continueShoppingUrl,
  onRequestCheckout,
  totalPrice,
}) => (
  <Section className={bem.block()}>
    <Section.Header divided className="hidden-md-down">
      <Section.Title>
        <FormattedMessage
          id="checkout_cart_subheader_complete_your_order"
          description="A subheader denoting the section of cart where totals can be reviewed and an action to continue checking out can be taken."
          defaultMessage="Complete Your Order"
        />
      </Section.Title>
    </Section.Header>
    <Section.Content>
      <Grid>
        <Row>
          <Column xs={12}>
            <dl className={bem.element('total')}>
              <dt className={bem.element('total-label')}>
                <FormattedMessage
                  id="checkout_cart_label_subtotal"
                  description="A label denoting the total amount of money the customer must pay for the order"
                  defaultMessage="Subtotal"
                />
              </dt>
              <dd className={bem.element('total-value')}>{totalPrice}</dd>
            </dl>
          </Column>
        </Row>
        <Row>
          <Column xs={12}>
            <div className={bem.element('actions')}>
              <Row>
                <Column md={6} lg={12}>
                  <Button fluid color="positive" onClick={onRequestCheckout}>
                    <FormattedMessage
                      id="checkout_cart_action_proceed_checkout"
                      description="Text displayed on the action to continue to the process of submitting fund in exchange for goods or services"
                      defaultMessage="Proceed to Checkout"
                    />
                  </Button>
                </Column>
                <Column md={6} lg={12} lgHidden>
                  <FeatureToggle
                    featureKey={OPTIMIZE_MOBILE_CTAS}
                    render={({ isFeatureEnabled }) => {
                      if (isFeatureEnabled) {
                        return (
                          <Button
                            flat
                            fluid
                            href={continueShoppingUrl}
                            text={(
                              <FormattedMessage
                                id="checkout_cart_action_continue_shopping"
                                description="Text on the action to navigate back to the store experience"
                                defaultMessage="Continue Shopping"
                              />
                            )}
                          />
                        );
                      }
                      return (
                        <Button
                          fluid
                          href={continueShoppingUrl}
                          color="tertiary"
                          text={(
                            <FormattedMessage
                              id="checkout_cart_action_continue_shopping"
                              description="Text on the action to navigate back to the store experience"
                              defaultMessage="Continue Shopping"
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Column>
              </Row>

              <Row>
                <Column md={12} lg={12}>
                  <p className={bem.element('notice')}>
                    <FormattedMessage
                      id="checkout_cart_text_total_excludes_tax_and_shipping"
                      description="A message denoting that the total of the order at this point in the ordering process does not include taxes or shipping charges which will be applied later."
                      defaultMessage="Total excludes tax and any applicable shipping charges (i.e. rush shipping)"
                    />
                  </p>
                </Column>
              </Row>
            </div>
          </Column>
        </Row>
      </Grid>
    </Section.Content>
  </Section>
);

CompleteOrder.displayName = 'CompleteOrder';

CompleteOrder.propTypes = {
  continueShoppingUrl: PropTypes.string,
  onRequestCheckout: PropTypes.func.isRequired,
  totalPrice: PropTypes.string.isRequired,
};

CompleteOrder.defaultProps = {
  continueShoppingUrl: '/',
};

export default CompleteOrder;
