import OrderErrorTypes from '../constants/order-error-types';

/**
 * An OrderBalanceError is thrown when an order cannot be submitted due to a pending balance.
 */
export default function OrderBalanceError() {
  const error = new Error('Cannot submit order with pending balance');
  error.name = 'OrderBalanceError';
  error.response = {
    status: 422,
    result: {
      code: OrderErrorTypes.ORDER_BALANCE,
    },
  };
  return error;
}
