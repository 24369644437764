import type { $HttpUnprocessableEntity, ShopifyOrderDetailsPutPromotionByNumberResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { AddPromotionActionParameters } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<ShopifyOrderDetailsPutPromotionByNumberResponse>
>;

const addPromotionCodeRequest = (
  parameters: AddPromotionActionParameters,
): RootAction => ({
  type: actionTypes.ADD_PROMOTION_CODE_REQUEST,
  params: parameters,
});

const addPromotionCodeSuccess = (
  parameters: AddPromotionActionParameters,
  order: io.flow.experience.v0.models.Order,
): RootAction => ({
  type: actionTypes.ADD_PROMOTION_CODE_SUCCESS,
  params: parameters,
  payload: order,
});

const addPromotionCodeFailure = (
  parameters: AddPromotionActionParameters,
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.ADD_PROMOTION_CODE_FAILURE,
  params: parameters,
  payload: error,
});

/**
 * Creates an action responsible for applying a promotion code
 */
const addPromotionCode = (
  organizationId: string,
  orderNumber: string,
  promotionCode: string,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  const parameters = {
    organization: organizationId,
    orderNumber,
    promotionCode,
  };

  dispatch(addPromotionCodeRequest(parameters));

  return extra.apiInternal.shopifyOrderDetails.putPromotionByNumber({
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
    body: {
      code: promotionCode,
    },
  })
    .catch((error) => {
      extra.rollbar.warning('An exception was raised while submitting promotion code', error);

      // HACK: This request is subject to rate limiting, which results in a
      // CORS violation due to the way it is implemented. Since there isn't a
      // reliable way to determine whether an exception is a CORS violation,
      // it's assumed this block is only executed when the violation occurs.
      // https://flowio.atlassian.net/browse/CHEC-2118
      return Promise.resolve({
        status: 422,
        ok: false,
        body: {
          code: 'generic_error',
          // This is the message we want to show when rate limiting occurs.
          messages: ['The provided code is invalid.'],
        },
      } as $HttpUnprocessableEntity<io.flow.error.v0.models.GenericError>);
    })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(addPromotionCodeSuccess(parameters, response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(addPromotionCodeFailure(parameters, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default addPromotionCode;
