import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getLoyaltyProgram, getGiftCardProgram } from '../store/checkout/selectors';
import Rewards from '../components/rewards';

const mapStateToProps = createStructuredSelector({
  loyalty: getLoyaltyProgram,
  giftCardProgram: getGiftCardProgram,
});

export default compose(
  connect(mapStateToProps),
)(Rewards);
