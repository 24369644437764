/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const CartWarning = (props) => (
  <SvgIcon viewBox="0 0 87 68" {...props}>
    <g strokeWidth="1" fillRule="evenodd">
      <path d="M9.515625,2.72 L23.1618361,57.3273644 L25.7995591,56.6682605 L11.9097734,1.030448 C11.7585945,0.42487636 11.2147989,0 10.5909119,0 L1.359375,0 C0.608612918,0 0,0.60889274 0,1.36 C0,2.11110726 0.608612918,2.72 1.359375,2.72 L9.515625,2.72 Z" />
      <path d="M32.625,61.2 C32.625,57.4444636 29.5819355,54.4 25.828125,54.4 C22.0743146,54.4 19.03125,57.4444636 19.03125,61.2 C19.03125,64.9555364 22.0743146,68 25.828125,68 C29.5819355,68 32.625,64.9555364 32.625,61.2 Z M21.75,61.2 C21.75,58.9466783 23.5758388,57.12 25.828125,57.12 C28.0804112,57.12 29.90625,58.9466783 29.90625,61.2 C29.90625,63.4533217 28.0804112,65.28 25.828125,65.28 C23.5758388,65.28 21.75,63.4533217 21.75,61.2 Z" />
      <path d="M70.6875,61.2 C70.6875,57.4444636 67.6444355,54.4 63.890625,54.4 C60.1368145,54.4 57.09375,57.4444636 57.09375,61.2 C57.09375,64.9555364 60.1368145,68 63.890625,68 C67.6444355,68 70.6875,64.9555364 70.6875,61.2 Z M59.8125,61.2 C59.8125,58.9466783 61.6383388,57.12 63.890625,57.12 C66.1429112,57.12 67.96875,58.9466783 67.96875,61.2 C67.96875,63.4533217 66.1429112,65.28 63.890625,65.28 C61.6383388,65.28 59.8125,63.4533217 59.8125,61.2 Z" />
      <path d="M59.987103,16.32 L14.953125,16.32 L14.953125,19.04 L58.3862022,19.04 M69.7935353,31.9743092 L65.5490641,48.96 L23.109375,48.96 L23.109375,51.68 L66.609375,51.68 C67.2331481,51.68 67.7768753,51.2552755 67.9281626,50.6498484 C67.9281626,50.6498484 71.0768775,38.0921183 72.6056269,31.9743092" />
      <polygon points="58.453125 62.56 58.453125 59.84 31.265625 59.84 31.265625 62.56" />
      <path d="M84.0758333,29.6214634 L60.0179167,29.6214634 C58.9545833,29.6214634 58.0241667,29.0907317 57.4925,28.1619512 C56.9608333,27.2663415 56.9608333,26.1717073 57.4925,25.2760976 L69.5214583,4.44487805 C70.053125,3.54926829 70.9835417,2.98536585 72.046875,2.98536585 L72.046875,2.98536585 C73.1102083,2.98536585 74.040625,3.51609756 74.5722917,4.44487805 L86.60125,25.2760976 C87.1329167,26.1717073 87.1329167,27.2663415 86.60125,28.1619512 C86.0695833,29.0907317 85.1391667,29.6214634 84.0758333,29.6214634 Z M59.3865625,26.3707317 C59.1871875,26.702439 59.3201042,27.0009756 59.3865625,27.1004878 C59.4530208,27.2 59.6523958,27.4653659 60.0179167,27.4653659 L84.1090625,27.4653659 C84.4745833,27.4653659 84.6739583,27.2 84.7404167,27.1004878 C84.806875,27.0009756 84.9397917,26.702439 84.7404167,26.3707317 L72.6782292,5.5395122 C72.4788542,5.20780488 72.1797917,5.17463415 72.046875,5.17463415 C71.9139583,5.17463415 71.6148958,5.20780488 71.4155208,5.5395122 L59.3865625,26.3707317 Z" />
      <path d="M72.046875,21.7268293 C71.44875,21.7268293 71.05,21.2292683 71.05,20.6321951 L71.05,11.5102439 C71.05,10.9131707 71.44875,10.4156098 72.046875,10.4156098 C72.645,10.4156098 73.04375,10.9131707 73.04375,11.5102439 L73.04375,20.6321951 C73.04375,21.2292683 72.645,21.7268293 72.046875,21.7268293 Z" />
      <ellipse cx="72.046875" cy="24.5463415" rx="1.2959375" ry="1.29365854" />
    </g>
  </SvgIcon>
);

CartWarning.displayName = 'CartWarning';

export default CartWarning;
