/**
 * Enums for prompt targets supported by Flow API
 * @see https://app.apibuilder.io/flow/api-internal/0.4.7#enum-prompt_target
 */
const PromptTarget = {
  BROWSE: 'browse',
  CHECKOUT: 'checkout',
};

export default PromptTarget;
