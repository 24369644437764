import includes from 'lodash/includes';

import { toOrderPutForm } from '../../../utilities/order-converters';
import { getOrderDenormalized } from '../selectors';
import updateOrderBuilder from './update-order-builder';

/**
 * Creates an thunk action responsible for removing items from the order.
 *
 * NOTE: This action creator cannot be dispatched to remove all items in an
 * order because such operation is not allowed.
 *
 * @param {string} organizationId
 * @param {string} orderNumber
 * @param {string[]} numbers
 * @returns {ThunkAction<Promise<ApiResponse>, object, void>}
 */
export default function removeItems(organizationId, orderNumber, numbers) {
  return function removeItemsEffects(dispatch, getState) {
    const previousOrder = getOrderDenormalized(getState());
    const previousOrderPutForm = toOrderPutForm(previousOrder);
    const nextOrderPutForm = {
      ...previousOrderPutForm,
      items: previousOrderPutForm.items.filter((item) => !includes(numbers, item.number)),
    };

    return dispatch(updateOrderBuilder(organizationId, orderNumber, nextOrderPutForm));
  };
}
