import { push, replace } from 'react-router-redux';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import get from 'lodash/get';

import { getConfirmationUrlV2 } from '../selectors';
import { getIsDefaultIntegration } from '../../flow/selectors';
import {
  getOrderAttributes,
  getCheckoutId,
  isConfirmationRedirectLogFeatureEnabled,
} from '../../checkout/selectors';
import { redirect } from '../../application/actions';
import OrderAttributes from '../../../constants/order-attributes';
import { trackHeapEvent } from '../../../utilities/heap';
import { RedirectMethod } from '../types';
import { ThunkResult } from '../../types';

const confirmRedirect = (
  message: string,
  url: string,
  checkoutId?: string,
) => {
  getRollbar((rollbar, extra = {}) => {
    rollbar.info(message, {
      ...extra,
      url,
      checkoutId,
    });
  });
};

/**
 * Navigates to confirmation page based on the current application state.
 */
export default function visitConfirmationStep(
  method: RedirectMethod = 'push',
): ThunkResult<Promise<void>> {
  return function visitConfirmationStepSideEffects(dispatch, getState): Promise<void> {
    return new Promise((resolve) => {
      const state = getState();
      const shouldLogConfirmationRedirect = isConfirmationRedirectLogFeatureEnabled(state);
      const checkoutId = getCheckoutId(state);
      const location = getConfirmationUrlV2(state);
      const returnUrl = get(getOrderAttributes(state), OrderAttributes.RETURN_URL);
      const isDefaultIntegration = getIsDefaultIntegration(state);

      if (isDefaultIntegration && returnUrl) {
        trackHeapEvent('redirect_return_url', {
          returnUrl,
        });

        if (shouldLogConfirmationRedirect) {
          confirmRedirect('redirecting to return url', returnUrl, checkoutId);
        }
        setTimeout(() => {
          dispatch(redirect(returnUrl));
          resolve();
        }, 0);
        return;
      }

      trackHeapEvent('redirect_confirmation_page', {
        location,
      });
      if (shouldLogConfirmationRedirect) {
        confirmRedirect('redirecting to confirmation url', location, checkoutId);
      }
      if (method === 'push') {
        setTimeout(() => {
          dispatch(push(location));
          resolve();
        }, 0);
      } else {
        setTimeout(() => {
          dispatch(replace(location));
          resolve();
        }, 0);
      }
    });
  };
}
