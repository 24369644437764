import React from 'react';

type ForceUpdate = () => void;

const useForceUpdate = (): ForceUpdate => {
  const [, forceUpdate] = React.useState(false);
  return React.useCallback(() => {
    forceUpdate((state) => !state);
  }, []);
};

export default useForceUpdate;
