/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getElementType, getUnhandledProps, isElementOfType } from '@flowio/react-helpers';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';

import PanelContent from './panel-content';
import PanelHeader from './panel-header';
import PanelTitle from './panel-title';

if (process.browser) {
  require('./panel.css');
}

const bem = new BemHelper('panel');

class Panel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { expanded, defaultExpanded } = this.props;
    return {
      expanded: isNil(expanded) ? defaultExpanded : expanded,
      controlled: !isNil(expanded),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { expanded: prevExpanded } = this.props;
    const { expanded: nextExpanded } = nextProps;

    if (!isNil(nextExpanded) && prevExpanded !== nextExpanded) {
      this.setState({ expanded: nextExpanded, controlled: true });
    }
  }

  handleHeaderClick = (event) => {
    const { collapsible, eventKey, onSelect } = this.props;
    const { controlled, expanded } = this.state;

    if (collapsible) {
      if (!controlled) {
        this.setState({ expanded: !expanded });
      }
    }

    onSelect(event, eventKey);
  }

  renderChildren() {
    const { children, collapsible } = this.props;
    const { expanded } = this.state;

    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (isElementOfType(child, PanelHeader)) {
        return React.cloneElement(child, {
          ...child.props,
          collapsible,
          expanded,
          onClick: this.handleHeaderClick,
        });
      }

      if (isElementOfType(child, PanelContent)) {
        return React.cloneElement(child, {
          ...child.props,
          collapsible,
          expanded,
        });
      }

      return child;
    });
  }

  render() {
    const {
      children, className, content, collapsible, title,
    } = this.props;
    const { expanded } = this.state;
    const ElementType = getElementType(Panel, this.props);
    const unhandledProps = getUnhandledProps(Panel, this.props);
    const modifiers = {
      expanded: collapsible && expanded,
    };

    if (!isNil(children)) {
      return (
        <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
          {this.renderChildren()}
        </ElementType>
      );
    }

    return (
      <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
        <PanelHeader
          collapsible={collapsible}
          expanded={expanded}
          onClick={this.handleHeaderClick}
          title={title}
        />
        <PanelContent
          collapsible={collapsible}
          expanded={expanded}
          content={content}
        />
      </ElementType>
    );
  }
}

Panel.Content = PanelContent;

Panel.Header = PanelHeader;

Panel.Title = PanelTitle;

Panel.displayName = 'Panel';

Panel.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  content: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  eventKey: PropTypes.string,
  expanded: PropTypes.bool,
  onSelect: PropTypes.func,
  title: PropTypes.node,
};

Panel.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  collapsible: false,
  content: undefined,
  defaultExpanded: false,
  eventKey: undefined,
  expanded: undefined,
  onSelect: noop,
  title: undefined,
};

export default Panel;
