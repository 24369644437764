import { BaseFieldProps, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import ReduxFormTextField, {
  BaseProps as ReduxFormTextFieldBaseProps,
} from '../redux-form/text-field';

type Props = {
  addressType?: AddressType;
  required?: boolean;
};

const PhoneNumber: React.FunctionComponent<Props> = ({
  addressType,
  required = false,
}) => (
  <Field<BaseFieldProps<ReduxFormTextFieldBaseProps>>
    component={ReduxFormTextField}
    props={{
      autoComplete: autoCompleteToken('tel', addressType),
      automationId: automationToken('telephone', addressType),
      labelText: (
        <FormattedMessage
          id="checkout_address_field_label_telephone"
          description="Content for telephone field label in address forms"
          defaultMessage="Telephone"
        />
      ),
      required,
      type: 'tel',
    }}
    name="phoneNumber"
  />
);

export default PhoneNumber;
