import {
  isCatchpointRumEnabled,
  getOrderTotalAmount,
  getOrderItems,
} from '../selectors';
import triggerTransactionEvent from './trigger-transaction-event';
import clearShopifyCart from './clear-shopify-cart';

import getRProfiler from '../../../utilities/get-rprofiler';

/**
 * Creates an action responsible for marking the order as submitted and
 * recording a beacon transaction for fraud service.
 *
 * The promise returned when the action is dispatched either resolves with
 * nothing or rejects with an HttpStatusError when response for order submission
 * is outside the 2xx status code range.
 *
 * @param {String} organizationId
 */
function createTransactionEvents(organizationId) {
  const unused = organizationId; // eslint-disable-line no-unused-vars
  return function createTransactionEventsSideEffects(dispatch, getState) {
    // So we do this because we do not care if anything within create transaction event fails/throws
    // Nothing in that function should ever affect the main order submission promise chain so we always
    // return a promise.resolve();
    try {
      const state = getState();
      return Promise.resolve()
        .then(() => dispatch(triggerTransactionEvent()))
        .then(() => dispatch(clearShopifyCart()))
        .then(() => {
          if (isCatchpointRumEnabled(state)) {
            const orderTotal = getOrderTotalAmount(state);
            const orderItems = getOrderItems(state);
            getRProfiler((profiler) => profiler.addInfo('conversion', orderTotal, orderItems.length));
          }
        });
    } catch {
      return Promise.resolve();
    }
  };
}

export default createTransactionEvents;
