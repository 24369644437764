import fetchPaymentMethodRules from './fetch-payment-method-rules';
import fetchPaymentMethodTypes from './fetch-payment-method-types';
import { getOrderExperienceKey, getOrder, getIsPaymentRequired } from '../selectors';

export default function updatePaymentMethods(organizationId) {
  return function updatePaymentMethodsSideEffects(dispatch, getState) {
    const state = getState();
    const experienceKey = getOrderExperienceKey(state);
    const order = getOrder(state);
    const promises = [];

    if (getIsPaymentRequired(state)) {
      promises.push(dispatch(fetchPaymentMethodTypes(organizationId, experienceKey)));
      promises.push(dispatch(fetchPaymentMethodRules(organizationId, experienceKey, {
        amount: order.balance.amount,
        currency: order.balance.currency,
      })));
    }

    return Promise.all(promises);
  };
}
