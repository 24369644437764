import { PaymentMethodRulesGetPaymentMethodRulesResponse } from '@flowio/api-sdk';
import { getSessionId } from '../../../utilities/session';
import { usePagingAndSorting, PaginationResult } from '../../../utilities/use-paging-and-sorting';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { FetchPaymentMethodRulesActionParameters } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<PaymentMethodRulesGetPaymentMethodRulesResponse>
>;

interface Options {
  amount?: string;
  entriesPerPage?: string | number;
  pageNumber?: string | number;
  currency?: string;
}

const fetchPaymentMethodRulesRequest = (
  params: FetchPaymentMethodRulesActionParameters,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_RULES_REQUEST,
  params,
});

const fetchPaymentMethodRulesSuccess = (
  params: FetchPaymentMethodRulesActionParameters,
  paginationResult: PaginationResult<io.flow.v0.models.PaymentMethodRule>,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_RULES_SUCCESS,
  params,
  payload: paginationResult,
});

const fetchPaymentMethodRulesFailure = (
  params: FetchPaymentMethodRulesActionParameters,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_RULES_FAILURE,
  params,
  payload: error,
});

const fetchPaymentMethodRules = (
  organizationId: string,
  experienceKey: string,
  options: Options = {},
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const {
    entriesPerPage = 100,
    pageNumber = 1,
    amount,
    currency,
  } = options;

  const {
    limit,
    offset,
    paginationResult,
  } = usePagingAndSorting({
    entriesPerPage,
    pageNumber,
  });

  const sessionId = getSessionId();

  const params: FetchPaymentMethodRulesActionParameters = {
    entriesPerPage,
    experienceKey,
    organization: organizationId,
    pageNumber,
    sessionId,
    amount,
    currency,
  };

  dispatch(fetchPaymentMethodRulesRequest(params));
  return extra.api.paymentMethodRules.getPaymentMethodRules({
    organization: organizationId,
    amount,
    currency,
    experience: experienceKey,
    limit,
    offset,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchPaymentMethodRulesSuccess(params, paginationResult(response.result)));
          break;
        case 401:
        case 404:
          dispatch(fetchPaymentMethodRulesFailure(params, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchPaymentMethodRules;
