import { BaseFieldProps, Field, EventWithDataHandler } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { automationToken } from './utilities';
import { AddressType } from './types';
import bem from './bem';
import ReduxFormTextField, {
  BaseProps as ReduxFormTextFieldBaseProps,
} from '../redux-form/text-field';
import Check from '../svg-icons/check';
import CircleLoader from '../svg-icons/circle-loader';

type Props = {
  addressType?: AddressType;
  required?: boolean;
  children?: never;
  onChange?: EventWithDataHandler<React.ChangeEvent<HTMLInputElement>>;
  loading?: boolean;
  valid?: boolean;
};

const VatRegistrationNumber: React.FunctionComponent<Props> = ({
  addressType,
  onChange,
  required = false,
  loading = false,
  valid = false,
}) => {
  let icon;

  if (valid) {
    icon = <Check className={bem.element('vrn-success')} />;
  } else if (loading) {
    icon = <CircleLoader />;
  }

  return (
    <Field<BaseFieldProps<ReduxFormTextFieldBaseProps>>
      component={ReduxFormTextField}
      name="vrn"
      onChange={onChange}
      props={{
        automationId: automationToken('vrn', addressType),
        icon,
        labelText: (
          <FormattedMessage
            id="checkout_address_field_vrn"
            description="Content for VRN for a company"
            defaultMessage="VAT Registration Number"
          />
        ),
        required,
      }}
    />
  );
};

export default VatRegistrationNumber;
