import keyBy from 'lodash/keyBy';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { AdministrativeDivisionState } from '../types';

const initialState: AdministrativeDivisionState = {};

const administrativeDivisionsReducer: AppReducer<AdministrativeDivisionState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_ADMINISTRATIVE_DIVISIONS_SUCCESS:
      return update(state, {
        $merge: keyBy(action.payload, (_) => _.id),
      });
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return update(state, {
        $merge: keyBy(action.payload.provinces, (_) => _.id),
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      // The `AddressConfigurationProvince` and `Province` models aren't
      // compatible and was removed. Tread carefully.
      return state;
    default:
      return state;
  }
};

export default administrativeDivisionsReducer;
