import React from 'react';

import SvgIcon from '../svg-icon';

type OwnProps = {
  children?: never;
  viewBox?: string;
};

type UnhandledProps = Omit<
JSX.LibraryManagedAttributes<
    typeof SvgIcon,
React.ComponentProps<typeof SvgIcon>
>,
keyof OwnProps
>

type Props = OwnProps & UnhandledProps;

const CircleLoader: React.FunctionComponent<Props> = ({
  preserveAspectRatio = 'xMidYMid',
  strokeWidth = '0',
  viewBox = '0 0 100 100',
  ...unhandledProps
}) => (
  <SvgIcon
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...unhandledProps}
    viewBox={viewBox}
    strokeWidth={strokeWidth}
    preserveAspectRatio={preserveAspectRatio}
  >
    <g transform="rotate(-54, 50, 50)">
      <g transform="translate(82,50) rotate(0)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="1">
          <animateTransform attributeName="transform" type="scale" begin="-1.0799999999999998s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-1.0799999999999998s" />
        </circle>
      </g>
      <g transform="translate(75.88854381999832,68.80912807335915) rotate(36)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.9">
          <animateTransform attributeName="transform" type="scale" begin="-0.96s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.96s" />
        </circle>
      </g>
      <g transform="translate(59.88854381999832,80.43380852144492) rotate(72)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.8">
          <animateTransform attributeName="transform" type="scale" begin="-0.8400000000000001s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.8400000000000001s" />
        </circle>
      </g>
      <g transform="translate(40.11145618000168,80.43380852144492) rotate(108)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.7">
          <animateTransform attributeName="transform" type="scale" begin="-0.72s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.72s" />
        </circle>
      </g>
      <g transform="translate(24.111456180001685,68.80912807335915) rotate(144)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.6">
          <animateTransform attributeName="transform" type="scale" begin="-0.6s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.6s" />
        </circle>
      </g>
      <g transform="translate(18,50.00000000000001) rotate(180)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.5">
          <animateTransform attributeName="transform" type="scale" begin="-0.48s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.48s" />
        </circle>
      </g>
      <g transform="translate(24.11145618000168,31.190871926640863) rotate(216)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.4">
          <animateTransform attributeName="transform" type="scale" begin="-0.36s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.36s" />
        </circle>
      </g>
      <g transform="translate(40.11145618000168,19.566191478555087) rotate(252)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.3">
          <animateTransform attributeName="transform" type="scale" begin="-0.24s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.24s" />
        </circle>
      </g>
      <g transform="translate(59.88854381999831,19.566191478555083) rotate(288)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.2">
          <animateTransform attributeName="transform" type="scale" begin="-0.12s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="-0.12s" />
        </circle>
      </g>
      <g transform="translate(75.88854381999832,31.190871926640856) rotate(324)">
        <circle cx="0" cy="0" r="4" fill="#0051b2" fillOpacity="0.1">
          <animateTransform attributeName="transform" type="scale" begin="0s" values="1 1;1 1" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" />
          <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.2s" repeatCount="indefinite" values="1;0" begin="0s" />
        </circle>
      </g>
    </g>
  </SvgIcon>
);

export default CircleLoader;
