import type { CardPaymentSourcesPostResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CardPaymentSourcesPostResponse>>;

const createCardPaymentSourceRequest = (): RootAction => ({
  type: actionTypes.CREATE_CARD_PAYMENT_SOURCE_REQUEST,
});

const createCardPaymentSourceSuccess = (
  cardPaymentSource: io.flow.v0.models.CardPaymentSource,
): RootAction => ({
  type: actionTypes.CREATE_CARD_PAYMENT_SOURCE_SUCCESS,
  payload: cardPaymentSource,
});

const createCardPaymentSourceFailure = (
  error: io.flow.v0.models.PaymentError | LegacyError,
): RootAction => ({
  type: actionTypes.CREATE_CARD_PAYMENT_SOURCE_FAILURE,
  payload: error,
});

const createCardPaymentSource = (
  organizationId: string,
  cardPaymentSourceForm: io.flow.v0.models.CardPaymentSourceForm,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(createCardPaymentSourceRequest());
  return extra.api.cardPaymentSources.post({
    body: cardPaymentSourceForm,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 201:
          dispatch(createCardPaymentSourceSuccess(response.result));
          break;
        case 401:
        case 422:
          dispatch(createCardPaymentSourceFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default createCardPaymentSource;
