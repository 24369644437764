/* eslint-disable import/prefer-default-export */

/**
 * Back end mistakenly refers to the BitPay payment method as 'Bitcoin'.
 * This hack makes it display as intended for the user.
 */
export function translatePaymentMethodName(name) {
  switch (name.trim()) {
    case 'Bitcoin':
      return 'BitPay';
    default:
      return name;
  }
}
