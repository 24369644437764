import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import {
  fetchCart,
  removeCartItem,
  updateCartItemQuantity,
  transitionToCheckout,
} from '../store/cart/actions';
import { fetchContent } from '../store/content/actions';
import {
  getCart,
  getCartError,
  getCartItems,
  getIsCartLoading,
} from '../store/cart/selectors';
import { getLocale } from '../store/intl/selectors';
import { getOrderError, getCheckoutError, getContinueShoppingUrl } from '../store/checkout/selectors';
import { getOrganization } from '../store/flow/selectors';
import { checkFeatureValues } from '../store/checkout/actions';
import Cart from '../components/cart';
import withExternalEvents from '../components/with-external-events';

function getAsyncState(dispatch, getState, props) {
  const { params } = props;
  const { organization, cartId } = params;
  const state = getState();
  const locale = getLocale(state);

  const featureValueForm = {
    locale,
  };
  return Promise.all([
    dispatch(fetchCart(cartId)),
    dispatch(fetchContent(organization, { params: { locale } })),
    dispatch(checkFeatureValues(organization, featureValueForm)),
  ]);
}

const mapStateToProps = createStructuredSelector({
  cart: getCart,
  continueShoppingUrl: getContinueShoppingUrl,
  error: getCartError,
  orderError: getOrderError,
  checkoutError: getCheckoutError,
  items: getCartItems,
  loading: getIsCartLoading,
  organization: getOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  onRequestQuantityChange(cartId, variantId, quantity) {
    dispatch(updateCartItemQuantity(cartId, variantId, quantity));
  },
  onRequestRemove(cartId, variantId) {
    dispatch(removeCartItem(cartId, variantId));
  },
  onRequestCheckout(organization, cartId) {
    dispatch(transitionToCheckout(organization, cartId));
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
  withExternalEvents(),
)(Cart);
