import React from 'react';
import FlowContext from '../contexts/flow';
import type { FlowContextType } from '../contexts/flow';

const useFlowContext = (): FlowContextType => {
  const context = React.useContext(FlowContext);

  if (!context) {
    throw new Error(
      'Could not find flow context value. '
      + 'Please ensure the component is wrapped in flow context provider.',
    );
  }

  return context;
};

export default useFlowContext;
