import { createSelector } from 'reselect';
import get from 'lodash/get';

import { RootState } from '../../types';
import { ShopifyState, ShopifyCustomerState } from '../types';

export const getShopifyState = (
  state: RootState,
): ShopifyState | undefined => get(state, 'shopify');

export const getShopifyDomain = createSelector(
  getShopifyState,
  (state): string | undefined => get(state, 'entities.shop.myshopify_domain'),
);

export const getPathPrefix = createSelector(
  getShopifyState,
  (state): string => get(state, 'pathPrefix', ''),
);

export const getShopifyCustomer = createSelector(
  getShopifyState,
  (state): ShopifyCustomerState | undefined => get(state, 'entities.customer'),
);
