import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

import { toCardForm } from '../containers/payment-form/utilities';
import HttpStatusError from './http-status-error';

export default function createCardToken(
  values,
  flow,
  shippingAddress,
  addressBookContacts,
) {
  return new Promise((resolve, reject) => {
    const cardForm = toCardForm(values, shippingAddress, addressBookContacts);
    flow.createToken(cardForm, (status, response) => {
      if (response == null || response.error != null) {
        getRollbar((rollbar, extra = {}) => {
          rollbar.warning('Card tokenization failed', response && response.error, extra);
        });

        reject(new HttpStatusError({
          status,
          result: response && response.error,
        }));
      } else {
        resolve(response);
      }
    });
  });
}
