import type { V1CheckoutsPutSubmissionsByIdResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<V1CheckoutsPutSubmissionsByIdResponse>>;

const submitOrderCheckoutRequest = (): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_CHECKOUT_REQUEST,
});

const submitOrderCheckoutSuccess = (
  checkoutSubmission: io.flow.internal.v0.models.CheckoutSubmission,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_CHECKOUT_SUCCESS,
  payload: checkoutSubmission,
});

const submitOrderCheckoutFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.SUBMIT_ORDER_CHECKOUT_FAILURE,
  payload: error,
});

/**
 * Creates an async action responsible for submitting a checkout.
 */
const submitOrderCheckout = (
  checkoutId: string,
  authorizationForm?: io.flow.v0.unions.AuthorizationForm,
  total?: io.flow.v0.models.Money,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(submitOrderCheckoutRequest());
  return extra.apiInternal.v1Checkouts.putSubmissionsById({
    body: {
      authorization_form: authorizationForm,
      expected_order_summary: total != null ? {
        total: {
          amount: total.amount,
          currency: total.currency,
        },
      } : undefined,
    },
    id: checkoutId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(submitOrderCheckoutSuccess(response.result));
          break;
        case 401:
        case 404:
          dispatch(submitOrderCheckoutFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default submitOrderCheckout;
