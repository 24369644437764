import type { OrdersPutInventoryAndReservationsByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { CreateInventoryReservationError } from '../types';
import type { LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<OrdersPutInventoryAndReservationsByNumberResponse>
>;

const createInventoryReservationRequest = (): RootAction => ({
  type: actionTypes.CREATE_INVENTORY_RESERVATION_REQUEST,
});

const createInventoryReservationSuccess = (
  reservation: io.flow.v0.models.Reservation,
): RootAction => ({
  type: actionTypes.CREATE_INVENTORY_RESERVATION_SUCCESS,
  payload: reservation,
});

const createInventoryReservationFailure = (
  error: CreateInventoryReservationError,
): RootAction => ({
  type: actionTypes.CREATE_INVENTORY_RESERVATION_FAILURE,
  payload: error,
});

const createInventoryReservation = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(createInventoryReservationRequest());
  return extra.api.orders.putInventoryAndReservationsByNumber({
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(createInventoryReservationSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(createInventoryReservationFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default createInventoryReservation;
