import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = {
  taxAndDutyPrice?: io.flow.v0.models.PriceWithBase;
}

const initialState = {
  taxAndDutyPrice: undefined,
};

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PRICE_CONVERSION_SUCCESS:
      return update(previousState, {
        taxAndDutyPrice: {
          $set: action.payload.prices[0].price,
        },
      });
    case actionTypes.FETCH_PRICE_CONVERSION_FAILURE:
    case actionTypes.FETCH_PRICE_CONVERSION_REQUEST:
    default:
      return previousState;
  }
};

export default reducer;
