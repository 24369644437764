import get from 'lodash/get';

import TierEstimateType from '../constants/tier-estimate-type';

/**
 * Returns the type of label displayed for shipping tier in the specified
 * delivery option.
 * @param {DeliveryOption}
 * @returns {TierEstimateType}
 */
export default function getTierEstimateType(deliveryOption) {
  return get(deliveryOption, 'tier.display.estimate.type', TierEstimateType.Calculated);
}
