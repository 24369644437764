import { SubmissionError } from 'redux-form';
import addPromotionCodeAndUpdatePaymentMethods from './add-promotion-code-and-update-payment-methods';
import checkHttpStatus from '../../../utilities/check-http-status';

/**
 * Creates an action responsible for applying a promotion code to the order.
 *
 * If the Flow Commerce API response falls outside the 2xx status code range,
 * the promise returned by the Redux Thunk middleware will be rejected with a
 * SubmissionError containing the response result.
 *
 * @param {object} values - An object representing the submitted form data.
 * @param {string} values.orderNumber
 * @param {string} values.organizationId
 * @param {string} values.promotionCode
 * @returns {import('../../types').ThunkResult<Promise<void>>} A function that
 * returns a promise when invoked by the Redux Thunk middleware.
 */
export default function submitCoupon(values) {
  const { orderNumber, organizationId, promotionCode } = values;
  return function submitCouponSideEffects(dispatch) {
    return dispatch(addPromotionCodeAndUpdatePaymentMethods(
      organizationId,
      orderNumber,
      promotionCode,
    ))
      .then(checkHttpStatus)
      .catch((error) => {
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          default:
            throw error;
        }
      });
  };
}
