// @ts-check

import { SubmissionError } from 'redux-form';
import checkHttpStatus from '../../../utilities/check-http-status';
import checkOrderBuilderStatus from '../../../utilities/check-order-builder-status';
import updateShippingAddress from './update-shipping-address';
import saveNewAddress from './save-new-address';
import { toOrderAddress } from '../../../containers/customer-information-form/converters';
import { canSubmitVrn } from '../selectors';
import vrnErrorMessages from '../../../constants/intl-vrn-errors';

/**
 * @typedef {import('../../../store/checkout/types').ContactInfoFormValues} ContactInfoFormValues
 * @typedef {import('react-intl').IntlShape} IntlShape
 */

/**
 * @typedef {import('../../types').RootState} RootState
 */

/**
 * @typedef {import('redux-thunk').ThunkAction<R, S, E, A>} ThunkAction
 * @template R, S, E, A
 */

/**
 * Creates an action responsible for updating the order with the provided
 * contact form values.
 *
 * If the Flow Commerce API response falls outside the 2xx status code range,
 * the promise returned by the Redux Thunk middleware will be rejected with a
 * `SubmissionError` containing the response result.
 *
 * If errors are detected from the order builder response, the promise returned
 * by the Redux Thunk middleware will be rejected with a `SubmissionError`
 * containing the response result errors.
 *
 * @param {ContactInfoFormValues} values
 * @param {IntlShape} intl
 * @returns {ThunkAction<Promise<void>, RootState, void, any>}
 */
export default function submitCustomerInformation(values, intl) {
  return function submitCustomerInformationSideEffects(dispatch, getState) {
    if (!canSubmitVrn(getState())) {
      throw new SubmissionError({
        vrn: intl
          ? intl.formatMessage(vrnErrorMessages.vrnErrorMessage)
          : vrnErrorMessages.vrnErrorMessage.defaultMessage,
      });
    }

    return Promise.resolve()
    // TODO: Hoist business logic in this nested action here. Ideally, we are
    // simply passing an `order_put_form` to the action or calling the
    // `updateOrderBuilder` action creator.
      .then(() => dispatch(updateShippingAddress(values)))
      .then(checkHttpStatus)
      .then(checkOrderBuilderStatus)
      // @ts-ignore
      .then(() => {
        if (values.saveCustomerInfo && values.addressToUse === 'newAddress') {
          return dispatch(
            saveNewAddress(toOrderAddress(values), 'shipping'),
          );
        }

        return Promise.resolve();
      })
      .catch(/** @type {(error: Error | any) => void} */(error) => {
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          case 'OrderBuilderError':
            throw new SubmissionError({
              _error: error.response.result.errors[0],
            });
          default:
            if (error.mesage === 'VrnValidationError') {
              throw new SubmissionError({
                _error: 'Company name or number invalid',
              });
            }
            throw error;
        }
      });
  };
}
