import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

const updateBillingAddressRequest = (
  organizationId: string,
  orderNumber: string,
): RootAction => ({
  type: actionTypes.UPDATE_BILLING_ADDRESS_REQUEST,
  meta: {
    orderNumber,
    organizationId,
  },
});

const updateBillingAddressSuccess = (
  organizationId: string,
  orderNumber: string,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS,
  payload: orderBuilder,
  meta: {
    orderNumber,
    organizationId,
  },
});

const updateBillingAddressFailure = (
  organizationId: string,
  orderNumber: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_BILLING_ADDRESS_FAILURE,
  payload: error,
  meta: {
    orderNumber,
    organizationId,
  },
});

/**
 * An action responsible for updating the order with the provided billing address.
 */
const updateBillingAddress = (
  organizationId: string,
  orderNumber: string,
  billingAddress: io.flow.v0.models.BillingAddress,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const order = getOrderDenormalized(getState());
  const orderPutForm = orderPutFormConverter.fromOrder(order);
  dispatch(updateBillingAddressRequest(organizationId, orderNumber));
  return extra.api.orderBuilders.putByNumber({
    body: {
      ...orderPutForm,
      customer: {
        ...orderPutForm.customer,
        address: billingAddress,
      },
    },
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateBillingAddressSuccess(organizationId, orderNumber, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateBillingAddressFailure(organizationId, orderNumber, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateBillingAddress;
