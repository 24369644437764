import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import Button from '../button';

import LearnMoreModal from './learn-more-modal';

if (process.browser) {
  require('./learn-more.css'); // eslint-disable-line
}

const bem = new BemHelper('learn-more');

const LearnMoreContent: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <p className={bem.element('description')}>
        <FormattedMessage
          id="checkout_learn_more_content"
          description="A text to inform the customer of possible duties to be paid upon delivery"
          defaultMessage="Customs and duties may be charged upon delivery. <button>Learn more.</button>"
          values={{
            button: (msg: string): React.ReactElement => (
              <Button
                className={bem.element('link')}
                link
                onClick={handleLinkClick}
              >
                {msg}
              </Button>
            ),
          }}
        />
      </p>
      <LearnMoreModal
        isOpen={isOpen}
        handleRequestClose={handleLinkClick}
      />
    </>
  );
};

export default LearnMoreContent;
