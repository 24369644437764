/* eslint-disable react/jsx-props-no-spreading */

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import React from 'react';
import get from 'lodash/get';

import Message from '../message';

const DomesticShippingUnavailable = ({
  error,
  continueShoppingUrl,
  ...unhandledProps
}) => {
  const countryCode = get(error, 'destination_country.iso_3166_3');
  const countryName = get(error, 'destination_country.name');
  const href = `${continueShoppingUrl}?flow_country=${countryCode}`;

  return (
    <Message {...unhandledProps} color="negative">
      <Message.Text>
        <FormattedMessage
          id="checkout_customer_information_error_unmatched_country"
          description="An error message that is displayed when the shipping address does not match the selected country in the store."
          defaultMessage="In order to ship to this address, please {errorActionAnchor}"
          values={{
            errorActionAnchor: (
              <a href={href}>
                <FormattedMessage
                  id="checkout_customer_information_error_unmatched_country_correction_url"
                  description="Inside of an error message displayed when the shipping address does not match the selected country, this is the text inside or an anchor tag that will allow the user to set the country appropriately."
                  defaultMessage="return to shop and switch your country to {countryName}."
                  values={{ countryName }}
                />
              </a>
            ),
          }}
        />
      </Message.Text>
    </Message>
  );
};

DomesticShippingUnavailable.displayName = 'DomesticShippingUnavailable';

DomesticShippingUnavailable.propTypes = {
  continueShoppingUrl: PropTypes.string,
  error: ApiPropTypes.orderError.isRequired,
};

DomesticShippingUnavailable.defaultProps = {
  continueShoppingUrl: '/',
};

export default DomesticShippingUnavailable;
