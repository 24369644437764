import type { OrdersGetByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { FetchOrderActionParameters } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrdersGetByNumberResponse>>;

function fetchOrderRequest(
  parameters: FetchOrderActionParameters,
): RootAction {
  return {
    type: actionTypes.FETCH_ORDER_REQUEST,
    params: parameters,
  };
}

function fetchOrderSuccess(
  parameters: FetchOrderActionParameters,
  order: io.flow.v0.models.Order,
): RootAction {
  return {
    type: actionTypes.FETCH_ORDER_SUCCESS,
    params: parameters,
    payload: order,
  };
}

function fetchOrderFailure(
  parameters: FetchOrderActionParameters,
  error: LegacyError,
): RootAction {
  return {
    type: actionTypes.FETCH_ORDER_FAILURE,
    params: parameters,
    payload: error,
  };
}

export default function fetchOrder(
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> {
  return function fetchOrderEffects(dispatch, getState, extra) {
    const parameters: FetchOrderActionParameters = {
      organization: organizationId,
      orderNumber,
    };
    dispatch(fetchOrderRequest(parameters));
    return extra.api.orders.getByNumber({
      number: orderNumber,
      organization: organizationId,
      expand: ['experience'],
    })
      .then(legacyResponseConverter.withLegacyError)
      .then((response) => {
        switch (response.status) {
          case 200:
            dispatch(fetchOrderSuccess(parameters, response.result));
            break;
          case 401:
          case 404:
            dispatch(fetchOrderFailure(parameters, response.result));
            break;
          default:
            exhaustiveCheck(response);
        }
        return response;
      });
  };
}
