/* eslint-disable no-bitwise */

/**
 * Random string generator based on this article:
 * https://benjaminjurke.com/content/articles/2015/java-script-random-string-generator/
 */

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

const charsLength = chars.length;

const seed = [
  ((new Date()).getTime() * 41 ^ (Math.random() * (2 ** 32))) >>> 0,
  ((new Date()).getTime() * 41 ^ (Math.random() * (2 ** 32))) >>> 0,
  ((new Date()).getTime() * 41 ^ (Math.random() * (2 ** 32))) >>> 0,
  ((new Date()).getTime() * 41 ^ (Math.random() * (2 ** 32))) >>> 0,
];

function addRandomSeed() {
  const x = Math.floor(Math.random() * 1920);
  const y = Math.floor(Math.random() * 1080);

  seed[0] = ((seed[3] * 37)
    ^ (new Date()).getTime() * 41
    ^ Math.floor(Math.random() * (2 ** 32))
    + x
    + (y * 17)) >>> 0;

  seed[1] = ((seed[0] * 31)
    ^ (new Date()).getTime() * 43
    ^ Math.floor(Math.random() * (2 ** 32))
    + x
    + (y * 19)) >>> 0;

  seed[2] = ((seed[1] * 29)
    ^ (new Date()).getTime() * 47
    ^ Math.floor(Math.random() * (2 ** 32))
    + x
    + (y * 23)) >>> 0;

  seed[3] = ((seed[2] * 23)
    ^ (new Date()).getTime() * 51
    ^ Math.floor(Math.random() * (2 ** 32))
    + x
    + (y * 29)) >>> 0;
}

function unsignedRandom(maxval, index) {
  return Math.abs((
    Math.floor(Math.random() * (2 ** 32))
    ^ (seed[index % 4] >>> (index % 23)))
    % (maxval + 1));
}

export default function randomString(length) {
  let result = '';
  let index;

  addRandomSeed();

  for (index = length; index > 0; index -= 1) {
    result += chars[unsignedRandom(charsLength - 1, index)];
  }

  return result;
}
