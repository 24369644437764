import type { V1CheckoutsPostResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<V1CheckoutsPostResponse>>;

const createCheckoutOrderRequest = (): RootAction => ({
  type: actionTypes.CREATE_CHECKOUT_ORDER_REQUEST,
});

const createCheckoutOrderSuccess = (
  checkout: io.flow.internal.v0.models.V1Checkout,
): RootAction => ({
  type: actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS,
  payload: checkout,
});

const createCheckoutOrderFailure = (
  error: io.flow.checkout.common.v0.models.CheckoutError | LegacyError,
): RootAction => ({
  type: actionTypes.CREATE_CHECKOUT_ORDER_FAILURE,
  payload: error,
});

const createCheckoutOrder = (
  checkoutForm: io.flow.internal.v0.unions.CheckoutForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(createCheckoutOrderRequest());
  return extra.apiInternal.v1Checkouts.post({
    body: checkoutForm,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      if (response.ok) dispatch(createCheckoutOrderSuccess(response.result));
      else dispatch(createCheckoutOrderFailure(response.result));
      return response;
    });
};

export default createCheckoutOrder;
