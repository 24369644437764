type AuthorizationFormDiscriminator = io.flow.v0.unions.AuthorizationForm['discriminator'];

interface AuthorizationFormDiscriminatorEnum {
  ACH: 'ach_authorization_form';
  CARD_PAYMENT_SOURCE: 'card_payment_source_authorization_form';
  COPY: 'authorization_copy_form';
  DIRECT: 'direct_authorization_form';
  INLINE: 'inline_authorization_form';
  MERCHANT_OF_RECORD: 'merchant_of_record_authorization_form';
  PAYPAL: 'paypal_authorization_form';
  REDIRECT: 'redirect_authorization_form';
}

const AuthorizationFormDiscriminator: AuthorizationFormDiscriminatorEnum = {
  ACH: 'ach_authorization_form',
  CARD_PAYMENT_SOURCE: 'card_payment_source_authorization_form',
  COPY: 'authorization_copy_form',
  DIRECT: 'direct_authorization_form',
  INLINE: 'inline_authorization_form',
  MERCHANT_OF_RECORD: 'merchant_of_record_authorization_form',
  PAYPAL: 'paypal_authorization_form',
  REDIRECT: 'redirect_authorization_form',
};

export default AuthorizationFormDiscriminator;
