export default function getBrowserInfo(): io.flow.v0.models.BrowserInfo {
  return {
    language: window.navigator.language,
    java_enabled: window.navigator.javaEnabled(),
    color_depth: window.screen.colorDepth,
    screen_height: window.outerHeight,
    screen_width: window.outerWidth,
    time_zone_offset: new Date().getTimezoneOffset(),
    origin: window.location.origin,
  };
}
