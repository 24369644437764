import type { LoyaltyProgramsPostResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { AddLoyaltyRewardsError } from '../types';
import type { LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<LoyaltyProgramsPostResponse>>;

const addLoyaltyRewardsRequest = (): RootAction => ({
  type: actionTypes.ADD_LOYALTY_REWARDS_REQUEST,
});

const addLoyaltyRewardsSuccess = (
  loyaltyProgram: io.flow.internal.v0.models.LoyaltyProgram,
): RootAction => ({
  type: actionTypes.ADD_LOYALTY_REWARDS_SUCCESS,
  payload: loyaltyProgram,
});

const addLoyaltyRewardsFailure = (
  error: AddLoyaltyRewardsError,
): RootAction => ({
  type: actionTypes.ADD_LOYALTY_REWARDS_FAILURE,
  payload: error,
});

const addLoyaltyRewards = (
  organizationId: string,
  orderNumber: string,
  loyaltyForm: io.flow.common.v0.models.InputForm,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(addLoyaltyRewardsRequest());
  return extra.apiInternal.loyaltyPrograms.post({
    body: loyaltyForm,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(addLoyaltyRewardsSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(addLoyaltyRewardsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default addLoyaltyRewards;
