import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

import Button from '../button';

if (process.browser) {
  require('./empty-cart.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('empty-cart');

const EmptyCart = ({
  continueShoppingUrl,
}) => (
  <div className={bem.block()}>
    <p className={bem.element('message')}>
      <FormattedMessage
        id="checkout_cart_empty"
        description="Text describing the current state of the cart is empty"
        defaultMessage="Your cart is empty"
      />
    </p>
    <Button
      className={bem.element('continue-shopping')}
      color="positive"
      href={continueShoppingUrl}
      outline
      text="Continue Shopping"
    />
  </div>
);

EmptyCart.displayName = 'EmptyCart';

EmptyCart.propTypes = {
  continueShoppingUrl: PropTypes.string,
};

EmptyCart.defaultProps = {
  continueShoppingUrl: '/',
};

export default EmptyCart;
