import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

const setOrderAttributesRequest = (
  organizationId: string,
  attributes: Record<string, string>,
): RootAction => ({
  type: actionTypes.SET_ORDER_ATTRIBUTES_REQUEST,
  params: {
    attributes,
    organization: organizationId,
  },
});

const setOrderAttributesSuccess = (
  organizationId: string,
  attributes: Record<string, string>,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.SET_ORDER_ATTRIBUTES_SUCCESS,
  payload: orderBuilder,
  params: {
    attributes,
    organization: organizationId,
  },
});

const setOrderAttributesFailure = (
  organizationId: string,
  attributes: Record<string, string>,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.SET_ORDER_ATTRIBUTES_FAILURE,
  payload: error,
  params: {
    attributes,
    organization: organizationId,
  },
});

const setOrderAttributes = (
  organizationId: string,
  attributes: Record<string, string>,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const order = getOrderDenormalized(getState());
  const orderNumber = order.number;
  const orderPutForm = orderPutFormConverter.fromOrder(order);
  dispatch(setOrderAttributesRequest(organizationId, attributes));
  return extra.api.orderBuilders.putByNumber({
    body: {
      ...orderPutForm,
      attributes: {
        ...orderPutForm.attributes,
        ...attributes,
      },
    },
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(setOrderAttributesSuccess(organizationId, attributes, response.result));
          break;
        case 401:
        case 404:
          dispatch(setOrderAttributesFailure(organizationId, attributes, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default setOrderAttributes;
