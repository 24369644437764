import keyMirror from '../../utilities/keymirror';

const actionTypes = keyMirror({
  ADD_GENERIC_PROMOTION_CODE_FAILURE: null,
  ADD_GENERIC_PROMOTION_CODE_REQUEST: null,
  ADD_GENERIC_PROMOTION_CODE_SUCCESS: null,
  ADD_DEVICE_DETAILS: null,
  ADD_GIFT_CARD_FAILURE: null,
  ADD_GIFT_CARD_REQUEST: null,
  ADD_GIFT_CARD_SUCCESS: null,
  ADD_GIFT_CARD_V2_FAILURE: null,
  ADD_GIFT_CARD_V2_REQUEST: null,
  ADD_GIFT_CARD_V2_SUCCESS: null,
  ADD_LOYALTY_REWARDS_FAILURE: null,
  ADD_LOYALTY_REWARDS_REQUEST: null,
  ADD_LOYALTY_REWARDS_SUCCESS: null,
  ADD_PROMOTION_CODE_FAILURE: null,
  ADD_PROMOTION_CODE_REQUEST: null,
  ADD_PROMOTION_CODE_SUCCESS: null,
  ADD_REWARDS_FAILURE: null,
  ADD_REWARDS_REUQEST: null,
  ADD_REWARDS_SUCCESS: null,
  APPLE_PAY_AVAILABILITY: null,
  AUTHORIZE_GIFT_CARDS_FAILURE: null,
  AUTHORIZE_GIFT_CARDS_REQUEST: null,
  AUTHORIZE_GIFT_CARDS_SUCCESS: null,
  CANCELLED_CHECK_VRN_REQUEST: null,
  CHANGE_CARD: null,
  CHANGE_LOCALE: null,
  CHECK_FEATURE_VALUES_FAILURE: null,
  CHECK_FEATURE_VALUES_REQUEST: null,
  CHECK_FEATURE_VALUES_SUCCESS: null,
  CHECK_SHOPIFY_INVENTORY_FAILURE: null,
  CHECK_SHOPIFY_INVENTORY_REQUEST: null,
  CHECK_SHOPIFY_INVENTORY_SUCCESS: null,
  CHECK_VRN_NUMBER_FAILURE: null,
  CHECK_VRN_NUMBER_REQUEST: null,
  CHECK_VRN_NUMBER_SUCCESS: null,
  CLEAR_REVIEW_STATE: null,
  CLEAR_SHOPIFY_CART_FAILURE: null,
  CLEAR_SHOPIFY_CART_REQUEST: null,
  CLEAR_SHOPIFY_CART_SUCCESS: null,
  CLOSE_ADYEN_3DS_DIALOG: null,
  CLOSE_EDIT_CUSTOMER_ADDRESS_BOOK_CONTACT_DIALOG: null,
  CLOSE_INLINE_AUTHORIZATION_DIALOG: null,
  CLOSE_KLARNA_DIALOG: null,
  CLOSE_RESTRICTED_ITEMS_MODAL: null,
  CREATE_AUTHORIZATION_FAILURE: null,
  CREATE_AUTHORIZATION_REQUEST: null,
  CREATE_AUTHORIZATION_SUCCESS: null,
  CREATE_CARD_PAYMENT_SOURCE_FAILURE: null,
  CREATE_CARD_PAYMENT_SOURCE_REQUEST: null,
  CREATE_CARD_PAYMENT_SOURCE_SUCCESS: null,
  CREATE_CHECKOUT_ORDER_FAILURE: null,
  CREATE_CHECKOUT_ORDER_REQUEST: null,
  CREATE_CHECKOUT_ORDER_SUCCESS: null,
  CREATE_INVENTORY_RESERVATION_FAILURE: null,
  CREATE_INVENTORY_RESERVATION_REQUEST: null,
  CREATE_INVENTORY_RESERVATION_SUCCESS: null,
  CREATE_ORDER_BUILDER_FAILURE: null,
  CREATE_ORDER_BUILDER_REQUEST: null,
  CREATE_ORDER_BUILDER_SUCCESS: null,
  CREATE_SHOPIFY_ORDER_FAILURE: null,
  CREATE_SHOPIFY_ORDER_REQUEST: null,
  CREATE_SHOPIFY_ORDER_SUCCESS: null,
  DELETE_CARD_PAYMENT_SOURCE_FAILURE: null,
  DELETE_CARD_PAYMENT_SOURCE_REQUEST: null,
  DELETE_CARD_PAYMENT_SOURCE_SUCCESS: null,
  DELETE_ORDER_FAILURE: null,
  DELETE_ORDER_REQUEST: null,
  DELETE_ORDER_SUCCESS: null,
  DISABLE_DELIVERED_DUTY_DIALOG: null,
  EDIT_ADDRESS_BOOK_CONTACT: null,
  ENABLE_DELIVERED_DUTY_DIALOG: null,
  FETCH_ADDRESS_CONFIGURATION_FAILURE: null,
  FETCH_ADDRESS_CONFIGURATION_REQUEST: null,
  FETCH_ADDRESS_CONFIGURATION_SUCCESS: null,
  FETCH_ADMINISTRATIVE_DIVISIONS_FAILURE: null,
  FETCH_ADMINISTRATIVE_DIVISIONS_REQUEST: null,
  FETCH_ADMINISTRATIVE_DIVISIONS_SUCCESS: null,
  FETCH_AUTHORIZATION_FAILURE: null,
  FETCH_AUTHORIZATION_REQUEST: null,
  FETCH_AUTHORIZATION_SUCCESS: null,
  FETCH_CART_FAILURE: null,
  FETCH_CART_REQUEST: null,
  FETCH_CART_SUCCESS: null,
  FETCH_CHECKOUT_BUNDLE_FAILURE: null,
  FETCH_CHECKOUT_BUNDLE_REQUEST: null,
  FETCH_CHECKOUT_BUNDLE_SUCCESS: null,
  FETCH_CHECKOUT_FAILURE: null,
  FETCH_CHECKOUT_REQUEST: null,
  FETCH_CHECKOUT_SUCCESS: null,
  FETCH_CONTENT_FAILURE: null,
  FETCH_CONTENT_REQUEST: null,
  FETCH_CONTENT_SUCCESS: null,
  FETCH_COUNTRIES_FAILURE: null,
  FETCH_COUNTRIES_REQUEST: null,
  FETCH_COUNTRIES_SUCCESS: null,
  FETCH_GIFT_CARDS_FAILURE: null,
  FETCH_GIFT_CARDS_REQUEST: null,
  FETCH_GIFT_CARDS_SUCCESS: null,
  FETCH_ITEM_CONTENT_FAILURE: null,
  FETCH_ITEM_CONTENT_REQUEST: null,
  FETCH_ITEM_CONTENT_SUCCESS: null,
  FETCH_LOCALE_DATA_FAILURE: null,
  FETCH_LOCALE_DATA_REQUEST: null,
  FETCH_LOCALE_DATA_SUCCESS: null,
  FETCH_LOCALE_MESSAGES_FAILURE: null,
  FETCH_LOCALE_MESSAGES_REQUEST: null,
  FETCH_LOCALE_MESSAGES_SUCCESS: null,
  FETCH_LOYALTY_PROGRAMS_FAILURE: null,
  FETCH_LOYALTY_PROGRAMS_REQUEST: null,
  FETCH_LOYALTY_PROGRAMS_SUCCESS: null,
  FETCH_OPTIN_PROMPTS_FAILURE: null,
  FETCH_OPTIN_PROMPTS_REQUEST: null,
  FETCH_OPTIN_PROMPTS_SUCCESS: null,
  FETCH_ORDER_FAILURE: null,
  FETCH_ORDER_REQUEST: null,
  FETCH_ORDER_SUCCESS: null,
  FETCH_ORGANIZATION_CONFIG_FAILURE: null,
  FETCH_ORGANIZATION_CONFIG_REQUEST: null,
  FETCH_ORGANIZATION_CONFIG_SUCCESS: null,
  FETCH_ORGANIZATION_COUNTRIES_FAILURE: null,
  FETCH_ORGANIZATION_COUNTRIES_REQUEST: null,
  FETCH_ORGANIZATION_COUNTRIES_SUCCESS: null,
  FETCH_PAYMENT_METHOD_PARAMS_FAILURE: null,
  FETCH_PAYMENT_METHOD_PARAMS_REQUEST: null,
  FETCH_PAYMENT_METHOD_PARAMS_SUCCESS: null,
  FETCH_PAYMENT_METHOD_RULES_FAILURE: null,
  FETCH_PAYMENT_METHOD_RULES_REQUEST: null,
  FETCH_PAYMENT_METHOD_RULES_SUCCESS: null,
  FETCH_PAYMENT_METHOD_TYPES_FAILURE: null,
  FETCH_PAYMENT_METHOD_TYPES_REQUEST: null,
  FETCH_PAYMENT_METHOD_TYPES_SUCCESS: null,
  FETCH_PRICE_CONVERSION_FAILURE: null,
  FETCH_PRICE_CONVERSION_REQUEST: null,
  FETCH_PRICE_CONVERSION_SUCCESS: null,
  FETCH_PUBLIC_KEY_FAILURE: null,
  FETCH_PUBLIC_KEY_REQUEST: null,
  FETCH_PUBLIC_KEY_SUCCESS: null,
  FINALIZE_ORDER_FAILURE: null,
  FINALIZE_ORDER_REQUEST: null,
  FINALIZE_ORDER_SUCCESS: null,
  FINISHED_KLARNA_RENDER: null,
  HIDE_DELIVERED_DUTY_DIALOG: null,
  HIDE_DOMESTIC_SHIPPING_UNAVAILABLE_DIALOG: null,
  HIDE_KLARNA_DIALOG: null,
  HIDE_LOADING_INDICATOR: null,
  HIDE_ORDER_SUMMARY: null,
  OPEN_ADYEN_3DS_DIALOG: null,
  OPEN_INLINE_AUTHORIZATION_DIALOG: null,
  OPEN_KLARNA_DIALOG: null,
  OPEN_RESTRICTED_ITEMS_MODAL: null,
  PATCH_ORDER_FAILURE: null,
  PATCH_ORDER_REQUEST: null,
  PATCH_ORDER_SUCCESS: null,
  PRELOADED_CHECKOUT_CONSUMED: null,
  PROCEED_TO_REVIEW: null,
  PROCESS_ONLINE_AUTHORIZATION_FAILURE: null,
  PROCESS_ONLINE_AUTHORIZATION_SUCCESS: null,
  REDIRECT: null,
  REMOVE_ADDRESS_BOOK_CONTACT_FAILURE: null,
  REMOVE_ADDRESS_BOOK_CONTACT_REQUEST: null,
  REMOVE_ADDRESS_BOOK_CONTACT_SUCCESS: null,
  REMOVE_CART_ITEM_FAILURE: null,
  REMOVE_CART_ITEM_REQUEST: null,
  REMOVE_CART_ITEM_SUCCESS: null,
  REMOVE_GENERIC_PROMOTION_CODE_FAILURE: null,
  REMOVE_GENERIC_PROMOTION_CODE_REQUEST: null,
  REMOVE_GENERIC_PROMOTION_CODE_SUCCESS: null,
  REMOVE_GIFT_CARD_FAILURE: null,
  REMOVE_GIFT_CARD_REQUEST: null,
  REMOVE_GIFT_CARD_SUCCESS: null,
  REMOVE_LOYALTY_REWARD_FAILURE: null,
  REMOVE_LOYALTY_REWARD_REQUEST: null,
  REMOVE_LOYALTY_REWARD_SUCCESS: null,
  REMOVE_PROMOTION_CODE_FAILURE: null,
  REMOVE_PROMOTION_CODE_REQUEST: null,
  REMOVE_PROMOTION_CODE_SUCCESS: null,
  REMOVE_SHOPIFY_PROMO_CODE_FAILURE: null,
  REMOVE_SHOPIFY_PROMO_CODE_REQUEST: null,
  REMOVE_SHOPIFY_PROMO_CODE_SUCCESS: null,
  SAVE_CUSTOMER_ADDRESS_FAILURE: null,
  SAVE_CUSTOMER_ADDRESS_REQUEST: null,
  SAVE_CUSTOMER_ADDRESS_SUCCESS: null,
  SET_ADDRESS_BOOK: null,
  SET_ADDRESS_CONFIGURATION: null,
  SET_AUTHORIZATION_PAYLOAD_FAILURE: null,
  SET_AUTHORIZATION_PAYLOAD_REQUEST: null,
  SET_AUTHORIZATION_PAYLOAD_SUCCESS: null,
  SET_CHECKOUT_PRELOADED: null,
  SET_FEATURE_VALUE: null,
  SET_GIFT_CARD_CONTENT_STATE: null,
  SET_GIFT_MESSAGING_CONTENT_STATE: null,
  SET_ORDER_ATTRIBUTES_FAILURE: null,
  SET_ORDER_ATTRIBUTES_REQUEST: null,
  SET_ORDER_ATTRIBUTES_SUCCESS: null,
  SET_REVIEW_ERROR: null,
  SET_VISITED: null,
  SHOW_DELIVERED_DUTY_DIALOG: null,
  SHOW_DOMESTIC_SHIPPING_UNAVAILABLE_DIALOG: null,
  SHOW_LOADING_INDICATOR: null,
  SHOW_ORDER_SUMMARY: null,
  START_KLARNA_RENDER: null,
  SUBMIT_AUTHORIZATION_FAILURE: null,
  SUBMIT_AUTHORIZATION_REQUEST: null,
  SUBMIT_AUTHORIZATION_SUCCESS: null,
  SUBMIT_ORDER_BUNDLE_FAILURE: null,
  SUBMIT_ORDER_BUNDLE_REQUEST: null,
  SUBMIT_ORDER_BUNDLE_SUCCESS: null,
  SUBMIT_ORDER_CHECKOUT_FAILURE: null,
  SUBMIT_ORDER_CHECKOUT_REQUEST: null,
  SUBMIT_ORDER_CHECKOUT_SUCCESS: null,
  SUBMIT_ORDER_FAILURE: null,
  SUBMIT_ORDER_REQUEST: null,
  SUBMIT_ORDER_SUCCESS: null,
  TRIGGER_INITIALIZED_EVENT: null,
  TRIGGER_PAYMENT_SUBMITTED_EVENT: null,
  TRIGGER_TRANSACTION_EVENT: null,
  UPDATE_ADYEN_PAYMENT_DETAILS_FAILURE: null,
  UPDATE_ADYEN_PAYMENT_DETAILS_REQUEST: null,
  UPDATE_ADYEN_PAYMENT_DETAILS_SUCCESS: null,
  UPDATE_BILLING_ADDRESS_FAILURE: null,
  UPDATE_BILLING_ADDRESS_REQUEST: null,
  UPDATE_BILLING_ADDRESS_SUCCESS: null,
  UPDATE_CARD_PAYMENT_SOURCE_FAILURE: null,
  UPDATE_CARD_PAYMENT_SOURCE_REQUEST: null,
  UPDATE_CARD_PAYMENT_SOURCE_SUCCESS: null,
  UPDATE_CART_ITEM_QUANTITY_FAILURE: null,
  UPDATE_CART_ITEM_QUANTITY_REQUEST: null,
  UPDATE_CART_ITEM_QUANTITY_SUCCESS: null,
  UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_FAILURE: null,
  UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_REQUEST: null,
  UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_SUCCESS: null,
  UPDATE_DELIVERED_DUTY_FAILURE: null,
  UPDATE_DELIVERED_DUTY_REQUEST: null,
  UPDATE_DELIVERED_DUTY_SUCCESS: null,
  UPDATE_GOOGLE_PAY_INFO: null,
  UPDATE_INVOICE_ADDRESS_FAILURE: null,
  UPDATE_INVOICE_ADDRESS_ORDER_FAILURE: null,
  UPDATE_INVOICE_ADDRESS_ORDER_REQUEST: null,
  UPDATE_INVOICE_ADDRESS_ORDER_SUCCESS: null,
  UPDATE_INVOICE_ADDRESS_REQUEST: null,
  UPDATE_INVOICE_ADDRESS_SUCCESS: null,
  UPDATE_ORDER_AUTHORIZATION_KEYS_FAILURE: null,
  UPDATE_ORDER_AUTHORIZATION_KEYS_REQUEST: null,
  UPDATE_ORDER_AUTHORIZATION_KEYS_SUCCESS: null,
  UPDATE_ORDER_BUILDER_FAILURE: null,
  UPDATE_ORDER_BUILDER_REQUEST: null,
  UPDATE_ORDER_BUILDER_SUCCESS: null,
  UPDATE_ORDER: null,
  UPDATE_PAYPAL_INFO: null,
  UPDATE_FRAUD_REFERENCES: null,
  UPDATE_RETURN_URL_ATTRIBUTE_FAILURE: null,
  UPDATE_RETURN_URL_ATTRIBUTE_REQUEST: null,
  UPDATE_RETURN_URL_ATTRIBUTE_SUCCESS: null,
  UPDATE_SHIPPING_ADDRESS_FAILURE: null,
  UPDATE_SHIPPING_ADDRESS_REQUEST: null,
  UPDATE_SHIPPING_ADDRESS_SUCCESS: null,
  UPDATE_SHIPPING_METHOD_FAILURE: null,
  UPDATE_SHIPPING_METHOD_REQUEST: null,
  UPDATE_SHIPPING_METHOD_SUCCESS: null,
  UPDATE_VRN_VISIBILITY: null,
});

export default actionTypes;
