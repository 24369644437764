import defaultTo from 'lodash/defaultTo';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.shopify.v0.models.ShopifyCart | null;

const initialState: State = null;

const shopifyCartReducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return update(previousState, {
        $set: defaultTo(action.payload.cart, previousState),
      });
    case actionTypes.FETCH_CART_SUCCESS:
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS:
    case actionTypes.REMOVE_CART_ITEM_SUCCESS:
      return update(previousState, {
        $set: action.payload,
      });
    default:
      return previousState;
  }
};

export default shopifyCartReducer;
