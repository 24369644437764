/**
 * Hack to decode HTML entities from strings.
 *
 * CAUTION: There are differences in cross-browser results when using the DOM
 * to do this. The community seems to lean on the `he` library to receive
 * consistent results, but that library is bloated for our current use case.
 *
 * @see https://www.npmjs.com/package/he
 * @see https://bundlephobia.com/result?p=he@1.2.0
 */
export default function decodeHtml(html: string): string {
  const element = document.createElement('textarea');
  element.innerHTML = html;
  return element.value;
}
