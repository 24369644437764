export const ADD_SESSION_TO_AUTHORIZATION_RETURN_URL = 'global_checkout_add_session_to_authorization_return_url';
export const ADDRESS_CONFIGURATION_FORMATS_BY_COUNTRY = 'global_address_configuration_formats_by_country';
export const ADDRESS_POSTAL_AUTOCOMPLETE = 'global_checkout_address_postal_autocomplete';
export const ALLOW_INVOICE = 'global_checkout_allow_invoice';
export const ALLOW_VRN = 'global_allow_vrn';
export const CATCHPOINT_RUM_TESTING = 'global_checkout_catchpoint_rum';
export const CHECKOUT_RESOURCE = 'global_checkout_resource';
export const COLLAPSED_ORDER_SUMMARY = 'global_checkout_mobile_pricing_item_summary';
export const COLLECT_CUSTOMER_ADDRESS = 'global_checkout_collect_customer_address';
export const CONFIRMATION_REDIRECT_LOG = 'global_checkout_confirmation_redirect_log';
export const COUNTDOWN_TIMER = 'checkout_countdown_timer';
export const CUSTOMER_ADDRESS_BOOK = 'global_checkout_customer_address_book';
export const CUSTOMER_INFO_PROMO_CODE = 'global_checkout_mobile_promo_cust_info';
export const DATADOG_RUM = 'global_checkout_datadog_rum';
export const DISABLE_SHOPIFY_LIQUID = 'global_checkout_disable_shopify_liquid';
export const DISCOUNT_CODE = 'global_checkout_discount_codes';
export const DONT_SUBMIT_AUTH_AMOUNT = 'global_checkout_dont_submit_auth_amount';
export const DUTIES_MESSAGING = 'global_checkout_duties_messaging';
export const FINALIZE = 'global_checkout_finalize';
export const FORCE_ENABLE_SHOPIFY_DISCOUNTS = 'global_checkout_force_enable_shopify_discounts';
export const FORCE_ORDER_NUMBER_FROM_PLATFORM = 'global_checkout_force_order_number_from_platform';
export const FULLSTORY = 'global_checkout_fullstory';
export const GIFT_CARDS = 'global_checkout_gift_cards';
export const GIFT_MESSAGING = 'global_checkout_gift_messaging';
export const KLARNA_REDIRECT = 'global_payment_klarna_checkout_redirect_enabled_development';
export const LEARN_MORE_CONTENT = 'global_checkout_learn_more_content';
export const MOBILE_UX_SPACING = 'global_checkout_mobile_ux_spacing';
export const OPTIMIZE_MOBILE_CTAS = 'global_checkout_mobile_optimize_ctas';
export const PAYMENT_INFO_SUMMARY = 'global_checkout_payment_info_summary';
export const PAYMENT_METHOD_POSITIONING = 'global_checkout_payment_method_positioning';
export const PAYMENT_PROMO_CODE = 'global_checkout_mobile_promo_payment';
export const PAYPAL_EXPRESS = 'global_checkout_paypal_express';
export const PENDING_PAGE_3DS = 'global_checkout_3ds_pending_page';
export const REMOVE_TAX_DUTY = 'global_checkout_remove_tax_duty';
export const RESTRICTED_ITEMS_MODAL = 'global_checkout_restricted_items_modal';
export const RETURNING_CUSTOMERS = 'global_checkout_returning_customers';
export const REVIEW_STEP_PAGE = 'global_checkout_review_page';
export const SERVER_SIDE_INVENTORY = 'global_checkout_server_side_inventory';
export const SHOPIFY_CHECKOUT_CODE_NEW_DELETE = 'global_shopify_checkout_code_new_delete';
export const SHOPIFY_JS_CART_V2 = 'global_shopify_js_cart_v2';
export const SHOW_GIFT_CARD = 'global_checkout_show_gift_card';
export const SHOW_LOYALTY = 'global_checkout_show_loyalty';
export const UPDATED_ADDRESS_LINE_2_TEXT = 'global_checkout_updated_address_line_2';
export const WRAP_ITEM_NAME = 'global_checkout_wrap_item_name';
export const DUTY_TAX_SINGLE_LINE = 'global_checkout_duty_tax_single_line';
