import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function showDomesticShippingUnavailableDialog(
  orderError: io.flow.v0.models.OrderError,
): RootAction {
  return {
    type: actionTypes.SHOW_DOMESTIC_SHIPPING_UNAVAILABLE_DIALOG,
    payload: orderError,
  };
}
