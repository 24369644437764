import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';

import LoyaltyForm from '../../containers/loyalty-form';
import GiftCardForm from '../../containers/gift-card-form';

const Rewards = ({
  isGiftCardEnabled,
  isLoyaltyEnabled,
  loyalty,
  giftCardProgram,
}) => (
  <div>
    {isLoyaltyEnabled && !isEmpty(loyalty) && (
      <LoyaltyForm loyaltyProgram={loyalty} />
    )}
    {isGiftCardEnabled && !isEmpty(giftCardProgram) && (
      <GiftCardForm giftCardProgram={giftCardProgram} />
    )}
  </div>
);

Rewards.displayName = 'Rewards';

Rewards.propTypes = {
  isLoyaltyEnabled: PropTypes.bool.isRequired,
  loyalty: ApiInternalPropTypes.loyaltyProgram.isRequired,
  isGiftCardEnabled: PropTypes.bool.isRequired,
  giftCardProgram: ApiInternalPropTypes.giftCardProgram.isRequired,
};

export default Rewards;
