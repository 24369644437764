import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';

import {
  getIsCollectCustomerAddressEnabled,
  getOrderDestination,
  getGiftCards,
  isGiftCardEnabled,
  allowInvoiceAddress,
  getInvoiceAddress as getStateInvoiceAddress,
  getCustomerAddressBookContacts,
  getCustomerPaymentSources,
} from '../selectors';
import {
  getBillingAddress,
  getInvoiceAddress,
} from '../../../containers/payment-form/utilities';
import checkHttpStatus from '../../../utilities/check-http-status';
import checkOrderBuilderStatus from '../../../utilities/check-order-builder-status';
import setOrderAttributes from './set-order-attributes';
import syncOptInOrderAttributes from './sync-opt-in-order-attributes';
import updateBillingAddress from './update-billing-address';
import completeGiftCardAuthorization from './complete-gift-card-authorization';
import checkShopifyInventory from './check-shopify-inventory';
import updateInvoiceAddressByOrderPut from './update-invoice-address-by-order-put';
import createInventoryReservation from './create-inventory-reservation';

import patchOrder from './patch-order';
import { trackHeapEvent } from '../../../utilities/heap';

export default function finalizeOrderV1(values) {
  return function finalizeOrderV1Effects(dispatch, getState) {
    const {
      billingAddress,
      invoiceAddress,
      orderNumber,
      optinPrompts,
      organizationId,
      paymentMethodId,
      giftMessage,
    } = values;
    const state = getState();
    const destination = getOrderDestination(state);
    const isCollectCustomerAddressEnabled = getIsCollectCustomerAddressEnabled(state);
    const isGiftCardsEnabled = isGiftCardEnabled(state);
    const allowInvoice = allowInvoiceAddress(state);
    const giftCards = getGiftCards(state);
    const paymentSources = getCustomerPaymentSources(state);
    const attributes = optinPrompts;

    if (giftMessage && giftMessage.length > 0) {
      attributes.gift_message = giftMessage;
    }

    return Promise.resolve()
      .then(() => dispatch(setOrderAttributes(organizationId, attributes)))
      .then(checkHttpStatus)
      .then(() => dispatch(patchOrder(organizationId, orderNumber)))
      .then(checkHttpStatus)
      // Capture billing address on order when feature to always capture
      // billing address in checkout is enabled.
      .then(() => {
        if (!isCollectCustomerAddressEnabled) {
          return Promise.resolve();
        }

        const selectedPaymentSource = find(paymentSources, { id: paymentMethodId });

        let selectedBillingAddress;

        // If any, use billing address in selected payment source.
        if (selectedPaymentSource != null) {
          selectedBillingAddress = selectedPaymentSource.summary.card.address;
        } else if (billingAddress != null) {
          selectedBillingAddress = getBillingAddress(
            billingAddress,
            destination,
            getCustomerAddressBookContacts(state),
          );
        }

        if (selectedBillingAddress == null) {
          return Promise.resolve();
        }

        return dispatch(updateBillingAddress(
          organizationId,
          orderNumber,
          selectedBillingAddress,
        )).then(checkHttpStatus).then(checkOrderBuilderStatus);
      })
      .then(() => {
        const stateInvoiceAddress = getStateInvoiceAddress(state);

        // if the state invoice address is undefined then we know that its not a 'business'
        if (!isUndefined(stateInvoiceAddress) && allowInvoice) {
          return dispatch(updateInvoiceAddressByOrderPut(
            organizationId,
            orderNumber,
            getInvoiceAddress(invoiceAddress, destination, getCustomerAddressBookContacts(state)),
          )).then(checkHttpStatus).then(checkOrderBuilderStatus);
        }

        return Promise.resolve();
      })
      // DEPRECATED: We have evolved how we capture marketing opt-in or opt-in
      // prompts from users. Using `setOrderAttributes` with optin prompts passed
      // in is the recommended approach. `syncOptInOrderAttributes` is the legacy
      // action backed back the `checkout_content` model from content service.
      // Using it should be avoided.
      .then(() => dispatch(syncOptInOrderAttributes(values)))
      .then((response) => {
        if (response) {
          checkHttpStatus(response);
        }
      })
      .then(() => {
        if (isGiftCardsEnabled && giftCards && giftCards.length > 0) {
          return dispatch(completeGiftCardAuthorization(organizationId, orderNumber))
            .then(checkHttpStatus);
        }
        return Promise.resolve();
      })
      .then(() => dispatch(checkShopifyInventory(organizationId, orderNumber)))
      .then((response) => {
        if (response) {
          checkHttpStatus(response);
        }
      })
      .then(() => dispatch(createInventoryReservation(organizationId, orderNumber)))
      .then(checkHttpStatus)
      .then((response) => {
        trackHeapEvent('order_finalize_succeeded', {
          payment_method_id: paymentMethodId,
          version: 1,
        });
        return response;
      })
      .catch((error) => {
        trackHeapEvent('order_finalize_failed', {
          payment_method_id: paymentMethodId,
          version: 1,
        });
        throw error;
      });
  };
}
