// @ts-check

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { addressConfigurationToConstraints, messages } from '../../utilities/address-utilities';
import { getContactById } from '../../utilities/address-book-utilities';
import { isValidContact } from '../../utilities/address-book-contact-utilities';
import validate from '../../utilities/validate';

/**
 * A synchronous validation function that takes the customer information form
 * values and a message formatter. It validates the fields on the customer
 * information section. If validation passes, it returns `{}`. If validation
 * fails, it returns the validation errors in the form `{ field1: <String>, field2: <String> }`.
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {function} formatMessage
 * @returns {Record<string, string>} A collection of error messages
 */
export function validateCustomerInfo(values, formatMessage) {
  const result = validate(values, {
    email: {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingEmail),
      },
    },
  }, {
    format: 'zipped',
    fullMessages: false,
  });

  return result != null ? result : {};
}

/**
 * A synchronous function that takes form values and performs validation.
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 * @param {import('react-intl').FormatMessage} formatMessage
 * @param {string[] | undefined} registeredFields
 * @returns {Record<string, string>} A collection of error messages
 */
function validateAddressFormValues(values, addressConfiguration, formatMessage, registeredFields) {
  const constraints = addressConfigurationToConstraints(
    addressConfiguration,
    formatMessage,
    registeredFields,
  );

  const result = validate(values, {
    ...constraints,
    // At the time of writing, the address configuration model does not
    // enforce validation the following fields. For backward compatibility,
    // we want to continue enforcing the same validation.
    organizationName: {
      length: {
        maximum: 100,
        message: formatMessage(messages.tooLong, { length: 100 }),
      },
    },
    country: {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCountry),
      },
    },
  }, {
    format: 'zipped',
    fullMessages: false,
  });

  return result != null ? result : {};
}

/**
 * A synchronous validation function that takes the customer information form
 * values and a message formatter. It validates the fields on the shipping
 * address section. If validation passes, it returns `{}`. If validation fails,
 * it returns the validation errors in the form `{ field1: <String>, field2: <String> }`.
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 * @param {import('react-intl').FormatMessage} formatMessage
 * @param {io.flow.v0.models.CustomerAddressBook} [addressBook]
 * @param {string[]} [registeredFields]
 * @returns {Record<string, string>} A collection of error messages
 */
export function validateShippingAddress(
  values,
  addressConfiguration,
  formatMessage,
  addressBook,
  registeredFields,
) {
  const { addressToUse } = values;

  if (isNil(addressToUse) || isEmpty(addressToUse)) {
    return {
      addressToUse: formatMessage(messages.required),
    };
  }

  if (addressToUse === 'newAddress') {
    return validateAddressFormValues(values, addressConfiguration, formatMessage, registeredFields);
  }

  if (isNil(addressBook)) {
    return {
      addressToUse: formatMessage(messages.invalid),
    };
  }

  const contact = getContactById(addressBook, addressToUse);

  if (isNil(contact)) {
    return {
      addressToUse: formatMessage(messages.invalid),
    };
  }

  if (!isValidContact(contact, addressConfiguration, formatMessage, registeredFields)) {
    return {
      addressToUse: formatMessage(messages.invalidCustomerAddressBook),
    };
  }

  return {};
}

/**
 * A synchronous validation function that takes the customer information form
 * values and a message formatter. Returns whether the customer information
 * section is valid.
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {function} formatMessage
 * @returns {boolean}
 */
export function isValidCustomerInfo(values, formatMessage) {
  const errors = validateCustomerInfo(values, formatMessage);
  return Object.keys(errors).length === 0;
}

/**
 * A synchronous validation function that takes the customer information form
 * values and a message formatter. Returns whether the shipping address section
 * is valid.
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 * @param {import('react-intl').FormatMessage} formatMessage
 * @returns {boolean}
 */
export function isValidShippingAddress(values, addressConfiguration, formatMessage) {
  const errors = validateShippingAddress(values, addressConfiguration, formatMessage);
  return Object.keys(errors).length === 0;
}

/**
 * @param {import('../../store/checkout/types').ContactInfoFormValues} values
 * @param {import('../../components/customer-information-form/customer-information-form').Props} props
 */
export function handleValidate(values, props) {
  const {
    addressBook,
    addressConfiguration,
    intl,
    registeredFields = {},
  } = props;
  const { formatMessage } = intl;

  const validationResults = validateCustomerInfo(values, formatMessage);

  if (addressConfiguration) {
    return {
      ...validationResults,
      ...validateShippingAddress(
        values,
        addressConfiguration,
        formatMessage,
        addressBook,
        Object.keys(registeredFields),
      ),
    };
  }
  return validationResults;
}
