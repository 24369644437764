import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import assign from 'lodash/assign';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';
import filter from 'lodash/filter';

import FormName from '../../constants/form-name';
import {
  allowInvoiceAddress,
  getCustomerAddressBook,
  getCustomerPaymentSources,
  getOrderBillingAddress,
  getOrderDestination,
  getOrderInvoiceAddress,
  getOrderNumber,
  getReviewPaymentMethodId,
  getTaxRegistration,
  getBillingAddressFromPaymentFormValues,
  getBillingAddressFromReviewFormValues,
  getReviewBillingAddress,
  getIsReturningCustomersEnabled,
  getReturningCustomer,
  getIsPaymentMethodPositioningEnabled,
  getPaymentMethodRules,
  getInitialPaymentMethodId,
  getOrder,
  getOrderTotalCurrency,
  getAddressConfigurationOrUseDefaultByCountryCode,
} from '../../store/checkout';
import { getOrganization } from '../../store/flow';
import { getMarketingOptInsInitialFormValues } from '../../store/content';
import { getOptinPrompts } from '../../store/optin';
import { getAdministrativeDivisionsByCountryCode } from '../../store/reference';
import {
  billingAddressFromAddressBook,
  billingAddressFromOrderAddress,
} from './utilities';
import mapOptinPromptsToValuePairs from '../../utilities/map-optin-prompts-to-value-pairs';
import { removeUnnecessaryFieldValidationRules } from '../../utilities/address-utilities';
import { getContactById, getPreferredBillingContact, getPreferredInvoiceContact } from '../../utilities/address-book-utilities';

/**
 * @typedef {import('../../store/types').RootState} RootState
 */

const getValueOfField = formValueSelector(FormName.PAYMENT_INFORMATION);

const getInitialValuesForBillingAddress = createSelector(
  getOrderBillingAddress,
  getOrderDestination,
  getReviewBillingAddress,
  getCustomerAddressBook,
  getIsReturningCustomersEnabled,
  (billingAddress, shippingAddress, formData, addressBook, isReturningCustomersEnabled) => {
    // Use values stored in review state
    if (formData != null) {
      return formData;
    }

    /**
     * @type {import('../../store/checkout/types').BillingAddressFormValues}
     */
    const initialValues = {};

    // Check billing address stored in the order model
    if (billingAddress != null && billingAddress.name != null && billingAddress.streets != null) {
      initialValues.addressToUse = 'newAddress';

      if (isEqual(billingAddress, billingAddressFromOrderAddress(shippingAddress))) {
        initialValues.addressToUse = 'sameAsShippingAddress';
      } else if (isReturningCustomersEnabled && addressBook != null) {
        const selectedContact = addressBook.contacts.find((contact) => (
          isEqual(billingAddress, billingAddressFromAddressBook(contact))
        ));

        // Billing address matches one of the address book contacts.
        if (selectedContact != null) {
          initialValues.addressToUse = selectedContact.id;
        }
      }

      if (initialValues.addressToUse === 'newAddress') {
        [initialValues.addressLine1, initialValues.addressLine2] = billingAddress.streets;
        initialValues.administrativeArea = billingAddress.province;
        initialValues.country = billingAddress.country;
        initialValues.firstName = billingAddress.name.first;
        initialValues.lastName = billingAddress.name.last;
        initialValues.locality = billingAddress.city;
        initialValues.organizationName = billingAddress.company;
        initialValues.postalCode = billingAddress.postal;
      }
    // Default to shipping address unless a preferred billing address is
    // available in the customer's address book.
    } else {
      initialValues.addressToUse = 'sameAsShippingAddress';

      if (isReturningCustomersEnabled && addressBook != null) {
        const preferredContact = getPreferredBillingContact(addressBook);

        if (preferredContact != null) {
          initialValues.addressToUse = preferredContact.id;
        }
      }
    }

    return initialValues;
  },
);

const getInitialValuesForInvoiceAddress = createSelector(
  getOrderDestination,
  getOrderInvoiceAddress,
  getTaxRegistration,
  getCustomerAddressBook,
  getIsReturningCustomersEnabled,
  (
    shippingAddress,
    invoiceAddress,
    taxRegistration,
    addressBook,
    isReturningCustomersEnabled,
  ) => {
    /**
     * @type {import('../../store/checkout/types').InvoiceAddressFormValues}
     */
    const initialValues = {};

    if (taxRegistration != null) {
      initialValues.vrn = taxRegistration.number;
    }

    if (invoiceAddress != null && invoiceAddress.name != null && invoiceAddress.streets != null) {
      initialValues.addressToUse = 'newAddress';

      if (isEqual(invoiceAddress, billingAddressFromOrderAddress(shippingAddress))) {
        initialValues.addressToUse = 'sameAsShippingAddress';
      } else if (isReturningCustomersEnabled && addressBook != null) {
        const selectedContact = addressBook.contacts.find((contact) => (
          isEqual(invoiceAddress, billingAddressFromAddressBook(contact))
        ));

        if (selectedContact != null) {
          initialValues.addressToUse = selectedContact.id;
        }
      }

      if (initialValues.addressToUse === 'newAddress') {
        [initialValues.addressLine1, initialValues.addressLine2] = invoiceAddress.streets;
        initialValues.administrativeArea = invoiceAddress.province;
        initialValues.country = invoiceAddress.country;
        initialValues.firstName = invoiceAddress.name.first;
        initialValues.lastName = invoiceAddress.name.last;
        initialValues.locality = invoiceAddress.city;
        initialValues.organizationName = invoiceAddress.company;
        initialValues.postalCode = invoiceAddress.postal;
      }
    } else {
      initialValues.addressToUse = 'sameAsShippingAddress';

      if (isReturningCustomersEnabled && addressBook != null) {
        const preferredContact = getPreferredInvoiceContact(addressBook);

        if (preferredContact != null) {
          initialValues.addressToUse = preferredContact.id;
        }
      }
    }

    return initialValues;
  },
);

export const getInitialValues = createSelector(
  getInitialValuesForBillingAddress,
  getInitialValuesForInvoiceAddress,
  getReturningCustomer,
  getCustomerPaymentSources,
  getOrder,
  getOrderNumber,
  getOrderTotalCurrency,
  getOrganization,
  getMarketingOptInsInitialFormValues,
  getOptinPrompts,
  allowInvoiceAddress,
  getPaymentMethodRules,
  getReviewPaymentMethodId,
  getIsReturningCustomersEnabled,
  getIsPaymentMethodPositioningEnabled,
  getInitialPaymentMethodId,
  (
    billingAddress,
    invoiceAddress,
    customer,
    paymentSources,
    order,
    orderNumber,
    orderTotalCurrency,
    organizationId,
    marketingOptIns,
    optinPrompts,
    isInvoiceAddressAllowed,
    paymentMethodRules,
    reviewPaymentMethodId,
    isReturningCustomersEnabled,
    isPaymentMethodPositioningEnabled,
    initialPaymentMethod,
  ) => {
    const paymentMethodId = initialPaymentMethod;

    const paymentOptins = filter(
      optinPrompts,
      (optinPrompt) => get(optinPrompt, 'display.display_position', 'submission') === 'submission',
    );

    const initialValues = {
      billingAddress,
      currencyCode: orderTotalCurrency,
      invoiceAddress: isInvoiceAddressAllowed ? invoiceAddress : undefined,
      optinPrompts: fromPairs(mapOptinPromptsToValuePairs(paymentOptins, order)),
      giftCard: {
        giftCardV2Number: '',
        giftCardV2Pin: '',
      },
      merchantOfRecord: order.merchant_of_record,
      orderNumber,
      organizationId,
      paymentMethodId,
    };

    if (customer != null) {
      initialValues.customerNumber = customer.number;
    }

    return assign(initialValues, marketingOptIns);
  },
);

/**
 * @deprecated Moved to avoid circular dependency.
 */
export const getBillingAddress = getBillingAddressFromPaymentFormValues;

/**
 * @deprecated Moved to avoid circular dependency.
 */
export const getBillingAddressFromReview = getBillingAddressFromReviewFormValues;

export function getPaymentMethodIdFieldValue(state) {
  const initialValues = getInitialValues(state);
  const initialValue = initialValues.paymentMethodId;
  return defaultTo(getValueOfField(state, 'paymentMethodId'), initialValue);
}

export function getBillingAddressCountryFieldValue(state) {
  const addressBook = getCustomerAddressBook(state);
  const orderAddress = getOrderDestination(state);
  const initialValues = getInitialValues(state);

  let addressToUse = getValueOfField(state, 'billingAddress.addressToUse');

  if (addressToUse == null && initialValues.billingAddress != null) {
    // eslint-disable-next-line prefer-destructuring
    addressToUse = initialValues.billingAddress.addressToUse;
  }

  if (addressToUse === 'newAddress') {
    return defaultTo(
      getValueOfField(state, 'billingAddress.country'),
      initialValues.billingAddress.country,
    );
  }

  if (addressToUse === 'sameAsShippingAddress') {
    return orderAddress.country;
  }

  let contact;

  if (addressBook != null) {
    contact = getContactById(addressBook, addressToUse);
  }

  return contact != null ? contact.address.country : undefined;
}

/**
 * Returns address configuration to be used in billing address form.
 * @param {RootState} state
 * @returns {AddressConfiguration}
 */
export function getBillingAddressConfiguration(state) {
  const countryCode = getBillingAddressCountryFieldValue(state);
  const addressConfiguration = getAddressConfigurationOrUseDefaultByCountryCode(countryCode)(state);
  return removeUnnecessaryFieldValidationRules(addressConfiguration);
}

export function getBillingAddressAdministrativeDivisionOptions(state) {
  const countryCode = getBillingAddressCountryFieldValue(state);
  return getAdministrativeDivisionsByCountryCode(countryCode)(state);
}

export function getInvoiceAddressCountryFieldValue(state) {
  const addressBook = getCustomerAddressBook(state);
  const orderAddress = getOrderDestination(state);
  const initialValues = getInitialValues(state);

  let addressToUse = getValueOfField(state, 'invoiceAddress.addressToUse');

  if (addressToUse == null && initialValues.invoiceAddress != null) {
    // eslint-disable-next-line prefer-destructuring
    addressToUse = initialValues.invoiceAddress.addressToUse;
  }

  if (addressToUse === 'newAddress') {
    return defaultTo(
      getValueOfField(state, 'invoiceAddress.country'),
      initialValues.invoiceAddress.country,
    );
  }

  if (addressToUse === 'sameAsShippingAddress') {
    return orderAddress.country;
  }

  let contact;

  if (addressBook != null) {
    contact = getContactById(addressBook, addressToUse);
  }

  return contact != null ? contact.address.country : undefined;
}

/**
 * Returns address configuration to be used in invoice address form.
 * @param {RootState} state
 * @returns {AddressConfiguration}
 */
export function getInvoiceAddressConfiguration(state) {
  const countryCode = getInvoiceAddressCountryFieldValue(state);
  const addressConfiguration = getAddressConfigurationOrUseDefaultByCountryCode(countryCode)(state);
  return removeUnnecessaryFieldValidationRules(addressConfiguration);
}

export function getInvoiceAddressAdministrativeDivisionOptions(state) {
  const countryCode = getInvoiceAddressCountryFieldValue(state);
  return getAddressConfigurationOrUseDefaultByCountryCode(countryCode)(state);
}
