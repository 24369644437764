import { getOrganization } from '../../store/flow/selectors';
import { getOrderNumber } from '../../store/checkout/selectors';

/**
 * Add checkout specific 'extra' information for rollbar.
 */
export default function reduxRollbarMiddlewareExtend({ state, options }) {
  const organization = getOrganization(state);
  const orderNumber = getOrderNumber(state);

  return {
    ...options,
    organization,
    orderNumber,
  };
}
