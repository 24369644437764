/* eslint-disable react/no-array-index-key */

import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import OrderItem from '../../containers/order-item';
import OrderShopifyItem from '../../containers/order-shopify-item';
import getItemPriceLabel from '../../utilities/get-item-price-label';
import getDeliveryItemPriceLabel from '../../utilities/get-delivery-item-price-label';

if (process.browser) {
  require('./order-delivery-items.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-delivery-items');

const OrderDeliveryItems = ({
  order,
  itemContentsMap,
  isShopifyIntegration,
}) => {
  const hasMultipleDeliveries = order.deliveries.length > 1;

  if (!hasMultipleDeliveries) {
    if (order.lines != null) {
      return (
        <div>
          <div className={bem.element('delivery')}>
            {order.lines.map((line, index) => (
              (isShopifyIntegration) ? (
                <OrderShopifyItem
                  key={`${line.item_number}_${index}`}
                  content={get(itemContentsMap, line.item_number)}
                  quantity={line.quantity}
                  priceLabel={line.price.label}
                />
              ) : (
                <OrderItem
                  key={`${line.item_number}_${index}`}
                  content={get(itemContentsMap, line.item_number)}
                  quantity={line.quantity}
                  priceLabel={line.price.label}
                />
              )
            ))}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={bem.element('delivery')}>
          {order.items.map((item, index) => (
            (isShopifyIntegration) ? (
              <OrderShopifyItem
                key={`${item.number}_${index}`}
                content={get(itemContentsMap, item.number)}
                quantity={item.quantity}
                priceLabel={getItemPriceLabel(item)}
              />
            ) : (
              <OrderItem
                key={`${item.number}_${index}`}
                content={get(itemContentsMap, item.number)}
                quantity={item.quantity}
                priceLabel={getItemPriceLabel(item)}
              />
            )
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {order.deliveries.map((delivery, index) => (
        <div key={delivery.id}>
          <div className={bem.element('delivery')}>
            <h1 className={bem.element('delivery-header')}>
              <FormattedMessage
                id="checkout_order_summary_shipment_heading"
                description="A line showing the delivery number in a list of deliveries"
                defaultMessage="Shipment {deliveryIndex} of {deliveryCount}"
                values={{
                  deliveryIndex: index + 1,
                  deliveryCount: order.deliveries.length,
                }}
              />
            </h1>
            {delivery.items.map((deliveryItem, position) => (
              isShopifyIntegration ? (
                <OrderShopifyItem
                  key={`${deliveryItem.number}_${position}`}
                  content={get(itemContentsMap, deliveryItem.number)}
                  quantity={deliveryItem.quantity}
                  priceLabel={getDeliveryItemPriceLabel(deliveryItem, order)}
                />
              ) : (
                <OrderItem
                  key={`${deliveryItem.number}_${position}`}
                  content={get(itemContentsMap, deliveryItem.number)}
                  quantity={deliveryItem.quantity}
                  priceLabel={getDeliveryItemPriceLabel(deliveryItem, order)}
                />
              )
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

OrderDeliveryItems.displayName = 'OrderDeliveryItems';

OrderDeliveryItems.propTypes = {
  order: ApiPropTypes.order.isRequired,
  itemContentsMap: PropTypes.objectOf(ApiPropTypes.checkoutItemContent),
  isShopifyIntegration: PropTypes.bool.isRequired,
};

OrderDeliveryItems.defaultProps = {
  itemContentsMap: {},
};

export default OrderDeliveryItems;
