/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const Padlock = ({ ...unhandledProps }) => (
  <SvgIcon {...unhandledProps} viewBox="0 0 12 16">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-6.000000, -4.000000)" fillRule="nonzero">
        <path d="M16.5393101,10.5102229 L16.1077437,10.5102229 L16.1059964,8.32177867 C16.1042492,5.93777885 14.1980185,3.99822344 11.8549797,4.00000122 C9.51194088,4.001779 7.60571019,5.94133441 7.60745742,8.32533422 L7.61095189,10.5137785 L7.45719542,10.5137785 C6.65172123,10.5155563 5.99825626,11.1804451 6.0000035,12.0000006 L6.0000035,18.5155557 C6.0000035,19.3351112 6.65521569,20 7.46068989,20 L16.5428046,19.9946667 C17.3482788,19.9946667 18.0017437,19.3297778 17.9999965,18.5102223 L17.9999965,11.9928895 C17.9999965,11.173334 17.3447843,10.5102229 16.5393101,10.5102229 Z M9.17647223,10.5137785 L9.17297777,8.32533422 C9.17297777,6.81955656 10.3750737,5.59466777 11.8549797,5.59288999 C13.3348857,5.59288999 14.5387289,6.81777878 14.5387289,8.32177867 L14.5422233,10.5102229 L9.17647223,10.5137785 Z" />
      </g>
    </g>
  </SvgIcon>
);

Padlock.displayName = 'Padlock';

export default Padlock;
