/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BemHelper from '@flowio/bem-helper';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import isNil from 'lodash/isNil';

import createUniqueIdFactory from '../../utilities/create-unique-id-factory';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./checkbox.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('checkbox');
const uniqueId = createUniqueIdFactory('Checkbox');

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.id = uniqueId();
    this.state = this.getInitialState();
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  getInitialState() {
    const {
      checked,
      defaultChecked,
      defaultIndeterminate,
      indeterminate,
    } = this.props;
    return {
      checked: isNil(checked) ? defaultChecked : checked,
      indeterminate: isNil(indeterminate) ? defaultIndeterminate : indeterminate,
    };
  }

  componentDidMount() {
    this.setIndeterminate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      checked,
      errorText,
      indeterminate,
      name,
    } = nextProps;

    const {
      errorText: previousErrorText,
    } = this.props;

    if (!isNil(checked)) {
      this.setState({ checked });
    }

    if (!isNil(indeterminate)) {
      this.setState({ indeterminate });
    }

    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: name,
      });
    }
  }

  componentDidUpdate() {
    this.setIndeterminate();
  }

  handleBlur(event) {
    const { name, onBlur } = this.props;

    trackHeapEvent('field_blur', {
      field: name,
    });

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  handleChange(event) {
    const { onChange } = this.props;
    const { checked } = this.state;

    if (!this.isControlled()) {
      this.setState({
        checked: !checked,
        indeterminate: false,
      });
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  handleFocus(event) {
    const { name, onFocus } = this.props;

    trackHeapEvent('field_focus', {
      field: name,
    });

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  getTabIndex() {
    const { disabled, tabIndex } = this.props;
    if (!isNil(tabIndex)) return tabIndex;
    return disabled ? -1 : 0;
  }

  // Note: You can't directly set the indeterminate prop on the input, so we
  // need to maintain a ref to the input and set it manually whenever the
  // component updates.
  setIndeterminate() {
    const { indeterminate } = this.state;

    if (this.inputNode) {
      this.inputNode.indeterminate = !!indeterminate;
    }
  }

  setInputNode = (node) => {
    this.inputNode = node;
  };

  isControlled() {
    const { checked, indeterminate } = this.props;
    return !isNil(checked) || !isNil(indeterminate);
  }

  render() {
    const {
      as: ElementType,
      automationId,
      className,
      disabled,
      errorText,
      id = this.id,
      labelText,
      fitted = isNil(labelText),
      fluid,
      name,
      required,
      value,
    } = this.props;
    const { checked, indeterminate } = this.state;
    const tabIndex = this.getTabIndex();
    const unhandledProps = getUnhandledProps(Checkbox, this.props);

    return (
      <ElementType
        {...unhandledProps}
        className={bem.block({
          checked, disabled, fitted, fluid, indeterminate,
        }, className)}
        data-automation-id={automationId}
      >
        <input
          ref={this.setInputNode}
          type="checkbox"
          id={id}
          className={bem.element('input')}
          checked={checked}
          disabled={disabled}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          required={required}
          tabIndex={tabIndex}
          value={value}
        />
        <label htmlFor={id} className={bem.element('label')}>
          {labelText}
        </label>
        {errorText && (
          <div className={bem.element('error-text')}>
            {errorText}
          </div>
        )}
      </ElementType>
    );
  }
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  as: PropTypes.elementType,
  automationId: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  defaultIndeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.node,
  fitted: PropTypes.bool,
  fluid: PropTypes.bool,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  labelText: PropTypes.node,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  tabIndex: PropTypes.string,
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  as: 'div',
  automationId: undefined,
  checked: undefined,
  className: '',
  defaultChecked: false,
  defaultIndeterminate: false,
  disabled: false,
  errorText: undefined,
  fitted: undefined,
  fluid: false,
  id: undefined,
  indeterminate: undefined,
  labelText: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  required: false,
  tabIndex: undefined,
  value: undefined,
};

export default Checkbox;
