import { FormattedMessage } from 'react-intl';
import React from 'react';
import BemHelper from '@flowio/bem-helper';

import './confirm-dialog.css';
import { Dialog } from '../dialog';
import Button from '../button';

const bem = new BemHelper('confirm-dialog');

interface Children {
  confirm?: React.ReactNode;
  cancel?: React.ReactNode;
  header: React.ReactNode;
  content: React.ReactNode | React.ReactNode[];
}

export interface Props {
  children: Children;
  isOpen: boolean;
  onCancel?: React.MouseEventHandler;
  onConfirm?: React.MouseEventHandler;
}

const ConfirmDialog: React.FunctionComponent<Props> = ({
  children,
  isOpen,
  onCancel,
  onConfirm,
}) => (
  <Dialog
    data-automation-id="confirm-modal"
    showBackdrop
    className={bem.block('confirm-dialog')}
    isOpen={isOpen}
  >
    <Dialog.Header>
      {children.header}
    </Dialog.Header>
    <Dialog.Body className={bem.element('content')}>
      {children.content}
    </Dialog.Body>
    <Dialog.Actions className={bem.element('actions')}>
      <Button color="secondary" onClick={onCancel} fluid>
        {children.cancel != null ? children.cancel : (
          <FormattedMessage
            id="checkout_dialog_cancel_action"
            description="A label that indicates the cancel action of the a dialog"
            defaultMessage="Cancel"
          />
        )}
      </Button>
      <Button color="primary" onClick={onConfirm} fluid>
        {children.confirm != null ? children.confirm : (
          <FormattedMessage
            id="checkout_dialog_confirm_action"
            description="Text used in call to actions to confirm an action"
            defaultMessage="Ok"
          />
        )}
      </Button>
    </Dialog.Actions>
  </Dialog>
);

export default ConfirmDialog;
