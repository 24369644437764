import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';

import { getInitialValues } from './selectors';
import { handleValidate } from './validation';
import { submitCoupon } from '../../store/checkout/actions';
import FormName from '../../constants/form-name';
import CouponForm from '../../components/coupon-form';

const handleSubmit = (values, dispatch) => dispatch(submitCoupon(values));

const handleSubmitSuccess = (result, dispatch) => dispatch(reset(FormName.COUPON));

const mapStateToProps = createStructuredSelector({
  initialValues: getInitialValues,
});

export default compose(
  injectIntl,
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true,
    form: FormName.COUPON,
    keepDirtyOnReinitialize: true,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSubmitSuccess,
    validate: handleValidate,
    touchOnBlur: false,
  }),
)(CouponForm);
