import { reset, SubmissionError } from 'redux-form';

import { getOrderNumber } from '../selectors';
import { getOrganization } from '../../flow/selectors';
import addGiftCard from './add-gift-card';
import fetchOrder from './fetch-order';
import FormName from '../../../constants/form-name';
import checkHttpStatus from '../../../utilities/check-http-status';
import { showLoadingIndicator, hideLoadingIndicator } from '../../application/actions';

export default function submitGiftCard(values) {
  return function submitGiftCardSideEffects(dispatch, getState) {
    const state = getState();
    const orderNumber = getOrderNumber(state);
    const organization = getOrganization(state);

    dispatch(showLoadingIndicator());

    return dispatch(addGiftCard(organization, orderNumber, { ...values }))
      .then(checkHttpStatus)
      .then(() => dispatch(fetchOrder(organization, orderNumber)))
      .then(checkHttpStatus)
      .then(dispatch(reset(FormName.GIFT_CARD_FORM)))
      .then(() => dispatch(hideLoadingIndicator()))
      .catch((error) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          default:
            throw error;
        }
      });
  };
}
