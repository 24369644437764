import update from 'immutability-helper';
import defaultTo from 'lodash/defaultTo';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.experience.v0.models.Order | null;

const initialState: State = null;

const orderReducer: AppReducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.UPDATE_SHIPPING_METHOD_REQUEST:
      return state != null ? {
        ...state,
        selections: action.params.selections,
      } : state;
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return action.payload.order;
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.SUBMIT_ORDER_CHECKOUT_SUCCESS:
    {
      const { device_details: deviceDetails } = state || {};
      const { order } = action.payload.builder;
      let updatedOrder;

      if (order && !order?.device_details && deviceDetails) {
        updatedOrder = { ...order, device_details: deviceDetails };
      } else {
        updatedOrder = order;
      }

      return defaultTo(updatedOrder, state);
    }

    case actionTypes.ADD_DEVICE_DETAILS:
      return update(state, {
        device_details: { $set: action.payload },
      });
    case actionTypes.ADD_PROMOTION_CODE_SUCCESS:
    case actionTypes.CREATE_SHOPIFY_ORDER_SUCCESS:
    case actionTypes.FETCH_ORDER_SUCCESS:
    case actionTypes.REMOVE_PROMOTION_CODE_SUCCESS:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_SUCCESS:
    case actionTypes.SUBMIT_ORDER_SUCCESS:
    case actionTypes.UPDATE_ORDER:
      return action.payload;
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.ADD_GIFT_CARD_V2_SUCCESS:
    case actionTypes.CHECK_VRN_NUMBER_SUCCESS:
    case actionTypes.CREATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.FINALIZE_ORDER_SUCCESS:
    case actionTypes.PATCH_ORDER_SUCCESS:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.SET_ORDER_ATTRIBUTES_SUCCESS:
    case actionTypes.SUBMIT_ORDER_BUNDLE_SUCCESS:
    case actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_ORDER_BUILDER_SUCCESS:
    case actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS: {
      const { device_details: deviceDetails } = state || {};
      const { order } = action.payload;
      let updatedOrder;

      if (order && !order?.device_details && deviceDetails) {
        updatedOrder = { ...order, device_details: deviceDetails };
      } else {
        updatedOrder = order;
      }

      return defaultTo(updatedOrder, state);
    }
    default:
      return state;
  }
};

export default orderReducer;
