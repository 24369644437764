import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import Chip from '../chip';

import './order-total.css';

interface Props {
  isConfirmationStep: boolean;
  order: io.flow.v0.models.Order;
  onRemoveCreditPayment(paymentReference: string): void;
}

const bem = new BemHelper('order-total');

const OrderTotal: React.FunctionComponent<Props> = ({
  isConfirmationStep,
  order,
  onRemoveCreditPayment,
}) => {
  /*
    The following contains a hack put in place to temporarily support cash on delivery for demo purposes.
    This updates the order balance to be the full total when the entire payment was made up
    of CoD. It should be removed and its implementation better thought through when it comes to fully
    supporting this payment method. All of the following code block can be cleanly removed
    once there is no further need for it.
  */
  // TODO: remove after cash on delivery support is no longer required for demo purposes
  const isFullyCashOnDeliveryPayment = order.payments != null && order.payments.length && order.payments.every(({ type, description }) => type === 'credit' && description === 'Cash on Delivery');
  if (isFullyCashOnDeliveryPayment) {
    return (
      <dl className={bem.block()}>
        <dt className={bem.element('label')}>
          <FormattedMessage
            id="checkout_order_summary_line_total"
            description="Text identifying the total amount of the order"
            defaultMessage="Total"
          />
        </dt>
        <dd className={bem.element('value')}>
          {order.total.label}
        </dd>
        <dt className={bem.element('amount-paid-label')}>
          Amount Paid
        </dt>
        <dd className={bem.element('payment-value')}>
          {order.balance?.label || '-'}
        </dd>
        {!isConfirmationStep && (
          <>
            <dt className={bem.element('label')}>
              <FormattedMessage
                id="checkout_order_summary_line_cash_on_delivery_balance"
                description="Text identifying the balance due on delivery of the order"
                defaultMessage="Balance"
              />
            </dt>
            <dd className={bem.element('value')}>
              {order.total.label}
            </dd>
          </>
        )}
      </dl>
    );
  }

  return (
    <dl className={bem.block()}>
      <dt className={bem.element('label')}>
        <FormattedMessage
          id="checkout_order_summary_line_total"
          description="Text identifying the total amount of the order"
          defaultMessage="Total"
        />
      </dt>
      <dd className={bem.element('value')}>
        {order.total.label}
      </dd>
      {order.payments != null && order.payments.map((payment) => (
        <React.Fragment key={payment.id}>
          <dt className={bem.element('payment-label')}>
            <Chip
              size="small"
              color="light"
              dismissible={!isConfirmationStep && payment.type === 'credit'}
              text={payment.description}
              onRequestDelete={(): void => {
                if (payment.type === 'credit') {
                  onRemoveCreditPayment(payment.reference);
                }
              }}
            />
          </dt>
          <dd className={bem.element('payment-value')}>
            {`- ${payment.total.label}`}
          </dd>
        </React.Fragment>
      ))}
      {!isConfirmationStep
        && order.balance != null
        && order.balance.amount !== order.total.amount && (
        <>
          <dt className={bem.element('label')}>
            <FormattedMessage
              id="checkout_order_summary_line_balance"
              description="Text identifying the balance amount due for the order"
              defaultMessage="Balance"
            />
          </dt>
          <dd className={bem.element('value')}>
            {order.balance.label}
          </dd>
        </>
      )}
    </dl>
  );
};

export default OrderTotal;
