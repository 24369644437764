import get from 'lodash/get';
import storage from '@flowio/storage';

import { getVisitorId, getSession } from '../../../utilities/session';
import { getCheckoutId } from '../selectors';

export default function canTriggerInitialized() {
  return function canTriggerInitializedEffects(dispatch, getState) {
    const state = getState();
    const visitorId = getVisitorId();
    const checkoutId = getCheckoutId(state);
    const cookieName = `${visitorId}.${checkoutId}`;
    const initializedCookie = storage.cookie.get(cookieName);
    const sessionExpiresAt = get(getSession(), 'visit.expires_at');

    if (!initializedCookie) {
      storage.cookie.set(cookieName, true, {
        expires: sessionExpiresAt,
      });
      return true;
    }

    return false;
  };
}
