/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getElementType from '@flowio/react-helpers/lib/getElementType';

import bem from './bem';
import StepGroup from './step-group';
import StepTitle from './step-title';

if (process.browser) {
  require('./step.css');
}

const Step = (props) => {
  const {
    active,
    children,
    className,
    completed,
    title,
  } = props;

  const unhandledProps = getUnhandledProps(Step, props);

  const ElementType = getElementType(Step, props);

  const modifiers = { active, completed };

  if (children != null) {
    return (
      <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
      <StepTitle text={title} />
    </ElementType>
  );
};

Step.displayName = 'Step';

Step.propTypes = {
  active: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  completed: PropTypes.bool,
  title: PropTypes.string,
};

Step.defaultProps = {
  active: false,
  as: 'li',
  children: undefined,
  className: '',
  completed: false,
  title: undefined,
};

Step.Group = StepGroup;
Step.Title = StepTitle;

export default Step;
