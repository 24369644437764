/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./non-ideal-state.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('non-ideal-state');

const NonIdealState = ({
  className,
  description,
  heading,
  visual,
  ...unhandledProps
}) => (
  <div {...unhandledProps} className={bem.block(className)}>
    {visual !== undefined && (
      <div className={bem.element('visual')}>
        {visual}
      </div>
    )}
    {heading !== undefined && (
      <h4 className={bem.element('heading')}>
        {heading}
      </h4>
    )}
    {description !== undefined && (
      <div className={bem.element('description')}>
        {description}
      </div>
    )}
  </div>
);

NonIdealState.displayName = 'NonIdealState';

NonIdealState.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node,
  heading: PropTypes.node,
  visual: PropTypes.node,
};

NonIdealState.defaultProps = {
  className: '',
  description: undefined,
  heading: undefined,
  visual: undefined,
};

export default NonIdealState;
