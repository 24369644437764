/* eslint-disable react/jsx-props-no-spreading */

import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';
import hoistNonReactStatics from 'hoist-non-react-statics';

import SelectField from '../select-field';

const ReduxFormSelectField = ({
  input, meta, unnamed, ...unhandledProps
}) => {
  const { touched, error, warning } = meta;

  let errorText = error || warning;
  let { name } = input;

  if (!touched) {
    errorText = undefined;
  }

  if (unnamed) {
    name = undefined;
  }

  return (
    <SelectField
      {...input}
      {...unhandledProps}
      name={name}
      errorText={errorText}
    />
  );
};

ReduxFormSelectField.displayName = `ReduxForm(${getDisplayName(SelectField)})`;

ReduxFormSelectField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  unnamed: PropTypes.bool,
};

ReduxFormSelectField.defaultProps = {
  unnamed: false,
};

export default hoistNonReactStatics(ReduxFormSelectField, SelectField, {
  handledProps: true,
});
