import { WrappedFieldProps } from 'redux-form';
import React from 'react';

import TextField from '../text-field';

type OwnProps = {
  unnamed?: boolean;
};

type UnhandledProps = Omit<
PropsOf<typeof TextField>,
keyof OwnProps
>;

export type BaseProps = OwnProps & UnhandledProps;

export type Props = BaseProps & WrappedFieldProps;

export default class ReduxFormTextField extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleBlur = this.handleBlur.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleChange = this.handleChange.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleBlur(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { input } = this.props;
    input.onBlur(event);
  }

  handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ): void {
    const { input } = this.props;
    input.onChange(value);
  }

  handleFocus(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { input } = this.props;
    input.onFocus(event);
  }

  render(): React.ReactElement {
    const {
      input,
      meta,
      unnamed,
      ...unhandledProps
    } = this.props;

    let errorText = meta.error || meta.warning;

    if (!meta.touched) {
      errorText = undefined;
    }

    return (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...unhandledProps}
        errorText={errorText}
        name={unnamed ? undefined : input.name}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onBlur={this.handleBlur}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onChange={this.handleChange}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onFocus={this.handleFocus}
        value={input.value}
      />
    );
  }
}
