// @ts-check

import { createSelector } from 'reselect';
import assign from 'lodash/assign';
import every from 'lodash/every';
import find from 'lodash/find';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import matches from 'lodash/matches';
import negate from 'lodash/negate';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import property from 'lodash/property';
import some from 'lodash/some';
import values from 'lodash/values';
import uniq from 'lodash/uniq';

import { getCartItems } from '../../cart/selectors';
import { getLanguage } from '../../intl/selectors';
import { getReturnUrl, getOrganizationUrl, getIntegration } from '../../flow/selectors';
import { toOrderPutForm } from '../../../utilities/order-converters';
import DeliveredDuties from '../../../constants/delivered-duties';
import DeliveredDutyDisplayType from '../../../constants/delivered-duty-display-type';
import OrderAttributes from '../../../constants/order-attributes';
import ReadyState from '../../../utilities/redux/ready-state';
import UrlParameters from '../../../constants/url-parameters';
import Features from '../../../constants/features';
import AuthorizationStatus from '../../../constants/authorization-status';
import PaymentTypes from '../../../constants/payment-types';
import { getDefaultAddressFieldValidation } from '../../../utilities/address-utilities';
import { getAdministrativeDivisionsByCountryCode } from '../../reference/selectors';
import { billingAddressFromOrderAddress, billingAddressFromFormValues, billingAddressFromAddressBook } from '../../../containers/payment-form/utilities';
import {
  getCheckoutEntities,
  getCheckoutHacks,
  getCheckoutMetadata,
  getCheckoutState,
  getFeatureValues,
  getOrder,
  getOrderAttributes,
  getPaymentMethodParams,
} from './ts_selectors';
import isNotNil from '../../../utilities/is-not-nil';

/**
 * @param {string} featureKey
 * @return {(state: import('../../types').RootState) => boolean}
 */
export const isFeatureEnabled = (featureKey) => (state) => {
  const featureValues = getFeatureValues(state);
  return featureValues != null && featureValues.some(
    (featureValue) => featureValue.feature.key === featureKey && featureValue.value === true,
  );
};

/**
 * @deprecated
 */
const checkFeatureByKey = isFeatureEnabled;

/**
 * @type {(featureKey: string) => (state: import('../../types').RootState) => io.flow.internal.v0.unions.FeatureValue | undefined}
 */
export const getFeatureValueByKey = (featureKey) => createSelector(
  getFeatureValues,
  (featureValues) => find(featureValues, matches({
    feature: {
      key: featureKey,
    },
  })),
);

const isNotEmpty = negate(isEmpty);

/**
 * @type {(variantId: string) => (cartItem: any) => boolean}
 */
const findCartItemByVariantId = (variantId) => (cartItem) => (
  cartItem.variant_id.toString() === variantId.toString()
);

export const getDidAddGiftCard = createSelector(
  getCheckoutMetadata,
  (metaData) => get(metaData, 'didAddGiftCard'),
);

export const getIsCheckoutResourceEnabled = createSelector(
  getCheckoutMetadata,
  /**
   * @type {(metadata: import('../types').CheckoutMetadataState) => boolean}
   */
  (state) => state.isCheckoutResourceEnabled,
);

export const getCheckoutId = createSelector(
  getCheckoutMetadata,
  /**
   * @type {(metadata: import('../types').CheckoutMetadataState) => string | undefined}
   */
  (metadata) => get(metadata, 'id'),
);

export const getIsCheckoutPreloaded = createSelector(
  getCheckoutMetadata,
  /**
   * @type {(metadata: import('../types').CheckoutMetadataState) => boolean}
   */
  (metadata) => get(metadata, 'isCheckoutPreloaded'),
);

export const getPlatformOrderNumber = createSelector(
  getCheckoutMetadata,
  /**
   * @type {(metadata: import('../types').CheckoutMetadataState) => string}
   */
  (metadata) => get(metadata, 'platform.order_number'),
);

// Returns all the errors in the checkout state.
const getErrors = createSelector(
  getCheckoutState,
  (state) => get(state, 'errors'),
);

// Returns all the request parameters in the checkout state.
const getParams = createSelector(
  getCheckoutState,
  (state) => get(state, 'params'),
);

// Returns the status of all the entities in the checkout state.
export const getStatuses = createSelector(
  getCheckoutState,
  (state) => get(state, 'statuses'),
);

/**
 * Returns whether customer just entered the Checkout process.
 */
export const getIsFirstTimeVisitor = createSelector(
  getStatuses,
  /** @type {function(object): boolean} */
  (statuses) => get(statuses, 'isFirstTimeVisitor'),
);

const getPaymentMethodRuleEntities = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.paymentMethodRules'),
);

const getPaymentMethodRulesPagination = createSelector(
  getCheckoutState,
  (state) => get(state, 'paginations.paymentMethodRules'),
);

const getPaymentMethodRulesCurrentPageNumber = createSelector(
  getPaymentMethodRulesPagination,
  (pagination) => get(pagination, 'currentPage'),
);

const getPaymentMethodRulesCurrentPage = createSelector(
  getPaymentMethodRulesCurrentPageNumber,
  getPaymentMethodRulesPagination,
  (pageNumber, pagination) => get(pagination, `pages.${pageNumber}`),
);

const getPaymentMethodRuleCurrentPageResults = createSelector(
  getPaymentMethodRuleEntities,
  getPaymentMethodRulesCurrentPage,
  (entities, page) => values(pick(entities, get(page, 'ids'))),
);

const getPaymentMethodTypesPagination = createSelector(
  getCheckoutState,
  (state) => get(state, 'paginations.paymentMethodTypes'),
);

const getPaymentMethodTypesCurrentPageNumber = createSelector(
  getPaymentMethodTypesPagination,
  (pagination) => get(pagination, 'currentPage'),
);

const getPaymentMethodTypesCurrentPage = createSelector(
  getPaymentMethodTypesCurrentPageNumber,
  getPaymentMethodTypesPagination,
  (pageNumber, pagination) => get(pagination, `pages.${pageNumber}`),
);

export const getPaymentMethodRules = createSelector(
  getPaymentMethodRuleCurrentPageResults,
  (paymentMethodRules) => paymentMethodRules,
);

export const getPaymentMethodTypes = createSelector(
  getPaymentMethodTypesCurrentPage,
  (page) => get(page, 'types'),
);

// Returns a list of countries for which an organization has experiences.
export const getOrganizationCountries = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.organization.countries'),
);

// Returns the CRUD status of the order entity.
const getOrderStatus = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'order'),
);

const getVrnStatus = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'vrn'),
);

export const getVrn = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.vrn'),
);

export const shouldUpdateReturnUrlAttribute = createSelector(
  getOrderAttributes,
  getReturnUrl,
  (attributes, returnUrl) => returnUrl && get(attributes, UrlParameters.RETURN_URL) !== returnUrl,
);

/**
 * Returns localized line items in the order
 */
export const getItems = createSelector(
  getOrder,
  (order) => get(order, 'items'),
);

/**
 * Returns deliveries in the order
 */
export const getDeliveries = createSelector(
  getOrder,
  (order) => get(order, 'deliveries'),
);

/**
 * Returns lines in the order
 */
export const getLineItems = createSelector(
  getOrder,
  (order) => get(order, 'lines'),
);

export const getOrderError = createSelector(
  getErrors,
  (errors) => get(errors, 'order'),
);

export const getGiftCardError = createSelector(
  getErrors,
  (errors) => get(errors, 'giftCard'),
);

export const getOrderExperience = createSelector(
  getOrder,
  (order) => get(order, 'experience'),
);

export const getOrderExperienceKey = createSelector(
  getOrder,
  (order) => get(order, 'experience.key'),
);

export const getOrderExperienceCountry = createSelector(
  getOrder,
  (order) => get(order, 'experience.country'),
);

/**
 * Returns the region identifier associated with the order experience.
 * @param {object} state
 * @returns {string}
 */
export const getOrderExperienceRegionId = createSelector(
  getOrderExperience,
  (experience) => get(experience, 'region.id'),
);

/**
 * Returns the device details.
 * @param {object} state
 * @returns {string}
 */
export const getDeviceDetails = createSelector(
  getCheckoutMetadata,
  (metaData) => get(metaData, 'deviceDetails'),
);

/**
 * Returns the order number corresponding to the current order.
 */
export const getOrderNumber = createSelector(
  getOrder,
  /** @type {(order: io.flow.v0.models.Order) => string} */
  (order) => get(order, 'number'),
);

export const getOrderSubmittedAt = createSelector(
  getOrder,
  (order) => get(order, 'submitted_at'),
);

export const getOrderBalanceCurrency = createSelector(
  getOrder,
  (order) => get(order, 'balance.currency'),
);

export const getLoyaltyRemoveError = createSelector(
  getErrors,
  property('loyaltyRemove'),
);

export const getGiftCardRemoveError = createSelector(
  getErrors,
  property('giftCardRemove'),
);

// Returns a list of selected deliveries for the order.
export const getSelectedDeliveryOptions = createSelector(
  getOrder,
  getDeliveries,
  (order, deliveries) => {
    const deliveryOptions = flatMap(deliveries, 'options');
    const selectedOptions = filter(deliveryOptions, ({ id }) => includes(order.selections, id));
    return selectedOptions;
  },
);

export const getFeatureValuesStatus = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'featureValues.state'),
);

export const getCheckoutStatus = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'checkout.state'),
);

export const getIsFeatureValuesFulfilled = createSelector(
  getFeatureValuesStatus,
  (status) => status === ReadyState.Fulfilled,
);

export const getGooglePaymentDataRequest = createSelector(
  getPaymentMethodParams,
  (paymentParams) => get(find(paymentParams, matches({
    method: PaymentTypes.GooglePay,
  })), 'parameters'),
);

export const isConfirmationRedirectLogFeatureEnabled = isFeatureEnabled(
  Features.CONFIRMATION_REDIRECT_LOG,
);

/**
 * @param {string} featureKey
 * @returns {function(object): boolean}
 */
export const getIsCollectCustomerAddressEnabled = checkFeatureByKey(
  Features.COLLECT_CUSTOMER_ADDRESS,
);

export const getIsWrapCartItemEnabled = checkFeatureByKey(
  Features.WRAP_ITEM_NAME,
);

export const getIsReturningCustomersEnabled = checkFeatureByKey(
  Features.RETURNING_CUSTOMERS,
);

export const getIsPaymentMethodPositioningEnabled = checkFeatureByKey(
  Features.PAYMENT_METHOD_POSITIONING,
);

export const isMobileUXSpacingEnabled = checkFeatureByKey(
  Features.MOBILE_UX_SPACING,
);

export const getIsShopifyCartV2Enabled = checkFeatureByKey(
  Features.SHOPIFY_JS_CART_V2,
);

export const isFinalizeEnabled = checkFeatureByKey(Features.FINALIZE);

export const isLoyaltyEnabled = checkFeatureByKey(Features.SHOW_LOYALTY);

export const isReviewStepEnabled = checkFeatureByKey(Features.REVIEW_STEP_PAGE);

// Legacy: Gymboree GiftCardSupport
export const isGiftCardEnabled = checkFeatureByKey(Features.SHOW_GIFT_CARD);

export const getDontSubmitAuthAmount = checkFeatureByKey(Features.DONT_SUBMIT_AUTH_AMOUNT);

export const isDiscountsEnabled = checkFeatureByKey(Features.DISCOUNT_CODE);

export const isShopifyCheckoutCodeNewDeleteEnabled = checkFeatureByKey(
  Features.SHOPIFY_CHECKOUT_CODE_NEW_DELETE,
);

export const isForceEnableShopifyDiscountsEnabled = checkFeatureByKey(
  Features.FORCE_ENABLE_SHOPIFY_DISCOUNTS,
);

export const allowVRN = checkFeatureByKey(Features.ALLOW_VRN);

export const allowInvoiceAddress = checkFeatureByKey(Features.ALLOW_INVOICE);

export const isPaymentInfoSummaryEnabled = checkFeatureByKey(Features.PAYMENT_INFO_SUMMARY);

export const isCatchpointRumEnabled = checkFeatureByKey(Features.CATCHPOINT_RUM_TESTING);

export const isServerSideInventoryEnabled = checkFeatureByKey(Features.SERVER_SIDE_INVENTORY);

export const is3DSPendingPageEnabled = checkFeatureByKey(Features.PENDING_PAGE_3DS);

export const isRestrictedItemsModalEnabled = checkFeatureByKey(Features.RESTRICTED_ITEMS_MODAL);

export const isCustomerAddressBookEnabled = checkFeatureByKey(Features.CUSTOMER_ADDRESS_BOOK);

export const isCheckoutResourceFeatureEnabled = checkFeatureByKey(Features.CHECKOUT_RESOURCE);

export const isAddSessionToAuthorizationReturnUrlEnabled = checkFeatureByKey(
  Features.ADD_SESSION_TO_AUTHORIZATION_RETURN_URL,
);

export const isForceOrderNumberFromPlatform = (
  checkFeatureByKey(Features.FORCE_ORDER_NUMBER_FROM_PLATFORM)
);

// Generic Gift Card Support
export const isGiftCardsEnabled = checkFeatureByKey(Features.GIFT_CARDS);

export const getKlarnaRedirectEnabled = createSelector(
  getFeatureValueByKey(Features.KLARNA_REDIRECT),
  (featureValue) => featureValue != null && featureValue.value === true,
);

// Returns whether at least one delivery in the order is using SF Express as
// the shipping carrier.
export const getIsSFExpressOrder = createSelector(
  getSelectedDeliveryOptions,
  (options) => some(options, (option) => get(option, 'service.carrier.id') === 'sf-express'),
);

// Returns the customer correspoding to the Flow order entity.
export const getOrderCustomer = createSelector(
  getOrder,
  (order) => get(order, 'customer'),
);

/** @type {function(import('../../types').RootState): io.flow.v0.models.BillingAddress | undefined} */
export const getInvoiceAddress = createSelector(
  getOrderCustomer,
  (customer) => get(customer, 'invoice.address'),
);

// Returns the delivered duty corresponding to the Flow order entity.
export const getOrderDeliveredDuty = createSelector(
  getOrder,
  (order) => get(order, 'delivered_duty'),
);

/**
 * Returns the shipping address corresponding to the order.
 */
export const getOrderDestination = createSelector(
  getOrder,
  (order) => get(order, 'destination'),
);

/**
 * Returns the ISO 3166 3 character country code for the shipping address.
 * Typically, this is pre-populated when the order is created.
 * @param {Object} state
 * @returns {String}
 */
export const getOrderDestinationCountryCode = createSelector(
  getOrderDestination,
  (destination) => get(destination, 'country'),
);

// Returns a list of available deliveries for an order. Each delivery will have
// the selected option in its `selection` property and its `items` will be
// expanded to contain the Shopify item details.

/** @deprecated */
export const getOrderDeliveries = createSelector(
  getOrder,
  getLineItems,
  getCartItems,
  getDeliveries,
  (order, lineItems, cartItems, deliveries) => {
    // Get list of deliveries corresponding to the order.
    let result = deliveries;

    // Map delivery items to order lines because the line items in the delivery
    // models are useless for rendering (price not localized)
    result = map(result, (delivery) => assign({}, delivery, {
      items: map(delivery.items, (item) => {
        const lineItem = find(lineItems, { item_number: item.number });
        // preserve structure of original delivery.item model, as that is the
        // PropType that is used in some components.
        return { ...item, ...lineItem, quantity: item.quantity };
        /**
         * Note on the spread of item.quantity above. It's possible that and item_number will be used
         * for multiple lines of the order. There is a bug wherein the `lineItem` fetched above will
         * always return the first instance. However the quantities can differ between the
         * occurrences of the item.
         *
         * This can be removed when we can look up `order.lines`, `order.items` and `delivery.items`
         * by a unique id shared across all three.
         */
      }),
    }));

    // Attach shopify details to delivery items.
    // HACK: Only for Shopify :(
    result = map(result, (delivery) => assign({}, delivery, {
      items: map(delivery.items, (item) => assign({}, item, {
        shopify: find(cartItems, findCartItemByVariantId(item.number)),
      })),
    }));

    // Attach selected option to delivery
    result = map(result, (delivery, index) => assign({}, delivery, {
      selection: get(order, `selections[${index}]`),
    }));

    return result;
  },
);

export const getDeliveredDutySettings = createSelector(
  getOrder,
  (order) => get(order, 'experience.settings.delivered_duty'),
);

// Returns a list of identifiers representing the selected deliveries for the order.
export const getOrderSelections = createSelector(
  getOrder,
  (order) => get(order, 'selections'),
);

// Returns the request parameters send when fetching an order.
export const getOrderParams = createSelector(
  getParams,
  (params) => get(params, 'order'),
);

export const getOrderItems = createSelector(
  getOrder,
  (order) => get(order, 'items'),
);

// Returns a list of line items corresponding to the order. Each item is
// expanded with a `shopify` property containing the Shopify item data
// associated with the item.
export const getOrderLines = createSelector(
  getOrder,
  getLineItems,
  getCartItems,
  (order, lineItems, cartItems) => {
    let orderLines = map(lineItems, (orderLine) => assign({}, orderLine, {
      shopify: find(cartItems, findCartItemByVariantId(orderLine.item_number)),
    }));
    orderLines = values(orderLines);
    return orderLines;
  },
);

/**
 * Selects the order in the application state (denormalized)
 * @param {Object} state
 * @returns {Order}
 */
export const getOrderDenormalized = createSelector(
  getOrder,
  getOrderLines,
  getOrderDeliveries,
  getDeviceDetails,
  /**
   * @type {(order: io.flow.v0.models.Order, lines: io.flow.v0.models.Line[], deliveries: io.flow.v0.unions.Delivery[]) => io.flow.v0.models.Order}
   */
  (order, lines, deliveries, deviceDetails) => assign({}, order,
    { deliveries, lines, device_details: deviceDetails }),
);

export const getOrderDiscounts = createSelector(
  getOrderDenormalized,
  (order) => {
    const allDiscounts = find(order.prices, { key: 'discount' });
    if (allDiscounts) {
      return isNotEmpty(allDiscounts.components) ? allDiscounts.components : [allDiscounts];
    }
    return [];
  },
);

export const getOrderPutForm = createSelector(
  getOrderDenormalized,
  (order) => toOrderPutForm(order),
);

// Returns whether some CRUD operation on the order entity is in progress.
export const getIsOrderLoading = createSelector(
  getOrderStatus,
  (status) => get(status, 'state') === ReadyState.Loading,
);

// Returns whether some CRUD operation on the order entity resulted in an error.
export const getIsOrderRejected = createSelector(
  getOrderStatus,
  (status) => get(status, 'state') === ReadyState.Rejected,
);

// Returns whether some CRUD operation on the order entity was successful.
export const getIsOrderFulfilled = createSelector(
  getOrderStatus,
  (status) => get(status, 'state') === ReadyState.Fulfilled,
);

// Returns whether some CRUD operation on the payment entity is in progress.
export const getIsPaymentLoading = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'payment') === ReadyState.Loading,
);

// Returns whether taxes are included with the order total price.
export const getIsTaxIncluded = createSelector(
  getOrderItems,
  (items) => some(items, (item) => (
    some(get(item, 'local.prices'), (price) => (
      (/^(vat|vat_and_duty)$/).test(get(price, 'includes.key'))
    ))
  )),
);

// Returns whether duties are included with the order total price.
export const getIsDutyIncluded = createSelector(
  getOrderItems,
  (items) => some(items, (item) => (
    some(get(item, 'local.prices'), (price) => (
      (/^(duty|vat_and_duty)$/).test(get(price, 'includes.key'))
    ))
  )),
);

export const getOrderPrices = createSelector(
  getOrder,
  (order) => get(order, 'prices'),
);

export const getOrderTaxAndDutyPrices = createSelector(
  getOrderPrices,
  (prices) => filter(prices, (price) => price.key === 'duty' || price.key === 'vat'),
);

export const getTaxAndDutyPrice = createSelector(
  getCheckoutHacks,
  (hacks) => get(hacks, 'taxAndDutyPrice'),
);

// Returns whether an order has already been created.
export const getIsOrderCreated = createSelector(
  getOrder,
  (order) => !(isNil(order) || isNil(order.id) || isNil(order.number)),
);

export const getDeliveredDutyDialogError = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.deliveredDuty.error'),
);

// Returns whether the delivered duty dialog is currently visible.
export const getIsDeliveredDutyDialogVisible = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.deliveredDuty.visibility') === 'visible',
);

// Returns whether the delivered duty dialog should accept user input.
export const getIsDeliveredDutyDialogEnabled = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.deliveredDuty.enabled'),
);

/**
 * Returns whether delivered duties can be modified by the customer.
 * @see https://docs.flow.io/type/delivered-duty-setting
 * @returns {Boolean}
 */
export const getIsDeliveredDutyCustomerChoice = createSelector(
  getDeliveredDutySettings,
  (deliveredDutySetting) => get(deliveredDutySetting, 'available', []).length > 1,
);

/**
 * Returns the delivered duty display type.
 * @see https://docs.flow.io/type/delivered-duty-setting
 * @returns {DeliveredDutyDisplayType}
 */
export const getDeliveredDutyDisplayType = createSelector(
  getDeliveries,
  getDeliveredDutySettings,
  (deliveries, deliveredDutySetting) => {
    // FIXME: There are discrepancies with delivered duties in orders.
    // When an order is created for an experience configured to allow customers
    // to choose their preferred delivered duty, the initial order payload will
    // have both DDP and DDU delivery options. However, whenever a customer
    // updates the order (e.g. submits a shipping address) the delivery
    // options are filtered to a single delivered duty. Therefore,
    // the preference to display delivery options for all delivered duties
    // cannot be satisfied and we are forced default to single display type.
    //
    // This occurs because the initial order payload has a required
    // `order.delivered_duty` field that is set to either `"paid"` or `"unpaid"`
    // even when the order includes both types of delivery options. The
    // `order.delivered_duty` field cannot represent that delivered duty
    // for an order is a customer choice. Unfortunately, Checkout relies on
    // the order state to create the base `order_put_form` used to update the
    // order. In this case, the `order_put_form` will have a `delivered_duty`
    // field that maps to the `order.delivered_duty` field and the response for
    // the updated order has delivery options filtered by this value.
    const hasDdpDeliveryOptions = every(deliveries, (delivery) => (
      // @ts-ignore
      some(delivery.options, { delivered_duty: DeliveredDuties.Paid })
    ));

    const hasDduDeliveryOptions = every(deliveries, (delivery) => (
      // @ts-ignore
      some(delivery.options, { delivered_duty: DeliveredDuties.Unpaid })
    ));

    let deliveredDutyDisplayType = get(deliveredDutySetting, 'display', DeliveredDutyDisplayType.Single);

    if (!hasDdpDeliveryOptions || !hasDduDeliveryOptions) {
      deliveredDutyDisplayType = DeliveredDutyDisplayType.Single;
    }

    return deliveredDutyDisplayType;
  },
);

/**
 * Returns whether the order experience is configured to display delivery
 * options for a single delivered duty.
 * @returns {Boolean}
 */
export const getIsSingleDeliveredDutyDisplayType = createSelector(
  getDeliveredDutyDisplayType,
  (deliveredDutyDisplayType) => deliveredDutyDisplayType === DeliveredDutyDisplayType.Single,
);

// Returns whether the delivery options on the order contain mixed paid and unpaid
// delivered duty options. Note that this is a competing feature of getIsDeliveredDutyCustomerChoice
// and the use of the LandedCostSettings component. If an order has both paid and unpaid options
// in the delivery options then the experience/org is configured to display both at the same time.
// Otherwise, if the experience settings have both paid and unpaid delivered duty options available,
// the LandedCostSettings component should be displayed to allow the customer to change the
// delivered_duty setting on the order itself.
export const getHasMultipleDeliveredDuties = createSelector(
  getOrderDeliveries,
  // @ts-ignore
  (deliveries) => some(deliveries, (delivery) => uniq(map(delivery.options, 'delivered_duty')).length > 1),
);

// Returns whether the klarna dialog is currently visible.
export const getIsKlarnaDialogOpen = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.klarna.isOpen'),
);

// Returns whether the klarna dialog is currently visible.
export const getIsKlarnaDialogVisible = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.klarna.visibility') === 'visible',
);

// Returns whether the order summary should be visible.
export const getIsOrderSummaryVisible = createSelector(
  getCheckoutState,
  (state) => get(state, 'collapsibles.orderSummary.visibility') === 'visible',
);

// Returns whether the gift card is open
export const getIsGiftCardContentOpen = createSelector(
  getCheckoutState,
  (state) => get(state, 'collapsibles.giftCard.isOpen'),
);

// Returns whether the gift messaging is open
export const getIsGiftMessagingContentOpen = createSelector(
  getCheckoutState,
  (state) => get(state, 'collapsibles.giftMessaging.isOpen'),
);

export const getOrderTotal = createSelector(
  getOrder,
  (order) => get(order, 'total'),
);

/**
 * @param {object} state
 * @returns {number}
 */
export const getOrderTotalAmount = createSelector(
  getOrder,
  /**
   * @type {function(io.flow.v0.models.Order): number}
   */
  (order) => get(order, 'total.amount'),
);

/**
 * @param {object} state
 * @returns {string}
 */
export const getOrderTotalCurrency = createSelector(
  getOrder,
  /**
   * @type {function(io.flow.v0.models.Order): string}
   */
  (order) => get(order, 'total.currency'),
);

/**
 * Returns whether additional payment should be collected from customer.
 */
export const getIsPaymentRequired = createSelector(
  getOrder,
  (order) => {
    // No additional payment required when order is fully paid using gift cards.
    // Arguably, this logic belongs on the view layer.
    if (order.balance != null
      && order.payments != null
      && order.payments.length > 0
      && order.payments.every((payment) => payment.type === 'credit')
    ) {
      return order.balance.amount > 0;
    }

    // Comparing balance amount is avoided to prevent flashing "no payment required"
    // view to customers as they transition to confirmation page using
    // a different payment method.
    return order.total.amount > 0;
  },
);

// Returns the organization public key used for card tokenization.
export const getPublicKey = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.publicKey.id'),
);

// Returns the status of the last CRUD operation on the public key entity.
export const getPublicKeyReadyState = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'publicKey.state'),
);

// Returns whether the last CRUD operation on the public key entity was successful.
export const getIsPublicKeyFulfilled = createSelector(
  getPublicKeyReadyState,
  (state) => state === ReadyState.Fulfilled,
);

// Returns whether the order is already submitted.
export const getIsOrderSubmitted = createSelector(
  getOrder,
  (order) => get(order, 'submitted_at') !== undefined,
);

export const getAuthorizations = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.authorizations'),
);

export const getSuccessfulAuthorizations = createSelector(
  getAuthorizations,
  (authorizations) => filter(authorizations, (authorization) => (
    includes([
      AuthorizationStatus.PENDING,
      AuthorizationStatus.REVIEW,
      AuthorizationStatus.AUTHORIZED,
    ], get(authorization, 'result.status'))
  )),
);

export const getAuthorizationsList = createSelector(
  getSuccessfulAuthorizations,
  (authorizations) => filter(values(authorizations), property('id')),
);

// Returns the error of the last online authorization.
export const getOnlineAuthorizationCallbackError = createSelector(
  getCheckoutState,
  (state) => get(state, 'callbacks.authorization.error'),
);

export const getOrderPayments = createSelector(
  getOrder,
  (order) => get(order, 'payments'),
);

export const getItemContents = createSelector(
  getCheckoutState,
  /**
   * @type {(state: import('../types').CheckoutState) => io.flow.v0.models.CheckoutItemContent[]}
   */
  (state) => get(state, 'entities.itemContents'),
);

export const getItemContentsMap = createSelector(
  getItemContents,
  (state) => keyBy(state, 'item.number'),
);

export const getKlarnaToken = createSelector(
  getAuthorizations,
  (authorizations) => get(authorizations, 'klarnaToken'),
);

export const getIsKlarnaRendering = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.klarna.rendering'),
);

export const isVrnVisible = createSelector(
  getVrn,
  (state) => get(state, 'isVrnVisible', false),
);

export const isVrnValid = createSelector(
  getVrn,
  (state) => get(state, 'isValidVrn', false),
);

export const showInvalidVrnMessage = createSelector(
  getVrn,
  (state) => get(state, 'showInvalidVrnMessage', false),
);

export const canSubmitVrn = createSelector(
  isVrnVisible,
  isVrnValid,
  showInvalidVrnMessage,
  (isVisible, isValid, showInvalid) => !isVisible || (isVisible && isValid && !showInvalid),
);

export const unableToValidateVrn = createSelector(
  getVrn,
  (state) => get(state, 'unableToValidate', false),
);

export const getIsVrnValidating = createSelector(
  getVrnStatus,
  (status) => get(status, 'state') === ReadyState.Loading,
);

export const getLoyaltyProgram = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.loyalty.program'),
);

export const getGiftCardProgram = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.giftCards'),
);

export const getGiftCards = createSelector(
  getGiftCardProgram,
  (state) => get(state, 'cards'),
);

export const getReviewEntities = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.review'),
);

export const getReviewBillingAddress = createSelector(
  getReviewEntities,
  (state) => get(state, 'billingAddress'),
);

export const getReviewInvoiceAddress = createSelector(
  getReviewEntities,
  (state) => get(state, 'invoiceAddress'),
);

export const getReviewErrors = createSelector(
  getReviewEntities,
  (state) => get(state, 'error'),
);

export const getReviewPaymentMethodId = createSelector(
  getReviewEntities,
  (state) => get(state, 'paymentMethodId'),
);

export const getReviewCard = createSelector(
  getReviewEntities,
  (state) => get(state, 'card'),
);

export const getExpressCheckoutEntities = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities.expressCheckout'),
);

export const getPaypalPaymentInfo = createSelector(
  getExpressCheckoutEntities,
  (state) => ({
    paymentID: get(state, 'paypal.paypalPaymentId'),
    payerID: get(state, 'paypal.paypalPayerId'),
  }),
);

/**
 * Returns the billing address associated with the order. If the feature to
 * always capture a billing address is enabled, the billing address in the
 * order customer model is returned. Otherwise, the first billing address in
 * order payments is returned. May return undefined when a billing address
 * is not available in either location.
 */
export const getOrderBillingAddress = createSelector(
  getIsCollectCustomerAddressEnabled,
  getOrderCustomer,
  getOrderPayments,
  (isCollectCustomerAddressEnabled, customer, payments) => {
    // Use billing address in order.customer.address when feature to always
    // capture billing address is enabled.
    if (isCollectCustomerAddressEnabled) {
      return get(customer, 'address');
    }

    // Otherwise, select billing address from order payments.
    // It's safe to select any of the billing addresses because it's not
    // possible to checkout with multiple payment methods.
    return find(map(payments, 'address'), isNotNil);
  },
);

/** @type {(state: import('../../types').RootState) => io.flow.v0.models.BillingAddress | undefined} */
export const getOrderInvoiceAddress = createSelector(
  getOrderCustomer,
  (customer) => get(customer, 'invoice.address'),
);

export const getTaxRegistration = createSelector(
  getOrder,
  (order) => order.tax_registration,
);

export const getVrnFromRegistration = createSelector(
  getTaxRegistration,
  (taxRegistration) => get(taxRegistration, 'number'),
);

export const getCompanyFromRegistration = createSelector(
  getTaxRegistration,
  (taxRegistration) => get(taxRegistration, 'name'),
);

/**
 * Selects continue shopping URL from order attributes. If present, this URL is
 * used as the HREF for ALL links that redirect customer back to the storefront
 * in Checkout (does not apply to the Cart).
 */
export const getContinueShoppingUrl = createSelector(
  getOrganizationUrl,
  getOrderAttributes,
  (organizationUrl, attributes) => (
    property(OrderAttributes.CONTINUE_SHOPPING_URL)(attributes) || organizationUrl
  ),
);

/**
 * Selects checkout configuration stored in application state.
 * @param {Object} state
 * @returns {io.flow.internal.v0.models.CheckoutConfiguration}
 */
export const getCheckoutConfiguration = (state) => get(state, 'checkout.configuration');

/**
 * Selects the customer email prompt behavior.
 * @param {Object} state
 * @returns {CheckoutPromptBehavior}
 */
export const getCustomerEmailPromptBehavior = createSelector(
  getCheckoutConfiguration,
  /**
   * @type {(state: io.flow.internal.v0.models.CheckoutConfiguration) => io.flow.internal.v0.enums.CheckoutPromptBehavior}
   */
  (configuration) => get(configuration, 'behavior.customer_info.email.prompt', 'always'),
);

/**
 * Selects the shipping address prompt behavior.
 * @param {Object} state
 * @returns {CheckoutPromptBehavior}
 */
export const getShippingAddressPromptBehavior = createSelector(
  getCheckoutConfiguration,
  /**
   * @type {(state: io.flow.internal.v0.models.CheckoutConfiguration) => io.flow.internal.v0.enums.CheckoutPromptBehavior}
   */
  (configuration) => get(configuration, 'behavior.shipping_address.prompt', 'always'),
);

/**
 * Selects the shipping method prompt behavior.
 */
export const getShippingMethodPromptBehavior = createSelector(
  getCheckoutConfiguration,
  /**
   * @type {function(io.flow.internal.v0.models.CheckoutConfiguration): io.flow.internal.v0.enums.CheckoutShippingMethodPromptBehavior}
   */
  (configuration) => get(configuration, 'behavior.shipping_method.prompt', 'always'),
);

/**
 * Taking into account the landed cost settings, particularly the delivery duty
 * display type, returns whether multiple delivery options would be presented
 * on the shipping method step.
 * @returns {Boolean}
 */
export const hasMultipleShippingMethods = createSelector(
  getDeliveries,
  getDeliveredDutyDisplayType,
  getOrderDeliveredDuty,
  (deliveries, displayType, deliveredDuty) => {
    // If displaying all available options...
    if (displayType === DeliveredDutyDisplayType.All) {
      return some(deliveries, (delivery) => get(delivery, 'options', []).length > 1);
    }

    // If displaying only a single option (either DDU or DDP), while the other
    // can be displayed by changing duties and tax preferences at checkout...
    return some(deliveries, (delivery) => (
      get(delivery, 'options', [])
        .filter(matches({ delivered_duty: deliveredDuty }))
        .length > 1
    ));
  },
);

export const isRestrictedItemsModalOpen = createSelector(
  getCheckoutState,
  /** @type {(state: object) => boolean} */
  (state) => get(state, 'dialogs.restrictedItems.isOpen'),
);

export const getRestrictedItemNumbers = createSelector(
  getCheckoutState,
  /** @type {(state: object) => string[]} */
  (state) => get(state, 'dialogs.restrictedItems.numbers', []),
);

/**
 * @param {string} number
 */
export const getCheckoutItemContentByNumber = (number) => createSelector(
  getItemContents,
  /** @type {(contents: io.flow.v0.models.CheckoutItemContent[]) => (io.flow.v0.models.CheckoutItemContent | undefined)} */
  (contents) => contents.find((content) => content.item.number === number),
);

export const getLines = createSelector(
  getCheckoutState,
  /** @type {(state: object) => { [key: string]: io.flow.v0.models.Line }} */
  (state) => get(state, 'entities.lineByItemNumber'),
);

/**
 * @param {string} number
 * @returns {(state: import('../../types').RootState) => (io.flow.v0.models.Line | undefined)}
 */
export const getLineByNumber = (number) => createSelector(
  getLines,
  /** @type {(lines: { [key: string]: io.flow.v0.models.Line }) => (io.flow.v0.models.Line | undefined)} */
  (lines) => get(lines, number),
);

/** @type {(state: import('../../types').RootState) => io.flow.v0.models.Customer | null} */
export const getReturningCustomer = createSelector(
  getCheckoutEntities,
  (entities) => entities.customer,
);

const getNormalizedAddressBook = createSelector(
  getCheckoutState,
  (state) => state.entities.addressBook,
);

const getNormalizedAddressBookContacts = createSelector(
  getCheckoutState,
  (state) => state.entities.addressBookContacts,
);

/** @type {function(import('../../types').RootState): io.flow.v0.models.CustomerAddressBook | undefined} */
export const getCustomerAddressBook = createSelector(
  getNormalizedAddressBook,
  getNormalizedAddressBookContacts,
  (addressBook, addressBookContacts) => {
    const ids = get(addressBook, 'contacts');
    return {
      ...addressBook,
      contacts: values(pick(addressBookContacts, ids)).filter(isNotNil),
    };
  },
);

/** @type {function(import('../../types').RootState): io.flow.v0.models.CustomerAddressBookContact[] | undefined} */
export const getCustomerAddressBookContacts = createSelector(
  getCustomerAddressBook,
  (addressBook) => get(addressBook, 'contacts'),
);

export const getPreferredShippingAddressBookContact = createSelector(
  getCustomerAddressBookContacts,
  (addressBookContacts) => {
    if (addressBookContacts == null) {
      return undefined;
    }

    return addressBookContacts.find(
      (addressBookContact) => addressBookContact.address_preferences.some(
        /** @type {(preference: io.flow.v0.models.CustomerAddressPreference) => boolean} */
        (preference) => preference.type === 'shipping',
      ),
    );
  },
);

export const getCustomerPaymentSources = createSelector(
  getCheckoutEntities,
  (entities) => entities.paymentSources,
);

/**
 * Returns address configuration for the specified country.
 * @param {string} countryCode
 */
export const getAddressConfigurationByCountryCode = (countryCode) => (
  /** @type {(state: import('../../types').RootState) => io.flow.v0.models.AddressConfiguration | undefined} */
  (state) => get(state, ['checkout', 'entities', 'addressConfiguration', countryCode])
);

/**
 * Returns the address configuration model that would be use by default prior
 * to the introduction of the Address Configuration API. Note that, address
 * configuration formats are not added due to the model not supporting all
 * possible address fields (e.g. VRN).
 * @param {string} countryCode
 */
export const getDefaultAddressConfigurationByCountryCode = (countryCode) => (
  /** @type import('../../types').RootState */ state,
) => {
  const addressFieldValidation = getDefaultAddressFieldValidation();
  const administrativeDivisions = getAdministrativeDivisionsByCountryCode(countryCode)(state);

  const addressConfigurationProvinces = administrativeDivisions.map((administrativeDivision) => {
    /** @type {io.flow.v0.models.AddressConfigurationProvince} */
    const addressConfigurationProvince = {
      name: administrativeDivision.name,
      translations: administrativeDivision.translations,
      value: administrativeDivision.name,
    };

    return addressConfigurationProvince;
  });

  /** @type {io.flow.v0.models.AddressConfiguration} */
  const addressConfiguration = {
    country: countryCode,
    field_validation: addressFieldValidation,
    provinces: addressConfigurationProvinces,
  };
  return addressConfiguration;
};

/**
 * @param {string} countryCode
 * @returns {(state: import('../../types').RootState) => io.flow.v0.models.AddressConfiguration}
 */
export const getAddressConfigurationOrUseDefaultByCountryCode = (countryCode) => (state) => {
  const addressConfiguration = getAddressConfigurationByCountryCode(countryCode)(state);

  if (addressConfiguration == null) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        `Could not find address configuration for country with ISO code: ${countryCode}. `
        + 'A default address configuration will be used instead.',
      );
    }

    const defaultConfiguration = getDefaultAddressConfigurationByCountryCode(countryCode)(state);
    return defaultConfiguration;
  }

  return addressConfiguration;
};

export const getCheckoutError = createSelector(
  getErrors,
  (errors) => get(errors, 'checkout'),
);

export const getOrderBuilderQueryParams = createSelector(
  getLanguage,
  /** @type {(lang: string | undefined) => import('../types').OrderBuilderQueryParams} */
  (lang) => pickBy({
    expand: ['experience'],
    language: lang,
  }),
);

export const getIsAdyen3dsDialogOpen = createSelector(
  getCheckoutState,
  (state) => get(state, 'dialogs.adyen3ds.isOpen', false),
);

/** @type {function(import('../../types').RootState): import('../types').BillingAddressFormValues} */
export const getBillingAddressFormValues = (state) => get(state, 'form.paymentInformation.values.billingAddress');

export const getBillingAddressFromPaymentFormValues = createSelector(
  getBillingAddressFormValues,
  getOrderDestination,
  getCustomerAddressBookContacts,
  (formData, destination, contacts) => {
    if (formData.addressToUse === 'sameAsShippingAddress') {
      return billingAddressFromOrderAddress(destination);
    }

    if (contacts != null && formData.addressToUse !== 'newAddress') {
      return find(contacts, { id: formData.addressToUse });
    }

    return billingAddressFromFormValues(formData);
  },
);

export const getBillingAddressFromReviewFormValues = createSelector(
  getReviewBillingAddress,
  getOrderDestination,
  getCustomerAddressBookContacts,
  (formData, destination, contacts) => {
    if (formData.addressToUse === 'sameAsShippingAddress') {
      return billingAddressFromOrderAddress(destination);
    }

    if (contacts != null && formData.addressToUse !== 'newAddress') {
      return find(contacts, { id: formData.addressToUse });
    }

    return billingAddressFromFormValues(formData);
  },
);

export const getBillingAddressFromFormValues = createSelector(
  isReviewStepEnabled,
  getBillingAddressFormValues,
  getReviewBillingAddress,
  getOrderDestination,
  getCustomerAddressBookContacts,
  (
    reviewStepEnabled,
    paymentBillingAddressFormValues,
    reviewBillingAddressFormValues,
    orderAddress,
    addressBookContacts,
  ) => {
    const formValues = reviewStepEnabled
      ? reviewBillingAddressFormValues
      : paymentBillingAddressFormValues;

    if (formValues != null) {
      if (formValues.addressToUse === 'sameAsShippingAddress') {
        return billingAddressFromOrderAddress(orderAddress);
      }

      if (formValues.addressToUse === 'newAddress') {
        return billingAddressFromFormValues(formValues);
      }

      const addressBookContact = find(addressBookContacts, {
        id: formValues.addressToUse,
      });

      if (addressBookContact != null) {
        return billingAddressFromAddressBook(addressBookContact);
      }
    }

    return undefined;
  },
);

export const getEditAddressBookContactState = createSelector(
  getCheckoutState,
  (state) => state.dialogs.editAddressBookContact,
);

export const isDomesticShippingUnavailableDialogOpen = createSelector(
  getCheckoutState,
  (state) => state.dialogs.domesticShippingUnavailable.isOpen,
);

export const getDomesticShippingUnavailableDialogError = createSelector(
  getCheckoutState,
  (state) => state.dialogs.domesticShippingUnavailable.orderError,
);

/**
 * Returns rendering behavior for card payment methods.
 *
 * Card payment methods can be combined into a single field or separated into
 * debit and credit card fields.
 */
export const getCardPaymentDisplay = createSelector(
  getOrderDestinationCountryCode,
  (shippingCountryCode) => {
    if (shippingCountryCode != null && shippingCountryCode.toUpperCase() === 'SWE') {
      return 'debit_card_first';
    }

    return 'combined';
  },
);

/**
 * Creates payment form to be used for Crypto payments.
 */
export const getCryptoPaymentForm = createSelector(
  getOrder,
  /**
   * @returns {io.flow.v0.unions.PaymentForm}
   */
  (order) => ({
    amount: order.balance != null ? order.balance.amount : order.total.amount,
    currency: order.balance != null ? order.balance.currency : order.total.currency,
    discriminator: 'merchant_of_record_payment_form',
    method: PaymentTypes.CryptoPay,
    order_number: order.number,
  }),
);

export const getCryptoParameters = createSelector(
  getPaymentMethodParams,
  /** @returns {io.flow.internal.v0.models.AuthorizationParameters | undefined} */
  (parameters) => find(parameters, matches({ method: PaymentTypes.CryptoPay })),
);

export const getCryptoPublishableKey = createSelector(
  getCryptoParameters,
  /** @returns {string | undefined} */
  (parameters) => get(parameters, 'parameters.publishable_key'),
);

export const getIsPaypalExpressReady = createSelector(
  getExpressCheckoutEntities,
  (state) => (
    state != null && state.paypal.paypalPayerId != null && state.paypal.paypalPaymentId != null
  ),
);

export const getIsPayPalEnabled = createSelector(
  getPaymentMethodRules,
  (rules) => rules.some((_) => _.payment_method.id === 'paypal'),
);

export const getIsPayPalExpressCheckoutEnabled = createSelector(
  isFeatureEnabled(Features.PAYPAL_EXPRESS),
  getIsPayPalEnabled,
  (featureEnabled, payPalEnabled) => featureEnabled && payPalEnabled,
);

export const getInitialPaymentMethodId = createSelector(
  getCardPaymentDisplay,
  getReviewPaymentMethodId,
  getIsReturningCustomersEnabled,
  getCustomerPaymentSources,
  getPaymentMethodRules,
  getIsPaypalExpressReady,
  getIsPaymentMethodPositioningEnabled,
  (
    cardPaymentDisplay,
    reviewPaymentMethodId,
    isReturningCustomersEnabled,
    paymentSources,
    paymentMethodRules,
    isPaypalExpressReady,
    isPaymentMethodPositioningEnabled,
  ) => {
    let paymentMethodId;

    // Use first payment method from payment method rules as default selection,
    // unless another condition trumps over this decision.

    // account for express checkout
    let initialPaymentMethod = get(paymentMethodRules, '[0].payment_method');

    if (isPaypalExpressReady) {
      const paymentMethodRule = paymentMethodRules.find((_) => _.payment_method.id === 'paypal');
      initialPaymentMethod = paymentMethodRule.payment_method;
    }

    if (reviewPaymentMethodId != null) {
      paymentMethodId = reviewPaymentMethodId;
    } else if (isReturningCustomersEnabled && paymentSources != null && paymentSources.length > 0) {
      paymentMethodId = paymentSources[0].id;
    } else if (isPaymentMethodPositioningEnabled && initialPaymentMethod != null) {
      if (initialPaymentMethod.type === 'card') {
        if (cardPaymentDisplay === 'debit_card_first') {
          paymentMethodId = PaymentTypes.DebitCard;
        } else {
          paymentMethodId = initialPaymentMethod.type;
        }
      } else {
        paymentMethodId = initialPaymentMethod.id;
      }
    } else {
      paymentMethodId = PaymentTypes.Card;
    }

    return paymentMethodId;
  },
);

export const getInitialPaymentMethodIdForGtagTracker = createSelector(
  getCustomerPaymentSources,
  getInitialPaymentMethodId,
  (
    paymentSources,
    paymentMethodId,
  ) => {
    if (paymentSources != null && paymentSources.length > 0) {
      const paymentSource = paymentSources.find((_) => _.id === paymentMethodId);
      if (paymentSource != null) {
        return paymentSource.discriminator;
      }
    }
    return paymentMethodId;
  },
);

/**
 * Returns whether a liquid template would have been rendered from
 * the server side.
 */
export const isLiquid = createSelector(
  getIntegration,
  getFeatureValueByKey(Features.DISABLE_SHOPIFY_LIQUID),
  (integration, featureValue) => integration === 'shopify' && (
    featureValue == null || featureValue.value === false
  ),
);

export const getIsDeliveredDutyPaidMessageEnabled = createSelector(
  getOrderDeliveredDuty,
  getFeatureValueByKey(Features.DUTIES_MESSAGING),
  (orderDeliverdDuty, featureValue) => featureValue?.value && orderDeliverdDuty === 'paid',
);
