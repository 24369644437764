function toCartLineItemForm(
  item: io.flow.v0.models.LocalizedLineItem,
): io.flow.v0.models.LineItemForm {
  return {
    number: item.number,
    quantity: item.quantity,
    attributes: item.attributes,
    center: item.center,
  };
}

/**
 * Converts an existing order into a simplified OrderPutForm that represents the content of a cart.
 * This is intended to convert a Flow Cart, which is represented on the backend as an order in Flow,
 * into an OrderPutForm. This is helpful for various APIs, such as bundle's Checkouts resource which
 * accept an order form in order to create a `checkout`.
 */
export default function toCartOrderPutForm(
  order: io.flow.v0.models.Order,
): io.flow.v0.models.OrderPutForm {
  return {
    items: order.items.map(toCartLineItemForm),
  };
}
