import React from 'react';

import { isGenericError, isPaymentError } from '../../utilities/predicates';
import { Response } from '../../../common/services/types';
import GenericError from '../generic-error';
import UnknownError from '../unknown-error';

interface Props {
  response: Response;
}

const ApiError: React.FunctionComponent<Props> = ({
  response,
}) => {
  if (isPaymentError(response.result) || isGenericError(response.result)) {
    return <GenericError error={response.result} />;
  }

  return <UnknownError error={response.result} />;
};

export default ApiError;
