import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./integration-header.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('integration-header');

const IntegrationHeader = ({ version }) => (
  <div className={bem.block()}>
    Checkout - Integration <span className={bem.element('version')}>({ version })</span>
  </div>
);

IntegrationHeader.displayName = 'IntegrationHeader';

IntegrationHeader.propTypes = {
  version: PropTypes.string.isRequired,
};

export default IntegrationHeader;
