import assign from 'lodash/assign';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

export function isAsyncAction(action) {
  return isPlainObject(action) && isArray(action.types);
}

export function isValidActionDescriptor(descriptor) {
  return isPlainObject(descriptor) && isString(descriptor.type);
}

export function isValidActionType(candidate) {
  return isString(candidate) || isValidActionDescriptor(candidate);
}

/**
 * Validates action specified meets the async action specifications.
 * @param {Any} action - Action to validate
 * @throws
 */
export function validateAsyncAction(action) {
  if (!isPlainObject(action)) {
    throw new Error('asynchronous action must be an object');
  }

  if (!isArray(action.types) && action.types.length !== 3) {
    throw new Error('types must be an array of length 3');
  }

  if (!every(action.types, isValidActionType)) {
    throw new Error('types must be an array containing strings or plain object descriptors');
  }

  if (!isFunction(action.callAPI)) {
    throw new Error('callAPI must be a function');
  }
}

/**
 * Transform string types into full-fledged type descriptors.
 * @param {Array} types - The action types from a valid async action
 * @returns {Array}
 */
export function normalizeTypeDescriptors(types) {
  let [requestType, successType, failureType] = types;

  if (isString(requestType)) {
    requestType = { type: requestType };
  }

  if (isString(successType)) {
    successType = { type: successType };
  }

  if (isString(failureType)) {
    failureType = { type: failureType };
  }

  successType = assign({}, {
    payload: (state, response) => response.result,
  }, successType);

  failureType = assign({}, {
    payload: (state, response) => response.result,
  }, failureType);

  return [requestType, successType, failureType];
}
