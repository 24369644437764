import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import get from 'lodash/get';
import formatErrorMessage from '../errors/format-error-message';

export default function logInternalServerError(error, orderNumber) {
  let message;

  switch (error.name) {
    case 'HttpStatusError': {
      const result = get(error, 'response.result');
      const status = get(error, 'response.status', '');
      message = `Bummer: messages[${formatErrorMessage(result)}] status[${status}]`;
      break;
    }
    default:
      message = `Bummer: message[${error.message}]`;
      break;
  }

  getRollbar((rollbar, extra = {}) => {
    const finalExtra = {
      ...extra,
      message: error.message,
      stack: error.stack,
      orderNumber,
    };
    rollbar.error(message, finalExtra);
  });
}
