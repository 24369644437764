import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

const bem = new BemHelper('section');

const SectionContent = ({ children, className, fitted }) => (
  <div className={bem.element('content', { fitted }, className)}>
    {children}
  </div>
);

SectionContent.displayName = 'SectionContent';

SectionContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fitted: PropTypes.bool,
};

SectionContent.defaultProps = {
  children: undefined,
  className: '',
  fitted: false,
};

export default SectionContent;
