import { injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import {
  formValueSelector,
  SubmissionError,
  change,
  clearFields,
  reduxForm,
  setSubmitFailed,
  startSubmit,
  stopSubmit,
  submit,
  touch,
  getFormSyncErrors,
  getFormValues,
  isValid,
  clearSubmitErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import { CancellationTokenSource } from '@flowio/cancellation-token';

import { applePay } from '@flowio/payment';
import {
  getBillingAddressCountryFieldValue,
  getInitialValues,
  getPaymentMethodIdFieldValue,
  getInvoiceAddressCountryFieldValue,
  getBillingAddressConfiguration,
  getInvoiceAddressConfiguration,
} from './selectors';
import {
  getPayPalDisclaimer,
  getPrivacyPolicy,
  getTermAndConditions,
  getMarketingOptIns,
  getLogoUrl,
} from '../../store/content/selectors';
import { getCountries } from '../../store/reference/selectors';
import { getLocale } from '../../store/intl/selectors';
import { visitReviewStep } from '../../store/navigation/actions';
import {
  getGooglePaymentDataRequest,
  getIsCollectCustomerAddressEnabled,
  getOnlineAuthorizationCallbackError,
  getOrderExperience,
  getOrderNumber,
  getGiftCardError,
  getCardPaymentDisplay,
  getPaymentMethodRules,
  getPaymentMethodTypes,
  is3DSPendingPageEnabled,
  getOrderError,
  allowVRN,
  allowInvoiceAddress,
  getInvoiceAddress,
  getOrderDestination,
  getIsVrnValidating,
  isReviewStepEnabled,
  isFinalizeEnabled,
  getReviewCard,
  getCustomerPaymentSources,
  getCustomerAddressBook,
  getCustomerAddressBookContacts,
  getReturningCustomer,
  isGiftCardsEnabled,
  isForceEnableShopifyDiscountsEnabled,
  isDiscountsEnabled,
  getIsPaypalExpressReady,
  getPaypalPaymentInfo,
  getApplePayAvailibility,
  getApplePayParameters,
  getOrderBalanceAmount,
  getKlarnaRedirectEnabled,
} from '../../store/checkout/selectors';
import {
  checkVrnAndUpdateOrder,
  deleteCardPaymentSource,
  updateInvoiceAddress,
  proceedToReview,
  changeCard,
  finalizeOrderV2,
  saveNewAddress,
  fetchAddressConfiguration,
  triggerPaymentSubmittedEvent,
} from '../../store/checkout/actions';
import submitPaymentInformation from '../../store/checkout/actions/submit-payment-information';
import { getOrganization, getIsShopifyIntegration } from '../../store/flow/selectors';
import { hideLoadingIndicator, showLoadingIndicator } from '../../store/application/actions';
import { genericErrorFromString } from '../../utilities/parsers';
import { handleValidation } from './validation';
import injectFlow from '../../components/injectFlow';
import FormName from '../../constants/form-name';
import PaymentForm from '../../components/payment-form';
import isEuropeanUnion from '../../utilities/is-european-union';
import createCardToken from '../../utilities/create-card-token';
import { isCard, isCryptoPay } from './utilities';
import finalizeOrderV1 from '../../store/checkout/actions/finalize-order-v1';
import { toOrderAddress, toCustomerFormValuesFromAddressBook } from '../customer-information-form/converters';
import getPreferredUserAddress from '../../utilities/get-preferred-user-address';
import { getContactById } from '../../utilities/address-book-utilities';
import PaymentTypes from '../../constants/payment-types';
import { getSessionId } from '../../utilities/session';
import { trackHeapEvent } from '../../utilities/heap';

/** @type {CancellationTokenSource | undefined} */
let cancellationTokenSource;

const formName = FormName.PAYMENT_INFORMATION;

const formSelector = formValueSelector(formName);

function toInvoiceAddress(values) {
  return omitBy({
    city: get(values, 'locality'),
    company: get(values, 'organizationName'),
    country: get(values, 'country'),
    name: {
      first: get(values, 'firstName'),
      last: get(values, 'lastName'),
    },
    postal: get(values, 'postalCode'),
    province: get(values, 'province'),
    streets: compact([get(values, 'addressLine1'), get(values, 'addressLine2')]),
  }, isNil);
}

function toInvoiceAddressFromDestination(destination) {
  return omitBy({
    city: get(destination, 'city'),
    company: get(destination, 'contact.company'),
    country: get(destination, 'country'),
    name: {
      first: get(destination, 'contact.name.first'),
      last: get(destination, 'contact.name.last'),
    },
    postal: get(destination, 'postal'),
    province: get(destination, 'province'),
    streets: get(destination, 'streets'),
  }, isNil);
}

function handleBillingAddressCountryChange(organizationId, prevCountry, nextCountry, dispatch) {
  if (prevCountry !== nextCountry) {
    dispatch(showLoadingIndicator());
    dispatch(clearFields(formName, false, false, 'billingAddress.administrativeArea'));
    dispatch(fetchAddressConfiguration(organizationId, nextCountry)).then(() => {
      dispatch(hideLoadingIndicator());
    }).catch(() => {
      dispatch(hideLoadingIndicator());
    });
  }
}

function handleInvoiceAddressChange(
  prevValues,
  nextValues,
  destination,
  vrn,
  companyName,
  organizationId,
  orderNumber,
  dispatch,
) {
  const prevCountry = get(prevValues, 'country');
  const nextCountry = get(nextValues, 'country');
  const prevAddressToUse = get(prevValues, 'addressToUse');
  const nextAddressToUse = get(nextValues, 'addressToUse');

  if (cancellationTokenSource) cancellationTokenSource.cancel();
  cancellationTokenSource = new CancellationTokenSource();

  // Why do we need to do this?????
  // A: Its because we want the tax to update before the user continues so if the user
  // Changes the country then goes back to same as shipping we want the tax to reflect
  // the tax in the country it is being shipped to
  if (prevAddressToUse != null && prevAddressToUse !== 'sameAsShippingAddress' && nextAddressToUse === 'sameAsShippingAddress') {
    dispatch(updateInvoiceAddress(
      organizationId,
      orderNumber,
      toInvoiceAddressFromDestination(destination),
    ));
  } else if (prevCountry !== nextCountry) {
    // If we change the country to a non EU country we want to clear out the associated VRN
    if (!isEuropeanUnion(nextCountry) && isEuropeanUnion(prevCountry)) {
      dispatch(checkVrnAndUpdateOrder({
        organizationId,
        orderNumber,
        companyName: '',
        vrn: '',
        cancellationToken: cancellationTokenSource.token,
      }))
        .then(() => dispatch(updateInvoiceAddress(
          organizationId,
          orderNumber,
          toInvoiceAddress(nextValues),
        )));
    // Chaanging to a EU country, will try to validate the in-state VRN
    } else if (!isEuropeanUnion(prevCountry) && isEuropeanUnion(nextCountry)) {
      Promise.resolve()
        .then(() => {
          if (vrn != null && companyName != null) {
            return dispatch(checkVrnAndUpdateOrder({
              organizationId,
              orderNumber,
              vrn,
              companyName,
              cancellationToken: cancellationTokenSource.token,
            }));
          }

          return Promise.resolve();
        })
        .then(() => dispatch(updateInvoiceAddress(
          organizationId,
          orderNumber,
          toInvoiceAddress(nextValues),
        )));
    } else {
      dispatch(updateInvoiceAddress(organizationId, orderNumber, toInvoiceAddress(nextValues)));
    }

    dispatch(clearFields(formName, false, false, 'administrativeArea'));

    if (prevCountry !== nextCountry) {
      dispatch(showLoadingIndicator());
      dispatch(fetchAddressConfiguration(organizationId, nextCountry)).then(() => {
        dispatch(hideLoadingIndicator());
      }).catch(() => {
        dispatch(hideLoadingIndicator());
      });
    }
  }
}

function handleChange(nextValues, dispatch, props, prevValues) {
  const prevBillingCountry = get(prevValues, 'billingAddress.country');
  const nextBillingCountry = get(nextValues, 'billingAddress.country');
  const nextInvoiceAddress = get(nextValues, 'invoiceAddress');

  const {
    organizationId,
    orderNumber,
    destination,
    invoiceAddressBooks,
    allowInvoiceAddress: isInvoiceAddressAllowed,
  } = props;

  handleBillingAddressCountryChange(
    organizationId,
    prevBillingCountry,
    nextBillingCountry,
    dispatch,
  );

  if (isInvoiceAddressAllowed && nextInvoiceAddress) {
    const { vrn, organizationName } = nextInvoiceAddress;
    let invoiceAddressToUse = nextInvoiceAddress;
    // If we are using a saved address, I need to make sure
    // That VRN is cleared if not applicable
    // IE. Going from a French invoice address to a Canadian one
    if (nextInvoiceAddress.addressToUse.includes('savedInvoiceAddress')) {
      const addressId = nextInvoiceAddress.addressToUse.split('_')[1];
      const selectedInvoiceAddress = find(getPreferredUserAddress(invoiceAddressBooks, 'invoice'), { id: addressId });
      invoiceAddressToUse = toCustomerFormValuesFromAddressBook({
        newAddress: selectedInvoiceAddress,
      });
    }

    handleInvoiceAddressChange(
      get(prevValues, 'invoiceAddress'),
      invoiceAddressToUse,
      destination,
      vrn,
      organizationName,
      organizationId,
      orderNumber,
      dispatch,
    );
  }
}

function createSubmissionError(error, dispatch) {
  const submissionError = new SubmissionError({ _error: error });
  dispatch(startSubmit(formName));
  dispatch(stopSubmit(formName, submissionError.errors));
  dispatch(setSubmitFailed(formName));
}

function getSubmitPaymentAction(
  values,
  flow,
  paymentSources,
  useCheckoutsSubmission,
  isKlarnaRedirectEnabled,
) {
  const {
    paymentMethodId,
  } = values;

  let valuesToUse = values;

  const selectedPaymentSource = find(paymentSources, { id: paymentMethodId });

  if (selectedPaymentSource != null) {
    valuesToUse = {
      ...valuesToUse,
      card: selectedPaymentSource.summary.card,
    };
  }

  return submitPaymentInformation({
    values: valuesToUse,
    flow,
    isCheckoutResourceEnabled: useCheckoutsSubmission,
    isKlarnaRedirectEnabled,
  });
}

function getReviewAction(
  values,
  storedReviewCard,
  paymentSources,
  flow,
  destination,
  addressBook,
  dispatch,
) {
  const {
    paymentMethodId,
  } = values;

  const selectedPaymentSource = find(paymentSources, { id: paymentMethodId });

  if (selectedPaymentSource != null) {
    return dispatch(proceedToReview({
      ...values,
      card: selectedPaymentSource.summary.card,
    }));
  }

  if (!isCard(values)) {
    return dispatch(proceedToReview(values));
  }

  if (!isEmpty(storedReviewCard)) {
    return dispatch(proceedToReview({
      ...values,
      card: storedReviewCard,
    }));
  }

  const contacts = addressBook != null ? addressBook.contacts : [];

  return createCardToken(values, flow, destination, contacts).then((result) => {
    dispatch(proceedToReview({
      ...values,
      card: result,
    }));
  });
}

function handleSubmit(values, dispatch, props) {
  const {
    flow,
    reviewEnabled,
    destination,
    storedReviewCard,
    addressBook,
    customerPaymentSources,
    isFinalizeEnabled: finalizeEnabled,
    useCheckoutsSubmission,
    isKlarnaRedirectEnabled,
  } = props;
  return Promise.resolve()
    .then(() => dispatch(showLoadingIndicator()))
    .then(() => {
      if (get(values, 'billingAddress.saveCustomerInfo')) {
        const newAddress = toOrderAddress(values.billingAddress);
        return dispatch(saveNewAddress(newAddress, 'billing'));
      }

      return Promise.resolve();
    })
    .then(() => {
      if (get(values, 'invoiceAddress.saveCustomerInfo')) {
        const newAddress = toOrderAddress(values.invoiceAddress);
        return dispatch(saveNewAddress(newAddress, 'invoice'));
      }

      return Promise.resolve();
    })
    .then(() => {
      if (isCryptoPay(values)) {
        return Promise.resolve();
      }

      return finalizeEnabled
        ? dispatch(finalizeOrderV2(values))
        : dispatch(finalizeOrderV1(values));
    })
    .then(() => dispatch(triggerPaymentSubmittedEvent()))
    .then(() => (reviewEnabled
      ? getReviewAction(
        values,
        storedReviewCard,
        customerPaymentSources,
        flow,
        destination,
        addressBook,
        dispatch,
      )
      : dispatch(getSubmitPaymentAction(
        values,
        flow,
        customerPaymentSources,
        useCheckoutsSubmission,
        isKlarnaRedirectEnabled,
      ))))
    .then(() => {
      if (reviewEnabled) {
        dispatch(visitReviewStep());
        dispatch(hideLoadingIndicator());
      }
    })
    .catch((error) => {
      dispatch(hideLoadingIndicator());
      switch (error.name) {
        case 'HttpStatusError':
          throw new SubmissionError({
            _error: error.response.result,
          });
        case 'OrderBuilderError':
          throw new SubmissionError({
            _error: error.response.result.errors[0],
          });
        default:
          throw error;
      }
    });
}

function handleGooglePaySuccess(paymentData) {
  return function handleGooglePaySuccessEffects(dispatch) {
    dispatch(change(formName, 'googlePaymentData', paymentData));
    return dispatch(submit(formName));
  };
}

function handleGooglePayError(errorMessage) {
  return function handleGooglePayErrorEffects(dispatch) {
    createSubmissionError(
      genericErrorFromString(errorMessage),
      dispatch,
    );
  };
}

function handlePayPalSuccess(payment) {
  return function handlePayPalSuccessSideEffects(dispatch) {
    dispatch(change(formName, 'paypalPaymentId', payment.paymentID));
    dispatch(change(formName, 'paypalPayerId', payment.payerID));
    /**
     * !! HACK !!
     * For some reason the props in redux-form are updated all at the same time.
     * When submitting, it's using the previous props instead of the new ones coming in.
     * This makes the changes above unknown to redux-form before submitting.
     */
    setTimeout(() => dispatch(submit(formName)), 0);
  };
}

function handlePayPalError(error) {
  return function handlePayPalErrorSideEffects(dispatch) {
    createSubmissionError(error, dispatch);
  };
}

function handleKlarnaError(error) {
  return function handleKlarnaErrorSideEffects(dispatch) {
    createSubmissionError(error, dispatch);
  };
}

function handleKlarnaSuccess() {
  return function handleKlarnaSuccessSideEffects(dispatch) {
    return dispatch(submit(formName));
  };
}

function handleCardChange(d, paymentMethod) {
  return function handleCardChangeSideEffects(dispatch) {
    return dispatch(changeCard(paymentMethod));
  };
}

const mapStateToProps = (state) => ({
  addressBook: getCustomerAddressBook(state),
  allowVRN: allowVRN(state),
  allowInvoiceAddress: allowInvoiceAddress(state),
  applepayAvailability: getApplePayAvailibility(state),
  invoiceAddressBooks: getPreferredUserAddress(getCustomerAddressBookContacts(state), 'invoice'),
  reviewEnabled: isReviewStepEnabled(state),
  isGlobalGiftCardsEnabled: isGiftCardsEnabled(state),
  customerPaymentSources: getCustomerPaymentSources(state),
  billingAddressConfiguration: getBillingAddressConfiguration(state),
  invoiceAddressConfiguration: getInvoiceAddressConfiguration(state),
  authorizationCallbackError: getOnlineAuthorizationCallbackError(state),
  countries: getCountries(state),
  billingCountryCode: getBillingAddressCountryFieldValue(state),
  invoiceCountryCode: getInvoiceAddressCountryFieldValue(state),
  experience: getOrderExperience(state),
  error: getOrderError(state),
  giftCardError: getGiftCardError(state),
  googlePaymentDataRequest: getGooglePaymentDataRequest(state),
  initialValues: getInitialValues(state),
  locale: getLocale(state),
  marketingOptIns: getMarketingOptIns(state),
  orderBalance: getOrderBalanceAmount(state),
  orderNumber: getOrderNumber(state),
  organizationId: getOrganization(state),
  paymentMethodId: getPaymentMethodIdFieldValue(state),
  paymentMethodRules: getPaymentMethodRules(state),
  paymentMethodTypes: getPaymentMethodTypes(state),
  paypalDisclaimer: getPayPalDisclaimer(state),
  paypalExpressReady: getIsPaypalExpressReady(state),
  paypalPaymentInfo: getPaypalPaymentInfo(state),
  privacyPolicy: getPrivacyPolicy(state),
  shouldCollectBillingAddress: getIsCollectCustomerAddressEnabled(state),
  terms: getTermAndConditions(state),
  invoiceAddress: getInvoiceAddress(state),
  is3DSPendingPageEnabled: is3DSPendingPageEnabled(state),
  // Need this to reset the invoice address back to the shipping address
  // This is required so we can update the invoice address and get the correct
  // tax and duties, fun I know
  destination: getOrderDestination(state),
  isVrnValidating: getIsVrnValidating(state),
  storedReviewCard: getReviewCard(state),
  isFinalizeEnabled: isFinalizeEnabled(state),
  returningCustomer: getReturningCustomer(state),
  isShopifyIntegration: getIsShopifyIntegration(state),
  isDiscountsEnabled: isDiscountsEnabled(state),
  forceEnableShopifyDiscounts: isForceEnableShopifyDiscountsEnabled(state),
  cardPaymentDisplay: getCardPaymentDisplay(state),
  isKlarnaRedirectEnabled: getKlarnaRedirectEnabled(state),
});

const mapFormValuesToProps = (state) => ({
  companyName: formSelector(state, 'invoiceAddress.organizationName'),
  vrn: formSelector(state, 'invoiceAddress.vrn'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onApplePayClick() {
    return function onApplePayClickEffects(_, getState) {
      return Promise.resolve().then(() => {
        // User is attempting to Buy with Apple Pay again, clear submit errors to allow new attempt
        dispatch(clearSubmitErrors(formName));
      }).then(() => {
        const valid = isValid(formName)(getState());
        if (!valid) {
          dispatch(submit(formName)); // Show form errors!
          throw new Error('The payment form is invalid');
        }
      }).then(() => {
        const state = getState();
        const applePayPaymentPopup = new applePay.ApplePayCheckoutPopup();
        applePayPaymentPopup.open();
        applePayPaymentPopup.createWindowedApplePaySession(
          getApplePayParameters(state),
          {
            logoUrl: getLogoUrl(state),
            orderNumber: getOrderNumber(state),
            organization: getOrganization(state),
            sessionId: getSessionId(),
          },
        ).then((result) => {
          if (result.status === 'success') {
            dispatch(submit(formName));
            return;
          }
          if (result.status === 'error') {
            getRollbar((rollbar, extra = {}) => {
              rollbar.error('Apple Pay payment error', { ...extra, ...result });
            });
          }
          createSubmissionError(
            genericErrorFromString(result.reason),
            dispatch,
          );
        }).catch((e) => {
          getRollbar((rollbar, extra = {}) => {
            rollbar.error('Apple Pay error thrown', { ...extra, message: e.message });
          });
          createSubmissionError(
            genericErrorFromString('Your payment was not accepted. Please try using a different payment method.'),
            dispatch,
          );
        });
      });
    };
  },
  onCryptoPaySuccess(payment) {
    return function onCryptoPaySuccessEffects() {
      dispatch(change(formName, 'cryptoPaymentId', payment.id));
      dispatch(submit(formName));
    };
  },
  onCryptoPayClick() {
    return function onCryptoPayClickEffects(_, getState) {
      return Promise.resolve()
        .then(() => {
          const valid = isValid(formName)(getState());
          if (!valid) {
            dispatch(submit(formName)); // Show form errors!
            throw new Error('The payment form is invalid');
          }
        })
        // CryptoPay payments are auto-captured.
        // We need to prepare the order for submission before proceeding
        // to prevent double captures due to order errors.
        .then(() => {
          const values = getFormValues(formName)(getState());
          const finalizeEnabled = isFinalizeEnabled(getState());
          dispatch(showLoadingIndicator());
          return finalizeEnabled
            ? dispatch(finalizeOrderV2(values))
            : dispatch(finalizeOrderV1(values));
        })
        .then(() => {
          dispatch(hideLoadingIndicator());
          return true;
        })
        .catch(() => {
          // TODO: Display feedback to customer.
          dispatch(hideLoadingIndicator());
          return false;
        });
    };
  },
  onGooglePayError: handleGooglePayError,
  onGooglePaySuccess: handleGooglePaySuccess,
  onPayPalError: handlePayPalError,
  onPayPalSuccess: handlePayPalSuccess,
  onKlarnaFailure: handleKlarnaError,
  onKlarnaSuccess: handleKlarnaSuccess,
  onCardChange: handleCardChange,
  onOrderCheck() {
    return function onOrderCheckSideEffects(d, getState) {
      const state = getState();
      const registeredFieldNames = Object.keys(state.form[formName].registeredFields);
      registeredFieldNames.forEach((key) => dispatch(touch(formName, key)));
      return getFormSyncErrors(formName)(state);
    };
  },
  onDeleteCardPaymentSource(organizationId, paymentSourceId) {
    return function onDeleteCardPaymentSourceEffects() {
      dispatch(showLoadingIndicator());
      dispatch(deleteCardPaymentSource(organizationId, paymentSourceId))
        .then(() => {
          dispatch(hideLoadingIndicator());
        }).catch(() => {
          dispatch(hideLoadingIndicator());
        });
    };
  },
}, dispatch);

export default compose(
  injectIntl,
  injectFlow,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onSubmitFail(errors, dispatch, submitError, props) {
      const paymentMethodId = get(props, 'values.paymentMethodId');

      // Debugging CHEC-757
      if (paymentMethodId === PaymentTypes.CryptoPay) {
        getRollbar((rollbar, extra = {}) => {
          rollbar.error('Failed to submit CryptoPay payment', { ...extra, errors });
        });
      }

      trackHeapEvent('payment_submit_failed', {
        payment_method_id: paymentMethodId,
      });

      // if the error is not a SubmissionError re-throw it to prevent swallowing.
      if (!errors && submitError instanceof Error) {
        getRollbar((rollbar, extra = {}) => {
          rollbar.error('Unexpected payment submission  error', extra, submitError);
        });
        throw submitError;
      }
    },
    onSubmitSuccess(result, dispatch, props) {
      const paymentMethodId = get(props, 'values.paymentMethodId');

      trackHeapEvent('payment_submit_succeeded', {
        payment_method_id: paymentMethodId,
      });
    },
    validate: handleValidation,
    // Override implementation to ensure sync validation is re-evaluated when
    // a selected address book contact changes (e.g. after customer updates an
    // incomplete address book contact)
    shouldError({
      fieldValidatorKeys,
      initialRender,
      lastFieldValidatorKeys,
      nextProps,
      props,
      structure,
      values,
    }) {
      if (initialRender) {
        return true;
      }

      const {
        addressBook,
      } = props;

      const {
        addressBook: nextAddressBook,
        values: nextValues,
      } = nextProps;

      let selectedInvoiceContact;
      let selectedBillingContact;

      let nextSelectedInvoiceContact;
      let nextSelectedBillingContact;

      if (addressBook != null) {
        if (values.billingAddress != null) {
          selectedBillingContact = getContactById(
            addressBook,
            values.billingAddress.addressToUse,
          );
        }

        if (values.invoiceAddress != null) {
          selectedInvoiceContact = getContactById(
            addressBook,
            values.invoiceAddress.addressToUse,
          );
        }
      }

      if (nextAddressBook != null) {
        if (nextValues.billingAddress != null) {
          nextSelectedBillingContact = getContactById(
            nextAddressBook,
            nextValues.billingAddress.addressToUse,
          );
        }

        if (nextValues.invoiceAddress != null) {
          nextSelectedInvoiceContact = getContactById(
            nextAddressBook,
            nextValues.invoiceAddress.addressToUse,
          );
        }
      }

      return (
        !structure.deepEqual(values, nextProps && nextProps.values)
        || !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
        || !structure.deepEqual(selectedBillingContact, nextSelectedBillingContact)
        || !structure.deepEqual(selectedInvoiceContact, nextSelectedInvoiceContact)
      );
    },
  }),
  connect(mapFormValuesToProps),
)(PaymentForm);
