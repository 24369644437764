import BemHelper from '@flowio/bem-helper';
import React from 'react';
import ContentLabel from '../content-label';

interface Props {
  className?: string;
  content: io.flow.internal.v0.models.Dict;
}

const bem = new BemHelper('dict');

const Dict: React.FunctionComponent<Props> = ({
  className,
  content,
}) => (
  <dl className={bem.block(className)}>
    <dt className={bem.element('label')}>
      <ContentLabel content={content.label} />
    </dt>
    <dd className={bem.element('description')}>
      <ContentLabel content={content.description} />
    </dd>
  </dl>
);

Dict.displayName = 'Dict';

export default Dict;
