import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '../svg-icon';

const ChevronLeft = ({
  className,
}) => (
  <SvgIcon className={className} viewBox="0 0 8 16">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="5.5 3 7 4.5 3.25 8 7 11.5 5.5 13 0.5 8" />
    </g>
  </SvgIcon>
);

ChevronLeft.displayName = 'ChevronLeft';

ChevronLeft.propTypes = {
  className: PropTypes.string,
};

ChevronLeft.defaultProps = {
  className: undefined,
};

export default ChevronLeft;
