import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

import { DispatchProps, StateProps, OwnProps } from '../types';
import { RootState } from '../../../store/types';
import { isDomesticShippingUnavailableDialogOpen, hideDomesticShippingUnavailableDialog, getDomesticShippingUnavailableDialogError } from '../../../store/checkout';
import ShopifyDomesticShippingUnavailableDialog from '../components/shopify-domestic-shipping-unavailable-dialog';

const mapStateToProps: MapStateToProps<
StateProps,
OwnProps,
RootState
> = (state) => ({
  isOpen: isDomesticShippingUnavailableDialogOpen(state),
  orderError: getDomesticShippingUnavailableDialogError(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, props) => ({
  onCancel(event): void {
    const { onCancel } = props;
    if (onCancel != null) onCancel(event);
    dispatch(hideDomesticShippingUnavailableDialog());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ShopifyDomesticShippingUnavailableDialog,
);
