import React from 'react';

import { CUSTOMER_INFO_PROMO_CODE } from '../../../common/constants/feature-keys';
import CustomerInformationForm from '../../containers/customer-information-form';
import FeatureToggle from '../feature-toggle';
import MobileDiscountFormV2 from '../mobile-discount-form-v2';

interface Props {
  discountsEnabled: boolean;
  forceEnableShopifyDiscounts: boolean;
  shopifyIntegration: boolean;
}

const CustomerInformationStep: React.FunctionComponent<Props> = ({
  discountsEnabled,
  forceEnableShopifyDiscounts,
  shopifyIntegration,
}) => (
  <>
    {(discountsEnabled || forceEnableShopifyDiscounts || shopifyIntegration) && (
      <FeatureToggle featureKey={CUSTOMER_INFO_PROMO_CODE}>
        <MobileDiscountFormV2 />
      </FeatureToggle>
    )}
    <CustomerInformationForm />
  </>
);

CustomerInformationStep.displayName = 'CustomerInformationStep';

export default CustomerInformationStep;
