import React, { Component } from 'react';
import { Field } from 'redux-form';
import { FormattedMessage, defineMessages } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import ApiPropTypes from '@flowio/api-prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';

import { Grid, Row, Column } from '../../grid';
import Section from '../../section';
import TextField from '../../redux-form/text-field';
import Button from '../../button';
import { CircleCheck } from '../../svg-icons';
import GenericError from '../../generic-error';
import handleEnterKeyboardEvent from '../../../utilities/handle-enter-keyboard-event';
import FeatureToggle from '../../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../../common/constants/feature-keys';

if (process.browser) {
  require('./rewards-form.css'); // eslint-disable-line global-require
}

// 9 is the max column number (12) minus the width of the button (3)
const COLUMN_BUDGET = 9;

const bem = new BemHelper('rewards-form');

const messages = defineMessages({
  requiredField: {
    id: 'checkout_error_empty_field',
    description: 'Generic error shown when a form field is required',
    defaultMessage: 'This field is required',
  },
});

class RewardsForm extends Component {
  constructor(props) {
    super(props);
    this.required = this.required.bind(this);
  }

  required = (value) => {
    const { intl } = this.props;

    if (value) {
      return undefined;
    }
    return intl.formatMessage(messages.requiredField);
  };

  render() {
    const {
      removeError,
      onRemoveReward,
      rewardsProgram,
      appliedRewards,
      error,
      labelKey,
    } = this.props;

    const inputLength = rewardsProgram.input_specification
      ? (COLUMN_BUDGET / rewardsProgram.input_specification.inputs.length)
      : 0;

    // 9 is the max column number (12) minus the width of the button (3)
    const firstInputLength = rewardsProgram.input_specification
      ? inputLength + (COLUMN_BUDGET % rewardsProgram.input_specification.inputs.length)
      : 0;

    const rewardsLimits = get(rewardsProgram, 'input_specification.limitations.limitations', []);
    const rewardsLimit = get(find(rewardsLimits, (limit) => limit.discriminator === 'input_specification_limitation_max'), 'max');

    return (
      <FeatureToggle
        featureKey={MOBILE_UX_SPACING}
        render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
          <Section fitted={isMobileUXSpacingEnabled}>
            <Section.Header>
              <Section.Title>
                {rewardsProgram.title}
              </Section.Title>
            </Section.Header>
            <Section.Content>
              <Grid>
                {rewardsProgram.message && (
                  <Row>
                    <Column xs={12}>
                      <span className={bem.element('message')}>
                        {rewardsProgram.message.content}
                      </span>
                    </Column>
                  </Row>
                )}
                {rewardsProgram.input_specification && (
                  <Row>
                    <div>
                      {rewardsProgram.input_specification.inputs.map((input, index) => (
                        <Column
                          key={input.key}
                          className={bem.element(input.name)}
                          style={index === 0 ? { 'padding-left': '20px' } : {}}
                          xs={index === 0 ? Math.floor(firstInputLength) : Math.floor(inputLength)}
                        >
                          <Field
                            disabled={rewardsLimit <= appliedRewards.length}
                            component={TextField}
                            labelText={input.display_text}
                            name={input.name}
                            validate={[this.required]}
                          />
                        </Column>
                      ))}
                      <Column xs={3}>
                        <Button
                          className={bem.element('submit-reward')}
                          disabled={rewardsLimit <= appliedRewards.length}
                          type="submit"
                          color="primary"
                          fluid
                        >
                          <FormattedMessage
                            id="checkout_loyalty_submit"
                            description="Message to describe the submit button in a promo form"
                            defaultMessage="Enter"
                          />
                        </Button>
                      </Column>
                    </div>
                  </Row>
                )}
                {error && (
                  <Row className={bem.element('error-row')}>
                    <GenericError error={error} />
                  </Row>
                )}
                <div className={bem.element('applied-rewards')}>
                  {appliedRewards.map((reward) => (
                    <Row className={bem.element('applied-reward')} key={reward.id}>
                      <CircleCheck className={bem.element('rewards-checked')} />
                      <span>{get(reward, labelKey)}</span>
                      <span
                        className={bem.element('rewards-remove')}
                        onClick={() => onRemoveReward(reward.id)}
                        onKeyPress={handleEnterKeyboardEvent(() => onRemoveReward(reward.id))}
                        role="button"
                        tabIndex={0}
                      >
                        <FormattedMessage
                          id="checkout_cart_item_action_remove"
                          description="Text on the action to remove an item from the cart"
                          defaultMessage="Remove"
                        />
                      </span>
                    </Row>
                  ))}
                </div>
                {removeError && (
                  <Row className={bem.element('error-row')}>
                    <GenericError error={removeError} />
                  </Row>
                )}
              </Grid>
            </Section.Content>
          </Section>
        )}
      />
    );
  }
}

RewardsForm.displayName = 'RewardsForm';

RewardsForm.propTypes = {
  appliedRewards: PropTypes.oneOf([
    PropTypes.arrayOf(ApiInternalPropTypes.loyaltyProgramReward),
    PropTypes.arrayOf(ApiInternalPropTypes.giftCard),
  ]),
  onRemoveReward: PropTypes.func.isRequired,
  rewardsProgram: PropTypes.oneOf([
    ApiInternalPropTypes.loyaltyProgram,
    ApiInternalPropTypes.giftCardProgram,
  ]).isRequired,
  removeError: ApiPropTypes.genericError,
  error: ApiPropTypes.genericError,
  labelKey: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

RewardsForm.defaultProps = {
  appliedRewards: [],
  removeError: undefined,
  labelKey: 'label',
  error: null,
};

export default RewardsForm;
