/* eslint-disable import/prefer-default-export */

import { PixelOrderItem } from '@flowio/pixel';

/**
 * Creates a `PixelOrderItem` object from the provided `ShopifyCartItem`.
 * @param shopifyCartItem The cart item to map into a pixel order item.
 * @param quantity Optional quantity to use when creating the object, defaults
 * to the provided item quantity. Useful in cart events, where the quantity
 * needs to reflect the number of items that were added or removed from the
 * cart, and not the final item tally.
 */
export function shopifyCartItemToPixelOrderItem(
  shopifyCartItem: io.flow.v0.models.ShopifyCartItem,
  quantity?: number,
): PixelOrderItem {
  return {
    base_currency: shopifyCartItem.local.line_price.base?.currency,
    base_price: shopifyCartItem.local.line_price.base?.amount,
    currency: shopifyCartItem.local.line_price.currency,
    id: shopifyCartItem.id,
    name: shopifyCartItem.product_title,
    price: shopifyCartItem.local.line_price.amount,
    quantity: quantity != null ? quantity : shopifyCartItem.quantity,
    variant: shopifyCartItem.variant_id.toString(),
  };
}
