import { Field, Form } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

import './address-book-contact-dialog.css';

import { Dialog } from '../../dialog';
import { Props } from '../types';
import Address from '../../address';
import Button from '../../button';
import GenericError from '../../generic-error';
import getCorrectAdministrativeArea from '../../../utilities/get-correct-administrative-area';

const bem = new BemHelper('flow-address-book-contact-dialog');

const AddressBookContactDialog: React.FunctionComponent<Props> = ({
  addressConfiguration,
  autofill,
  countries,
  handleSubmit,
  error,
  isOpen,
  locale,
  onClose,
  selectedCountryCode,
  submitting,
}) => (
  <Dialog
    className={bem.block()}
    isOpen={isOpen}
    showBackdrop
  >
    <Dialog.Header
      text={(
        <FormattedMessage
          id="checkout_edit_address_dialog_header"
          description="A label that indicates the title of the edit address dialog"
          defaultMessage="Edit Address"
        />
      )}
    />
    <Dialog.Body>
      <Form onSubmit={handleSubmit}>
        <Field component="input" type="hidden" name="organizationId" />
        <Field component="input" type="hidden" name="customerNumber" />
        <Field component="input" type="hidden" name="contactId" />
        <Address
          addressConfiguration={addressConfiguration}
          countries={countries}
          countryCode={selectedCountryCode}
          locale={locale}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onAutoCompleteAddress={(place: any): void => {
            if (place.street != null && place.street.length) {
              autofill('addressLine1', place.street);
            } else {
              autofill('addressLine1', place.backupAddress);
            }

            autofill('locality', place.city);
            autofill('administrativeArea', getCorrectAdministrativeArea(addressConfiguration, locale, place.administrativeAreas));
            autofill('postalCode', place.postalCode);
          }}
        />
      </Form>
      {error != null && (
        <GenericError error={error} />
      )}
    </Dialog.Body>
    <Dialog.Footer>
      <Dialog.Actions>
        <Button
          type="button"
          disabled={submitting}
          onClick={onClose}
        >
          <FormattedMessage
            id="checkout_dialog_cancel_action"
            description="A label that indicates the cancel action of the a dialog"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          type="submit"
          color="positive"
          disabled={submitting}
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="checkout_dialog_submit_action"
            description="A label that indicates the submit action of the a dialog"
            defaultMessage="Submit"
          />
        </Button>
      </Dialog.Actions>
    </Dialog.Footer>
  </Dialog>
);

AddressBookContactDialog.displayName = 'AddressBookContactDialog';

export default AddressBookContactDialog;
