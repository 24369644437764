import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import filter from 'lodash/filter';
import ApiPropTypes from '@flowio/api-prop-types';

import Spinner from '../spinner';

if (process.browser) {
  require('./pending-page.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('pending-page');

class PendingPage extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  render() {
    const {
      is3DSPendingPageEnabled,
      authorizations,
    } = this.props;
    const hasCardPayment = filter(authorizations, { discriminator: 'card_authorization' }).length > 0;

    return (
      <div className={bem.block()}>
        <Spinner className={bem.element('spinner')} size="small" />
        <div className={bem.element('message')}>
          <FormattedMessage
            id="checkout_order_process_message"
            description="Message that shows when order is being processed"
            defaultMessage="Please wait..."
          />
        </div>
        {hasCardPayment && is3DSPendingPageEnabled && (
          <div className={bem.element('message-container')}>
            <FormattedMessage
              id="checkout_pending_message"
              description="Message that shows when 3DS auth is pending"
              defaultMessage="Your payment method is currently being verified by 3D Secure. The typical verification process is less than 1 minute.
                Please stay on this page. You will be directed to the order confirmation page once the verification process is complete.
                We appreciate your patience."
            />
          </div>
        )}
      </div>
    );
  }
}

PendingPage.displayName = 'PendingPage';

PendingPage.propTypes = {
  onMount: PropTypes.func.isRequired,
  is3DSPendingPageEnabled: PropTypes.bool,
  authorizations: PropTypes.arrayOf(ApiPropTypes.authorization),
};

PendingPage.defaultProps = {
  is3DSPendingPageEnabled: false,
  authorizations: [],
};

export default PendingPage;
