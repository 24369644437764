import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';

import {
  fetchCheckoutBundle,
  getCustomerAddressBookContacts,
  getOrderDestinationCountryCode,
  syncReturnUrlAttribute,
  skipCheckoutSteps,
  isDiscountsEnabled,
  isForceEnableShopifyDiscountsEnabled,
} from '../store/checkout';
import CustomerInformationStep from '../components/customer-information-step';
import checkHttpStatus from '../utilities/check-http-status';
import withExternalEvents from '../components/with-external-events';
import generateFeatureQuery from '../utilities/generate-feature-query';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import getPreferredUserAddress from '../utilities/get-preferred-user-address';
import { fetchAdministrativeDivisions } from '../store/reference';
import PromptTarget from '../constants/prompt-target';
import { fetchOptinPrompts } from '../store/optin';
import { getLocale } from '../store/intl';
import { getIsShopifyIntegration } from '../store/flow';
import { updatePaymentMethods, fetchAddressConfiguration } from '../store/checkout/actions';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { organization, orderNumber } = params;

  return dispatch(fetchCheckoutBundle(organization, orderNumber, {
    feature_q: generateFeatureQuery(organization),
  }))
    .then(checkHttpStatus)
    .then(() => dispatch(updatePaymentMethods(organization)))
    .then(() => {
      const contacts = getCustomerAddressBookContacts(getState());
      const orderCountry = getOrderDestinationCountryCode(getState());
      const preferredShippingContact = getPreferredUserAddress(contacts, 'shipping')[0];
      const preferredShippingCountry = get(preferredShippingContact, 'address.country');

      // Generally, checkout bundle models include country specific data based on
      // customer geolocation. By comparing whether the order destination and
      // the preferred address book contact country code match, we can determine
      // whether the checkout model includes the initial country specific data
      // needed to render the returning customer view correctly.
      if (preferredShippingCountry && orderCountry !== preferredShippingCountry) {
        return Promise.all([
          dispatch(fetchAddressConfiguration(organization, preferredShippingCountry)),
          dispatch(fetchAdministrativeDivisions({
            countries: [preferredShippingCountry],
          })),
          dispatch(fetchOptinPrompts({
            organizationId: organization,
            updatedRegion: preferredShippingCountry,
            promptTarget: PromptTarget.CHECKOUT,
            locale: getLocale(getState()),
          })),
        ]);
      }

      return Promise.resolve();
    })
    .then(() => dispatch(syncReturnUrlAttribute(organization)))
    .then(() => dispatch(skipCheckoutSteps()))
    .catch((error) => {
      logInternalServerError(error, orderNumber);
      throw error;
    });

  // TODO: Render error page when resources cannot be loaded successfully.
};

const mapStateToProps = (state) => ({
  discountsEnabled: isDiscountsEnabled(state),
  forceEnableShopifyDiscounts: isForceEnableShopifyDiscountsEnabled(state),
  shopifyIntegration: getIsShopifyIntegration(state),
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(CustomerInformationStep);
