import { BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import React from 'react';

import type { AddressesGetResponse } from '@flowio/api-sdk';
import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import { createApiClient } from '../../../common/services/api-v2';
import { PostalCodeType, getPostalCodeType } from '../../utilities/address-utilities';
import createFetch from '../../../common/utilities/enveloped-fetch-v2';
import DropdownMenu from '../dropdown-menu';
import FormGroup from '../form-group';
import FloatingLabelInput from '../floating-label-input';
import ClearableField from '../redux-form/clearable-field';
import { usePrevious } from '../../hooks';
import { trackHeapEvent } from '../../utilities/heap';

type Props = {
  addressType?: AddressType;
  children?: never;
  countryCode: string;
  errorText?: React.ReactNode;
  invalid?: boolean;
  required?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  onSelection?: (address: io.flow.v0.models.Address) => void;
  value?: string;
}

type ResponseHandler = (response: AddressesGetResponse) => void;

const getLabelText = (
  countryCode: string,
): React.ReactElement => {
  switch (getPostalCodeType(countryCode)) {
    case PostalCodeType.ZIP:
      return (
        <FormattedMessage
          id="checkout_address_field_label_zip_code"
          description="Content for postal code field label in address forms applied when the selected country is United States"
          defaultMessage="Zip Code"
        />
      );
    default:
      return (
        <FormattedMessage
          id="checkout_address_field_label_postal_code"
          description="Content for postal code field label in address forms applied when the selected country is not United States"
          defaultMessage="Postal Code"
        />
      );
  }
};

const PostalAutoComplete: React.FunctionComponent<Props> = ({
  addressType,
  countryCode,
  invalid,
  onSelection,
  required,
  onBlur,
  onFocus,
  onValueChange,
  name,
  value,
  errorText,
}) => {
  const fetch = createFetch();
  const client = createApiClient({ fetch });
  const lastCallbackRef = React.useRef<ResponseHandler>();
  const floatingLabelInputRef = React.useRef<FloatingLabelInput>(null);
  const [options, setOptions] = React.useState<io.flow.v0.models.Address[]>([]);
  const [open, setOpen] = React.useState(false);

  const items = options
    .map((option) => {
      const content = compact([
        option.postal,
        option.city,
        option.province,
        option.country,
      ]);
      return {
        content: content.join(', '),
      };
    });

  let minMenuWidth: string | number = 'auto';

  if (floatingLabelInputRef.current != null
    && floatingLabelInputRef.current.inputRef.current != null) {
    minMenuWidth = floatingLabelInputRef.current.inputRef.current.clientWidth;
  }

  const handleFocus = React.useCallback((
    event: React.FocusEvent<HTMLInputElement>,
  ): void => {
    setOpen(true);
    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }, [onFocus]);

  const handleBlur = React.useCallback((
    event: React.FocusEvent<HTMLInputElement>,
  ): void => {
    setOpen(false);
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }, [onBlur]);

  const handleItemSelection = React.useCallback((
    selectedIndex: number,
  ): void => {
    const selectedOption = options[selectedIndex];
    if (typeof onSelection === 'function') {
      onSelection(selectedOption);
    }
    setOpen(false);
  }, [addressType, options, onSelection]);

  const handleValueChange = React.useCallback((
    inputValue: string,
  ): void => {
    const debounced = debounce(() => {
      const callback: ResponseHandler = (response) => {
        if (callback === lastCallbackRef.current) {
          if (response.ok) {
            const addresses = response.body.filter((address) => (
              address.city != null || address.province != null
            ));

            setOptions(addresses);
            setOpen(true);
          }
        }
      };

      lastCallbackRef.current = callback;

      client.addresses.get({
        address: `${inputValue} ${countryCode}`,
        country: countryCode,
        postal_prefix: inputValue,
      }).then(callback);
    }, 150);

    debounced();

    if (typeof onValueChange === 'function') {
      onValueChange(inputValue);
    }
  }, [countryCode, onValueChange, setOptions, setOpen]);

  const previousErrorText = usePrevious(errorText);

  React.useEffect(() => {
    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: name,
      });
    }
  }, [errorText]);

  return (
    <FormGroup errorText={errorText}>
      <DropdownMenu
        autoHighlight={false}
        open={open && options.length > 0}
        items={items}
        menuProps={{
          style: {
            minWidth: minMenuWidth,
          },
        }}
        onItemSelection={handleItemSelection}
        trigger={(
          <FloatingLabelInput
            data-automation-id={automationToken('postal-code', addressType)}
            inputProps={{
              'aria-required': required,
              autoComplete: autoCompleteToken('postal-code', addressType),
              required,
            }}
            invalid={invalid}
            labelText={getLabelText(countryCode)}
            name={name}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onValueChange={handleValueChange}
            ref={floatingLabelInputRef}
            value={value}
          />
        )}
      />
    </FormGroup>
  );
};

const ReduxFormPostalAutoComplete: React.FunctionComponent<Props & WrappedFieldProps> = ({
  input,
  meta,
  ...unhandledProps
}) => {
  let errorText: React.ReactNode = null;

  if (meta.touched) {
    if (meta.error != null) {
      errorText = meta.error;
    } else if (meta.warning != null) {
      errorText = meta.warning;
    }
  }

  return (
    <PostalAutoComplete
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...unhandledProps}
      invalid={errorText != null}
      errorText={errorText}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onValueChange={input.onChange}
      name={input.name}
      value={input.value}
    />
  );
};

const PostalAutoCompleteField: React.FunctionComponent<Props> = (props) => (
  <ClearableField<BaseFieldProps<Props>>
    component={ReduxFormPostalAutoComplete}
    props={props}
    name="postalCode"
  />
);

export default PostalAutoCompleteField;
