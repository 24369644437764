import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import Header from '../header';
import IntegrationHeader from '../integration-header';
import Footer from '../footer';
import Spinner from '../spinner';
import Steps from '../../constants/steps';
import getDeviceDetails from '../../../common/utilities/get-device-details';

if (process.browser) {
  require('./application.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('application');

class Application extends Component {
  componentDidMount() {
    const { setVisited, addDeviceDetails, deviceDetails } = this.props;
    setVisited();

    // if for some reason device details aren't set
    // set it again
    if (deviceDetails && deviceDetails.userAgent === undefined) {
      addDeviceDetails(getDeviceDetails());
    }
  }

  componentDidUpdate(prevProps) {
    const { redirectLocation } = this.props;
    const { redirectLocation: prevRedirectLocation } = prevProps;

    if (redirectLocation && redirectLocation !== prevRedirectLocation) {
      window.location.assign(redirectLocation);
    }
  }

  // TODO: Look into translating page titles.
  getCurrentTitle() {
    const { currentStep, organizationData } = this.props;
    const orgString = get(organizationData, 'name') ? `| ${get(organizationData, 'name')}` : '';
    switch (currentStep) {
      case Steps.Cart:
        return `Cart ${orgString}`;
      case Steps.ContactInfo:
        return `Contact Info ${orgString}`;
      case Steps.ShippingMethod:
        return `Shipping Method ${orgString}`;
      case Steps.PaymentInfo:
        return `Payment Info ${orgString}`;
      case Steps.Confirmation:
        return `Confirmation ${orgString}`;
      default:
        return `Checkout ${orgString}`;
    }
  }

  render() {
    const {
      blocking,
      children,
      isMobileUXSpacingEnabled,
      versionOverride,
      organizationUrl,
    } = this.props;
    return (
      <main
        className={bem.block(
          'flow-checkout',
          isMobileUXSpacingEnabled && 'flow-generic-mobile-improvements',
        )}
      >
        {versionOverride && (<IntegrationHeader version={versionOverride} />)}
        <Header className={bem.element('header')} logoLinkUrl={organizationUrl} />
        <Helmet>
          <title>{this.getCurrentTitle()}</title>
        </Helmet>
        <div className={bem.element('content-area')}>
          {children}
        </div>
        <Footer className={bem.element('footer')} />
        <TransitionGroup appear>
          {blocking && (
            <CSSTransition
              classNames={bem.element('dimmer')}
              timeout={200}
            >
              <div className={bem.element('dimmer')}>
                <Spinner
                  className={bem.element('spinner')}
                  data-automation-id="application-spinner"
                  size="small"
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </main>
    );
  }
}

Application.displayName = 'Application';

Application.propTypes = {
  blocking: PropTypes.bool.isRequired,
  children: PropTypes.node,
  currentStep: PropTypes.string.isRequired,
  deviceDetails: PropTypes.shape({
    userAgent: PropTypes.string,
  }),
  addDeviceDetails: PropTypes.func.isRequired,
  isMobileUXSpacingEnabled: PropTypes.bool.isRequired,
  versionOverride: PropTypes.string,
  // TODO: Use prop type validator for `organization_summary` model
  organizationData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  organizationUrl: PropTypes.string,
  redirectLocation: PropTypes.string,
  setVisited: PropTypes.func.isRequired,
};

Application.defaultProps = {
  children: undefined,
  versionOverride: undefined,
  organizationData: undefined,
  deviceDetails: undefined,
  organizationUrl: '/',
  redirectLocation: undefined,
};

export default Application;
