/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getElementType from '@flowio/react-helpers/lib/getElementType';

import bem from './bem';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

class StepTitle extends PureComponent {
  render() {
    const { children, className, text } = this.props;
    const unhandledProps = getUnhandledProps(StepTitle, this.props);
    const ElementType = getElementType(StepTitle, this.props);

    return (
      <ElementType {...unhandledProps} className={bem.element('title', {}, className)}>
        <FeatureToggle
          featureKey={MOBILE_UX_SPACING}
          render={({ isFeatureEnabled }) => {
            if (isFeatureEnabled) {
              return (
                <span className={bem.element('text')}>
                  {children != null ? children : text}
                </span>
              );
            }

            return children || text;
          }}
        />
      </ElementType>
    );
  }
}

StepTitle.displayName = 'StepTitle';

StepTitle.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  text: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

StepTitle.defaultProps = {
  as: 'span',
  text: undefined,
  children: undefined,
  className: '',
};

export default StepTitle;
