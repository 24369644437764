import { combineReducers } from 'redux';
import cart from './cart';
import statuses from './statuses';
import errors from './errors';
import params from './params';
import type { CartState, CartStateEntities } from '../types';

export default combineReducers<CartState>({
  entities: combineReducers<CartStateEntities>({
    cart,
  }),
  errors,
  params,
  statuses,
});
