import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CheckoutEntitiesReviewState } from '../types';

const initialState: CheckoutEntitiesReviewState = {};

const reviewReducer: AppReducer<CheckoutEntitiesReviewState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.UPDATE_GOOGLE_PAY_INFO:
      return update(state, {
        googlePayInfo: {
          $set: action.payload,
        },
      });
    case actionTypes.UPDATE_PAYPAL_INFO:
      return update(state, {
        paypalPayerId: {
          $set: action.payload.paypalPayerId,
        },
        paypalPaymentId: {
          $set: action.payload.paypalPaymentId,
        },
      });
    case actionTypes.PROCEED_TO_REVIEW:
      return update(state, {
        $set: {
          ...action.payload,
          error: null,
        },
      });
    case actionTypes.CHANGE_CARD:
      return update(state, {
        card: {
          $set: undefined,
        },
      });
    case actionTypes.SET_REVIEW_ERROR:
      return update(state, {
        error: {
          $set: action.payload,
        },
      });
    case actionTypes.CLEAR_REVIEW_STATE:
      return update(state, {
        $set: initialState,
      });
    default:
      return state;
  }
};

export default reviewReducer;
