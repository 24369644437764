import map from 'lodash/map';
import isOptinPromptConsent from './is-optin-prompt-consent';

const mapOptinPromptsToValuePairs = (optinPrompts, order) => map(optinPrompts, (optinPrompt) => {
  const key = optinPrompt.optin_attribute.optin_attribute_key;
  const value = (order != null && order.attributes != null && order.attributes[key] != null)
    ? order.attributes[key] === 'true'
    : isOptinPromptConsent(optinPrompt);
  return [key, value];
});

export default mapOptinPromptsToValuePairs;
