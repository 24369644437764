import React, { useCallback } from 'react';
import BemHelper from '@flowio/bem-helper';

import './menu-item.css';

type OwnProps = {
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  highlighted?: boolean;
  index?: number;
  onClick?: (event: React.MouseEvent) => void;
  onHighlight?: (event: React.MouseEvent, props: Props) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onSelect?: (event: React.MouseEvent, props: Props) => void;
  selected?: boolean;
  value?: string;
};

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLLIElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

const bem = new BemHelper('flow-menu-item');

const MenuItem = React.forwardRef<HTMLLIElement, Props>((props, ref) => {
  const {
    children,
    className,
    content,
    description,
    disabled = false,
    highlighted = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    index,
    onClick,
    onHighlight,
    onKeyDown,
    onMouseEnter,
    onMouseMove,
    onSelect,
    selected = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
    ...unhandledProps
  } = props;

  const triggerSelect = useCallback((
    event: React.MouseEvent,
  ) => {
    if (!disabled && typeof onSelect === 'function') {
      onSelect(event, props);
    }
  }, [disabled, onSelect, props]);

  const triggerHighlight = useCallback((
    event: React.MouseEvent,
  ) => {
    if (!disabled && !highlighted && typeof onHighlight === 'function') {
      onHighlight(event, props);
    }
  }, [disabled, highlighted, onHighlight, props]);

  const handleClick = useCallback((
    event: React.MouseEvent,
  ) => {
    triggerSelect(event);
    if (typeof onClick === 'function') {
      onClick(event);
    }
  }, [onClick, triggerSelect]);

  const handleMouseEnter = useCallback((
    event: React.MouseEvent,
  ) => {
    triggerHighlight(event);
    if (typeof onMouseEnter === 'function') {
      onMouseEnter(event);
    }
  }, [onMouseEnter, triggerHighlight]);

  const handleMouseMove = useCallback((
    event: React.MouseEvent,
  ) => {
    triggerHighlight(event);
    if (typeof onMouseMove === 'function') {
      onMouseMove(event);
    }
  }, [onMouseMove, triggerHighlight]);

  return (
    <li
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...unhandledProps}
      aria-selected={selected}
      className={bem.block({
        disabled,
        highlighted,
        selected,
      }, className)}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      ref={ref}
      role="option"
    >
      {children != null ? children : (
        <div className={bem.element('content-group')}>
          <div className={bem.element('content')}>
            {content}
          </div>
          <div className={bem.element('description')}>
            {description}
          </div>
        </div>
      )}
    </li>
  );
});

export default MenuItem;
