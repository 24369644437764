import wait from './wait';

/**
 * Retry an asynchronous operation until an error is no longer raised or the
 * maximum retry count is exceeded.
 */
export default function retry<T>(
  operation: () => Promise<T>,
  retryDelay: number,
  retryLeft: number,
): Promise<T> {
  return new Promise((resolve, reject) => {
    operation()
      .then(resolve)
      .catch((error) => {
        if (retryLeft > 1) {
          return wait(retryDelay)
            .then(() => retry(operation, retryDelay, retryLeft - 1))
            .then(resolve)
            .catch(reject);
        }

        return reject(error);
      });
  });
}
