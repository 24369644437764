/* eslint-disable global-require */

import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

require('./delivered-duty-messaging.css');

const bem = new BemHelper('landed-cost-messaging');
export class DeliveredDutyMessaging extends React.PureComponent {
  render() {
    return (
      <div className={bem.element('description')}>
        <FormattedMessage
          id="checkout_delivered_duties_paid_messaging"
          description="Message displays to customers before placing an order, guaranteeing that no additional fees will be due on delivery."
          defaultMessage="All applicable duties, taxes and fees are included in the total amount of your order. We guarantee that there are no further fees due on delivery."
        />
      </div>
    );
  }
}

DeliveredDutyMessaging.displayName = 'DeliveredDutyMessaging';

export default DeliveredDutyMessaging;
