import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

import { getFooterContents } from '../../store/content/selectors';
import ContentItem from '../content-item';

if (process.browser) {
  require('./footer.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('footer');

const Footer = ({ className, contents }) => (
  <footer className={bem.block(className)}>
    <nav className={bem.element('content')}>
      <ul className={bem.element('list')}>
        {!!contents && contents.length && map(contents, (content, index) => (
          <li key={index} className={bem.element('item')}>
            <ContentItem content={content} />
          </li>
        ))}
      </ul>
    </nav>
  </footer>
);

Footer.displayName = 'Footer';

Footer.propTypes = {
  className: PropTypes.string,
  contents: PropTypes.arrayOf(ApiInternalPropTypes.contentItem).isRequired,
};

Footer.defaultProps = {
  className: '',
};

export default connect(createStructuredSelector({
  contents: getFooterContents,
}))(Footer);
