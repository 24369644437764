import fetch from 'isomorphic-fetch';
import appendQuery from './append-query';

export default function envelopedFetch(url, options) {
  const {
    method = 'GET',
    headers,
    body,
    ...otherOptions
  } = options;

  const newUrl = appendQuery(url, { envelope: 'request' });
  const newBody = JSON.stringify({
    method,
    headers,
    body: body != null ? JSON.parse(body) : undefined,
  });

  return fetch(newUrl, {
    ...otherOptions,
    method: 'POST',
    body: newBody,
  });
}
