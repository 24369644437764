import type { CheckoutBundlesPostOrdersByOrgResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import updateOrderNumberInUrl from '../../../utilities/update-order-number-in-urls';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CheckoutBundlesPostOrdersByOrgResponse>>;

type FetchCheckoutBundle = {
  feature_q?: string;
}

interface Parameters {
  organizationId: string;
  orderNumber: string;
  params: FetchCheckoutBundle;
}

const fetchCheckoutBundleRequest = (
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.FETCH_CHECKOUT_BUNDLE_REQUEST,
  params: parameters,
});

const fetchCheckoutBundleFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.FETCH_CHECKOUT_BUNDLE_FAILURE,
  params: parameters,
  payload: error,
});

const fetchCheckoutBundleSuccess = (
  checkoutBundle: io.flow.internal.v0.models.CheckoutBundle,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS,
  params: parameters,
  payload: checkoutBundle,
});

const fetchCheckoutBundle = (
  organizationId: string,
  orderNumber: string,
  params: FetchCheckoutBundle = {},
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  const parameters: Parameters = {
    organizationId,
    orderNumber,
    params,
  };

  dispatch(fetchCheckoutBundleRequest(parameters));

  return extra.apiInternal.checkoutBundle.postOrdersByOrg({
    org: organizationId,
    body: {
      source_order_number: orderNumber,
      feature_q: params.feature_q,
    },
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 201:
          dispatch(fetchCheckoutBundleSuccess(response.result, parameters));
          // if order number different the server couldnt find valid session
          // so creates anonymous order hence diff order number
          if (response.result.order.number !== orderNumber) {
            updateOrderNumberInUrl(orderNumber, response.result.order.number);
          }
          break;
        case 401:
        case 404:
        case 422:
          dispatch(fetchCheckoutBundleFailure(response.result, parameters));
          break;
        default:
          exhaustiveCheck(response);
      }

      return response;
    });
};

export default fetchCheckoutBundle;
