/* eslint-disable global-require */
import {
  getOrganization,
  getIntegration,
  getIsShopifyIntegration,
  getIsLocalDevelopment,
} from '../../store/flow/selectors';
import enableDebugMode from './enable-debug-mode';
import debug from './debug';

/**
 * Setup application for development mode
 */
export default function setup(state) {
  const organization = getOrganization(state);

  debug(`Integration: ${getIntegration(state)}`);

  enableDebugMode();

  // Simulate execution from Shopify store proxy
  // eg: add this to the URL: ?flow_integration=shopify&shop=flow-development-346.myshopify.com
  if (getIsLocalDevelopment(state) && getIsShopifyIntegration(state)) {
    debug('Local Shopify development enabled');

    const shopifyJsInit = require('./shopify-js-init').default;
    shopifyJsInit(organization);
  }
}
