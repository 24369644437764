import { connect } from 'react-redux';
import { getLineByNumber, getCheckoutItemContentByNumber } from '../store/checkout/selectors';

import OrderItem from '../components/order-item';
import { RootState } from '../store/types';

interface Props {
  number: string;
}

interface MappedProps {
  item: io.flow.v0.models.Line | undefined;
  content: io.flow.v0.models.CheckoutItemContent | undefined;
}

function mapStateToProps(state: RootState, props: Props): MappedProps {
  return {
    item: getLineByNumber(props.number)(state),
    content: getCheckoutItemContentByNumber(props.number)(state),
  };
}

export default connect(mapStateToProps)(OrderItem);
