import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import BemHelper from '@flowio/bem-helper';
import get from 'lodash/get';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import Section from '../section/section';

import { AddressType } from '../../constants/address-type';
import getCountryNameByCountryCode from '../../utilities/get-country-name-by-country-code';
import './mobile-address-summary.css';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

interface MobileAddressSummaryProps {
  countries: io.flow.v0.models.Country[];
  address: io.flow.v0.models.Address;
  editUrl: string;
  type: AddressType;
  vrn?: string;
}

const messageMap = {
  [AddressType.INVOICE]: (
    <FormattedMessage
      id="checkout_invoice_address_summary_heading"
      description="A title denoting a section of the checkout experience displaying captured shipping address information"
      defaultMessage="Invoice Address"
    />
  ),
  [AddressType.SHIPPING]: (
    <FormattedMessage
      id="checkout_shipping_address_summary_heading"
      description="A title denoting a section of the checkout experience displaying captured shipping address information"
      defaultMessage="Shipping Address"
    />
  ),
  [AddressType.BILLING]: (
    <FormattedMessage
      id="checkout_billing_address_summary_heading"
      defaultMessage="Billing Address"
      description="Title for the section containing a summary of the billing address information in the order"
    />
  ),
};

const bem = new BemHelper('mobile-address-summary');

const MobileAddressSummary: React.FC<MobileAddressSummaryProps> = ({
  countries,
  address,
  editUrl,
  type,
  vrn,
}): React.ReactElement => {
  const isInvoice = type === AddressType.INVOICE;
  const isBilling = type === AddressType.BILLING;
  const firstName = get(address, 'contact.name.first', '') || get(address, 'name.first', '');
  const lastName = get(address, 'contact.name.last', '') || get(address, 'name.last', '');
  const fullName = trim(`${firstName} ${lastName}`);
  const locality = get(address, 'city');
  const region = get(address, 'province');
  const streets = get(address, 'streets');
  const telephone = isBilling || isInvoice ? undefined : get(address, 'contact.phone');
  const country = isInvoice || isBilling ? getCountryNameByCountryCode(countries, get(address, 'country')) : `${getCountryNameByCountryCode(countries, get(address, 'country'))}, `;
  const company = isInvoice || isBilling ? get(address, 'company', '') : get(address, 'contact.company', '');
  const postalCode = get(address, 'postal');

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({
        isFeatureEnabled: isMobileUXSpacingEnabled,
      }): React.ReactElement => (
        <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
          <Section.Header className={bem.element('header')}>
            <Section.Title>
              {messageMap[type]}
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <div className={bem.element('content')} itemScope itemType="http://schema.org/Person">
              {isInvoice ? (
                <p className={bem.element('name')} itemProp="name">{company}</p>
              ) : (
                <p className={bem.element('name')} itemProp="name">{fullName}</p>
              )}
              <address
                className={bem.element('address')}
                itemScope
                itemType="http://schema.org/PostalAddress"
              >
                {!isInvoice && company && company.length > 0 && (
                  <span className={bem.element('company')} itemProp="companyName">
                    {company}
                  </span>
                )}
                {!isNil(streets) && (
                  <span className={bem.element('street')} itemProp="streetAddress">
                    {map(streets, (street, index) => (
                      <span key={index} className={bem.element('street-line')}>
                        {street}
                      </span>
                    ))}
                  </span>
                )}
                {!isNil(locality) && (
                  <span className={bem.element('locality')} itemProp="addressLocality">
                    {locality}
                  </span>
                )}
                {!isNil(region) && (
                  <span className={bem.element('region')} itemProp="addressRegion">
                    {region}
                  </span>
                )}
                {!isNil(postalCode) && (
                  <span className={bem.element('postal-code')} itemProp="addressRegion">
                    {postalCode}
                  </span>
                )}
                {!isNil(country) && (
                  <span className={bem.element('country')} itemProp="addressCountry">
                    {country}
                  </span>
                )}
                {!isNil(telephone) && (
                  <span className={bem.element('telephone')} itemProp="telephone">
                    {telephone}
                  </span>
                )}
              </address>
              {isInvoice && !isNil(vrn) && (
                <div>
                  <FormattedMessage
                    id="checkout_invoice_address_vat_label"
                    description="A label denoting the VAT number"
                    defaultMessage="VAT Number:"
                  />
                  <span className={bem.element('vrn')}>{vrn}</span>
                </div>
              )}
              {editUrl && (
                <Link className={bem.element('edit-link')} to={editUrl}>
                  <FormattedMessage
                    id="checkout_action_edit"
                    description="A text describing action to edit"
                    defaultMessage="Edit"
                  />
                </Link>
              )}
            </div>
          </Section.Content>
        </Section>
      )}
    />
  );
};

export default MobileAddressSummary;
