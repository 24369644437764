/**
 * Returns a promise that is resolved after the specified delay.
 */
export default function wait(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}
