import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import {
  allowInvoiceAddress, getCustomerAddressBookContacts, getDeviceDetails,
  getOrderDenormalized,
} from '../selectors';
import { billingAddressFromOrderAddress } from '../../../containers/payment-form/utilities';
import { CustomerType } from '../../../constants/customer-type';
import { getLanguage } from '../../intl/selectors';
import { toOrderAddress } from '../../../containers/customer-information-form/converters';
import actionTypes from '../../constants/action-types';
import addressFormValuesConverter from '../../../utilities/converters/address-form-values';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { ContactInfoFormValues } from './submit-customer-information';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';
import OrderAttributes from '../../../constants/order-attributes';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

interface GenerateNextOrderPutFormArgs {
  previousOrderPutForm: io.flow.v0.models.OrderPutForm;
  sendInvoiceAddress: boolean;
  formValues: ContactInfoFormValues;
  customerType?: 'individual' | 'business';
  addressBooks?: io.flow.v0.models.CustomerAddressBookContact[];
  deviceDetails? :io.flow.payment.v0.unions.DeviceDetails;
}

function coerceOptinPrompts(
  values: Record<string, boolean> = {},
): Record<string, string> {
  return Object.keys(values).reduce((previousValue, key) => ({
    ...previousValue,
    [key]: String(values[key]),
  }), {});
}

function getCustomerTaxIdAttribute(
  values: ContactInfoFormValues,
): Record<string, string> {
  return (
    values.country.toUpperCase() === 'MEX'
    && values.tax_id != null
  ) ? { [OrderAttributes.MEXICO_RFC]: values.tax_id } : {};
}

export function generateNextOrderPutForm({
  previousOrderPutForm,
  sendInvoiceAddress,
  formValues,
  customerType,
  addressBooks = [],
  deviceDetails,
}: GenerateNextOrderPutFormArgs): io.flow.v0.models.OrderPutForm {
  const {
    attributes: previousOrderAttributes,
    customer: previousOrderCustomerForm,
    destination: previousOrderAddress,
  } = (previousOrderPutForm);

  // More like previous order address converted to billing address...
  const previousShippingAddress = previousOrderAddress != null
    ? billingAddressFromOrderAddress(previousOrderAddress)
    : {};

  const previousInvoiceAddress = (
    previousOrderCustomerForm != null
    && previousOrderCustomerForm.invoice != null
    && previousOrderCustomerForm.invoice.address != null
  ) ? previousOrderCustomerForm.invoice.address : {};

  const customerNumber = previousOrderCustomerForm != null
    ? previousOrderCustomerForm.number
    : undefined;

  let newValues = formValues;

  if (formValues.addressToUse !== 'newAddress') {
    const selectedAddressBookContact = addressBooks.find((_) => _.id === formValues.addressToUse);

    if (selectedAddressBookContact != null) {
      newValues = {
        ...newValues,
        ...addressFormValuesConverter.fromCustomerAddressBookContact(selectedAddressBookContact),
      };
    }

    // We want to replace the organization name with the one given in the form (if present)
    if (formValues.organizationName != null) {
      newValues.organizationName = formValues.organizationName;
    }
  }

  if (customerNumber != null) {
    newValues.customerNumber = customerNumber;
  }

  const previousInvoiceKeys = previousInvoiceAddress != null
    ? Object.keys(previousInvoiceAddress)
    : [];

  /**
   * Carefully building `order_put_form` to avoid overwriting values not
   * managed by this section the application...
   */
  const nextOrderPutForm = {
    ...previousOrderPutForm,
    attributes: {
      ...previousOrderAttributes,
      ...coerceOptinPrompts(newValues.optinPrompts),
      ...getCustomerTaxIdAttribute(newValues),
    },
    device_details: deviceDetails,
    customer: {
      ...previousOrderCustomerForm,
      email: newValues.email,
      number: newValues.customerNumber,
    },
    destination: {
      ...previousOrderAddress,
      city: newValues.locality,
      country: newValues.country,
      postal: newValues.postalCode,
      province: newValues.administrativeArea,
      streets: compact([
        newValues.addressLine1,
        newValues.addressLine2,
      ]),
      contact: {
        ...(previousOrderAddress != null ? previousOrderAddress.contact : {}),
        company: newValues.organizationName,
        name: {
          first: newValues.firstName,
          last: newValues.lastName,
        },
        phone: newValues.phoneNumber,
      },
    },
  };

  if (sendInvoiceAddress) {
    // If its a customer type of individual we want to clear out the invoice address
    // Just in case they switched their type and an invoice address already exists
    if (customerType === CustomerType.INDIVIDUAL && nextOrderPutForm.customer != null) {
      delete nextOrderPutForm.customer.invoice;
    }

    // If an invoice address already exists we do not want to override it
    // But also if its the same as the last destination they probably want the same
    // address for their invoice address. Because of how we have to have an invoice address
    // for the tax rate to update, the invoice address won't be 'empty', it will only
    // have these two keys if it does only have those keys then we want to copy
    // over the shipping address
    if (customerType === CustomerType.BUSINESS
      && (!previousInvoiceAddress || isEqual(previousShippingAddress, previousInvoiceAddress) || difference(previousInvoiceKeys, ['company', 'country']).length === 0)) {
      const invoiceAddress = billingAddressFromOrderAddress(toOrderAddress(newValues));
      nextOrderPutForm.customer.invoice = {
        address: invoiceAddress,
      };
    }
  }

  return nextOrderPutForm;
}

const updateShippingAddressRequest = (
  organizationId: string,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_ADDRESS_REQUEST,
  params: {
    organization: organizationId,
  },
});

const updateShippingAddressSuccess = (
  organizationId: string,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
  payload: orderBuilder,
  params: {
    organization: organizationId,
  },
});

const updateShippingAddressFailure = (
  organizationId: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
  params: {
    organization: organizationId,
  },
});

/**
 * Creates an action responsible for updating the order address and customer
 * with the values from the customer information form.
 */
const updateShippingAddress = (
  values: ContactInfoFormValues,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const {
    customerType,
    orderNumber,
    organizationId,
  } = values;
  const state = getState();
  const deviceDetails = getDeviceDetails(state);

  const customerAddressBookContacts = getCustomerAddressBookContacts(getState());
  const isInvoiceAddressEnabled = allowInvoiceAddress(getState());
  const order = getOrderDenormalized(getState());
  const orderPutForm = orderPutFormConverter.fromOrder(order);

  dispatch(updateShippingAddressRequest(organizationId));

  return extra.api.orderBuilders.putByNumber({
    body: generateNextOrderPutForm({
      addressBooks: customerAddressBookContacts,
      customerType,
      formValues: values,
      previousOrderPutForm: orderPutForm,
      sendInvoiceAddress: isInvoiceAddressEnabled,
      deviceDetails,
    }),
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateShippingAddressSuccess(organizationId, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateShippingAddressFailure(organizationId, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateShippingAddress;
