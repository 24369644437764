/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import SvgIcon from '../svg-icon';

const Window = ({ ...unhandledProps }) => (
  <SvgIcon {...unhandledProps} viewBox="0 0 90 70">
    <path d="M14,6.6 C14,7.4 13.3,8.1 12.5,8.1 C11.7,8.1 11,7.4 11,6.6 C11,5.8 11.7,5.1 12.5,5.1 C13.4,5.1 14,5.8 14,6.6 Z M7.5,5.1 C6.7,5.1 6,5.8 6,6.6 C6,7.4 6.7,8.1 7.5,8.1 C8.3,8.1 9,7.4 9,6.6 C9,5.8 8.4,5.1 7.5,5.1 Z M90,6 L90,64 C90,67.3 87.3,70 84,70 L6,70 C2.7,70 0,67.3 0,64 L0,6 C0,2.7 2.7,0 6,0 L84,0 C87.3,0 90,2.7 90,6 Z M88,13.1 L2,13.1 L2,64 C2,66.2 3.8,68 6,68 L84,68 C86.2,68 88,66.2 88,64 L88,13.1 Z M88,6 C88,3.8 86.2,2 84,2 L6,2 C3.8,2 2,3.8 2,6 L2,11.1 L88,11.1 L88,6 Z M17.5,5.1 C16.7,5.1 16,5.8 16,6.6 C16,7.4 16.7,8.1 17.5,8.1 C18.3,8.1 19,7.4 19,6.6 C19,5.8 18.4,5.1 17.5,5.1 Z" />
  </SvgIcon>
);

Window.displayName = 'Window';

export default Window;
