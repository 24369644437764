import isLocalhost from '../is-localhost';
import { productionSnippet, sandboxSnippet } from './snippet';

interface ForterEvent extends Event {
  detail: string;
}

export function loadForter(checkoutOrg: io.flow.v0.models.OrganizationSummary | undefined) {
  let scriptSnippet = sandboxSnippet;
  if (
    process.env.NODE_ENV === 'production'
    // Check that we are not in localhost to avoid sending data to
    // production account from CI servers or when testing the
    // production build locally.
    && !isLocalhost(window.location.hostname)
    && checkoutOrg?.environment === 'production'
  ) {
    scriptSnippet = productionSnippet;
  }

  const scriptElement = document.createElement('script');
  scriptElement.appendChild(document.createTextNode(scriptSnippet));
  document.body.appendChild(scriptElement);
}

export const listenForForterToken = (
  onForterToken: (token: string) => void,
): void => {
  document.addEventListener('ftr:tokenReady', (evt) => {
    const token = (<ForterEvent>evt).detail;
    onForterToken(token);
  });
};
