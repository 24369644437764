import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import ReadyState from '../../../utilities/redux/ready-state';
import type { AppReducer } from '../../types';
import type { ContentStateStatuses } from '../types';

const initialState: ContentStateStatuses = {
  content: ReadyState.Pending,
};

const statusesReducer: AppReducer<ContentStateStatuses> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENT_REQUEST:
      return update(state, {
        content: { $set: ReadyState.Loading },
      });
    case actionTypes.FETCH_CONTENT_SUCCESS:
      return update(state, {
        content: { $set: ReadyState.Fulfilled },
      });
    case actionTypes.FETCH_CONTENT_FAILURE:
      return update(state, {
        content: { $set: ReadyState.Rejected },
      });
    default:
      return state;
  }
};

export default statusesReducer;
