import type { AuthorizationsGetByKeyResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<AuthorizationsGetByKeyResponse>>;

const fetchAuthorizationRequest = (
  organizationId: string,
  authorizationKey: string,
): RootAction => ({
  type: actionTypes.FETCH_AUTHORIZATION_REQUEST,
  organizationId,
  authorizationKey,
});

const fetchAuthorizationSuccess = (
  organizationId: string,
  authorizationKey: string,
  authorization: io.flow.v0.unions.Authorization,
): RootAction => ({
  type: actionTypes.FETCH_AUTHORIZATION_SUCCESS,
  organizationId,
  authorizationKey,
  payload: authorization,
});

const fetchAuthorizationFailure = (
  organizationId: string,
  authorizationKey: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_AUTHORIZATION_FAILURE,
  organizationId,
  authorizationKey,
  payload: error,
});

const fetchAuthorization = (
  organizationId: string,
  authorizationKey: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(fetchAuthorizationRequest(organizationId, authorizationKey));
  return extra.api.authorizations.getByKey({
    key: authorizationKey,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchAuthorizationSuccess(organizationId, authorizationKey, response.result));
          break;
        case 401:
        case 404:
          dispatch(fetchAuthorizationFailure(organizationId, authorizationKey, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchAuthorization;
