import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid, Row, Column } from '../grid';
import Button from '../button';
import ChevronLeft from '../svg-icons/chevron-left';
import ContentItem from '../content-item';
import GenericError from '../generic-error/generic-error';
import Prompt from '../prompt/prompt';
import optinPromptPropTypes from '../../utilities/prop-types/optin-prompt-prop-types';
import FeatureToggle from '../feature-toggle';
import { OPTIMIZE_MOBILE_CTAS } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./no-payment-required.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('no-payment-required');

const NoPaymentRequired = ({
  error,
  handleSubmit,
  optinPrompts,
  privacyPolicy,
  shippingMethodUrl,
  submitting,
  terms,
}) => (
  <form noValidate className={bem.block()} onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="orderNumber" />
    <Grid>
      <Row>
        <Column xs={12}>
          <div className={bem.element('content')}>
            <p className={bem.element('message')}>
              <FormattedMessage
                id="checkout_payment_method_unnecessary"
                defaultMessage="No payment is required for your order."
                description="Text describing that a payment is unnecessary for a particular order."
              />
            </p>
          </div>
        </Column>
      </Row>
      {optinPrompts != null && optinPrompts.length > 0 && (
        <Row>
          <Column xs={12}>
            <FormSection className={bem.element('prompt-group')} name="optinPrompts">
              {optinPrompts
                .filter((optinPrompt) => (
                  optinPrompt.display == null || optinPrompt.display.display_position === 'submission'
                ))
                .map((optinPrompt) => (
                  <Prompt key={optinPrompt.id} prompt={optinPrompt} />
                ))}
            </FormSection>
          </Column>
        </Row>
      )}
      {error && (
        <Row>
          <Column xs={12}>
            <GenericError error={error} />
          </Column>
        </Row>
      )}
      <Row>
        <Column xs={12} md={6} mdPush={6}>
          <Button
            className={bem.element('continue-button')}
            color="positive"
            disabled={submitting}
            fluid
          >
            <FormattedMessage
              id="checkout_complete_free_order"
              defaultMessage="Complete Order"
              description="Text describing action to complete order that requires no payment information"
            />
          </Button>
        </Column>
        <Column xs={12} md={6} mdPull={6}>
          <div className="hidden-sm-down">
            <Button
              as={Link}
              automationId="return-to-shipping-method"
              className={bem.element('return-button')}
              disabled={submitting}
              flat
              fluid
              icon={(<ChevronLeft />)}
              text={(
                <FormattedMessage
                  id="checkout_return_to_shipping_method"
                  defaultMessage="Return to Shipping Method"
                  description="A message indicating that interactions will transition customers to the shipping method step"
                />
              )}
              to={shippingMethodUrl}
            />
          </div>
          <FeatureToggle
            featureKey={OPTIMIZE_MOBILE_CTAS}
            render={({ isFeatureEnabled: optimizeMobileCtas }) => {
              if (optimizeMobileCtas) {
                return (
                  <div className="hidden-md-up">
                    <Button
                      as={Link}
                      automationId="return-to-shipping-method"
                      className={bem.element('return-button')}
                      disabled={submitting}
                      flat
                      fluid
                      to={shippingMethodUrl}
                      text={(
                        <FormattedMessage
                          id="checkout_return_to_shipping_method"
                          description="A message indicating that interactions will transition customers to the shipping method step"
                          defaultMessage="Return to Shipping Method"
                        />
                      )}
                    />
                  </div>
                );
              }
              return (
                <div className="hidden-md-up">
                  <Button
                    as={Link}
                    automationId="return-to-shipping-method"
                    className={bem.element('return-button')}
                    color="tertiary"
                    disabled={submitting}
                    fluid
                    to={shippingMethodUrl}
                  >
                    <FormattedMessage
                      id="checkout_return_to_shipping_method"
                      defaultMessage="Return to Shipping Method"
                      description="A message indicating that interactions will transition customers to the shipping method step"
                    />
                  </Button>
                </div>
              );
            }}
          />
        </Column>
      </Row>
      {privacyPolicy && terms && (
        <Row>
          <Column xs={12}>
            <p className={bem.element('disclaimer')}>
              <FormattedMessage
                id="checkout_payment_info_terms_acknowledgment"
                description="A message indicating to customers that they agree with the organization privacy policy and conditions"
                defaultMessage="By placing this order, I agree to the {privacyPolicy} and {termsOfUse}"
                values={{
                  privacyPolicy: <ContentItem content={privacyPolicy} />,
                  termsOfUse: <ContentItem content={terms} />,
                }}
              />
            </p>
          </Column>
        </Row>
      )}
    </Grid>
  </form>
);

NoPaymentRequired.displayName = 'NoPaymentRequired';

NoPaymentRequired.propTypes = {
  error: ApiPropTypes.genericError,
  handleSubmit: PropTypes.func.isRequired,
  optinPrompts: PropTypes.arrayOf(PropTypes.shape(optinPromptPropTypes)),
  privacyPolicy: ApiInternalPropTypes.contentItem.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  terms: ApiInternalPropTypes.contentItem.isRequired,
};

NoPaymentRequired.defaultProps = {
  error: undefined,
  optinPrompts: [],
};

export default NoPaymentRequired;
