import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function setFeatureValue(
  featureKey: string,
  featureValue: boolean,
): RootAction {
  return {
    type: actionTypes.SET_FEATURE_VALUE,
    payload: {
      featureKey,
      featureValue,
    },
  };
}
