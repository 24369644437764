/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import noop from 'lodash/noop';

import Button from '../button';
import Close from '../svg-icons/close';
import MessageHeader from './message-header';
import MessageText from './message-text';

if (process.browser) {
  require('./message.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('message');

const Message = (props) => {
  const {
    children,
    className,
    color,
    dismissible,
    header,
    onRequestDismiss,
    text,
  } = props;

  const unhandledProps = getUnhandledProps(Message, props);
  const ElementType = getElementType(Message, props);

  const modifiers = {
    [color]: true,
    dismissible,
  };

  if (children != null) {
    return (
      <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
        {children}
      </ElementType>
    );
  }

  return (
    <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
      <MessageHeader text={header} />
      <MessageText text={text} />
      {dismissible && (
        <Button icon flat className={bem.element('dismiss')} onClick={onRequestDismiss}>
          <Close />
        </Button>
      )}
    </ElementType>
  );
};

Message.Header = MessageHeader;

Message.Text = MessageText;

Message.colors = ['default', 'negative', 'info', 'positive', 'warning'];

Message.displayName = 'Message';

Message.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(Message.colors),
  dismissible: PropTypes.bool,
  header: PropTypes.string,
  onRequestDismiss: PropTypes.func,
  text: PropTypes.string,
};

Message.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  color: 'default',
  dismissible: false,
  header: undefined,
  onRequestDismiss: noop,
  text: undefined,
};

export default Message;
