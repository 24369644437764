import type { ExperiencePriceConversionsGetResponse } from '@flowio/api-sdk';
import {
  getOrderTaxAndDutyPrices,
  getOrderExperience,
  getOrderTotalCurrency,
  isFeatureEnabled,
} from '../selectors';
import { getOrganization } from '../../flow/selectors';
import * as featureKeys from '../../../../common/constants/feature-keys';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<ExperiencePriceConversionsGetResponse> | void>;

const fetchPriceConversionRequest = (): RootAction => ({
  type: actionTypes.FETCH_PRICE_CONVERSION_REQUEST,
});

const fetchPriceConversionSuccess = (
  priceConversionResponse: io.flow.v0.models.ExperiencePriceConversionResponse,
): RootAction => ({
  type: actionTypes.FETCH_PRICE_CONVERSION_SUCCESS,
  payload: priceConversionResponse,
});

const fetchPriceConversionFailure = (
  error: io.flow.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_PRICE_CONVERSION_FAILURE,
  payload: error,
});

const fetchPriceConversion = (): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (!isFeatureEnabled(featureKeys.DUTY_TAX_SINGLE_LINE)(getState())) {
    return Promise.resolve();
  }

  const organizationId = getOrganization(getState());
  const experience = getOrderExperience(getState());
  const currency = getOrderTotalCurrency(getState());
  const prices = getOrderTaxAndDutyPrices(getState());
  const amount = prices.reduce((sum, price) => sum + price.amount, 0).toFixed(2);

  if (experience == null) {
    extra.rollbar.warn('Cannot convert price without an experience');
    return Promise.resolve();
  }

  dispatch(fetchPriceConversionRequest());
  return extra.api.experiencePriceConversions.get({
    experience_key: experience.key,
    organization: organizationId,
    amount: [amount],
    base: currency,
    local: currency,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchPriceConversionSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(fetchPriceConversionFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchPriceConversion;
