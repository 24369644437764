import React from 'react';

import { AddressType } from './types';
import { Grid, Row, Column } from '../grid';
import { isFieldRequired } from '../../utilities/address-utilities';
import { ADDRESS_POSTAL_AUTOCOMPLETE } from '../../../common/constants/feature-keys';
import AddressLine1 from './address-line1';
import AddressLine2 from './address-line2';
import AdministrativeArea from './administrative-area';
import CountryField from './country';
import FeatureToggle from '../feature-toggle';
import FirstName from './first-name';
import LastName from './last-name';
import Locality from './locality';
import OrganizationName from './organization-name';
import PhoneNumber from './phone-number';
import PostalAutoComplete from './postal-auto-complete';
import PostalCode from './postal-code';

type Props = {
  addressConfiguration: io.flow.v0.models.AddressConfiguration;
  addressType?: AddressType;
  countries: io.flow.v0.models.Country[];
  countryCode: string;
  locale: string;
  onAutoCompleteAddress?: (place: unknown, addressType?: AddressType) => void;
  onPostalAutoComplete?: (address: io.flow.v0.models.Address) => void;
};

const defaultFormat: io.flow.v0.models.AddressConfigurationFormat[] = [
  { placements: [{ name: 'first_name' }, { name: 'last_name' }] },
  { placements: [{ name: 'company' }] },
  { placements: [{ name: 'street_1' }] },
  { placements: [{ name: 'street_2' }] },
  { placements: [{ name: 'country' }] },
  { placements: [{ name: 'city' }, { name: 'province' }] },
  { placements: [{ name: 'postal' }, { name: 'phone' }] },
];

const FormattedAddressFields: React.FunctionComponent<Props> = ({
  addressConfiguration,
  addressType,
  countries,
  countryCode,
  locale,
  onAutoCompleteAddress,
  onPostalAutoComplete,
}) => {
  const {
    formats = defaultFormat,
  } = addressConfiguration;
  return (
    <Grid>
      {formats.map(({ placements }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Row key={index}>
          {placements.map((placement) => (
            <Column
              key={placement.name}
              xs={Math.floor(12 / placements.length)}
            >
              {((): React.ReactElement | null => {
                switch (placement.name) {
                  case 'city':
                    return (
                      <Locality
                        addressType={addressType}
                        required={isFieldRequired('locality', addressConfiguration)}
                      />
                    );
                  case 'company':
                    return (
                      <OrganizationName
                        addressType={addressType}
                      />
                    );
                  case 'country':
                    return (
                      <CountryField
                        addressType={addressType}
                        options={countries}
                        required
                      />
                    );
                  case 'first_name':
                    return (
                      <FirstName
                        addressType={addressType}
                        required={isFieldRequired('firstName', addressConfiguration)}
                      />
                    );
                  case 'last_name':
                    return (
                      <LastName
                        addressType={addressType}
                        required={isFieldRequired('lastName', addressConfiguration)}
                      />
                    );
                  case 'phone':
                    return (
                      <PhoneNumber
                        addressType={addressType}
                        required={isFieldRequired('phoneNumber', addressConfiguration)}
                      />
                    );
                  case 'postal':
                    return (
                      <FeatureToggle
                        featureKey={ADDRESS_POSTAL_AUTOCOMPLETE}
                        render={({
                          isFeatureEnabled: isAddressPostalAutoCompleteEnabled,
                        }): React.ReactElement => {
                          if (isAddressPostalAutoCompleteEnabled) {
                            return (
                              <PostalAutoComplete
                                addressType={addressType}
                                countryCode={countryCode}
                                onSelection={onPostalAutoComplete}
                                required={isFieldRequired('postalCode', addressConfiguration)}
                              />
                            );
                          }

                          return (
                            <PostalCode
                              addressType={addressType}
                              countryCode={countryCode}
                              required={isFieldRequired('postalCode', addressConfiguration)}
                            />
                          );
                        }}
                      />
                    );
                  case 'province':
                    return (
                      <AdministrativeArea
                        addressType={addressType}
                        countryCode={countryCode}
                        locale={locale}
                        provinces={addressConfiguration.provinces}
                        required={isFieldRequired('administrativeArea', addressConfiguration)}
                      />
                    );
                  case 'street_1':
                    return (
                      <AddressLine1
                        addressType={addressType}
                        countries={countries}
                        countryCode={countryCode}
                        locale={locale}
                        onAutoCompleteAddress={onAutoCompleteAddress}
                        required={isFieldRequired('addressLine1', addressConfiguration)}
                      />
                    );
                  case 'street_2':
                    return (
                      <AddressLine2
                        addressType={addressType}
                        required={isFieldRequired('addressLine2', addressConfiguration)}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Column>
          ))}
        </Row>
      ))}
    </Grid>
  );
};

export default FormattedAddressFields;
