import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./dialog-header.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('dialog-header');

const DialogHeader = ({ children, dividing, text }) => (
  <div className={bem.block({ dividing })}>
    <h1 className={bem.element('text')}>
      {children != null ? children : text}
    </h1>
  </div>
);

DialogHeader.displayName = 'DialogHeader';

DialogHeader.propTypes = {
  children: PropTypes.node,
  dividing: PropTypes.bool,
  text: PropTypes.node,
};

DialogHeader.defaultProps = {
  children: undefined,
  dividing: false,
  text: undefined,
};

export default DialogHeader;
