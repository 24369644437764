import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';

import {
  getCustomerAddressBook,
  getStatuses,
  getOrder,
  getOrderNumber,
  getReturningCustomer,
  getIsReturningCustomersEnabled,
  getAddressConfigurationOrUseDefaultByCountryCode,
  isVrnVisible,
} from '../../store/checkout/selectors';
import { getOrganization } from '../../store/flow/selectors';
import { getShopifyCustomer } from '../../store/shopify/selectors';
import { getAdministrativeDivisionsByCountryCode } from '../../store/reference/selectors';
import { getOptinPrompts } from '../../store/optin';
import FormName from '../../constants/form-name';
import { CustomerType } from '../../constants/customer-type';
import mapOptinPromptsToValuePairs from '../../utilities/map-optin-prompts-to-value-pairs';

import addressFormValuesConverter from '../../utilities/converters/address-form-values';
import { hasContacts, getPreferredShippingContact, getContactById } from '../../utilities/address-book-utilities';
import { ContactInfoFormValues } from '../../store/checkout/types';
import { RootState } from '../../store/types';
import { removeUnnecessaryFieldValidationRules } from '../../utilities/address-utilities';
import isNotNil from '../../utilities/is-not-nil';

const getValueOfField = formValueSelector(FormName.CUSTOMER_INFORMATION);

export const getInitialContactInfoFormValues = createSelector(
  getIsReturningCustomersEnabled,
  getOrder,
  getOrderNumber,
  getOrganization,
  getOptinPrompts,
  getShopifyCustomer,
  getReturningCustomer,
  getCustomerAddressBook,
  (
    isReturningCustomersEnabled,
    order,
    orderNumber,
    organizationId,
    optinPrompts,
    shopifyCustomer,
    customer,
    addressBook,
  ) => {
    const initialValues: Partial<ContactInfoFormValues> = {};

    let selectedContact: io.flow.v0.models.CustomerAddressBookContact | undefined;

    initialValues.orderNumber = orderNumber;
    initialValues.organizationId = organizationId;
    initialValues.saveCustomerInfo = false;

    if (isNil(order.customer.invoice) || isNil(order.customer.invoice.address)) {
      initialValues.customerType = CustomerType.INDIVIDUAL;
    } else {
      initialValues.customerType = CustomerType.BUSINESS;
    }

    // Set initial opt-in prompts
    if (!isNil(optinPrompts)) {
      initialValues.optinPrompts = fromPairs(mapOptinPromptsToValuePairs(optinPrompts, order));
    }

    // Only option is to enter a new address when feature is disabled or
    // address book is empty.
    if (!isReturningCustomersEnabled || isNil(addressBook) || !hasContacts(addressBook)) {
      initialValues.addressToUse = 'newAddress';
    // Select the preferred shipping address book contact when order does not
    // have a shipping address, or prompt for a new address when a preferred
    // shipping address book contact does not exist.
    } else if (isNil(order.destination.streets) || isEmpty(order.destination.streets)) {
      selectedContact = getPreferredShippingContact(addressBook);
      initialValues.addressToUse = isNil(selectedContact) ? 'newAddress' : selectedContact.id;
    // Determine whether existing shipping address is one of the saved address
    // book contact or a new address.
    } else {
      selectedContact = addressBook.contacts.find((contact) => {
        const valuesInOrder = addressFormValuesConverter.fromOrderAddress(order.destination);
        const valuesInContact = addressFormValuesConverter.fromCustomerAddressBookContact(contact);
        return isEqual(valuesInOrder, valuesInContact);
      });
      initialValues.addressToUse = isNil(selectedContact) ? 'newAddress' : selectedContact.id;
    }

    if (!isNil(order.customer.number)) {
      initialValues.customerNumber = order.customer.number;
    } else if (!isNil(customer) && !isNil(customer.number)) {
      initialValues.customerNumber = customer.number;
    }

    if (!isNil(order.customer.email)) {
      initialValues.email = order.customer.email;
    } else if (!isNil(customer) && !isNil(customer.email)) {
      initialValues.email = customer.email;
    } else if (!isNil(shopifyCustomer)) {
      initialValues.email = shopifyCustomer.email;
    }

    // Set initial VAT registration number (VRN)
    if (!isNil(order.tax_registration)) {
      initialValues.vrn = order.tax_registration.number;
    }

    if (isNil(selectedContact)) {
      initialValues.administrativeArea = order.destination.province;
      initialValues.country = order.destination.country;
      initialValues.locality = order.destination.city;
      initialValues.postalCode = order.destination.postal;

      if (!isNil(order.destination.contact)) {
        initialValues.firstName = order.destination.contact.name.first;
        initialValues.lastName = order.destination.contact.name.last;
        initialValues.phoneNumber = order.destination.contact.phone;
        initialValues.organizationName = order.destination.contact.company;
      }

      if (!isNil(order.destination.streets)) {
        [initialValues.addressLine1, initialValues.addressLine2] = order.destination.streets;
      }
    }

    return initialValues;
  },
);

export const getIsFirstTimeVisitor = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'isFirstTimeVisitor'),
);

export function getCountryFieldValue(
  state: RootState,
): string | undefined {
  const addressBook = getCustomerAddressBook(state);
  const initialValues = getInitialContactInfoFormValues(state);

  let addressToUse = getValueOfField(state, 'addressToUse');

  if (addressToUse == null) {
    // eslint-disable-next-line prefer-destructuring
    addressToUse = initialValues.addressToUse;
  }

  if (addressToUse === 'newAddress') {
    return defaultTo(
      getValueOfField(state, 'country'),
      initialValues.country,
    );
  }

  let contact;

  if (addressBook != null) {
    contact = getContactById(addressBook, addressToUse);
  }

  return contact != null ? contact.address.country : undefined;
}

/**
 * Returns the administrative division options based on the selected country.
 * An empty array is returned if no administrative divisions match the selected country.
 */
export function getAdministrativeDivisionOptions(
  state: RootState,
): io.flow.v0.models.Province[] {
  const countryCode = getCountryFieldValue(state);
  return countryCode != null
    ? getAdministrativeDivisionsByCountryCode(countryCode)(state)
    : [];
}

export function getShippingAddressConfiguration(
  state: RootState,
): io.flow.v0.models.AddressConfiguration {
  let countryCode = getCountryFieldValue(state);

  // Hack to satisfy typescript because models from which a country code
  // is derived have an optional country field, however a country should always
  // be available because it is inferred by geolocating customers...
  if (countryCode == null) {
    countryCode = '';
  }

  const addressConfiguration = getAddressConfigurationOrUseDefaultByCountryCode(countryCode)(state);

  return removeUnnecessaryFieldValidationRules(addressConfiguration, isVrnVisible(state) ? ['vat_registration_number'] : []);
}

export function getShouldCollectCustomerTaxId(
  state: RootState,
): boolean {
  const collectTaxIdCountries = [
    'MEX',
  ];

  const customerCountry = getCountryFieldValue(state)?.toUpperCase();

  if (customerCountry == null) {
    return false;
  }

  if (collectTaxIdCountries.includes(customerCountry)) {
    return true;
  }
  return false;
}
