import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import Section from '../section';
import PaymentReadableName from '../../constants/payment-readable-name';
import { formatSingleLineCardSummaryLabel, formatSingleLinePaymentSourceLabel } from '../../utilities/payment-source-utilities';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./payment-method-review-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-method-review-summary');

const PaymentMethodReviewSummary = ({
  paymentMethodId,
  paymentSources,
  card,
  editUrl,
}) => {
  let description;

  const selectedPaymentSource = paymentSources.find((paymentSource) => (
    paymentSource.id === paymentMethodId
  ));

  if (card != null) {
    description = formatSingleLineCardSummaryLabel(card);
  } else if (selectedPaymentSource != null) {
    description = formatSingleLinePaymentSourceLabel(selectedPaymentSource);
  } else {
    description = PaymentReadableName[paymentMethodId];
  }

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
        <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
          <Section.Header>
            <Section.Title>
              <FormattedMessage
                id="checkout_payment_method_subheading"
                defaultMessage="Payment Method"
                description="Title for the section containing a summary of the payment methods in the order"
              />
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <div className={bem.element('content')}>
              <ul className={bem.element('list')}>
                {description}
              </ul>
              {editUrl && (
                <Link className={bem.element('edit-link')} to={editUrl}>
                  <FormattedMessage
                    id="checkout_action_edit"
                    description="A text describing action to edit"
                    defaultMessage="Edit"
                  />
                </Link>
              )}
            </div>
          </Section.Content>
        </Section>
      )}
    />
  );
};

PaymentMethodReviewSummary.displayName = 'PaymentMethodReviewSummary';

PaymentMethodReviewSummary.propTypes = {
  paymentMethodId: PropTypes.string.isRequired,
  paymentSources: PropTypes.arrayOf(ApiPropTypes.paymentSource),
  card: ApiPropTypes.card,
  editUrl: PropTypes.string,
};

PaymentMethodReviewSummary.defaultProps = {
  paymentSources: [],
  card: undefined,
  editUrl: undefined,
};

export default PaymentMethodReviewSummary;
