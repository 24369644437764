/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

const bem = new BemHelper('message');

class MessageText extends PureComponent {
  render() {
    const { children, className, text } = this.props;
    const ElementType = getElementType(MessageText, this.props);
    const unhandledProps = getUnhandledProps(MessageText, this.props);

    if (children != null) {
      return (
        <ElementType {...unhandledProps} className={bem.element('text', className)}>
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType {...unhandledProps} className={bem.element('text', className)}>
        {text}
      </ElementType>
    );
  }
}

MessageText.displayName = 'MessageText';

MessageText.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

MessageText.defaultProps = {
  as: 'p',
  children: undefined,
  className: '',
  text: undefined,
};

export default MessageText;
