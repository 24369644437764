/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

import Column from './column';
import Row from './row';

if (process.browser) {
  require('./grid.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('flow-grid');

const Grid = (props) => {
  const {
    children,
    className,
    container,
    parent,
  } = props;
  const ElementType = getElementType(Grid, props);
  const unhandledProps = getUnhandledProps(Grid, props);
  const modifiers = { container, parent };

  return (
    <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
      {children}
    </ElementType>
  );
};

Grid.displayName = 'Grid';

Grid.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.bool,
  parent: PropTypes.bool,
};

Grid.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  container: false,
  parent: false,
};

Grid.Column = Column;
Grid.Row = Row;

export default Grid;
