import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { automationToken } from './utilities';
import bem from './bem';
import Checkbox from '../redux-form/checkbox';

type Props = {
  addressType?: AddressType;
};

const SaveAddress: React.FunctionComponent<Props> = ({
  addressType,
}) => (
  <Field
    className={bem.element('save-info')}
    automationId={automationToken('save-address', addressType)}
    component={Checkbox}
    labelText={(
      <FormattedMessage
        id="checkout_customer_information_field_save_customer"
        description="A label denoting that they can save their contact information"
        defaultMessage="Remember my information for faster checkout in the future"
      />
    )}
    name="saveCustomerInfo"
    type="checkbox"
  />
);

export default SaveAddress;
