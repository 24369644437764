import OrderBundleError from './order-bundle-error';

/**
 * Check the order bundle API response for errors. Instead of returning
 * a 422 when errors are present the `order_bundle` resource returns an
 * object containing both the order and any errors from the request as 2xx.
 * @param {any} response - Response object from `@flowio/node-sdk` package.
 */
export default function checkOrderBundleStatus(response) {
  if (response.result != null
    && response.result.errors != null
    && response.result.errors.length > 0) {
    throw new OrderBundleError(response);
  }

  return response;
}
