import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { getOptinPrompts } from '../store/optin';
import {
  fetchApplePayAvailibility,
  fetchCheckout,
  redirectSubmittedOrder,
} from '../store/checkout/actions';
import {
  getIsPaymentRequired,
  getPublicKey,
  isGiftCardEnabled,
  isLoyaltyEnabled,
  getOrderDestination,
  getSelectedDeliveryOptions,
  isPaymentInfoSummaryEnabled,
  getIsAdyen3dsDialogOpen,
  getIsInlineAuthorizationDialogOpen,
  getCheckoutOrganization,
  getIsApplepayEnabled,
} from '../store/checkout/selectors';
import getFeatureKeys from '../utilities/get-feature-keys';
import withExternalEvents from '../components/with-external-events';
import PaymentInfoStep from '../components/payment-info-step';
import { getCountries } from '../store/reference/selectors';
import { getCheckoutContactInfoUrl, getCheckoutShippingMethodUrl } from '../store/navigation/selectors';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import { fetchCountries } from '../store/reference/actions';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { orderNumber, checkoutId } = params;
  return Promise.all([
    dispatch(fetchCheckout(checkoutId, { feature_key: getFeatureKeys() })),
    dispatch(fetchCountries()),
  ])
    .then(() => Promise.all([
      getIsApplepayEnabled(getState()) ? dispatch(fetchApplePayAvailibility()) : Promise.resolve(),
      dispatch(redirectSubmittedOrder()),
    ]))
    .catch((error) => {
      logInternalServerError(error, orderNumber);
      throw error;
    });
};

const mapStateToProps = (state) => ({
  contactInfoUrl: getCheckoutContactInfoUrl(state),
  shippingMethodUrl: getCheckoutShippingMethodUrl(state),
  destination: getOrderDestination(state),
  selectedDeliveryOptions: getSelectedDeliveryOptions(state),
  isGiftCardEnabled: isGiftCardEnabled(state),
  isLoyaltyEnabled: isLoyaltyEnabled(state),
  countries: getCountries(state),
  isPaymentRequired: getIsPaymentRequired(state),
  optinPrompts: getOptinPrompts(state),
  organizationSummary: getCheckoutOrganization(state),
  publicKey: getPublicKey(state),
  isPaymentInfoSummaryEnabled: isPaymentInfoSummaryEnabled(state),
  isAdyen3dsDialogOpen: getIsAdyen3dsDialogOpen(state),
  isInlineAuthorizationDialogOpen: getIsInlineAuthorizationDialogOpen(state),
  useCheckoutsSubmission: true,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(PaymentInfoStep);
