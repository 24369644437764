export type AddressType =
  | 'billing'
  | 'invoice'
  | 'shipping';

export interface AddressTypeEnum {
  BILLING: 'billing';
  INVOICE: 'invoice';
  SHIPPING: 'shipping';
}

export const AddressType: AddressTypeEnum = {
  BILLING: 'billing',
  INVOICE: 'invoice',
  SHIPPING: 'shipping',
};
