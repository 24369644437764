/* eslint-disable jsx-a11y/anchor-is-valid */

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';

import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import { AddressType } from '../../constants/address-type';
import PostalAddress from '../postal-address';
import Section from '../section';
import getCountryNameByCountryCode from '../../utilities/get-country-name-by-country-code';

if (process.browser) {
  require('./address-summary.css'); // eslint-disable-line global-require
}

const messageMap = {
  invoice: (
    <FormattedMessage
      id="checkout_invoice_address_summary_heading"
      description="A title denoting a section of the checkout experience displaying captured shipping address information"
      defaultMessage="Invoice Address"
    />
  ),
  shipping: (
    <FormattedMessage
      id="checkout_shipping_address_summary_heading"
      description="A title denoting a section of the checkout experience displaying captured shipping address information"
      defaultMessage="Shipping Address"
    />
  ),
  billing: (
    <FormattedMessage
      id="checkout_billing_address_summary_heading"
      defaultMessage="Billing Address"
      description="Title for the section containing a summary of the billing address information in the order"
    />
  ),
};

const bem = new BemHelper('address-summary');

const AddressSummary = ({
  countries,
  address,
  editUrl,
  type,
  vrn,
}) => {
  const isInvoice = type === AddressType.INVOICE;
  const firstName = get(address, 'contact.name.first', '') || get(address, 'name.first', '');
  const lastName = get(address, 'contact.name.last', '') || get(address, 'name.last', '');
  const fullName = trim(`${firstName} ${lastName}`);
  const firstLine = isInvoice ? get(address, 'company', '') : fullName;
  const secondLine = isInvoice ? vrn : get(address, 'contact.company', '') || get(address, 'company', '');

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
        <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
          <Section.Header className={bem.element('header')}>
            <Section.Title>
              {messageMap[type]}
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <div className={bem.element('content')} itemScope itemType="http://schema.org/Person">
              <p className={bem.element(isInvoice ? 'tax-company' : 'name')} itemProp="name">{firstLine}</p>
              <p className={bem.element(isInvoice ? 'vrn' : 'company')} itemProp="affiliation">
                {isInvoice ? (
                  <>
                    {vrn && (
                      <div>
                        <FormattedMessage
                          id="checkout_invoice_address_vat_label"
                          description="A label denoting the VAT number"
                          defaultMessage="VAT Number:"
                        />
                        <span>{` ${secondLine}`}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <span>{`${secondLine}`}</span>
                )}
              </p>
              <PostalAddress
                className={bem.element('address')}
                itemProp="orderDelivery"
                country={getCountryNameByCountryCode(countries, get(address, 'country'))}
                locality={get(address, 'city')}
                postalCode={get(address, 'postal')}
                region={get(address, 'province')}
                streets={get(address, 'streets')}
                telephone={type === 'billing' ? undefined : get(address, 'contact.phone')}
              />
              {editUrl && (
                <Link className={bem.element('edit-link')} to={editUrl}>
                  <FormattedMessage
                    id="checkout_action_edit"
                    description="A text describing action to edit"
                    defaultMessage="Edit"
                  />
                </Link>
              )}
            </div>
          </Section.Content>
        </Section>
      )}
    />
  );
};

AddressSummary.displayName = 'AddressSummary';

AddressSummary.propTypes = {
  type: PropTypes.oneOf([
    AddressType.SHIPPING,
    AddressType.BILLING,
    AddressType.INVOICE,
  ]).isRequired,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  address: PropTypes.oneOfType([
    ApiPropTypes.orderAddress,
    ApiPropTypes.billingAddress,
  ]).isRequired,
  editUrl: PropTypes.string,
  vrn: PropTypes.string,
};

AddressSummary.defaultProps = {
  editUrl: undefined,
  vrn: undefined,
};

export default AddressSummary;
