import PropTypes from 'prop-types';
import ContentStatus from '../../constants/content-status';
import localizedContentElementPropTypes from './localized-content-element-prop-types';

const localizationPropTypes = {
  id: PropTypes.string.isRequired,
  content_id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    ContentStatus.ARCHIVED,
    ContentStatus.DRAFT,
    ContentStatus.LIVE,
  ]).isRequired,
  elements: PropTypes.objectOf(PropTypes.shape(localizedContentElementPropTypes)).isRequired,
};

export default localizationPropTypes;
