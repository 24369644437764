/* eslint-disable react/jsx-props-no-spreading */

import { BaseFieldProps, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { RETURNING_CUSTOMERS, MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import { AddressType } from '../../constants/address-type';
import { CustomerType } from '../../constants/customer-type';
import FeatureToggle from '../feature-toggle';
import { Grid, Row, Column } from '../grid';
import Address from '../../containers/address';
import Section from '../section';
import AddressPicker, { OwnProps as AddressPickerOwnProps } from '../address-picker';

interface OwnProps {
  addressBook?: io.flow.v0.models.CustomerAddressBook;
  addressConfiguration: io.flow.v0.models.AddressConfiguration;
  children?: never;
  companyName: string;
  countries: io.flow.v0.models.Country[];
  countryCode: string;
  customerType: CustomerType;
  locale: string;
  onAutoCompleteAddress: (place: unknown, addressType?: AddressType) => void;
  onPostalAutoComplete: (address: io.flow.v0.models.Address) => void;
  returningCustomer?: io.flow.v0.models.Customer;
  vrn: string;
}

const ShippingAddress: React.FunctionComponent<OwnProps> = ({
  addressBook,
  addressConfiguration,
  companyName,
  countries,
  countryCode,
  customerType,
  locale,
  onAutoCompleteAddress,
  onPostalAutoComplete,
  returningCustomer,
  vrn,
  ...unhandledProps
}) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({
      isFeatureEnabled: isMobileUXSpacingEnabled,
    }): React.ReactElement => (
      <Section fitted={isMobileUXSpacingEnabled}>
        <Section.Header>
          <Section.Title>
            <FormattedMessage
              id="checkout_shipping_address_heading"
              description="Text describing the form to collect the address where the order will be delivered"
              defaultMessage="Shipping Address"
            />
          </Section.Title>
        </Section.Header>
        <Section.Content>
          <Grid parent>
            <Row>
              <Column xs={12}>
                <FeatureToggle
                  featureKey={RETURNING_CUSTOMERS}
                  render={({ isFeatureEnabled }): React.ReactElement => {
                    if (isFeatureEnabled && returningCustomer != null && addressBook != null) {
                      return (
                        <Field<BaseFieldProps<AddressPickerOwnProps>>
                          component={AddressPicker}
                          name="addressToUse"
                          props={{
                            addressBook,
                            addressConfiguration,
                            addressType: AddressType.SHIPPING,
                            countries,
                            customer: returningCustomer,
                            onAutoCompleteAddress,
                            selectedCompanyName: companyName,
                            selectedCountryCode: countryCode,
                            selectedCustomerType: customerType,
                            selectedVrn: vrn,
                          }}
                        />
                      );
                    }

                    return (
                      <Address
                        {...unhandledProps}
                        addressConfiguration={addressConfiguration}
                        addressType={AddressType.SHIPPING}
                        companyName={companyName}
                        countries={countries}
                        countryCode={countryCode}
                        customerType={customerType}
                        locale={locale}
                        onAutoCompleteAddress={onAutoCompleteAddress}
                        onPostalAutoComplete={onPostalAutoComplete}
                        showSaveAddress={isFeatureEnabled && returningCustomer != null}
                        vrn={vrn}
                      />
                    );
                  }}
                />
              </Column>
            </Row>
          </Grid>
        </Section.Content>
      </Section>
    )}
  />
);

ShippingAddress.displayName = 'ShippingAddress';

export default ShippingAddress;
