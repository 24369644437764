import { Provider } from 'react-redux';
import React from 'react';
import type { Store } from 'redux';
import type Rollbar from 'rollbar';
import { FeatureOverrideProvider, FeatureOverrideSynchronizer } from '../contexts/feature-override';
import IntlProvider from './intl-provider';
import FlowContext from '../contexts/flow';
import RollbarContext from '../contexts/rollbar';
import type { RootAction, RootState } from '../store/types';
import type { ApiClientV2 } from '../../common/services/api-v2';
import type { ApiInternalClientV2 } from '../../common/services/api-internal-v2';

interface BridgeProps {
  apiClient: ApiClientV2;
  apiInternalClient: ApiInternalClientV2;
  rollbar: Rollbar;
  store: Store<RootState, RootAction>;
}

const Bridge: React.FC<BridgeProps> = ({
  apiClient,
  apiInternalClient,
  children,
  rollbar,
  store,
}) => (
  <Provider store={store}>
    <IntlProvider>
      <FeatureOverrideProvider>
        <RollbarContext.Provider value={rollbar}>
          <FlowContext.Provider value={{ apiClient, apiInternalClient }}>
            {children}
          </FlowContext.Provider>
        </RollbarContext.Provider>
        <FeatureOverrideSynchronizer />
      </FeatureOverrideProvider>
    </IntlProvider>
  </Provider>
);

export default Bridge;
