/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const Check = (props) => (
  <SvgIcon viewBox="0 0 24 20" {...props}>
    <g fillRule="evenodd">
      <path d="M8.89988593,21.5834054 C8.42889547,21.5834054 7.97562382,21.3967399 7.64102692,21.062143 L0.52136542,13.9424815 C-0.173788473,13.2469156 -0.173788473,12.1203295 0.52136542,11.4251756 C1.21693138,10.7300217 2.34351747,10.7300217 3.03867137,11.4251756 L8.79563345,17.1821377 L20.8654335,3.02481562 C21.5033103,2.27774152 22.6261878,2.18749925 23.3749102,2.82496403 C24.1228084,3.46284086 24.2122266,4.5865425 23.5747618,5.33444073 L10.254344,20.9578906 C9.93210911,21.3357543 9.46688756,21.561978 8.97034907,21.5817572 C8.94686136,21.5825813 8.92337364,21.5834054 8.89988593,21.5834054 Z" />
    </g>
  </SvgIcon>
);

Check.displayName = 'Check';

export default Check;
