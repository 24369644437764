import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getOrderDiscounts, handleRemoveGiftCard, removePromotionCodeAndUpdatePaymentMethods } from '../store/checkout';
import { getOrderSummaryMessage } from '../store/content';
import MobileOrderSummary from '../components/mobile-order-summary';

const mapStateToProps = createStructuredSelector({
  discounts: getOrderDiscounts,
  orderSummaryMessage: getOrderSummaryMessage,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRemoveGiftCard: handleRemoveGiftCard,
  onRequestRemoveDiscount: removePromotionCodeAndUpdatePaymentMethods,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrderSummary);
