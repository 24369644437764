import findIndex from 'lodash/findIndex';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.v0.unions.PaymentSource[];

const paymentSourcesReducer: AppReducer<State> = (
  previousState = [],
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return action.payload.payment.sources;
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return action.payload.payment_sources != null
        ? action.payload.payment_sources
        : previousState;
    case actionTypes.CREATE_CARD_PAYMENT_SOURCE_SUCCESS:
      return update(previousState, {
        $push: [action.payload],
      });
    case actionTypes.UPDATE_CARD_PAYMENT_SOURCE_SUCCESS:
      return previousState.map((paymentSource) => {
        if (paymentSource.id !== action.payload.id) return paymentSource;
        return action.payload;
      });
    case actionTypes.DELETE_CARD_PAYMENT_SOURCE_SUCCESS:
      return update(previousState, {
        $splice: [
          [findIndex(previousState, { id: action.meta.paymentSourceId }), 1],
        ],
      });
    default:
      return previousState;
  }
};

export default paymentSourcesReducer;
