import type { GiftCardProgramsGetResponse } from '@flowio/api-internal-sdk';
import { isGiftCardEnabled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<GiftCardProgramsGetResponse> | void>;

const fetchGiftCardsRequest = (): RootAction => ({
  type: actionTypes.FETCH_GIFT_CARDS_REQUEST,
});

const fetchGiftCardsSuccess = (
  giftCardProgram: io.flow.internal.v0.models.GiftCardProgram,
): RootAction => ({
  type: actionTypes.FETCH_GIFT_CARDS_SUCCESS,
  payload: giftCardProgram,
});

const fetchGiftCardsFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_GIFT_CARDS_FAILURE,
  payload: error,
});

const fetchGiftCards = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (!isGiftCardEnabled(getState())) {
    return Promise.resolve();
  }

  dispatch(fetchGiftCardsRequest());

  return extra.apiInternal.giftCardPrograms.get({
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchGiftCardsSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(fetchGiftCardsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchGiftCards;
