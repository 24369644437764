import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CheckoutLoyaltyState } from '../types';

const initialState: CheckoutLoyaltyState = {};

const loyaltyReducer: AppReducer<CheckoutLoyaltyState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.ADD_LOYALTY_REWARDS_SUCCESS:
    case actionTypes.FETCH_LOYALTY_PROGRAMS_SUCCESS:
      return update(previousState, {
        program: {
          $set: action.payload,
        },
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return update(previousState, {
        program: {
          $set: action.payload.loyalty_program,
        },
      });
    case actionTypes.FETCH_LOYALTY_PROGRAMS_FAILURE:
      return update(previousState, {
        $set: {},
      });
    case actionTypes.REMOVE_LOYALTY_REWARD_SUCCESS:
      return (previousState.program != null && previousState.program.rewards != null) ? {
        ...previousState,
        program: {
          ...previousState.program,
          rewards: {
            ...previousState.program.rewards,
            applied: previousState.program.rewards.applied
              .filter((_) => _.id !== action.params.rewardId),
          },
        },
      } : previousState;
    default:
      return previousState;
  }
};

export default loyaltyReducer;
