import { Dispatch, bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { createStructuredSelector } from 'reselect';
import { getCheckoutId } from '../store/checkout/selectors';
import CheckoutTokenNext from '../components/checkout-token-next';
import { CheckoutActions } from '../store/checkout/types';
import { RootState } from '../store/types';

interface StateProps {
  checkoutId?: string;
}

const actionCreators = {
  replace,
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checkoutId: getCheckoutId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<CheckoutActions>,
): typeof actionCreators => bindActionCreators(actionCreators, dispatch);

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhanced(CheckoutTokenNext);
