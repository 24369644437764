import { FormattedMessage } from 'react-intl';
import React from 'react';

import AuthorizationReversedStatusError from '../authorization-reversed-status-error';
import AuthorizationDeclinedStatusError from '../authorization-declined-status-error';
import Message from '../message';

interface Props {
  error: import('../../utilities/errors/authorization-error').AuthorizationError;
}

const AuthorizationError: React.FunctionComponent<Props> = ({
  error,
}) => {
  if (error.authorization.result.status === 'reversed') {
    return (
      <AuthorizationReversedStatusError />
    );
  }

  if (error.authorization.result.status === 'declined') {
    return (
      <AuthorizationDeclinedStatusError
        declineCode={error.authorization.result.decline_code}
      />
    );
  }

  return (
    <Message color="negative">
      <Message.Text>
        <FormattedMessage
          id="checkout_default_authorization_error"
          description="A message displayed when a payment authorization fails for any reason"
          defaultMessage="Your payment was not accepted. Please try using a different payment method."
        />
      </Message.Text>
    </Message>
  );
};

export default AuthorizationError;
