import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import PageError from '../page-error';
import formatErrorMessage from '../../utilities/errors/format-error-message';

const displayName = 'InternalServerError';

// TODO: Add translation for hard-coded messages.
class InternalServerError extends PureComponent {
  componentDidMount() {
    const { error, orderNumber } = this.props;
    let response;
    if (error) {
      const { message } = error;
      if (error.response) {
        response = formatErrorMessage(error.response.result);
      } else {
        response = message;
      }
      getRollbar((rollbar, extra = {}) => {
        const finalExtra = {
          ...extra,
          response: error.response,
          error,
          orderNumber,
        };
        rollbar.error(response, finalExtra);
      });
    }
  }

  render() {
    return (
      <PageError>
        Bummer. Something went wrong.
        <br />
        Please go back and try again.
      </PageError>
    );
  }
}

InternalServerError.displayName = displayName;

InternalServerError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    response: PropTypes.object,
    name: PropTypes.string,
    stack: PropTypes.string,
  }),
  orderNumber: PropTypes.string,
};

InternalServerError.defaultProps = {
  error: undefined,
  orderNumber: undefined,
};

export default InternalServerError;
