/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import Panel from '../panel/panel';
import RadioButton from '../radio-button/radio-button';

if (process.browser) {
  require('./radio-panel.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('radio-panel');

const propTypes = {
  accessory: PropTypes.node,
  automationId: PropTypes.string,
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  label: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  unmountOnExit: PropTypes.bool,
};

/**
 * @typedef {PropTypes.InferProps<typeof propTypes>} RadioPanelOwnProps
 * @typedef {import('../radio-button/radio-button').RadioButtonProps} RadioButtonProps
 * @typedef {Pick<RadioButtonProps, Exclude<keyof RadioButtonProps, keyof RadioPanelOwnProps>>} UnhandledProps
 * @typedef {RadioPanelOwnProps & UnhandledProps} RadioPanelProps
 */

/**
 * @type {React.FunctionComponent<RadioPanelProps>}
 */
const RadioPanel = ({
  accessory,
  automationId,
  children,
  collapsible,
  defaultExpanded,
  expanded,
  label,
  name,
  unmountOnExit,
  value,
  ...unhandledProps
}) => (
  <Panel
    className={bem.block()}
    collapsible={collapsible}
    defaultExpanded={defaultExpanded}
    eventKey={value}
    data-automation-id={automationId}
    expanded={expanded}
  >
    <Panel.Header>
      <RadioButton {...unhandledProps} fluid name={name} value={value}>
        {label}
      </RadioButton>
      {accessory && (
        <div className={bem.element('accessory')}>
          {accessory}
        </div>
      )}
    </Panel.Header>
    {children != null && (
      <Panel.Content unmountOnExit={unmountOnExit}>
        {children}
      </Panel.Content>
    )}
  </Panel>
);

RadioPanel.displayName = 'RadioPanel';

RadioPanel.propTypes = propTypes;

RadioPanel.defaultProps = {
  accessory: undefined,
  automationId: undefined,
  collapsible: false,
  children: undefined,
  defaultExpanded: false,
  expanded: undefined,
  unmountOnExit: false,
};

export default RadioPanel;
