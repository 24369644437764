import { FormattedMessage } from 'react-intl';
import { replace } from 'react-router-redux';
import { useDispatch } from 'react-redux';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import BemHelper from '@flowio/bem-helper';
import get from 'lodash/get';
import React, { useEffect } from 'react';

import { MOBILE_UX_SPACING, FORCE_ORDER_NUMBER_FROM_PLATFORM } from '../../../../common/constants/feature-keys';
import FeatureToggle from '../../feature-toggle';
import { Grid, Row, Column } from '../../grid';
import Button from '../../button';
import ContentItem from '../../content-item';
import CheckIcon from '../../svg-icons/check';
import CustomerInfoSummary from '../../customer-info-summary';
import { createTransactionEvents } from '../../../store/checkout';

import type { MergedProps as Props } from '../types';

if (process.browser) {
  require('./order-confirmation-step.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-confirmation-step');

const getOrderNumber = (
  forcePlatformNumberEnabled: boolean,
  orderNumber: string,
  platformOrderNumber?: string,
): string => {
  if (forcePlatformNumberEnabled) {
    return (forcePlatformNumberEnabled && platformOrderNumber) ? platformOrderNumber : '';
  }

  return orderNumber;
};

const OrderConfirmationStep: React.FC<Props> = ({
  allowInvoiceAddress = false,
  authorizations = [],
  billingAddress,
  countries,
  contactUs,
  continueShoppingUrl,
  deliveredDuty,
  deliveryOptions,
  destination,
  invoiceAddress,
  isDeliveredDutyCustomerChoice,
  order,
  orderNumber,
  orderPayments,
  organizationUrl,
  organization,
  platformOrderNumber,
  vrn,
  location,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (order != null && order.submitted_at == null) {
      getRollbar((rollbar, extra) => {
        rollbar.error('Thank you page rendered with order not yet submitted', {
          ...extra,
          orderNumber: order.number,
        });
      });
    } else {
      const {
        track_transaction: trackTransaction,
        ...otherQueryParams
      } = location.query;

      if (trackTransaction === 'true') {
        dispatch(createTransactionEvents(organization));
        dispatch(replace({
          pathname: location.pathname,
          query: otherQueryParams,
        }));
      }
    }
  }, []);

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled }): React.ReactNode => (
        <Grid className={bem.block()} parent={isFeatureEnabled}>
          <Row>
            <Column xs={12}>
              <div className={bem.element('header')}>
                <div className={bem.element('check')}>
                  <CheckIcon />
                </div>
                <div className={bem.element('header-content')}>
                  <p className={bem.element('order-number')}>
                    <FeatureToggle
                      featureKey={FORCE_ORDER_NUMBER_FROM_PLATFORM}
                      render={({
                        isFeatureEnabled: forcePlatformNumberEnabled,
                      }): React.ReactNode => {
                        if (forcePlatformNumberEnabled && !platformOrderNumber) {
                          return (
                            <FormattedMessage
                              id="checkout_confirmation_no_order_number"
                              defaultMessage="Your order is confirmed!"
                              description="Text confirming to customers the order has been placed"
                            />
                          );
                        }
                        return (
                          <FormattedMessage
                            id="checkout_confirmation_order_number"
                            defaultMessage="Your order #{orderNumber} is confirmed!"
                            description="Text with order number confirming to customers the order placement"
                            values={{
                              orderNumber: getOrderNumber(
                                forcePlatformNumberEnabled,
                                orderNumber,
                                platformOrderNumber,
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  </p>
                  <p className={bem.element('customer-name')}>
                    <FormattedMessage
                      id="checkout_confirmation_thank_you"
                      defaultMessage="Thank you, {name}!"
                      description="Text showing appreciation to customer for placing an order"
                      values={{
                        name: get(order, 'customer.name.first')
                          || get(destination, 'contact.name.first', ''),
                      }}
                    />
                  </p>
                </div>
              </div>
              <CustomerInfoSummary
                allowInvoiceAddress={allowInvoiceAddress}
                invoiceAddress={invoiceAddress}
                authorizations={authorizations}
                billingAddress={billingAddress}
                countries={countries}
                deliveredDuty={deliveredDuty}
                deliveryOptions={deliveryOptions}
                destination={destination}
                giftMessage={get(order, 'attributes.gift_message')}
                nationalIdNumber={get(order, 'attributes.national_id_number')}
                isDeliveredDutyCustomerChoice={isDeliveredDutyCustomerChoice}
                orderPayments={orderPayments}
                vrn={vrn}
              />
            </Column>
            <Column xs={12} md={6} mdPush={6}>
              <Button
                className={bem.element('continue-shopping')}
                color="secondary"
                href={continueShoppingUrl || organizationUrl}
                fluid
              >
                <FormattedMessage
                  id="checkout_confirmation_continue_shopping"
                  defaultMessage="Continue Shopping"
                  description="Text describing action to continue shopping"
                />
              </Button>
            </Column>
            <Column xs={12} md={6} mdPull={6}>
              {contactUs && (
                <p className={bem.element('need-help')}>
                  <FormattedMessage
                    id="checkout_confirmation_contact_us"
                    defaultMessage="Need help? {contactInfo}"
                    description="Text describing action to contact merchant"
                    values={{ contactInfo: <ContentItem content={contactUs} /> }}
                  />
                </p>
              )}
            </Column>
          </Row>
        </Grid>
      )}
    />
  );
};

OrderConfirmationStep.displayName = 'OrderConfirmationStep';

export default OrderConfirmationStep;
