/* eslint-disable react/jsx-props-no-spreading */
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AddressType } from '../../constants/address-type';
import { CustomerType } from '../../constants/customer-type';
import { Grid, Row, Column } from '../grid';
import { isPostalCodeVisible, isAdministrativeAreaVisible, isFieldRequired } from '../../utilities/address-utilities';
import { ADDRESS_POSTAL_AUTOCOMPLETE, MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import AddressLine1 from './address-line1';
import AddressLine2 from './address-line2';
import AdministrativeArea from './administrative-area';
import bem from './bem';
import Country from './country';
import FeatureToggle from '../feature-toggle';
import FirstName from './first-name';
import GenericError from '../generic-error';
import isEuropeanUnion from '../../utilities/is-european-union';
import LastName from './last-name';
import Locality from './locality';
import OrderErrorTypes from '../../constants/order-error-types';
import VrnErrorMessages from '../../constants/intl-vrn-errors';
import OrganizationName from './organization-name';
import PhoneNumber from './phone-number';
import PostalCode from './postal-code';
import PostalAutoComplete from './postal-auto-complete';
import SaveAddress from './save-address';
import VatRegistrationNumber from './vat-registration-number';
import { updateVrnVisibility } from '../../store/checkout/actions';

if (process.browser) {
  require('./address.css'); // eslint-disable-line global-require
}

type Props = {
  addressType?: AddressType;
  addressConfiguration: io.flow.v0.models.AddressConfiguration;
  allowInvoiceAddress?: boolean;
  allowVRN?: boolean;
  children?: never;
  companyName?: string;
  countries: io.flow.v0.models.Country[];
  countryCode: string;
  customerType?: CustomerType;
  invoice?: io.flow.v0.models.BillingAddress;
  isVrnValid?: boolean;
  isVrnValidating?: boolean;
  locale: string;
  onAutoCompleteAddress?: (
    place: unknown,
    addressType?: AddressType
  ) => void;
  onPostalAutoComplete?: (address: io.flow.v0.models.Address) => void;
  // Mixing VRN into this component led to this undeterministic state.
  // This should be refactored such that all VRN related attributes are not
  // props of addresses.
  onVrnCheck?: (
    organization: string | undefined,
    orderNumber: string | undefined,
    companyName: string | undefined,
    vrn: string | undefined,
    countryCode: string,
    allowInvoiceAddress: boolean,
    hasInvoiceAddress: boolean,
  ) => void;
  orderNumber?: string;
  organization?: string;
  showSaveAddress?: boolean;
  showInvalidVrnMessage?: boolean;
  unableToValidateVrn?: boolean;
  vrn?: string;
};

/**
 * Address provides fields to collect international postal addresses, implementing a subset of the
 * top-level address elements defined in the xNAL standard. The terms used to designate various
 * parts of an address may not be immediately recognizable, so this quick glossary maps the
 * address parts in xNAL to their equivalent U.S. address terms:
 *
 * country => Country (always required, 2 character ISO code)
 * firstName => First name
 * lastName => Last name
 * organizationName => Company
 * administrativeArea => State / Province / Region (ISO code when available)
 * locality => City / Town
 * postalCode => Postal code / ZIP Code
 * addressLine => Street address
 *
 * @see http://xml.coverpages.org/xnal.html.
 */
const AddressFields: React.FunctionComponent<Props> = ({
  addressConfiguration,
  addressType,
  allowInvoiceAddress = false,
  allowVRN = false,
  companyName,
  countries,
  countryCode,
  customerType = CustomerType.INDIVIDUAL,
  invoice,
  isVrnValid = false,
  isVrnValidating = false,
  locale,
  onAutoCompleteAddress,
  onPostalAutoComplete,
  onVrnCheck,
  orderNumber,
  organization,
  showInvalidVrnMessage = false,
  showSaveAddress = false,
  unableToValidateVrn = false,
  vrn,
  ...unhandledProps
}) => {
  const intl = useIntl();

  const isBusiness = customerType === CustomerType.BUSINESS;
  const isBillingAddress = addressType === AddressType.BILLING;
  const isInvoiceAddress = addressType === AddressType.INVOICE;
  const isShippingAddress = addressType === AddressType.SHIPPING;

  const isVrnActive = allowInvoiceAddress
    ? isInvoiceAddress || (isShippingAddress && isBusiness)
    : isShippingAddress && companyName != null && companyName.length > 0;

  const showVrn = isVrnActive && isEuropeanUnion(countryCode) && allowVRN;

  const isPhoneNumberVisible = !isBillingAddress && !isInvoiceAddress;

  const handleCompanyChange = React.useCallback(
    debounce((
      event: React.ChangeEvent<HTMLInputElement> | undefined,
      value: string,
    ) => {
      if (allowVRN
        && isEuropeanUnion(countryCode)
        && (isBusiness || isInvoiceAddress || !allowInvoiceAddress)
        && typeof onVrnCheck === 'function') {
        onVrnCheck(
          organization,
          orderNumber,
          value,
          vrn,
          countryCode,
          allowInvoiceAddress,
          !isEmpty(invoice),
        );
      }
    }, 400),
    [
      addressType,
      allowInvoiceAddress,
      allowVRN,
      countryCode,
      invoice,
      isBusiness,
      orderNumber,
      organization,
      vrn,
    ],
  );

  const handleVatRegistrationNumberChange = React.useCallback(
    debounce((
      event: React.ChangeEvent<HTMLInputElement> | undefined,
      value: string,
    ) => {
      if (typeof onVrnCheck === 'function') {
        onVrnCheck(
          organization,
          orderNumber,
          companyName,
          value,
          countryCode,
          allowInvoiceAddress,
          !isEmpty(invoice),
        );
      }
    }, 400),
    [
      allowInvoiceAddress,
      companyName,
      countryCode,
      invoice,
      orderNumber,
      organization,
    ],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateVrnVisibility(showVrn));
  }, [showVrn]);

  const postalCodeIsVisible = isPostalCodeVisible(addressConfiguration);
  const administrativeAreaIsVisible = isAdministrativeAreaVisible(addressConfiguration);

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled }): React.ReactElement => (isFeatureEnabled ? (
        <div className={bem.block()}>
          <Grid {...unhandledProps} parent>
            <Row>
              <Column xs={6}>
                <FirstName
                  addressType={addressType}
                  required={isFieldRequired('firstName', addressConfiguration)}
                />
              </Column>
              <Column xs={6}>
                <LastName
                  addressType={addressType}
                  required={isFieldRequired('lastName', addressConfiguration)}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={12}>
                <OrganizationName
                  addressType={addressType}
                  onChange={handleCompanyChange}
                />
              </Column>
            </Row>
            {showVrn && (
              <Row>
                <Column xs={12}>
                  <VatRegistrationNumber
                    addressType={addressType}
                    required={isFieldRequired('vrn', addressConfiguration)}
                    onChange={handleVatRegistrationNumberChange}
                    loading={isVrnValidating}
                    valid={isVrnValid}
                  />
                </Column>
              </Row>
            )}
            {showInvalidVrnMessage && isVrnActive && (
              <Row>
                <Column xs={12}>
                  <GenericError
                    className={bem.element('vrn-error')}
                    code={OrderErrorTypes.VRN_VALIDATION_ERROR}
                    error={{
                      code: 'generic_error',
                      messages: [intl.formatMessage(VrnErrorMessages.vrnErrorMessage)],
                    }}
                  />
                </Column>
              </Row>
            )}
            {unableToValidateVrn && (
              <Row>
                <Column xs={12}>
                  <GenericError
                    className={bem.element('vrn-error')}
                    code={OrderErrorTypes.VRN_VALIDATION_ERROR}
                    error={{
                      code: 'generic_error',
                      messages: [intl.formatMessage(VrnErrorMessages.vrnValidationErrorMessage)],
                    }}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={12} md={12}>
                <AddressLine1
                  addressType={addressType}
                  countries={countries}
                  countryCode={countryCode}
                  locale={locale}
                  onAutoCompleteAddress={onAutoCompleteAddress}
                  required={isFieldRequired('addressLine1', addressConfiguration)}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={12} md={12}>
                <AddressLine2
                  addressType={addressType}
                  required={isFieldRequired('addressLine2', addressConfiguration)}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={12}>
                <Country
                  addressType={addressType}
                  options={countries}
                  required
                />
              </Column>
            </Row>
            <Row>
              <Column xs={administrativeAreaIsVisible ? 6 : 12}>
                <Locality
                  addressType={addressType}
                  required={isFieldRequired('locality', addressConfiguration)}
                />
              </Column>
              {administrativeAreaIsVisible && (
                <Column xs={6}>
                  <AdministrativeArea
                    addressType={addressType}
                    countryCode={countryCode}
                    locale={locale}
                    provinces={addressConfiguration.provinces}
                    required={isFieldRequired('administrativeArea', addressConfiguration)}
                  />
                </Column>
              )}
            </Row>
            <Row>
              {postalCodeIsVisible && (
                <Column xs={isPhoneNumberVisible ? 6 : 12}>
                  <FeatureToggle
                    featureKey={ADDRESS_POSTAL_AUTOCOMPLETE}
                    render={({
                      isFeatureEnabled: isAddressPostalAutoCompleteEnabled,
                    }): React.ReactElement => {
                      if (isAddressPostalAutoCompleteEnabled) {
                        return (
                          <PostalAutoComplete
                            addressType={addressType}
                            countryCode={countryCode}
                            onSelection={onPostalAutoComplete}
                            required={isFieldRequired('postalCode', addressConfiguration)}
                          />
                        );
                      }

                      return (
                        <PostalCode
                          addressType={addressType}
                          countryCode={countryCode}
                          required={isFieldRequired('postalCode', addressConfiguration)}
                        />
                      );
                    }}
                  />
                </Column>
              )}
              {isPhoneNumberVisible && (
                <Column xs={postalCodeIsVisible ? 6 : 12}>
                  <PhoneNumber
                    addressType={addressType}
                    required={isFieldRequired('phoneNumber', addressConfiguration)}
                  />
                </Column>
              )}
            </Row>
            {showSaveAddress && (
              <Row>
                <Column xs={12}>
                  <SaveAddress addressType={addressType} />
                </Column>
              </Row>
            )}
          </Grid>
        </div>
      ) : (
        <Grid {...unhandledProps} parent>
          <Column xs={12} md={6}>
            <FirstName
              addressType={addressType}
              required={isFieldRequired('firstName', addressConfiguration)}
            />
          </Column>
          <Column xs={12} md={6}>
            <LastName
              addressType={addressType}
              required={isFieldRequired('lastName', addressConfiguration)}
            />
          </Column>
          <Column xs={12}>
            <OrganizationName
              addressType={addressType}
              onChange={handleCompanyChange}
            />
          </Column>
          {showVrn && (
            <Column xs={12}>
              <VatRegistrationNumber
                addressType={addressType}
                onChange={handleVatRegistrationNumberChange}
                loading={isVrnValidating}
                valid={isVrnValid}
              />
            </Column>
          )}
          {showInvalidVrnMessage && isVrnActive && (
            <Column xs={12}>
              <GenericError
                className={bem.element('vrn-error')}
                code={OrderErrorTypes.VRN_VALIDATION_ERROR}
                error={{
                  code: 'generic_error',
                  messages: [intl.formatMessage(VrnErrorMessages.vrnErrorMessage)],
                }}
              />
            </Column>
          )}
          {unableToValidateVrn && (
            <Column xs={12}>
              <GenericError
                className={bem.element('vrn-error')}
                code={OrderErrorTypes.VRN_VALIDATION_ERROR}
                error={{
                  code: 'generic_error',
                  messages: [intl.formatMessage(VrnErrorMessages.vrnValidationErrorMessage)],
                }}
              />
            </Column>
          )}
          <Column xs={12} md={12}>
            <AddressLine1
              addressType={addressType}
              countries={countries}
              countryCode={countryCode}
              locale={locale}
              onAutoCompleteAddress={onAutoCompleteAddress}
              required={isFieldRequired('addressLine1', addressConfiguration)}
            />
          </Column>
          <Column xs={12} md={12}>
            <AddressLine2
              addressType={addressType}
              required={isFieldRequired('addressLine2', addressConfiguration)}
            />
          </Column>
          <Column xs={12}>
            <Locality
              addressType={addressType}
              required={isFieldRequired('locality', addressConfiguration)}
            />
          </Column>
          <Column xs={12} md={administrativeAreaIsVisible ? 6 : 12}>
            <Country
              addressType={addressType}
              options={countries}
              required
            />
          </Column>
          {administrativeAreaIsVisible && (
            <Column xs={12} md={6}>
              <AdministrativeArea
                addressType={addressType}
                countryCode={countryCode}
                locale={locale}
                provinces={addressConfiguration.provinces}
                required={isFieldRequired('administrativeArea', addressConfiguration)}
              />
            </Column>
          )}
          {postalCodeIsVisible && (
            <Column xs={12} md={isPhoneNumberVisible ? 6 : 12}>
              <FeatureToggle
                featureKey={ADDRESS_POSTAL_AUTOCOMPLETE}
                render={({
                  isFeatureEnabled: isAddressPostalAutoCompleteEnabled,
                }): React.ReactElement => {
                  if (isAddressPostalAutoCompleteEnabled) {
                    return (
                      <PostalAutoComplete
                        addressType={addressType}
                        countryCode={countryCode}
                        onSelection={onPostalAutoComplete}
                        required={isFieldRequired('postalCode', addressConfiguration)}
                      />
                    );
                  }

                  return (
                    <PostalCode
                      addressType={addressType}
                      countryCode={countryCode}
                      required={isFieldRequired('postalCode', addressConfiguration)}
                    />
                  );
                }}
              />
            </Column>
          )}
          {isPhoneNumberVisible && (
            <Column xs={12} md={postalCodeIsVisible ? 6 : 12}>
              <PhoneNumber
                addressType={addressType}
                required={isFieldRequired('phoneNumber', addressConfiguration)}
              />
            </Column>
          )}
          {showSaveAddress && (
            <Column xs={12}>
              <SaveAddress addressType={addressType} />
            </Column>
          )}
        </Grid>
      ))}
    />
  );
};

export default AddressFields;
