import React from 'react';
import BemHelper from '@flowio/bem-helper';
import { Dialog } from '../dialog';

import './adyen-challenge-dialog.css';
import { injectScript } from '../../utilities/script-cache';

const bem = new BemHelper('adyen-challenge-dialog');

export interface AdyenChallengeDialogProps {
  isAdyen3dsDialogOpen: boolean;
}

class AdyenChallengeDialog extends React.PureComponent<AdyenChallengeDialogProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static stopObservingAdyenScript: (...args: any[]) => void;

  public componentDidMount(): void {
    AdyenChallengeDialog.stopObservingAdyenScript = injectScript('https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.1.0/adyen.js');
  }

  public componentWillUnmount(): void {
    AdyenChallengeDialog.stopObservingAdyenScript();
  }

  public render(): React.ReactNode {
    const { isAdyen3dsDialogOpen } = this.props;
    return (
      <div className={bem.block()}>
        <div id="adyen3ds" className={bem.element('fingerprint-element')} />
        <Dialog className={bem.element('challenge-modal')} isOpen={isAdyen3dsDialogOpen} showBackdrop>
          <Dialog.Body>
            <div id="adyenChallenge" />
          </Dialog.Body>
        </Dialog>
      </div>
    );
  }
}

export default AdyenChallengeDialog;
