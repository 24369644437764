import type { CustomerAddressBookContactsPostResponse } from '@flowio/api-sdk';
import { getOrganization } from '../../flow/selectors';
import { getReturningCustomer } from '../selectors';
import actionTypes from '../../constants/action-types';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { RootAction, ThunkResult } from '../../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';

type Result = Promise<
LegacyResponseWithLegacyError<CustomerAddressBookContactsPostResponse> | void
>;

const saveCustomerAddressRequest = (): RootAction => ({
  type: actionTypes.SAVE_CUSTOMER_ADDRESS_REQUEST,
});

const saveCustomerAddressSuccess = (
  customerAddressBookContact: io.flow.v0.models.CustomerAddressBookContact,
): RootAction => ({
  type: actionTypes.SAVE_CUSTOMER_ADDRESS_SUCCESS,
  payload: customerAddressBookContact,
});

const saveCustomerAddressFailure = (
  error: io.flow.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.SAVE_CUSTOMER_ADDRESS_FAILURE,
  payload: error,
});

// TODO: Rename file and default export to match action creators.
const saveNewAddress = (
  orderAddress: io.flow.v0.models.OrderAddress,
  preferredAddressType: io.flow.v0.enums.CustomerAddressType,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const customer = getReturningCustomer(getState());

  // TODO: Just ask for the customer number and organization identifier
  // instead of looking in the application state.
  if (customer == null) {
    extra.rollbar.error('Cannot save address without returning customer object');
    return Promise.resolve(undefined);
  }

  // TODO: Just ask for these objects separately in the parameters.
  const { contact, ...address } = orderAddress;
  const customerNumber = customer.number;
  const organizationId = getOrganization(getState());

  dispatch(saveCustomerAddressRequest());

  return extra.api.customerAddressBookContacts.post({
    body: {
      address,
      address_preferences: [{
        type: preferredAddressType,
      }],
      contact,
    },
    number: customerNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(saveCustomerAddressSuccess(response.result));
          break;
        case 401:
        case 422:
          dispatch(saveCustomerAddressFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default saveNewAddress;
