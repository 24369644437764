/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import { injectScript } from '../../utilities/script-cache';
import { getRequiredEnvVar } from '../../../common/utilities/environment';
import TextField from '../redux-form/text-field';
import decodeHtml from '../../utilities/decode-html';
import {
  unitRegex,
  cityRegex,
  postalRegex,
  streetAddressRegex,
  extendedAddressRegex,
  regionRegex,
} from './google-place-regular-expressions';

if (process.browser) {
  require('./google-place-auto-complete.css'); // eslint-disable-line global-require
}

const GOOGLE_PLACES_API_KEY = getRequiredEnvVar('GOOGLE_PLACES_API_KEY');

class GooglePlaceAutoComplete extends Component {
  constructor(props, context) {
    super(props, context);
    this.setInputElement = this.setInputElement.bind(this);
    this.handleGooglePlacesLoaded = this.handleGooglePlacesLoaded.bind(this);
    this.handleGooglePlaceChanged = this.handleGooglePlaceChanged.bind(this);
  }

  componentDidMount() {
    const { locale } = this.props;
    this.stopObservingScript = injectScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places&language=${locale}`, {
      onLoad: this.handleGooglePlacesLoaded,
    });
  }

  componentDidUpdate(prevProps) {
    const { countryCode: nextCountryCode } = this.props;
    const { countryCode: prevCountryCode } = prevProps;
    if (this.autocomplete && prevCountryCode !== nextCountryCode) {
      this.autocomplete.setComponentRestrictions({ country: nextCountryCode });
    }
  }

  componentWillUnmount() {
    this.stopObservingScript();
  }

  // Buckle up buckaroos, this is a fun one to explain.....
  // Basically google places API returns back this object with address components, the problem is
  // based on the address and the country the component types can be used in multiple different ways
  // IE. sublocality_level_2 is completely useless for US postal addresses but are really important for Korean addresses
  // Google places API also returns a formatted address string which is the actual formatted postal address
  // Problem is, it is just a string so we have no way of knowing what the street is, territory, etc...
  // Lucky for us Google also returns back a formatted address in the adr format found here: http://microformats.org/wiki/adr#Format
  // We can parse the HTML and actually pull out the relevant text. The slight hiccup is that sometimes extra information is required for postal and street lines
  // That information is found directly next to the span that has the basic info for some reason
  // Ex. In Saudi Arabia they have a postal code suffix that is needed for a mailing address, unfortunately Google returns it outside of the postal code span.
  // This is why we have these extra regex matches, to simply extract the extra data to the best of our abilities.
  handleGooglePlaceChanged() {
    let longAdminAreaName;

    const { onPlaceChange, countryCode } = this.props;
    const addressComponents = this.autocomplete.getPlace().address_components;
    const formattedHtmlAddr = get(this.autocomplete.getPlace(), 'adr_address');
    const administrativeArea = get(formattedHtmlAddr.match(regionRegex), '[1]', '');
    const unitPrefix = get(unitRegex.exec(formattedHtmlAddr), '[1]', '');

    const defaultCity = get(find(addressComponents, (component) => component.types.indexOf('locality') >= 0), 'long_name', '');
    const city = get(formattedHtmlAddr.match(cityRegex), '[1]', defaultCity);

    const streetAddressMatch = formattedHtmlAddr.match(streetAddressRegex);
    const streetAddress = get(streetAddressMatch, '[1]', '');
    // Usually there is an extra comma on the end we don't want.
    const extraStreetAddrInfo = ` ${get(streetAddressMatch, '[2]', '').replace(',', '')}`;
    const extendedAddress = get(formattedHtmlAddr.match(extendedAddressRegex), '[1]', '');
    const postalCodeMatch = formattedHtmlAddr.match(postalRegex);
    const postalCode = get(postalCodeMatch, '[1]', '');
    // Usually there is an extra comma on the end we don't want.
    const postalSuffix = get(postalCodeMatch, '[2]', '').replace(',', '');

    const foundAdminAreaObj = find(addressComponents,
      (comp) => comp.short_name === administrativeArea);

    if (foundAdminAreaObj) {
      longAdminAreaName = foundAdminAreaObj.long_name;
    } else {
      longAdminAreaName = administrativeArea;
    }

    onPlaceChange({
      street: decodeHtml(`${unitPrefix}${streetAddress}${extendedAddress}${extraStreetAddrInfo}`.trim()),
      city: decodeHtml(city),
      postalCode: `${postalCode} ${postalSuffix}`.trim(),
      country: countryCode,
      administrativeAreas: [longAdminAreaName],
    });
  }

  handleGooglePlacesLoaded() {
    const { countryCode } = this.props;

    try {
      this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, {
        types: ['address'],
        componentRestrictions: {
          country: countryCode,
        },
      });

      this.autocomplete.addListener('place_changed', this.handleGooglePlaceChanged);
    } catch (error) {
      getRollbar((rollbar, extra) => {
        rollbar.error('Failed to initialize Google Autocomplete', error, extra);
      });
    }
  }

  setInputElement(element) {
    this.inputElement = element;
  }

  render() {
    // proxy other props to TextField so this whole thing works with Redux Form
    const unhandledProps = getUnhandledProps(GooglePlaceAutoComplete, this.props);

    return (
      <TextField {...unhandledProps} inputRef={this.setInputElement} />
    );
  }
}

GooglePlaceAutoComplete.displayName = 'GooglePlaceTextInput';

GooglePlaceAutoComplete.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onPlaceChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
};

export default GooglePlaceAutoComplete;
