import { Action } from './types';

export function removeDiscountCodeFailure(): Action {
  return {
    type: 'REMOVE_DISCOUNT_CODE_FAILURE',
  };
}

export function removeDiscountCodeRequest(): Action {
  return {
    type: 'REMOVE_DISCOUNT_CODE_REQUEST',
  };
}

export function removeDiscountCodeSuccess(): Action {
  return {
    type: 'REMOVE_DISCOUNT_CODE_SUCCESS',
  };
}

export function setDiscountCode(discountCode: string): Action {
  return {
    type: 'SET_DISCOUNT_CODE',
    payload: discountCode,
  };
}

export function submitDiscountCodeFailure(error: Error): Action {
  return {
    type: 'SUBMIT_DISCOUNT_CODE_FAILURE',
    payload: error,
  };
}

export function submitDiscountCodeRequest(): Action {
  return {
    type: 'SUBMIT_DISCOUNT_CODE_REQUEST',
  };
}

export function submitDiscountCodeSuccess(): Action {
  return {
    type: 'SUBMIT_DISCOUNT_CODE_SUCCESS',
  };
}
