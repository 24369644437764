import React from 'react';
import type { ApiClientV2 } from '../../common/services/api-v2';
import type { ApiInternalClientV2 } from '../../common/services/api-internal-v2';

export interface FlowContextType {
  apiClient: ApiClientV2;
  apiInternalClient: ApiInternalClientV2;
}

const FlowContext = React.createContext<FlowContextType | null>(null);

export default FlowContext;
