/* eslint-disable global-require */

import { Field } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';

import Checkbox from '../redux-form/checkbox';
import LocalizedContentElement from '../localized-content-element';
import PromptOptions from '../../constants/prompt-options';
import optinPromptPropTypes from '../../utilities/prop-types/optin-prompt-prop-types';

const bem = new BemHelper('flowio-optin-prompt');

const messages = defineMessages({
  requiredField: {
    id: 'checkout_error_empty_field',
    description: 'Generic error shown when a form field is required',
    defaultMessage: 'This field is required',
  },
});

function isPromptRequired(prompt) {
  return includes(prompt.options, PromptOptions.REQUIRE_CONSENT);
}

class Prompt extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleCheckboxValidation = this.handleCheckboxValidation.bind(this);
  }

  handleCheckboxValidation(value) {
    const { intl, prompt } = this.props;

    if (isPromptRequired(prompt) && !value) {
      return get(
        prompt,
        'content.elements.error_consent_missing.value',
        intl.formatMessage(messages.requiredField),
      );
    }

    return undefined;
  }

  renderCheckbox() {
    const { prompt } = this.props;

    const label = this.renderText();

    // NOTE: Redux Form does not know whether a custom component is a checkbox,
    // but we can hint it by declaring the `type` property to `"checkbox"`.
    // This will ensure the `checked` property of the injected `input` property
    // is defined.
    return (
      <Field
        automationId={prompt.optin_attribute.optin_attribute_key}
        component={Checkbox}
        name={prompt.optin_attribute.optin_attribute_key}
        id={prompt.optin_attribute.optin_attribute_key}
        fluid
        labelText={label}
        required={isPromptRequired(prompt)}
        type="checkbox"
        validate={this.handleCheckboxValidation}
      />
    );
  }

  renderNotice() {
    const { prompt } = this.props;
    return (
      <div>
        <Field component="input" type="hidden" name={prompt.optin_attribute.optin_attribute_key} />
        {this.renderText()}
      </div>
    );
  }

  renderText() {
    const { prompt } = this.props;

    return (
      <LocalizedContentElement
        element={get(prompt, 'content.elements.text')}
        inline
      />
    );
  }

  render() {
    const { prompt } = this.props;

    let children;

    if (includes(prompt.options, PromptOptions.NOTICE_ONLY)) {
      children = this.renderNotice();
    } else {
      children = this.renderCheckbox();
    }

    return (
      <div className={bem.block()}>
        {children}
      </div>
    );
  }
}

Prompt.displayName = 'Prompt';

Prompt.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  prompt: PropTypes.shape(optinPromptPropTypes).isRequired,
};

export default injectIntl(Prompt);
