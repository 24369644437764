import React from 'react';
import Popover, { Position } from '../popover';
import Menu from '../menu';
import MenuItem from '../menu-item';

type MenuProps = PropsOf<typeof Menu>;

type MenuItemProps = PropsOf<typeof MenuItem>;

type Props = {
  autoHighlight?: boolean;
  closeOnClickAway?: boolean;
  closeOnEscape?: boolean;
  closeOnInvisible?: boolean;
  constraint?: string;
  defaultHighlightIndex?: number;
  defaultOpen?: boolean;
  defaultValue?: string | string[];
  disabled?: boolean;
  items?: MenuItemProps[];
  menuProps?: MenuProps;
  multiple?: boolean;
  offset?: string;
  onClose?: () => void;
  onItemSelection?: (index: number, value?: string) => void;
  onOpen?: () => void;
  onValueChange?: (value?: string | string[]) => void;
  open?: boolean;
  openOnClick?: boolean;
  openOnFocus?: boolean;
  position?: Position;
  selectItemOnEnter?: boolean;
  selectItemOnSpace?: boolean;
  trigger: React.ReactElement;
  value?: string | string[];
};

const DropdownMenu: React.FunctionComponent<Props> = ({
  autoHighlight,
  children,
  closeOnClickAway,
  closeOnEscape,
  closeOnInvisible,
  constraint,
  defaultHighlightIndex,
  defaultOpen,
  defaultValue,
  disabled,
  items,
  menuProps,
  multiple,
  offset,
  onClose,
  onItemSelection,
  onOpen,
  onValueChange,
  open,
  openOnClick,
  openOnFocus,
  position,
  selectItemOnEnter,
  selectItemOnSpace,
  trigger,
  value,
}) => {
  const handleMenuChange = React.useCallback((
    index: number,
    nextValue?: string | string[],
  ): void => {
    if (typeof onValueChange === 'function') {
      onValueChange(nextValue);
    }
  }, [onValueChange]);

  return (
    <Popover
      closeOnClickAway={closeOnClickAway}
      closeOnEscape={closeOnEscape}
      closeOnInvisible={closeOnInvisible}
      closeOnResize={false}
      closeOnScroll={false}
      constraint={constraint}
      defaultOpen={defaultOpen}
      disabled={disabled}
      inline={false}
      lockScrolling={false}
      offset={offset}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      openOnClick={openOnClick}
      openOnFocus={openOnFocus}
      openOnHover={false}
      openOnSpace={false}
      position={position}
      trigger={trigger}
    >
      <Menu
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...menuProps}
        autoHighlight={autoHighlight}
        defaultHighlightIndex={defaultHighlightIndex}
        defaultValue={defaultValue}
        items={items}
        multiple={multiple}
        onItemSelection={onItemSelection}
        onChange={handleMenuChange}
        selectItemOnEnter={selectItemOnEnter}
        selectItemOnSpace={selectItemOnSpace}
        value={value}
      >
        {children}
      </Menu>
    </Popover>
  );
};

export default DropdownMenu;
