import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid, Row, Column } from '../grid';
import AddressSummary from '../address-summary';
import ShippingMethodSummary from '../shipping-method-summary';
import { AddressType } from '../../constants/address-type';
import MobileAddressSummary from '../mobile-address-summary';
import isSmallScreen from '../../utilities/is-small-screen';

if (process.browser) {
  require('./payment-step-info-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-step-info-summary');

const PaymentStepInfoSummary = ({
  countries,
  selectedDeliveryOptions,
  contactInfoUrl,
  shippingMethodUrl,
  destination,
}) => (
  <section className={bem.block()}>
    <div>
      <Grid>
        <Row>
          {isSmallScreen() ? (
            <Column xs={12} sm={6}>
              <MobileAddressSummary
                type={AddressType.SHIPPING}
                countries={countries}
                address={destination}
                editUrl={contactInfoUrl}
              />
            </Column>
          ) : (
            <Column xs={12} sm={6}>
              <AddressSummary
                type={AddressType.SHIPPING}
                countries={countries}
                address={destination}
                editUrl={contactInfoUrl}
              />
            </Column>
          )}
          <Column xs={12} sm={6}>
            <ShippingMethodSummary
              deliveryOptions={selectedDeliveryOptions}
              editUrl={shippingMethodUrl}
            />
          </Column>
        </Row>
      </Grid>
    </div>
  </section>
);

PaymentStepInfoSummary.displayName = 'PaymentStepInfoSummary';

PaymentStepInfoSummary.propTypes = {
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  selectedDeliveryOptions: PropTypes.arrayOf(ApiPropTypes.deliveryOption).isRequired,
  destination: ApiPropTypes.orderAddress.isRequired,
  contactInfoUrl: PropTypes.string.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
};

export default PaymentStepInfoSummary;
