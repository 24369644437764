import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FormSection } from 'redux-form';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import BemHelper from '@flowio/bem-helper';

import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import { Grid, Row, Column } from '../grid';
import Prompt from '../prompt';
import Section from '../section';
import TextField from '../redux-form/text-field';
import optinPromptPropTypes from '../../utilities/prop-types/optin-prompt-prop-types';
import OrderAttributes from '../../constants/order-attributes';

if (process.browser) {
  require('./customer-information.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('customer-information');

/**
 *
 * @param {string} label
 * @param {string} countryCode
 * @returns string
 */
const getTaxIdName = (label, countryCode) => {
  const taxIdName = countryCode.toUpperCase() === 'MEX' ? OrderAttributes.MEXICO_RFC : null;
  if (taxIdName == null) {
    return label;
  }
  return `${label} (${taxIdName})`;
};

/**
 * @typedef {object} Props
 * @property {io.flow.internal.v0.models.OptinPrompt[]} optinPrompts
 * @property {boolean} shouldCollectCustomerTaxId
 * @property {import('react-intl').IntlShape} intl
 * @property {string} countryCode
 */

/**
 * @type {React.FC<Props>}
 */
const CustomerInformation = ({
  optinPrompts,
  shouldCollectCustomerTaxId,
  intl,
  countryCode,
}) => {
  const emailOptins = filter(optinPrompts, (optin) => get(optin, 'display.display_position') === 'email');

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
        <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
          <Section.Header>
            <Section.Title>
              <FormattedMessage
                id="checkout_customer_information_heading"
                description="A title denoting a section of the checkout experience for capturing customer information"
                defaultMessage="Customer Info"
              />
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <Grid parent={isMobileUXSpacingEnabled}>
              <Row>
                <Column xs={12}>
                  <Field
                    autoComplete="shipping email"
                    automationId="customer-email"
                    component={TextField}
                    labelText={(
                      <FormattedMessage
                        id="checkout_customer_information_field_label_email"
                        description="A label denoting where the customer is to provide their email address"
                        defaultMessage="Email"
                      />
                    )}
                    name="email"
                    type="email"
                    required
                  />
                </Column>
                {shouldCollectCustomerTaxId && (
                <Column xs={12}>
                  <Field
                    automationId="customer-tax-id"
                    component={TextField}
                    labelText={(
                      getTaxIdName(
                        intl.formatMessage({
                          id: 'checkout_customer_information_field_label_tax_id',
                          defaultMessage: 'Tax ID',
                          description: 'A label denoting where the customer is to provide their Tax ID',
                        }),
                        countryCode,
                      )
                    )}
                    name="tax_id"
                  />
                </Column>
                )}
              </Row>
              {!isEmpty(emailOptins) && (
                <Row>
                  <Column xs={12}>
                    <FormSection className={bem.element('prompt-group')} name="optinPrompts">
                      {map(emailOptins, (emailOptin) => (
                        <Prompt key={emailOptin.id} prompt={emailOptin} />
                      ))}
                    </FormSection>
                  </Column>
                </Row>
              )}
            </Grid>
          </Section.Content>
        </Section>
      )}
    />
  );
};

CustomerInformation.propTypes = {
  optinPrompts: PropTypes.arrayOf(PropTypes.shape(optinPromptPropTypes)),
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
  shouldCollectCustomerTaxId: PropTypes.bool,
  countryCode: PropTypes.string.isRequired,
};

CustomerInformation.defaultProps = {
  optinPrompts: [],
  shouldCollectCustomerTaxId: false,
};

CustomerInformation.displayName = 'CustomerInformation';

export default injectIntl(CustomerInformation);
