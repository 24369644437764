/*
 * TODO: Replace these prop types with those exported by @flowio/api-internal-prop-types
 * https://app.apibuilder.io/flow/optin-internal/latest#model-optin_prompt
 */

import PropTypes from 'prop-types';
import PromptOptions from '../../constants/prompt-options';
import localizationPropTypes from './localization-prop-types';
import localizedContentReferencePropTypes from './localizable-content-reference-prop-types';
import optinAttributePropTypes from './optin-attribute-prop-types';

const optinPromptPropTypes = {
  id: PropTypes.string.isRequired,
  optin_attribute: PropTypes.shape(optinAttributePropTypes).isRequired,
  region: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOf([
    PromptOptions.CONSENT_BY_DEFAULT,
    PromptOptions.NOTICE_ONLY,
    PromptOptions.REQUIRE_CONSENT,
  ])).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.shape(localizationPropTypes),
    PropTypes.shape(localizedContentReferencePropTypes),
  ]).isRequired,
  position: PropTypes.number.isRequired,
  enforce_on: PropTypes.string.isRequired,
};

export default optinPromptPropTypes;
