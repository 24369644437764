import type { AddressConfigurationsGetResponse } from '@flowio/api-sdk';
import { getAddressConfigurationByCountryCode } from '../selectors';
import actionTypes from '../../constants/action-types';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { RootAction, ThunkResult } from '../../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';

type Result = Promise<LegacyResponseWithLegacyError<AddressConfigurationsGetResponse>>;

const fetchAddressConfigurationRequest = (): RootAction => ({
  type: actionTypes.FETCH_ADDRESS_CONFIGURATION_REQUEST,
});

const fetchAddressConfigurationSuccess = (
  addressConfigurations: io.flow.v0.models.AddressConfiguration[],
): RootAction => ({
  type: actionTypes.FETCH_ADDRESS_CONFIGURATION_SUCCESS,
  payload: addressConfigurations,
});

const fetchAddressConfigurationFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_ADDRESS_CONFIGURATION_FAILURE,
  payload: error,
});

const fetchAddressConfiguration = (
  organizationId: string,
  countryCode: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const addressConfiguration = getAddressConfigurationByCountryCode(countryCode)(getState());

  if (addressConfiguration != null) {
    return Promise.resolve({
      ok: true,
      result: [addressConfiguration],
      status: 200,
    });
  }

  dispatch(fetchAddressConfigurationRequest());

  return extra.api.addressConfigurations.get({
    organization: organizationId,
    country: [countryCode],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchAddressConfigurationSuccess(response.result));
          break;
        case 401:
          dispatch(fetchAddressConfigurationFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchAddressConfiguration;
