/**
 * @see https://app.apibuilder.io/flow/optin-internal/latest#enum-prompt_options
 */
const PromptOptions = {
  NOTICE_ONLY: 'notice_only',
  REQUIRE_CONSENT: 'require_consent',
  CONSENT_BY_DEFAULT: 'consent_by_default',
};

export default PromptOptions;
