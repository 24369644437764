import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

import { getCustomerServiceLabel, getCustomerServiceContents } from '../../store/content/selectors';
import { Grid, Row, Column } from '../grid';
import ContentItem from '../content-item';
import ContentLabel from '../content-label';
import Section from '../section';

if (process.browser) {
  require('./customer-service.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('customer-service');

const CustomerService = ({ contents, title }) => {
  // Avoid rendering empty customer section when data is not available.
  if (title == null || contents == null || !contents.length) {
    return null;
  }

  return (
    <Section className={bem.block()}>
      <Section.Header divided>
        <Section.Title>
          <ContentLabel content={title} />
        </Section.Title>
      </Section.Header>
      <Section.Content>
        <Grid>
          <Row>
            {map(contents, (content, index) => (
              <Column key={index} xs={12} md={6} lg={12}>
                <ContentItem content={content} />
              </Column>
            ))}
          </Row>
        </Grid>
      </Section.Content>
    </Section>
  );
};

CustomerService.displayName = 'CustomerService';

CustomerService.propTypes = {
  title: PropTypes.oneOfType([
    ApiInternalPropTypes.label, // @deprecated
    ApiInternalPropTypes.contentLabel,
  ]).isRequired,
  contents: PropTypes.arrayOf(ApiInternalPropTypes.contentItem).isRequired,
};

export default connect(createStructuredSelector({
  title: getCustomerServiceLabel,
  contents: getCustomerServiceContents,
}))(CustomerService);
