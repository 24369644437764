import keyBy from 'lodash/keyBy';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { AddressConfigurationState } from '../types';

const initialState: AddressConfigurationState = {};

const addressConfigurationReducer: AppReducer<AddressConfigurationState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_ADDRESS_CONFIGURATION_SUCCESS:
      return {
        ...previousState,
        ...keyBy(action.payload, (_) => _.country),
      };
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return {
        ...previousState,
        ...keyBy(action.payload.address_configurations, (_) => _.country),
      };
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return {
        ...previousState,
        ...keyBy(action.payload.addresses, (_) => _.country),
      };
    case actionTypes.SET_ADDRESS_CONFIGURATION:
      return {
        ...previousState,
        [action.payload.country]: action.payload,
      };
    default:
      return previousState;
  }
};

export default addressConfigurationReducer;
