import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import OperationTypes from '../../../utilities/redux/operation-types';
import ReadyState from '../../../utilities/redux/ready-state';
import type { AppReducer } from '../../types';
import type { CheckoutStatusesState } from '../types';

const initialState: CheckoutStatusesState = {
  checkout: {
    state: ReadyState.Pending,
  },
  featureValues: {
    state: ReadyState.Pending,
  },
  order: {},
  payment: {},
  publicKey: {
    state: ReadyState.Pending,
  },
  receipt: {},
  isFirstTimeVisitor: true,
  vrn: {},
};

const statusesReducer: AppReducer<CheckoutStatusesState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CREATE_SHOPIFY_ORDER_FAILURE:
    case actionTypes.CREATE_ORDER_BUILDER_FAILURE:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Create,
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.CREATE_SHOPIFY_ORDER_REQUEST:
    case actionTypes.CREATE_ORDER_BUILDER_REQUEST:
    case actionTypes.CREATE_CHECKOUT_ORDER_REQUEST:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Create,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.CREATE_SHOPIFY_ORDER_SUCCESS:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Create,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.CREATE_ORDER_BUILDER_SUCCESS: {
      // TODO: Ha. An order builder can have an order and errors at the same time!
      // Anyways, what's the point of this whole state slice?
      if (action.payload.errors != null) {
        return update(previousState, {
          order: {
            $set: {
              operation: OperationTypes.Create,
              state: ReadyState.Rejected,
            },
          },
        });
      }

      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Create,
            state: ReadyState.Fulfilled,
          },
        },
      });
    }
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS: {
      if (action.payload.builder.errors != null) {
        return update(previousState, {
          order: {
            $set: {
              operation: OperationTypes.Create,
              state: ReadyState.Rejected,
            },
          },
        });
      }

      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Create,
            state: ReadyState.Fulfilled,
          },
        },
      });
    }
    case actionTypes.CHECK_VRN_NUMBER_REQUEST:
      return update(previousState, {
        vrn: {
          $set: {
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.CHECK_VRN_NUMBER_SUCCESS:
      return update(previousState, {
        vrn: {
          $set: {
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.CANCELLED_CHECK_VRN_REQUEST:
      return update(previousState, {
        vrn: {
          $set: {
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.CHECK_VRN_NUMBER_FAILURE:
      return update(previousState, {
        vrn: {
          $set: {
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.FETCH_ORDER_REQUEST:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.FETCH_ORDER_SUCCESS:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.FETCH_ORDER_FAILURE:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Read,
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.ADD_PROMOTION_CODE_FAILURE:
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_FAILURE:
    case actionTypes.REMOVE_PROMOTION_CODE_FAILURE:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_FAILURE:
    case actionTypes.UPDATE_BILLING_ADDRESS_FAILURE:
    case actionTypes.UPDATE_DELIVERED_DUTY_FAILURE:
    case actionTypes.UPDATE_ORDER_BUILDER_FAILURE:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE:
    case actionTypes.UPDATE_SHIPPING_METHOD_FAILURE:
    case actionTypes.SET_ORDER_ATTRIBUTES_FAILURE:
    case actionTypes.SUBMIT_ORDER_FAILURE:
    case actionTypes.SUBMIT_ORDER_BUNDLE_FAILURE:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.ADD_PROMOTION_CODE_REQUEST:
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_REQUEST:
    case actionTypes.REMOVE_PROMOTION_CODE_REQUEST:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_REQUEST:
    case actionTypes.UPDATE_BILLING_ADDRESS_REQUEST:
    case actionTypes.UPDATE_DELIVERED_DUTY_REQUEST:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_REQUEST:
    case actionTypes.UPDATE_SHIPPING_METHOD_REQUEST:
    case actionTypes.SET_ORDER_ATTRIBUTES_REQUEST:
    case actionTypes.SUBMIT_ORDER_REQUEST:
    case actionTypes.SUBMIT_ORDER_BUNDLE_REQUEST:
    case actionTypes.UPDATE_ORDER_BUILDER_REQUEST:
    case actionTypes.ADD_GIFT_CARD_V2_REQUEST:
    case actionTypes.REMOVE_GIFT_CARD_REQUEST:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Loading,
          },
        },
      });
    case actionTypes.ADD_PROMOTION_CODE_SUCCESS:
    case actionTypes.ADD_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.REMOVE_PROMOTION_CODE_SUCCESS:
    case actionTypes.REMOVE_SHOPIFY_PROMO_CODE_SUCCESS:
    case actionTypes.REMOVE_GENERIC_PROMOTION_CODE_SUCCESS:
    case actionTypes.SUBMIT_ORDER_SUCCESS:
    case actionTypes.SUBMIT_ORDER_BUNDLE_SUCCESS:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.SUBMIT_ORDER_CHECKOUT_SUCCESS:
      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.UPDATE_BILLING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case actionTypes.UPDATE_SHIPPING_METHOD_SUCCESS:
    case actionTypes.SET_ORDER_ATTRIBUTES_SUCCESS:
    case actionTypes.ADD_GIFT_CARD_V2_SUCCESS:
    case actionTypes.REMOVE_GIFT_CARD_SUCCESS:
    case actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS:
    case actionTypes.UPDATE_ORDER_BUILDER_SUCCESS: {
      if (action.payload.errors != null) {
        return update(previousState, {
          order: {
            $set: {
              operation: OperationTypes.Update,
              state: ReadyState.Rejected,
            },
          },
        });
      }

      return update(previousState, {
        order: {
          $set: {
            operation: OperationTypes.Update,
            state: ReadyState.Fulfilled,
          },
        },
      });
    }
    case actionTypes.FETCH_PUBLIC_KEY_REQUEST:
      return update(previousState, {
        publicKey: {
          operation: {
            $set: OperationTypes.Read,
          },
          state: {
            $set: ReadyState.Loading,
          },
        },
      });
    case actionTypes.FETCH_PUBLIC_KEY_SUCCESS:
      return update(previousState, {
        publicKey: {
          operation: {
            $set: OperationTypes.Read,
          },
          state: {
            $set: ReadyState.Fulfilled,
          },
        },
      });
    case actionTypes.FETCH_PUBLIC_KEY_FAILURE:
      return update(previousState, {
        publicKey: {
          operation: {
            $set: OperationTypes.Read,
          },
          state: {
            $set: ReadyState.Rejected,
          },
        },
      });
    case actionTypes.SET_VISITED:
      return update(previousState, {
        isFirstTimeVisitor: {
          $set: false,
        },
      });
    case actionTypes.CHECK_FEATURE_VALUES_REQUEST:
      return update(previousState, {
        featureValues: {
          state: { $set: ReadyState.Loading },
        },
      });
    case actionTypes.CHECK_FEATURE_VALUES_FAILURE:
      return update(previousState, {
        featureValues: {
          state: { $set: ReadyState.Rejected },
        },
      });
    case actionTypes.CHECK_FEATURE_VALUES_SUCCESS:
      return update(previousState, {
        featureValues: {
          state: { $set: ReadyState.Fulfilled },
        },
      });
    case actionTypes.FETCH_CHECKOUT_REQUEST:
      return update(previousState, {
        checkout: {
          state: { $set: ReadyState.Loading },
        },
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        checkout: {
          state: { $set: ReadyState.Fulfilled },
        },
      });
    case actionTypes.FETCH_CHECKOUT_FAILURE:
      return update(previousState, {
        checkout: {
          state: { $set: ReadyState.Rejected },
        },
      });
    default:
      return previousState;
  }
};

export default statusesReducer;
