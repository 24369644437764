import Client from './client';

export default class OrderBuilders extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API';

    super(options);
  }

  post(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders`, {
      ...options,
       method: 'POST',
    });
  }

  putCartsByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/carts/${number}`, {
      ...options,
       method: 'PUT',
    });
  }

  getByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}`, options);
  }

  putByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}`, {
      ...options,
       method: 'PUT',
    });
  }

  putAttributesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/attributes`, {
      ...options,
       method: 'PUT',
    });
  }

  putCountryByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/country`, {
      ...options,
       method: 'PUT',
    });
  }

  putDestinationByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/destination`, {
      ...options,
       method: 'PUT',
    });
  }

  putDutyByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/duty`, {
      ...options,
       method: 'PUT',
    });
  }

  putInvoiceAndAddressByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/invoice/address`, {
      ...options,
       method: 'PUT',
    });
  }

  postLinesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines`, {
      ...options,
       method: 'POST',
    });
  }

  putLinesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines`, {
      ...options,
       method: 'PUT',
    });
  }

  putLinesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteLinesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  putLinesAndAttributesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines/${id}/attributes`, {
      ...options,
       method: 'PUT',
    });
  }

  putLinesAndQuantityByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/lines/${id}/quantity`, {
      ...options,
       method: 'PUT',
    });
  }

  putTaxAndRegistrationByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order/builders/${number}/tax/registration`, {
      ...options,
       method: 'PUT',
    });
  }

}
