/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const CircleCheck = ({ ...unhandledProps }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    {...unhandledProps}
  >
    <g fillRule="evenodd">
      <path d="m11.312 15.224c-0.17 0.17-0.39217 0.25478-0.61478 0.25478s-0.44522-0.084783-0.61478-0.25478l-2.6087-2.6087c-0.33957-0.33957-0.33957-0.89 0-1.2296s0.89-0.33957 1.2296 0l1.9939 1.9939 4.6026-4.6026c0.33957-0.33957 0.89-0.33957 1.2296 0 0.33957 0.33957 0.33957 0.89 0 1.2296l-5.2174 5.2174zm0.68957-13.223c-7.1965 0-10 2.8035-10 10 0 7.1965 2.8035 10 10 10 7.1965 0 10-2.8035 10-10 0-7.1965-2.8035-10-10-10" />
    </g>
  </SvgIcon>
);

CircleCheck.displayName = 'CircleCheck';

export default CircleCheck;
