import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import assign from 'lodash/assign';
import isError from 'lodash/isError';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import uniqueId from 'lodash/uniqueId';
import formatErrorResponse from '../../utilities/format-error-response';
import checkHttpStatus from '../../utilities/check-http-status';
import { isAsyncAction, normalizeTypeDescriptors, validateAsyncAction } from './utilities';

const omitByUndefined = (object) => omitBy(object, isUndefined);

export default function createAsyncMiddleware(options = {}) {
  return (store) => (next) => (action) => {
    const {
      callAPI,
      messages = {},
      onBeforeFailure = options.onBeforeFailure,
      onBeforeSuccess = options.onBeforeSuccess,
      onFailure = options.onFailure,
      onSuccess = options.onSuccess,
      optimistic = false, // Enables integration with redux optimist
      payload = {},
      shouldCallAPI = () => true,
      types,
    } = action;

    const { dispatch, getState } = store;

    if (!isAsyncAction(action)) {
      return next(action);
    }

    validateAsyncAction(action);

    const prevState = getState();

    if (!shouldCallAPI(prevState, dispatch)) {
      return Promise.resolve();
    }

    const transactionId = uniqueId('transaction');

    let [requestType, successType, failureType] = normalizeTypeDescriptors(types);

    return new Promise((resolve, reject) => {
      requestType = assign({}, requestType, payload, omitByUndefined({
        payload: isFunction(requestType.payload)
          ? requestType.payload(prevState)
          : requestType.payload,
        optimist: optimistic ? { type: BEGIN, transactionId } : undefined,
      }));

      dispatch(requestType);
      callAPI(prevState)
        .then(checkHttpStatus)
        .then((response) => {
        // Materialize success action object
          successType = assign({}, successType, payload, omitByUndefined({
            payload: isFunction(successType.payload)
              ? successType.payload(prevState, response)
              : successType.payload,
            optimist: optimistic ? { type: COMMIT, transactionId } : undefined,
          }));

          // Materialize parameter object for lifecycle callbacks
          const paramsObject = {
            dispatch,
            status: response.status,
            prevState,
            result: successType,
          };

          // Trigger success lifecycle callbacks
          if (!isFunction(onBeforeSuccess) || onBeforeSuccess(paramsObject) !== false) {
            dispatch(successType);

            if (isFunction(onSuccess)) {
              paramsObject.nextState = getState();
              onSuccess(paramsObject);
            }
          }

          // Resolve with response
          resolve(response);
        })
        .catch((error) => {
        // Reject immediately when error is a runtime error. The only error we
        // allow through is the one created by `checkHttpStatus` which should
        // have the response attached as a `response` property.
          if (!error.response && isError(error)) {
            return reject(error);
          }

          let response;

          // If error does not have a `response` property, then it must have
          // been part of a rejection in the `callAPI` option. This should only
          // occur when the Node SDK library encounters responses in the 5xx range.
          if (!error.response) {
            response = formatErrorResponse(error, { messages });
          } else {
            response = formatErrorResponse(error.response, { messages });
          }

          // Materialize failure action object.
          failureType = assign({}, failureType, payload, omitByUndefined({
            optimist: optimistic ? { type: REVERT, transactionId } : undefined,
            payload: isFunction(failureType.payload)
              ? failureType.payload(prevState, response)
              : failureType.payload,
          }));

          // Materialize parameter object for lifecycle callbacks.
          const paramsObject = {
            dispatch,
            status: response.status,
            prevState,
            result: failureType,
          };

          // Trigger failure lifecycle callbacks
          if (!isFunction(onBeforeFailure) || onBeforeFailure(paramsObject) !== false) {
            dispatch(failureType);

            if (isFunction(onFailure)) {
              paramsObject.nextState = getState();
              onFailure(paramsObject);
            }
          }

          return resolve(response);
        });
    });
  };
}
