export type SortOrder = 'ascending' | 'descending';

export interface PaginationResult<T> {
  results: T[];
  isLastPage: boolean;
  isFirstPage: boolean;
}

interface Args {
  entriesPerPage: string | number;
  pageNumber: string | number;
  sortName?: string;
  sortOrder?: SortOrder
}

interface ReturnType {
  limit: number;
  offset: number;
  sort?: string;
  paginationResult: <T>(results: T[]) => PaginationResult<T>;
}

/**
 * Use this helper to deal with endpoints that return paginated results.
 *
 * Pagination is not yet implemented by FLOW API so this just encapsultes
 * a convention that we have used to deal with paged results.
 */
export function usePagingAndSorting(args: Args): ReturnType {
  const {
    entriesPerPage,
    pageNumber,
    sortOrder,
    sortName,
  } = args;

  let limit: number;
  let offset: number;
  let sort: string | undefined;

  if (typeof entriesPerPage === 'string') {
    limit = parseInt(entriesPerPage, 10);
  } else {
    limit = entriesPerPage;
  }

  if (typeof pageNumber === 'string') {
    offset = parseInt(pageNumber, 10) - 1;
  } else {
    offset = pageNumber - 1;
  }

  if (sortName != null) {
    if (sortOrder === 'ascending') {
      sort = `+${sortName}`;
    } else if (sortOrder === 'descending') {
      sort = `-${sortName}`;
    } else {
      sort = sortName;
    }
  }

  offset *= limit;

  // By requesting an additional item as part of the request we can determine
  // whether the user has reached the last page. Later we just need to make
  // sure we are not sending more items than requested.
  limit += 1;

  function paginationResult<T>(
    results: T[],
  ): PaginationResult<T> {
    const isFirstPage = offset === 0;
    const isLastPage = results.length !== limit;
    return {
      isFirstPage,
      isLastPage,
      results: isLastPage ? results : results.slice(0, results.length - 1),
    };
  }

  return {
    limit,
    offset,
    sort,
    paginationResult,
  };
}
