// @ts-check
import CheckoutSubmissionError from './checkout-submission-error';

/**
 * @typedef {io.flow.internal.v0.models.CheckoutSubmission} CheckoutSubmission
 */

/**
 * @typedef {import('../../common/services/types').Response<T>} Response
 * @template T
 */

/**
 * Check the order builder api response for errors. Instead of returning
 * a 422 when errors are present the `order_builder` resource returns an
 * object containing both the order and any errors from the request as a 2xx.
 * @param {Response<CheckoutSubmission>} response - Response object from `@flowio/node-sdk` package.
 * @returns {Response<CheckoutSubmission>}
 */
export default function checkCheckoutSubmissionStatus(response) {
  if (response.result && response.result.builder && response.result.builder.errors) {
    // @ts-ignore
    throw new CheckoutSubmissionError(response);
  } else {
    return response;
  }
}
