import { BaseFieldProps, EventWithDataHandler } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import { PostalCodeType, getPostalCodeType } from '../../utilities/address-utilities';
import ClearableField from '../redux-form/clearable-field';
import TextField, {
  BaseProps as TextFieldBaseProps,
} from '../redux-form/text-field';

type Props = {
  addressType?: AddressType;
  countryCode: string;
  onChange?: EventWithDataHandler<React.ChangeEvent<HTMLInputElement>>;
  required?: boolean;
};

const getLabelText = (
  countryCode: string,
): React.ReactElement => {
  switch (getPostalCodeType(countryCode)) {
    case PostalCodeType.ZIP:
      return (
        <FormattedMessage
          id="checkout_address_field_label_zip_code"
          description="Content for postal code field label in address forms applied when the selected country is United States"
          defaultMessage="Zip Code"
        />
      );
    default:
      return (
        <FormattedMessage
          id="checkout_address_field_label_postal_code"
          description="Content for postal code field label in address forms applied when the selected country is not United States"
          defaultMessage="Postal Code"
        />
      );
  }
};

const PostalCode: React.FunctionComponent<Props> = ({
  addressType,
  countryCode,
  onChange,
  required = false,
}) => (
  <ClearableField<BaseFieldProps<TextFieldBaseProps>>
    component={TextField}
    onChange={onChange}
    props={{
      autoComplete: autoCompleteToken('postal-code', addressType),
      automationId: automationToken('postal-code', addressType),
      labelText: getLabelText(countryCode),
      required,
    }}
    name="postalCode"
  />
);

export default PostalCode;
