// @ts-check

import every from 'lodash/every';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';

/**
 * @typedef {io.flow.v0.models.Order} Order
 */

/**
 * @typedef {io.flow.v0.models.OrderPutForm} OrderPutForm
 */

/**
 * @typedef {io.flow.v0.models.OrderAddress} OrderAddress
 */

/**
 * @typedef {io.flow.v0.models.LocalizedLineItem} LocalizedLineItem
 */

/**
 * @typedef {io.flow.v0.models.LineItemForm} LineItemForm
 */

/**
 * @typedef {io.flow.v0.models.DiscountsForm} DiscountsForm
 */

/**
 * @typedef {io.flow.v0.models.DiscountForm} DiscountForm
 */

/**
 * @param {OrderAddress} destination
 * @returns {boolean}
 */
function validateDestination(destination) {
  const required = ['streets', 'city', 'country', 'contact.name.first', 'contact.name.last'];
  return every(required, (prop) => !isUndefined(get(destination, prop)));
}

/**
 * @param {OrderAddress} destination
 * @return {(OrderAddress | undefined)}
 */
function normalizeDestination(destination) {
  return validateDestination(destination) ? destination : undefined;
}

/**
 * @param {LocalizedLineItem} item
 * @return {DiscountsForm}
 */
export function getDiscounts(item) {
  /** @type {DiscountForm[]} */
  let discounts = [];
  if (item.discounts != null) {
    discounts = item.discounts.map((discount) => ({
      offer: {
        discriminator: 'discount_offer_fixed',
        money: {
          amount: discount.amount,
          currency: discount.currency,
        },
      },
      label: discount.discount_label,
    }));
  }
  return { discounts };
}

/**
 * @param {LocalizedLineItem} item
 * @return {Partial<LineItemForm>}
 * @deprecated prefer `lineItemFormConverter.fromLocalizedLineItem` for proper type safety.
 */
export function toLineItemForm(item) {
  return omitBy({
    number: get(item, 'number'),
    quantity: get(item, 'quantity'),
    shipment_estimate: get(item, 'shipment_estimate'),
    price: get(item, 'price'),
    attributes: get(item, 'attributes'),
    center: get(item, 'center'),
    discount: get(item, 'discount'),
    discounts: getDiscounts(item),
  }, isUndefined);
}

/**
 * @param {Order} order
 * @returns {Partial<OrderPutForm>}
 */
export function toOrderForm(order) {
  return omitBy({
    customer: get(order, 'customer'),
    items: map(get(order, 'items'), toLineItemForm),
    delivered_duty: get(order, 'delivered_duty'),
    number: get(order, 'number'),
    destination: normalizeDestination(get(order, 'destination')),
    // Cannot be inferred from order model
    discount: undefined,
    attributes: get(order, 'attributes'),
    device_details: get(order, 'device_details'),
    // Cannot be inferred from order model
    authorization_keys: undefined,
  }, isUndefined);
}

/**
 * @param {Order} order
 * @returns {Partial<OrderPutForm>}
 * @deprecated use `orderPutFormConverter.fromOrder` instead for proper type safety.
 */
export function toOrderPutForm(order) {
  return omitBy({
    items: map(get(order, 'items'), toLineItemForm),
    customer: get(order, 'customer'),
    delivered_duty: get(order, 'delivered_duty'),
    selections: get(order, 'selections'),
    destination: normalizeDestination(get(order, 'destination')),
    // Cannot be inferred from order model
    discount: undefined,
    attributes: get(order, 'attributes'),
    device_details: get(order, 'device_details'),
    // Cannot be inferred from order model
    authorization_keys: undefined,
  }, isUndefined);
}
