import { applePay } from '@flowio/payment';
import { ThunkResult } from '../../types';
import { getApplePayMerchantId, getOrganizationData } from '../selectors';

export default function fetchApplePayAvailibility()
: ThunkResult<Promise<applePay.ApplePayAvailabilityResponse>> {
  return (
    dispatch,
    getState,
  ) => {
    const state = getState();
    const applePayMerchantIds = getApplePayMerchantId(state);
    const organizationData = getOrganizationData(state);

    if (!organizationData || !applePayMerchantIds) {
      return Promise.reject(new Error('fetchApplePayAvailibility: Expected data not available'));
    }
    return applePay.getAvailability().then((result) => {
      dispatch({
        type: 'APPLE_PAY_AVAILABILITY',
        payload: result,
      });
      return result;
    });
  };
}
