import Rollbar from 'rollbar';

const environment = process.env.NODE_ENV || 'development';

let rollbar: Rollbar | undefined;

/**
 * Returns instance of Rollbar with the appropriate configuration for this application.
 */
export function createRollbar(): Rollbar {
  return new Rollbar({
    accessToken: '3069f41a18344e118fccebdeea9ded99', // This is a token to identify the app, not private
    captureUncaught: false,
    captureIp: 'anonymize',
    payload: {
      environment,
    },
    scrubTelemetryInputs: true,
  });
}

export function getRollbar(): Rollbar {
  if (rollbar != null) return rollbar;
  rollbar = createRollbar();
  return rollbar;
}

/**
 * Returns instance of the rollbar object. Enables 'singleton' behavior by returning existing
 * instance if defined already.
 * @deprecated Prefer getRollbar function.
 */
export function getInstance(): Rollbar {
  return getRollbar();
}
