import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import property from 'lodash/property';
import { createStructuredSelector } from 'reselect';

import InternalServerError from '../components/internal-server-error';
import { fetchContent } from '../store/content/actions';
import { getLocale } from '../store/intl/selectors';
import { getOrderNumber } from '../store/checkout/selectors';

const getOrganizationId = property('params.organization');

const getAsyncState = (dispatch, getState, props) => Promise.all([
  dispatch(fetchContent(getOrganizationId(props), {
    params: {
      locale: getLocale(getState()),
    },
  })),
]);

const mapStateToProps = createStructuredSelector({
  orderNumber: getOrderNumber,
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps),
)(InternalServerError);
