import { defineMessages, useIntl } from 'react-intl';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import React, { useEffect } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

import Message from '../message';

interface Props {
  className?: string;
  error?: any;
  orderNumber?: string;
}

const messages = defineMessages({
  unknownErrorMessage: {
    id: 'checkout_unknown_error_message',
    description: 'A message describing that an unexpected error has occurred',
    defaultMessage: 'An error has occurred while processing your request. Please try again.',
  },
});

const UnknownError: React.FunctionComponent<Props> = ({
  className,
  error,
  orderNumber,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      getRollbar((rollbar, extra = {}) => {
        rollbar.warning('An unknown error has occurred', error, { ...extra, orderNumber });
      });
    }
  }, []);

  const errorMessages = get(error, 'messages', [
    intl.formatMessage(messages.unknownErrorMessage),
  ]);

  return (
    <Message className={className} data-automation-id="unknown-error" color="negative">
      {map(errorMessages, (errorMessage) => (
        <Message.Text key={errorMessage}>{errorMessage}</Message.Text>
      ))}
    </Message>
  );
};

export default UnknownError;
