/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

if (process.browser) {
  require('./badge.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('badge');

class Badge extends PureComponent {
  render() {
    const {
      children, className, color, content, floating,
    } = this.props;
    const ElementType = getElementType(Badge, this.props);
    const unhandledProps = getUnhandledProps(Badge, this.props);
    const modifiers = { [color]: true, floating };

    return (
      <ElementType {...unhandledProps} className={bem.block(className)}>
        {children}
        <span className={bem.element('content', modifiers)}>
          {content}
        </span>
      </ElementType>
    );
  }
}

Badge.displayName = 'Badge';

Badge.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'positive', 'negative']),
  content: PropTypes.node,
  floating: PropTypes.bool,
};

Badge.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  color: 'default',
  content: undefined,
  floating: false,
};

export default Badge;
