const ipv4LoopbackAddressRegex = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;

/**
 * Determines whether the current environment is the localhost.
 */
export default function isLocalhost(
  hostname: string,
): boolean {
  return hostname === 'localhost' || hostname === '[::1]' || ipv4LoopbackAddressRegex.test(hostname);
}
