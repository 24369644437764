/**
 * @fileoverview A map of enumerations for each operation available while
 * interacting with Flow REST API. Can be used in combination with ReadyState
 * enumerations to determine the state of each operation.
 */
enum OperationTypes {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export default OperationTypes;
