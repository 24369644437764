import postRedirect from '../../../utilities/post-redirect';
import { redirect } from '../../application/actions';

export default function authorizeRedirect(dispatch, authorizationResultAction) {
  switch (authorizationResultAction.discriminator) {
    case 'authorization_result_action_get':
      dispatch(redirect(authorizationResultAction.url));
      return Promise.race([]);
    case 'authorization_result_action_post':
      postRedirect(authorizationResultAction.url, authorizationResultAction.parameters);
      return Promise.race([]);
    default:
      throw new Error(`Unexpected authorization result action with "${authorizationResultAction.discriminator}" discriminator provided`);
  }
}
