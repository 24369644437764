import { getReviewEntities, getKlarnaRedirectEnabled } from '../selectors';
import submitPaymentInformation from './submit-payment-information';

export default function submitPaymentInformationFromReview(
  isCheckoutResourceEnabled,
) {
  return function submitPaymentInformationFromReviewSideEffects(dispatch, getState) {
    const state = getState();
    const isKlarnaRedirectEnabled = getKlarnaRedirectEnabled(state);
    return dispatch(
      submitPaymentInformation(
        {
          values: getReviewEntities(state),
          isCheckoutResourceEnabled,
          isKlarnaRedirectEnabled,
        },
      ),
    );
  };
}
