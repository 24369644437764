import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import { bindActionCreators, Dispatch } from 'redux';
import { injectIntl } from 'react-intl';
import compact from 'lodash/compact';
import { RootState, RootActionTypes } from '../../../store/types';

import {
  getAddressConfigurationByCountryCode,
  getDefaultAddressConfigurationByCountryCode,
  getEditAddressBookContactState,
  getAddressConfigurationOrUseDefaultByCountryCode,
} from '../../../store/checkout/selectors';
import { getLocale } from '../../../store/intl/selectors';
import { closeEditCustomerAddressBookContactDialog, updateCustomerAddressBookContact } from '../../../store/checkout/actions';
import {
  DispatchProps,
  FormData,
  MergedProps,
  OwnProps,
  StateProps,
} from '../types';
import { addressConfigurationToConstraints } from '../../../utilities/address-utilities';
import AddressBookContactDialog from '../components/address-book-contact-dialog';
import FormName from '../../../constants/form-name';
import addressFormValues from '../../../utilities/converters/address-form-values';
import validate from '../../../utilities/validate';
import checkHttpStatus from '../../../../common/utilities/check-http-status';

const getFormValue = formValueSelector(FormName.ADDRESS_BOOK_CONTACT);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => {
  const {
    isOpen,
    organizationId,
    contact,
    customer,
  } = getEditAddressBookContactState(state);

  let initialValues: Partial<FormData> = {};

  if (contact != null) {
    initialValues = addressFormValues.fromCustomerAddressBookContact(contact);
    initialValues.contactId = contact.id;
  }

  if (customer != null) {
    initialValues.customerNumber = customer.number;
  }

  initialValues.organizationId = organizationId;

  const initialCountryCode = initialValues.country;
  const countryCode = getFormValue(state, 'country') || initialCountryCode;
  const addressConfiguration = getAddressConfigurationOrUseDefaultByCountryCode(countryCode)(state);

  return {
    addressConfiguration,
    isOpen,
    initialValues,
    locale: getLocale(state),
    selectedCountryCode: countryCode,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<RootActionTypes>,
) => bindActionCreators({
  onClose: closeEditCustomerAddressBookContactDialog,
}, dispatch);

const mapFormDataToCustomerAddressBookContactForm = (
  values: FormData,
): io.flow.v0.models.CustomerAddressBookContactForm => {
  const {
    addressLine1,
    addressLine2,
    administrativeArea,
    country,
    firstName,
    lastName,
    locality,
    organizationName,
    phoneNumber,
    postalCode,
  } = values;

  return {
    address: {
      city: locality,
      country,
      postal: postalCode,
      province: administrativeArea,
      streets: compact([addressLine1, addressLine2]),
    },
    contact: {
      company: organizationName,
      name: {
        first: firstName,
        last: lastName,
      },
      phone: phoneNumber,
    },
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(
    reduxForm<FormData, MergedProps>({
      form: FormName.ADDRESS_BOOK_CONTACT,
      enableReinitialize: true,
      keepDirtyOnReinitialize: false,
      validate(values, props) {
        const { addressConfiguration, intl } = props;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const { formatMessage } = intl;
        const constraints = addressConfigurationToConstraints(addressConfiguration, formatMessage);
        const errors = validate(values, constraints, {
          format: 'zipped',
          fullMessages: false,
        });
        return errors;
      },
      onSubmitSuccess(result, dispatch) {
        dispatch(closeEditCustomerAddressBookContactDialog());
      },
      onSubmit(values, dispatch) {
        return Promise.resolve()
          .then(() => {
            const {
              contactId,
              customerNumber,
              organizationId,
            } = values;
            return dispatch(updateCustomerAddressBookContact(
              organizationId,
              customerNumber,
              contactId,
              mapFormDataToCustomerAddressBookContactForm(values),
            ));
          })
          .then(checkHttpStatus)
          .catch((error) => {
            switch (error.name) {
              case 'HttpStatusError':
                throw new SubmissionError({
                  _error: error.response.result,
                });
              default:
                throw error;
            }
          });
      },
    })(AddressBookContactDialog),
  ),
);
