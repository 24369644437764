import type { OrderBuildersPostResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { CreateOrderBuilderActionParameters } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPostResponse>>;

const createOrderBuilderRequest = (
  params: CreateOrderBuilderActionParameters,
): RootAction => ({
  type: actionTypes.CREATE_ORDER_BUILDER_REQUEST,
  params,
});

const createOrderBuilderSuccess = (
  params: CreateOrderBuilderActionParameters,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.CREATE_ORDER_BUILDER_SUCCESS,
  params,
  payload: orderBuilder,
});

const createOrderBuilderFailure = (
  params: CreateOrderBuilderActionParameters,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.CREATE_ORDER_BUILDER_FAILURE,
  params,
  payload: error,
});

const createOrderBuilder = (
  organizationId: string,
  params: CreateOrderBuilderActionParameters,
  orderPutForm: io.flow.v0.models.OrderPutForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(createOrderBuilderRequest(params));
  return extra.api.orderBuilders.post({
    ...params,
    body: orderPutForm,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      if (response.ok) dispatch(createOrderBuilderSuccess(params, response.result));
      else dispatch(createOrderBuilderFailure(params, response.result));
      return response;
    });
};

// TODO: Rename file to match default export
export default createOrderBuilder;
