import { Dispatch, bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { createStructuredSelector } from 'reselect';
import { getOrderNumber, getCheckoutId } from '../store/checkout/selectors';
import CheckoutToken from '../components/checkout-token';
import { getOrganization } from '../store/flow/selectors';
import { RootActionTypes } from '../store/types';

type RootState = import('../store/types').RootState;

interface StateProps {
  checkoutId?: string;
  organizationId?: string;
  orderNumber?: string;
}

const actionCreators = {
  replace,
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checkoutId: getCheckoutId,
  organizationId: getOrganization,
  orderNumber: getOrderNumber,
});

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): typeof actionCreators => bindActionCreators(actionCreators, dispatch);

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhanced(CheckoutToken);
