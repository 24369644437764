import { FormattedMessage } from 'react-intl';
import React from 'react';
import BemHelper from '@flowio/bem-helper';

import './address-picker.css';

import { getContactById } from '../../../utilities/address-book-utilities';
import { Props } from '../types';
import { Grid, Row, Column } from '../../grid';
import Address from '../../../containers/address';
import AddressBookContact from '../../address-book-contact';
import Button from '../../button';
import RadioPanel from '../../radio-panel';
import RadioPanelGroup from '../../radio-panel-group';

const bem = new BemHelper('flow-address-picker');

const ViewMode = {
  DEFAULT: 'default',
  LISTING: 'listing',
};

const AddressPicker: React.FunctionComponent<Props> = ({
  addressBook,
  addressConfiguration,
  addressType,
  countries,
  customer,
  locale,
  input,
  meta,
  onAutoCompleteAddress,
  onEditAddressBookContact,
  onRemoveAddressBookContact,
  organizationId,
  selectedCompanyName,
  selectedCountryCode,
  selectedCustomerType,
  selectedVrn,
}): React.ReactElement => {
  const selectedContact = getContactById(addressBook, input.value);
  const initialViewMode = (selectedContact != null || input.value === 'sameAsShippingAddress') ? ViewMode.DEFAULT : ViewMode.LISTING;
  const [viewMode, setViewMode] = React.useState(initialViewMode);

  let children: React.ReactNode | undefined;

  if (viewMode === ViewMode.DEFAULT) {
    if (input.value === 'sameAsShippingAddress') {
      children = (
        <div className={bem.element('selection')}>
          <div className={bem.element('same-as-shipping-address')}>
            <div className={bem.element('same-as-shipping-address-label')}>
              <FormattedMessage
                id="checkout_same_as_shipping_address"
                description="A message used for indicating an address is the same as the selected shipping address"
                defaultMessage="Same As Shipping Address"
              />
            </div>
            <Button
              text="Change"
              type="button"
              size="small"
              color="primary"
              outline
              onClick={(): void => { setViewMode(ViewMode.LISTING); }}
            />
          </div>
        </div>
      );
    } else if (selectedContact != null) {
      children = (
        <div className={bem.element('selection')}>
          <AddressBookContact
            addressType={addressType}
            contact={selectedContact}
          >
            {{
              action: (
                <Button
                  automationId={`change-${addressType}-address`}
                  text="Change"
                  type="button"
                  size="small"
                  color="primary"
                  outline
                  onClick={(): void => { setViewMode(ViewMode.LISTING); }}
                />
              ),
            }}
          </AddressBookContact>
          {meta.invalid && (
            <div className={bem.element('error')}>
              <span>{meta.error}</span>
              <Button
                type="button"
                color="negative"
                onBlur={(event): void => {
                  event.stopPropagation();
                }}
                onClick={(event): void => {
                  event.preventDefault();
                  event.stopPropagation();
                  onEditAddressBookContact(organizationId, customer, selectedContact);
                }}
                size="small"
              >
                <FormattedMessage
                  id="checkout_action_update_address"
                  description="text used in call to action to update an address book contact"
                  defaultMessage="Update Address"
                />
              </Button>
            </div>
          )}
        </div>
      );
    }
  } else {
    children = (
      <RadioPanelGroup
        fluid
        name={input.name}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
      >
        {addressType !== 'shipping' && (
          <RadioPanel
            id={`${addressType}-same-as-shipping-address`}
            name={input.name}
            value="sameAsShippingAddress"
            label={(
              <div className={bem.element('same-as-shipping-address-label')}>
                <FormattedMessage
                  id="checkout_same_as_shipping_address"
                  description="A message used for indicating an address is the same as the selected shipping address"
                  defaultMessage="Same As Shipping Address"
                />
              </div>
            )}
          />
        )}
        {addressBook.contacts.map((contact) => (
          <RadioPanel
            key={contact.id}
            id={`${addressType}-${contact.id}`}
            name={input.name}
            value={contact.id}
            label={(
              <>
                <AddressBookContact
                  addressType={addressType}
                  contact={contact}
                >
                  {{
                    action: (
                      <Button
                        text="Remove"
                        type="button"
                        color="negative"
                        disabled={contact.id === input.value}
                        outline
                        size="small"
                        onBlur={(event): void => {
                          event.stopPropagation();
                        }}
                        onClick={(event): void => {
                          event.preventDefault();
                          event.stopPropagation();
                          onRemoveAddressBookContact(
                            organizationId,
                            customer.number,
                            contact.id,
                          );
                        }}
                      />
                    ),
                  }}
                </AddressBookContact>
                {meta.dirty && meta.invalid && input.value === contact.id && (
                  <div className={bem.element('error')}>
                    <span>{meta.error}</span>
                    <Button
                      type="button"
                      className={bem.element('update-address-button')}
                      color="negative"
                      onBlur={(event): void => {
                        event.stopPropagation();
                      }}
                      onClick={(event): void => {
                        event.preventDefault();
                        event.stopPropagation();
                        onEditAddressBookContact(organizationId, customer, contact);
                      }}
                      size="small"
                    >
                      <FormattedMessage
                        id="checkout_action_update_address"
                        description="text used in call to action to update an address book contact"
                        defaultMessage="Update Address"
                      />
                    </Button>
                  </div>

                )}
              </>
            )}
          />
        ))}
        <RadioPanel
          automationId={`new-${addressType}-address`}
          id={`new-${addressType}-address`}
          name="addressToUse"
          label={(
            <FormattedMessage
              id="checkout_customer_info_new_address"
              description="A label that denotes to the user that they are using a new address"
              defaultMessage="New Address"
            />
          )}
          value="newAddress"
        >
          <Address
            addressType={addressType}
            addressConfiguration={addressConfiguration}
            companyName={selectedCompanyName}
            countries={countries}
            countryCode={selectedCountryCode}
            customerType={selectedCustomerType}
            locale={locale}
            onAutoCompleteAddress={onAutoCompleteAddress}
            showSaveAddress
            vrn={selectedVrn}
          />
        </RadioPanel>
      </RadioPanelGroup>
    );
  }

  return (
    <div className={bem.block()} data-automation-id={`${addressType}-address-picker`}>
      <Grid parent>
        <Row>
          <Column xs={12}>
            {children}
          </Column>
        </Row>
      </Grid>
    </div>
  );
};

AddressPicker.displayName = 'AddressPicker';

export default AddressPicker;
