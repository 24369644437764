import type { AuthorizationsPostPaymentAndDetailsAndAdyenByKeyResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<AuthorizationsPostPaymentAndDetailsAndAdyenByKeyResponse>
>;

const updateAdyenPaymentDetailsRequest = (): RootAction => ({
  type: actionTypes.UPDATE_ADYEN_PAYMENT_DETAILS_REQUEST,
});

const updateAdyenPaymentDetailsSuccess = (
  authorization: io.flow.v0.unions.Authorization,
): RootAction => ({
  type: actionTypes.UPDATE_ADYEN_PAYMENT_DETAILS_SUCCESS,
  payload: authorization,
});

const updateAdyenPaymentDetailsFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_ADYEN_PAYMENT_DETAILS_FAILURE,
  payload: error,
});

const updateAdyenPaymentDetails = (
  organizationId: string,
  authorizationKey: string,
  adyenPaymentDetailsForm: io.flow.internal.v0.models.AdyenPaymentDetailsForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(updateAdyenPaymentDetailsRequest());
  return extra.apiInternal.authorizations.postPaymentAndDetailsAndAdyenByKey({
    body: adyenPaymentDetailsForm,
    key: authorizationKey,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(updateAdyenPaymentDetailsSuccess(response.result));
          break;
        case 401:
        case 422:
          dispatch(updateAdyenPaymentDetailsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateAdyenPaymentDetails;
