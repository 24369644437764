import { createSelector } from 'reselect';
import get from 'lodash/get';

import { RootState } from '../../types';
import { getOrganization } from '../../flow/selectors';
import { getCartId } from '../../cart/selectors';
import {
  getCheckoutId,
  getOrderNumber,
  isAddSessionToAuthorizationReturnUrlEnabled,
  isCheckoutResourceFeatureEnabled,
} from '../../checkout/selectors';
import Steps from '../../../constants/steps';

export const getCurrentUrl = (
  state: RootState,
): string => get(state, 'routing.locationBeforeTransitions.pathname');

export const getCartUrl = createSelector(
  getCartId,
  getOrganization,
  (cartId, organization) => `/${organization}/cart/${cartId}`,
);

export const getCheckoutUrl = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organization) => `/${organization}/checkout/${orderNumber}`,
);

export const getContactInfoUrl = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organization) => `/${organization}/checkout/${orderNumber}/contact-info`,
);

export const getCheckoutContactInfoUrl = createSelector(
  getCheckoutId,
  (id) => `/checkouts/${id}/contact-info`,
);

export const getOrderReviewUrl = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organization) => `/${organization}/checkout/${orderNumber}/review`,
);

export const getCheckoutOrderReviewUrl = createSelector(
  getCheckoutId,
  (id) => `/checkouts/${id}/order-review`,
);

export const getCheckoutShippingMethodUrl = createSelector(
  getCheckoutId,
  (id) => `/checkouts/${id}/shipping-method`,
);

// Legacy
export const getShippingMethodUrl = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organization) => `/${organization}/checkout/${orderNumber}/shipping-method`,
);
// End

// Will refactor back to getShippingMethodUrl once migration to checkouts resource is complete
export const getShippingMethodUrlV2 = createSelector(
  getCheckoutShippingMethodUrl,
  getShippingMethodUrl,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (
    checkoutShippingUrl,
    shippingUrl,
    checkoutResourceEnabled,
    checkoutId,
  ) => (checkoutResourceEnabled && checkoutId ? checkoutShippingUrl : shippingUrl),
);

// Will refactor back to getContactInfoUrl once migration to checkouts resource is complete
export const getContactInfoUrlV2 = createSelector(
  getCheckoutContactInfoUrl,
  getContactInfoUrl,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (
    checkoutContactInfoUrl,
    contactInfoUrl,
    checkoutResourceEnabled,
    checkoutId,
  ) => (checkoutResourceEnabled && checkoutId ? checkoutContactInfoUrl : contactInfoUrl),
);

export const getPaymentInfoUrl = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organization) => `/${organization}/checkout/${orderNumber}/payment-info`,
);

export const getCheckoutPaymentInfoUrl = createSelector(
  getCheckoutId,
  (id) => `/checkouts/${id}/payment-info`,
);

// Will refactor back to getPaymentInfoUrl once migration to checkouts resource is complete
export const getPaymentInfoUrlV2 = createSelector(
  getCheckoutPaymentInfoUrl,
  getPaymentInfoUrl,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (
    checkoutPaymentUrl,
    paymentInfoUrl,
    checkoutResourceEnabled,
    checkoutId,
  ) => (checkoutResourceEnabled && checkoutId ? checkoutPaymentUrl : paymentInfoUrl),
);

export const getOrderReviewUrlV2 = createSelector(
  getCheckoutOrderReviewUrl,
  getOrderReviewUrl,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (
    checkoutReviewUrl,
    reviewUrl,
    checkoutResourceEnabled,
    checkoutId,
  ) => (checkoutResourceEnabled && checkoutId ? checkoutReviewUrl : reviewUrl),
);

export const getCheckoutConfirmationUrl = createSelector(
  getCheckoutId,
  (id) => `/checkouts/${id}/confirmation`,
);

export const getConfirmationUrl = createSelector(
  getOrderNumber,
  getOrganization,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (orderNumber, organizationId, isCheckoutResourceEnabled, checkoutId) => {
    if (isCheckoutResourceEnabled) {
      return `/checkouts/${checkoutId}/confirmation`;
    }
    return `/${organizationId}/checkout/${orderNumber}/confirmation`;
  },
);

// Will refactor back to getPaymentInfoUrlV2 once migration to checkouts resource is complete
export const getConfirmationUrlV2 = createSelector(
  getCheckoutConfirmationUrl,
  getConfirmationUrl,
  isCheckoutResourceFeatureEnabled,
  getCheckoutId,
  (
    checkoutConfirmationUrl,
    confirmationUrl,
    checkoutResourceEnabled,
    checkoutId,
  ) => (checkoutResourceEnabled && checkoutId ? checkoutConfirmationUrl : confirmationUrl),
);

type Selector<R> = (state: RootState) => R;

export const getOnlineAuthorizationCallbackUrl = (
  sessionId: string,
): Selector<string> => createSelector(
  getOrderNumber,
  getOrganization,
  isCheckoutResourceFeatureEnabled,
  isAddSessionToAuthorizationReturnUrlEnabled,
  getCheckoutId,
  (
    orderNumber,
    organizationId,
    isCheckoutResourceEnabled,
    addSessionToAuthorizationReturnUrl,
    checkoutId,
  ) => {
    let callbackUrl;

    if (isCheckoutResourceEnabled) {
      callbackUrl = `/checkouts/${checkoutId}/callbacks/authorization`;

      // HACK! BEWARE!
      // https://flowio.atlassian.net/browse/CHEC-2013
      if (sessionId != null && addSessionToAuthorizationReturnUrl) {
        callbackUrl = `${callbackUrl}?flow_session_id=${sessionId}`;
      }
    } else {
      callbackUrl = `/${organizationId}/checkout/${orderNumber}/callbacks/authorization`;
    }

    return callbackUrl;
  },
);

export const getActiveStep = createSelector(
  getCurrentUrl,
  getCartUrl,
  getContactInfoUrlV2,
  getShippingMethodUrlV2,
  getPaymentInfoUrlV2,
  getOrderReviewUrlV2,
  getConfirmationUrlV2,
  (
    currentUrl,
    cartUrl,
    contactInfoUrl,
    shippingMethodUrl,
    paymentInfoUrl,
    orderReviewUrl,
    confirmationUrl,
  ) => {
    switch (currentUrl) {
      case cartUrl: return Steps.Cart;
      case contactInfoUrl: return Steps.ContactInfo;
      case shippingMethodUrl: return Steps.ShippingMethod;
      case paymentInfoUrl: return Steps.PaymentInfo;
      case orderReviewUrl: return Steps.Review;
      case confirmationUrl: return Steps.Confirmation;
      default: return Steps.None;
    }
  },
);

export const isContactInfoStep = createSelector(
  getActiveStep,
  (activeStep) => activeStep === Steps.ContactInfo,
);

export const isConfirmationStep = createSelector(
  getActiveStep,
  (activeStep) => activeStep === Steps.Confirmation,
);
