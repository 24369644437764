// https://app.apibuilder.io/flow/payment/latest#enum-authorization_status

const AuthorizationStatus = {
  // If an immediate response is not available, the state will be ‘pending’.
  // For example, online payment methods like AliPay or PayPal will have a
  // status of ‘pending’ until the user completes the payment. Pending
  // authorizations expire if the user does not complete the payment in a
  // timely fashion.
  PENDING: 'pending',
  // Authorization has expired.
  EXPIRED: 'expired',
  // Authorization was successful.
  AUTHORIZED: 'authorized',
  // If an immediate response is not available, the state will be ‘review’ -
  // this usually indicates fraud review requires additional time/verification
  // (or a potential network issue with the issuing bank).
  REVIEW: 'review',
  // Indicates the authorization has been declined by the issuing bank. See the
  // authorization decline code for more details as to the reason for decline.
  DECLINED: 'declined',
  // Indicates the authorization has been fully reversed. You can fully reverse
  // an authorization up until the moment you capture funds; once you have
  // captured funds you must create refunds.
  REVERSED: 'reversed',
};

export default AuthorizationStatus;
