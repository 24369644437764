/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

const TriangleDown = (props) => (
  <SvgIcon viewBox="0 0 12 16" {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="0 5 6 11 12 5" />
    </g>
  </SvgIcon>
);

TriangleDown.displayName = 'TriangleDown';

export default TriangleDown;
