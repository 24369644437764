import { AuthorizationError } from './errors/authorization-error';

/**
 * Returns the status of any authorization entity supported by Flow Commerce API.
 */
export const getAuthorizationStatus = (
  authorization: io.flow.v0.unions.Authorization,
): io.flow.v0.enums.AuthorizationStatus => authorization.result.status;

/**
 * Returns the redirect URL for a redirect authorization entity.
 */
export const getAuthorizationRedirectUrl = (
  details: io.flow.v0.models.RedirectAuthorizationDetails,
): string => details.payment_redirect_url;

export const getAuthorizationActionType = (
  authorization: io.flow.v0.unions.Authorization,
): io.flow.v0.enums.AuthorizationResultActionType | undefined => authorization.result.action?.type;

export const getAuthorizationResultRedirectUrl = (
  authorization: io.flow.v0.unions.Authorization,
): string | undefined => {
  if (authorization.result.action == null) {
    return undefined;
  }

  if (authorization.result.action.discriminator === 'authorization_result_action_get') {
    return authorization.result.action.url;
  }

  if (authorization.result.action.discriminator === 'authorization_result_action_post') {
    return authorization.result.action.url;
  }

  return undefined;
};

/**
 * Use this utility to verify whether an authorization is in a valid state.
 * @throws when authorization is in an invalid state.
 */
export function checkAuthorization(
  authorization: io.flow.v0.unions.Authorization,
): io.flow.v0.unions.Authorization {
  // Bail when the authorization status is invalid.
  if (authorization.result.status !== 'authorized' && authorization.result.status !== 'review' && authorization.result.status !== 'pending') {
    throw new AuthorizationError('authorization_status_error', authorization);
  }

  return authorization;
}
