import { useEffect, useState } from 'react';
import { injectScript } from '../utilities/script-cache';

export default function useScript(source: string): [boolean, boolean] {
  const [state, setState] = useState({ error: false, loaded: false });

  useEffect(() => {
    const stopObservingScript = injectScript(source, {
      onError() {
        setState({ error: true, loaded: true });
      },
      onLoad() {
        setState({ error: false, loaded: true });
      },
    });
    return stopObservingScript;
  }, [source]);

  return [state.loaded, state.error];
}
