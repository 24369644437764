import type { CheckoutFinalizeOrderFormsPutResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CheckoutFinalizeOrderFormsPutResponse>>;

const finalizeOrderRequest = (): RootAction => ({
  type: actionTypes.FINALIZE_ORDER_REQUEST,
});

const finalizeOrderSuccess = (
  orderBuider: io.flow.experience.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.FINALIZE_ORDER_SUCCESS,
  payload: orderBuider,
});

const finalizeOrderFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FINALIZE_ORDER_FAILURE,
  payload: error,
});

/**
 * Creates an action responsible for performing the final order updates prior
 * to submission and taking payment from the consumer.
 */
const finalizeOrder = (
  organizationId: string,
  orderNumber: string,
  checkoutFinalizeOrderForm: io.flow.internal.v0.models.CheckoutFinalizeOrderForm,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(finalizeOrderRequest());
  return extra.apiInternal.checkoutFinalizeOrderForms.put({
    body: checkoutFinalizeOrderForm,
    number: orderNumber,
    organization_id: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(finalizeOrderSuccess(response.result));
          break;
        case 401:
        case 404:
          dispatch(finalizeOrderFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default finalizeOrder;
