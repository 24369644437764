import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CheckoutEntitiesOrganizationState } from '../types';

const initialState: CheckoutEntitiesOrganizationState = {
  countries: [],
  organizationData: undefined,
};

const checkoutEntitiesOrganizationReducer: AppReducer<CheckoutEntitiesOrganizationState> = (
  state = initialState,
  action,
): CheckoutEntitiesOrganizationState => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return update(state, {
        countries: { $set: action.payload.destinations },
        organizationData: { $set: action.payload.metadata?.organization },
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return update(state, {
        countries: { $set: action.payload.destinations },
        organizationData: { $set: action.payload.organization },
      });
    case actionTypes.FETCH_ORGANIZATION_COUNTRIES_SUCCESS:
      return update(state, {
        countries: { $set: action.payload },
      });
    default:
      return state;
  }
};

export default checkoutEntitiesOrganizationReducer;
