import { Field } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import SelectField from '../redux-form/select-field';

type Props = {
  addressType?: AddressType;
  options: io.flow.v0.models.Country[];
  required?: boolean;
};

const messages: Record<string, MessageDescriptor> = defineMessages({
  CN: {
    id: 'checkout_address_country_label_china',
    description: 'The name of the People\'s Republic of China',
    defaultMessage: 'China',
  },
});

const messageToOption = (
  message: React.ReactNode,
): React.ReactNode => (
  <SelectField.Option disabled value="" text={message} />
);

const getLabelText = (
  children?: (...nodes: React.ReactNode[]) => React.ReactNode,
): React.ReactElement => (
  <FormattedMessage
    id="checkout_address_field_label_country"
    description="Content for country field label in address forms"
    defaultMessage="Country"
  >
    {children}
  </FormattedMessage>
);

const CountryField: React.FunctionComponent<Props> = ({
  addressType,
  options,
  required = false,
}) => {
  const labelText = getLabelText();
  const header = getLabelText(messageToOption);
  const intl = useIntl();

  return (
    <Field
      autoComplete={autoCompleteToken('country', addressType)}
      automationId={automationToken('country', addressType)}
      component={SelectField}
      labelText={labelText}
      name="country"
      required={required}
    >
      {header}
      {options.map((option) => (
        <SelectField.Option
          key={option.iso_3166_3}
          text={(messages[option.iso_3166_2])
            ? intl.formatMessage(messages[option.iso_3166_2])
            : option.name}
          value={option.iso_3166_3}
        />
      ))}
    </Field>
  );
};

export default CountryField;
