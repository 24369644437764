/* eslint-disable react/jsx-props-no-spreading */

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import values from 'lodash/values';

import messages from './utilities/messages';
import Step from '../step';
import Steps from '../../constants/steps';
import { isStepActive, isStepCompleted } from '../../utilities/step';

if (process.browser) {
  require('./breadcrumb.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('breadcrumb');

const Breadcrumb = ({
  activeStep,
  cartUrl,
  contactInfoUrl,
  isCartStepAvailable,
  shippingMethodUrl,
  paymentInfoUrl,
  isReviewStepEnabled,
}) => (
  <Step.Group className={bem.block()}>
    {isCartStepAvailable && (
      <Step
        active={isStepActive(activeStep, Steps.Cart)}
        className={bem.element('item')}
        completed={isStepCompleted(activeStep, Steps.Cart)}
        data-automation-id="cart-breadcrumb"
      >
        <Step.Title as={Link} to={cartUrl}>
          <FormattedMessage {...messages.cart} />
        </Step.Title>
      </Step>
    )}
    <Step
      active={isStepActive(activeStep, Steps.ContactInfo)}
      className={bem.element('item')}
      completed={isStepCompleted(activeStep, Steps.ContactInfo)}
      data-automation-id="customer-info-breadcrumb"
    >
      <Step.Title as={Link} to={contactInfoUrl}>
        <FormattedMessage {...messages.customerInfo} />
      </Step.Title>
    </Step>
    <Step
      active={isStepActive(activeStep, Steps.ShippingMethod)}
      className={bem.element('item')}
      completed={isStepCompleted(activeStep, Steps.ShippingMethod)}
      data-automation-id="shipping-method-breadcrumb"
    >
      <Step.Title as={Link} to={shippingMethodUrl}>
        <FormattedMessage {...messages.shippingMethod} />
      </Step.Title>
    </Step>
    <Step
      active={isStepActive(activeStep, Steps.PaymentInfo)}
      className={bem.element('item')}
      completed={isStepCompleted(activeStep, Steps.PaymentInfo)}
      data-automation-id="payment-info-breadcrumb"
    >
      <Step.Title as={Link} to={paymentInfoUrl}>
        <FormattedMessage {...messages.paymentInfo} />
      </Step.Title>
    </Step>
    {isReviewStepEnabled && (
      <Step
        active={isStepActive(activeStep, Steps.Review)}
        className={bem.element('item')}
        completed={isStepCompleted(activeStep, Steps.Review)}
        data-automation-id="review-step-breadcrumb"
      >
        <Step.Title>
          <FormattedMessage {...messages.review} />
        </Step.Title>
      </Step>
    )}
  </Step.Group>
);

Breadcrumb.displayName = 'Breadcrumb';

Breadcrumb.propTypes = {
  activeStep: PropTypes.oneOf(values(Steps)),
  cartUrl: PropTypes.string.isRequired,
  contactInfoUrl: PropTypes.string.isRequired,
  isCartStepAvailable: PropTypes.bool,
  paymentInfoUrl: PropTypes.string.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
  isReviewStepEnabled: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  activeStep: Steps.None,
  isCartStepAvailable: true,
  isReviewStepEnabled: false,
};

export default Breadcrumb;
