import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  SubmissionError,
} from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { push } from 'react-router-redux';
import getFeatureKeys from '../utilities/get-feature-keys';
import {
  fetchCheckout,
  updatePaypalInfo,
  updateGooglePayInfo,
} from '../store/checkout/actions';
import submitPaymentInformationFromReview from '../store/checkout/actions/submit-payment-information-from-review';
import { getCountries } from '../store/reference/selectors';
import {
  allowInvoiceAddress,
  getContinueShoppingUrl,
  getCustomerAddressBook,
  getCustomerPaymentSources,
  getGooglePaymentDataRequest,
  getIsCheckoutResourceEnabled,
  getIsDeliveredDutyCustomerChoice,
  getOrderDeliveredDuty,
  getOrderDestination,
  getOrderNumber,
  getPaymentMethodRules,
  getReviewCard,
  getReviewEntities,
  getReviewErrors,
  getReviewPaymentMethodId,
  getSelectedDeliveryOptions,
  getVrnFromRegistration,
  getReviewInvoiceAddress,
  getReviewBillingAddress,
  getCheckoutOrganization,
} from '../store/checkout/selectors';
import OrderReviewStep from '../components/order-review-step';
import withExternalEvents from '../components/with-external-events';
import { getContactInfoUrl, getShippingMethodUrl, getPaymentInfoUrlV2 } from '../store/navigation/selectors';
import injectFlow from '../components/injectFlow';
import { genericErrorFromString } from '../utilities/parsers';
import setReviewError from '../store/checkout/actions/set-review-error';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { checkoutId } = params;
  const state = getState();
  const reviewEntities = getReviewEntities(state);

  if (isEmpty(reviewEntities)) {
    dispatch(push(getPaymentInfoUrlV2(state)));
    return Promise.resolve();
  }

  return Promise.all([
    dispatch(fetchCheckout(checkoutId, { feature_key: getFeatureKeys() })),
  ]).catch((error) => {
    throw error;
  });
};

function handleSubmitOrder() {
  return function handleSubmitOrderEffects(dispatch, getState) {
    const isCheckoutResourceEnabled = getIsCheckoutResourceEnabled(getState());
    return dispatch(submitPaymentInformationFromReview(isCheckoutResourceEnabled))
      .catch((error) => dispatch(setReviewError(error)));
  };
}

function createReviewError(error, dispatch) {
  const submissionError = new SubmissionError({ _error: error });
  dispatch(setReviewError(submissionError));
}

function handleGooglePaySuccess(paymentData) {
  return function handleGooglePaySuccessEffects(dispatch) {
    dispatch(updateGooglePayInfo(paymentData));
    return dispatch(handleSubmitOrder());
  };
}

function handleGooglePayError(errorMessage) {
  return function handleGooglePayErrorEffects(dispatch) {
    createReviewError(
      genericErrorFromString(errorMessage),
      dispatch,
    );
  };
}

function handlePayPalSuccess(payment) {
  return function handlePayPalSuccessSideEffects(dispatch) {
    dispatch(updatePaypalInfo(payment.paymentID, payment.payerID));
    dispatch(handleSubmitOrder());
  };
}

function handlePayPalError(error) {
  return function handlePayPalErrorSideEffects(dispatch) {
    createReviewError(error, dispatch);
  };
}

function handleKlarnaError(error) {
  return function handleKlarnaErrorSideEffects(dispatch) {
    createReviewError(error, dispatch);
  };
}

function handleKlarnaSuccess() {
  return function handleKlarnaSuccessSideEffects(dispatch) {
    return dispatch(handleSubmitOrder());
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onGooglePayError: handleGooglePayError,
  onGooglePaySuccess: handleGooglePaySuccess,
  onPayPalError: handlePayPalError,
  onPayPalSuccess: handlePayPalSuccess,
  onKlarnaFailure: handleKlarnaError,
  onKlarnaSuccess: handleKlarnaSuccess,
  onSubmitOrder: handleSubmitOrder,
}, dispatch);

const mapStateToProps = createStructuredSelector({
  addressBook: getCustomerAddressBook,
  allowInvoiceAddress,
  billingAddress: getReviewBillingAddress,
  error: getReviewErrors,
  card: getReviewCard,
  countries: getCountries,
  continueShoppingUrl: getContinueShoppingUrl,
  deliveryOptions: getSelectedDeliveryOptions,
  deliveredDuty: getOrderDeliveredDuty,
  destination: getOrderDestination,
  invoiceAddress: getReviewInvoiceAddress,
  isCheckoutResource: getIsCheckoutResourceEnabled,
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice,
  googlePaymentDataRequest: getGooglePaymentDataRequest,
  orderNumber: getOrderNumber,
  organizationSummary: getCheckoutOrganization,
  vrn: getVrnFromRegistration,
  contactInfoUrl: getContactInfoUrl,
  shippingMethodUrl: getShippingMethodUrl,
  paymentMethodRules: getPaymentMethodRules,
  paymentInfoUrl: getPaymentInfoUrlV2,
  paymentMethodId: getReviewPaymentMethodId,
  paymentSources: getCustomerPaymentSources,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  injectFlow,
  connect(mapStateToProps, mapDispatchToProps),
)(OrderReviewStep);
