import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import SectionContent from './section-content';
import SectionHeader from './section-header';
import SectionTitle from './section-title';

if (process.browser) {
  require('./section.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('section');

const Section = ({ children, className, fitted }) => (
  <section className={bem.block({ fitted }, className)}>
    {children}
  </section>
);

Section.displayName = 'Section';

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  fitted: PropTypes.bool,
};

Section.defaultProps = {
  className: undefined,
  fitted: false,
};

Section.Content = SectionContent;
Section.Header = SectionHeader;
Section.Title = SectionTitle;

export default Section;
