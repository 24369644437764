import { connect, MapStateToProps } from 'react-redux';

import OrderPricesComponent from '../components/order-prices';

import type { RootState } from '../../../store/types';
import type { StateProps } from '../types';
import { getFlowVatName, getTaxAndDutyPrice } from '../../../store/checkout';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  flowVatName: getFlowVatName(state),
  taxAndDutyPrice: getTaxAndDutyPrice(state),
});

export default connect(mapStateToProps)(OrderPricesComponent);
