/* global Stripe: true */
/* global AdyenCheckout: true */

import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import checkHttpStatus from '../check-http-status';
import updateAdyenPaymentDetails from '../../store/checkout/actions/update-adyen-payment-details';
import { hideLoadingIndicator, showLoadingIndicator } from '../../store/application/actions';
import {
  openAdyen3dsDialog,
  closeAdyen3dsDialog,
} from '../../store/checkout/actions';
import { getEnvironment } from '../session';
import AdyenLocales from '../../store/checkout/constants/adyen-locales';
import { handleFiserv3DS2Challenge, handleFiserv3DS2Identify } from './fiserv-result-action-handlers';

let checkout;

function challengeShopper(
  challengeToken,
  organization,
  authKey,
  resolve,
  reject,
  dispatch,
) {
  dispatch(openAdyen3dsDialog());
  dispatch(hideLoadingIndicator());
  checkout.create('threeDS2Challenge', {
    challengeToken,
    size: '01',
    onComplete: (challengeData) => {
      dispatch(closeAdyen3dsDialog());
      dispatch(showLoadingIndicator());
      const challengeResult = challengeData.data.details['threeds2.challengeResult'];
      dispatch(updateAdyenPaymentDetails(organization, authKey, {
        challenge_result: challengeResult,
      }))
        .then(checkHttpStatus)
        .then((res) => {
          dispatch(closeAdyen3dsDialog());
          if (res.result.result.status === 'verified' || res.result.result.status === 'review') {
            resolve();
          } else {
            reject({
              name: 'ThreedsError',
              reason: res.result.result.status,
            });
          }
        });
    },
    onError: (error) => {
      dispatch(closeAdyen3dsDialog());
      reject(error);
    },
  }).mount('#adyenChallenge');
}

function identifyShopper(
  data,
  organization,
  authKey,
  resolve,
  reject,
  dispatch,
) {
  checkout.create('threeDS2DeviceFingerprint', {
    fingerprintToken: data.fingerprint_token,
    onComplete: (fingerprintData) => {
      const fingerprint = get(fingerprintData, 'data.details[\'threeds2.fingerprint\']');
      if (isUndefined(fingerprint) || fingerprint.length < 1) {
        reject({
          name: 'ThreedsError',
          reason: 'Fingerprinting failed',
        });
      }
      dispatch(updateAdyenPaymentDetails(organization, authKey, {
        fingerprint,
      }))
        .then(checkHttpStatus)
        .then((response) => {
          const adyenData = get(response, 'result.result.action.details.data', {});
          if (adyenData.discriminator === 'adyen_challenge_shopper_data') {
            challengeShopper(
              adyenData.challenge_token,
              organization,
              authKey,
              resolve,
              reject,
              dispatch,
            );
          } else if (get(response, 'result.result.status') === 'authorized' || get(response, 'result.result.status') === 'review') {
            resolve();
          } else {
            reject({
              name: 'ThreedsError',
              reason: 'Adyen fingerprinting failed',
            });
          }
        })
        .catch((e) => {
          reject({
            name: 'ThreedsError',
            reason: e,
          });
        });
    },
  }).mount('#adyen3ds');
}

/** @returns {Promise<any>} */
export default function handleAuthResultActionDetail(
  /** @type {io.flow.v0.unions.AuthorizationResultActionDetails} */
  authorizationResultActionDetail,
  organization,
  authKey,
  dispatch,
  locale,
) {
  switch (authorizationResultActionDetail.discriminator) {
    case 'stripe_authorization_result_action_details': {
      const stripe = Stripe(authorizationResultActionDetail.publishable_key);
      return stripe.handleCardPayment(authorizationResultActionDetail.client_secret, {
        payment_method: authorizationResultActionDetail.payment_method_id,
      }).then(checkHttpStatus)
        .then((stripeResponse) => {
          if (stripeResponse.error) {
            throw new SubmissionError({
              _error: {
                messages: [stripeResponse.error.message],
              },
            });
          }
        });
    }
    case 'adyen_native_action_details': {
      if (!checkout) {
        const adyenLocale = AdyenLocales[locale] || 'en-US';
        checkout = new AdyenCheckout({
          environment: getEnvironment() === 'sandbox' ? 'test' : 'live',
          locale: adyenLocale,
          originKey: authorizationResultActionDetail.origin_key,
        });
      }

      return new Promise((resolve, reject) => {
        switch (authorizationResultActionDetail.data.discriminator) {
          case 'adyen_identify_shopper_data':
            identifyShopper(
              authorizationResultActionDetail.data,
              organization,
              authKey,
              resolve,
              reject,
              dispatch,
            );
            break;
          case 'adyen_challenge_shopper_data':
            challengeShopper(
              get(authorizationResultActionDetail, 'data.challenge_token'),
              organization,
              authKey,
              resolve,
              reject,
              dispatch,
            );
            break;
          default:
            break;
        }
      });
    }

    case 'threeds_identify_action_details':
      return handleFiserv3DS2Identify(
        authorizationResultActionDetail,
        organization,
        authKey,
        dispatch,
      );
    case 'threeds_challenge_action_details':
      return handleFiserv3DS2Challenge(
        authorizationResultActionDetail,
        organization,
        authKey,
        dispatch,
      );
    default: break;
  }

  return Promise.resolve();
}
