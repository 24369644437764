/**
 * @fileoverview
 * ScriptCache.js - An utility responsible for dynamically importing
 * JavaScript <script> tags on a page. It will only load a single <script> tag
 * on a page per-script tag declaration. If it's already loaded on a page, it
 * calls the callback from the onLoad event immediately.
 *
 * @example
 * export default extends React.Component {
 *   componentDidMount() {
 *     this.stopObservingScript = injectScript('https://cdn.flow.io/flowjs/latest/flow.min.js', {
 *       onError: this.handleScriptError,
 *       onLoad: this.handleScriptLoaded,
 *     });
 *   }
 *   componentWillUnmount() {
 *     this.stopObservingScript();
 *   }
 * }
 */

import loadScript from '@flowio/browser-helpers/lib/loadScript';
import noop from 'lodash/noop';

export class EventObserver {
  constructor() {
    this.observers = [];
  }

  isEmpty() {
    return this.observers.length === 0;
  }

  subscribe(callback) {
    this.observers.push(callback);
  }

  unsubscribe(callback) {
    this.observers = this.observers.filter((subscriber) => subscriber !== callback);
  }

  broadcast(data) {
    this.observers.forEach((subscriber) => subscriber(data));
  }
}

// An object mapping script URL to a boolean value indicating whether
// the script has failed to load.
const erroredScripts = {};

// An object mapping script URL to a boolean value indicating whether
// the script has already been loaded.
const loadedScripts = {};

// An object mapping script URLs to an array of observers that are
// waiting for the script to load.
const scriptObservers = {};

export function injectScript(source, options = {}) {
  const {
    onError = noop,
    onLoad = noop,
  } = options;

  if (loadedScripts[source]) {
    onLoad();
    return noop;
  }

  if (erroredScripts[source]) {
    onError();
    return noop;
  }

  // If the script is loading, subscribe to the script's observer.
  // Otherwise, initialize the script's observer with the callbacks
  // and start loading the script.

  const {
    [source]: observer = new EventObserver(),
  } = scriptObservers;

  function subscription(event) {
    if (event.error) onError();
    else onLoad();
  }

  function unsubscribe() {
    observer.unsubscribe(subscription);
  }

  if (observer.isEmpty()) {
    scriptObservers[source] = observer;

    loadScript(source, {
      onLoad() {
        const event = { error: false };
        loadedScripts[source] = true;
        observer.broadcast(event);
        unsubscribe();
      },
      onError() {
        const event = { error: true };
        erroredScripts[source] = true;
        observer.broadcast(event);
        unsubscribe();
      },
    });
  }

  observer.subscribe(subscription);

  return unsubscribe;
}
