// @ts-check

import get from 'lodash/get';

import checkHttpStatus from '../../../utilities/check-http-status';
import checkOrderBundleStatus from '../../../utilities/check-order-bundle-status';
import submitOrderBundle from './submit-order-bundle';
import { authorizeRedirect } from './index';
import handleAuthResultActionDetail from '../../../utilities/helpers/handle-auth-result-action-detail';

/**
 * @typedef {io.flow.internal.v0.models.CheckoutSubmitOrderBundle} CheckoutSubmitOrderBundle
 */

/**
 * @typedef {io.flow.v0.models.LocalizedTotal} LocalizedTotal
 */

/**
 * @typedef {io.flow.v0.unions.AuthorizationForm} AuthorizationForm
 */

/**
 * @typedef {import('../../../../common/services/types').Response<T>} Response
 * @template T
 */

/**
 * @typedef {io.flow.v0.unions.AuthorizationResultAction} AuthorizationResultAction
 */

/**
 * @typedef {import('redux').Action} Action
 */

/**
 * @typedef {import('redux').Dispatch<Action>} Dispatch
 */

/**
  * @param {Dispatch} dispatch
  * @param {string} organizationId
  * @param {string} orderNumber
  * @param {AuthorizationForm} authorizationForm
  * @param {LocalizedTotal} total
  * @param {string} locale
  */

export default function submitBundlePayment(
  dispatch,
  organizationId,
  orderNumber,
  authorizationForm,
  total,
  locale = 'en',
) {
  return dispatch(submitOrderBundle(
    organizationId,
    orderNumber,
    authorizationForm,
    total,
  ))
    .then(checkHttpStatus)
    .then(checkOrderBundleStatus)
    .then(
      /** @type {(response: Response<CheckoutSubmitOrderBundle>) => Promise<any>} */
      (response) => {
        const checkoutSubmitOrderBundle = response.result;
        /** @type {AuthorizationResultAction | undefined} */
        const authorizationResultAction = checkoutSubmitOrderBundle.redirect;

        if (authorizationResultAction == null) {
          return Promise.resolve();
        }

        if (authorizationResultAction.type === 'native') {
          const authorizationResultActionDetail = get(authorizationResultAction, 'details');
          const authKey = get(authorizationResultAction, 'authorization.key');
          return handleAuthResultActionDetail(
            authorizationResultActionDetail,
            organizationId,
            authKey,
            dispatch,
            locale,
          );
        }

        // Right now the only action we have is a redirect. Even the stripe 3ds 2.0 stuff comes back as a redirect technically.
        // There will be another type of action added in the future with the Adyen 3ds work
        return authorizeRedirect(dispatch, authorizationResultAction);
      },
    );
}
