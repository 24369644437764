// @ts-check

/* eslint-disable react/jsx-props-no-spreading */

import ApiPropTypes from '@flowio/api-prop-types';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OrderErrors from '../../constants/order-error-types';
import DomesticShippingUnavailable from '../domestic-shipping-unavailable';
import GenericError from '../generic-error';

/**
 * @typedef {io.flow.v0.models.OrderError} OrderError
 */

/**
 * @typedef {object} OrderErrorProps
 * @property {string} continueShoppingUrl
 * @property {OrderError} error
 * @property {boolean} isRestrictedItemsModalEnabled
 * @property {function(string[]): void} [onShowRestrictedItemsModal]
 * @property {function(OrderError): void} [onShowDomesticShippingUnavailableDialog]
 * @property {boolean} isShopifyCartV2Enabled
 * @property {boolean} isShopifyIntegration
 */

/**
 * @extends {React.Component<OrderErrorProps>}
 */
class OrderErrorComponent extends Component {
  componentDidMount() {
    const { error } = this.props;
    this.handleOrderError(error);
  }

  /**
   * @param {OrderErrorProps} previousProps
   */
  componentDidUpdate(previousProps) {
    const { error: previousError } = previousProps;
    const { error: nextError } = this.props;
    this.handleOrderError(nextError, previousError);
  }

  /**
   * @param {OrderError} orderError
   * @param {OrderError} [previousOrderError]
   */
  handleOrderError(orderError, previousOrderError) {
    if (orderError != null && previousOrderError !== orderError) {
      if (orderError.code === 'order_item_not_available' && orderError.numbers != null) {
        this.showRestrictedItemsModal(orderError.numbers);
      } else if (orderError.code === 'domestic_shipping_unavailable' && this.useDomesticShippingUnavailableModal()) {
        this.showDomesticShippingUnavailableModal(orderError);
      }
    }
  }

  useDomesticShippingUnavailableModal() {
    const { isShopifyCartV2Enabled, isShopifyIntegration } = this.props;
    return isShopifyIntegration && isShopifyCartV2Enabled;
  }

  /**
   * @param {string[]} restrictedItemNumbers
   */
  showRestrictedItemsModal(restrictedItemNumbers) {
    const { isRestrictedItemsModalEnabled, onShowRestrictedItemsModal } = this.props;
    if (isRestrictedItemsModalEnabled && onShowRestrictedItemsModal != null) {
      onShowRestrictedItemsModal(restrictedItemNumbers);
    }
  }

  /**
   * @param {OrderError} orderError
   */
  showDomesticShippingUnavailableModal(orderError) {
    const { onShowDomesticShippingUnavailableDialog } = this.props;
    if (onShowDomesticShippingUnavailableDialog != null) {
      onShowDomesticShippingUnavailableDialog(orderError);
    }
  }

  render() {
    const {
      continueShoppingUrl,
      error,
      isRestrictedItemsModalEnabled,
      isShopifyCartV2Enabled,
      isShopifyIntegration,
      ...unhandledProps
    } = this.props;

    switch (error.code) {
      case OrderErrors.DOMESTIC_SHIPPING_UNAVAILABLE:
        return this.useDomesticShippingUnavailableModal() ? null : (
          <DomesticShippingUnavailable
            {...unhandledProps}
            error={error}
            continueShoppingUrl={continueShoppingUrl}
          />
        );
      case OrderErrors.ORDER_ITEM_NOT_AVAILABLE:
        return isRestrictedItemsModalEnabled ? null : (
          <GenericError {...unhandledProps} error={error} />
        );
        // TODO: Other errors are handled as generic errors, but there are several
        // error codes that we could handle differently to provide a better user
        // experience. For example, the value threshold error provides additional
        // information that we can use to provide a better message to customers.
      default:
        return (
          <GenericError {...unhandledProps} error={error} />
        );
    }
  }
}

OrderErrorComponent.displayName = 'OrderError';

OrderErrorComponent.propTypes = {
  error: ApiPropTypes.orderError.isRequired,
  continueShoppingUrl: PropTypes.string,
  isRestrictedItemsModalEnabled: PropTypes.bool,
  isShopifyIntegration: PropTypes.bool.isRequired,
  isShopifyCartV2Enabled: PropTypes.bool.isRequired,
  onShowRestrictedItemsModal: PropTypes.func,
  onShowDomesticShippingUnavailableDialog: PropTypes.func,
};

OrderErrorComponent.defaultProps = {
  continueShoppingUrl: '/',
  isRestrictedItemsModalEnabled: false,
  onShowRestrictedItemsModal: undefined,
  onShowDomesticShippingUnavailableDialog: undefined,
};

export default OrderErrorComponent;
