/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

const Option = (props) => {
  const {
    children,
    disabled,
    text,
    value,
  } = props;

  const ElementType = getElementType(Option, props);
  const unhandledProps = getUnhandledProps(Option, props);

  return (
    <ElementType {...unhandledProps} disabled={disabled} value={value}>
      {children || text}
    </ElementType>
  );
};

Option.displayName = 'Option';

Option.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  text: PropTypes.node,
  value: PropTypes.string,
};

Option.defaultProps = {
  as: 'option',
  children: undefined,
  disabled: false,
  text: '',
  value: undefined,
};

export default Option;
