import { BaseFieldProps, Field, EventWithDataHandler } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { automationToken } from './utilities';
import ReduxFormTextField, {
  BaseProps as ReduxFormTextFieldBaseProps,
} from '../redux-form/text-field';

type Props = {
  addressType?: AddressType;
  children?: never;
  onChange?: EventWithDataHandler<React.ChangeEvent<HTMLInputElement>>;
};

const OrganizationName: React.FunctionComponent<Props> = ({
  addressType,
  onChange,
}) => (
  <Field<BaseFieldProps<ReduxFormTextFieldBaseProps>>
    component={ReduxFormTextField}
    props={{
      automationId: automationToken('organization-name', addressType),
      labelText: (
        <FormattedMessage
          id="checkout_address_field_label_company"
          description="Content for company field label in address forms"
          defaultMessage="Company"
        />
      ),
    }}
    onChange={onChange}
    name="organizationName"
  />
);

export default OrganizationName;
