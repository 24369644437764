import React from 'react';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { Panel } from '../panel';
import { Row, Column, Grid } from '../grid';

import './gift-messaging.css';
import { ChevronLeft, GiftMessage } from '../svg-icons';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

const GIFT_MESSAGE_CHARACTER_LIMIT = 280;

const bem = new BemHelper('flow-gift-messaging');

class GiftMessaging extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.debounceOnChange = debounce(props.onChange, 100);
  }

  handleChange = (event) => {
    event.persist();
    this.debounceOnChange(event);
  }

  render() {
    const {
      giftMessage,
      onHeaderClick,
      isOpen,
    } = this.props;

    return (
      <FeatureToggle
        featureKey={MOBILE_UX_SPACING}
        render={({ isFeatureEnabled }) => (
          <Grid parent={isFeatureEnabled}>
            <Row>
              <Column xs={12}>
                <Panel
                  defaultExpanded={false}
                  onSelect={() => onHeaderClick(!isOpen)}
                  expanded={isOpen}
                  collapsible
                >
                  <Panel.Header>
                    <Panel.Title className={bem.element('panel-title')}>
                      <GiftMessage className={bem.element('title-icon')} />
                      <span className={bem.element('header-text')}>
                        <FormattedMessage
                          id="checkout_add_gift_messaging"
                          description="Text asking the user to add gift messaging"
                          defaultMessage="Add Gift Message"
                        />
                      </span>
                      <span className={isOpen ? bem.element('chevron-open') : bem.element('chevron-closed')}><ChevronLeft /></span>
                    </Panel.Title>
                  </Panel.Header>
                  <Panel.Content unmountOnExit className={bem.element('panel-content')}>
                    <Row>
                      <Column className={bem.element('text-container')}>
                        {/* If this is a redux form element updating the text box is really sluggish and feels really bad to type in
                          The idea here is to debounce the change so the redux
                          state isn't updating every single change */}
                        <textarea rows="3" maxLength={GIFT_MESSAGE_CHARACTER_LIMIT} className={bem.element('text')} onChange={this.handleChange} />
                        <div className={bem.element('character-count-container')}>
                          <FormattedMessage
                            id="checkout_character_remaining"
                            description="Text showing how many characters remain in input"
                            defaultMessage="{characterCount} {characterCount, plural,
                              one {Character}
                              other {Characters}} Remaining"
                            values={{
                              characterCount: giftMessage
                                ? GIFT_MESSAGE_CHARACTER_LIMIT - giftMessage.length
                                : GIFT_MESSAGE_CHARACTER_LIMIT,
                            }}
                          />
                        </div>
                      </Column>
                    </Row>
                  </Panel.Content>
                </Panel>
              </Column>
            </Row>
          </Grid>
        )}
      />
    );
  }
}

GiftMessaging.propTypes = {
  onChange: PropTypes.func.isRequired,
  giftMessage: PropTypes.string,
  onHeaderClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

GiftMessaging.defaultProps = {
  giftMessage: undefined,
};

export default GiftMessaging;
