import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import { extendChildren, isElementOfType } from '@flowio/react-helpers';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';

import { PanelGroup } from '../panel';
import RadioPanel from '../radio-panel';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./radio-panel-group.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('radio-panel-group');

class RadioPanelGroup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  getInitialState() {
    const { defaultValue, value } = this.props;
    return {
      value: isUndefined(value) ? defaultValue : value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      errorText: previousErrorText,
      value: previousValue,
    } = this.props;

    const {
      name,
      errorText,
      value,
    } = nextProps;

    if (previousValue !== value) {
      this.setState({ value });
    }

    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: name,
      });
    }
  }

  handleChange(event, value) {
    const { onChange } = this.props;

    if (!this.isControlled()) {
      this.setState({ value });
    }

    onChange(event, value);
  }

  handleBlur(event) {
    const { onBlur } = this.props;
    onBlur(event);
  }

  handleFocus(event) {
    const { onFocus } = this.props;
    onFocus(event);
  }

  isControlled() {
    const { value } = this.props;
    return !isUndefined(value);
  }

  render() {
    const {
      accordion, children, disabled, errorText, name,
    } = this.props;
    const { value } = this.state;
    return (
      <div className={bem.block()}>
        <PanelGroup accordion={accordion} activeKey={value}>
          {extendChildren(children, (child) => {
            if (isElementOfType(child, RadioPanel)) {
              return {
                collapsible: accordion,
                checked: child.props.value && child.props.value === value,
                expanded: accordion && child.props.value === value,
                disabled,
                name,
                onBlur: this.handleBlur,
                onChange: this.handleChange,
                onFocus: this.handleFocus,
              };
            }

            return undefined;
          })}
        </PanelGroup>
        {errorText && (
          <p className={bem.element('error-text')}>
            {errorText}
          </p>
        )}
      </div>
    );
  }
}

RadioPanelGroup.displayName = 'RadioPanelGroup';

RadioPanelGroup.propTypes = {
  accordion: PropTypes.bool,
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
};

RadioPanelGroup.defaultProps = {
  accordion: true,
  defaultValue: '',
  disabled: false,
  errorText: undefined,
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
  value: undefined,
};

export default RadioPanelGroup;
