import BemHelper from '@flowio/bem-helper';
import React from 'react';
import map from 'lodash/map';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { FormattedMessage } from 'react-intl';

import Button from '../../button';
import Chip from '../../chip';
import Popover from '../../popover';
import { InfoCircle } from '../../svg-icons';
import FeatureToggle from '../../feature-toggle';
import { DUTY_TAX_SINGLE_LINE } from '../../../../common/constants/feature-keys';

if (process.browser) {
  require('./order-prices.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-prices');

type Props = {
  discounts: io.flow.v0.models.OrderPriceDetailComponent[],
  flowVatName: string,
  // Whether customer can update order from any control in this component.
  isDiscountClearable?: boolean,
  isDutyIncluded?: boolean,
  isTaxIncluded?: boolean,
  onRequestRemoveDiscount: (name?: string) => void,
  prices: io.flow.v0.models.OrderPriceDetail[],
  promotionCode?: string,
  taxAndDutyPrice?: io.flow.v0.models.PriceWithBase,
}

const OrderPrices: React.FunctionComponent<Props> = ({
  flowVatName,
  discounts,
  isDiscountClearable = true,
  promotionCode,
  onRequestRemoveDiscount,
  isDutyIncluded = false,
  isTaxIncluded = false,
  taxAndDutyPrice,
  prices,
}) => {
  const getRateText = (rate?: number, rateLabel?: string) => {
    if (rateLabel) {
      return (<span>{`(${rateLabel})`}</span>);
    }
    if (isNumber(rate)) {
      return (
        <span>
          {`${round(rate * 100, 2)}%`}
        </span>
      );
    }
    return null;
  };

  const renderDiscount = (
    key: io.flow.v0.enums.OrderPriceDetailComponentKey,
    label: string,
    removable = false,
    name?: string,
  ) => (
    <>
      <dt className={bem.element('label')}>
        <span className={bem.element('label-text', { [key]: true })}>
          {name}
        </span>
        <div className={bem.element('discount')}>
          <Chip color="positive" size="small" text={name} />
          {isDiscountClearable && promotionCode && removable && (
            <Button className={bem.element('remove-discount')} link size="small" onClick={() => onRequestRemoveDiscount(name)}>
              <FormattedMessage
                id="checkout_order_summary_action_remove_promotion"
                defaultMessage="Remove"
                description="Text describing action to remove applied promotion code"
              />
            </Button>
          )}
        </div>
      </dt>
      <dd className={bem.element('value')}>
        {label}
      </dd>
    </>
  );

  const renderDiscounts = () => map(discounts, (discount) => renderDiscount(
    // hack here to determine if the discount is removable or not.
    discount.key,
    discount.label,
    discount.name === promotionCode,
    discount.name,
  ));

  const renderPrices = ({
    key,
    name,
    label,
    rate_label: rateLabel,
    rate,
  }: io.flow.v0.models.OrderPriceDetail) => {
    switch (key) {
      case 'duty':
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                <FormattedMessage
                  id="checkout_order_summary_duties"
                  description="A label identifying the duty that the customer will be responsible for"
                  defaultMessage="Duties"
                />
              </span>
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );
      case 'subtotal':
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                <FormattedMessage
                  id="checkout_order_summary_line_item_subtotal"
                  description="A label identifying the combined values of the items"
                  defaultMessage="Item Subtotal"
                />
              </span>
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );
      case 'shipping':
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                <FormattedMessage
                  id="checkout_order_summary_line_shipping"
                  description="A label identifying the cost of sending items to the customer"
                  defaultMessage="Shipping"
                />
              </span>
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );
      case 'surcharges':
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                <FormattedMessage
                  id="checkout_order_summary_line_surcharge"
                  description="A label identifying the shipping surcharges to the customer"
                  defaultMessage="Surcharges"
                />
                <Popover
                  className={bem.element('surcharge-popover')}
                  position="bottom left"
                  offset="0 8"
                  constraint="fit"
                  openOnClick
                  trigger={(
                    <span>
                      <InfoCircle className={bem.element('info')} />
                    </span>
                )}
                >
                  <div className={bem.element('surcharge-tooltip')}>
                    <FormattedMessage
                      id="checkout_surcharge_tooltip"
                      description="A message used to help customers understand what exactly is a surcharge"
                      defaultMessage="Surcharges are additional fees charged by the carrier, which may include disbursement fees, fuel surcharges, emergency situation surcharges (i.e. COVID-19, natural disaster, etc.) and remote area delivery fees."
                    />
                  </div>
                </Popover>
              </span>
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );

      case 'discount':
        return renderDiscounts();
      case 'vat':
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                {flowVatName || name}
              </span>
              {getRateText(rate, rateLabel)}
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );
      default:
        return (
          <>
            <dt className={bem.element('label')}>
              <span className={bem.element('label-text', { [key]: true })}>
                {name}
              </span>
              {getRateText(rate, rateLabel)}
            </dt>
            <dd className={bem.element('value')}>
              {label}
            </dd>
          </>
        );
    }
  };

  const renderTaxesBreakout = (price: io.flow.v0.models.OrderPriceDetail) => {
    switch (price.key) {
      case 'duty':
        return (
          <>
            <dt className={bem.element('taxes-content-label')}>
              <span>
                <FormattedMessage
                  id="checkout_order_summary_duties"
                  description="A label identifying the duty that the customer will be responsible for"
                  defaultMessage="Duties"
                />
              </span>
            </dt>
            <dd className={bem.element('taxes-content-value')}>
              {price.label}
            </dd>
          </>
        );
      default:
        return (
          <>
            <dt className={bem.element('taxes-content-label')}>
              <span>
                {flowVatName || price.name}
              </span>
              {getRateText(price.rate, price.rate_label)}
            </dt>
            <dd className={bem.element('taxes-content-value')}>
              {price.label}
            </dd>
          </>
        );
    }
  };

  return (
    <div>
      <dl className={bem.block()}>
        <FeatureToggle
          featureKey={DUTY_TAX_SINGLE_LINE}
          render={({ isFeatureEnabled }) => (
            (isFeatureEnabled
            && prices.some((price) => price.key === 'duty')
            && (prices.some((price) => price.key === 'vat'))
            ) ? (
              <>
                {map(prices.filter((price) => price.key !== 'duty' && price.key !== 'vat'), renderPrices)}
                <>
                  <dt className={bem.element('label')}>
                    <span className={bem.element('label-text', { duty: true })}>
                      <FormattedMessage
                        id="checkout_taxes_tooltip"
                        description="A message used to help customers understand what is included in taxes"
                        defaultMessage="Duties & Taxes"
                      />
                    </span>
                    <Popover
                      className={bem.element('surcharge-popover')}
                      position="bottom left"
                      offset="0 8"
                      constraint="fit"
                      openOnClick
                      trigger={(
                        <span>
                          <InfoCircle className={bem.element('info')} />
                        </span>
              )}
                    >
                      <div className={bem.element('taxes-tooltip')}>
                        <span className={bem.element('taxes-content-title')}>
                          <FormattedMessage
                            id="checkout_taxes_tooltip_title"
                            description="A message used to help customers understand what is included in taxes"
                            defaultMessage="Duties & Taxes Detail"
                          />
                        </span>
                        {map(prices.filter((price) => price.key === 'duty' || price.key === 'vat'), renderTaxesBreakout)}
                        <div className={bem.element('taxes-content-divider')} />
                        <span className={bem.element('taxes-content-total')}>
                          <dt className={bem.element('taxes-content-label')}>
                            <span>
                              <FormattedMessage
                                id="checkout_taxes_tooltip_total"
                                description="Taxes and duties total sum"
                                defaultMessage="Total"
                              />
                            </span>
                          </dt>
                          <dd className={bem.element('taxes-content-value')}>
                            {taxAndDutyPrice && taxAndDutyPrice.label}
                          </dd>
                        </span>
                      </div>
                    </Popover>
                  </dt>
                  <dd className={bem.element('value')}>
                    {taxAndDutyPrice && taxAndDutyPrice.label}
                  </dd>
                </>
              </>
              ) : (
                <>
                  {map(prices, renderPrices)}
                </>
              ))}
        />
        {isTaxIncluded && (
          <>
            <dt className={bem.element('label')}>
              {flowVatName}
            </dt>
            <dd className={bem.element('value')}>
              <FormattedMessage
                id="checkout_order_summary_line_tax_included"
                description="A label used in conjuction with another in the order summary describing that taxes are included in the order total"
                defaultMessage="Included"
              />
            </dd>
          </>
        )}
        {isDutyIncluded && (
          <>
            <dt className={bem.element('label')}>
              <FormattedMessage
                id="checkout_order_summary_line_duty"
                description="A label identifying a charge as being additional Duty charges"
                defaultMessage="Duty"
              />
            </dt>
            <dd className={bem.element('value')}>
              <FormattedMessage
                id="checkout_order_summary_line_duty_included"
                description="A label used in conjuction with another in the order summary describing that duties are included in the order total"
                defaultMessage="Included"
              />
            </dd>
          </>
        )}
      </dl>
    </div>
  );
};

export default OrderPrices;
