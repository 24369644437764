import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AddressBookState } from '../types';
import type { AppReducer } from '../../types';

const initialState: AddressBookState = {
  contacts: [],
};

const addressBookReducer: AppReducer<AddressBookState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_ADDRESS_BOOK:
      return update(previousState, {
        contacts: {
          $set: action.payload.contacts.map((_) => _.id),
        },
      });
    case actionTypes.REMOVE_ADDRESS_BOOK_CONTACT_SUCCESS:
      return update(previousState, {
        contacts: {
          $splice: [
            [previousState.contacts.indexOf(action.meta.contactId), 1],
          ],
        },
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return update(previousState, {
        contacts: {
          $set: action.payload.address_book != null
            ? action.payload.address_book.contacts.map((_) => _.id)
            : previousState.contacts,
        },
      });
    case actionTypes.SAVE_CUSTOMER_ADDRESS_SUCCESS:
      return update(previousState, {
        contacts: {
          $push: [action.payload.id],
        },
      });
    default:
      return previousState;
  }
};

export default addressBookReducer;
