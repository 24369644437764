import { combineReducers } from 'redux';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { PaginationState, PaymentMethodRulesPage } from '../types';

type PageNumber = string | number;

type PageRecord = Partial<Record<PageNumber, PaymentMethodRulesPage>>;

type State = PaginationState<PaymentMethodRulesPage>;

const pages: AppReducer<PageRecord> = (
  previousState = {},
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_REQUEST:
      return update(previousState, {
        [action.params.pageNumber]: {
          $apply: (previousPage) => ({
            ...previousPage,
            ids: previousPage != null ? previousPage.ids : [],
            params: action.params,
            status: 'loading',
          }),
        },
      });
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_SUCCESS:
      return update(previousState, {
        [action.params.pageNumber]: {
          $set: {
            ids: action.payload.results.map((_) => _.payment_method.id),
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
            params: action.params,
            status: 'fulfilled',
          },
        },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_REQUEST:
    case actionTypes.FETCH_CHECKOUT_REQUEST: {
      return update(previousState, {
        1: {
          $apply: (previousPage) => ({
            ...previousPage,
            ids: previousPage != null ? previousPage.ids : [],
            status: 'loading',
          }),
        },
      });
    }
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS: {
      return update(previousState, {
        1: {
          $set: {
            ids: action.payload.payment.method_rules.map((_) => _.payment_method.id),
            isFirstPage: true,
            isLastPage: true,
            status: 'fulfilled',
          },
        },
      });
    }
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_FAILURE:
      return update(previousState, {
        [action.params.pageNumber]: {
          $apply: (previousPage) => ({
            ...previousPage,
            error: action.payload,
            ids: previousPage != null ? previousPage.ids : [],
            params: action.params,
            status: 'rejected',
          }),
        },
      });
    default:
      return previousState;
  }
};

const currentPage: AppReducer<PageNumber> = (
  previousState = 1,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_REQUEST:
      return previousState;
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_SUCCESS:
      return action.params.pageNumber;
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_FAILURE:
      return action.params.pageNumber;
    default:
      return previousState;
  }
};

export default combineReducers<State>({
  currentPage,
  pages,
});
