import {
  createOrder,
  fetchOrder,
  isCheckoutResourceFeatureEnabled,
  setCheckoutPreloaded,
} from '../../checkout';
import { createCheckoutOrder } from '../../order';
import { visitContactInfoStep } from '../../navigation';
import checkHttpStatus from '../../../../common/utilities/check-http-status';
import toCartOrderPutForm from '../utilities/to-cart-order-put-form';
import checkOrderBuilderStatus from '../../../utilities/check-order-builder-status';
import getFeatureKeys from '../../../utilities/get-feature-keys';

/**
 * Transition from Cart UI to Checkout UI
 *
 * @param {string} organization The Flow organization
 * @param {string} orderNumber The order number or cart id, depending on the integration / feature combination
 */
export default function transitionToCheckout(organization, orderNumber) {
  return function transitionToCheckoutEffects(
    dispatch,
    getState,
  ) {
    const state = getState();
    const isCheckoutResourceEnabled = isCheckoutResourceFeatureEnabled(state);

    // Expected flow takes user to /checkouts/:id
    if (isCheckoutResourceEnabled) {
      return dispatch(fetchOrder(organization, orderNumber))
        .then(checkHttpStatus)
        .then((response) => {
          const { result: order } = response;
          const orderPutForm = toCartOrderPutForm(order);
          const form = {
            discriminator: 'order',
            organization,
            order: orderPutForm,
            feature_keys: getFeatureKeys(),
          };
          return dispatch(createCheckoutOrder(form));
        })
        .then(checkHttpStatus)
        .then(checkOrderBuilderStatus)
        .then(() => {
          dispatch(setCheckoutPreloaded());
          return dispatch(visitContactInfoStep());
        });
    }

    // Legacy flow takes user to /:organization/checkout/:number/contact-info
    return dispatch(createOrder(orderNumber))
      .then(checkHttpStatus)
      .then(() => dispatch(visitContactInfoStep()));
  };
}
