// @ts-check

// Enums used to identify payment options in the payment form.
// Generally, used to target payment methods that change the user interface behavior.

/**
 * @type {import('../store/checkout/types').PaymentTypeEnum}
 */
const PaymentTypes = {
  ACH: 'ach',
  Afterpay: 'afterpay',
  ApplePay: 'apple_pay',
  CreditCard: 'credit_card',
  Card: 'card',
  CryptoPay: 'cryptocom_pay',
  DebitCard: 'debit_card',
  Klarna: 'klarna',
  GooglePay: 'google_pay',
  PayPal: 'paypal',
  Credit: 'credit',
};

export default PaymentTypes;
