import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OrderItem from '../components/order-item';
import {
  getIsWrapCartItemEnabled,
} from '../store/checkout/selectors';

const mapStateToProps = createStructuredSelector({
  isWrapCartItemEnabled: getIsWrapCartItemEnabled,
});

export default connect(mapStateToProps)(OrderItem);
