import initSession from '@flowio/session/lib/init';
import storage from '@flowio/storage';
import { parseQueryString } from '@flowio/web-sdk';

/**
 * Initialize session. Support checkout specific use cases like
 * redirects to checkout that have the `flow_session_id` url parameter
 *
 * @param {Object}                - Settings
 * @param {String} *.organization - the organization init beacon with
 * @param {Function} *.onReady    - the callback to execute when beacon is ready
 */
export function init({ organization, onReady }) {
  const params = parseQueryString(window.location.search);
  const {
    flow_session_id: sessionId,
    flow_session_storage: useSessionStorage,
    language: languageParam,
  } = params;
  const languageCookie = storage.cookie.get('language');

  // if a language param is passed store preference in a cookie to override header
  if (languageParam && languageParam !== languageCookie) {
    storage.cookie.set('language', languageParam, { path: '/', expires: 31536000 });
  }

  initSession({
    organization,
    sessionId,
    useSessionStorage,
    onSuccess: () => {
      onReady();
    },
  });
}

export default {
  init,
};
