/* eslint-disable react/jsx-props-no-spreading */

/**
 * @fileoverview
 * A component that handles rendering of errors that may occur after a customer presses the complete
 * order button on the payment step. Some of the errors are manually thrown to handle application
 * specific scenarios not enforced by Flow API.
 */

import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { isAuthorizationError } from '../../utilities/errors/authorization-error';
import AuthorizationError from '../authorization-error';
import CardError from '../card-error';
import GenericError from '../generic-error';
import CardErrorTypes from '../../constants/card-error-types';
import OrderErrorTypes from '../../constants/order-error-types';
import UnknownError from '../unknown-error';

const OrderSubmissionError = ({
  error,
  orderNumber,
  ...unhandledProps
}) => {
  if (isAuthorizationError(error)) {
    return (<AuthorizationError error={error} />);
  }

  switch (error.code) {
  // Generally, 422 responses from api.flow.io
    case OrderErrorTypes.GENERIC:
      return (<GenericError {...unhandledProps} error={error} />);
      // Generally, 422 responses from js.flow.io
    case CardErrorTypes.ADDRESS_VERIFICATION:
    case CardErrorTypes.CARD_VERIFICATION:
    case CardErrorTypes.FRAUD:
    case CardErrorTypes.INVALID_ADDRESS:
    case CardErrorTypes.INVALID_EXPIRATION:
    case CardErrorTypes.INVALID_NAME:
    case CardErrorTypes.INVALID_NUMBER:
    case CardErrorTypes.INVALID_TOKEN_TYPE:
    case CardErrorTypes.UNKNOWN:
      return (<CardError {...unhandledProps} error={error} />);
    default:
      return (<UnknownError {...unhandledProps} error={error} orderNumber={orderNumber} />);
  }
};

OrderSubmissionError.displayName = 'OrderSubmissionError';

OrderSubmissionError.propTypes = {
  error: PropTypes.oneOfType([
    ApiPropTypes.cardError,
    PropTypes.shape({
      code: PropTypes.oneOf([
        OrderErrorTypes.ORDER_BALANCE,
      ]).isRequired,
    }),
    PropTypes.shape({
      authorization: ApiPropTypes.authorization.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stack: PropTypes.string,
    }),
  ]).isRequired,
  orderNumber: PropTypes.string.isRequired,
};

export default OrderSubmissionError;
