import { defineMessages, MessageDescriptor, FormatMessage } from 'react-intl';
import { Constraints, Validators } from 'validate.js';
import includes from 'lodash/includes';

type Keys = <T>(o: T) => (Extract<keyof T, string>)[];

export type AdministrativeAreaType =
  | 'area'
  | 'county'
  | 'department'
  | 'district'
  | 'do_si'
  | 'emirate'
  | 'island'
  | 'oblast'
  | 'parish'
  | 'prefecture'
  | 'province'
  | 'region'
  | 'state'
  | 'territory';

export interface AdministrativeAreaTypeEnum {
  AREA: 'area';
  COUNTY: 'county';
  DEPARTMENT: 'department';
  DISTRICT: 'district';
  DO_SI: 'do_si';
  EMIRATE: 'emirate';
  ISLAND: 'island';
  OBLAST: 'oblast';
  PARISH: 'parish';
  PREFECTURE: 'prefecture';
  PROVINCE: 'province';
  REGION: 'region';
  STATE: 'state';
  TERRITORY: 'territory';
}

/**
 * Enumerates available administrative area types.
 * @see https://en.wikipedia.org/wiki/Administrative_division
 * @see https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country
 * @type {AdministrativeAreaTypeEnum}
 */
export const AdministrativeAreaType: AdministrativeAreaTypeEnum = {
  AREA: 'area',
  COUNTY: 'county',
  DEPARTMENT: 'department',
  DISTRICT: 'district',
  DO_SI: 'do_si',
  EMIRATE: 'emirate',
  ISLAND: 'island',
  OBLAST: 'oblast',
  PARISH: 'parish',
  PREFECTURE: 'prefecture',
  PROVINCE: 'province',
  REGION: 'region',
  STATE: 'state',
  TERRITORY: 'territory',
};

export type PostalCodeType =
  | 'eircode'
  | 'pin'
  | 'postal'
  | 'zip';

export interface PostalCodeTypeEnum {
  EIR: 'eircode';
  PIN: 'pin';
  POSTAL: 'postal';
  ZIP: 'zip';
}

/**
 * Enumerates available postal code types.
 * @see https://en.wikipedia.org/wiki/Postal_code
 * @type {PostalCodeTypeEnum}
 */
export const PostalCodeType: PostalCodeTypeEnum = {
  EIR: 'eircode',
  PIN: 'pin',
  POSTAL: 'postal',
  ZIP: 'zip',
};

/**
 * Returns the administrative area type for the specified country.
 *
 * Called the "state" in the United States, "province" in France and Italy,
 * "county" in Great Britain, "prefecture" in Japan, etc.
 *
 * @param countryCode ISO country code
 * @returns The administrative area type enumeration.
 */
export function getAdministrativeAreaType(
  countryCode: string,
): AdministrativeAreaType {
  switch (countryCode) {
    case 'HKG': // Hong Kong
      return AdministrativeAreaType.DISTRICT;
    case 'AUS': // Australia
    case 'MYS': // Malaysia
      return AdministrativeAreaType.TERRITORY;
    case 'EGY': // Egypt
    case 'GTM': // Guatemala
    case 'NZL': // New Zealand
    case 'PAN': // Panama
    case 'PRT': // Portugal
    case 'RUS': // Russia
    case 'ARE': // United Arab Emirates
      return AdministrativeAreaType.REGION;
    case 'BRA': // Brazil
    case 'IND': // India
    case 'MEX': // Mexico
    case 'USA': // United States
      return AdministrativeAreaType.STATE;
    case 'JPN': // Japan
      return AdministrativeAreaType.PREFECTURE;
    default:
      return AdministrativeAreaType.PROVINCE;
  }
}

/**
 * Returns the postal code type for the specified country.
 *
 * Called the "zip code" in the United States, "postal code" in France and
 * Italy, "eircode" in Ireland, etc.
 *
 * @param countryCode ISO country code
 * @returns The postal code type enumeration.
 */
export function getPostalCodeType(
  countryCode: string,
): PostalCodeType {
  switch (countryCode) {
    case 'USA':
      return PostalCodeType.ZIP;
    default:
      return PostalCodeType.POSTAL;
  }
}

/**
 * A list of countries without postal codes.
 */
export const countriesWithoutPostalCodes: string[] = [
  'AGO', // Angola
  'ATG', // Antigua and Barbuda
  'ABW', // Aruba
  'SHN', // Saint Helena, Ascension and Tristan da Cunha
  'BHS', // Bahamas
  'BLZ', // Belize
  'BEN', // Benin
  'BOL', // Bolivia
  'BES', // Bonaire, Sint Eustatius and Saba
  'BWA', // Botswana
  'BFA', // Burkina Faso
  'BDI', // Burundi
  'CMR', // Cameroon
  'CAF', // Central African Republic
  'COM', // Comoros
  'COG', // Congo
  'COD', // Democratic Republic of the Congo
  'COK', // Cook Islands
  'CIV', // Cote d’Ivoire
  'CUW', // Curaçao
  'DJI', // Djibouti
  'DMA', // Dominica
  'TLS', // East Timor (Timor-Leste)
  'GNQ', // Equatorial Guinea
  'ERI', // Eritrea
  'FJI', // Fiji
  'ATF', // French Southern Territories
  'GAB', // Gabon
  'GMB', // Gambia
  'GHA', // Ghana
  'GRD', // Grenada
  'GUY', // Guyana
  'HMD', // Heard Island and McDonald Islands
  'HKG', // Hong Kong
  'KIR', // Kiribati
  'LBY', // Libya
  'MAC', // Macau
  'MWI', // Malawi
  'MLI', // Mali
  'MRT', // Mauritania
  'NRU', // Nauru
  // Netherlands Antilles (ISO 3166-3 Missing)
  'NIU', // Niue
  // North Korea (ISO 3166-3 Missing)
  'PAN', // Panama
  'QAT', // Qatar
  'RWA', // Rwanda
  'KNA', // Saint Kitts and Nevis
  'STP', // Sao Tome and Principe
  'SYC', // Seychelles
  'SLE', // Sierra Leone
  'SLB', // Solomon Islands
  'SUR', // Suriname
  'SYR', // Syria
  'TGO', // Togo
  'TKL', // Tokelau
  'TON', // Tonga
  'TTO', // Trinidad & Tobago
  'TUV', // Tuvalu
  'UGA', // Uganda
  'ARE', // United Arab Emirates
  'VUT', // Vanuatu
  'YEM', // Yemen
  'ZWE', // Zimbabwe
];

/**
 * A list of countries with administrative areas.
 */
export const countriesWithRequiredAdministrativeArea: string[] = [
  'ARG', // Argentina
  'AUS', // Australia
  'CAN', // Canada
  'BRA', // Brazil
  'CHN', // China
  'EGY', // Egypt
  'GTM', // Guatemala
  'HKG', // Hong Kong
  'IND', // India
  'IDN', // Indonesia
  'ITA', // Italy
  'JPN', // Japan
  'MYS', // Malaysia
  'MEX', // Mexico
  'NZL', // New Zealand
  'PAN', // Panama
  'PRT', // Portugal
  'RUS', // Russia
  'ZAF', // South Africa
  'KOR', // South Korea
  'ESP', // Spain
  'CHE', // Switzerland
  'THA', // Thailand
  'ARE', // United Arab Emirates
  'USA', // United States
  'ROU', // Romania
];

export const isAddressFieldVisible = (
  name: io.flow.v0.enums.AddressFieldName,
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  forcedVisibleFields: string[] = [],
): boolean => {
  // Note that this is a temporary hack for supporting required_if_present
  // validation specifically for the vat_registration_number as the placements
  // do not contain it. This provides a way to only eliminate a field when
  // we know it should not be visible
  if (forcedVisibleFields.indexOf(name) >= 0) {
    return true;
  }

  // Note that, address formatting is enforced through the address configuration
  // model when the global_address_configuration_formats_by_country feature is
  // enabled. The formats field on the address configuration model is not
  // defined unless the feature is enabled.
  if (addressConfiguration.formats != null) {
    return addressConfiguration.formats.some(
      (format) => format.placements.some(
        (placement) => placement.name === name,
      ),
    );
  }

  if (name === 'postal') {
    return !includes(countriesWithoutPostalCodes, addressConfiguration.country);
  }

  if (name === 'province') {
    return includes(countriesWithRequiredAdministrativeArea, addressConfiguration.country);
  }

  return true;
};

/**
 * Returns whether the field representing the postal code for an address
 * should be present.
 * @param addressConfiguration
 */
export function isPostalCodeVisible(
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
): boolean {
  return isAddressFieldVisible('postal', addressConfiguration);
}

/**
 * Returns whether the field representing the administrative division of an
 * address should be present.
 * @param addressConfiguration
 */
export function isAdministrativeAreaVisible(
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
): boolean {
  return isAddressFieldVisible('province', addressConfiguration);
}

/**
 * Error messages displayed when validation addresses.
 */
export const messages = defineMessages<MessageDescriptor, Record<string, MessageDescriptor>>({
  required: {
    id: 'checkout_field_error_required',
    description: 'A validation error message displayed when a required field value is not provided',
    defaultMessage: 'This field is required',
  },
  missingCity: {
    id: 'checkout_address_field_error_missing_city',
    description: 'A validation error message displayed when a city is not provided to address forms',
    defaultMessage: 'Please enter city',
  },
  missingCountry: {
    id: 'checkout_address_field_error_missing_country',
    description: 'A validation error message displayed in address forms when a country is not provided',
    defaultMessage: 'Please enter country',
  },
  missingDistrict: {
    id: 'checkout_address_field_error_missing_district',
    description: 'A validation error message displayed in address forms when a district is not provided',
    defaultMessage: 'Please enter your district',
  },
  missingEmail: {
    id: 'checkout_customer_information_field_error_missing_email',
    description: 'A validation error message displayed in the customer information form when an email is not provided',
    defaultMessage: 'Please enter your email',
  },
  missingFirstName: {
    id: 'checkout_address_field_error_missing_first_name',
    description: 'A validation error message displayed in address forms when a first name is not provided',
    defaultMessage: 'Please enter first name',
  },
  missingLastName: {
    id: 'checkout_address_field_error_missing_last_name',
    description: 'A validation error message displayed in address forms when a last name is not provided',
    defaultMessage: 'Please enter last name',
  },
  missingAddressLine1: {
    id: 'checkout_address_field_error_missing_line1',
    description: 'A validation error message displayed in address forms when the first street line is not provided',
    defaultMessage: 'Please enter address',
  },
  missingPostalCode: {
    id: 'checkout_address_field_error_missing_postal_code',
    description: 'A validation error message displayed in address forms when a postal code is not provided',
    defaultMessage: 'Please enter postal code',
  },
  missingProvince: {
    id: 'checkout_address_field_error_missing_province',
    description: 'A validation error message displayed in address forms when a province is not provided',
    defaultMessage: 'Please enter province',
  },
  missingRegion: {
    id: 'checkout_address_field_error_missing_region',
    description: 'A validation error message displayed in address forms when a region is not provided',
    defaultMessage: 'Please enter region',
  },
  missingState: {
    id: 'checkout_address_field_error_missing_state',
    description: 'A validation error message displayed in address forms when a state is not provided',
    defaultMessage: 'Please enter state',
  },
  missingTelephone: {
    id: 'checkout_address_field_error_missing_telephone',
    description: 'A validation error message displayed in address forms when a telephone is not provided',
    defaultMessage: 'Please enter your phone number',
  },
  missingTerritory: {
    id: 'checkout_address_field_error_missing_territory',
    description: 'A validation error message displayed in address forms when a territory is not provided',
    defaultMessage: 'Please enter state or territory',
  },
  missingPrefecture: {
    id: 'checkout_address_field_error_missing_prefecture',
    description: 'A validation error message displayed in address forms when a prefecture is not provided',
    defaultMessage: 'Please enter prefecture',
  },
  missingZipCode: {
    id: 'checkout_address_field_error_missing_zip_code',
    description: 'A validation error message displayed in address forms when a zip code is not provided',
    defaultMessage: 'Please enter zip code',
  },
  tooShort: {
    id: 'checkout_field_error_too_short',
    description: 'A validation error message displayed when a field value is too short',
    // This is a bug in the rule. Once fixed we can remove.
    // https://github.com/formatjs/formatjs/issues/356
    // eslint-disable-next-line formatjs/enforce-placeholders
    defaultMessage: 'Sorry, this field cannot be shorter than {length, plural, one {# character} other {# characters}} long',
  },
  tooLong: {
    id: 'checkout_address_field_error_character_limit',
    description: 'A validation error message displayed when a field exceeds the maximum number of characters allowed',
    // This is a bug in the rule. Once fixed we can remove.
    // https://github.com/formatjs/formatjs/issues/356
    // eslint-disable-next-line formatjs/enforce-placeholders
    defaultMessage: 'Sorry, this field cannot be more than {length, plural, one {# character} other {# characters}} long',
  },
  invalid: {
    id: 'checkout_field_error_invalid',
    description: 'A validation error message displayed when a field value is invalid',
    defaultMessage: 'This field is invalid',
  },
  invalidCustomerAddressBook: {
    id: 'checkout_field_error_address_book',
    description: 'An error message that is shown when an incomplete address book is selected',
    defaultMessage: 'The address you have selected is incomplete. Please update the address or select a different address.',
  },
});

export type FieldValidationRuleType =
  | 'required'
  | 'required_if_present'
  | 'max'
  | 'min'
  | 'pattern';

export interface FieldValidationRuleEnum {
  REQUIRED: 'required';
  REQUIRED_IF_PRESENT: 'required_if_present';
  MAX: 'max';
  MIN: 'min';
  PATTERN: 'pattern';
}

export const FieldValidationRuleType: FieldValidationRuleEnum = {
  REQUIRED: 'required',
  REQUIRED_IF_PRESENT: 'required_if_present',
  MAX: 'max',
  MIN: 'min',
  PATTERN: 'pattern',
};

/**
 * @deprecated Use `FieldValidationRuleType` instead.
 */
export const FieldValidationType = FieldValidationRuleType;

export type AddressFieldValidationKey =
  | 'first_name'
  | 'last_name'
  | 'street_1'
  | 'street_2'
  | 'city'
  | 'province'
  | 'postal'
  | 'phone'
  | 'vat_registration_number'

export interface AddressFieldValidationKeyEnum {
  FIRST_NAME: 'first_name';
  LAST_NAME: 'last_name';
  ADDRESS_LINE_1: 'street_1';
  ADDRESS_LINE_2: 'street_2';
  CITY: 'city';
  PROVINCE: 'province';
  POSTAL: 'postal';
  PHONE: 'phone';
  VAT_REGISTRATION_NUMBER: 'vat_registration_number';
}

/**
 * An object enumerating the possible address field validation keys within
 * an address configuration model.
 */
export const AddressFieldValidationKey: AddressFieldValidationKeyEnum = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ADDRESS_LINE_1: 'street_1',
  ADDRESS_LINE_2: 'street_2',
  CITY: 'city',
  PROVINCE: 'province',
  POSTAL: 'postal',
  PHONE: 'phone',
  VAT_REGISTRATION_NUMBER: 'vat_registration_number',
};

/**
 * Remove any `field_validation_rule` that does not apply based on our existing
 * address formatting logic.
 *
 * Note that, this is necessary because field validation rules in the address
 * configuration model are not aware of field placements (i.e. a field that is
 * not displayed cannot be forced to be required). The same behavior can be
 * observed when the address configuration model enforces address formatting.
 */
export function removeUnnecessaryFieldValidationRules(
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  forcedVisibleFields?: string[],
): io.flow.v0.models.AddressConfiguration {
  const {
    field_validation: fieldValidation,
  } = addressConfiguration;

  const result = {
    ...addressConfiguration,
    field_validation: Object
      .keys(fieldValidation)
      .map<
    [keyof io.flow.v0.models.AddressFieldValidation, io.flow.v0.unions.FieldValidationRule[]]
    >((key) => {
      const fieldName = key as keyof io.flow.v0.models.AddressFieldValidation;
      const fieldValidationRules = fieldValidation[fieldName];
      return [fieldName, fieldValidationRules];
    })
      .reduce<io.flow.v0.models.AddressFieldValidation>((
      previousFieldValidation,
      [fieldName, fieldValidationRules],
    ) => ({
      ...previousFieldValidation,
      [fieldName]: fieldValidationRules.filter((fieldValidationRule) => {
        switch (fieldValidationRule.discriminator) {
          case 'required':
            return isAddressFieldVisible(fieldName, addressConfiguration);
          case 'required_if_present':
            return isAddressFieldVisible(fieldName, addressConfiguration, forcedVisibleFields);
          default:
            return true;
        }
      }),
    }), fieldValidation),
  };
  return result;
}

/**
 * Returns an `address_field_validation` model matching behavior prior to
 * introduction of Address Configuration API.
 * @param {string} countryCode
 * @returns {AddressFieldValidation}
 */
export function getDefaultAddressFieldValidation(): io.flow.v0.models.AddressFieldValidation {
  return {
    [AddressFieldValidationKey.FIRST_NAME]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.LAST_NAME]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.ADDRESS_LINE_1]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.ADDRESS_LINE_2]: [{
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.CITY]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.PROVINCE]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.POSTAL]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 12,
    }],
    [AddressFieldValidationKey.PHONE]: [{
      discriminator: FieldValidationRuleType.REQUIRED,
    }, {
      discriminator: FieldValidationRuleType.MAX,
      length: 35,
    }],
    [AddressFieldValidationKey.VAT_REGISTRATION_NUMBER]: [{
      discriminator: FieldValidationRuleType.REQUIRED_IF_PRESENT,
    }],
  };
}

/**
 * This helper is only useful in tests. It's not as useful as the selectors
 * because the administrative divisions are always missing, which the selectors
 * can read from the application state.
 */
export function getDefaultAddressConfigurationByCountryCode(
  countryCode: string,
): io.flow.v0.models.AddressConfiguration {
  return {
    country: countryCode,
    field_validation: getDefaultAddressFieldValidation(),
    provinces: [],
  };
}

/**
 * Returns the name of the address field mapping to the field validation key
 * returned from Flow API.
 * @param key
 */
export function addressFieldValidationKeyToFieldName(
  key: AddressFieldValidationKey,
): string {
  switch (key) {
    case AddressFieldValidationKey.FIRST_NAME:
      return 'firstName';
    case AddressFieldValidationKey.LAST_NAME:
      return 'lastName';
    case AddressFieldValidationKey.ADDRESS_LINE_1:
      return 'addressLine1';
    case AddressFieldValidationKey.ADDRESS_LINE_2:
      return 'addressLine2';
    case AddressFieldValidationKey.CITY:
      return 'locality';
    case AddressFieldValidationKey.PROVINCE:
      return 'administrativeArea';
    case AddressFieldValidationKey.POSTAL:
      return 'postalCode';
    case AddressFieldValidationKey.PHONE:
      return 'phoneNumber';
    case AddressFieldValidationKey.VAT_REGISTRATION_NUMBER:
      return 'vrn';
    default:
      return key;
  }
}

function getFieldValidationRulesByFieldName(
  fieldName: string,
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
): io.flow.v0.unions.FieldValidationRule[] {
  switch (fieldName) {
    case 'firstName':
      return addressConfiguration.field_validation.first_name;
    case 'lastName':
      return addressConfiguration.field_validation.last_name;
    case 'addressLine1':
      return addressConfiguration.field_validation.street_1;
    case 'addressLine2':
      return addressConfiguration.field_validation.street_2;
    case 'locality':
      return addressConfiguration.field_validation.city;
    case 'administrativeArea':
      return addressConfiguration.field_validation.province;
    case 'postalCode':
      return addressConfiguration.field_validation.postal;
    case 'phoneNumber':
      return addressConfiguration.field_validation.phone;
    case 'vrn':
      return addressConfiguration.field_validation.vat_registration_number;
    default:
      return [];
  }
}

/**
 * Returns whether an address field is required in the provided
 * address configuration.
 * @param fieldName
 * @param addressConfiguration
 */
export function isFieldRequired(
  fieldName: string,
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
): boolean {
  const rules = getFieldValidationRulesByFieldName(fieldName, addressConfiguration);
  return rules.some((rule) => rule.discriminator === 'required' || rule.discriminator === 'required_if_present');
}

function getPresenceValidationMessageByFieldNameAndCountryCode(
  fieldName: string,
  countryCode: string,
): MessageDescriptor {
  switch (fieldName) {
    case 'firstName':
      return messages.missingFirstName;
    case 'lastName':
      return messages.missingLastName;
    case 'addressLine1':
      return messages.missingAddressLine1;
    case 'locality':
      return messages.missingCity;
    case 'administrativeArea': {
      switch (getAdministrativeAreaType(countryCode)) {
        case AdministrativeAreaType.DISTRICT:
          return messages.missingDistrict;
        case AdministrativeAreaType.REGION:
          return messages.missingRegion;
        case AdministrativeAreaType.STATE:
          return messages.missingState;
        case AdministrativeAreaType.TERRITORY:
          return messages.missingTerritory;
        case AdministrativeAreaType.PREFECTURE:
          return messages.missingPrefecture;
        default:
          return messages.missingProvince;
      }
    }
    case 'postalCode': {
      switch (getPostalCodeType(countryCode)) {
        case PostalCodeType.ZIP:
          return messages.missingZipCode;
        default:
          return messages.missingPostalCode;
      }
    }
    case 'phoneNumber':
      return messages.missingTelephone;
    default:
      return messages.required;
  }
}

export function addressConfigurationToConstraints(
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  formatMessage: FormatMessage,
  registeredFields?: string[],
): Constraints {
  const {
    country: countryCode,
    field_validation: addressFieldValidation,
  } = addressConfiguration;

  const initialConstraints: Constraints = {};

  const addressFieldValidationKeys = ((Object.keys as Keys))(addressFieldValidation);

  return addressFieldValidationKeys.reduce(
    (previousContraints, addressFieldValidationKey) => {
      const fieldName = addressFieldValidationKeyToFieldName(addressFieldValidationKey);
      const fieldValidationsRules = addressFieldValidation[addressFieldValidationKey];
      const initialValidators: Validators = {};
      const validators = fieldValidationsRules.reduce(
        (previousValidators, fieldValidationRule) => {
          switch (fieldValidationRule.discriminator) {
            case 'max':
              return {
                ...previousValidators,
                length: {
                  ...previousValidators.length,
                  maximum: fieldValidationRule.length,
                  tooLong: formatMessage(messages.tooLong, { length: fieldValidationRule.length }),
                },
              };
            case 'min':
              return {
                ...previousValidators,
                length: {
                  ...previousValidators.length,
                  minimum: fieldValidationRule.length,
                  tooShort: formatMessage(messages.tooShort, {
                    length: fieldValidationRule.length,
                  }),
                },
              };
            case 'pattern':
              return {
                ...previousValidators,
                format: {
                  pattern: fieldValidationRule.pattern,
                  message: formatMessage(messages.invalid),
                },
              };
            case 'required':
              return {
                ...previousValidators,
                presence: {
                  allowEmpty: false,
                  message: formatMessage(
                    getPresenceValidationMessageByFieldNameAndCountryCode(fieldName, countryCode),
                  ),
                },
              };
            case 'required_if_present': {
              if (!registeredFields || registeredFields.indexOf(fieldName) < 0) {
                return previousValidators;
              }
              return {
                ...previousValidators,
                presence: {
                  allowEmpty: false,
                  message: formatMessage(
                    getPresenceValidationMessageByFieldNameAndCountryCode(fieldName, countryCode),
                  ),
                },
              };
            }
            default:
              return previousValidators;
          }
        },
        initialValidators,
      );

      return {
        ...previousContraints,
        [fieldName]: validators,
      };
    },
    initialConstraints,
  );
}

export function formatSingleLineNameLabel(
  contact: io.flow.v0.models.CustomerAddressBookContact,
): string {
  const result = [];

  if (contact.contact.name.first != null) {
    result.push(contact.contact.name.first);
  }

  if (contact.contact.name.last != null) {
    result.push(contact.contact.name.last);
  }

  return result.join(' ');
}

export function formatSingleLineAddressLabel(
  contact: io.flow.v0.models.CustomerAddressBookContact,
): string {
  const result = [];

  if (contact.contact.company != null) {
    result.push(contact.contact.company);
  }

  if (contact.address.streets != null) {
    result.push(...contact.address.streets);
  }

  if (contact.address.city != null) {
    result.push(contact.address.city);
  }

  if (contact.address.province != null) {
    result.push(contact.address.province);
  }

  if (contact.address.postal != null) {
    result.push(contact.address.postal);
  }

  if (contact.address.country != null) {
    result.push(contact.address.country);
  }

  if (contact.contact.phone != null) {
    result.push(`Phone: ${contact.contact.phone}`);
  }

  return result.join(', ');
}

function compareCaseInsensitive(
  a: string,
  b: string,
): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

export function matchProvinceTranslation(
  translations: io.flow.v0.models.AddressConfigurationProvinceTranslation[],
  value: string,
): io.flow.v0.models.AddressConfigurationProvinceTranslation | undefined {
  return translations.find((translation) => (
    compareCaseInsensitive(translation.name, value)
    || compareCaseInsensitive(translation.locale.name, value)
  ));
}

export function matchProvince(
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  value: string,
): io.flow.v0.models.AddressConfigurationProvince | undefined {
  return addressConfiguration.provinces.find((province) => (
    compareCaseInsensitive(province.value, value)
    || compareCaseInsensitive(province.name, value)
    || (
      province.translations != null
      && matchProvinceTranslation(province.translations, value) != null
    )
  ));
}
