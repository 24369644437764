/**
 * @fileoverview
 *
 * This module provides a React component for conditionally rendering content
 * based on the on the status of a feature value matching the specified
 * feature key.
 *
 * ```javascript
 * <FeatureToggle featureKey="global_checkout_returning_customers">
 *   <AddressPicker />
 * </FeatureToggle>
 * ```
 *
 * The recommended method of rendering something with a `<FeatureToggle>` is to
 * use `children` elements, as shown above. There are, however, a few other
 * methods you can use to render something with a `<FeatureToggle>`. These are
 * provided mostly for supporting more complicated scenarios.
 *
 * ```javascript
 * <FeatureToggle activeComponent />
 * <FeatureToggle inactiveComponent />
 * <FeatureToggle activeComponent inactiveComponent />
 * <FeatureToggle render />
 * <FeatureToggle children />
 * ```
 *
 * You should use only one of the prop combinations above on a given
 * `<FeatureToggle>`. See their explanations below to understand the differences
 * between them.
 *
 * `activeComponent`
 *
 * A React component to render only when the feature value matching the
 * specified `featureKey` is enabled
 *
 * `inactiveComponent`
 *
 * A React component to render only when the feature value matching the
 * specified `featureKey` is disabled
 *
 * When you use `activeComponent` or `inactiveComponent` (instead of `render` or
 * `children`, below) the component uses `React.createElement` to create a new
 * React element from the given component. That means if you provide an inline
 * function to the `activeComponent` or `inactiveComponent` prop, you would
 * create a new component every render. This results in the existing component
 * unmounting and the new component mounting instead of just updating the
 * existing component. When using an inline function for inline rendering,
 * use the `render` prop.
 *
 * `render`
 *
 * This allows for convenient inline rendering and wrapping without the
 * undesired remounting explained above.
 *
 * `children`
 *
 * A React element to render when the feature value matching the specified
 * `featureKey` is enabled. If the matching feature value is diabled, `null` is
 * rendered instead.
 *
 */

import React from 'react';
import { Props } from '../types';

function isFeatureValueEnabled(
  featureValue?: io.flow.internal.v0.unions.FeatureValue,
): boolean {
  return featureValue != null && featureValue.value === true;
}

class FeatureToggle extends React.PureComponent<Props> {
  public static displayName = 'FeatureToggle';

  public render(): React.ReactNode {
    const {
      activeComponent,
      children,
      inactiveComponent,
      featureKey,
      featureValue,
      render,
    } = this.props;

    const isFeatureEnabled = isFeatureValueEnabled(featureValue);

    if (render != null) {
      return render({
        featureKey,
        featureValue,
        isFeatureEnabled,
      });
    }

    if (isFeatureEnabled) {
      if (activeComponent != null) {
        return React.createElement(activeComponent);
      }

      if (children != null && React.Children.count(children)) {
        return children;
      }
    }

    if (inactiveComponent != null) {
      return React.createElement(inactiveComponent);
    }

    return null;
  }
}

export default FeatureToggle;
