/* eslint-disable react/jsx-props-no-spreading */
import { mountable } from '@flowio/react-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import noop from 'lodash/noop';

import AutoLockScrolling from '../auto-lock-scrolling';
import Portal from '../portal';
import handleEnterKeyboardEvent from '../../utilities/handle-enter-keyboard-event';

import DialogActions from './dialog-actions';
import DialogHeader from './dialog-header';
import DialogBody from './dialog-body';
import DialogFooter from './dialog-footer';

if (process.browser) {
  require('./dialog.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('dialog');

class Dialog extends Component {
  handleBackdropClick = (ev) => {
    ev.stopPropagation();
    const { enableBackdropDismiss, onRequestClose } = this.props;
    if (enableBackdropDismiss) {
      onRequestClose();
    }
  }

  render() {
    const {
      ariaLabel,
      className,
      children,
      container,
      enableBackdropDismiss, // eslint-disable-line no-unused-vars
      isOpen,
      isStatic,
      onRequestClose, // eslint-disable-line no-unused-vars
      showBackdrop,
      ...otherProps
    } = this.props;

    const tree = (
      <TransitionGroup appear component="div">
        {isOpen && (
          <CSSTransition classNames="dialog" timeout={300}>
            <article
              {...otherProps}
              className={bem.block(className, { static: isStatic })}
            >
              {showBackdrop && (
                <div
                  aria-label={ariaLabel}
                  className={bem.element('backdrop')}
                  onClick={this.handleBackdropClick}
                  onKeyPress={handleEnterKeyboardEvent(this.handleBackdropClick)}
                  role="button"
                  tabIndex={0}
                />
              )}
              <div className={bem.element('container')}>
                <div className={bem.element('content')}>
                  {children}
                </div>
              </div>
              <AutoLockScrolling isLocked={!isStatic} />
            </article>
          </CSSTransition>
        )}
      </TransitionGroup>
    );

    return isStatic ? (tree) : (
      <Portal container={container}>
        {tree}
      </Portal>
    );
  }
}

Dialog.displayName = 'Dialog';

Dialog.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  container: PropTypes.oneOfType([mountable, PropTypes.func]),
  enableBackdropDismiss: PropTypes.bool,
  isOpen: PropTypes.bool,
  isStatic: PropTypes.bool,
  onRequestClose: PropTypes.func,
  showBackdrop: PropTypes.bool,
};

Dialog.defaultProps = {
  ariaLabel: undefined,
  className: '',
  container: undefined,
  enableBackdropDismiss: false,
  isOpen: false,
  isStatic: false,
  onRequestClose: noop,
  showBackdrop: false,
};

Dialog.Actions = DialogActions;
Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

export default Dialog;
