import get from 'lodash/get';

import { showLoadingIndicator, hideLoadingIndicator } from '../../application/actions/index';
import removePromotionCode from './remove-promotion-code';
import checkHttpStatus from '../../../utilities/check-http-status';
import updatePaymentMethods from './update-payment-methods';
import { isDiscountsEnabled, getOrderAttributes, isShopifyCheckoutCodeNewDeleteEnabled } from '../selectors';
import removeGenericPromotionCode from './remove-generic-promotion-code';
import removeShopifyPromoCode from './remove-shopify-promo-code';

/**
 * @typedef {import('redux-thunk').ThunkAction} ThunkAction
 */

/**
 * Returns an action responsible for removing the promotion code applied to
 * the order and updating the available payment methods. An activity indicator
 * will be rendered while this action is being processed.
 *
 * Since removing a promotion code may change the order total, we need to make
 * sure the payment method rules are updated to reflect the new amount.
 *
 * NOTE: Promotion codes are a Shopify specific feature and only a single
 * promotion code can be applied per order.
 *
 * @param {String} organizationId - The unique organization identifier
 * @param {String} orderNumber - The order number
 *
 * @returns {ThunkAction}
 */
export default function removePromotionCodeAndUpdatePaymentMethods(
  organizationId,
  orderNumber,
  promoCode,
) {
  return function removePromotionCodeAndUpdatePaymentMethodsSideEffects(dispatch, getState) {
    const orderAttributes = getOrderAttributes(getState());
    const promoCodeForRemoval = promoCode || get(orderAttributes, 'discount_code_label', '');

    dispatch(showLoadingIndicator());

    return Promise.resolve()
      .then(() => {
        let action;

        if (isDiscountsEnabled(getState())) {
          if (promoCodeForRemoval) {
            action = removeGenericPromotionCode(organizationId, orderNumber, promoCodeForRemoval);
          }
        } else if (isShopifyCheckoutCodeNewDeleteEnabled(getState())) {
          action = removeShopifyPromoCode(organizationId, orderNumber);
        } else {
          action = removePromotionCode(organizationId, orderNumber);
        }

        return action != null
          ? dispatch(action).then(checkHttpStatus)
          : Promise.resolve();
      })
      .then(() => dispatch(updatePaymentMethods(organizationId)))
      .then(() => dispatch(hideLoadingIndicator()))
      .catch((error) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
            return error.response;
          default:
            throw error;
        }
      });
  };
}
