import React from 'react';
import BemHelper from '@flowio/bem-helper';

import { Dialog } from '../dialog';

import './inline-authorization-dialog.css';

const bem = new BemHelper('inline-authorization-dialog');

type Props = {
  isInlineAuthorizationDialogOpen: boolean;
};

export const inlineAuthorizationDialogIdentifyId = 'inlineAuthorizationIdentify';
export const inlineAuthorizationDialogChallengeId = 'inlineAuthorizationChallenge';

const inlineAuthorizationDialog: React.FC<Props> = ({
  isInlineAuthorizationDialogOpen,
}) => (
  <div className={bem.block()}>
    <div id={inlineAuthorizationDialogIdentifyId} className={bem.element('identify-element')} />
    <Dialog className={bem.element('challenge-modal')} isOpen={isInlineAuthorizationDialogOpen} showBackdrop>
      <Dialog.Body>
        <div id={inlineAuthorizationDialogChallengeId} />
      </Dialog.Body>
    </Dialog>
  </div>
);

export default inlineAuthorizationDialog;
