import { compose } from 'redux';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { withFetch } from '@flowio/redux-fetch';
import { appendQueryParameters, parseQueryString } from '@flowio/web-sdk';
import getFeatureKeys from '../utilities/get-feature-keys';
import { createOrder, checkFeatureValues, setCheckoutPreloaded } from '../store/checkout/actions';
import { createCheckoutOrder } from '../store/order/actions';
import { getIsOrderFulfilled, getIsOrderRejected, isCheckoutResourceFeatureEnabled } from '../store/checkout/selectors';
import { getCartUrl, getContactInfoUrlV2 } from '../store/navigation/selectors';
import WithRedirect from '../components/with-redirect';
import Application from './application';

const fetchAsyncState = (dispatch, getState, ownProps) => {
  const { cartId, organization } = ownProps.params;
  return dispatch(checkFeatureValues(organization, { })).then(() => {
    const checkoutResourceEnabled = isCheckoutResourceFeatureEnabled(getState());

    if (checkoutResourceEnabled) {
      return dispatch(createCheckoutOrder({
        discriminator: 'order_number',
        feature_keys: getFeatureKeys(),
        organization,
        order_number: cartId,
      })).then(() => dispatch(setCheckoutPreloaded())); // Prevent re-fetching of checkout data on next URL change
    }

    return dispatch(createOrder(cartId));
  });
};

const getContactInfoUrlWithParams = (state) => {
  const queryParams = parseQueryString(window.location.search);
  const baseUrl = getContactInfoUrlV2(state);
  const {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  } = queryParams;
  const params = {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  };

  return appendQueryParameters(baseUrl, pickBy(params, identity));
};

export default compose(
  withFetch(fetchAsyncState),
  WithRedirect(getContactInfoUrlWithParams, getIsOrderFulfilled, { replace: true }),
  WithRedirect(getCartUrl, getIsOrderRejected, { replace: true }),
)(Application);
