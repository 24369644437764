import type { FeatureValuesPostResponse } from '@flowio/api-internal-sdk';
import { getIsFeatureValuesFulfilled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import generateFeatureQuery from '../../../utilities/generate-feature-query';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<FeatureValuesPostResponse> | void>;

const checkFeatureValuesRequest = (): RootAction => ({
  type: actionTypes.CHECK_FEATURE_VALUES_REQUEST,
});

const checkFeatureValuesSuccess = (
  featureValueResult: io.flow.internal.v0.models.FeatureValueResult,
): RootAction => ({
  type: actionTypes.CHECK_FEATURE_VALUES_SUCCESS,
  payload: featureValueResult,
});

const checkFeatureValuesFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.CHECK_FEATURE_VALUES_FAILURE,
  payload: error,
});

const checkFeatureValues = (
  organizationId: string,
  featureContextForm: io.flow.internal.v0.models.FeatureContextForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (getIsFeatureValuesFulfilled(getState())) {
    return Promise.resolve();
  }

  const featureQuery = generateFeatureQuery(organizationId);

  dispatch(checkFeatureValuesRequest());

  return extra.apiInternal.featureValues.post({
    organization: organizationId,
    body: {
      feature_query: featureQuery,
      context: featureContextForm,
    },
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 201:
          dispatch(checkFeatureValuesSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(checkFeatureValuesFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }

      return response;
    });
};

export default checkFeatureValues;
