import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function updateOrder(
  order: io.flow.v0.models.Order,
): RootAction {
  return {
    type: actionTypes.UPDATE_ORDER,
    payload: order,
  };
}
