/**
 * Creates a replace point for webpack to inject the value of the
 * `process.env` object. Helps reduce file size of production JavaScript build.
 */
const env = process.env; // eslint-disable-line prefer-destructuring

/**
 * Look up an environment variable in `process.env`. If not found, use
 * `defaultValue` as a fallback.
 *
 * @param  {string} key environment variable name
 * @return {string}     the value of the environment variable at `key`
 */
export function getEnvVar(key, defaultValue) {
  return env[key] ? env[key] : defaultValue;
}

/**
 * Look up an environment variable in `env`. Throw an error if not
 * found.
 *
 * @param  {string} key environment variable name
 * @return {[type]}     the value of the environment variable at `key`
 */
export function getRequiredEnvVar(key) {
  if (!env[key]) {
    throw new Error(`Could not find required environment variable [${key}]`);
  }

  return env[key];
}
