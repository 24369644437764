import { defineMessages } from 'react-intl';
import curry from 'lodash/curry';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import PaymentTypes from '../constants/payment-types';

export const messages = defineMessages({
  paymentMethodGuidance: {
    id: 'checkout_payment_method_guidance',
    description: 'A generic message used to guide customers on the next steps after an alternate payment method is selected.',
    // This is a bug in the rule. Once fixed we can remove.
    // https://github.com/formatjs/formatjs/issues/356
    // eslint-disable-next-line formatjs/enforce-placeholders
    defaultMessage: 'To complete payment using {paymentMethod}, you will be redirected to enter your details and complete the transaction.',
  },
  paypalGuidance: {
    id: 'checkout_payment_method_paypal_guidance',
    description: 'A message used to guide customer on the next steps after PayPal is selected as the payment method.',
    defaultMessage: 'Click PayPal Checkout button below to continue.',
  },
  paypalExpressGuidance: {
    id: 'checkout_payment_method_paypal_express_guidance',
    description: 'A message used to guide customers on the next steps after PayPal is selected as the default payment method.',
    defaultMessage: 'Click Complete with PayPal button below to place your order',
  },
  klarnaGuidance: {
    id: 'checkout_payment_method_klarna_guidance',
    description: 'A message used to guide customers on the next steps after Klarna is selected as the payment method.',
    defaultMessage: 'Click the Complete with Klarna button below to continue.',
  },
  googlePayGuidance: {
    id: 'checkout_payment_method_google_pay_guidance',
    description: 'A message used to guide customers on the next steps after Google Pay is selected as the payment method.',
    defaultMessage: 'Click the Pay with Google button below to continue',
  },
  applePayGuidance: {
    id: 'checkout_payment_method_apple_pay_guidance',
    description: 'A message used to guide customers on the next steps after Apple Pay is selected as the payment method.',
    defaultMessage: 'Click the Apple Pay button below to continue. Complete the purchase in the next window.',
  },
  applePayNotSupported: {
    id: 'checkout_payment_method_apple_pay_not_supported',
    description: 'A message used to inform the user that their browser is detected as not supporting Apple Pay.',
    defaultMessage: 'Apple Pay is not supported on your browser. Please choose another payment method to proceed.',
  },
});

export function getPaymentMethod(paymentMethodRule) {
  return get(paymentMethodRule, 'payment_method');
}

export function getPaymentMethodId(paymentMethodRule) {
  return get(paymentMethodRule, 'payment_method.id');
}

export function getPaymentMethodType(paymentMethodRule) {
  return get(paymentMethodRule, 'payment_method.type');
}

export const isPaymentMethodId = curry((paymentMethodId, paymentMethodRule) => (
  paymentMethodId === getPaymentMethodId(paymentMethodRule)
));

export const isPaymentMethodType = curry((paymentMethodType, paymentMethodRule) => (
  paymentMethodType === getPaymentMethodType(paymentMethodRule)
));

export function getCardPaymentMethodRules(paymentMethodRules) {
  return filter(paymentMethodRules, isPaymentMethodType('card'));
}

export function getOnlinePaymentMethodRules(paymentMethodRules) {
  return filter(paymentMethodRules, isPaymentMethodType('online'));
}

export function getPaymentMethodName(paymentMethodRule) {
  return get(paymentMethodRule, 'payment_method.name');
}

export function isPayPal(paymentMethodRule) {
  return getPaymentMethodId(paymentMethodRule) === PaymentTypes.PayPal;
}

export function isKlarna(paymentMethodRule) {
  return getPaymentMethodId(paymentMethodRule) === PaymentTypes.Klarna;
}

export function isGooglePay(paymentMethodRule) {
  return getPaymentMethodId(paymentMethodRule) === PaymentTypes.GooglePay;
}

export function isApplePay(paymentMethodRule) {
  return getPaymentMethodId(paymentMethodRule) === PaymentTypes.ApplePay;
}

export function getPaymentMethodIssuers(paymentMethodRule) {
  return get(paymentMethodRule, 'issuers', []);
}

export function getDefaultPaymentMethodDescription(
  paymentMethodRule,
  formatMessage,
  paypalExpressReady,
) {
  const {
    klarnaGuidance,
    paypalGuidance,
    paypalExpressGuidance,
    paymentMethodGuidance,
    applePayGuidance,
    googlePayGuidance,
  } = messages;
  switch (true) {
    case isPayPal(paymentMethodRule):
      return formatMessage(paypalExpressReady ? paypalExpressGuidance : paypalGuidance, {
        paymentMethod: getPaymentMethodName(paymentMethodRule),
      });
    case isKlarna(paymentMethodRule): return formatMessage(klarnaGuidance, {
      paymentMethod: getPaymentMethodName(paymentMethodRule),
    });
    case isGooglePay(paymentMethodRule): return formatMessage(googlePayGuidance, {
      paymentMethod: getPaymentMethodName(paymentMethodRule),
    });
    case isApplePay(paymentMethodRule): return formatMessage(applePayGuidance, {
      paymentMethod: getPaymentMethodName(paymentMethodRule),
    });
    default: return formatMessage(paymentMethodGuidance, {
      paymentMethod: getPaymentMethodName(paymentMethodRule),
    });
  }
}

export function getPaymentMethodDescription(paymentMethodRule, formatMessage) {
  const paymentMethodRuleContent = get(paymentMethodRule, 'content');
  const paymentMethodRuleDescription = find(paymentMethodRuleContent, { key: 'description' });
  return get(paymentMethodRuleDescription, 'value', getDefaultPaymentMethodDescription(paymentMethodRule, formatMessage));
}

export function getPaymentMethodLogo(paymentMethodRule, imageFilter = 'original') {
  const paymentMethodId = getPaymentMethodId(paymentMethodRule);
  return `https://flowcdn.io/util/logos/payment-methods/${paymentMethodId}/120/${imageFilter}.png`;
}
