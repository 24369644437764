import Steps from '../constants/steps';

const stepValue = {
  [Steps.None]: 0,
  [Steps.Cart]: 1,
  [Steps.ContactInfo]: 2,
  [Steps.ShippingMethod]: 3,
  [Steps.PaymentInfo]: 4,
  [Steps.Review]: 5,
  [Steps.Confirmation]: 6,
};

/**
 * Returns true if the active step is before or at the compareStep
 *
 * @param {Steps} activeStep The current active step of checkout
 * @param {Steps} compareStep The step being compared against
 */
export function isStepCompleted(activeStep, compareStep) {
  return stepValue[activeStep] >= stepValue[compareStep];
}

export function isStepActive(activeStep, compareStep) {
  return stepValue[activeStep] === stepValue[compareStep];
}
