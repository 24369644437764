import { browserHistory } from 'react-router';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

/**
 * Edits an the url and replaces order number
 * @param {string} oldOrderNumber
 * @param {string} newOrderNumber
 */

export default function updateOrderNumberInUrl(oldOrderNumber, newOrderNumber) {
  const currentLocationUrl = browserHistory.getCurrentLocation().pathname;
  const newLocationUrl = currentLocationUrl.replace(oldOrderNumber, newOrderNumber);
  window.history.replaceState(null, '', newLocationUrl);
  getRollbar((rollbar, extra = {}) => {
    rollbar.info(`Old order number: ${oldOrderNumber} being replaced with ${newOrderNumber}`,
      { ...extra });
  });
}
