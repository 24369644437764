import PropTypes from 'prop-types';

import ContentElementType from '../../constants/content-element-type';

const localizedContentElementPropTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    ContentElementType.MARKDOWN,
    ContentElementType.HREF,
    ContentElementType.HTML,
    ContentElementType.PLAIN_TEXT,
  ]).isRequired,
};

export default localizedContentElementPropTypes;
