/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import React from 'react';
import {
  IndexRedirect,
  Router,
  Route,
  applyRouterMiddleware,
} from 'react-router';
import { useFetch } from '@flowio/redux-fetch';
import { useScroll } from 'react-router-scroll';

import Application from './containers/application';
import Bridge from './components/bridge';
import Cart from './containers/cart';
import CheckoutToken from './containers/checkout-token';
import CheckoutLayout from './containers/checkout-layout';
import CreateOrderRedirect from './containers/create-order-redirect';
import CustomerInformationStep from './containers/customer-information-step';
import CheckoutCustomerInformationStep from './containers/checkout-customer-information-step';
import CheckoutCustomerPaymentInfo from './containers/checkout-payment-info-step';
import CheckoutShippingMethodStep from './containers/checkout-shipping-method-step';
import CheckoutOrderConfirmationStep from './components/order-confirmation-step/containers/checkout-order-confirmation-step';
import CheckoutsOrderReview from './containers/checkout-order-review-step';
import EnterCheckout from './containers/enter-checkout';
import GenericOrderRedirect from './containers/generic-order-redirect';
import CreateCheckoutOrderRedirect from './containers/create-checkout-order-redirect';
import NotFound from './components/not-found';
import OrderConfirmationStep from './components/order-confirmation-step';
import CheckoutV2ConfirmationStep from './components/confirmation-page-v2';
import PaymentInfoStep from './containers/payment-info-step';
import ProcessOnlineAuthorization from './containers/process-online-authorization';
import CheckoutsProcessOnlineAuthorization from './containers/checkouts-process-online-authorization';
import ShippingMethodStep from './containers/shipping-method-step';
import InternalServerError from './containers/internal-server-error';
import OrderReviewStep from './containers/order-review-step';
import CheckoutTokenNext from './containers/checkout-token-next';

const Root = ({
  apiClient,
  apiInternalClient,
  history,
  rollbar,
  store,
}) => {
  const enhancer = applyRouterMiddleware(
    useScroll(),
    useFetch({
      forceInitialFetch: true,
      renderFailure: (error) => (<InternalServerError error={error} />),
    }),
  );

  return (
    <Bridge
      apiClient={apiClient}
      apiInternalClient={apiInternalClient}
      rollbar={rollbar}
      store={store}
    >
      <Router history={history} render={enhancer}>
        <Route path="/" component={Application}>
          <Route path="tokens/:token" component={CheckoutToken} />
          <Route path="tokens/:token/next" component={CheckoutTokenNext} />
          <Route path=":organization/cart/:cartId/order" component={CreateOrderRedirect} />
          <Route path="initialize" component={EnterCheckout} />
          <Route path="v2/checkouts/:checkoutV2Id/confirmation" component={CheckoutV2ConfirmationStep} />
          <Route path="checkouts/create" component={CreateCheckoutOrderRedirect} />
          <Route path="checkouts/:checkoutId/callbacks/authorization" component={CheckoutsProcessOnlineAuthorization} />
          <Route path="checkouts/:checkoutId" component={CheckoutLayout}>
            <IndexRedirect to="contact-info" />
            <Route path="contact-info" component={CheckoutCustomerInformationStep} />
            <Route path="shipping-method" component={CheckoutShippingMethodStep} />
            <Route path="payment-info" component={CheckoutCustomerPaymentInfo} />
            <Route path="order-review" component={CheckoutsOrderReview} />
            <Route path="confirmation" component={CheckoutOrderConfirmationStep} />
          </Route>
          <Route path=":organization/cart/:cartId" component={Cart} />
          <Route path=":organization/checkout/:orderNumber" component={CheckoutLayout}>
            <IndexRedirect to="contact-info" />
            <Route path="contact-info" component={CustomerInformationStep} />
            <Route path="shipping-method" component={ShippingMethodStep} />
            <Route path="payment-info" component={PaymentInfoStep} />
            <Route path="review" component={OrderReviewStep} />
            <Route path="confirmation" component={OrderConfirmationStep} />
          </Route>
          <Route path=":organization/checkout/:orderNumber/callbacks/authorization" component={ProcessOnlineAuthorization} />
          <Route path=":organization/order" component={GenericOrderRedirect} />
          <Route path=":organization/error-pages/404" component={NotFound} />
          <Route path=":organization/error-pages/500" component={InternalServerError} />
          <Route path="*" component={NotFound} />
        </Route>
      </Router>
    </Bridge>
  );
};

Root.displayName = 'Root';

Root.propTypes = {
  apiClient: PropTypes.object.isRequired,
  apiInternalClient: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  rollbar: PropTypes.object.isRequired,
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

export default Root;
