import {
  AuthorizationsResource,
  CheckoutBundlesResource,
  CheckoutContentSummariesResource,
  CheckoutFinalizeOrderFormsResource,
  CheckoutSubmitOrderBundlesResource,
  DiscountCodesResource,
  FeatureValuesResource,
  GiftCardProgramsResource,
  LoyaltyProgramsResource,
  OptinPromptsResource,
  OrdersResource,
  ShopifyOrderDetailsResource,
  V1CheckoutsResource,
} from '@flowio/api-internal-sdk';

import type {
  $HttpClientOptions,
} from '@flowio/api-internal-sdk';

export interface ApiInternalClientV2 {
  authorizations: AuthorizationsResource;
  checkoutBundle: CheckoutBundlesResource;
  checkoutContentSummaries: CheckoutContentSummariesResource;
  checkoutFinalizeOrderForms: CheckoutFinalizeOrderFormsResource;
  checkoutSubmitOrderBundles: CheckoutSubmitOrderBundlesResource;
  discountCodes: DiscountCodesResource;
  featureValues: FeatureValuesResource;
  giftCardPrograms: GiftCardProgramsResource;
  loyaltyPrograms: LoyaltyProgramsResource;
  optinPrompts: OptinPromptsResource;
  orders: OrdersResource;
  shopifyOrderDetails: ShopifyOrderDetailsResource;
  v1Checkouts: V1CheckoutsResource;
}

// eslint-disable-next-line import/prefer-default-export
export function createApiInternalClient(
  options: $HttpClientOptions,
): ApiInternalClientV2 {
  return {
    authorizations: new AuthorizationsResource(options),
    checkoutBundle: new CheckoutBundlesResource(options),
    checkoutContentSummaries: new CheckoutContentSummariesResource(options),
    checkoutFinalizeOrderForms: new CheckoutFinalizeOrderFormsResource(options),
    checkoutSubmitOrderBundles: new CheckoutSubmitOrderBundlesResource(options),
    discountCodes: new DiscountCodesResource(options),
    featureValues: new FeatureValuesResource(options),
    giftCardPrograms: new GiftCardProgramsResource(options),
    loyaltyPrograms: new LoyaltyProgramsResource(options),
    optinPrompts: new OptinPromptsResource(options),
    orders: new OrdersResource(options),
    shopifyOrderDetails: new ShopifyOrderDetailsResource(options),
    v1Checkouts: new V1CheckoutsResource(options),
  };
}
