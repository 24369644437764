import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

const updateInvoiceAddressOrderRequest = (
  organizationId: string,
  orderNumber: string,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_REQUEST,
  meta: {
    organizationId,
    orderNumber,
  },
});

const updateInvoiceAddressOrderSuccess = (
  organizationId: string,
  orderNumber: string,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_SUCCESS,
  payload: orderBuilder,
  meta: {
    organizationId,
    orderNumber,
  },
});

const updateInvoiceAddressOrderFailure = (
  organizationId: string,
  orderNumber: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_ORDER_FAILURE,
  payload: error,
  meta: {
    organizationId,
    orderNumber,
  },
});

/**
 * An action responsible for updating the order with the provided invoice
 * address. We specifically use this endpoint for order submission since it
 * will do a full address validation.
 */
const updateInvoiceAddressByOrderPut = (
  organizationId: string,
  orderNumber: string,
  invoiceAddress: io.flow.v0.models.BillingAddress,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const order = getOrderDenormalized(getState());
  const orderPutForm = orderPutFormConverter.fromOrder(order);
  dispatch(updateInvoiceAddressOrderRequest(organizationId, orderNumber));
  return extra.api.orderBuilders.putByNumber({
    body: {
      ...orderPutForm,
      customer: {
        ...orderPutForm.customer,
        invoice: {
          ...orderPutForm.customer?.address,
          address: invoiceAddress,
        },
      },
    },
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateInvoiceAddressOrderSuccess(organizationId, orderNumber, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateInvoiceAddressOrderFailure(organizationId, orderNumber, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateInvoiceAddressByOrderPut;
