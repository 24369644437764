import type { OrderBuildersPutInvoiceAndAddressByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<OrderBuildersPutInvoiceAndAddressByNumberResponse>
>;

const updateInvoiceAddressRequest = (
  organizationId: string,
  orderNumber: string,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_REQUEST,
  meta: {
    organizationId,
    orderNumber,
  },
});

const updateInvoiceAddressSuccess = (
  organizationId: string,
  orderNumber: string,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_SUCCESS,
  payload: orderBuilder,
  meta: {
    organizationId,
    orderNumber,
  },
});

const updateInvoiceAddressFailure = (
  organizationId: string,
  orderNumber: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_INVOICE_ADDRESS_FAILURE,
  payload: error,
  meta: {
    organizationId,
    orderNumber,
  },
});

/**
 * An action responsible for updating the order with the provided invoice address.
 * We use this for switching the country in invoice address, it does not do a full
 * address validation
 */
const updateInvoiceAddress = (
  organizationId: string,
  orderNumber: string,
  invoiceAddress: io.flow.v0.models.BillingAddress,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(updateInvoiceAddressRequest(organizationId, orderNumber));
  return extra.api.orderBuilders.putInvoiceAndAddressByNumber({
    body: {
      address: invoiceAddress,
    },
    expand: ['experience'],
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(updateInvoiceAddressSuccess(organizationId, orderNumber, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateInvoiceAddressFailure(organizationId, orderNumber, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateInvoiceAddress;
