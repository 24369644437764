import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCheckoutBundle } from '../../../store/checkout/actions';
import { fetchCountries } from '../../../store/reference/actions';
import { getCountries } from '../../../store/reference/selectors';
import { getOrganization, getOrganizationUrl } from '../../../store/flow/selectors';
import { getCheckoutContactUs } from '../../../store/content/selectors';
import {
  allowInvoiceAddress,
  getAuthorizationsList,
  getContinueShoppingUrl,
  getIsDeliveredDutyCustomerChoice,
  getIsSFExpressOrder,
  getOrderBillingAddress,
  getOrderDeliveredDuty,
  getOrder,
  getOrderDestination,
  getOrderLines,
  getSelectedDeliveryOptions,
  getOrderNumber,
  getOrderPayments,
  getVrnFromRegistration,
  getInvoiceAddress,
  getPlatformOrderNumber,
  getIsBlazeCheckout,
} from '../../../store/checkout/selectors';
import checkHttpStatus from '../../../utilities/check-http-status';
import OrderConfirmationStep from '../components/order-confirmation-step';
import withExternalEvents from '../../with-external-events';
import generateFeatureQuery from '../../../utilities/generate-feature-query';
import logInternalServerError from '../../../utilities/rollbar/log-internal-server-error';
import { OwnProps, StateProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../store/types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown> => {
  const { params } = props;
  const { organization, orderNumber } = params;

  return Promise.all([
    dispatch(fetchCheckoutBundle(organization, orderNumber, {
      feature_q: generateFeatureQuery(organization),
    })).then(checkHttpStatus),
    dispatch(fetchCountries()),
  ]).catch((error) => {
    logInternalServerError(error);
    throw error;
  });
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  allowInvoiceAddress,
  invoiceAddress: getInvoiceAddress,
  authorizations: getAuthorizationsList,
  billingAddress: getOrderBillingAddress,
  countries: getCountries,
  contactUs: getCheckoutContactUs,
  continueShoppingUrl: getContinueShoppingUrl,
  deliveryOptions: getSelectedDeliveryOptions,
  deliveredDuty: getOrderDeliveredDuty,
  destination: getOrderDestination,
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice,
  order: getOrder,
  orderNumber: getOrderNumber,
  orderPayments: getOrderPayments,
  organization: getOrganization,
  organizationUrl: getOrganizationUrl,
  platformOrderNumber: getPlatformOrderNumber,
  vrn: getVrnFromRegistration,
});

export default compose<React.FC<OwnProps>>(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(OrderConfirmationStep);
