import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function setGiftCardContentState(
  isOpen: boolean,
): RootAction {
  return {
    type: actionTypes.SET_GIFT_CARD_CONTENT_STATE,
    payload: isOpen,
  };
}
