import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import TriggerOnceEvents from '@flowio/events/lib/trigger-once-events';
import PageViews from '../constants/pageviews';
import ExternalEvents from '../external/constants/external-events';

/**
 * These can only fire once per page view, but can fire again if the user goes
 * back and forth between the steps of checkout
 */
const pageViewEvents = [
  PageViews.ALL,
  PageViews.CART,
  PageViews.CONTACT_INFO,
  PageViews.SHIPPING_METHOD,
  PageViews.PAYMENT_INFO,
  PageViews.CONFIRMATION,
];

// These can only fire ONCE per checkout
const checkoutExternalEvents = [
  ExternalEvents.INITIALIZED,
  ExternalEvents.TRANSACTION,
];

/**
 * Events that are only triggered once by the page. Used to auto execute
 * callbacks for events in this list
 * @type {Array}
 */
const triggerOnceEvents = [
  ...pageViewEvents,
  ...checkoutExternalEvents,
];

const checkoutEvents = new TriggerOnceEvents({ triggerOnceEvents });

checkoutEvents.onError((error) => {
  if (error instanceof Error) {
    getRollbar((rollbar, extra = {}) => {
      rollbar.warn('Error emitted from checkout events', error, { ...extra });
    });
  }
});

export function resetPageViewEvents() {
  checkoutEvents.resetPreviouslyTriggered(pageViewEvents);
}

export default checkoutEvents;
