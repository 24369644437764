import get from 'lodash/get';
import set from 'lodash/set';
import { getAuthorizationStatus } from '../../../utilities/authorization-utilities';
import checkHttpStatus from '../../../utilities/check-http-status';
import AuthorizationStatus from '../../../constants/authorization-status';
import fetchAuthorization from './fetch-authorization';
import ExponentialBackoff from '../../../utilities/exponential-backoff';
import { is3DSPendingPageEnabled } from '../selectors';

const pollOnStatus = AuthorizationStatus.PENDING;
const backoff = new ExponentialBackoff({ maxDuration: 120000 });

// HACK TO SIMULATE PRODUCTION BEHAVIOR
function hackSimulateProduction(authorization) {
  const delayMs = 20000;
  const createdAt = new Date(authorization.created_at);
  const transitionAtTime = createdAt.getTime() + delayMs;

  if (Date.now() < transitionAtTime) {
    set(authorization, 'result.action.type', 'wait');
    set(authorization, 'result.status', 'pending');
  }
}

function pollUntilStatusChange(dispatch, organizationId, authorizationKey, callback) {
  dispatch(fetchAuthorization(organizationId, authorizationKey))
    .then(checkHttpStatus)
    .then((response) => {
      const { result: authorization } = response;

      // DEVELOPMENT ONLY: Simulates the transition from pending -> (review|declined)
      if (process.env.NODE_ENV === 'development') {
        hackSimulateProduction(authorization);
      }

      const status = getAuthorizationStatus(authorization);
      const timeoutDelay = backoff.next();

      if (timeoutDelay && status === pollOnStatus) {
        setTimeout(() => {
          pollUntilStatusChange(dispatch, organizationId, authorizationKey, callback);
        }, timeoutDelay);
      } else {
        callback(authorization);
      }
    });
}

export default function pollResultActionPendingAuthorization(
  organizationId,
  originalAuthorizationResponse,
) {
  return function pollResultActionPendingAuthorizationEffects(dispatch, getState) {
    return new Promise((resolve) => {
      if (!is3DSPendingPageEnabled(getState())) {
        resolve(originalAuthorizationResponse);
        return;
      }

      const { result: authorization } = originalAuthorizationResponse;

      // DEVELOPMENT ONLY: Simulates the transition from pending -> (review|declined)
      if (process.env.NODE_ENV === 'development') {
        hackSimulateProduction(authorization);
      }

      const status = getAuthorizationStatus(authorization);
      const resultActionType = get(authorization, 'result.action.type');

      backoff.reset();

      // TODO make result action an enum / object
      if (status === pollOnStatus && resultActionType === 'wait') {
        pollUntilStatusChange(dispatch, organizationId, authorization.key, () => (
          dispatch(fetchAuthorization(organizationId, authorization.key))
            .then((response) => resolve(response))
        ));
      } else {
        resolve(originalAuthorizationResponse);
      }
    });
  };
}
