import type { GiftCardProgramsPostResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<GiftCardProgramsPostResponse>>;

const addGiftCardRequest = (): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_REQUEST,
});

const addGiftCardSuccess = (
  giftCardProgram: io.flow.internal.v0.models.GiftCardProgram,
): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_SUCCESS,
  payload: giftCardProgram,
});

const addGiftCardFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_FAILURE,
  payload: error,
});

/**
 * Creates an action responsible for creating a gift card program.
 */
const addGiftCard = (
  organizationId: string,
  orderNumber: string,
  giftCardForm: io.flow.internal.v0.models.GiftCardForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(addGiftCardRequest());
  return extra.apiInternal.giftCardPrograms.post({
    body: giftCardForm,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(addGiftCardSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(addGiftCardFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default addGiftCard;
