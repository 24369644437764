import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CartStateErrors } from '../types';

const initialState: CartStateErrors = {};

const errorsReducer: AppReducer<CartStateErrors> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CART_REQUEST:
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_REQUEST:
    case actionTypes.REMOVE_CART_ITEM_REQUEST:
      return update(previousState, { cart: { $set: undefined } });
    case actionTypes.FETCH_CART_FAILURE:
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_FAILURE:
    case actionTypes.REMOVE_CART_ITEM_FAILURE:
      return update(previousState, { cart: { $set: action.payload } });
    default:
      return previousState;
  }
};

export default errorsReducer;
