/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

import Collapse from '../collapse';

const bem = new BemHelper('panel');

class PanelContent extends Component {
  getAriaProps() {
    const { collapsible, expanded } = this.props;
    const props = {};

    if (collapsible) {
      props.role = 'tabpanel';
      props['aria-hidden'] = !expanded;
    }

    return props;
  }

  render() {
    const {
      children,
      className,
      collapsible,
      content,
      expanded,
      unmountOnExit,
    } = this.props;

    const ElementType = getElementType(PanelContent, this.props);
    const ariaProps = this.getAriaProps();
    const unhandledProps = getUnhandledProps(PanelContent, this.props);

    if (!collapsible) {
      return (
        <ElementType
          {...ariaProps}
          {...unhandledProps}
          className={bem.element('content', className)}
        >
          {children || content}
        </ElementType>
      );
    }

    return (
      <Collapse in={expanded} unmountOnExit={unmountOnExit}>
        <ElementType
          {...ariaProps}
          {...unhandledProps}
          className={bem.element('content', className)}
        >
          {children || content}
        </ElementType>
      </Collapse>
    );
  }
}

PanelContent.displayName = 'PanelContent';

PanelContent.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  content: PropTypes.string,
  expanded: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
};

PanelContent.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  collapsible: false,
  content: undefined,
  expanded: false,
  unmountOnExit: false,
};

export default PanelContent;
