import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withFetch } from '@flowio/redux-fetch';
import {
  fetchCheckout,
  syncReturnUrlAttribute,
  skipCheckoutSteps,
  isDiscountsEnabled,
  isForceEnableShopifyDiscountsEnabled,
  fetchAddressConfiguration,
  fetchPriceConversion,
} from '../store/checkout';
import CustomerInformationStep from '../components/customer-information-step';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import checkHttpStatus from '../utilities/check-http-status';
import withExternalEvents from '../components/with-external-events';
import getFeatureKeys from '../utilities/get-feature-keys';
import { getCustomerAddressBookContacts, getOrderDestinationCountryCode } from '../store/checkout/selectors';
import getPreferredUserAddress from '../utilities/get-preferred-user-address';
import { fetchOptinPrompts } from '../store/optin';
import PromptTarget from '../constants/prompt-target';
import { getLocale } from '../store/intl/selectors';
import { fetchAdministrativeDivisions } from '../store/reference/actions';
import { getIsShopifyIntegration, getOrganization } from '../store/flow';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { checkoutId } = params;
  const { organization } = getState().flow;

  return dispatch(fetchCheckout(checkoutId, { feature_key: getFeatureKeys() }))
    .then((response) => response && checkHttpStatus(response))
    .then(() => {
      const organizationId = getOrganization(getState());
      const addressBooks = getCustomerAddressBookContacts(getState());
      const orderCountry = getOrderDestinationCountryCode(getState());
      const shippingAddressBook = getPreferredUserAddress(addressBooks, 'shipping')[0];
      const shippingAddressCountry = get(shippingAddressBook, 'address.country');

      // Generally, checkout models include country specific data based on
      // customer geolocation. By comparing whether the order destination and
      // the preferred address book contact country code match, we can determine
      // whether the checkout model includes the initial country specific data
      // needed to render the returning customer view correctly.
      if (shippingAddressCountry && orderCountry !== shippingAddressCountry) {
        return Promise.all([
          dispatch(fetchAddressConfiguration(organizationId, shippingAddressCountry)),
          dispatch(fetchAdministrativeDivisions({
            countries: [shippingAddressCountry],
          })),
          dispatch(fetchOptinPrompts({
            organizationId: organization,
            updatedRegion: shippingAddressCountry,
            promptTarget: PromptTarget.CHECKOUT,
            locale: getLocale(getState()),
          })),
        ]);
      }

      return Promise.resolve();
    })
    .then(() => dispatch(syncReturnUrlAttribute(organization)))
    .then(() => dispatch(skipCheckoutSteps()))
    .then(() => dispatch(fetchPriceConversion()))
    .catch((error) => {
      logInternalServerError(error);
      throw error;
    });
};

const mapStateToProps = (state) => ({
  discountsEnabled: isDiscountsEnabled(state),
  forceEnableShopifyDiscounts: isForceEnableShopifyDiscountsEnabled(state),
  shopifyIntegration: getIsShopifyIntegration(state),
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(CustomerInformationStep);
