/**
 * @fileoverview
 * A route container intended to be used to finalize submission of an order being paid with a
 * supported online payment method.
 */

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { createStructuredSelector } from 'reselect';

import { fetchCheckoutBundle, fetchAuthorization, redirectSubmittedOrder } from '../store/checkout/actions';
import { is3DSPendingPageEnabled, getAuthorizations } from '../store/checkout/selectors';
import PendingPage from '../components/pending-page';
import checkHttpStatus from '../utilities/check-http-status';
import generateFeatureQuery from '../utilities/generate-feature-query';
import processOnlineAuthorization from '../store/checkout/actions/process-online-authorization';

function fetchAsyncState(dispatch, getState, props) {
  const { organization, orderNumber } = props.params;
  const { authorization_key: authorizationKey } = props.location.query;

  return Promise.all([
    dispatch(fetchAuthorization(organization, authorizationKey)),
    dispatch(fetchCheckoutBundle(organization, orderNumber, {
      feature_q: generateFeatureQuery(organization),
    })).then(checkHttpStatus),
  ]).then(() => (
    dispatch(redirectSubmittedOrder())
  ));
}

const mapDispatchToProps = (dispatch, props) => {
  const { location, params } = props;
  const { organization, orderNumber } = params;
  const { authorization_key: authorizationKey } = location.query;
  return {
    onMount() {
      dispatch(processOnlineAuthorization(organization, orderNumber, authorizationKey));
    },
  };
};

const mapStateToProps = createStructuredSelector({
  is3DSPendingPageEnabled,
  authorizations: getAuthorizations,
});

export default compose(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PendingPage);
