import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import { AppReducer } from '../../types';
import { CheckoutParametersState } from '../types';

const initialState: CheckoutParametersState = {};

const paramsReducer: AppReducer<CheckoutParametersState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_REQUEST:
      return update(state, {
        order: { $set: action.params },
      });
    default:
      return state;
  }
};

export default paramsReducer;
