import cookies from 'cookies-js';
import debugInit from 'debug';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

import { BEARER_COOKIE } from '../../../common/constants/cookie';
import { getSessionId } from '../session';
import randomString from '../../../common/utilities/random-string';

const debug = debugInit('checkout:client');

/**
 * Proxy the api to add wrapper function to handle application state.
 * Uses state to set auth and request-id headers.
 *
 * Changes syntax of api from
 *
 * `api.experiences.get()`
 *
 * to
 *
 * `api.experiences(sessionId).get`
 *
 * It's assumed that `sessionId` is obtained from the Flow Session stored in
 * the `_f60_session` cookie. This is set by flow.js (shopify).
 * (everything else).
 */
export default class ApiProxy {
  constructor(client) {
    this.client = client;

    Object.keys(client).forEach((resource) => {
      this[resource] = () => {
        const jwt = cookies.get(BEARER_COOKIE);
        const sessionId = getSessionId();
        const resourceRef = client[resource];
        const headers = {
          'X-Flow-Request-Id': `chk${randomString(20)}`,
        };
        if (jwt != null) {
          headers.Authorization = `Bearer ${jwt}`;
        } else if (sessionId != null) {
          headers.Authorization = `Session ${sessionId}`;
        } else {
          // Log when a request is made without authentication headers.
          // Good indication that something went awry.
          getRollbar((rollbar, extra = {}) => {
            rollbar.warning('Missing authentication for Request', { ...extra, resource });
          });
        }

        return resourceRef.withHeaders(headers);
      };
    });

    Object.keys(client)
      .filter((k) => !!client[k].on)
      .forEach((resource) => {
        client[resource].on('request', (req) => {
          try {
            const method = req.method || 'GET';
            let msg = `[request] ${method} ${req.url} \n Request Id: ${req.requestId}`;
            if (req.params) {
              msg += `\n Params: ${JSON.stringify(req.params, null, 2)}`;
            }
            if (req.headers && Object.keys(req.headers).length > 0) {
              msg += `\n Headers: ${JSON.stringify(req.headers, null, 2)}`;
            }
            debug(msg);
          } catch (error) {
            debug(error.message);
          }
        });
        client[resource].on('response', (res) => {
          try {
            let msg = `[response] Request Id: ${res.requestId}, status[${res.status}]`;
            if (res.body) {
              msg += `\n Body: ${JSON.stringify(JSON.parse(res.body), null, 2)}`;
            }
            debug(msg);
          } catch (error) {
            debug(error.message);
          }
        });
      });
  }
}
