import get from 'lodash/get';
import session from '@flowio/session';

export function getSession() {
  return session.getSession();
}

export function getSessionId() {
  return session.getSessionId();
}

export function getEnvironment() {
  return session.getEnvironment();
}

export function getVisitorId() {
  return session.getVisitorId();
}

export function getSessionAttribute(attributeName) {
  const attributes = session.getAttributes();
  return get(attributes, attributeName);
}

export function getCartId() {
  return getSessionAttribute('cart_id');
}
