import { ThunkAction } from 'redux-thunk';
import createSession from '@flowio/session/lib/create-session';
import persistSession from '@flowio/session/lib/persist-session';
import { CheckoutErrorCode } from '@flowio/api-internal-constants';
import isError from 'lodash/isError';
import get from 'lodash/get';
import { Dispatch } from 'redux';

import { apiInternal } from '../../../utilities/clients';
import { Response } from '../../../../common/services/types';
import actionTypes from '../../constants/action-types';
import { getIsCheckoutPreloaded } from '../selectors';
import { RootState, RootAction, ThunkExtraArgument } from '../../types';
import checkHttpStatus from '../../../utilities/check-http-status';
import formatErrorResponse from '../../../utilities/format-error-response';
import fetchOrganizationConfig from './fetch-organization-config';
import { getOrganization, getIntegration } from '../../flow/selectors';
import { getPathPrefix, getShopifyDomain } from '../../shopify/selectors';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';

type ReturnType = Promise<void>

interface SessionOptions {
  sessionType: 'shopify' | 'organization';
  shop?: string;
  organization: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: any) => void;
}

function getCheckoutV2Id(checkoutId: string): string | undefined {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const checkoutV2Match = checkoutId?.match(/^v2-(.+)$/i);
  if (checkoutV2Match != null) {
    return checkoutV2Match[1];
  }
  return undefined;
}

function createAndPersistSession(
  organization: string,
  state: RootState,
): Promise<io.flow.v0.unions.Session> {
  return new Promise((resolve, reject) => {
    const sessionOptions: SessionOptions = {
      sessionType: getIntegration(state) === 'shopify' ? 'shopify' : 'organization',
      organization,
      onError: (error) => reject(error),
    };
    if (sessionOptions.sessionType === 'shopify') {
      sessionOptions.shop = getShopifyDomain(state);
    }

    createSession(sessionOptions, (_status: number, response: io.flow.v0.unions.Session) => {
      persistSession(response);
      resolve(response);
    });
  });
}

/**
 * Creates an asynchronous action responsible for reading the checkouts
 * resource corresponding the specified parameters.
 * @param {string} id
 */
function fetchCheckout(
  id: string,
  params: object = {},
): ThunkAction<ReturnType, RootState, ThunkExtraArgument, RootAction> {
  return function fetchCheckoutEffects(
    dispatch: Dispatch,
    getState: (() => RootState),
    extra: ThunkExtraArgument,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const isCheckoutPreloaded = getIsCheckoutPreloaded(getState());
      if (isCheckoutPreloaded) {
        dispatch({ type: actionTypes.PRELOADED_CHECKOUT_CONSUMED });
        resolve();
        return;
      }

      dispatch({ type: actionTypes.FETCH_CHECKOUT_REQUEST });

      const checkoutV2Id = getCheckoutV2Id(id);
      let fetchCheckoutPromise: Promise<Response<io.flow.internal.v0.models.V1Checkout>>;

      if (checkoutV2Id != null) {
        fetchCheckoutPromise = extra.api.v1Checkouts.getV1ByV2CheckoutId({
          v2_checkout_id: checkoutV2Id,
        })
          .then(legacyResponseConverter.withLegacyError)
          .then((response) => {
            if (response.ok) return response;
            throw new Error('Failed to fetch Checkout from V2 Checkout ID');
          });
      } else {
        fetchCheckoutPromise = apiInternal
          .checkouts()
          .getById(id, { params })
          .then(checkHttpStatus);
      }

      fetchCheckoutPromise
        .catch((
          error: any,
        ): Promise<Response<io.flow.internal.v0.models.V1Checkout>> => {
          const errorCode = get(error, ['response', 'result', 'code']);
          const organization = get(error, ['response', 'result', 'organization', 'id']);

          if ([
            CheckoutErrorCode.SESSION_INVALID,
            CheckoutErrorCode.SESSION_MISSING,
            CheckoutErrorCode.SESSION_ORGANIZATION_INVALID,
          ].includes(errorCode)) {
            if (organization != null) {
              // Create a new session for the organization provided by the error
              return createAndPersistSession(organization, getState())
                .then(() => apiInternal.checkouts().getById(id, { params }))
                .then(checkHttpStatus)
                .catch(() => {
                  throw new Error('Failed to create session and retrieve checkout.');
                });
            }
          }

          throw error;
        })
        .then((response: Response<io.flow.internal.v0.models.V1Checkout>) => dispatch({
          type: actionTypes.FETCH_CHECKOUT_SUCCESS,
          payload: response.result,
        }))
        .then(() => {
          const organization = getOrganization(getState());
          const pathPrefix = getPathPrefix(getState());
          return dispatch(fetchOrganizationConfig(organization, pathPrefix));
        })
        .then(() => resolve())
        .catch((error: any): void => {
          dispatch({
            type: actionTypes.FETCH_CHECKOUT_FAILURE,
          });

          if (!error.response && isError(error)) {
            return reject(error);
          }

          return reject(formatErrorResponse(error, { messages: get(error, 'response.messages', {}) }));
        });
    });
  };
}

export default fetchCheckout;
