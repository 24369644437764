import BemHelper from '@flowio/bem-helper';
import React from 'react';

import './floating-label-input.css';
import { trackHeapEvent } from '../../utilities/heap';

type Size =
  | 'small'
  | 'medium'
  | 'large';

const defaultProps = {
  defaultValue: '',
  disabled: false,
  invalid: false,
  readOnly: false,
  type: 'text',
  size: 'large' as Size,
};

type OwnProps = {
  children?: never;
  hintText?: string;
  id?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  labelText?: React.ReactNode;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onValueChange?: (value: string) => void;
  value?: string;
} & typeof defaultProps;

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLDivElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

type State = {
  focused: boolean;
  inputValue: string;
};

const bem = new BemHelper('flow-floating-label-input');

class FloatingLabelInput extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    const {
      defaultValue,
      value,
    } = this.props;

    this.state = {
      focused: false,
      inputValue: value != null ? value : defaultValue,
    };

    this.inputRef = React.createRef();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleInputBlur = this.handleInputBlur.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleInputChange = this.handleInputChange.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleInputFocus = this.handleInputFocus.bind(this);
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Props,
  ): void {
    const { value: nextValue } = nextProps;

    if (nextValue != null) {
      this.setState({ inputValue: nextValue });
    }
  }

  handleInputBlur(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { name, onBlur } = this.props;

    trackHeapEvent('field_blur', {
      field: name,
    });

    this.setFocusState(false);

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  handleInputFocus(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { name, onFocus } = this.props;

    trackHeapEvent('field_focus', {
      field: name,
    });

    this.setFocusState(true);

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const { onChange } = this.props;

    this.setValue(event.currentTarget.value);

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  setFocusState(
    focused: boolean,
  ): void {
    this.setState({ focused });
  }

  setValue(
    value: string,
  ): void {
    const { onValueChange } = this.props;

    if (!this.hasControlledValue()) {
      this.setState({ inputValue: value });
    }

    if (typeof onValueChange === 'function') {
      onValueChange(value);
    }
  }

  hasControlledValue(): boolean {
    const { value } = this.props;
    return value != null;
  }

  blur(): void {
    if (this.inputRef.current != null) {
      this.inputRef.current.blur();
    }
  }

  focus(): void {
    if (this.inputRef.current != null) {
      this.inputRef.current.focus();
    }
  }

  select(): void {
    if (this.inputRef.current != null) {
      this.inputRef.current.select();
    }
  }

  render(): React.ReactElement {
    const {
      defaultValue,
      disabled,
      labelText,
      hintText,
      id,
      inputProps,
      invalid,
      name,
      onBlur,
      onChange,
      onFocus,
      onValueChange,
      readOnly,
      size,
      type,
      value,
      ...unhandledProps
    } = this.props;

    const {
      focused,
      inputValue,
    } = this.state;

    const floating = focused || inputValue.length > 0;

    return (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...unhandledProps}
        className={bem.block()}
      >
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          className={bem.element('control', {
            small: size === 'small',
            medium: size === 'medium',
            large: size === 'large',
            floating,
            invalid,
          })}
          disabled={disabled}
          id={id}
          name={name}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          onBlur={this.handleInputBlur}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          onChange={this.handleInputChange}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          onFocus={this.handleInputFocus}
          placeholder={hintText}
          readOnly={readOnly}
          ref={this.inputRef}
          type={type}
          value={inputValue}
        />
        <label
          className={bem.element('label', {
            floating,
          })}
          htmlFor={id}
        >
          {labelText}
        </label>
      </div>
    );
  }
}

export default FloatingLabelInput;
