/* eslint-disable react/prop-types */

import { LocationAction } from 'react-router-redux';
import React, { useEffect } from 'react';

interface Props {
  checkoutId: string | undefined;
  replace: LocationAction;
}

const CheckoutTokenNext: React.FunctionComponent<Props> = ({
  checkoutId,
  replace,
}): React.ReactElement => {
  useEffect((): void => {
    if (checkoutId === undefined) {
      throw new Error('CheckoutTokenNext: Invalid checkoutId prop `undefined`.');
    }
    replace(`/checkouts/${checkoutId}/contact-info`);
  }, []);

  return (<div />);
};

CheckoutTokenNext.displayName = 'CheckoutTokenNext';

export default CheckoutTokenNext;
