import DiscountForm from './discount-form';

function fromLocalizedLineItem(
  localizedLineItem: io.flow.v0.models.LocalizedLineItem,
): io.flow.v0.models.LineItemForm {
  const discountForms: io.flow.v0.models.DiscountForm[] = localizedLineItem.discounts != null
    ? localizedLineItem.discounts.map(DiscountForm.fromLocalizedLineItemDiscount)
    : [];

  const discountsForm: io.flow.v0.models.DiscountsForm = {
    discounts: discountForms,
  };

  return {
    number: localizedLineItem.number,
    quantity: localizedLineItem.quantity,
    attributes: localizedLineItem.attributes,
    center: localizedLineItem.center,
    discount: localizedLineItem.discount,
    discounts: discountsForm,
    price: localizedLineItem.price,
    shipment_estimate: localizedLineItem.shipment_estimate,
  };
}

export default {
  fromLocalizedLineItem,
};
