import ItemContentHelper from '../utilities/item-content-helper';
import Steps from '../constants/steps';
import PageViews from '../constants/pageviews';
import ExternalEvents from './constants/external-events';
import events from '../utilities/events';

export const callbacks = {};

function createSafeEventHandler(callback, functionName) {
  return function safeEventHandler(...args) {
    try {
      if (typeof callback === 'function') {
        callback(...args);
      }
    } catch (error) {
      if (typeof console !== 'undefined') {
        // A trade-off catching errors from custom integrations is that
        // developers won't easily detect when something went wrong.
        // eslint-disable-next-line no-console
        console.error(`Error detected on "${functionName}" handler.`, error);
      }
    }
  };
}

export function onPageView(pageView, callback) {
  events.on(pageView, createSafeEventHandler(callback, 'onPageView'));
}

export function onOrderUpserted(callback) {
  events.on(ExternalEvents.ORDER_UPSERTED, createSafeEventHandler(callback, 'onOrderUpserted'));
}

export function onTransaction(callback) {
  events.on(ExternalEvents.TRANSACTION, createSafeEventHandler(callback, 'onTransaction'));
}

export function onInitialized(callback) {
  events.on(ExternalEvents.INITIALIZED, createSafeEventHandler(callback, 'onInitialized'));
}

export function triggerPageViewByStep(step, data) {
  switch (step) {
    case Steps.Cart:
      events.trigger(PageViews.CART, data);
      break;
    case Steps.Confirmation:
      events.trigger(PageViews.CONFIRMATION, data);
      break;
    case Steps.ContactInfo:
      events.trigger(PageViews.CONTACT_INFO, data);
      break;
    case Steps.PaymentInfo:
      events.trigger(PageViews.PAYMENT_INFO, data);
      break;
    case Steps.ShippingMethod:
      events.trigger(PageViews.SHIPPING_METHOD, data);
      break;
    default:
      break;
  }
}

export function handleStep(client, step) {
  const orderClient = client.getOrder();

  if (orderClient) {
    const data = {
      defaultPayment: client.getDefaultPayment(),
      order: orderClient.order,
      getOrderPrices: () => orderClient.getOrderPrices(),
      content: new ItemContentHelper(client.getItemContents()),
    };

    triggerPageViewByStep(step, data);
    events.trigger(PageViews.ALL, data);
  }
}

export function init(client) {
  events.on(Steps.None, () => { handleStep(client, Steps.None); });
  events.on(Steps.Cart, () => { handleStep(client, Steps.Cart); });
  events.on(Steps.ContactInfo, () => { handleStep(client, Steps.ContactInfo); });
  events.on(Steps.ShippingMethod, () => { handleStep(client, Steps.ShippingMethod); });
  events.on(Steps.PaymentInfo, () => { handleStep(client, Steps.PaymentInfo); });
  events.on(Steps.Confirmation, () => { handleStep(client, Steps.Confirmation); });
}
