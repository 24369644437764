/**
 * @typedef {typeof OrderAttributes} OrderAttributes
 */

const OrderAttributes = {
  RETURN_URL: 'flow_return_url',
  CONTINUE_SHOPPING_URL: 'checkout_continue_shopping_url',
  SHIPPING_ADDRESS_PROMPT_BEHAVIOR: 'flow_checkout_behavior_shipping_address_prompt',
  SHIPPING_METHOD_PROMPT_BEHAVIOR: 'flow_checkout_behavior_shipping_method_prompt',
  CUSTOMER_EMAIL_PROMPT_BEHAVIOR: 'flow_checkout_behavior_customer_email_prompt',
  MEXICO_RFC: 'mexico_rfc',
};

export default OrderAttributes;
