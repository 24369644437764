export function getPreferredContact(
  addressBook: io.flow.v0.models.CustomerAddressBook,
  addressType: io.flow.v0.enums.CustomerAddressType,
): io.flow.v0.models.CustomerAddressBookContact | undefined {
  return addressBook.contacts.find(
    (contact) => contact.address_preferences.some(
      (addressPreference) => addressPreference.type === addressType,
    ),
  );
}

export function getPreferredShippingContact(
  addressBook: io.flow.v0.models.CustomerAddressBook,
): io.flow.v0.models.CustomerAddressBookContact | undefined {
  return getPreferredContact(addressBook, 'shipping');
}

export function getPreferredBillingContact(
  addressBook: io.flow.v0.models.CustomerAddressBook,
): io.flow.v0.models.CustomerAddressBookContact | undefined {
  return getPreferredContact(addressBook, 'billing');
}

export function getPreferredInvoiceContact(
  addressBook: io.flow.v0.models.CustomerAddressBook,
): io.flow.v0.models.CustomerAddressBookContact | undefined {
  return getPreferredContact(addressBook, 'invoice');
}

export function getContactById(
  addressBook: io.flow.v0.models.CustomerAddressBook,
  id: string,
): io.flow.v0.models.CustomerAddressBookContact | undefined {
  return addressBook.contacts.find((contact) => contact.id === id);
}

export function hasContacts(
  addressBook: io.flow.v0.models.CustomerAddressBook,
): boolean {
  return addressBook.contacts.length > 0;
}
