import type { OrdersPostGiftAndCardsByNumberResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { AddGiftCardV2Error } from '../types';
import type { LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrdersPostGiftAndCardsByNumberResponse>>;

const addGiftCardV2Request = (): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_V2_REQUEST,
});

const addGiftCardV2Success = (
  orderBuilder: io.flow.experience.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_V2_SUCCESS,
  payload: orderBuilder,
});

const addGiftCardV2Failure = (
  error: AddGiftCardV2Error,
): RootAction => ({
  type: actionTypes.ADD_GIFT_CARD_V2_FAILURE,
  payload: error,
});

const addGiftCardV2 = (
  organizationId: string,
  orderNumber: string,
  giftCardForm: io.flow.internal.v0.models.GiftCardForm,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(addGiftCardV2Request());
  return extra.apiInternal.orders.postGiftAndCardsByNumber({
    body: giftCardForm,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(addGiftCardV2Success(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(addGiftCardV2Failure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default addGiftCardV2;
