/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./dialog-body.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('dialog-body');

const DialogBody = ({ children, ...otherProps }) => (
  <div {...otherProps} className={bem.block()}>
    {children}
  </div>
);

DialogBody.displayName = 'DialogBody';

DialogBody.propTypes = {
  children: PropTypes.node,
};

DialogBody.defaultProps = {
  children: undefined,
};

export default DialogBody;
