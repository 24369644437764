import type { CardPaymentSourcesPutByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CardPaymentSourcesPutByIdResponse>>;

const updateCardPaymentSourceRequest = (): RootAction => ({
  type: actionTypes.UPDATE_CARD_PAYMENT_SOURCE_REQUEST,
});

const updateCardPaymentSourceSuccess = (
  cardPaymentSource: io.flow.v0.models.CardPaymentSource,
): RootAction => ({
  type: actionTypes.UPDATE_CARD_PAYMENT_SOURCE_SUCCESS,
  payload: cardPaymentSource,
});

const updateCardPaymentSourceFailure = (
  error: io.flow.v0.models.PaymentError | LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_CARD_PAYMENT_SOURCE_FAILURE,
  payload: error,
});

const updateCardPaymentSource = (
  organizationId: string,
  cardPaymentSourceId: string,
  cardPaymentSourceForm: io.flow.v0.models.CardPaymentSourceForm,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(updateCardPaymentSourceRequest());
  return extra.api.cardPaymentSources.putById({
    body: cardPaymentSourceForm,
    id: cardPaymentSourceId,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(updateCardPaymentSourceSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(updateCardPaymentSourceFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateCardPaymentSource;
