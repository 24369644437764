import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { getOrganization } from '../store/flow/selectors';
import { getContactInfoUrlV2, getPaymentInfoUrlV2 } from '../store/navigation/selectors';
import { updateShippingMethod } from '../store/checkout/actions';
import {
  getDeliveredDutyDisplayType,
  getDeliveredDutySettings,
  getIsOrderLoading,
  getOrderDeliveredDuty,
  getOrderDeliveries,
  getOrderError,
  getOrderSelections,
} from '../store/checkout/selectors';
import ShippingMethodForm from '../components/shipping-method-form';

const mapStateToProps = createStructuredSelector({
  contactInfoUrl: getContactInfoUrlV2,
  deliveredDuty: getOrderDeliveredDuty,
  deliveredDutyDisplayType: getDeliveredDutyDisplayType,
  deliveredDutySetting: getDeliveredDutySettings,
  deliveries: getOrderDeliveries,
  disabled: getIsOrderLoading,
  error: getOrderError,
  organization: getOrganization,
  paymentInfoUrl: getPaymentInfoUrlV2,
  selections: getOrderSelections,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRequestUpdateShippingMethod: updateShippingMethod,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ShippingMethodForm);
