import keyBy from 'lodash/keyBy';
import matches from 'lodash/matches';
import update from 'immutability-helper';
import values from 'lodash/values';
import actionTypes from '../../constants/action-types';
import isNotNil from '../../../utilities/is-not-nil';
import type { AddressBookContactsState } from '../types';
import type { AppReducer } from '../../types';

const initialState: AddressBookContactsState = {};

const removeAddressPreferences = (
  previousState: AddressBookContactsState,
  addressPreferences: io.flow.v0.models.CustomerAddressPreference[],
): io.flow.v0.models.CustomerAddressBookContact[] => (
  values(previousState).filter(isNotNil).map((addressBookContact) => ({
    ...addressBookContact,
    address_preferences: addressBookContact.address_preferences
      .filter((_) => !addressPreferences.some(matches(_))),
  }))
);

const addressBookContactsReducer: AppReducer<AddressBookContactsState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_ADDRESS_BOOK:
      return update(previousState, {
        $set: keyBy(action.payload.contacts, (_) => _.id),
      });
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS: {
      if (action.payload.address_book != null) {
        return update(previousState, {
          $merge: keyBy(action.payload.address_book.contacts, (_) => _.id),
        });
      }

      return previousState;
    }
    case actionTypes.UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_SUCCESS:
      return update(previousState, {
        [action.payload.id]: {
          $set: action.payload,
        },
      });
    case actionTypes.SAVE_CUSTOMER_ADDRESS_SUCCESS:
      return keyBy(
        // Remove address preferences for incoming address book contact from
        // existing address book contacts.
        removeAddressPreferences(
          previousState,
          action.payload.address_preferences,
        // Add incoming address book contact to the index
        ).concat(action.payload),
        (_) => _.id,
      );
    default:
      return previousState;
  }
};

export default addressBookContactsReducer;
