import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';

import Timer from './timer';
import Steps from '../../constants/steps';
import Collapse from '../collapse';

if (process.browser) {
  require('./countdown-timer.css'); // eslint-disable-line
}

const bem = new BemHelper('countdown-timer');

export interface Props {
  timeout: number;
  currentStep: string;
}

const {
  None,
  ContactInfo,
  ShippingMethod,
  PaymentInfo,
} = Steps;

const CountdownTimer: React.FunctionComponent<Props> | false = (props: Props) => {
  const { timeout, currentStep } = props;
  const [showTimer, setShowTimer] = useState(true);

  const displayTimer: boolean = currentStep.includes(ContactInfo)
    || currentStep.includes(ShippingMethod)
    || currentStep.includes(PaymentInfo)
    || currentStep.includes(None);

  const handleTimeout = useCallback(() => {
    setShowTimer(false);
  }, [showTimer]);

  return (
    displayTimer ? (
      <Collapse in={showTimer}>
        <div className={bem.block()}>
          <p className={bem.element('label')}>
            <FormattedMessage
              id="checkout_countdown-timer_label"
              description="Countdown timer label"
              defaultMessage="Items in your order are reserved for"
            />
          </p>
          <Timer
            timeout={timeout}
            onTimeout={handleTimeout}
          />
        </div>
      </Collapse>
    ) : null
  );
};

export default CountdownTimer;
