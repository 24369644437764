import { combineReducers } from 'redux';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import { AppReducer } from '../../types';
import { PaginationState, PaymentMethodTypesPage } from '../types';

type PageNumber = string | number;

type PageRecord = Partial<Record<PageNumber, PaymentMethodTypesPage>>;

type State = PaginationState<PaymentMethodTypesPage>;

const pages: AppReducer<PageRecord> = (
  previousState = {},
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_TYPES_REQUEST:
      return update(previousState, {
        [action.params.pageNumber]: {
          $apply: (previousPage) => ({
            ...previousPage,
            types: previousPage != null ? previousPage.types : [],
            params: action.params,
            status: 'loading',
          }),
        },
      });
    case actionTypes.FETCH_PAYMENT_METHOD_TYPES_SUCCESS:
      return update(previousState, {
        [action.params.pageNumber]: {
          $set: {
            types: action.payload.results,
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
            params: action.params,
            status: 'fulfilled',
          },
        },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_REQUEST:
    case actionTypes.FETCH_CHECKOUT_REQUEST:
      // hardcoded page number because payment types from
      // new checkout model are not paged
      return update(previousState, {
        1: {
          $apply: (previousPage) => ({
            ...previousPage,
            types: previousPage != null ? previousPage.types : [],
            status: 'loading',
          }),
        },
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      // hardcoded page number because payment types from
      // new checkout model are not paged
      return update(previousState, {
        1: {
          $set: {
            types: action.payload.payment.method_types,
            isFirstPage: true,
            isLastPage: true,
            status: 'fulfilled',
          },
        },
      });

    case actionTypes.FETCH_PAYMENT_METHOD_TYPES_FAILURE:
      return update(previousState, {
        [action.params.pageNumber]: {
          $set: {
            types: ['card'],
            error: action.payload,
            params: action.params,
            status: 'rejected',
          },
        },
      });
    default:
      return previousState;
  }
};

const currentPage: AppReducer<PageNumber> = (
  previousState = 1,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_TYPES_SUCCESS:
      return action.params.pageNumber;
    case actionTypes.FETCH_PAYMENT_METHOD_TYPES_FAILURE:
      return action.params.pageNumber;
    default:
      return previousState;
  }
};

export default combineReducers<State>({
  currentPage,
  pages,
});
