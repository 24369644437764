import map from 'lodash/map';
import reverse from 'lodash/reverse';
import zipObjectDeep from 'lodash/zipObjectDeep';
import validate from 'validate.js';

// Extend validate.js package with an error formatter that makes sense for us.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
validate.formatters.zipped = (errors: any): any => {
  const attributes = reverse(map(errors, 'attribute'));
  const messages = reverse(map(errors, 'error'));
  return zipObjectDeep(attributes, messages);
};

export default validate;
