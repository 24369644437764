import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import OrderShopfiyItem from '../components/order-shopify-item';
import {
  getIsWrapCartItemEnabled,
} from '../store/checkout/selectors';

const mapStateToProps = createStructuredSelector({
  isWrapCartItemEnabled: getIsWrapCartItemEnabled,
});

export default connect(mapStateToProps)(OrderShopfiyItem);
