// @ts-check

import get from 'lodash/get';
import property from 'lodash/property';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import flow from 'lodash/flow';
import words from 'lodash/words';
import some from 'lodash/some';

/**
 * @param {io.flow.v0.models.AddressConfigurationProvince} addressConfigurationProvince
 * @param {string} locale
 */
const findTranslatedDivision = (
  addressConfigurationProvince,
  locale,
) => find(addressConfigurationProvince.translations,
  (translation) => translation.locale.language === locale);

// TODO: This helper also exists in the `AdministrativeArea` component.
// It can be moved into a shared helper module.
const getTranslatedAreaName = flow([
  findTranslatedDivision,
  property('name'),
]);

/**
 * @param {(string | undefined)[]} areaArray
 * @param {string} locale
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 */
const mapAreaArray = (
  areaArray,
  locale,
  addressConfiguration,
) => map(areaArray, (adminArea) => find(
  addressConfiguration.provinces,
  (d) => getTranslatedAreaName(d, locale) === adminArea,
));

const findAreas = flow([
  mapAreaArray,
  (areas) => filter(areas, (area) => !!area),
  (filteredAreas) => get(filteredAreas[0], 'name'),
]);

/**
 * @param {string | undefined} suggestedAdministrativeArea
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 * @param {string} locale
 */
const getAdministrativeAreaOption = (
  suggestedAdministrativeArea,
  addressConfiguration,
  locale,
) => {
  const matches = addressConfiguration.provinces.some((addressConfigurationProvince) => {
    const localizedName = getTranslatedAreaName(addressConfigurationProvince, locale);
    return localizedName === suggestedAdministrativeArea;
  });

  if (matches) {
    return suggestedAdministrativeArea;
  }

  const suggestedAdministrativeAreaArray = words(suggestedAdministrativeArea);
  return findAreas(suggestedAdministrativeAreaArray, locale, addressConfiguration);
};

/**
 * @param {io.flow.v0.models.AddressConfiguration} addressConfiguration
 * @param {string} locale
 * @param {(string | undefined)[]} suggestedAdministrativeAreas
 */
export default function getCorrectAdministrativeArea(
  addressConfiguration,
  locale,
  suggestedAdministrativeAreas,
) {
  let selectedDivision;

  some(suggestedAdministrativeAreas, (suggestedAdministrativeArea) => {
    if (addressConfiguration.provinces.length < 1 && suggestedAdministrativeArea) {
      selectedDivision = suggestedAdministrativeArea;
      return true;
    }

    const foundAdministrativeArea = getAdministrativeAreaOption(
      suggestedAdministrativeArea,
      addressConfiguration,
      locale,
    );

    if (foundAdministrativeArea) {
      selectedDivision = foundAdministrativeArea;
      return true;
    }

    return false;
  });

  return selectedDivision;
}
