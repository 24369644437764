import get from 'lodash/get';
import OrderBuilderError from './order-builder-error';

/**
 * Check the order builder api response for errors. Instead of returning
 * a 422 when errors are present the `order_builder` resource returns an
 * object containing both the order and any errors from the request as a 2xx.
 * @param {Object} response - Response object from `@flowio/node-sdk` package.
 */
export default function checkOrderBuilderStatus(response) {
  const builderErrors = get(response, 'result.errors', []);
  const checkoutBuidlerErrors = get(response, 'result.builder.errors', []);

  if (builderErrors.length || checkoutBuidlerErrors.length) {
    throw new OrderBuilderError(response);
  } else {
    return response;
  }
}
