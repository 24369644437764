import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getIsLoading } from '@flowio/redux-fetch';
import some from 'lodash/some';

import { setVisited, addDeviceDetails } from '../store/checkout/actions';
import { getIsCartLoading } from '../store/cart/selectors';
import { getApplicationVersionOverride, getOrganizationUrl } from '../store/flow/selectors';
import {
  getDeviceDetails,
  getIsOrderLoading,
  getIsPaymentLoading,
  getOrganizationData,
  isMobileUXSpacingEnabled,
} from '../store/checkout/selectors';
import { getRedirectLocation, getIsLoading as getIsLoadingIndicatorVisible } from '../store/application/selectors';
import Application from '../components/application';
import { getActiveStep } from '../store/navigation/selectors';

const mapStateToProps = (state) => ({
  isMobileUXSpacingEnabled: isMobileUXSpacingEnabled(state),
  blocking: some([
    getIsLoadingIndicatorVisible(state),
    getIsLoading(state),
    getIsCartLoading(state),
    getIsOrderLoading(state),
    getIsPaymentLoading(state),
  ]),
  versionOverride: getApplicationVersionOverride(state),
  organizationUrl: getOrganizationUrl(state),
  redirectLocation: getRedirectLocation(state),
  currentStep: getActiveStep(state),
  deviceDetails: getDeviceDetails(state),
  organizationData: getOrganizationData(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setVisited,
  addDeviceDetails,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Application);
