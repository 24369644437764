import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

import Section from '../section';
import getFormattedDeliveryOptionLabel from '../../utilities/get-formatted-delivery-option-label';
import FeatureToggle from '../feature-toggle';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./shipping-method-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('shipping-method-summary');

const ShippingMethodSummary = ({ deliveryOptions, editUrl }) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
      <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
        <Section.Header>
          <Section.Title>
            <FormattedMessage
              id="checkout_shipping_method_summary_heading"
              defaultMessage="Shipping Method"
              description="Title for the section containing a summary of the selected shipping methods in the order"
            />
          </Section.Title>
        </Section.Header>
        <Section.Content>
          <div className={bem.element('content')}>
            <ul className={bem.element('list')}>
              {map(deliveryOptions, (deliveryOption, index) => (
                <li key={deliveryOption.id} className={bem.element('item')}>
                  {(deliveryOptions.length > 1) ? (
                    <FormattedMessage
                      id="checkout_multiple_delivery_description"
                      defaultMessage="{deliveryNumber} of {deliveryTotal}: {deliveryOptionLabel}"
                      description="Text describing one of multiple deliveries in the order"
                      values={{
                        deliveryNumber: index + 1,
                        deliveryTotal: deliveryOptions.length,
                        deliveryOptionLabel: getFormattedDeliveryOptionLabel(deliveryOption),
                      }}
                    />
                  ) : getFormattedDeliveryOptionLabel(deliveryOption)}
                </li>
              ))}
            </ul>
          </div>
          {editUrl && (
            <Link className={bem.element('edit-link')} to={editUrl}>
              <FormattedMessage
                id="checkout_action_edit"
                description="A text describing action to edit"
                defaultMessage="Edit"
              />
            </Link>
          )}
        </Section.Content>
      </Section>
    )}
  />
);

ShippingMethodSummary.displayName = 'ShippingMethodSummary';

ShippingMethodSummary.propTypes = {
  deliveryOptions: PropTypes.arrayOf(ApiPropTypes.deliveryOption).isRequired,
  editUrl: PropTypes.string,
};

ShippingMethodSummary.defaultProps = {
  editUrl: undefined,
};

export default ShippingMethodSummary;
