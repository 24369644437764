import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import Badge from '../badge';
import Image from '../image';

if (process.browser) {
  require('./order-item.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-item');

const OrderItem = ({
  quantity,
  priceLabel,
  content,
  isWrapCartItemEnabled,
}) => (
  <div>
    <div className={bem.block()}>
      <div className={bem.element('media')}>
        <Badge className={bem.element('quantity')} color="tertiary" content={quantity} floating>
          {get(content, 'image') && (
            <Image
              className={bem.element('image')}
              bordered
              rounded
              fit="fill"
              source={get(content, 'image.url')}
              accessibilityLabel={get(content, 'name')}
            />
          )}
        </Badge>
      </div>
      <div className={bem.element('details')}>
        <div className={bem.element('name', { wrapping: isWrapCartItemEnabled })}>
          {get(content, 'name')}
        </div>
        {get(content, 'attributes') && map(get(content, 'attributes'), (attribute) => (
          <div key={`${attribute.key}-${attribute.value}`}>
            <div
              className={bem.element('attribute')}
              data-attribute-name={attribute.name}
            >
              <span className={bem.element('attribute-name')}>
                {attribute.name}
              </span>
              <span className={bem.element('attribute-value')}>
                {attribute.value}
              </span>
            </div>
          </div>
        ))}
      </div>
      {priceLabel != null && (
        <p className={bem.element('price')}>
          {priceLabel}
        </p>
      )}
    </div>
  </div>
);

OrderItem.displayName = 'OrderItem';

OrderItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  priceLabel: PropTypes.string,
  isWrapCartItemEnabled: PropTypes.bool,
  content: ApiPropTypes.checkoutItemContent,
};

OrderItem.defaultProps = {
  content: {},
  priceLabel: undefined,
  isWrapCartItemEnabled: false,
};

export default OrderItem;
