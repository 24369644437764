import ExtendableError from 'es6-error';

import { isUnknownObject, isAuthorization } from '../predicates';

type Code = 'authorization_action_error' | 'authorization_status_error';

export function isAuthorizationError(
  error: unknown,
): error is AuthorizationError {
  return isUnknownObject(error)
    && error.name === 'AuthorizationError'
    && isAuthorization(error.authorization);
}

/**
 * An error thrown when the status for a payment authorization is invalid.
 */
export class AuthorizationError extends ExtendableError {
  /**
   * The payment authorization that failed.
   */
  authorization: io.flow.v0.unions.Authorization;

  /**
   * A code representing reason payment authorization failed.
   *
   * Use "authorization_action_error" when payment authorization failed due to
   * an incomplete action (e.g. 3DS challenge incomplete)
   *
   * Use "authorization_status_error" when payment authorization was not
   * accepted (e.g. declined).
   */
  code: Code;

  constructor(code: Code, authorization: io.flow.v0.unions.Authorization) {
    super('Payment authorization was not accepted');
    this.name = 'AuthorizationError';
    this.code = code;
    this.authorization = authorization;
  }

  toJSON(): Omit<AuthorizationError, 'toJSON'> {
    return {
      name: 'AuthorizationError',
      message: this.message,
      code: this.code,
      authorization: this.authorization,
      stack: this.stack,
    };
  }
}
