import property from 'lodash/property';

import { getOrganization } from '../../flow/selectors';
import { visitConfirmationStep, safeReplace } from '../../navigation/actions';
import { isValidCustomerInfo, isValidShippingAddress } from '../../../containers/customer-information-form/validation';
import {
  getCustomerEmailPromptBehavior,
  getIsOrderSubmitted,
  getOrderDenormalized,
  getShippingAddressPromptBehavior,
  getShippingMethodPromptBehavior,
  hasMultipleShippingMethods,
} from '../selectors';
import {
  getShippingAddressConfiguration, getInitialContactInfoFormValues,
} from '../../../containers/customer-information-form/selectors';

import * as RedirectReason from '../../../constants/redirect-reason';
import OrderAttributes from '../../../constants/order-attributes';
import setOrderAttributes from './set-order-attributes';
import { getPaymentInfoUrlV2, getShippingMethodUrlV2 } from '../../navigation';

function isAnyPromptBehaviorAttributePresentInOrder(state) {
  const order = getOrderDenormalized(state);

  return Object.keys(order.attributes).some((attributeName) => (
    attributeName === OrderAttributes.CUSTOMER_EMAIL_PROMPT_BEHAVIOR
    || attributeName === OrderAttributes.SHIPPING_ADDRESS_PROMPT_BEHAVIOR
    || attributeName === OrderAttributes.SHIPPING_METHOD_PROMPT_BEHAVIOR
  ));
}

export default function skipCheckoutSteps() {
  return function skipCheckoutStepsEffect(dispatch, getState) {
    if (getIsOrderSubmitted(getState())) {
      dispatch(visitConfirmationStep('replace'));
      return Promise.resolve();
    }

    if (isAnyPromptBehaviorAttributePresentInOrder(getState())) {
      return Promise.resolve();
    }

    const formatMessage = property('defaultMessage');
    const organizationId = getOrganization(getState());
    const values = getInitialContactInfoFormValues(getState());
    const customerEmailPromptBehavior = getCustomerEmailPromptBehavior(getState());
    const shippingAddressPromptBehavior = getShippingAddressPromptBehavior(getState());
    const shippingMethodPromptBehavior = getShippingMethodPromptBehavior(getState());
    const addressConfiguration = getShippingAddressConfiguration(getState());

    const promptForCustomerEmail = (
      customerEmailPromptBehavior === 'always'
      || (customerEmailPromptBehavior === 'incomplete' && !isValidCustomerInfo(values, formatMessage))
    );

    const promptForShippingAddress = (
      shippingAddressPromptBehavior === 'always'
      || (shippingAddressPromptBehavior === 'incomplete' && !isValidShippingAddress(values, addressConfiguration, formatMessage))
    );

    const promptForShippingMethod = (
      shippingMethodPromptBehavior === 'always'
      || (shippingMethodPromptBehavior === 'multiple' && hasMultipleShippingMethods(getState()))
    );

    // Sync prompt behaviors to order attributes.
    return dispatch(setOrderAttributes(organizationId, {
      [OrderAttributes.CUSTOMER_EMAIL_PROMPT_BEHAVIOR]: customerEmailPromptBehavior,
      [OrderAttributes.SHIPPING_ADDRESS_PROMPT_BEHAVIOR]: shippingAddressPromptBehavior,
      [OrderAttributes.SHIPPING_METHOD_PROMPT_BEHAVIOR]: shippingMethodPromptBehavior,
    })).then(() => {
      // Redirect if necessary.
      if (!promptForShippingAddress && !promptForCustomerEmail) {
        if (!promptForShippingMethod) {
          dispatch(safeReplace(getPaymentInfoUrlV2(getState()), {
            redirectReason: RedirectReason.CHECKOUT_PROMPT_BEHAVIOR,
          }));
        } else {
          dispatch(safeReplace(getShippingMethodUrlV2(getState()), {
            redirectReason: RedirectReason.CHECKOUT_PROMPT_BEHAVIOR,
          }));
        }
      }
    });
  };
}
