import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ApiPropTypes from '@flowio/api-prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';

import RewardsForm from '../../../containers/rewards-form';

const GiftCardForm = ({
  error,
  removeError,
  handleSubmit,
  onRemoveGiftCard,
  giftCardProgram,
}) => (
  <form onSubmit={handleSubmit}>
    <RewardsForm
      error={error}
      removeError={removeError}
      onRemoveReward={onRemoveGiftCard}
      rewardsProgram={giftCardProgram}
      labelKey="display_label"
      appliedRewards={get(giftCardProgram, 'cards', [])}
    />
  </form>
);

GiftCardForm.displayName = 'GiftCardForm';

GiftCardForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onRemoveGiftCard: PropTypes.func.isRequired,
  giftCardProgram: ApiInternalPropTypes.giftCardProgram.isRequired,
  error: ApiPropTypes.genericError,
  removeError: ApiPropTypes.genericError,
};

GiftCardForm.defaultProps = {
  error: undefined,
  removeError: undefined,
};

export default GiftCardForm;
