import getItemPriceLabel from './get-item-price-label';

// CAUTION: there is known bug where the price label may be incorrect when
// multiple items with the same item number are listed in the delivery
export default function getDeliveryItemPriceLabel(
  deliveryItem: io.flow.v0.models.DeliveryItem,
  order: io.flow.v0.models.Order,
): string | undefined {
  // delivery items do not have a localized price label
  if (order.lines != null) {
    const line = order.lines.find((_) => _.item_number === deliveryItem.number);
    if (line != null) {
      return line.price.label;
    }
  }

  const item = order.items.find((_) => _.number === deliveryItem.number);

  if (item != null) {
    return getItemPriceLabel(item);
  }

  return undefined;
}
