import type { AppReducer } from '../../types';
import type { ShopifyState } from '../types';

const initialState: ShopifyState = {};

// Shopify data is injected when server response is processed.
// Reducer is needed to avoid losing that information when the application
// is initialized on the client-side.
const shopifyReducer: AppReducer<ShopifyState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    default:
      return previousState;
  }
};

export default shopifyReducer;
