import { FormatMessage } from 'react-intl';
import { addressConfigurationToConstraints } from './address-utilities';

import { messages } from '../containers/payment-form/validation';
import addressFormValues from './converters/address-form-values';
import validate from './validate';

export function validateContact(
  contact: io.flow.v0.models.CustomerAddressBookContact,
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  formatMessage: FormatMessage,
  registeredFields?: string[],
): Record<string, string> | undefined {
  const values = addressFormValues.fromCustomerAddressBookContact(contact);
  const constraints = addressConfigurationToConstraints(
    addressConfiguration,
    formatMessage,
    registeredFields,
  );

  constraints.country = {
    presence: {
      allowEmpty: false,
      message: formatMessage(messages.missingCountry),
    },
  };

  return validate(values, constraints);
}

export function isValidContact(
  contact: io.flow.v0.models.CustomerAddressBookContact,
  addressConfiguration: io.flow.v0.models.AddressConfiguration,
  formatMessage: FormatMessage,
  registeredFields?: string[],
): boolean {
  const errors = validateContact(contact, addressConfiguration, formatMessage, registeredFields);
  return errors == null;
}
