import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

const updateDeliveredDutyRequest = (
  organizationId: string,
  deliveredDuty: io.flow.v0.enums.DeliveredDuty,
): RootAction => ({
  type: actionTypes.UPDATE_DELIVERED_DUTY_REQUEST,
  params: {
    deliveredDuty,
    organization: organizationId,
  },
});

const updateDeliveredDutySuccess = (
  organizationId: string,
  deliveredDuty: io.flow.v0.enums.DeliveredDuty,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_DELIVERED_DUTY_SUCCESS,
  payload: orderBuilder,
  params: {
    deliveredDuty,
    organization: organizationId,
  },
});

const updateDeliveredDutyFailure = (
  organizationId: string,
  deliveredDuty: io.flow.v0.enums.DeliveredDuty,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_DELIVERED_DUTY_FAILURE,
  payload: error,
  params: {
    deliveredDuty,
    organization: organizationId,
  },
});

const updateDeliveredDuty = (
  organizationId: string,
  deliveredDuty: io.flow.v0.enums.DeliveredDuty,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const order = getOrderDenormalized(getState());
  const orderPutForm = orderPutFormConverter.fromOrder(order);
  const orderNumber = order.number;
  dispatch(updateDeliveredDutyRequest(organizationId, deliveredDuty));
  return extra.api.orderBuilders.putByNumber({
    body: {
      ...orderPutForm,
      delivered_duty: deliveredDuty,
      selections: [],
    },
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateDeliveredDutySuccess(organizationId, deliveredDuty, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateDeliveredDutyFailure(organizationId, deliveredDuty, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateDeliveredDuty;
