import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import {
  hideOrderSummary,
  showOrderSummary,
  triggerInitialized,
  canTriggerInitialized,
} from '../store/checkout/actions';

import {
  getCheckoutConfiguration,
  getOrderDenormalized,
  getIsOrderSummaryVisible,
  getIsDutyIncluded,
  getIsTaxIncluded,
  getItemContentsMap,
  isDiscountsEnabled,
  isReviewStepEnabled,
  isCatchpointRumEnabled,
  isLiquid,
  isLoyaltyEnabled,
  isGiftCardsEnabled,
  isForceEnableShopifyDiscountsEnabled,
  getIsDeliveredDutyPaidMessageEnabled,
} from '../store/checkout/selectors';
import { getActiveStep } from '../store/navigation/selectors';
import { getOrganization, getIsShopifyIntegration } from '../store/flow/selectors';
import CheckoutLayout from '../components/checkout-layout';

const mapStateToProps = createStructuredSelector({
  activeStep: getActiveStep,
  checkoutConfiguration: getCheckoutConfiguration,
  order: getOrderDenormalized,
  organization: getOrganization,
  forceEnableShopifyDiscounts: isForceEnableShopifyDiscountsEnabled,
  isDutyIncluded: getIsDutyIncluded,
  isLiquid,
  itemContentsMap: getItemContentsMap,
  // Only applicable to specific breakpoints
  isOrderSummaryVisible: getIsOrderSummaryVisible,
  isTaxIncluded: getIsTaxIncluded,
  isShopifyIntegration: getIsShopifyIntegration,
  isLoyaltyEnabled,
  isDiscountsEnabled,
  isReviewStepEnabled,
  isGiftCardsEnabled,
  isCatchpointRumEnabled,
  isDeliveredDutyPaidMessageEnabled: getIsDeliveredDutyPaidMessageEnabled,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRequestHideOrderSummary: hideOrderSummary,
  onRequestShowOrderSummary: showOrderSummary,
  triggerInitialized,
  canTriggerInitialized,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CheckoutLayout);
