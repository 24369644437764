import type { $HttpResponse } from '@flowio/api-sdk';
import legacyErrorConverter from './legacy-error-converter';
import type { LegacyResponse, LegacyResponseWithLegacyError } from '../../types';

/**
 * Converts response from our new TypeScript SDK to a response from Node SDK
 * for backward compatibility while migrating to a type safe environment.
 */
function fromHttpResponse<T extends $HttpResponse>(
  response: T,
): LegacyResponse<T> {
  return {
    ok: response.ok,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    result: response.body,
    status: response.status,
  } as LegacyResponse<T>;
}

function withLegacyError<R extends $HttpResponse>(
  response: R,
): LegacyResponseWithLegacyError<R> {
  return {
    ok: response.ok,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-nested-ternary
    result: response.ok
      ? response.body
      : response.body == null
        ? legacyErrorConverter.fromStatusCode(response.status)
        : response.body,
    status: response.status,
  } as LegacyResponseWithLegacyError<R>;
}

export default {
  fromHttpResponse,
  withLegacyError,
};
