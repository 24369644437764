/* eslint-disable import/prefer-default-export */

import { defineMessages } from 'react-intl';
import validate from '../../utilities/validate';

const messages = defineMessages({
  missingPromoCode: {
    id: 'checkout_promotion_code_warning',
    description: 'A message prompting the user to enter a promotion code to apply a discount to the order',
    defaultMessage: 'Please enter a promotion code',
  },
});

const options = {
  format: 'zipped',
  fullMessages: false,
};

export function handleValidate(values, props) {
  const { formatMessage } = props.intl;
  return validate(values, {
    promotionCode: {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingPromoCode),
      },
    },
  }, options);
}
