import map from 'lodash/map';
import Features from '../constants/features';

export default function generateFeatureQuery(organization) {
  let query = organization ? `organization.id:${organization}` : '';
  const globalFeatureQuery = map(Features, (feature) => feature);
  query += ` or feature.key in (${globalFeatureQuery.join(' or ')})`;

  return query;
}
