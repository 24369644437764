import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import LandedCostSettings from '../landed-cost-settings';
import Section from '../section';
import AddressSummary from '../address-summary';
import ShippingMethodForm from '../../containers/shipping-method-form';
import { Grid, Row, Column } from '../grid';
import { AddressType } from '../../constants/address-type';
import MobileAddressSummary from '../mobile-address-summary';
import isSmallScreen from '../../utilities/is-small-screen';

if (process.browser) {
  require('./shipping-method-step.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('shipping-method-step');

const ShippingMethodStep = ({
  countries,
  invoiceAddress,
  isDeliveredDutyCustomerChoice,
  isSingleDeliveredDutyDisplayType,
  destination,
  editShippingAddressUrl,
  vrn,
}) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
      <div data-automation-id="shipping-method-step" className={bem.block()}>
        <Grid parent={isMobileUXSpacingEnabled}>
          <Row>
            {isSmallScreen() ? (
              <>
                <Column xs={12} sm={6}>
                  <MobileAddressSummary
                    type={AddressType.SHIPPING}
                    countries={countries}
                    address={destination}
                    editUrl={editShippingAddressUrl}
                  />
                </Column>
                <Column xs={12} sm={6}>
                  {invoiceAddress && (
                    <MobileAddressSummary
                      type={AddressType.INVOICE}
                      countries={countries}
                      address={invoiceAddress}
                      vrn={vrn}
                    />
                  )}
                </Column>
              </>
            ) : (
              <>
                <Column md={6} s={12} sCenter>
                  <AddressSummary
                    type={AddressType.SHIPPING}
                    countries={countries}
                    address={destination}
                    editUrl={editShippingAddressUrl}
                  />
                </Column>
                <Column md={6} s={12} sCenter>
                  {invoiceAddress && (
                    <AddressSummary
                      type={AddressType.INVOICE}
                      countries={countries}
                      address={invoiceAddress}
                      vrn={vrn}
                    />
                  )}
                </Column>
              </>
            )}
          </Row>
        </Grid>
        {isDeliveredDutyCustomerChoice && isSingleDeliveredDutyDisplayType && (
          <Section fitted={isMobileUXSpacingEnabled}>
            <Section.Header>
              <Section.Title>
                <FormattedMessage
                  id="checkout_landed_cost_subheader"
                  description="Title for section for customer to select duty and tax preferences"
                  defaultMessage="Duties and Taxes"
                />
              </Section.Title>
            </Section.Header>
            <Section.Content>
              <LandedCostSettings />
            </Section.Content>
          </Section>
        )}
        <Section fitted={isMobileUXSpacingEnabled}>
          <Section.Header>
            <Section.Title>
              <FormattedMessage
                id="checkout_shipping_method_heading"
                description="Title for shipping method section"
                defaultMessage="Shipping Method"
              />
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <ShippingMethodForm />
          </Section.Content>
        </Section>
      </div>
    )}
  />
);

ShippingMethodStep.displayName = 'ShippingMethodStep';

ShippingMethodStep.propTypes = {
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  destination: ApiPropTypes.orderAddress.isRequired,
  editShippingAddressUrl: PropTypes.string.isRequired,
  isDeliveredDutyCustomerChoice: PropTypes.bool.isRequired,
  isSingleDeliveredDutyDisplayType: PropTypes.bool.isRequired,
  invoiceAddress: ApiPropTypes.billingAddress,
  vrn: PropTypes.string,
};

ShippingMethodStep.defaultProps = {
  vrn: undefined,
  invoiceAddress: undefined,
};

export default ShippingMethodStep;
