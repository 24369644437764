import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ApiPropTypes from '@flowio/api-prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';

import RewardsForm from '../../../containers/rewards-form';

const LoyaltyForm = ({
  error,
  removeError,
  onRemoveLoyalty,
  loyaltyProgram,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit}>
    <RewardsForm
      error={error}
      removeError={removeError}
      onRemoveReward={onRemoveLoyalty}
      rewardsProgram={loyaltyProgram}
      appliedRewards={get(loyaltyProgram, 'rewards.applied', [])}
    />
  </form>
);

LoyaltyForm.displayName = 'LoyaltyForm';

LoyaltyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onRemoveLoyalty: PropTypes.func.isRequired,
  loyaltyProgram: ApiInternalPropTypes.loyaltyProgram.isRequired,
  removeError: ApiPropTypes.genericError,
  error: ApiPropTypes.genericError,
};

LoyaltyForm.defaultProps = {
  removeError: undefined,
  error: undefined,
};

export default LoyaltyForm;
