/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect';
import { getOrderNumber } from '../../store/checkout/selectors';
import { getOrganization } from '../../store/flow/selectors';

/**
 * Returns the initial values for the coupon form based on the current
 * application state.
 * @param {Object} state
 * @returns {Object}
 */
export const getInitialValues = createSelector(
  getOrderNumber,
  getOrganization,
  (orderNumber, organizationId) => ({
    orderNumber,
    organizationId,
  }),
);
