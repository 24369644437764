/* eslint-disable no-underscore-dangle */

import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

import './mobile-discount-form-v2.css';

import {
  ErrorType,
  FormData,
  Props,
  State,
} from '../types';
import {
  removeDiscountCodeFailure,
  removeDiscountCodeRequest,
  removeDiscountCodeSuccess,
  setDiscountCode,
  submitDiscountCodeFailure,
  submitDiscountCodeRequest,
  submitDiscountCodeSuccess,
} from '../actions';
import { Column, Grid, Row } from '../../grid';
import Button from '../../button';
import Chip from '../../chip';
import isSubmissionError from '../../../utilities/redux-form/is-submission-error';
import reducer from '../reducer';
import Section from '../../section';
import TextField from '../../text-field';

const bem = new BemHelper('flow-mobile-discount-form-v2');

const messages = defineMessages({
  hintText: {
    id: 'checkout_mobile_discount_code_hint_text',
    defaultMessage: 'Enter promo code...',
    description: 'Text describing hint text for an input that takes a discount code to apply to an order',
  },
});

const initialState: State = {
  discountCode: '',
  submitFailed: false,
  submitSucceeded: false,
  submitting: false,
};

const MobileDiscountFormV2: React.FunctionComponent<Props> = ({
  discounts,
  onRemove,
  onSubmit,
  orderNumber,
  organizationId,
}) => {
  const [
    state,
    dispatch,
  ] = React.useReducer(reducer, initialState);

  const {
    discountCode,
    submitting,
    submitError,
    submitFailed,
  } = state;

  const intl = useIntl();

  const apiError = isSubmissionError<FormData, ErrorType>(submitError)
    ? submitError.errors._error
    : undefined;

  const isDisabled = submitting || discountCode.length === 0;

  const handleChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    dispatch(setDiscountCode(event.currentTarget.value));
  }, [dispatch]);

  const handleRemove = React.useCallback((discountCodeToRemove: string) => {
    dispatch(removeDiscountCodeRequest());
    onRemove({
      discountCode: discountCodeToRemove,
      orderNumber,
      organizationId,
    }).then(() => {
      dispatch(removeDiscountCodeSuccess());
    }).catch(() => {
      dispatch(removeDiscountCodeFailure());
    });
  }, [dispatch]);

  const applyPromotion = React.useCallback(() => {
    dispatch(submitDiscountCodeRequest());
    onSubmit({
      discountCode,
      orderNumber,
      organizationId,
    }).then(() => {
      dispatch(submitDiscountCodeSuccess());
    }).catch((error) => {
      dispatch(submitDiscountCodeFailure(error));
    });
  }, [dispatch, discountCode, orderNumber, organizationId]);

  const handleSubmit = React.useCallback<React.ReactEventHandler>((event) => {
    event.stopPropagation();
    event.preventDefault();
    applyPromotion();
  }, [applyPromotion]);

  const handleKeyPress = React.useCallback<React.KeyboardEventHandler>((event) => {
    if (event.key === 'Enter') {
      // To avoid submission of parent forms.
      event.stopPropagation();
      event.preventDefault();

      if (!isDisabled) {
        applyPromotion();
      }
    }
  }, [handleSubmit]);

  return (
    <Section className={bem.block()}>
      <Section.Header>
        <Section.Title>
          <FormattedMessage
            defaultMessage="Promo Code"
            description="Text describing section to apply discount codes to an order"
            id="checkout_mobile_discount_heading"
          />
        </Section.Title>
      </Section.Header>
      <Section.Content>
        <Grid>
          <Row>
            <Column xs={12}>
              <div className={bem.element('input-group')}>
                <TextField
                  className={bem.element('input')}
                  labelText={intl.formatMessage(messages.hintText)}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  name="promotionCode"
                  size="medium"
                  value={discountCode}
                />
                <Button
                  className={bem.element('apply-button')}
                  disabled={isDisabled}
                  onClick={handleSubmit}
                  size="medium"
                  type="button"
                >
                  <FormattedMessage
                    id="checkout_coupon_button_apply"
                    description="The text of an action to apply a discount to an order"
                    defaultMessage="Apply"
                  />
                </Button>
              </div>
            </Column>
          </Row>
          {submitFailed && (
            <Row>
              <Column xs={12}>
                {apiError != null && (
                  <p className={bem.element('error-message')}>
                    {apiError.messages.join('')}
                  </p>
                )}
              </Column>
            </Row>
          )}
          {discounts.map((discount) => (
            <Row>
              <Column xs={12}>
                <div className={bem.element('discount')}>
                  <Chip color="positive" size="small" text={discount.name} />
                  <Button
                    className={bem.element('remove-button')}
                    link
                    onClick={(event): void => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (discount.name != null) {
                        handleRemove(discount.name);
                      }
                    }}
                    size="small"
                    type="button"
                  >
                    <FormattedMessage
                      id="checkout_order_summary_action_remove_promotion"
                      defaultMessage="Remove"
                      description="Text describing action to remove applied promotion code"
                    />
                  </Button>
                  <div className={bem.element('discount-label')}>
                    {discount.label}
                  </div>
                </div>
              </Column>
            </Row>
          ))}
        </Grid>
      </Section.Content>
    </Section>
  );
};

export default MobileDiscountFormV2;
