import BemHelper from '@flowio/bem-helper';
import React from 'react';

if (process.browser) {
  // eslint-disable-next-line global-require
  require('./svg-icon.css');
}

type OwnProps = {
  children: React.ReactNode;
  className?: string;
  viewBox?: string;
};

type UnhandledProps = Omit<
React.SVGAttributes<SVGSVGElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

const bem = new BemHelper('svg-icon');

const SvgIcon: React.FunctionComponent<Props> = ({
  children,
  className,
  viewBox = '0 0 24 24',
  ...unhandledProps
}) => (
  <svg
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...unhandledProps}
    className={bem.block(className)}
    viewBox={viewBox}
  >
    {children}
  </svg>
);

export default SvgIcon;
