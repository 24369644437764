import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';
import type { PaymentFormValues } from '../types';

export default function proceedToReview(
  values: PaymentFormValues,
): RootAction {
  return {
    type: actionTypes.PROCEED_TO_REVIEW,
    payload: values,
  };
}
