import type { $HttpResponse } from '@flowio/api-sdk';
import type { LegacyError } from '../../types';

type Error =
  | io.flow.internal.v0.models.CheckoutError
  | io.flow.v0.models.GenericError
  | io.flow.v0.models.OrderError
  | io.flow.v0.models.PaymentError
  | io.flow.v0.unions.ReservationError;

export const httpStatusMessages: Record<number, string> = {
  401: 'You are not authorized to perform this action at this time.',
  404: 'We could not find the resource you requested.',
  500: 'A server error has occurred, please try again later.',
};

function fromStatusCode(statusCode: number): LegacyError {
  const message = httpStatusMessages[statusCode];
  if (message != null) {
    return {
      code: 'legacy_error',
      messages: [message],
    };
  }

  return {
    code: 'legacy_error',
    messages: [],
  };
}

function fromError(error: Error): LegacyError {
  return {
    code: 'legacy_error',
    messages: error.messages,
  };
}

/** @deprecated prefer other functions to validate exhaustive checks */
function fromHttpResponse(
  response: $HttpResponse<Error | undefined>,
): LegacyError {
  return response.body != null ? fromError(response.body) : fromStatusCode(response.status);
}

export default {
  fromStatusCode,
  fromError,
  fromHttpResponse,
};
