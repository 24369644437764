import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getLocale, getMessages } from '../store/intl/selectors';

const mapStateToProps = createStructuredSelector({
  locale: getLocale,
  messages: getMessages,
});

export default connect(mapStateToProps)(IntlProvider);
