import defaultTo from 'lodash/defaultTo';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.internal.v0.models.CheckoutConfiguration | null;

// NOTE: Previously this used to be an empty object. Tread carefully.
const initialState: State = null;

const checkoutConfigurationReducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return defaultTo(action.payload.configuration, previousState);
    default:
      return previousState;
  }
};

export default checkoutConfigurationReducer;
