/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

if (process.browser) {
  require('./postal-address.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('postal-address');

const PostalAddress = ({
  className,
  country,
  locality,
  postOfficeBoxNumber,
  postalCode,
  // TODO: Rename to administrativeArea for consistency
  region,
  streets,
  telephone,
  ...unhandledProps
}) => (
  <address
    {...unhandledProps}
    className={bem.block(className)}
    itemScope
    itemType="http://schema.org/PostalAddress"
  >
    {!isNil(streets) && (
      <span className={bem.element('street')} itemProp="streetAddress">
        {map(streets, (street, index) => (
          <span key={index} className={bem.element('street-line')}>
            {street}
          </span>
        ))}
      </span>
    )}
    {!isNil(postOfficeBoxNumber) && (
      <span className={bem.element('pobox')} itemProp="postOfficeBoxNumber">
        {postOfficeBoxNumber}
      </span>
    )}
    {!isNil(locality) && (
      <span className={bem.element('locality')} itemProp="addressLocality">
        {locality}
      </span>
    )}
    {!isNil(region) && (
      <span className={bem.element('region')} itemProp="addressRegion">
        {region}
      </span>
    )}
    {!isNil(postalCode) && (
      <span className={bem.element('postal-code')} itemProp="addressRegion">
        {postalCode}
      </span>
    )}
    {!isNil(country) && (
      <span className={bem.element('country')} itemProp="addressCountry">
        {country}
      </span>
    )}
    {!isNil(telephone) && (
      <span className={bem.element('telephone')} itemProp="telephone">
        {telephone}
      </span>
    )}
  </address>
);

PostalAddress.displayName = 'PostalAddress';

PostalAddress.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string,
  locality: PropTypes.string,
  postOfficeBoxNumber: PropTypes.string,
  postalCode: PropTypes.string,
  region: PropTypes.string,
  streets: PropTypes.arrayOf(PropTypes.string),
  telephone: PropTypes.string,
};

PostalAddress.defaultProps = {
  className: '',
  country: undefined,
  locality: undefined,
  postOfficeBoxNumber: undefined,
  postalCode: undefined,
  region: undefined,
  streets: undefined,
  telephone: undefined,
};

export default PostalAddress;
