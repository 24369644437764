import type { CustomerAddressBookContactsPutByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CustomerAddressBookContactsPutByIdResponse>>;

const updateCustomerAddressBookContactRequest = (): RootAction => ({
  type: actionTypes.UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_REQUEST,
});

const updateCustomerAddressBookContactSuccess = (
  customerAddressBookContact: io.flow.v0.models.CustomerAddressBookContact,
): RootAction => ({
  type: actionTypes.UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_SUCCESS,
  payload: customerAddressBookContact,
});

const updateCustomerAddressBookContactFailure = (
  error: io.flow.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_CUSTOMER_ADDRESS_BOOK_CONTACT_FAILURE,
  payload: error,
});

const updateCustomerAddressBookContact = (
  organizationId: string,
  customerNumber: string,
  customerAddressBookContactId: string,
  customerAddressBookContactForm: io.flow.v0.models.CustomerAddressBookContactForm,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(updateCustomerAddressBookContactRequest());
  return extra.api.customerAddressBookContacts.putById({
    body: customerAddressBookContactForm,
    id: customerAddressBookContactId,
    number: customerNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateCustomerAddressBookContactSuccess(response.result));
          break;
        case 401:
        case 422:
          dispatch(updateCustomerAddressBookContactFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default updateCustomerAddressBookContact;
