import envelopedFetch from '../utilities/enveloped-fetch';

import AddressConfigurations from '../../generated/api/address-configurations';
import Authorizations from '../../generated/api/authorizations';
import CardPaymentSources from '../../generated/api/card-payment-sources';
import CheckoutItemContents from '../../generated/api/checkout-item-contents';
import CheckoutTokens from '../../generated/api/checkout-tokens';
import Countries from '../../generated/api/countries';
import CustomerAddressBookContacts from '../../generated/api/customer-address-book-contacts';
import CustomerAddressBooks from '../../generated/api/customer-address-book';
import Customers from '../../generated/api/customers';
import CustomerTokens from '../../generated/api/customer-tokens';
import Experiences from '../../generated/api/experiences';
import ExperiencePriceConversions from '../../generated/api/experience-price-conversions';
import OrderBuilders from '../../generated/api/order-builders';
import Orders from '../../generated/api/orders';
import Organizations from '../../generated/api/organizations';
import PaymentMethodRules from '../../generated/api/payment-method-rules';
import Payments from '../../generated/api/payments';
import Provinces from '../../generated/api/provinces';
import PublicKeys from '../../generated/api/public-keys';
import Sessions from '../../generated/api/sessions';
import ShopifyCarts from '../../generated/api/shopify-carts';

import { getRequiredEnvVar } from '../utilities/environment';

class ApiClient {
  constructor(options) {
    this.addressConfigurations = new AddressConfigurations(options);
    this.authorizations = new Authorizations(options);
    this.cardPaymentSources = new CardPaymentSources(options);
    this.checkoutItemContents = new CheckoutItemContents(options);
    this.checkoutTokens = new CheckoutTokens(options);
    this.countries = new Countries(options);
    this.customers = new Customers(options);
    this.customerAddressBooks = new CustomerAddressBooks(options);
    this.customerAddressBookContacts = new CustomerAddressBookContacts(options);
    this.customerTokens = new CustomerTokens(options);
    this.experiences = new Experiences(options);
    this.ExperiencePriceConversions = new ExperiencePriceConversions(options);
    this.orderBuilders = new OrderBuilders(options);
    this.orders = new Orders(options);
    this.organizations = new Organizations(options);
    this.payments = new Payments(options);
    this.paymentMethodRules = new PaymentMethodRules(options);
    this.provinces = new Provinces(options);
    this.publicKeys = new PublicKeys(options);
    this.sessions = new Sessions(options);
    this.shopifyCarts = new ShopifyCarts(options);
  }
}

export default new ApiClient({
  host: getRequiredEnvVar('FLOW_API_URI'),
  fetch: envelopedFetch,
});
