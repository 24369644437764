import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function updateGooglePayInfo(
  // TODO: What's the expected type here?
  googlePayInfo: unknown,
): RootAction {
  return {
    type: actionTypes.UPDATE_GOOGLE_PAY_INFO,
    payload: googlePayInfo,
  };
}
