import { createSelector } from 'reselect';
import get from 'lodash/get';
import UrlParameters from '../../constants/url-parameters';
import { RootState } from '../types';
import { FlowState } from './types';

export const getFlowState = (state: RootState): FlowState => state.flow;

export const getOrganizationUrl = createSelector(
  getFlowState,
  (state) => get(state, 'organizationUrl'),
);

export const getCheckoutScriptUrl = createSelector(
  getFlowState,
  (state) => get(state, 'checkoutScriptUrl'),
);

export const getOverrideThemeUrl = createSelector(
  getFlowState,
  (state) => get(state, 'overrideThemeUrl'),
);

/**
 * Selects the organization identifier.
 */
export const getOrganization = createSelector(
  getFlowState,
  (state) => get(state, 'organization'),
);

export const getJwt = createSelector(
  getFlowState,
  (state) => get(state, 'jwt'),
);

export const getOrganizationScripts = createSelector(
  getFlowState,
  (state) => get(state, 'organizationScripts', []),
);

export const getIntegration = createSelector(
  getFlowState,
  (state) => get(state, 'integration'),
);

export const getIsLocalDevelopment = createSelector(
  getFlowState,
  (state) => get(state, 'local_development', false),
);

export const getIsShopifyIntegration = createSelector(
  getIntegration,
  (integration) => integration === 'shopify',
);

export const getIsDefaultIntegration = createSelector(
  getIntegration,
  (integration) => integration === 'default',
);

export const getApplicationVersionOverride = createSelector(
  getFlowState,
  (state) => get(state, 'app_version'),
);

export const getReturnUrl = createSelector(
  getFlowState,
  (state) => get(state, `request.query.${UrlParameters.RETURN_URL}`),
);
