import { push, replace } from 'react-router-redux';
import debugInit from 'debug';

import { getShippingMethodUrlV2 } from '../selectors';
import { RedirectMethod } from '../types';
import { ThunkResult } from '../../types';

const debugLog = debugInit('checkout:actions');

/**
 * Navigates to shipping method page based on the current application state.
 */
export default function visitShippingMethodStep(
  method: RedirectMethod = 'push',
): ThunkResult<void> {
  return function visitShippingMethodStepSideEffects(dispatch, getState): void {
    const state = getState();
    const location = getShippingMethodUrlV2(state);
    debugLog(`visitShippingMethodStep location: ${location}`);

    if (method === 'push') {
      dispatch(push(location));
    } else {
      dispatch(replace(location));
    }
  };
}
