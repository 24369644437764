import type { OrdersDeleteGiftAndCardsByNumberAndIdResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrdersDeleteGiftAndCardsByNumberAndIdResponse>>;

type Error =
  | io.flow.error.v0.models.GenericError
  | LegacyError;

type Parameters = {
  id: string;
}

const removeGiftCardRequest = (
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.REMOVE_GIFT_CARD_REQUEST,
  params: parameters,
});

const removeGiftCardSuccess = (
  parameters: Parameters,
  orderBuilder: io.flow.experience.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.REMOVE_GIFT_CARD_SUCCESS,
  params: parameters,
  payload: orderBuilder,
});

const removeGiftCardFailure = (
  parameters: Parameters,
  error: Error,
): RootAction => ({
  type: actionTypes.REMOVE_GIFT_CARD_FAILURE,
  params: parameters,
  payload: error,
});

const removeGiftCard = (
  organizationId: string,
  orderNumber: string,
  giftCardId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const parameters: Parameters = {
    id: giftCardId,
  };

  dispatch(removeGiftCardRequest(parameters));

  return extra.apiInternal.orders.deleteGiftAndCardsByNumberAndId({
    id: giftCardId,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(removeGiftCardSuccess(parameters, response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(removeGiftCardFailure(parameters, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }

      return response;
    });
};

export default removeGiftCard;
