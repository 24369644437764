/* eslint-disable import/prefer-default-export */
export type CustomerType = 'individual' | 'business';

export interface CustomerTypeEnum {
  INDIVIDUAL: 'individual';
  BUSINESS: 'business';
}

export const CustomerType: CustomerTypeEnum = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
};
