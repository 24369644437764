/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import every from 'lodash/every';
import get from 'lodash/get';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';
import isNil from 'lodash/isNil';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import { connect } from 'react-redux';

import { getCart } from '../../store/cart/selectors';
import Steps from '../../constants/steps';
import { getActiveStep } from '../../store/navigation/selectors';
import { getOrderDenormalized, getOrderError } from '../../store/checkout/selectors';
import { getIsShopifyIntegration } from '../../store/flow/selectors';
import { triggerEvent as triggerExternalEvent } from '../../external/checkout';
import events from '../../utilities/events';

/**
 * We have an idea to add porcelain methods to Flow.js
 * like: Flow.triggerCartUpdated so that this code can call
 * Flow.set('triggerCartUpdated', cart) and not have to maintain list of
 * constants
 * @type {Object}
 */
const FlowJsConstants = {
  PAGEVIEW_CART: 'pageview.cart',
  PAGEVIEW_CHECKOUT_STEP_1: 'pageview.checkout_step_1',
  PAGEVIEW_CHECKOUT_STEP_2: 'pageview.checkout_step_2',
  PAGEVIEW_CHECKOUT_STEP_3: 'pageview.checkout_step_3',
  PAGEVIEW_CHECKOUT_THANK_YOU: 'pageview.checkout_thank_you',
};

function triggerEvent(key, data) {
  if (window.Flow && window.Flow.set) {
    window.Flow.set('on', 'ready', () => {
      window.Flow.set('triggerEvent', key, data);
    });
  }
}

/**
 * Checks to see that shopify data is in the order.
 */
export function isDataValid(data, options = {}) {
  const orderLines = get(data, 'order.lines', []);

  if (options.isShopifyIntegration && orderLines.length > 0) {
    return every(orderLines, (orderLine) => !isNil(orderLine.shopify));
  }

  return true;
}

export const getEventData = (state) => ({
  cart: getCart(state),
  order: getOrderDenormalized(state),
});

function getExernalEventsClass(Component) {
  class ExternalEvents extends React.Component {
    componentDidMount() {
      const { state: appState } = this.props;
      const isShopifyIntegration = getIsShopifyIntegration(appState);
      const data = getEventData(appState);
      const activeStep = getActiveStep(appState);

      if (!isDataValid(data, { isShopifyIntegration })) {
        getRollbar((rollbar, extra = {}) => {
          // warning b/c false positives from people refreshing order confirmation page
          // and cart is expired
          rollbar.warning('Invalid data for pageview event', { ...extra, ...data });
        });
      }

      events.trigger(activeStep);

      switch (activeStep) {
        case Steps.Cart:
          triggerEvent(FlowJsConstants.PAGEVIEW_CART, data);
          break;
        case Steps.ContactInfo:
          triggerEvent(FlowJsConstants.PAGEVIEW_CHECKOUT_STEP_1, data);
          break;
        case Steps.ShippingMethod:
          triggerEvent(FlowJsConstants.PAGEVIEW_CHECKOUT_STEP_2, data);
          break;
        case Steps.PaymentInfo:
          triggerEvent(FlowJsConstants.PAGEVIEW_CHECKOUT_STEP_3, data);
          break;
        case Steps.Confirmation:
          triggerEvent(FlowJsConstants.PAGEVIEW_CHECKOUT_THANK_YOU, data);
          break;
        default: break;
      }
    }

    componentDidUpdate() {
      const { state: appState } = this.props;
      const orderError = getOrderError(appState);

      if (orderError) {
        triggerExternalEvent('error', orderError);
      }
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  ExternalEvents.displayName = `WithExternalEvents(${getDisplayName(Component)})`;

  ExternalEvents.propTypes = {
    state: PropTypes.shape({
      flow: PropTypes.shape({}),
      navigation: PropTypes.shape({}),
    }).isRequired,
  };

  return ExternalEvents;
}

const mapStateToProps = (state) => ({ state });

export default () => (Component) => connect(mapStateToProps)(getExernalEventsClass(Component));
