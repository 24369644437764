import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

export interface Props {
  className?: string;
  content: io.flow.internal.v0.models.ContentLabel;
}

// Cannot be renamed to match component for backward compatibility
// with custom CSS overrides injected by clients.
const bem = new BemHelper('label');

const ContentLabel: React.FunctionComponent<Props> = ({
  className,
  content,
}) => {
  switch (content.format) {
    case 'email':
      return (
        <a href={`mailto:${content.value}`} className={bem.block(className)}>
          {content.value}
        </a>
      );
    case 'phone':
      return (
        <a href={`tel:${content.value}`} className={bem.block(className)}>
          {content.value}
        </a>
      );
    case 'text':
    default:
      return (
        <span className={bem.block(className)}>
          {((): React.ReactNode => {
            switch (content.key) {
              case 'terms_of_use':
                return (
                  <FormattedMessage
                    id="checkout_content_terms_of_use"
                    description="Label referring to a document specifying terms for using the website"
                    defaultMessage="Terms of Use"
                  />
                );
              case 'privacy_policy':
                return (
                  <FormattedMessage
                    id="checkout_content_privacy_policy"
                    description="Label referring to a document outlining the policy of User's privacy"
                    defaultMessage="Privacy Policy"
                  />
                );
              case 'order_summary_message':
                return (
                  <FormattedMessage
                    id="checkout_order_summary_message"
                    description="Content rendered under the order summary section"
                    defaultMessage="This order is final sale, non-refundable and cannot be exchanged. Orders cannot be modified or cancelled after an order has been submitted. Shipping addresses cannot be changed."
                  />
                );
              default:
                return content.value;
            }
          })()}
        </span>
      );
  }
};

ContentLabel.displayName = 'ContentLabel';

export default ContentLabel;
