import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getOrganization } from '../../store/flow/selectors';
import {
  getOrderNumber,
  getOrderTotalAmount,
  getOrderTotalCurrency,
  getOrderBalanceAmount,
  isGiftCardsEnabled,
} from '../../store/checkout/selectors';
import PayPalButton from './paypal-button';

const mapStateToProps = createStructuredSelector({
  balance: getOrderBalanceAmount,
  total: getOrderTotalAmount,
  isGiftCardsEnabled,
  currency: getOrderTotalCurrency,
  organizationId: getOrganization,
  orderNumber: getOrderNumber,
});

export default connect(mapStateToProps)(PayPalButton);
