/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

interface GiftMessagingProps {
  className: string;
}

const GiftMessage = (props: GiftMessagingProps): JSX.Element => (
  <SvgIcon viewBox="0 0 36 24" {...props}>
    <g fillRule="evenodd">
      <path d="m26.75 1c0.74698 0 1.4244 0.29782 1.92 0.7812l-1.1672 1.1667c-0.19677-0.18473-0.46154-0.29788-0.75274-0.29788h-22c-0.60751 0-1.1 0.49249-1.1 1.1v16.5c0 0.60751 0.49249 1.1 1.1 1.1h22c0.60751 0 1.1-0.49249 1.1-1.1v-7.927l1.65-1.65v9.577c0 1.5188-1.2312 2.75-2.75 2.75h-22c-1.5188 0-2.75-1.2312-2.75-2.75v-16.5c0-1.5188 1.2312-2.75 2.75-2.75h22zm-8.7424 13.887 2.4183 2.4183-3.5156 1.0993 1.0972-3.5176zm9.6253-10.124 2.9168 2.9168-8.941 8.941-2.9168-2.9168 8.941-8.941zm-12.914 4.4872c0.56954 0 1.0312 0.46171 1.0312 1.0312 0 0.56954-0.46171 1.0312-1.0312 1.0312h-7.5625c-0.56954 0-1.0312-0.46171-1.0312-1.0312 0-0.56954 0.46171-1.0312 1.0312-1.0312h7.5625zm4.125-4.125c0.56954 0 1.0312 0.46171 1.0312 1.0312s-0.46171 1.0312-1.0312 1.0312h-11.688c-0.56954 0-1.0312-0.46171-1.0312-1.0312s0.46171-1.0312 1.0312-1.0312h11.688zm12.695-3.2052 1.8543 1.8543c0.36986 0.36986 0.0097966 1.0525-0.2238 1.286l-1.6554 1.6554-2.9168-2.9168 1.6554-1.6554c0.2336-0.2336 0.9178-0.59205 1.2863-0.22357z" />
    </g>
  </SvgIcon>
);

GiftMessage.displayName = 'GiftMessage';

export default GiftMessage;
