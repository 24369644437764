import { createSelector } from 'reselect';
import { RootState } from '../../types';
import { OptinState } from '../types';

export const getOptinState = (state: RootState): OptinState => state.optin;

export const getOptinPrompts = createSelector(
  getOptinState,
  (state) => state.entities.prompts,
);
