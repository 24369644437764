/* eslint-disable react/jsx-props-no-spreading */

import { push, replace } from 'react-router-redux';
import React from 'react';
import StaticContainer from 'react-static-container';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';
import { connect } from 'react-redux';

/**
 * A higher order component that redirects to a path if predicate
 * returns true.
 * @param {String|Object|Function} location The location to redirect
 * to. If specified as a function, it will be invoked with the current
 * application state tree and props passed to the wrapped component
 * as parameters, and return a location string or location descriptor
 * acceptable by React Router Redux push action.
 * @param {Function} predicate A function responsible for indicating
 * whether the user agent should be redirected. It will be invoked
 * with the current application state tree and props passed to the
 * wrapped component as parameters.
 * @param {Boolean} [options.replace]
 * @param {Boolean} [options.reload]
 * @return {Function}
 */
export default function withRedirect(location, predicate, options = {}) {
  return function createContainer(Component) {
    class Redirect extends React.Component {
      constructor(props, context) {
        super(props, context);

        this.state = {
          isRedirecting: false,
        };
      }

      UNSAFE_componentWillMount() {
        this.redirectIfNecessary(this.props);
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        this.redirectIfNecessary(nextProps);
      }

      redirectIfNecessary(props) {
        const { isRedirecting } = this.state;

        if (!isRedirecting) {
          const { dispatch, state } = props;

          if (predicate(state, props)) {
            this.setState({ isRedirecting: true });
            const url = (typeof location === 'function') ? location(state, props) : location;

            if (options.reload) {
              window.location.href = url;
            } else if (options.replace) {
              dispatch(replace(url));
            } else {
              dispatch(push(url));
            }
          }
        }
      }

      render() {
        const { isRedirecting } = this.state;
        return (
          <StaticContainer shouldUpdate={!isRedirecting}>
            <Component {...this.props} />
          </StaticContainer>
        );
      }
    }

    Redirect.displayName = `WithRedirect(${getDisplayName(Component)})`;

    const mapStateToProps = (state) => ({ state });

    return connect(mapStateToProps)(Redirect);
  };
}
