import { defineMessages } from 'react-intl';

const messages = defineMessages({
  vrnErrorMessage: {
    id: 'checkout_error_vrn',
    description: 'Error that shows when vrn is invalid',
    defaultMessage: 'VAT Number or Company Name invalid',
  },
  vrnValidationErrorMessage: {
    id: 'checkout_error_validate_vrn',
    description: 'Error that shows when the VRN could not be validated',
    defaultMessage: 'Unable to validate VAT information, try again later',
  },
});

export default messages;
