/* eslint-disable react/jsx-props-no-spreading */
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getElementType, getUnhandledProps, isElementOfType } from '@flowio/react-helpers';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import noop from 'lodash/noop';

import RadioButton from './radio-button';

if (process.browser) {
  require('./radio-button-group.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('radio-button-group');

class RadioButtonGroup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { value, defaultValue } = this.props;
    return {
      controlled: !isNil(value),
      value: value || defaultValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value: prevValue } = this.props;
    const { value: nextValue } = nextProps;

    if (!isNil(nextValue) && nextValue !== prevValue) {
      this.setState({ controlled: true, value: nextValue });
    }
  }

  handleChange = (event, value) => {
    const { onChange } = this.props;
    const { controlled } = this.state;

    if (!controlled) {
      this.setState({ value });
    }

    onChange(event, value);
  }

  renderChildren() {
    const {
      children, disabled, fluid, name,
    } = this.props;
    const { value } = this.state;

    return React.Children.map(children, (child) => {
      // Make sure child is a valid component.
      if (!React.isValidElement(child)) {
        return child;
      }

      if (!isElementOfType(child, RadioButton)) {
        return child;
      }

      const props = {
        checked: child.props.value && child.props.value === value,
        disabled,
        fluid,
        onChange: this.handleChange,
        name,
      };

      return React.cloneElement(child, props);
    });
  }

  render() {
    const {
      bordered, children, className, disabled, fluid, name, options, automationId,
    } = this.props;
    const { value } = this.state;
    const ElementType = getElementType(RadioButtonGroup, this.props);
    const unhandledProps = getUnhandledProps(RadioButtonGroup, this.props);
    const modifiers = { bordered, fluid };

    if (!isNil(children)) {
      return (
        <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
          {this.renderChildren()}
        </ElementType>
      );
    }

    return (
      <ElementType
        {...unhandledProps}
        className={bem.block(modifiers, className)}
        data-automation-id={automationId}
      >
        {map(options, (option) => (
          <RadioButton
            {...option}
            key={option.value}
            checked={value === option.value}
            disabled={disabled}
            fluid={fluid}
            name={name}
            onChange={this.handleChange}
          />
        ))}
      </ElementType>
    );
  }
}

RadioButtonGroup.displayName = 'RadioButtonGroup';

RadioButtonGroup.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  automationId: PropTypes.string,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    as: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    checked: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    id: PropTypes.string,
    labelText: PropTypes.node,
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  })),
  value: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  as: 'div',
  automationId: undefined,
  bordered: false,
  children: undefined,
  className: '',
  defaultValue: undefined,
  disabled: false,
  fluid: false,
  name: undefined,
  onChange: noop,
  options: [],
  value: undefined,
};

export default RadioButtonGroup;
