/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

if (process.browser) {
  require('./column.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('column');

const numberToStringMap = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
};

const ColumnWidthType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

class Column extends PureComponent {
  render() {
    const {
      className,
      children,
      xs, xsCenter, xsHidden, xsPrefix, xsPull, xsPush, xsSuffix,
      sm, smCenter, smHidden, smPrefix, smPull, smPush, smSuffix,
      md, mdCenter, mdHidden, mdPrefix, mdPull, mdPush, mdSuffix,
      lg, lgCenter, lgHidden, lgPrefix, lgPull, lgPush, lgSuffix,
    } = this.props;
    const ElementType = getElementType(Column, this.props);
    const unhandledProps = getUnhandledProps(Column, this.props);
    const modifiers = {
      [`xs-${numberToStringMap[xs]}`]: xs !== undefined,
      'xs-center': xsCenter === true,
      'xs-hidden': xsHidden === true,
      [`xs-prefix-${numberToStringMap[xsPrefix]}`]: xsPrefix !== undefined,
      [`xs-pull-${numberToStringMap[xsPull]}`]: xsPull !== undefined,
      [`xs-push-${numberToStringMap[xsPush]}`]: xsPush !== undefined,
      [`xs-suffix-${numberToStringMap[xsSuffix]}`]: xsSuffix !== undefined,
      [`sm-${numberToStringMap[sm]}`]: sm !== undefined,
      'sm-center': smCenter === true,
      'sm-hidden': smHidden === true,
      [`sm-prefix-${numberToStringMap[smPrefix]}`]: smPrefix !== undefined,
      [`sm-pull-${numberToStringMap[smPull]}`]: smPull !== undefined,
      [`sm-push-${numberToStringMap[smPush]}`]: smPush !== undefined,
      [`sm-suffix-${numberToStringMap[smSuffix]}`]: smSuffix !== undefined,
      [`md-${numberToStringMap[md]}`]: md !== undefined,
      'md-center': mdCenter === true,
      'md-hidden': mdHidden === true,
      [`md-prefix-${numberToStringMap[mdPrefix]}`]: mdPrefix !== undefined,
      [`md-pull-${numberToStringMap[mdPull]}`]: mdPull !== undefined,
      [`md-push-${numberToStringMap[mdPush]}`]: mdPush !== undefined,
      [`md-suffix-${numberToStringMap[mdSuffix]}`]: mdSuffix !== undefined,
      [`lg-${numberToStringMap[lg]}`]: lg !== undefined,
      'lg-center': lgCenter === true,
      'lg-hidden': lgHidden === true,
      [`lg-prefix-${numberToStringMap[lgPrefix]}`]: lgPrefix !== undefined,
      [`lg-pull-${numberToStringMap[lgPull]}`]: lgPull !== undefined,
      [`lg-push-${numberToStringMap[lgPush]}`]: lgPush !== undefined,
      [`lg-suffix-${numberToStringMap[lgSuffix]}`]: lgSuffix !== undefined,
    };

    return (
      <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
        {children}
      </ElementType>
    );
  }
}

Column.displayName = 'Column';

Column.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  xs: ColumnWidthType,
  xsCenter: PropTypes.bool,
  xsHidden: PropTypes.bool,
  xsPrefix: ColumnWidthType,
  xsPull: ColumnWidthType,
  xsPush: ColumnWidthType,
  xsSuffix: ColumnWidthType,
  sm: ColumnWidthType,
  smCenter: PropTypes.bool,
  smHidden: PropTypes.bool,
  smPrefix: ColumnWidthType,
  smPull: ColumnWidthType,
  smPush: ColumnWidthType,
  smSuffix: ColumnWidthType,
  md: ColumnWidthType,
  mdCenter: PropTypes.bool,
  mdHidden: PropTypes.bool,
  mdPrefix: ColumnWidthType,
  mdPull: ColumnWidthType,
  mdPush: ColumnWidthType,
  mdSuffix: ColumnWidthType,
  lg: ColumnWidthType,
  lgCenter: PropTypes.bool,
  lgHidden: PropTypes.bool,
  lgPrefix: ColumnWidthType,
  lgPull: ColumnWidthType,
  lgPush: ColumnWidthType,
  lgSuffix: ColumnWidthType,
};

Column.defaultProps = {
  as: 'div',
  className: '',
  children: undefined,
  xs: undefined,
  xsCenter: false,
  xsHidden: false,
  xsPrefix: undefined,
  xsPull: undefined,
  xsSuffix: undefined,
  xsPush: undefined,
  sm: undefined,
  smCenter: false,
  smHidden: false,
  smPrefix: undefined,
  smPull: undefined,
  smPush: undefined,
  smSuffix: undefined,
  md: undefined,
  mdCenter: false,
  mdHidden: false,
  mdPrefix: undefined,
  mdPull: undefined,
  mdPush: undefined,
  mdSuffix: undefined,
  lg: undefined,
  lgCenter: false,
  lgHidden: false,
  lgPrefix: undefined,
  lgPull: undefined,
  lgPush: undefined,
  lgSuffix: undefined,
};

export default Column;
