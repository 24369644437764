import request from '@flowio/request';
import actionTypes from '../../constants/action-types';
import type { OrganizationConfiguration, OrganizationConfigurationLegacyResponse } from '../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<OrganizationConfigurationLegacyResponse>;

const fetchOrganizationConfigRequest = (): RootAction => ({
  type: actionTypes.FETCH_ORGANIZATION_CONFIG_REQUEST,
});

const fetchOrganizationConfigSuccess = (
  configuration: OrganizationConfiguration,
): RootAction => ({
  type: actionTypes.FETCH_ORGANIZATION_CONFIG_SUCCESS,
  payload: configuration,
});

const fetchOrganizationConfigFailure = (): RootAction => ({
  type: actionTypes.FETCH_ORGANIZATION_CONFIG_FAILURE,
});

const fetchOrganizationConfig = (
  organizationId: string,
  pathPrefix: string,
): ThunkResult<Result> => (dispatch) => {
  dispatch(fetchOrganizationConfigRequest());

  return new Promise((resolve) => {
    const url = `${pathPrefix}/${organizationId}/config`;
    request.anonymous({
      method: 'GET',
      url,
    }, (status: number, result: OrganizationConfiguration) => {
      const response: OrganizationConfigurationLegacyResponse = {
        status,
        result,
        ok: status >= 200 && status < 300,
      };

      if (response.ok) {
        dispatch(fetchOrganizationConfigSuccess(result));
      } else {
        dispatch(fetchOrganizationConfigFailure());
      }

      resolve(response);
    });
  });
};

export default fetchOrganizationConfig;
