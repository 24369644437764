/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import React, { ReactEventHandler, SyntheticEvent } from 'react';
import { trackHeapEvent } from '../../utilities/heap';
import isNotNil from '../../utilities/is-not-nil';

const bem = new BemHelper('payment-method-issuer');

type PaymentMethodIssuerOptionProps = {
  name: string;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  paymentMethod: io.flow.v0.models.PaymentMethod;
  paymentMethodIssuer: io.flow.v0.models.PaymentMethodIssuer;
};

function getImgUrl(logo?: io.flow.v0.unions.LogoImage): string | undefined {
  if (isNotNil(logo)) {
    switch (logo.discriminator) {
      case 'svg':
        return logo.url;
      case 'static':
        return logo.medium.url;
      default:
    }
  }
  return undefined;
}

const PaymentMethodIssuerOption: React.FC<PaymentMethodIssuerOptionProps> = ({
  name,
  onBlur,
  onFocus,
  paymentMethod: {
    id: paymentMethodId,
  },
  paymentMethodIssuer: {
    id: paymentMethodIssuerId,
    name: paymentMethodIssuerName,
    logo: paymentMethodIssuerLogo,
  },
  ...unhandledProps
}) => {
  const handleInputBlur = React.useCallback((event) => {
    trackHeapEvent('field_blur', {
      field: name,
    });

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }, [name, onBlur]);

  const handleInputFocus = React.useCallback((event) => {
    trackHeapEvent('field_focus', {
      field: name,
    });

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }, [name, onFocus]);

  const handleImageOnError = (e: SyntheticEvent<HTMLImageElement>) => {
    // hide issuer logos that do not load.
    e.currentTarget.style.visibility = 'hidden';
  };

  return (
    <div className={bem.element('option')}>
      <input
        {...unhandledProps}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        type="radio"
        className={bem.element('option-input')}
        id={paymentMethodIssuerId}
      />
      <label className={bem.element('option-label')} htmlFor={paymentMethodIssuerId}>
        <img
          className={bem.element('option-image')}
          onError={handleImageOnError}
          src={getImgUrl(paymentMethodIssuerLogo) || `//cdn.flow.io/util/logos/payment-methods/${paymentMethodId}/issuers/120/${paymentMethodIssuerId}.png`}
          alt={paymentMethodIssuerName}
        />
        {paymentMethodIssuerName}
      </label>
    </div>
  );
};

PaymentMethodIssuerOption.displayName = 'PaymentMethodIssuerOption';

PaymentMethodIssuerOption.defaultProps = {
  name: undefined,
  onBlur: undefined,
  onFocus: undefined,
};

export default PaymentMethodIssuerOption;
