/**
 * @fileoverview
 * A route container intended to be used to finalize submission of an order being paid with a
 * supported online payment method.
 */

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { createStructuredSelector } from 'reselect';
import isNil from 'lodash/isNil';

import { fetchCheckout, redirectSubmittedOrder } from '../store/checkout/actions';
import { is3DSPendingPageEnabled, getAuthorizations } from '../store/checkout/selectors';
import PendingPage from '../components/pending-page';
import checkHttpStatus from '../utilities/check-http-status';
import getFeatureKeys from '../utilities/get-feature-keys';
import checkoutsProcessOnlineAuthorization from '../store/checkout/actions/checkouts-process-online-authorization';

function fetchAsyncState(dispatch, getState, props) {
  const { checkoutId } = props.params;

  return dispatch(fetchCheckout(checkoutId, { feature_key: getFeatureKeys() }))
    .then((response) => {
      if (!isNil(response)) {
        checkHttpStatus(response);
      }
    })
    .then(() => dispatch(redirectSubmittedOrder()));
}

const mapDispatchToProps = (dispatch, props) => {
  const { location, params } = props;
  const { checkoutId } = params;
  const { authorization_key: authorizationKey } = location.query;
  return {
    onMount() {
      dispatch(checkoutsProcessOnlineAuthorization(checkoutId, authorizationKey));
    },
  };
};

const mapStateToProps = createStructuredSelector({
  is3DSPendingPageEnabled,
  authorizations: getAuthorizations,
});

export default compose(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PendingPage);
