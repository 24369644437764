import { mountable } from '@flowio/react-prop-types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import getOwnerDocument from '@flowio/browser-helpers/lib/getOwnerDocument';

class Portal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      overlayTarget: null,
    };
  }

  componentDidMount() {
    this.setState({
      overlayTarget: this.getContainerDOMNode(),
    });
  }

  /**
   * Returns the DOM element where the children of this component are appended.
   * @return {DOMElement}
   */
  getContainerDOMNode() {
    const { container } = this.props;

    if (container) {
      if (typeof container === 'function') {
        // eslint-disable-next-line react/no-find-dom-node
        return ReactDOM.findDOMNode(container());
      }
      // eslint-disable-next-line react/no-find-dom-node
      return ReactDOM.findDOMNode(container);
    }
    // eslint-disable-next-line react/no-find-dom-node
    return getOwnerDocument(ReactDOM.findDOMNode(this)).body;
  }

  render() {
    const { overlayTarget } = this.state;
    const { children } = this.props;
    return overlayTarget
      ? ReactDOM.createPortal(
        <div className="portal">
          {children}
        </div>,
        overlayTarget,
      )
      : null;
  }
}

Portal.displayName = 'Portal';

Portal.propTypes = {
  children: PropTypes.node,
  container: PropTypes.oneOfType([mountable, PropTypes.func]),
};

Portal.defaultProps = {
  children: undefined,
  container: undefined,
};

export default Portal;
