/* eslint-disable react/prefer-stateless-function */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';

export default function injectFlow(WrappedComponent) {
  class WithFlow extends Component {
    render() {
      const { flow } = this.context;
      const props = { ...this.props, flow };
      return React.createElement(WrappedComponent, props);
    }
  }

  WithFlow.displayName = `Flow(${getDisplayName(Component)})`;

  WithFlow.contextTypes = {
    flow: PropTypes.shape({
      createElement: PropTypes.func,
      createToken: PropTypes.func,
    }),
  };

  return WithFlow;
}
