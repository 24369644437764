import actionTypes from '../../constants/action-types';
import LoadingIndicator from '../../../constants/loading-indicator';
import type { AppReducer } from '../../types';

const initialState = LoadingIndicator.Hidden;

const loadingIndicatorReducer: AppReducer<LoadingIndicator> = (
  previouState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADING_INDICATOR:
      return LoadingIndicator.Visible;
    case actionTypes.HIDE_LOADING_INDICATOR:
      return LoadingIndicator.Hidden;
    default:
      return previouState;
  }
};

export default loadingIndicatorReducer;
