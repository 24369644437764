import {
  KeyCodes,
  contains,
  isActiveElement,
  isElement,
  getKeyCode,
  isElementInViewport,
} from '@flowio/browser-helpers';
import { Transition } from 'react-transition-group';
import debounce from 'lodash/debounce';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import ReactDOM from 'react-dom';
import Tether from '@flowio/react-tether';

import { Position } from './position';
import AutoLockScrolling from '../auto-lock-scrolling';
import EventListener from '../event-listener';
import getPopoverAttachment from './get-popover-attachment';
import getTriggerAttachment from './get-trigger-attachment';
import triggerReflow from '../../utilities/trigger-reflow';
import Portal from '../portal';

import './popover.css';

const defaultProps = {
  closeOnClickAway: true,
  closeOnEscape: true,
  closeOnInvisible: false,
  closeOnResize: false,
  closeOnScroll: false,
  constraint: 'flipfit flipfit',
  defaultOpen: false,
  disabled: false,
  hoverCloseDelay: 300,
  hoverOpenDelay: 150,
  inline: false,
  lockScrolling: true,
  offset: '0px 2px',
  openOnClick: false,
  openOnFocus: false,
  openOnHover: false,
  openOnSpace: false,
  position: 'bottom left' as Position,
};

type DefaultProps = typeof defaultProps;

type OwnProps = {
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  trigger: React.ReactNode;
} & DefaultProps;

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLDivElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

type State = {
  isOpen: boolean;
};

const bem = new BemHelper('flow-popover');

class Popover extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  static Position = Position;

  closeTimeoutId?: ReturnType<typeof setTimeout>;

  openTimeoutId?: ReturnType<typeof setTimeout>;

  popoverRef: React.RefObject<HTMLDivElement>;

  // I guess we need to stop defining sub-components as static members.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  triggerRef: React.RefObject<Tether.Anchor>;

  constructor(props: Props) {
    super(props);

    const {
      open,
      defaultOpen,
      disabled,
    } = this.props;

    this.state = {
      isOpen: open != null ? open : defaultOpen && !disabled,
    };

    this.popoverRef = React.createRef();
    this.triggerRef = React.createRef();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleDocumentResize = debounce(this.handleDocumentResize.bind(this), 250);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleDocumentScroll = debounce(this.handleDocumentScroll.bind(this), 250);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleOverlayClose = this.handleOverlayClose.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handlePopoverMouseEnter = this.handlePopoverMouseEnter.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handlePopoverMouseLeave = this.handlePopoverMouseLeave.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerBlur = this.handleTriggerBlur.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerFocus = this.handleTriggerFocus.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerKeyDown = this.handleTriggerKeyDown.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerMouseEnter = this.handleTriggerMouseEnter.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleTriggerMouseLeave = this.handleTriggerMouseLeave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const {
      open: nextOpen,
    } = nextProps;

    if (nextOpen != null) {
      this.setState({
        isOpen: nextOpen,
      });
    }
  }

  handleDocumentClick(event: MouseEvent): void {
    this.closeOnClickAway(event);
  }

  handleDocumentKeyDown(event: KeyboardEvent): void {
    this.closeOnEscape(event);
  }

  handleDocumentScroll(): void {
    this.closeOnScroll();
    this.closeOnInvisible();
  }

  handleDocumentResize(): void {
    this.closeOnResize();
    this.closeOnInvisible();
  }

  handleOverlayClose(event: Event): void {
    // eslint-disable-next-line react/no-find-dom-node
    const triggerElement = ReactDOM.findDOMNode(this.triggerRef.current);
    const isClickAway = event.type === 'click';
    const isTriggerElement = isElement(event.target)
      && (event.target === triggerElement || contains(triggerElement, event.target));

    // Overlay will request to be closed when an element other than itself is
    // clicked. If the next element to receive a click event is the trigger
    // element, leave the popover open.
    if (!isClickAway || !isTriggerElement) {
      this.close();
    }
  }

  handlePopoverMouseEnter(): void {
    this.openOnMouseEnter();
  }

  handlePopoverMouseLeave(): void {
    this.closeOnMouseLeave();
  }

  handleTriggerBlur(): void {
    this.closeOnBlur();
  }

  handleTriggerClick(event: React.MouseEvent): void {
    this.openOnClick(event);
  }

  handleTriggerFocus(): void {
    this.openOnFocus();
  }

  handleTriggerKeyDown(event: React.KeyboardEvent): void {
    this.openOnSpace(event);
  }

  handleTriggerMouseEnter(): void {
    this.openOnMouseEnter();
  }

  handleTriggerMouseLeave(): void {
    this.closeOnMouseLeave();
  }

  isControlled(): boolean {
    const { open } = this.props;
    return open != null;
  }

  clearTimeouts(): void {
    if (this.openTimeoutId != null) {
      clearTimeout(this.openTimeoutId);
    }

    if (this.closeTimeoutId != null) {
      clearTimeout(this.closeTimeoutId);
    }
  }

  close(timeout = 0): void {
    const { onClose, openOnFocus } = this.props;
    const { isOpen } = this.state;
    // eslint-disable-next-line react/no-find-dom-node
    const triggerNode = ReactDOM.findDOMNode(this.triggerRef.current);
    const popoverNode = this.popoverRef.current;
    const isTriggerFocused = isElement(triggerNode) && isActiveElement(triggerNode);
    const isPopoverFocused = popoverNode != null && isActiveElement(popoverNode);
    const isFocused = isTriggerFocused || isPopoverFocused;
    // Avoid closing when popover is already closed.
    // Avoid closing when popover should be open while focused.
    const shouldClose = isOpen && !(openOnFocus && isFocused);

    this.clearTimeouts();

    if (timeout > 0) {
      this.closeTimeoutId = setTimeout(() => {
        this.close();
      }, timeout);
    } else if (shouldClose) {
      if (!this.isControlled()) {
        this.setState({ isOpen: false });
      }

      if (typeof onClose === 'function') {
        onClose();
      }
    }
  }

  closeOnBlur(): void {
    const { openOnFocus } = this.props;

    if (openOnFocus) {
      // If the next element to receive focus is within the popover, leave the
      // popover open. We must do this check *after* the next element focuses,
      // so we defer it to flush the rest of the event queue before proceeding.
      // *The check is handled inside the close function*.
      setTimeout(() => {
        this.close();
      }, 10);
    }
  }

  closeOnClickAway(event: MouseEvent): void {
    const { closeOnClickAway } = this.props;
    const clickInPopover = contains(this.popoverRef.current, event.target as Node);
    if (closeOnClickAway && !clickInPopover) {
      this.close();
    }
  }

  closeOnEscape(event: KeyboardEvent): void {
    const { closeOnEscape } = this.props;
    if (closeOnEscape && getKeyCode(event) === KeyCodes.Escape) {
      this.close();
    }
  }

  closeOnInvisible(): void {
    const { closeOnInvisible } = this.props;
    if (closeOnInvisible
        && this.popoverRef.current != null
        && !isElementInViewport(this.popoverRef.current)) {
      this.close();
    }
  }

  closeOnResize(): void {
    const { closeOnResize } = this.props;
    if (closeOnResize) {
      this.close();
    }
  }

  closeOnScroll(): void {
    const { closeOnScroll } = this.props;
    if (closeOnScroll) {
      this.close();
    }
  }

  closeOnMouseLeave(): void {
    const {
      hoverCloseDelay,
      openOnHover,
    } = this.props;

    if (openOnHover) {
      this.close(hoverCloseDelay);
    }
  }

  open(timeout = 0): void {
    const { onOpen } = this.props;
    const { isOpen } = this.state;

    // Avoid opening when popover is already opened.
    const shouldOpen = !isOpen;

    this.clearTimeouts();

    if (timeout > 0) {
      this.openTimeoutId = setTimeout(() => {
        this.open();
      }, timeout);
    } else if (shouldOpen) {
      if (!this.isControlled()) {
        this.setState({ isOpen: true });
      }

      if (typeof onOpen === 'function') {
        onOpen();
      }
    }
  }

  openOnClick(event: React.MouseEvent): void {
    const {
      disabled,
      openOnClick,
    } = this.props;

    if (!disabled && openOnClick) {
      event.stopPropagation();
      this.open();
    }
  }

  openOnFocus(): void {
    const {
      disabled,
      openOnFocus,
    } = this.props;

    if (!disabled && openOnFocus) {
      this.open();
    }
  }

  openOnMouseEnter(): void {
    const {
      disabled,
      hoverOpenDelay,
      openOnHover,
    } = this.props;

    if (!disabled && openOnHover) {
      this.open(hoverOpenDelay);
    }
  }

  openOnSpace(event: React.KeyboardEvent): void {
    const {
      disabled,
      openOnSpace,
    } = this.props;

    const keyCode = event.keyCode || event.which;

    if (!disabled && openOnSpace && keyCode === KeyCodes.Space) {
      this.open();
    }
  }

  render(): React.ReactNode {
    const {
      closeOnClickAway,
      closeOnEscape,
      closeOnInvisible,
      closeOnResize,
      closeOnScroll,
      constraint,
      defaultOpen,
      disabled,
      hoverCloseDelay,
      hoverOpenDelay,
      inline,
      lockScrolling,
      offset,
      openOnClick,
      openOnFocus,
      openOnHover,
      openOnSpace,
      position,
      trigger,
      children,
      className,
      content,
      onClose,
      onOpen,
      open,
      ...unhandledProps
    } = this.props;

    const { isOpen } = this.state;

    // TODO: Inline popovers are not positioned correctly. By default, tether
    // will compute the popover position relative to the viewport, unless the
    // popover offset parent is provided to the `withinElement` property.
    // Perhaps, tether should be updated to auto detect whether its target
    // component should be positioned relative to some offset parent container
    // and fallback to the viewport.
    return (
      <Tether
        anchorAttachment={getTriggerAttachment(position)}
        constraint={constraint}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onBlur={this.handleTriggerBlur}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onClick={this.handleTriggerClick}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onFocus={this.handleTriggerFocus}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onKeyDown={this.handleTriggerKeyDown}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onMouseEnter={this.handleTriggerMouseEnter}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onMouseLeave={this.handleTriggerMouseLeave}
        targetAttachment={getPopoverAttachment(position)}
        targetOffset={offset}
      >
        <AutoLockScrolling locked={isOpen && lockScrolling} />
        <EventListener
          target="document"
          type="click"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          listener={this.handleDocumentClick}
        />
        <EventListener
          target="document"
          type="keydown"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          listener={this.handleDocumentKeyDown}
        />
        <EventListener
          target="document"
          type="scroll"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          listener={this.handleDocumentScroll}
        />
        <EventListener
          target="document"
          type="resize"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          listener={this.handleDocumentResize}
        />
        <Tether.Anchor
          // eslint-disable-next-line @typescript-eslint/unbound-method
          ref={this.triggerRef}
        >
          {trigger}
        </Tether.Anchor>
        <Portal>
          <Transition
            appear
            in={isOpen}
            timeout={250}
            onEnter={triggerReflow}
            onExit={triggerReflow}
            mountOnEnter
            unmountOnExit
          >
            {(transitionStatus): React.ReactElement => (
              <Tether.Target>
                {(tether): React.ReactElement => (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...unhandledProps}
                    className={bem.block({
                      entering: transitionStatus === 'entering',
                      entered: transitionStatus === 'entered',
                    }, className)}
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    onMouseEnter={this.handlePopoverMouseEnter}
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    onMouseLeave={this.handlePopoverMouseLeave}
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    ref={this.popoverRef}
                    style={tether.position}
                  >
                    {children != null ? children : content}
                  </div>
                )}
              </Tether.Target>
            )}
          </Transition>
        </Portal>
      </Tether>
    );
  }
}

export default Popover;
