import { createSelector } from 'reselect';

import { ApplicationState } from '../types';
import { RootState } from '../../types';
import LoadingIndicator from '../../../constants/loading-indicator';

export const getApplicationState = (
  state: RootState,
): ApplicationState => state.application;

export const getIsLoading = createSelector(
  getApplicationState,
  (state) => state.loadingIndicator === LoadingIndicator.Visible,
);

export const getRedirectLocation = createSelector(
  getApplicationState,
  (state) => state.redirect.url,
);

export const getIsRedirecting = createSelector(
  getRedirectLocation,
  (location) => location != null,
);
