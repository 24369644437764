import { autofill, submit } from 'redux-form';
import { getAdministrativeDivisions } from '../../reference/selectors';
import FormName from '../../../constants/form-name';
import { PayPalPayerInfo } from '../../../types';
import { ThunkResult } from '../../types';

export default function applyPayPalInfoToForm(
  payerInfo: PayPalPayerInfo, countryCode?: string,
): ThunkResult<void> {
  return function applyPayPalInfoToFormSideEffects(
    dispatch, getState,
  ): void {
    const formName = FormName.CUSTOMER_INFORMATION;
    const provinces = getAdministrativeDivisions(getState());
    const provinceKeys = Object.keys(provinces);

    if (countryCode != null) dispatch(autofill(formName, 'country', countryCode));

    dispatch(autofill(formName, 'email', payerInfo.email));
    dispatch(autofill(formName, 'phoneNumber', payerInfo.phone));

    if (payerInfo.shipping_address != null) {
      const recipientName = payerInfo.shipping_address.recipient_name?.split(' ');
      const nameLength = recipientName?.length || 0;
      let paypalState = payerInfo.shipping_address.state;

      const activeProvinceName = provinceKeys
        .find((provinceKey) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          provinces[provinceKey].name.toLowerCase() === paypalState.toLowerCase()
        ));

      if (activeProvinceName != null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        paypalState = provinces[activeProvinceName].iso_3166_2;
      }

      dispatch(autofill(formName, 'firstName', recipientName && recipientName[0]));
      dispatch(autofill(formName, 'lastName', nameLength >= 2 && recipientName && recipientName[nameLength - 1]));
      dispatch(autofill(formName, 'addressLine1', payerInfo.shipping_address.line1));
      dispatch(autofill(formName, 'addressLine2', payerInfo.shipping_address.line2));
      dispatch(autofill(formName, 'locality', payerInfo.shipping_address.city));
      dispatch(autofill(formName, 'postalCode', payerInfo.shipping_address.postal_code));
      dispatch(autofill(formName, 'administrativeArea', paypalState));
    }

    dispatch(submit(formName));
  };
}
