/* eslint-disable global-require */

import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import size from 'lodash/size';

import handleEnterKeyboardEvent from '../../utilities/handle-enter-keyboard-event';

if (process.browser) {
  require('./delivery-items-summary.css');
}

// Base class name differs from component name for backward compatibility
const bem = new BemHelper('order-delivery-options');

class DeliveryItemsSummary extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleClickShowMore = this.handleClickShowMore.bind(this);
  }

  getInitialState() {
    return { showRemainingItems: false };
  }

  handleClickShowMore() {
    this.setState({ showRemainingItems: true });
  }

  render() {
    const { delivery } = this.props;
    const { showRemainingItems } = this.state;
    const deliveryItems = get(delivery, 'items');
    const deliveryItemCount = size(deliveryItems);
    const hasMultipleDeliveryItems = deliveryItemCount > 1;

    if (!hasMultipleDeliveryItems) {
      return (
        <p className={bem.element('shipment-content')}>
          {get(head(deliveryItems), 'shopify.product_title')}
        </p>
      );
    }

    if (showRemainingItems) {
      return (
        <ul className={bem.element('shipment-list')}>
          {map(deliveryItems, (deliveryItem, index) => (
            <li key={index} className={bem.element('shipment-item')}>
              {get(deliveryItem, 'shopify.product_title')}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <p className={bem.element('shipment-content')}>
        <FormattedMessage
          id="checkout_shipment_conjuction"
          description="A conjuction connecting the displayed item to the number of additional items in the shipment."
          defaultMessage="{deliveryItems} and {otherItems}"
          values={{
            deliveryItems: get(head(deliveryItems), 'shopify.product_title'),
            otherItems: (
              <span
                className={bem.element('shipment-more')}
                onClick={this.handleClickShowMore}
                onKeyPress={handleEnterKeyboardEvent(this.handleClickShowMore)}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage
                  id="checkout_shipment_includes_additional_items_label"
                  description="Text telling the user that a shippment includes additional items beyond the items listed."
                  defaultMessage="{numberOfItems} more"
                  values={{ numberOfItems: deliveryItemCount - 1 }}
                />
              </span>
            ),
          }}
        />
      </p>
    );
  }
}

DeliveryItemsSummary.displayName = 'DeliveryItemsSummary';

DeliveryItemsSummary.propTypes = {
  delivery: ApiPropTypes.delivery.isRequired,
};

export default DeliveryItemsSummary;
