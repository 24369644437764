import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { CheckoutDialogsState } from '../types';
import type { AppReducer } from '../../types';

const initialState: CheckoutDialogsState = {
  domesticShippingUnavailable: {
    isOpen: false,
  },
  editAddressBookContact: {
    isOpen: false,
  },
  deliveredDuty: {
    visibility: 'hidden',
    enabled: true,
    error: undefined,
  },
  inlineAuthorization: {
    isOpen: false,
  },
  klarna: {
    visibility: 'hidden',
    isOpen: false,
    rendering: false,
  },
  restrictedItems: {
    isOpen: false,
    numbers: [],
  },
  adyen3ds: {
    isOpen: false,
  },
};

const dialogsReducer: AppReducer<CheckoutDialogsState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.HIDE_DOMESTIC_SHIPPING_UNAVAILABLE_DIALOG:
      return update(state, {
        domesticShippingUnavailable: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.SHOW_DOMESTIC_SHIPPING_UNAVAILABLE_DIALOG:
      return update(state, {
        domesticShippingUnavailable: {
          isOpen: { $set: true },
          orderError: { $set: action.payload },
        },
      });
    case actionTypes.EDIT_ADDRESS_BOOK_CONTACT:
      return update(state, {
        editAddressBookContact: {
          isOpen: { $set: true },
          contact: { $set: action.payload.contact },
          customer: { $set: action.payload.customer },
          organizationId: { $set: action.payload.organizationId },
        },
      });
    case actionTypes.CLOSE_EDIT_CUSTOMER_ADDRESS_BOOK_CONTACT_DIALOG:
      return update(state, {
        editAddressBookContact: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.UPDATE_DELIVERED_DUTY_REQUEST:
      return update(state, {
        deliveredDuty: {
          error: { $set: undefined },
        },
      });
    case actionTypes.UPDATE_DELIVERED_DUTY_FAILURE:
      return update(state, {
        deliveredDuty: {
          error: { $set: action.payload },
        },
      });
    case actionTypes.HIDE_DELIVERED_DUTY_DIALOG:
      return update(state, {
        deliveredDuty: {
          error: { $set: undefined },
          visibility: { $set: 'hidden' },
        },
      });
    case actionTypes.SHOW_DELIVERED_DUTY_DIALOG:
      return update(state, {
        deliveredDuty: {
          visibility: { $set: 'visible' },
        },
      });
    case actionTypes.OPEN_KLARNA_DIALOG:
      return update(state, {
        klarna: {
          visibility: { $set: 'visible' },
          isOpen: { $set: true },
        },
      });
    case actionTypes.CLOSE_KLARNA_DIALOG:
      return update(state, {
        klarna: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.HIDE_KLARNA_DIALOG:
      return update(state, {
        klarna: {
          visibility: { $set: 'hidden' },
        },
      });
    case actionTypes.START_KLARNA_RENDER:
      return update(state, {
        klarna: {
          rendering: { $set: true },
        },
      });
    case actionTypes.FINISHED_KLARNA_RENDER:
      return update(state, {
        klarna: {
          rendering: { $set: false },
        },
      });
    case actionTypes.ENABLE_DELIVERED_DUTY_DIALOG:
      return update(state, {
        deliveredDuty: {
          enabled: { $set: true },
        },
      });
    case actionTypes.DISABLE_DELIVERED_DUTY_DIALOG:
      return update(state, {
        deliveredDuty: {
          enabled: { $set: false },
        },
      });
    case actionTypes.OPEN_RESTRICTED_ITEMS_MODAL:
      return update(state, {
        restrictedItems: {
          isOpen: { $set: true },
          numbers: { $set: action.payload.numbers },
        },
      });
    case actionTypes.CLOSE_RESTRICTED_ITEMS_MODAL:
      return update(state, {
        restrictedItems: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.CLOSE_ADYEN_3DS_DIALOG:
      return update(state, {
        adyen3ds: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.OPEN_ADYEN_3DS_DIALOG:
      return update(state, {
        adyen3ds: {
          isOpen: { $set: true },
        },
      });
    case actionTypes.CLOSE_INLINE_AUTHORIZATION_DIALOG:
      return update(state, {
        inlineAuthorization: {
          isOpen: { $set: false },
        },
      });
    case actionTypes.OPEN_INLINE_AUTHORIZATION_DIALOG:
      return update(state, {
        inlineAuthorization: {
          isOpen: { $set: true },
        },
      });
    default:
      return state;
  }
};

export default dialogsReducer;
