import { getSession } from '@flowio/session';
import actionTypes from '../../constants/action-types';
import { ThunkResult } from '../../types';
import { AddDeviceDetails } from '../types/actions';

export default function addDeviceDetails(
  deviceDetails: io.flow.payment.v0.unions.DeviceDetails,
): ThunkResult<AddDeviceDetails> {
  return (
    dispatch,
  ) => dispatch({
    type: actionTypes.ADD_DEVICE_DETAILS,
    payload: deviceDetails,
  });
}
