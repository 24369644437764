import { Plugin } from '@flowio/browser-metrics';

import ExternalEvents from '../../../external/constants/external-events';
import events from '../../events';

const checkoutPlugin: Plugin = ({ addVariable, sendBeacon }) => {
  events.on(ExternalEvents.TRANSACTION, () => {
    addVariable('action', 'purchase', true);
    sendBeacon();
  });
};

export default checkoutPlugin;
