/* eslint-disable react/jsx-props-no-spreading */

import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import getDisplayName from '@flowio/react-helpers/lib/getDisplayName';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import hoistNonReactStatics from 'hoist-non-react-statics';

import RadioButtonGroup from '../radio-button/radio-button-group';

class ReduxFormRadioButtonGroup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleBlur(event) {
    const { input } = this.props;
    input.onBlur(event);
  }

  handleChange(event, value) {
    const { input } = this.props;
    input.onChange(value);
  }

  handleFocus(event) {
    const { input } = this.props;
    input.onFocus(event);
  }

  render() {
    const { input, meta } = this.props;
    const { touched, error, warning } = meta;
    const unhandledProps = getUnhandledProps(ReduxFormRadioButtonGroup, this.props);

    let errorText = error || warning;

    if (!touched) {
      errorText = undefined;
    }

    return (
      <RadioButtonGroup
        {...unhandledProps}
        errorText={errorText}
        name={input.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        value={input.value}
      />
    );
  }
}

ReduxFormRadioButtonGroup.displayName = `ReduxForm(${getDisplayName(RadioButtonGroup)})`;

ReduxFormRadioButtonGroup.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default hoistNonReactStatics(ReduxFormRadioButtonGroup, RadioButtonGroup, {
  handledProps: true,
});
