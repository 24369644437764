import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { getInitialValues } from './selectors';
import { getPrivacyPolicy, getTermAndConditions } from '../../store/content/selectors';
import { getShippingMethodUrl } from '../../store/navigation/selectors';
import { submitFreeOrder } from '../../store/checkout/actions';
import { visitConfirmationStep } from '../../store/navigation/actions/index';
import FormName from '../../constants/form-name';
import NoPaymentRequired from '../../components/no-payment-required';

const mapStateToProps = createStructuredSelector({
  initialValues: getInitialValues,
  privacyPolicy: getPrivacyPolicy,
  shippingMethodUrl: getShippingMethodUrl,
  terms: getTermAndConditions,
});

const handleSubmit = (values, dispatch) => dispatch(submitFreeOrder(values));

const handleSubmitSuccess = (result, dispatch) => dispatch(visitConfirmationStep());

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FormName.NO_PAYMENT,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSubmitSuccess,
  }),
)(NoPaymentRequired);
