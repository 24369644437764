import PropTypes from 'prop-types';

const merchantInfo = PropTypes.shape({
  merchantId: PropTypes.string.isRequired,
  merchantName: PropTypes.string,
});

const billingAddressParameters = PropTypes.shape({
  format: PropTypes.oneOf(['MIN', 'FULL']),
  phoneNumberRequired: PropTypes.bool,
});

const cardParameters = PropTypes.shape({
  allowedAuthMethods: PropTypes.arrayOf(
    PropTypes.oneOf(['PAN_ONLY', 'CRYPTOGRAM_3DS']),
  ).isRequired,
  allowedCardNetworks: PropTypes.arrayOf(
    PropTypes.oneOf(['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA']),
  ).isRequired,
  allowPrepaidCards: PropTypes.bool,
  billingAddressRequired: PropTypes.bool,
  billingAddressParameters,
});

const shippingAddressParameters = PropTypes.shape({
  allowedCountryCodes: PropTypes.arrayOf(PropTypes.string),
  phoneNumberRequired: PropTypes.bool,
});

const paymentMethodTokenizationSpecification = PropTypes.shape({
  type: PropTypes.oneOf(['PAYMENT_GATEWAY', 'DIRECT']).isRequired,
  parameters: PropTypes.shape({}).isRequired,
});

const paymentMethod = PropTypes.shape({
  type: PropTypes.oneOf(['CARD']).isRequired,
  parameters: PropTypes.oneOfType([cardParameters]),
  tokenizationSpecification: paymentMethodTokenizationSpecification,
});

const transactionInfo = PropTypes.shape({
  currencyCode: PropTypes.string.isRequired,
  totalPriceStatus: PropTypes.oneOf(['NOT_CURRENTLY_KNOWN', 'ESTIMATED', 'FINAL']).isRequired,
  totalPrice: PropTypes.string,
  checkoutOption: PropTypes.oneOf(['DEFAULT', 'COMPLETE_IMMEDIATE_PURCHASE']),
});

const paymentDataRequest = PropTypes.shape({
  apiVersion: PropTypes.number.isRequired,
  apiVersionMinor: PropTypes.number.isRequired,
  merchantInfo: merchantInfo.isRequired,
  allowedPaymentMethods: PropTypes.arrayOf(paymentMethod).isRequired,
  transactionInfo: transactionInfo.isRequired,
  emailRequired: PropTypes.bool,
  shippingAddressRequired: PropTypes.bool,
  shippingAddressParameters,
});

const isReadyToPayRequest = PropTypes.shape({
  apiVersion: PropTypes.number.isRequired,
  apiVersionMinor: PropTypes.number.isRequired,
  allowedPaymentMethods: PropTypes.arrayOf(paymentMethod).isRequired,
  existingPaymentMethodRequired: PropTypes.bool,
});

export default {
  isReadyToPayRequest,
  paymentDataRequest,
};
