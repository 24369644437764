import BemHelper from '@flowio/bem-helper';
import React from 'react';

import './form-group.css';

type OwnProps = {
  children?: React.ReactNode;
  className?: string;
  errorText?: React.ReactNode;
}

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLDivElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

const bem = new BemHelper('flow-form-group');

const FormGroup: React.FC<Props> = ({
  children,
  className,
  errorText,
  ...unhandledProps
}) => (
  <div
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...unhandledProps}
    className={bem.block(className)}
  >
    {children}
    {errorText != null && (
      <div className={bem.element('error-text')}>
        {errorText}
      </div>
    )}
  </div>
);

export default FormGroup;
