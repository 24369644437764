import { FormattedMessage } from 'react-intl';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import { Grid, Row, Column } from '../grid';
import Button from '../button/button';
import Collapse from '../collapse';
import CouponForm from '../../containers/coupon-form';
import DeliveredDutyMessaging from '../delivered-duties-messaging';
import Divider from '../divider';
import ContentLabel from '../content-label';
import OrderDeliveryItems from '../order-delivery-items';
import OrderPrices from '../order-prices';
import OrderTotal from '../order-total';

if (process.browser) {
  require('./mobile-order-summary.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('mobile-order-summary');

const MobileOrderSummary = ({
  discounts,
  forceEnableShopifyDiscounts,
  order,
  orderNumber,
  orderSummaryMessage,
  organization,
  onRequestToggleOrderSummary,
  onRemoveGiftCard,
  onRequestRemoveDiscount,
  isDiscountsEnabled,
  isOrderSummaryVisible,
  itemContentsMap,
  isShopifyIntegration,
  isConfirmationStep,
  isDutyIncluded,
  isTaxIncluded,
  isDeliveredDutyPaidMessageEnabled,
}) => (
  <div className={bem.block()}>
    <div className={bem.element('header-container')}>
      <Grid>
        <Row>
          <Column className={bem.element('header')} sm={10} xs={8}>
            <FormattedMessage
              id="checkout_order_summary_cart_heading"
              description="A text describing a section of the page giving you information about the collection of items the user has selected"
              defaultMessage="Your Cart"
            />
            <span>
              {' ('}
              <FormattedMessage
                id="checkout_order_summary_item"
                description="A text stating 'Item'"
                defaultMessage="{numberOfItems, plural, one {# Item} other {# Items}}"
                values={{ numberOfItems: get(order, 'items', []).length }}
              />
              )
            </span>
          </Column>
          <Column className={bem.element('header-action')} sm={2} xs={4}>
            <Button
              size="small"
              color="positive"
              onClick={onRequestToggleOrderSummary}
            >
              {isOrderSummaryVisible ? (
                <FormattedMessage
                  id="checkout_order_summary_hide_items"
                  description="Text to hide items"
                  defaultMessage="{numberOfItems, plural, one {Hide Item} other {Hide Items}}"
                  values={{ numberOfItems: get(order, 'items', []).length }}
                />
              ) : (
                <FormattedMessage
                  id="checkout_order_summary_show_items"
                  description="Text to show items"
                  defaultMessage="{numberOfItems, plural, one {Show Item} other {Show Items}}"
                  values={{ numberOfItems: get(order, 'items', []).length }}
                />
              )}
            </Button>
          </Column>
        </Row>
      </Grid>
    </div>
    <Divider className={bem.element('section-divider')} />
    <Collapse in={isOrderSummaryVisible}>
      <div>
        <OrderDeliveryItems
          order={order}
          itemContentsMap={itemContentsMap}
          isShopifyIntegration={isShopifyIntegration}
        />
        <Divider className={bem.element('section-divider')} />
      </div>
    </Collapse>
    {!isConfirmationStep && (
      isShopifyIntegration || isDiscountsEnabled || forceEnableShopifyDiscounts
    ) && (
      <CouponForm mobile />
    )}
    <OrderPrices
      isDiscountClearable={!isConfirmationStep}
      isDutyIncluded={isDutyIncluded}
      isTaxIncluded={isTaxIncluded}
      onRequestRemoveDiscount={(promoCode) => {
        onRequestRemoveDiscount(organization, orderNumber, promoCode);
      }}
      prices={order.prices}
      discounts={discounts}
      promotionCode={get(order, 'attributes.shopify_discount_code')}
    />
    <Divider className={bem.element('total-divider')} />
    <OrderTotal
      isConfirmationStep={isConfirmationStep}
      order={order}
      onRemoveCreditPayment={onRemoveGiftCard}
    />
    {orderSummaryMessage && (
      <ContentLabel
        className={bem.element('summary-message')}
        content={orderSummaryMessage}
      />
    )}
    {isDeliveredDutyPaidMessageEnabled && <DeliveredDutyMessaging />}
  </div>
);

MobileOrderSummary.propTypes = {
  discounts: PropTypes.arrayOf(ApiPropTypes.orderPriceDetailComponent).isRequired,
  forceEnableShopifyDiscounts: PropTypes.bool,
  order: ApiPropTypes.order.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderSummaryMessage: PropTypes.oneOfType([
    ApiInternalPropTypes.label, // @deprecated
    ApiInternalPropTypes.contentLabel,
  ]),
  organization: PropTypes.string.isRequired,
  onRequestToggleOrderSummary: PropTypes.func.isRequired,
  onRequestRemoveDiscount: PropTypes.func.isRequired,
  isOrderSummaryVisible: PropTypes.bool.isRequired,
  isDiscountsEnabled: PropTypes.bool.isRequired,
  itemContentsMap: PropTypes.objectOf(ApiPropTypes.checkoutItemContent).isRequired,
  isShopifyIntegration: PropTypes.bool.isRequired,
  isConfirmationStep: PropTypes.bool.isRequired,
  isDutyIncluded: PropTypes.bool.isRequired,
  isTaxIncluded: PropTypes.bool.isRequired,
  onRemoveGiftCard: PropTypes.func.isRequired,
  isDeliveredDutyPaidMessageEnabled: PropTypes.bool.isRequired,
};

MobileOrderSummary.defaultProps = {
  forceEnableShopifyDiscounts: false,
  orderSummaryMessage: undefined,
};

export default MobileOrderSummary;
