import update from 'immutability-helper';
import actionTypes from '../constants/action-types';
import type { FlowState } from './types';
import type { AppReducer } from '../types';

const initialState: FlowState = {
  integration: 'default',
  local_development: false,
  organization: '',
  organizationScripts: [],
};

const reducer: AppReducer<FlowState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        organization: {
          $set: action.payload.organization.id,
        },
      });
    case actionTypes.FETCH_ORGANIZATION_CONFIG_SUCCESS:
      return update(previousState, {
        organizationUrl: {
          $set: action.payload.organizationUrl,
        },
        checkoutScriptUrl: {
          $set: action.payload.checkoutScriptUrl,
        },
        overrideThemeUrl: {
          $set: action.payload.overrideThemeUrl,
        },
      });
    default:
      return previousState;
  }
};

export default reducer;
