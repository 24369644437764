import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

/**
 * Dispatch this action to redirect customers to the specified location.
 */
export default function redirect(
  location: string,
): RootAction {
  return {
    type: actionTypes.REDIRECT,
    payload: location,
  };
}
