import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

const updatePayPalInfo = (
  paypalPaymentId: string,
  paypalPayerId: string,
): RootAction => ({
  type: actionTypes.UPDATE_PAYPAL_INFO,
  payload: {
    paypalPayerId,
    paypalPaymentId,
  },
});

export default updatePayPalInfo;
