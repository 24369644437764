// @ts-check

import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import find from 'lodash/find';

import {
  getIsCollectCustomerAddressEnabled,
  getOrderDenormalized,
  getOrderDestination,
  getOrderTotalAmount,
  getOrderTotalCurrency,
  allowInvoiceAddress,
  getCustomerAddressBookContacts,
  getInvoiceAddress as getStateInvoiceAddress,
  getCustomerPaymentSources,
} from '../selectors';
import { getBillingAddress, getInvoiceAddress } from '../../../containers/payment-form/utilities';
import { getOptInsWithValues, validateRequiredOptIns } from '../../../utilities/opt-in-utilities';
import { getMarketingOptIns } from '../../content/selectors';
import checkHttpStatus from '../../../utilities/check-http-status';
import checkOrderBuilderStatus from '../../../utilities/check-order-builder-status';
import finalizeOrder from './finalize-order';
import patchOrderPutForm from '../../../hacks/patch-order-put-form';
import { trackHeapEvent } from '../../../utilities/heap';

/**
 * @param {Object.<string, any> } [object]
 * @returns {Object.<string, string>}
 */
function toMapString(object) {
  if (object == null) {
    return {};
  }

  return Object.keys(object).reduce((previousValue, key) => {
    const value = object[key];
    return { ...previousValue, [key]: value.toString() };
  }, {});
}

/**
 * @param {import('../../types').RootState} state
 * @param {import('../types').PaymentFormValues} values
 * @returns {io.flow.v0.models.OrderPutForm}
 */
function toOrderPutForm(state, values) {
  const {
    billingAddress,
    invoiceAddress,
    optinPrompts,
    paymentMethodId,
    giftMessage,
  } = values;

  const isCollectCustomerAddressEnabled = getIsCollectCustomerAddressEnabled(state);
  const order = getOrderDenormalized(state);
  const stateInvoiceAddress = getStateInvoiceAddress(state);
  const allowInvoice = allowInvoiceAddress(state);
  const prevOrderPutForm = patchOrderPutForm(order);
  const paymentSources = getCustomerPaymentSources(state);
  const selectedPaymentSource = find(paymentSources, { id: paymentMethodId });

  /** @type {io.flow.v0.models.BillingAddress | undefined} */
  let selectedBillingAddress;

  if (selectedPaymentSource != null) {
    selectedBillingAddress = selectedPaymentSource.summary.card.address;
  } else if (billingAddress != null) {
    selectedBillingAddress = getBillingAddress(
      billingAddress,
      getOrderDestination(state),
      getCustomerAddressBookContacts(state),
    );
  }

  const attributes = {
    ...prevOrderPutForm.attributes,
    ...toMapString(optinPrompts),
    ...toMapString(getOptInsWithValues(values, getMarketingOptIns(state))),
  };

  if (giftMessage && giftMessage.length > 0) {
    attributes.gift_message = giftMessage;
  }

  const nextOrderPutForm = {
    ...prevOrderPutForm,
    attributes,
    customer: !isCollectCustomerAddressEnabled ? prevOrderPutForm.customer : {
      ...prevOrderPutForm.customer,
      address: selectedBillingAddress,
    },
  };

  if (!isUndefined(stateInvoiceAddress) && allowInvoice) {
    nextOrderPutForm.customer.invoice = {
      ...get(prevOrderPutForm, 'customer.invoice', {}),
      address: invoiceAddress != null ? getInvoiceAddress(
        invoiceAddress,
        getOrderDestination(state),
        getCustomerAddressBookContacts(state),
      ) : undefined,
    };
  }

  return nextOrderPutForm;
}

/**
 * @param {import('../../types').RootState} state
 * @returns {io.flow.internal.v0.models.ExpectedOrderSummary}
 */
function toExpectedOrderSummary(state) {
  return {
    total: {
      amount: getOrderTotalAmount(state),
      currency: getOrderTotalCurrency(state),
    },
  };
}

/**
 * @param {import('../../types').RootState} state
 * @param {import('../types').PaymentFormValues} values
 * @returns {io.flow.internal.v0.models.CheckoutFinalizeOrderForm}
 */
function toCheckoutFinalizeOrderForm(state, values) {
  return {
    expected_order_summary: toExpectedOrderSummary(state),
    order_put_form: toOrderPutForm(state, values),
  };
}

/**
 * @param {import('../types').PaymentFormValues} values
 * @returns {import('redux-thunk').ThunkAction<Promise<io.flow.v0.models.OrderBuilder>, import('../../types').RootState, void, any>}
 */
export default function finalizeOrderV2(values) {
  return function finalizeOrderV2Effects(dispatch, getState) {
    const { orderNumber, organizationId, paymentMethodId } = values;
    return Promise.resolve()
      .then(() => validateRequiredOptIns(values, getMarketingOptIns(getState())))
      .then(() => dispatch(
        finalizeOrder(
          organizationId,
          orderNumber,
          toCheckoutFinalizeOrderForm(getState(), values),
        ),
      ))
      .then(checkHttpStatus)
      .then(checkOrderBuilderStatus)
      .then((response) => {
        trackHeapEvent('order_finalize_succeeded', {
          payment_method_id: paymentMethodId,
          version: 2,
        });
        return response;
      })
      .catch((error) => {
        trackHeapEvent('order_finalize_failed', {
          payment_method_id: paymentMethodId,
          version: 2,
        });
        throw error;
      });
  };
}
