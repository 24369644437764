import type { ShopifyOrderDetailsGetChecksAndInventoryByNumberResponse } from '@flowio/api-internal-sdk';
import { getIsShopifyIntegration } from '../../flow/selectors';
import { isServerSideInventoryEnabled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
| LegacyResponseWithLegacyError<ShopifyOrderDetailsGetChecksAndInventoryByNumberResponse>
| void
>;

const checkShopifyInventoryRequest = (): RootAction => ({
  type: actionTypes.CHECK_SHOPIFY_INVENTORY_REQUEST,
});

const checkShopifyInventorySuccess = (
  shopifyOrderInventoryChecks: io.flow.internal.v0.models.ShopifyOrderInventoryCheck[],
): RootAction => ({
  type: actionTypes.CHECK_SHOPIFY_INVENTORY_SUCCESS,
  payload: shopifyOrderInventoryChecks,
});

const checkShopifyInventoryFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
): RootAction => ({
  type: actionTypes.CHECK_SHOPIFY_INVENTORY_FAILURE,
  payload: error,
});

const checkShopifyInventory = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  // If server side inventory check is enabled,
  // we do not need to make the API call in this action.
  const shopifyInventoryCheckEnabled = !isServerSideInventoryEnabled(getState());
  const shopifyIntegration = getIsShopifyIntegration(getState());

  if (!shopifyIntegration || !shopifyInventoryCheckEnabled) {
    return Promise.resolve();
  }

  dispatch(checkShopifyInventoryRequest());

  return extra.apiInternal.shopifyOrderDetails.getChecksAndInventoryByNumber({
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(checkShopifyInventorySuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(checkShopifyInventoryFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default checkShopifyInventory;
