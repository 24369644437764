import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { isNil } from 'lodash';
import { RootState } from '../../types';
import { CheckoutDialogsState, CheckoutState } from '../types';
import PaymentTypes from '../../../constants/payment-types';

export const getCheckoutState = (state: RootState): CheckoutState => state.checkout;

export const getCheckoutEntities = createSelector(
  getCheckoutState,
  (state) => get(state, 'entities'),
);

export const getCheckoutMetadata = createSelector(
  getCheckoutState,
  (checkout) => get(checkout, 'metadata'),
);

export const getFeatureValues = createSelector<
RootState, CheckoutState, io.flow.internal.v0.unions.FeatureValue[]
>(
  getCheckoutState,
  (checkout) => get(checkout, ['features', 'values']),
);

export const getCheckoutHacks = createSelector(
  getCheckoutState,
  (state) => get(state, 'hacks'),
);

export const getOrder = createSelector<RootState, CheckoutState, io.flow.v0.models.Order>(
  getCheckoutState,
  (state) => get(state, ['entities', 'order']),
);

export const getOrderAttributes = createSelector(
  getOrder,
  (order) => get(order, 'attributes'),
);

export const getIsBlazeCheckout = createSelector(
  getOrder,
  (state) => (Object.keys(get(state, 'attributes', {})).indexOf('flow_v2_checkout_id') > -1),
);

export const getFlowVatName = createSelector(
  getOrderAttributes,
  (state) => get(state, 'flow_vat_name'),
);

export const getCheckoutDialogsState = createSelector(
  getCheckoutState,
  (state) => state.dialogs,
);

export const getIsInlineAuthorizationDialogOpen = createSelector<
RootState, CheckoutDialogsState, CheckoutDialogsState['inlineAuthorization']['isOpen']
>(
  getCheckoutDialogsState,
  (state) => get(state, ['inlineAuthorization', 'isOpen'], false),
);

export const getCheckoutOrganization = createSelector(
  getCheckoutEntities,
  (state) => state.organization.organizationData,
);

export const getCheckoutConfigState = createSelector(
  getCheckoutState,
  (state) => state.config,
);

export const getApplePayMerchantId = createSelector(
  getCheckoutConfigState,
  (state) => state.applePayMerchantIds,
);

export const getApplePayAvailibility = createSelector(
  getCheckoutMetadata,
  (state) => state.applePayAvailabilty,
);

const getPaymentMethodRules = createSelector(
  getCheckoutEntities,
  (state) => state.paymentMethodRules,
);

export const getIsApplepayEnabled = createSelector(
  getPaymentMethodRules,
  (state) => Object.keys(state).includes(PaymentTypes.ApplePay),
);

export const getPaymentMethodParams = createSelector(
  getCheckoutEntities,
  (state) => state.paymentMethodParams,
);

export const getApplePayParameters = createSelector(
  getPaymentMethodParams,
  (state) => {
    const params = find(state, (ap) => ap.method === PaymentTypes.ApplePay);
    if (!isNil(params)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return params.parameters as io.flow.apple.pay.v0.models.ApplePayPaymentInfo;
    }
    return undefined;
  },
);

export const getOrganizationData = createSelector(
  getCheckoutEntities,
  (state) => state.organization.organizationData,
);

export const getOrderBalanceAmount = createSelector(
  getOrder,
  (order) => order.balance?.amount,
);
