const AuthorizationDeclineCodes = {
  // Payment method has expired
  Expired: 'expired',
  // Invalid card holder name
  InvalidName: 'invalid_name',
  // Invalid payment number
  InvalidNumber: 'invalid_number',
  // Invalid expiration date
  InvalidExpiration: 'invalid_expiration',
  // The provided payment method is not known by the issuing bank (the account does not exist)
  NoAccount: 'no_account',
  // Declined due to avs mismatch)
  Avs: 'avs',
  // Declined due to cvv mismatch)
  Cvv: 'cvv',
  // Declined due to suspected fraud
  Fraud: 'fraud',
  // Declined due to suspected duplicate transaction
  Duplicate: 'duplicate',
  // Declined as payment method is not supported
  NotSupported: 'not_supported',
  // Authorization canceled or reversed
  // (e.g. user canceled APM payment and wants to select a new method)
  Reversed: 'reversed',
  // Declined due to another reason (details not known)
  Unknown: 'unknown',
};

export default AuthorizationDeclineCodes;
