/* eslint-disable react/jsx-props-no-spreading */

import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import Button from '../button';
import Close from '../svg-icons/close';
import Image from '../image';
import Spinbox from '../spinbox';
import Textbox from '../textbox';

if (process.browser) {
  require('./cart-item.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('cart-item');

class CartItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.requestQuantityChange = debounce(this.requestQuantityChange.bind(this), 350);
  }

  getInitialState() {
    const { item } = this.props;
    return {
      quantity: item.quantity,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      quantity: nextProps.item.quantity,
    });
  }

  handleQuantityChange(event, value) {
    const { item } = this.props;
    this.setState({ quantity: value }, () => {
      this.requestQuantityChange(item.variant_id, value);
    });
  }

  handleRemoveItem() {
    const { item, onRequestRemove } = this.props;
    onRequestRemove(item.variant_id);
  }

  requestQuantityChange(variantId, quantity) {
    const { onRequestQuantityChange } = this.props;
    if (!isNil(quantity) && !isEmpty(quantity)) {
      onRequestQuantityChange(variantId, quantity);
    }
  }

  render() {
    const { item, isWrapCartItemEnabled } = this.props;
    const { quantity } = this.state;

    const dataAttributes = omitBy({
      'data-shopify-product-type': kebabCase(get(item, 'product_type'), ''),
      'data-shopify-variant-id': kebabCase(get(item, 'variant_id'), ''),
      'data-shopify-product-id': kebabCase(get(item, 'product_id'), ''),
    }, isEmpty);

    return (
      <div className={bem.block()} {...dataAttributes}>
        <Image
          accessibilityLabel={item.product_title}
          className={bem.element('image')}
          bordered
          rounded
          fit="fill"
          source={item.image}
        />
        <div className={bem.element('info')}>
          <div title={item.product_title} className={bem.element('title', { wrapping: isWrapCartItemEnabled })}>
            <a className={bem.element('pdp-link')} href={item.url}>
              {item.product_title}
            </a>
          </div>
          <div className={bem.element('price', 'hidden-sm-up')}>
            {item.local.line_price.label}
          </div>
        </div>
        <div className={bem.element('quantity')}>
          <Spinbox className="hidden-sm-down" minValue={1} value={quantity} onChange={this.handleQuantityChange} />
          <Textbox className="hidden-md-up" value={quantity} onChange={this.handleQuantityChange} />
        </div>
        <div className={bem.element('price', 'hidden-xs-down')}>
          {item.local.line_price.label}
        </div>
        <div className={bem.element('remove')}>
          <Button
            aria-label="Remove"
            flat
            size="small"
            color="negative"
            className={bem.element('remove-flat-button')}
            onClick={this.handleRemoveItem}
          >
            <FormattedMessage
              id="checkout_cart_item_action_remove"
              description="Text on the action to remove an item from the cart"
              defaultMessage="Remove"
            />
          </Button>
          <Button
            aria-label="Remove"
            flat
            icon
            color="negative"
            className={bem.element('remove-icon-button')}
            onClick={this.handleRemoveItem}
          >
            <Close />
          </Button>
        </div>
      </div>
    );
  }
}

CartItem.displayName = 'CartItem';

CartItem.propTypes = {
  item: ApiPropTypes.shopifyCartItem.isRequired,
  isWrapCartItemEnabled: PropTypes.bool,
  onRequestQuantityChange: PropTypes.func,
  onRequestRemove: PropTypes.func,
};

CartItem.defaultProps = {
  isWrapCartItemEnabled: false,
  onRequestQuantityChange: noop,
  onRequestRemove: noop,
};

export default CartItem;
