import type { LoyaltyProgramsGetResponse } from '@flowio/api-internal-sdk';
import { isLoyaltyEnabled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<LoyaltyProgramsGetResponse> | void>;

const fetchLoyaltyProgramsRequest = (): RootAction => ({
  type: actionTypes.FETCH_LOYALTY_PROGRAMS_REQUEST,
});

const fetchLoyaltyProgramsSuccess = (
  LoyaltyProgram: io.flow.internal.v0.models.LoyaltyProgram,
): RootAction => ({
  type: actionTypes.FETCH_LOYALTY_PROGRAMS_SUCCESS,
  payload: LoyaltyProgram,
});

const fetchLoyaltyProgramsFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_LOYALTY_PROGRAMS_FAILURE,
  payload: error,
});

const fetchLoyaltyPrograms = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (!isLoyaltyEnabled(getState())) {
    return Promise.resolve();
  }

  dispatch(fetchLoyaltyProgramsRequest());

  return extra.apiInternal.loyaltyPrograms.get({
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchLoyaltyProgramsSuccess(response.result));
          break;
        case 401:
        case 404:
          dispatch(fetchLoyaltyProgramsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchLoyaltyPrograms;
