import invariant from 'invariant';
import isString from 'lodash/isString';

import checkHttpStatus from '../../../utilities/check-http-status';
import authorizeGiftCards from './authorize-gift-cards';
import fetchOrder from './fetch-order';

export default function completeGiftCardAuthorization(
  organizationId,
  orderNumber,
) {
  invariant(isString(organizationId), 'An organization identifier must be provided');
  invariant(isString(orderNumber), 'An order number must be provided');
  return function completeGiftCardAuthorizationSideEffects(dispatch) {
    // In this case, the order will not be submitted because the customer needs to complete
    // the payment authorization of gift cards.

    return dispatch(authorizeGiftCards(organizationId, orderNumber))
      .then(checkHttpStatus)
      .catch((error) => dispatch(fetchOrder(organizationId, orderNumber)).then(() => {
        throw error;
      }));
  };
}
