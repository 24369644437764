/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';

import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getValueFromEvent from '@flowio/react-helpers/lib/getValueFromEvent';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./radio-button.css');
}

const bem = new BemHelper('radio-button');

/**
 * @typedef {PropTypes.InferProps<typeof RadioButton.propTypes>} RadioButtonProps
 */

/**
 * @type {React.ComponentClass<RadioButtonProps>}
 */
class RadioButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  getInitialState() {
    const { id } = this.props;
    return {
      id: id || `radio-button.${uniqueId()}`,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id: prevId } = this.props;
    const { id: nextId } = nextProps;

    if (!isNil(nextId) && prevId !== nextId) {
      this.setState({ id: nextId });
    }
  }

  handleBlur(event) {
    const { name, onBlur } = this.props;

    trackHeapEvent('field_blur', {
      field: name,
    });

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  handleChange(event) {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(event, getValueFromEvent(event));
    }
  }

  handleFocus(event) {
    const { name, onFocus } = this.props;

    trackHeapEvent('field_focus', {
      field: name,
    });

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  render() {
    const {
      className,
      checked,
      children,
      defaultChecked,
      fluid,
      labelText,
      disabled,
      name,
      value,
    } = this.props;

    const { id } = this.state;

    const ElementType = getElementType(RadioButton, this.props);

    const unhandledProps = getUnhandledProps(RadioButton, this.props);

    return (
      <ElementType
        {...unhandledProps}
        className={bem.block(className, { fluid })}
      >
        <input
          type="radio"
          id={id}
          checked={checked}
          className={bem.element('input')}
          defaultChecked={defaultChecked}
          name={name}
          value={value}
          disabled={disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
        />
        <label className={bem.element('label')} htmlFor={id}>
          {children || labelText}
        </label>
      </ElementType>
    );
  }
}

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  id: PropTypes.string,
  labelText: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
};

RadioButton.defaultProps = {
  as: 'div',
  checked: undefined,
  children: undefined,
  className: '',
  defaultChecked: undefined,
  disabled: false,
  fluid: false,
  id: undefined,
  labelText: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  name: undefined,
  value: undefined,
};

export default RadioButton;
