import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  SubmissionError,
  startSubmit,
  stopSubmit,
  setSubmitFailed,
} from 'redux-form';

import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import {
  getOrganizationCountries,
} from '../store/checkout/selectors';
import ExpressCheckout from '../components/express-checkout';
import {
  updatePaypalInfo,
  applyPayPalInfoToForm,
} from '../store/checkout/actions';
import { fetchAdministrativeDivisions } from '../store/reference/actions';
import FormName from '../constants/form-name';

const formName = FormName.CUSTOMER_INFORMATION;

function createSubmissionError(error, dispatch) {
  const submissionError = new SubmissionError({ _error: error });
  dispatch(startSubmit(formName));
  dispatch(stopSubmit(formName, submissionError.errors));
  dispatch(setSubmitFailed(formName));
}

function handlePaypalSuccess(payment, actions) {
  return function handlePayPalSuccessSideEffects(dispatch, getState) {
    const state = getState();
    const countries = getOrganizationCountries(state);

    dispatch(updatePaypalInfo(payment.paymentID, payment.payerID));
    actions.payment.get().then((info) => {
      const payerInfo = info.payer.payer_info;
      if (payerInfo.shipping_address == null) {
        return dispatch(applyPayPalInfoToForm(payerInfo));
      }

      const selectedCountry = countries.find(
        (country) => country.iso_3166_2 === payerInfo.shipping_address.country_code,
      );
      const countryCode = selectedCountry != null && selectedCountry.iso_3166_3;

      return dispatch(fetchAdministrativeDivisions({ countries: [countryCode] }))
        .then(() => {
          dispatch(applyPayPalInfoToForm(payerInfo, countryCode));
        });
    }).catch((error) => {
      getRollbar((rollbar) => {
        rollbar.error('Failed to retrieve payment data from PayPal', error);
      });
    });
  };
}

function handlePaypalError(error) {
  return function handlePayPalErrorSideEffects(dispatch) {
    createSubmissionError(error, dispatch);
  };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onPaypalSuccess: handlePaypalSuccess,
  onPaypalError: handlePaypalError,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ExpressCheckout);
