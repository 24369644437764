import { combineReducers } from 'redux';

import addressBook from './address-book';
import addressBookContacts from './address-book-contacts';
import addressConfiguration from './address-configuration';
import authorizations from './authorizations';
import callbacks from './callbacks';
import checkoutConfiguration from './checkout-configuration';
import configReducer from './config';
import metadata from './metadata';
import collapsibles from './collapsibles';
import dialogs from './dialogs';
import order from './order';
import organization from './organization';
import errors from './errors';
import params from './params';
import itemContents from './itemContents';
import giftCards from './giftCards';
import lineByItemNumber from './line-by-item-number';
import loyalty from './loyalty';
import paymentMethodRules from './payment-method-rules';
import paymentMethodRulesPagination from './payment-method-rules-pagination';
import paymentMethodTypesPagination from './payment-method-types-pagination';
import publicKey from './public-key';
import statuses from './statuses';
import features from './features';
import vrn from './vrn-check';
import customer from './customer';
import review from './review';
import paymentMethodParams from './payment-method-params';
import paymentSources from './payment-sources';
import expressCheckout from './express-checkout';
import hacks from './hacks';
import { CheckoutState, CheckoutEntitiesState } from '../types';

export default combineReducers<CheckoutState>({
  features,
  callbacks,
  metadata,
  collapsibles,
  config: configReducer,
  configuration: checkoutConfiguration,
  dialogs,
  entities: combineReducers<CheckoutEntitiesState>({
    addressBook,
    addressBookContacts,
    addressConfiguration,
    authorizations,
    customer,
    expressCheckout,
    giftCards,
    itemContents,
    lineByItemNumber,
    loyalty,
    order,
    organization,
    paymentMethodParams,
    paymentMethodRules,
    paymentSources,
    publicKey,
    review,
    vrn,
  }),
  errors,
  hacks,
  params,
  paginations: combineReducers({
    paymentMethodRules: paymentMethodRulesPagination,
    paymentMethodTypes: paymentMethodTypesPagination,
  }),
  statuses,
});
