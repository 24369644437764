import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import { getLogoUrl } from '../../store/content/selectors';
import { getContinueShoppingUrl } from '../../store/checkout/selectors';
import Image from '../image';

if (process.browser) {
  require('./header.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('header');

export const Header = ({
  className,
  continueShoppingUrl,
  logoUrl,
  logoLinkUrl,
}) => (
  <header className={bem.block(className)}>
    <a href={continueShoppingUrl || logoLinkUrl}>
      <Image source={logoUrl} accessibilityLabel="brand logo" />
    </a>
  </header>
);

Header.displayName = 'Header';

Header.propTypes = {
  className: PropTypes.string,
  continueShoppingUrl: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  logoLinkUrl: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  continueShoppingUrl: undefined,
  logoLinkUrl: '/',
};

const mapStateToProps = createStructuredSelector({
  continueShoppingUrl: getContinueShoppingUrl,
  logoUrl: getLogoUrl,
});

export default connect(mapStateToProps)(Header);
