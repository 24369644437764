import defaultTo from 'lodash/defaultTo';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.customer.v0.models.Customer | null;

const initialState: State = null;

const customerReducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return defaultTo(action.payload.customer, previousState);
    default:
      return previousState;
  }
};

export default customerReducer;
