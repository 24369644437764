import type { CountriesGetResponse } from '@flowio/api-sdk';
import { getIsCountriesFulfilled } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CountriesGetResponse> | void>;

const fetchCountriesRequest = (): RootAction => ({
  type: actionTypes.FETCH_COUNTRIES_REQUEST,
});

const fetchCountriesSuccess = (
  countries: io.flow.v0.models.Country[],
): RootAction => ({
  type: actionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: countries,
});

const fetchCountriesFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_COUNTRIES_FAILURE,
  payload: error,
});

const fetchCountries = (): ThunkResult<Result> => (dispatch, getState, extra) => {
  if (getIsCountriesFulfilled(getState())) return Promise.resolve();
  dispatch(fetchCountriesRequest());
  return extra.api.countries.get()
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchCountriesSuccess(response.result));
          break;
        case 401:
          dispatch(fetchCountriesFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchCountries;
