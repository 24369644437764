import React, { MouseEvent } from 'react';
import BemHelper from '@flowio/bem-helper';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';

import FeatureToggle from '../feature-toggle';
import { Panel } from '../panel';
import TextField from '../redux-form/text-field';
import { Grid, Row, Column } from '../grid';
import Button from '../button';
import { GiftCard, ChevronLeft } from '../svg-icons';
import OrderSubmissionError from '../order-submission-error';
import './gift-card-v2-form.css';
import Message from '../message';

const bem = new BemHelper('flow-gift-card-v2-form');

export interface GiftCardV2FormProps {
  handleSubmitGiftCard: () => void;
  orderNumber: string;
  isOpen: boolean;
  handleHeaderClick: (isOpen: boolean) => void;
  giftCardError?: io.flow.v0.models.GenericError;
  didAddGiftCard: boolean;
}

export interface GiftCardV2FormData {
  number: string;
  pin?: string;
}

const GiftCardV2Form: React.FC<GiftCardV2FormProps> = ({
  handleSubmitGiftCard,
  giftCardError,
  orderNumber,
  isOpen,
  handleHeaderClick,
  didAddGiftCard,
}): React.ReactElement => {
  /* This form is nested within another form so we have to stopPropagation */
  const handleNestedSubmit = (event: MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    return handleSubmitGiftCard();
  };

  return (
    <div className={bem.block()}>
      <FeatureToggle
        featureKey={MOBILE_UX_SPACING}
        render={({ isFeatureEnabled }): React.ReactElement => (
          <Grid parent={isFeatureEnabled}>
            <Row>
              <Column xs={12}>
                <Panel
                  defaultExpanded={false}
                  onSelect={(): void => handleHeaderClick(!isOpen)}
                  expanded={isOpen}
                  collapsible
                >
                  <Panel.Header>
                    <Panel.Title className={bem.element('header-title')}>
                      <GiftCard className={bem.element('header-icon')} />
                      <span className={bem.element('header-text')}>
                        <FormattedMessage
                          defaultMessage="Add a Gift Card"
                          description="Title messaging for the gift card form"
                          id="checkout_add_gift_card"
                        />
                      </span>
                      <span className={isOpen ? bem.element('chevron-open') : bem.element('chevron-closed')}><ChevronLeft /></span>
                    </Panel.Title>
                  </Panel.Header>
                  <Panel.Content className={bem.element('panel-content')}>
                    <Grid className={bem.element('form')}>
                      <Row>
                        <Column xs={8} sm={10}>
                          <Field
                            component={TextField}
                            size="medium"
                            name="giftCardV2Number"
                            automationId="gift-card-v2-number"
                            labelText={(
                              <FormattedMessage
                                defaultMessage="Card Number"
                                id="checkout_card_field_label_number"
                                description="Text indicating to insert the card number"
                              />
                            )}
                          />
                        </Column>
                        <Column xs={4} sm={2}>
                          <Button fluid text="Apply" outline type="button" onClick={handleNestedSubmit} color="primary" />
                        </Column>
                      </Row>
                      {didAddGiftCard && (
                        <Row>
                          <Column xs={12} className={bem.element('error-column')}>
                            <Message color="info" className={bem.element('added')}>
                              <Message.Text className={bem.element('added-text')}>
                                <FormattedMessage
                                  defaultMessage="Gift card has been applied!"
                                  id="checkout_gift_card_added"
                                  description="Text indicating that a gift card was added"
                                />
                              </Message.Text>
                            </Message>
                          </Column>
                        </Row>
                      )}
                      {giftCardError && (
                        <Row>
                          <Column xs={12} className={bem.element('error-column')}>
                            <OrderSubmissionError className={bem.element('error')} error={giftCardError} orderNumber={orderNumber} />
                          </Column>
                        </Row>
                      )}
                    </Grid>
                  </Panel.Content>
                </Panel>
              </Column>
            </Row>
          </Grid>
        )}
      />
    </div>
  );
};

export default GiftCardV2Form;
