import React, { useCallback } from 'react';
import BemHelper from '@flowio/bem-helper';

import type { applePay } from '@flowio/payment';

import handleEnterKeyboardEvent from '../../utilities/handle-enter-keyboard-event';

if (process.browser) {
  require('./applepay-button.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('applepay-button');

type Props = {
  availability: applePay.ApplePayAvailabilityResponse;
  locale: string;
  onApplePayClick: () => void;
}

const ApplePayButton: React.FC<Props> = ({
  availability,
  locale,
  onApplePayClick,
}) => {
  const startApplePayHandler = useCallback(() => {
    onApplePayClick();
  }, [onApplePayClick]);

  // Show the button if available, or not configured to allow the user configure in Apple Pay window.
  return availability.available ? (
    <div
      role="button"
      tabIndex={0}
      className={bem.element('button')}
      lang={locale}
      onKeyPress={handleEnterKeyboardEvent(startApplePayHandler)}
      onClick={startApplePayHandler}
    >
      <span className={bem.element('text')}>Continue with</span>
      <span className={bem.element('logo')} />
    </div>
  ) : null;
};

export default ApplePayButton;
