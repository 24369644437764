import { Location } from 'history';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import { CHECKOUT_PROMPT_BEHAVIOR } from '../constants/redirect-reason';
import {
  getDeliveredDutyDisplayType,
  getOrder,
  getShippingMethodPromptBehavior,
  hasMultipleShippingMethods,
} from '../store/checkout';
import { RootState } from '../store/types';

export default function checkShippingMethodPromptBehavior(
  location: Location,
  state: RootState,
): void {
  const order = getOrder(state);
  const behavior = getShippingMethodPromptBehavior(state);
  const multiple = hasMultipleShippingMethods(state);
  const deliveredDutyDisplayType = getDeliveredDutyDisplayType(state);
  if (location.state != null
    && location.state.redirectReason === CHECKOUT_PROMPT_BEHAVIOR
    && behavior === 'multiple'
    && !multiple) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getRollbar((rollbar: any, extra: any = {}) => {
      rollbar.warning('Broken prompt behavior detected', {
        ...extra,
        reason: 'Expected customer to be on the next step since order does not have multiple deliveries',
        behavior,
        orderNumber: order.number,
        deliveredDutyDisplayType,
      });
    });
  }
}
