import keyBy from 'lodash/keyBy';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = Partial<Record<string, io.flow.v0.models.PaymentMethodRule>>;

const initialState: State = {};

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_RULES_SUCCESS:
      return update(previousState, {
        $merge: keyBy(action.payload.results, (_) => _.payment_method.id),
      });
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, {
        $set: keyBy(action.payload.payment.method_rules, (_) => _.payment_method.id),
      });
    default:
      return previousState;
  }
};

export default reducer;
