import compact from 'lodash/compact';
import { ContactInfoFormValues } from '../../store/checkout/types';

export function toOrderAddress(
  values: ContactInfoFormValues,
): Partial<io.flow.v0.models.OrderAddress> {
  return {
    streets: compact([
      values.addressLine1,
      values.addressLine2,
    ]),
    city: values.locality,
    province: values.administrativeArea,
    postal: values.postalCode,
    country: values.country,
    contact: {
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      company: values.organizationName,
      phone: values.phoneNumber,
    },
  };
}

export function toCustomerForm(
  values: ContactInfoFormValues,
): io.flow.v0.models.CustomerForm {
  return {
    email: values.email,
    number: values.customerNumber,
  };
}

interface CustomerFormValuesFromAddressBookProps {
  newAddress: io.flow.v0.models.CustomerAddressBookContact;
  organizationId: string;
  orderNumber: string;
  customerNumber: string;
  returningCustomerEmail: string;
}

export function toCustomerFormValuesFromAddressBook({
  newAddress,
  organizationId,
  orderNumber,
  customerNumber,
  returningCustomerEmail,
}: CustomerFormValuesFromAddressBookProps): Partial<ContactInfoFormValues> {
  return {
    addressLine1: newAddress.address.streets && newAddress.address.streets[0],
    addressLine2: newAddress.address.streets && newAddress.address.streets[1],
    administrativeArea: newAddress.address.province,
    country: newAddress.address.country,
    email: returningCustomerEmail,
    firstName: newAddress.contact.name.first,
    lastName: newAddress.contact.name.last,
    locality: newAddress.address.city,
    orderNumber,
    organizationId,
    customerNumber,
    phoneNumber: newAddress.contact.phone,
    postalCode: newAddress.address.postal,
    organizationName: newAddress.contact.company,
  };
}
