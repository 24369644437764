import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import { AddressType } from './types';
import { autoCompleteToken, automationToken } from './utilities';
import GooglePlaceAutoComplete from '../google-place-auto-complete';

type Props = {
  addressType?: AddressType;
  countries: io.flow.v0.models.Country[];
  countryCode: string;
  locale: string;
  onAutoCompleteAddress?: (place: unknown, addressType?: AddressType) => void;
  required?: boolean;
};

const AddressLine1: React.FunctionComponent<Props> = ({
  addressType,
  countries,
  countryCode,
  locale,
  onAutoCompleteAddress,
  required = false,
}) => {
  const handlePlaceChange = React.useCallback((place: unknown) => {
    if (typeof onAutoCompleteAddress === 'function') {
      onAutoCompleteAddress(place, addressType);
    }
  }, [addressType, onAutoCompleteAddress]);

  return (
    <Field
      autoComplete={autoCompleteToken('address-line1', addressType)}
      automationId={automationToken('address-line1', addressType)}
      component={GooglePlaceAutoComplete}
      countryCode={countryCode}
      locale={locale}
      onPlaceChange={handlePlaceChange}
      countries={countries}
      labelText={(
        <FormattedMessage
          id="checkout_address_field_label_line1"
          description="Content for first street address line field label in address forms"
          defaultMessage="Address Line 1"
        />
      )}
      name="addressLine1"
      required={required}
    />
  );
};

export default AddressLine1;
