import compact from 'lodash/compact';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import find from 'lodash/find';
import matches from 'lodash/matches';

import PaymentTypes from '../../constants/payment-types';
import AuthorizationFormDiscriminator from '../../constants/authorization-form';

import {
  AchFormValues,
  CardFormValues,
  CryptoPayFormValues,
  GooglePayFormValues,
  KlarnaFormValues,
  PaymentFormValues,
  PayPalFormValues,
  BillingAddressFormValues,
  ApplePayFormValues,
} from '../../store/checkout/types';

/**
 * Returns whether a card is the selected payment method in the provided payment
 * form values.
 */
export function isCard(
  values: PaymentFormValues,
): values is CardFormValues {
  return values.paymentMethodId === PaymentTypes.Card
    || values.paymentMethodId === PaymentTypes.CreditCard
    || values.paymentMethodId === PaymentTypes.DebitCard;
}

/**
 * Returns whether Apple Pay is the selected payment method in the provided
 * payment form values.
 */
export function isApplePay(
  values: PaymentFormValues,
): values is ApplePayFormValues {
  return values.paymentMethodId === PaymentTypes.ApplePay;
}

/**
 * Returns whether CryptoPay is the selecteed payment method in the provided
 * payment form values.
 */
export function isCryptoPay(
  values: PaymentFormValues,
): values is CryptoPayFormValues {
  return values.paymentMethodId === PaymentTypes.CryptoPay;
}

/**
 * Returns whether PayPal is the selected payment method in the provided
 * payment form values.
 */
export function isPayPal(
  values: PaymentFormValues,
): values is PayPalFormValues {
  return values.paymentMethodId === PaymentTypes.PayPal;
}

/**
 * Returns whether Klarna is the selected payment method in the provided
 * payment form values.
 */
export function isKlarna(
  values: PaymentFormValues,
): values is KlarnaFormValues {
  return values.paymentMethodId === PaymentTypes.Klarna;
}

/**
 * Returns whether Google Pay is the selected payment method in the provided
 * payment form values.
 */
export function isGooglePay(
  values: PaymentFormValues,
): values is GooglePayFormValues {
  return values.paymentMethodId === PaymentTypes.GooglePay;
}

/**
 * Returns whether ACH Transfer is the selected payment method in the provided
 * payment form values.
 */
export function isAch(
  values: PaymentFormValues,
): values is AchFormValues {
  return values.paymentMethodId === PaymentTypes.ACH;
}

/**
 * Returns whether the customer has chosen for the billing address to be the
 * same as the shipping address.
 */
export function isBillingAddressSameAsShippingAddress(
  values: PaymentFormValues,
): boolean {
  return values.billingAddress != null
    && values.billingAddress.addressToUse === 'sameAsShippingAddress';
}

export function isBillingAddressSavedAddress(
  values: PaymentFormValues,
): boolean {
  return values.billingAddress != null
    && values.billingAddress.addressToUse !== null
    && values.billingAddress.addressToUse !== 'sameAsShippingAddress'
    && values.billingAddress.addressToUse !== 'newAddress';
}

/**
 * Returns whether the customer has chosen for the invoice address to be the
 * same as the shipping address.
 */
export function isInvoiceAddressSameAsShippingAddress(
  values: PaymentFormValues,
): boolean {
  return values.invoiceAddress != null
    && values.invoiceAddress.addressToUse === 'sameAsShippingAddress';
}

/**
 * Returns whether the customer has chosen for the invoice address to be
 * a saved address.
 */
export function isInvoiceAddressSavedAddress(
  values: PaymentFormValues,
): boolean {
  return values.invoiceAddress != null
    && values.invoiceAddress.addressToUse !== null
    && values.invoiceAddress.addressToUse !== 'sameAsShippingAddress'
    && values.invoiceAddress.addressToUse !== 'newAddress';
}

/**
 * Converts an `order_address` model into a `billing_address` model.
 */
export function billingAddressFromOrderAddress(
  destination: io.flow.v0.models.OrderAddress,
): io.flow.v0.models.BillingAddress {
  return omitBy<io.flow.v0.models.BillingAddress>({
    name: omitBy<io.flow.v0.models.Name>({
      first: get(destination, 'contact.name.first'),
      last: get(destination, 'contact.name.last'),
    }, isNil),
    streets: get(destination, 'streets'),
    city: get(destination, 'city'),
    province: get(destination, 'province'),
    postal: get(destination, 'postal'),
    country: get(destination, 'country'),
    company: get(destination, 'contact.company'),
  }, isNil);
}

/**
 * Converts an `customer_address_book_contact` model into a
 * `billing_address` model.
 */
export function billingAddressFromAddressBook(
  addressBook: io.flow.v0.models.CustomerAddressBookContact,
): io.flow.v0.models.BillingAddress {
  return omitBy<io.flow.v0.models.BillingAddress>({
    name: omitBy<io.flow.v0.models.Name>({
      first: get(addressBook, 'contact.name.first'),
      last: get(addressBook, 'contact.name.last'),
    }, isNil),
    streets: get(addressBook, 'address.streets'),
    city: get(addressBook, 'address.city'),
    province: get(addressBook, 'address.province'),
    postal: get(addressBook, 'address.postal'),
    country: get(addressBook, 'address.country'),
    company: get(addressBook, 'contact.company'),
  }, isNil);
}

/**
 * Converts billing address values collected from the payment form into a
 * `billing_address` model.
 */
export function billingAddressFromFormValues(
  values: BillingAddressFormValues,
): io.flow.v0.models.BillingAddress {
  return omitBy<io.flow.v0.models.BillingAddress>({
    name: omitBy<io.flow.v0.models.Name>({
      first: get(values, 'firstName'),
      last: get(values, 'lastName'),
    }, isNil),
    streets: compact([
      get(values, 'addressLine1'),
      get(values, 'addressLine2'),
    ]),
    company: get(values, 'organizationName'),
    city: get(values, 'locality'),
    province: get(values, 'administrativeArea'),
    postal: get(values, 'postalCode'),
    country: get(values, 'country'),
  }, isNil);
}

export function formValuesFromAddressBook(
  addressBook: io.flow.v0.models.CustomerAddressBookContact,
): Partial<BillingAddressFormValues> {
  return omitBy<BillingAddressFormValues>({
    firstName: get(addressBook, 'contact.name.first'),
    lastName: get(addressBook, 'contact.name.last'),
    addressLine1: get(addressBook, 'address.streets[0]'),
    addressLine2: get(addressBook, 'address.streets[1]'),
    organizationName: get(addressBook, 'contact.company'),
    locality: get(addressBook, 'address.city'),
    administrativeArea: get(addressBook, 'address.province'),
    postalCode: get(addressBook, 'address.postal'),
    country: get(addressBook, 'address.country'),
    phoneNumber: get(addressBook, 'contact.phone'),
  }, isNil);
}

export function getBillingAddress(
  billingAddress: BillingAddressFormValues,
  destination: io.flow.v0.models.OrderAddress,
  customerAddressBooks?: io.flow.v0.models.CustomerAddressBookContact[],
): io.flow.v0.models.BillingAddress {
  if (billingAddress.addressToUse === 'sameAsShippingAddress') {
    return billingAddressFromOrderAddress(destination);
  }

  if (customerAddressBooks != null) {
    const contact = find(customerAddressBooks, matches({
      id: billingAddress.addressToUse,
    }));

    if (contact != null) {
      return billingAddressFromAddressBook(contact);
    }
  }

  return billingAddressFromFormValues(billingAddress);
}

export function getInvoiceAddress(
  invoiceAddress: BillingAddressFormValues,
  destination: io.flow.v0.models.OrderAddress,
  customerAddressBooks?: io.flow.v0.models.CustomerAddressBookContact[],
): io.flow.v0.models.BillingAddress {
  if (invoiceAddress.addressToUse === 'sameAsShippingAddress') {
    return billingAddressFromOrderAddress(destination);
  }

  if (customerAddressBooks != null) {
    const contact = find(customerAddressBooks, {
      id: invoiceAddress.addressToUse,
    });

    if (contact != null) {
      return {
        ...billingAddressFromAddressBook(contact),
        company: invoiceAddress.organizationName,
      };
    }
  }

  return billingAddressFromFormValues(invoiceAddress);
}

/**
 * Converts card form values into a `card_form`.
 */
export function toCardForm(
  values: CardFormValues,
  destination: io.flow.v0.models.OrderAddress,
  addressBookContacts: io.flow.v0.models.CustomerAddressBookContact[],
): io.flow.v0.models.CardForm {
  const {
    billingAddress,
    cardExpiryMonth,
    cardExpiryYear,
    cardName,
    cardNumber,
    cardSecurityCode,
    currencyCode,
    merchantOfRecord,
  } = values;

  const cardForm: Mutable<io.flow.v0.models.CardForm> = {
    name: cardName,
    number: cardNumber,
    // Flow API will automatically coerce expiration strings to numbers
    expiration_month: (cardExpiryMonth as unknown) as number,
    expiration_year: (cardExpiryYear as unknown) as number,
    cvv: cardSecurityCode,
    address: getBillingAddress(billingAddress, destination, addressBookContacts),
  };

  if (merchantOfRecord != null) {
    cardForm.metadata = {
      merchant_of_record: merchantOfRecord,
    };
  }

  if (currencyCode != null) {
    cardForm.requested_currency = currencyCode;
  }

  return cardForm;
}

export function toACHForm(
  amount: number,
  currency: string,
  destination: io.flow.v0.models.OrderAddress,
  values: AchFormValues,
): io.flow.v0.unions.AuthorizationForm {
  const {
    abaRoutingNumber,
    accountHolderName,
    bankAccountNumber,
    billingAddress,
    orderNumber,
  } = values;

  return {
    discriminator: AuthorizationFormDiscriminator.ACH,
    account_owner_name: accountHolderName,
    account_number: bankAccountNumber,
    amount,
    billing_address: getBillingAddress(billingAddress, destination),
    currency,
    order_number: orderNumber,
    routing_number: abaRoutingNumber,
  };
}
