import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import map from 'lodash/map';

import { MOBILE_UX_SPACING, OPTIMIZE_MOBILE_CTAS } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import { Grid, Row, Column } from '../grid';
import Button from '../button';
import ChevronLeft from '../svg-icons/chevron-left';
import Deliveries from '../deliveries';
import OrderError from '../../containers/order-error';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./shipping-method-form.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('shipping-method-form');

class ShippingMethodForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChangeDeliveryOption = this.handleChangeDeliveryOption.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
  }

  handleChangeDeliveryOption(deliveryId, deliveryOptionId) {
    const { deliveries, organization, onRequestUpdateShippingMethod } = this.props;
    const selections = map(deliveries, (delivery) => (
      delivery.id === deliveryId ? deliveryOptionId : delivery.selection
    ));
    onRequestUpdateShippingMethod(organization, selections);
  }

  // eslint-disable-next-line class-methods-use-this
  handleContinueClick() {
    trackHeapEvent('continue_click', {
      step: 'shipping_method',
    });
  }

  render() {
    const {
      contactInfoUrl,
      deliveries,
      deliveredDuty,
      deliveredDutyDisplayType,
      deliveredDutySetting,
      disabled,
      error,
      paymentInfoUrl,
    } = this.props;
    return (
      <div className={bem.block()}>
        <FeatureToggle
          featureKey={MOBILE_UX_SPACING}
          render={({ isFeatureEnabled }) => (
            <Grid parent={isFeatureEnabled}>
              <Row>
                <Column xs={12}>
                  <Deliveries
                    deliveries={deliveries}
                    deliveredDuty={deliveredDuty}
                    deliveredDutyDisplayType={deliveredDutyDisplayType}
                    deliveredDutySetting={deliveredDutySetting}
                    onChangeDeliveryOption={this.handleChangeDeliveryOption}
                  />
                </Column>
                {error && (
                  <Column xs={12}>
                    <OrderError className={bem.element('error')} error={error} />
                  </Column>
                )}
                <Column xs={12} md={6} mdPush={6}>
                  <Button
                    as={Link}
                    automationId="continue-button"
                    to={paymentInfoUrl}
                    disabled={disabled}
                    className={bem.element('continue-button')}
                    fluid
                    color="secondary"
                    onClick={this.handleContinueClick}
                  >
                    <FormattedMessage
                      id="checkout_shipping_method_action_continue"
                      defaultMessage="Continue"
                      description="Text describing an action to continue to the next step in the checkout process"
                    />
                  </Button>
                </Column>
                <Column xs={12} md={6} mdPull={6}>
                  <div className="hidden-sm-down">
                    <Button
                      as={Link}
                      automationId="return-to-customer-information"
                      to={contactInfoUrl}
                      icon={<ChevronLeft />}
                      disabled={disabled}
                      className={bem.element('return-button')}
                      flat
                      fluid
                      text={(
                        <FormattedMessage
                          id="checkout_return_to_customer_information"
                          defaultMessage="Return to Customer Information"
                          description="Text describing an action to transition customers back to the customer information step of the checkout process"
                        />
                      )}
                    />
                  </div>
                  <FeatureToggle
                    featureKey={OPTIMIZE_MOBILE_CTAS}
                    render={({ isFeatureEnabled: optimizeMobileCtas }) => {
                      if (optimizeMobileCtas) {
                        return (
                          <div className="hidden-md-up">
                            <Button
                              as={Link}
                              automationId="return-to-customer-information"
                              to={contactInfoUrl}
                              disabled={disabled}
                              className={bem.element('return-button')}
                              flat
                              fluid
                              text={(
                                <FormattedMessage
                                  id="checkout_return_to_customer_information"
                                  defaultMessage="Return to Customer Information"
                                  description="Text describing an action to transition customers back to the customer information step of the checkout process"
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      return (
                        <div className="hidden-md-up">
                          <Button
                            as={Link}
                            automationId="return-to-customer-information"
                            to={contactInfoUrl}
                            disabled={disabled}
                            className={bem.element('return-button')}
                            fluid
                            color="tertiary"
                          >
                            <FormattedMessage
                              id="checkout_return_to_customer_information"
                              defaultMessage="Return to Customer Information"
                              description="Text describing an action to transition customers back to the customer information step of the checkout process"
                            />
                          </Button>
                        </div>
                      );
                    }}
                  />
                </Column>
              </Row>
            </Grid>
          )}
        />
      </div>
    );
  }
}

ShippingMethodForm.displayName = 'ShippingMethodForm';

ShippingMethodForm.propTypes = {
  contactInfoUrl: PropTypes.string.isRequired,
  error: ApiPropTypes.orderError,
  deliveries: PropTypes.arrayOf(ApiPropTypes.delivery).isRequired,
  deliveredDutyDisplayType: ApiPropTypes.deliveredDutyDisplayType.isRequired,
  deliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
  deliveredDutySetting: ApiPropTypes.deliveredDutySetting.isRequired,
  disabled: PropTypes.bool.isRequired,
  onRequestUpdateShippingMethod: PropTypes.func.isRequired,
  organization: PropTypes.string.isRequired,
  paymentInfoUrl: PropTypes.string.isRequired,
};

ShippingMethodForm.defaultProps = {
  error: undefined,
};

export default ShippingMethodForm;
