import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.internal.v0.models.FeatureValueResult;

const initialState: State = {
  values: [],
};

const reducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return update(previousState, { $set: action.payload.features });
    case actionTypes.CHECK_FEATURE_VALUES_SUCCESS:
      return update(previousState, { $set: action.payload });
    case actionTypes.SET_FEATURE_VALUE:
      return update(previousState, {
        values: {
          // TODO: If features aren't loaded this action might be missed.
          // I think we added this to override feature values at runtime.
          $set: previousState.values.map((previousFeatureValue) => {
            if (previousFeatureValue.feature.key !== action.payload.featureKey) {
              return previousFeatureValue;
            }

            return update(previousFeatureValue, {
              value: { $set: action.payload.featureValue },
            });
          }),
        },
      });
    default:
      return previousState;
  }
};

export default reducer;
