import { createSelector } from 'reselect';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import identity from 'lodash/identity';
import mapValues from 'lodash/mapValues';
import ReadyState from '../../../utilities/redux/ready-state';

/**
 * @typedef {import('../../types').RootState} RootState
 */

const getContentState = createSelector(
  identity,
  /**
   * @type {function(RootState): Checkout}
   */
  (state) => get(state, 'content'),
);

export const getCustomerServiceLabel = createSelector(
  getContentState,
  (state) => get(state, 'content.customer_service.label'),
);

export const getCustomerServiceContents = createSelector(
  getContentState,
  (state) => get(state, 'content.customer_service.contents'),
);

export const getLogoUrl = createSelector(
  getContentState,
  (state) => get(state, 'content.checkout.logo.url'),
);

export const getMarketingOptIns = createSelector(
  getContentState,
  /**
   * @type {function(Checkout): Checkbox[]}
   */
  (state) => get(state, 'content.marketing.optins', []),
);

export const getMarketingOptInsInitialFormValues = createSelector(
  getMarketingOptIns,
  (optIns) => mapValues(keyBy(optIns, 'name'), 'value'),
);

export const getPrivacyPolicy = createSelector(
  getContentState,
  (state) => get(state, 'content.checkout.privacy_policy'),
);

export const getCheckoutContactUs = createSelector(
  getContentState,
  (state) => get(state, 'content.checkout.contact_us'),
);

export const getOrderSummaryMessage = createSelector(
  getContentState,
  (state) => get(state, 'content.checkout.order_summary_message'),
);

export const getTermAndConditions = createSelector(
  getContentState,
  (state) => get(state, 'content.checkout.terms'),
);

export const getFooterContents = createSelector(
  getContentState,
  (state) => get(state, 'content.footer'),
);

export const getIsContentFulfilled = createSelector(
  getContentState,
  (state) => get(state, 'statuses.content') === ReadyState.Fulfilled,
);

export const getPayPalDisclaimer = createSelector(
  getContentState,
  (state) => get(state, 'content.payments.paypal.processor_description'),
);
