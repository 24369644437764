import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function setGiftMessagingContentState(
  isOpen: boolean,
): RootAction {
  return {
    type: actionTypes.SET_GIFT_MESSAGING_CONTENT_STATE,
    payload: {
      isOpen,
    },
  };
}
