/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import isNil from 'lodash/isNil';

import TriangleDown from '../svg-icons/triangle-down';

const bem = new BemHelper('panel');

class PanelTitle extends Component {
  getAriaProps() {
    const { collapsible, expanded } = this.props;
    const props = {};

    if (collapsible) {
      props.role = 'tab';
      props.selected = expanded;
      props.expanded = expanded;
    }

    return props;
  }

  render() {
    const {
      className, children, collapsible, expanded, text,
    } = this.props;
    const ElementType = getElementType(PanelTitle, this.props);
    const ariaProps = this.getAriaProps();
    const unhandledProps = getUnhandledProps(PanelTitle, this.props);
    const modifiers = { collapsible, expanded };

    if (!isNil(children)) {
      return (
        <ElementType
          {...ariaProps}
          {...unhandledProps}
          className={bem.element('title', modifiers, className)}
        >
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType
        {...ariaProps}
        {...unhandledProps}
        className={bem.element('title', modifiers, className)}
      >
        {collapsible && <TriangleDown className={bem.element('icon')} />}
        {text}
      </ElementType>
    );
  }
}

PanelTitle.displayName = 'PanelTitle';

PanelTitle.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  text: PropTypes.string,
};

PanelTitle.defaultProps = {
  as: 'h4',
  children: undefined,
  className: '',
  collapsible: false,
  expanded: false,
  text: undefined,
};

export default PanelTitle;
