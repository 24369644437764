import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { createTransactionEvents } from '../../../store/checkout';
import type { MergedProps as Props } from '../types';

const ConfirmationPageV2: React.FC<Props> = ({
  params,
  organization,
}) => {
  const checkoutv2Id = params.checkoutV2Id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createTransactionEvents(organization));
    dispatch(push(`/checkouts/v2-${checkoutv2Id}/confirmation`));
  });

  return (
    <>
      Please wait...
    </>
  );
};

export default ConfirmationPageV2;
