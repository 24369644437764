/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

if (process.browser) {
  require('./divider.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('divider');

class Divider extends React.PureComponent {
  render() {
    const { className } = this.props;
    const unhandledProps = getUnhandledProps(Divider, this.props);

    return (
      <div {...unhandledProps} className={bem.block(className)} />
    );
  }
}

Divider.displayName = 'Divider';

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: '',
};

export default Divider;
