import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CartItem from '../components/cart-item';
import {
  getIsWrapCartItemEnabled,
} from '../store/checkout/selectors';

const mapStateToProps = createStructuredSelector({
  isWrapCartItemEnabled: getIsWrapCartItemEnabled,
});

export default connect(mapStateToProps)(CartItem);
