import { withFetch } from '@flowio/redux-fetch';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { push } from 'react-router-redux';
import isEmpty from 'lodash/isEmpty';

import { fetchCountries } from '../store/reference/actions';
import {
  fetchCheckoutBundle,
  updatePaypalInfo,
  updateGooglePayInfo,
} from '../store/checkout/actions';
import submitPaymentInformationFromReview from '../store/checkout/actions/submit-payment-information-from-review';
import { getCountries } from '../store/reference/selectors';
import {
  getContinueShoppingUrl,
  getIsDeliveredDutyCustomerChoice,
  getOrderDeliveredDuty,
  getOrderDestination,
  getSelectedDeliveryOptions,
  getOrderNumber,
  getVrnFromRegistration,
  allowInvoiceAddress,
  getPaymentMethodRules,
  getGooglePaymentDataRequest,
  getReviewBillingAddress,
  getReviewInvoiceAddress,
  getReviewPaymentMethodId,
  getReviewCard,
  getReviewErrors,
  getReviewEntities,
  getCustomerAddressBook,
  getCustomerPaymentSources,
  getCheckoutOrganization,
} from '../store/checkout/selectors';
import checkHttpStatus from '../utilities/check-http-status';
import OrderReviewStep from '../components/order-review-step';
import withExternalEvents from '../components/with-external-events';
import { getContactInfoUrl, getShippingMethodUrl, getPaymentInfoUrl } from '../store/navigation/selectors';
import injectFlow from '../components/injectFlow';
import { genericErrorFromString } from '../utilities/parsers';
import setReviewError from '../store/checkout/actions/set-review-error';
import generateFeatureQuery from '../utilities/generate-feature-query';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { organization, orderNumber } = params;
  const reviewEntities = getReviewEntities(getState());
  const paymentInfoUrl = getPaymentInfoUrl(getState());

  if (isEmpty(reviewEntities)) {
    dispatch(push(paymentInfoUrl));
    return Promise.resolve();
  }

  return Promise.all([
    dispatch(fetchCheckoutBundle(organization, orderNumber, {
      feature_q: generateFeatureQuery(organization),
    })).then(checkHttpStatus),
    dispatch(fetchCountries()),
  ]).catch((error) => {
    throw error;
  });
};

function handleSubmitOrder() {
  return function handleSubmitOrderEffects(dispatch) {
    return dispatch(submitPaymentInformationFromReview())
      .catch((error) => dispatch(setReviewError(error)));
  };
}

function createReviewError(error, dispatch) {
  const submissionError = new SubmissionError({ _error: error });
  dispatch(setReviewError(submissionError));
}

function handleGooglePaySuccess(paymentData) {
  return function handleGooglePaySuccessEffects(dispatch) {
    dispatch(updateGooglePayInfo(paymentData));
    return dispatch(handleSubmitOrder());
  };
}

function handleGooglePayError(errorMessage) {
  return function handleGooglePayErrorEffects(dispatch) {
    createReviewError(
      genericErrorFromString(errorMessage),
      dispatch,
    );
  };
}

function handlePayPalSuccess(payment) {
  return function handlePayPalSuccessSideEffects(dispatch) {
    dispatch(updatePaypalInfo(payment.paymentID, payment.payerID));
    dispatch(handleSubmitOrder());
  };
}

function handlePayPalError(error) {
  return function handlePayPalErrorSideEffects(dispatch) {
    createReviewError(error, dispatch);
  };
}

function handleKlarnaError(error) {
  return function handleKlarnaErrorSideEffects(dispatch) {
    createReviewError(error, dispatch);
  };
}

function handleKlarnaSuccess() {
  return function handleKlarnaSuccessSideEffects(dispatch) {
    return dispatch(handleSubmitOrder());
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onGooglePayError: handleGooglePayError,
  onGooglePaySuccess: handleGooglePaySuccess,
  onPayPalError: handlePayPalError,
  onPayPalSuccess: handlePayPalSuccess,
  onKlarnaFailure: handleKlarnaError,
  onKlarnaSuccess: handleKlarnaSuccess,
  onSubmitOrder: handleSubmitOrder,
}, dispatch);

const mapStateToProps = (state) => ({
  addressBook: getCustomerAddressBook(state),
  allowInvoiceAddress: allowInvoiceAddress(state),
  billingAddress: getReviewBillingAddress(state),
  error: getReviewErrors(state),
  card: getReviewCard(state),
  countries: getCountries(state),
  continueShoppingUrl: getContinueShoppingUrl(state),
  deliveryOptions: getSelectedDeliveryOptions(state),
  deliveredDuty: getOrderDeliveredDuty(state),
  destination: getOrderDestination(state),
  invoiceAddress: getReviewInvoiceAddress(state),
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice(state),
  googlePaymentDataRequest: getGooglePaymentDataRequest(state),
  orderNumber: getOrderNumber(state),
  organizationSummary: getCheckoutOrganization(state),
  vrn: getVrnFromRegistration(state),
  contactInfoUrl: getContactInfoUrl(state),
  shippingMethodUrl: getShippingMethodUrl(state),
  paymentMethodRules: getPaymentMethodRules(state),
  paymentInfoUrl: getPaymentInfoUrl(state),
  paymentMethodId: getReviewPaymentMethodId(state),
  paymentSources: getCustomerPaymentSources(state),
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  injectFlow,
  connect(mapStateToProps, mapDispatchToProps),
)(OrderReviewStep);
