import type { ProvincesGetParameters, ProvincesGetResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<ProvincesGetResponse>>;

type Options = Omit<ProvincesGetParameters, 'headers'>;

const fetchAdministrativeDivisionsRequest = (): RootAction => ({
  type: actionTypes.FETCH_ADMINISTRATIVE_DIVISIONS_REQUEST,
});

const fetchAdministrativeDivisionsSuccess = (
  provinces: io.flow.v0.models.Province[],
): RootAction => ({
  type: actionTypes.FETCH_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  payload: provinces,
});

const fetchAdministrativeDivisionsFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_ADMINISTRATIVE_DIVISIONS_FAILURE,
  payload: error,
});

const fetchAdministrativeDivisions = (
  options?: Options,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  dispatch(fetchAdministrativeDivisionsRequest());
  return extra.api.provinces.get(options)
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchAdministrativeDivisionsSuccess(response.result));
          break;
        case 401:
          dispatch(fetchAdministrativeDivisionsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchAdministrativeDivisions;
