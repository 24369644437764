/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import isNil from 'lodash/isNil';

import PanelTitle from './panel-title';

const bem = new BemHelper('panel');

class PanelHeader extends PureComponent {
  render() {
    const {
      children, className, collapsible, expanded, title,
    } = this.props;
    const ElementType = getElementType(PanelHeader, this.props);
    const unhandledProps = getUnhandledProps(PanelHeader, this.props);
    const modifiers = { collapsible, expanded };

    if (!isNil(children)) {
      return (
        <ElementType
          {...unhandledProps}
          className={bem.element('header', modifiers, className)}
        >
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType
        {...unhandledProps}
        className={bem.element('header', modifiers, className)}
      >
        <PanelTitle collapsible={collapsible} expanded={expanded} text={title} />
      </ElementType>
    );
  }
}

PanelHeader.displayName = 'PanelHeader';

PanelHeader.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  expanded: PropTypes.bool,
  title: PropTypes.string,
};

PanelHeader.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  collapsible: false,
  expanded: false,
  title: '',
};

export default PanelHeader;
