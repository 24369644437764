import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import uniqueId from 'lodash/uniqueId';
import type { ShopifyCartsPostChangeByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import legacyErrorConverter from '../../../utilities/converters/legacy-error-converter';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { ThunkResult, RootAction } from '../../types';
import type { LegacyError, LegacyResponse } from '../../../types';
import type { UpdateCartItemQuantityActionParameters } from '../types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';

type Result = Promise<LegacyResponse<ShopifyCartsPostChangeByIdResponse>>;

export function updateCartItemQuantityRequest(
  parameters: UpdateCartItemQuantityActionParameters,
  transactionId: string,
): RootAction {
  return {
    type: actionTypes.UPDATE_CART_ITEM_QUANTITY_REQUEST,
    params: parameters,
    optimist: {
      type: BEGIN,
      transactionId,
    },
  };
}

export function updateCartItemQuantitySuccess(
  shopifyCart: io.flow.v0.models.ShopifyCart,
  parameters: UpdateCartItemQuantityActionParameters,
  transactionId: string,
): RootAction {
  return {
    type: actionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS,
    payload: shopifyCart,
    params: parameters,
    optimist: {
      type: COMMIT,
      transactionId,
    },
  };
}

export function updateCartItemQuantityFailure(
  error: LegacyError | io.flow.v0.models.GenericError,
  parameters: UpdateCartItemQuantityActionParameters,
  transactionId: string,
): RootAction {
  return {
    type: actionTypes.UPDATE_CART_ITEM_QUANTITY_FAILURE,
    payload: error,
    params: parameters,
    optimist: {
      type: REVERT,
      transactionId,
    },
  };
}

export const updateCartItemQuantity = (
  cartId: string,
  cartItemVariantId: number,
  quantity: number,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  const transactionId = uniqueId('transaction');

  const parameters: UpdateCartItemQuantityActionParameters = {
    cartId,
    cartItemVariantId,
    quantity,
  };

  dispatch(updateCartItemQuantityRequest(
    parameters,
    transactionId,
  ));

  return extra.api.shopifyCarts.postChangeById({
    id: cartId,
    body: {
      quantity,
      id: cartItemVariantId,
    },
  }).then((response) => {
    switch (response.status) {
      case 200:
        dispatch(updateCartItemQuantitySuccess(
          response.body,
          parameters,
          transactionId,
        ));
        break;
      case 401:
      case 404:
        dispatch(updateCartItemQuantityFailure(
          legacyErrorConverter.fromStatusCode(response.status),
          parameters,
          transactionId,
        ));
        break;
      case 422:
        dispatch(updateCartItemQuantityFailure(
          response.body,
          parameters,
          transactionId,
        ));
        break;
      default:
        exhaustiveCheck(response);
    }

    return legacyResponseConverter.fromHttpResponse(response);
  });
};
