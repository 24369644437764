import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import { getLanguage } from '../../intl/selectors';
import { getReturnUrl } from '../../flow/selectors';
import { shouldUpdateReturnUrlAttribute, getOrderDenormalized } from '../selectors';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import orderPutFormConverter from '../../../utilities/converters/order-put-form-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';
import UrlParameters from '../../../constants/url-parameters';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse> | void>;

const updateReturnUrlAttributeRequest = (
  organizationId: string,
): RootAction => ({
  type: actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_REQUEST,
  params: {
    organization: organizationId,
  },
});

const updateReturnUrlAttributeSuccess = (
  organizationId: string,
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_SUCCESS,
  payload: orderBuilder,
  params: {
    organization: organizationId,
  },
});

const updateReturnUrlAttributeFailure = (
  organizationId: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.UPDATE_RETURN_URL_ATTRIBUTE_FAILURE,
  payload: error,
  params: {
    organization: organizationId,
  },
});

const syncReturnUrlAttribute = (
  organizationId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const order = getOrderDenormalized(getState());
  const orderNumber = order.number;
  const orderPutForm = orderPutFormConverter.fromOrder(order);
  const returnUrl = getReturnUrl(getState());
  const updateRequired = shouldUpdateReturnUrlAttribute(getState());

  if (!updateRequired) {
    return Promise.resolve();
  }

  dispatch(updateReturnUrlAttributeRequest(organizationId));
  return extra.api.orderBuilders.putByNumber({
    body: {
      ...orderPutForm,
      attributes: {
        ...orderPutForm.attributes,
        [UrlParameters.RETURN_URL]: returnUrl,
      },
    },
    language: getLanguage(getState()),
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(updateReturnUrlAttributeSuccess(organizationId, response.result));
          break;
        case 401:
        case 404:
          dispatch(updateReturnUrlAttributeFailure(organizationId, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default syncReturnUrlAttribute;
