import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { fetchCheckoutBundle, redirectSubmittedOrder, syncReturnUrlAttribute } from '../store/checkout/actions';
import { fetchCountries } from '../store/reference/actions';
import { getCountries } from '../store/reference/selectors';
import { getContactInfoUrl } from '../store/navigation/selectors';
import {
  getIsDeliveredDutyCustomerChoice,
  getIsSingleDeliveredDutyDisplayType,
  getOrderDestination,
  getVrnFromRegistration,
  getInvoiceAddress,
  fetchPriceConversion,
} from '../store/checkout';
import checkHttpStatus from '../utilities/check-http-status';
import ShippingMethodStep from '../components/shipping-method-step';
import withExternalEvents from '../components/with-external-events';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import generateFeatureQuery from '../utilities/generate-feature-query';
import checkShippingMethodPromptBehavior from '../utilities/check-shipping-method-prompt-behavior';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params, location } = props;
  const { organization, orderNumber } = params;
  return Promise.all([
    dispatch(fetchCheckoutBundle(organization, orderNumber, {
      feature_q: generateFeatureQuery(organization),
    })).then(checkHttpStatus).then(() => {
      checkShippingMethodPromptBehavior(location, getState());
    }),
    dispatch(fetchCountries()),
    dispatch(fetchPriceConversion()),
  ]).then(() => (
    dispatch(syncReturnUrlAttribute(organization))
  )).then(() => (
    dispatch(redirectSubmittedOrder())
  )).catch((error) => {
    logInternalServerError(error, orderNumber);
    throw error;
  });
};

const mapStateToProps = createStructuredSelector({
  countries: getCountries,
  invoiceAddress: getInvoiceAddress,
  vrn: getVrnFromRegistration,
  destination: getOrderDestination,
  editShippingAddressUrl: getContactInfoUrl,
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice,
  isSingleDeliveredDutyDisplayType: getIsSingleDeliveredDutyDisplayType,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(ShippingMethodStep);
