import type { OrderBuildersPutByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<OrderBuildersPutByNumberResponse>>;

function updateOrderBuilderRequest(): RootAction {
  return {
    type: actionTypes.UPDATE_ORDER_BUILDER_REQUEST,
  };
}

function updateOrderBuilderSuccess(
  orderBuilder: io.flow.v0.models.OrderBuilder,
): RootAction {
  return {
    type: actionTypes.UPDATE_ORDER_BUILDER_SUCCESS,
    payload: orderBuilder,
  };
}

function updateOrderBuilderFailure(
  error: LegacyError,
): RootAction {
  return {
    type: actionTypes.UPDATE_ORDER_BUILDER_FAILURE,
    payload: error,
  };
}

export default function updateOrderBuilder(
  organizationId: string,
  orderNumber: string,
  orderPutForm: io.flow.v0.models.OrderPutForm,
): ThunkResult<Result> {
  return function updateOrderBuilderEffects(dispatch, getState, extra) {
    dispatch(updateOrderBuilderRequest());
    return extra.api.orderBuilders.putByNumber({
      body: orderPutForm,
      number: orderNumber,
      organization: organizationId,
      expand: ['experience'],
    })
      .then(legacyResponseConverter.withLegacyError)
      .then((response) => {
        switch (response.status) {
          case 200:
          case 201:
            dispatch(updateOrderBuilderSuccess(response.result));
            break;
          case 401:
          case 404:
            dispatch(updateOrderBuilderFailure(response.result));
            break;
          default:
            exhaustiveCheck(response);
        }
        return response;
      });
  };
}
