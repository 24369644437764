/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getValueFromEvent from '@flowio/react-helpers/lib/getValueFromEvent';
import noop from 'lodash/noop';

if (process.browser) {
  require('./textbox.css');
}

const bem = new BemHelper('textbox');

class Textbox extends Component {
  handleChange = (event) => {
    const { onChange } = this.props;
    const value = getValueFromEvent(event);
    onChange(event, value);
  }

  render() {
    const { className, type, size } = this.props;
    const ElementType = getElementType(Textbox, this.props);
    const unhandledProps = getUnhandledProps(Textbox, this.props);
    const modifiers = { [size]: true };

    const textBox = (
      <ElementType
        {...unhandledProps}
        className={bem.block(className, modifiers)}
        onChange={this.handleChange}
        type={type}
      />
    );

    return (type === 'text' ? (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
      <label>{textBox}</label>
    ) : textBox);
  }
}

Textbox.displayName = 'Textbox';

Textbox.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'large']),
  type: PropTypes.oneOf(['email', 'number', 'search', 'text', 'tel', 'url']),
};

Textbox.defaultProps = {
  as: 'input',
  className: '',
  onChange: noop,
  size: 'medium',
  type: 'text',
};

export default Textbox;
