import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';
import get from 'lodash/get';
import noop from 'lodash/noop';
import overEvery from 'lodash/overEvery';
import BemHelper from '@flowio/bem-helper';

import CustomerInfoSummary from '../customer-info-summary';
import { Row, Column } from '../grid';
import Button from '../button';
import GooglePayButton from '../google-pay-button';
import PaymentTypes from '../../constants/payment-types';
import Features from '../../constants/features';
import ChevronLeft from '../svg-icons/chevron-left';
import GooglePayPropTypes from '../google-pay-button/google-pay-prop-types';
import { isPaymentMethodId, isPaymentMethodType } from '../../utilities/payment-method-rule-utilities';
import PayPalButton from '../paypal-button';
import { billingAddressFromOrderAddress, billingAddressFromAddressBook, billingAddressFromFormValues } from '../../containers/payment-form/utilities';
import OrderSubmissionError from '../order-submission-error';
import { getContactById } from '../../utilities/address-book-utilities';
import FeatureToggle from '../feature-toggle';
import { OPTIMIZE_MOBILE_CTAS } from '../../../common/constants/feature-keys';
import KlarnaButtonV2 from '../klarna-button-v2';

if (process.browser) {
  require('./order-review-step.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('order-review-step');

function getFormattedAddress(addressBook, formData, destination) {
  let formattedAddress;

  if (formData != null) {
    if (formData.addressToUse === 'sameAsShippingAddress') {
      formattedAddress = billingAddressFromOrderAddress(destination);
    } else if (formData.addressToUse === 'newAddress') {
      return billingAddressFromFormValues(formData);
    } else if (addressBook != null) {
      const contact = getContactById(addressBook, formData.addressToUse);
      if (contact != null) {
        formattedAddress = billingAddressFromAddressBook(contact);
        formattedAddress.company = formData.organizationName;
      }
    }
  }

  return formattedAddress;
}

const OrderReviewStep = ({
  addressBook,
  allowInvoiceAddress,
  organizationSummary,
  billingAddress,
  card,
  countries,
  deliveredDuty,
  deliveryOptions,
  destination,
  invoiceAddress,
  isDeliveredDutyCustomerChoice,
  vrn,
  error,
  paymentMethodId,
  contactInfoUrl,
  shippingMethodUrl,
  paymentInfoUrl,
  paymentMethodRules,
  onKlarnaSuccess,
  onKlarnaFailure,
  onPayPalSuccess,
  onPayPalError,
  onGooglePaySuccess,
  onGooglePayError,
  orderNumber,
  googlePaymentDataRequest,
  onSubmitOrder,
  paymentSources,
}) => {
  const selectedOnlinePaymentMethodRule = find(paymentMethodRules, overEvery([
    isPaymentMethodType('online'),
    isPaymentMethodId(paymentMethodId),
  ]));

  const formattedBillingAddress = getFormattedAddress(addressBook, billingAddress, destination);
  const formattedInvoiceAddress = getFormattedAddress(addressBook, invoiceAddress, destination);

  return (
    <div className={bem.block()} data-automation-id="review-page">
      <CustomerInfoSummary
        card={card}
        allowInvoiceAddress={allowInvoiceAddress}
        invoiceAddress={formattedInvoiceAddress}
        billingAddress={formattedBillingAddress}
        countries={countries}
        deliveredDuty={deliveredDuty}
        deliveryOptions={deliveryOptions}
        destination={destination}
        isDeliveredDutyCustomerChoice={isDeliveredDutyCustomerChoice}
        contactInfoUrl={contactInfoUrl}
        shippingMethodUrl={shippingMethodUrl}
        paymentInfoUrl={paymentInfoUrl}
        paymentMethodId={paymentMethodId}
        paymentSources={paymentSources}
        isReview
        vrn={vrn}
      />
      <Row>
        {error && (
          <Column xs={12}>
            <OrderSubmissionError orderNumber={orderNumber} className={bem.element('error')} error={error} />
          </Column>
        )}
      </Row>
      <Row>
        <Column xs={12} md={6} mdPush={6}>
          {(() => {
            switch (paymentMethodId) {
              case PaymentTypes.Klarna: return (
                <FeatureToggle
                  featureKey={Features.KLARNA_REDIRECT}
                  render={({ isFeatureEnabled: klarnaRedirectEnabled }) => {
                    if (klarnaRedirectEnabled) {
                      return (
                        <Button
                          automationId="submit-payment-form"
                          onClick={() => onSubmitOrder()}
                          color="positive"
                          fluid
                        >
                          <FormattedMessage
                            id="checkout_complete_order_with_online_payment_method"
                            description="A message for action that will attempt to create an authorization for the selected online payment method before submitting the order"
                            defaultMessage="Complete with {paymentMethod}"
                            values={{ paymentMethod: get(selectedOnlinePaymentMethodRule, 'payment_method.name') }}
                          />
                        </Button>
                      );
                    }

                    return (
                      <KlarnaButtonV2
                        onAuthorized={onKlarnaSuccess}
                        onError={onKlarnaFailure}
                      />
                    );
                  }}
                />
              );
              case PaymentTypes.PayPal: return (
                <PayPalButton
                  onError={onPayPalError}
                  onSuccess={onPayPalSuccess}
                />
              );
              case PaymentTypes.GooglePay: return (
                <GooglePayButton
                  isReadyToPayRequest={googlePaymentDataRequest}
                  onError={onGooglePayError}
                  onSuccess={onGooglePaySuccess}
                  organizationSummary={organizationSummary}
                  paymentDataRequest={googlePaymentDataRequest}
                />
              );
              default: return (
                <Button
                  automationId="submit-payment-form"
                  onClick={() => onSubmitOrder()}
                  color="positive"
                  fluid
                >
                  {selectedOnlinePaymentMethodRule ? (
                    <FormattedMessage
                      id="checkout_complete_order_with_online_payment_method"
                      description="A message for action that will attempt to create an authorization for the selected online payment method before submitting the order"
                      defaultMessage="Complete with {paymentMethod}"
                      values={{ paymentMethod: get(selectedOnlinePaymentMethodRule, 'payment_method.name') }}
                    />
                  ) : (
                    <FormattedMessage
                      id="checkout_complete_order_with_card"
                      description="A message for action that will attempt to create a card authorization before submitting the order"
                      defaultMessage="Complete Order"
                    />
                  )}
                </Button>
              );
            }
          })()}
        </Column>
        <Column xs={12} md={6} mdPull={6}>
          <div className="hidden-sm-down">
            <Button
              as={Link}
              automationId="return-to-payment-method"
              flat
              fluid
              icon={<ChevronLeft />}
              to={paymentInfoUrl}
              text={(
                <FormattedMessage
                  id="checkout_return_to_payment_method"
                  description="A message indicating that interactions will transition customers to the payment method step"
                  defaultMessage="Return to Payment Method"
                />
              )}
            />
          </div>
          <FeatureToggle
            featureKey={OPTIMIZE_MOBILE_CTAS}
            render={({ isFeatureEnabled: optimizeMobileCtas }) => {
              if (optimizeMobileCtas) {
                return (
                  <div className="hidden-md-up">
                    <Button
                      as={Link}
                      automationId="return-to-payment-method"
                      flat
                      fluid
                      to={paymentInfoUrl}
                      text={(
                        <FormattedMessage
                          id="checkout_return_to_payment_method"
                          description="A message indicating that interactions will transition customers to the payment method step"
                          defaultMessage="Return to Payment Method"
                        />
                      )}
                    />
                  </div>
                );
              }
              return (
                <div className="hidden-md-up">
                  <Button
                    as={Link}
                    automationId="return-to-payment-method"
                    color="tertiary"
                    fluid
                    to={paymentInfoUrl}
                  >
                    <FormattedMessage
                      id="checkout_return_to_payment_method"
                      description="A message indicating that interactions will transition customers to the payment method step"
                      defaultMessage="Return to Payment Method"
                    />
                  </Button>
                </div>
              );
            }}
          />
        </Column>
      </Row>
    </div>
  );
};

OrderReviewStep.propTypes = {
  addressBook: ApiPropTypes.customerAddressBook,
  allowInvoiceAddress: PropTypes.bool,
  organizationSummary: ApiPropTypes.organizationSummary.isRequired,
  billingAddress: ApiPropTypes.billingAddress,
  invoiceAddress: ApiPropTypes.billingAddress,
  card: ApiPropTypes.card,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  deliveredDuty: PropTypes.string.isRequired,
  deliveryOptions: PropTypes.arrayOf(ApiPropTypes.deliveryOption).isRequired,
  destination: ApiPropTypes.orderAddress.isRequired,
  isDeliveredDutyCustomerChoice: PropTypes.bool.isRequired,
  vrn: PropTypes.string,
  paymentMethodRules: PropTypes.arrayOf(ApiPropTypes.paymentMethodRule).isRequired,
  contactInfoUrl: PropTypes.string.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
  paymentInfoUrl: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.string.isRequired,
  onKlarnaSuccess: PropTypes.func,
  onKlarnaFailure: PropTypes.func,
  onPayPalSuccess: PropTypes.func,
  onPayPalError: PropTypes.func,
  onGooglePaySuccess: PropTypes.func,
  onGooglePayError: PropTypes.func,
  onSubmitOrder: PropTypes.func,
  googlePaymentDataRequest: GooglePayPropTypes.paymentDataRequest.isRequired,
  error: PropTypes.oneOfType([
    // Expected when payment authorization is declined.
    PropTypes.shape({
      authorization: ApiPropTypes.authorization.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stack: PropTypes.string,
    }),
    // Expected when HTTP status error is thrown for card tokenization endpoint
    ApiPropTypes.cardError,
    // Expected when HTTP status error is thrown for any endpoint called to complete the order
    ApiPropTypes.genericError,
  ]),
  orderNumber: PropTypes.string.isRequired,
  paymentSources: PropTypes.arrayOf(ApiPropTypes.paymentSource),
};

OrderReviewStep.defaultProps = {
  addressBook: undefined,
  allowInvoiceAddress: false,
  invoiceAddress: undefined,
  card: undefined,
  error: undefined,
  billingAddress: undefined,
  vrn: undefined,
  onKlarnaSuccess: noop,
  onKlarnaFailure: noop,
  onPayPalSuccess: noop,
  onPayPalError: noop,
  onGooglePaySuccess: noop,
  onGooglePayError: noop,
  onSubmitOrder: noop,
  paymentSources: [],
};

export default OrderReviewStep;
