import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { visitContactInfoStep } from '../store/navigation/actions';

import {
  continueShopping,
  closeRestrictedItemsModal,
  removeItems,
} from '../store/checkout/actions';

import {
  isRestrictedItemsModalOpen,
  getIsOrderLoading,
  getRestrictedItemNumbers,
  getOrderError,
  getOrder,
} from '../store/checkout/selectors';

import RestrictedItemsModal from '../components/restricted-items-modal';
import isOrderBuilderResponseOk from '../../common/utilities/is-order-builder-response-ok';
import { getOrganization } from '../store/flow/selectors';
import { getVisitorId, getSessionAttribute } from '../utilities/session';

const mapDispatchToProps = (dispatch) => ({
  onChangeAddress() {
    dispatch(closeRestrictedItemsModal());
    dispatch(visitContactInfoStep());
  },
  onClearCartAndContinueShopping() {
    const csrfToken = getSessionAttribute('flow_csrf_token') || getVisitorId();

    dispatch(continueShopping({
      flow_cart_action: 'clear',
      flow_csrf_token: csrfToken,
    }));
  },
  onRemoveRestrictedItems(organizationId, orderNumber, numbers) {
    dispatch(removeItems(organizationId, orderNumber, numbers)).then((response) => {
      if (isOrderBuilderResponseOk(response)) {
        dispatch(closeRestrictedItemsModal());
      }
    });
  },
});

const mapStateToProps = createStructuredSelector({
  error: getOrderError,
  isLoading: getIsOrderLoading,
  isOpen: isRestrictedItemsModalOpen,
  numbers: getRestrictedItemNumbers,
  order: getOrder,
  organizationId: getOrganization,
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedItemsModal);
