import { Field } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid, Row, Column } from '../grid';
import Button from '../button';
import OrderError from '../../containers/order-error';
import TextField from '../redux-form/text-field';
import FeatureToggle from '../feature-toggle';
import { COLLAPSED_ORDER_SUMMARY } from '../../../common/constants/feature-keys';

if (process.browser) {
  require('./coupon-form.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('coupon-form');

const CouponForm = ({
  error,
  handleSubmit,
  submitting,
  intl,
  mobile,
}) => (
  <form className={bem.block()} noValidate onSubmit={handleSubmit}>
    <FeatureToggle
      featureKey={COLLAPSED_ORDER_SUMMARY}
      render={({ isFeatureEnabled }) => (
        <Grid parent={mobile && isFeatureEnabled}>
          <Row>
            <Column xs={isFeatureEnabled ? 9 : 12} sm={10} lg={8} className={bem.element('text-field-column')}>
              <Field
                className={bem.element('text-field')}
                component={TextField}
                size={mobile ? 'small' : 'large'}
                labelText={!mobile ? (
                  <FormattedMessage
                    id="checkout_coupon_field_label_discount_code"
                    description="Text describing an input that takes a code to apply a discount to an order"
                    defaultMessage="Discount Code"
                  />
                ) : undefined}
                hintText={mobile ? intl.formatMessage({
                  id: 'checkout_coupon_field_label_discount_code',
                  defaultMessage: 'Discount Code',
                  description: 'Text describing an input that takes a code to apply a discount to an order',
                }) : undefined}
                name="promotionCode"
              />
            </Column>
            <Column xs={isFeatureEnabled ? 3 : 12} sm={2} lg={4} className={bem.element('button-column')}>
              <Button
                className={bem.element('apply')}
                disabled={submitting}
                fluid
                size={mobile ? 'small' : 'large'}
                type="submit"
              >
                <FormattedMessage
                  id="checkout_coupon_button_apply"
                  description="The text of an action to apply a discount to an order"
                  defaultMessage="Apply"
                />
              </Button>
            </Column>
            {error && (
              <Column xs={12}>
                <OrderError className={bem.element('error')} error={error} />
              </Column>
            )}
          </Row>
        </Grid>
      )}
    />
  </form>
);

CouponForm.displayName = 'CouponForm';

CouponForm.propTypes = {
  error: ApiPropTypes.orderError,
  handleSubmit: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

CouponForm.defaultProps = {
  error: undefined,
  mobile: false,
};

export default injectIntl(CouponForm);
