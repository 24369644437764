/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

if (process.browser) {
  require('./dialog-actions.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('dialog-actions');

const DialogActions = ({ children, ...unhandledProps }) => (
  <div {...unhandledProps} className={bem.block()}>
    {children}
  </div>
);

DialogActions.displayName = 'DialogActions';

DialogActions.propTypes = {
  children: PropTypes.node,
};

DialogActions.defaultProps = {
  children: undefined,
};

export default DialogActions;
