import get from 'lodash/get';
import find from 'lodash/find';

export default class ItemContentHelper {
  public itemContents: io.flow.v0.models.CheckoutItemContent[];

  public constructor(itemContents: io.flow.v0.models.CheckoutItemContent[]) {
    this.itemContents = itemContents;
  }

  public getItem(number: string): io.flow.v0.models.Item | undefined {
    const findPredicate = (itemContent: io.flow.v0.models.CheckoutItemContent): boolean => (
      itemContent.item.number === number
    );

    return get(
      find(this.itemContents, findPredicate),
      'item',
    );
  }

  public getItemAttributes(number: string): {} | { string: string } {
    return get(this.getItem(number), 'attributes', {});
  }
}
