import { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import storage from '@flowio/storage';
import { replace } from 'react-router-redux';

import { getOrganization, getJwt } from '../store/flow/selectors';
import { getOrderNumber, getCheckoutId } from '../store/checkout/selectors';
import { getCartId } from '../store/cart/selectors';

const EnterCheckout = (props) => {
  useEffect(() => {
    props.onLoad(props);
  }, []);
  return null;
};

const mapStateToProps = createStructuredSelector({
  cartId: getCartId,
  checkoutId: getCheckoutId,
  jwt: getJwt,
  organizationId: getOrganization,
  orderNumber: getOrderNumber,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onLoad({
    cartId,
    checkoutId,
    jwt,
    organizationId,
    orderNumber,
  }) {
    const previousJwt = storage.cookie.get('bearer');

    if (jwt != null && previousJwt !== jwt) {
      storage.cookie.set('bearer', jwt, { path: '/' });
    }

    if (checkoutId != null) {
      return replace(`/checkouts/${checkoutId}/contact-info`);
    }

    if (orderNumber != null) {
      return replace(`/${organizationId}/checkout/${orderNumber}/contact-info`);
    }

    if (cartId != null) {
      return replace(`/${organizationId}/cart/${cartId}`);
    }

    return replace(`/${organizationId}/error-pages/500`);
  },
}, dispatch);

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhanced(EnterCheckout);
