import get from 'lodash/get';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import OrderBalanceError from './order-balance-error';

function getBalanceAmount(orderOrOrderBuilder) {
  return get(
    orderOrOrderBuilder,
    'balance.amount',
    get(orderOrOrderBuilder, 'order.balance.amount'),
  );
}

export function throwIfPendingBalance(orderOrOrderBuilder) {
  const balanceAmount = getBalanceAmount(orderOrOrderBuilder);
  if (balanceAmount > 0) {
    throw new OrderBalanceError();
  }
}

/**
 * Use this utility to verity the order has no pending balance before submitting.
 * @param {Order} order
 * @throws {OrderBalanceError} An error thrown when the order has a pending balance.
 */
export default function checkOrderBalance(order) {
  const amount = getBalanceAmount(order);
  const orderNumber = get(order, 'number') || get(order, 'order.number');

  if (amount > 0) {
    getRollbar((rollbar, extra) => {
      rollbar.warning(`Order ${orderNumber} submitted with pending balance of ${amount}.`, extra);
    });
  }

  return order;
}
