import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import {
  fetchCheckout,
  redirectSubmittedOrder,
} from '../store/checkout/actions';
import { getCountries } from '../store/reference/selectors';
import { getCheckoutContactInfoUrl } from '../store/navigation/selectors';
import {
  getIsDeliveredDutyCustomerChoice,
  getIsSingleDeliveredDutyDisplayType,
  getOrderDestination,
  getVrnFromRegistration,
  getInvoiceAddress,
} from '../store/checkout/selectors';
import ShippingMethodStep from '../components/shipping-method-step';
import getFeatureKeys from '../utilities/get-feature-keys';
import withExternalEvents from '../components/with-external-events';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import checkShippingMethodPromptBehavior from '../utilities/check-shipping-method-prompt-behavior';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params, location } = props;
  const { orderNumber, checkoutId } = params;

  return Promise.all([
    dispatch(fetchCheckout(checkoutId, {
      feature_key: getFeatureKeys(),
    })).then(() => {
      checkShippingMethodPromptBehavior(location, getState());
    }),
  ])
    .then(() => dispatch(redirectSubmittedOrder()))
    .catch((error) => {
      logInternalServerError(error, orderNumber);
      throw error;
    });
};

const mapStateToProps = createStructuredSelector({
  countries: getCountries,
  invoiceAddress: getInvoiceAddress,
  vrn: getVrnFromRegistration,
  destination: getOrderDestination,
  editShippingAddressUrl: getCheckoutContactInfoUrl,
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice,
  isSingleDeliveredDutyDisplayType: getIsSingleDeliveredDutyDisplayType,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(ShippingMethodStep);
