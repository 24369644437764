/* eslint-disable react/no-array-index-key */

import type { Dictionary } from 'lodash';
import get from 'lodash/get';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import OrderItem from '../../../containers/order-item';
import OrderShopifyItem from '../../../containers/order-shopify-item';
import getDeliveryItemPriceLabel from '../../../utilities/get-delivery-item-price-label';
import getItemPriceLabel from '../../../utilities/get-item-price-label';

interface Props {
  order: io.flow.v0.models.Order;
  isShopifyIntegration?: boolean;
  itemContentsMap: Dictionary<io.flow.v0.models.CheckoutItemContent>
}

const MobileOrderDeliveryItems: React.FunctionComponent<Props> = ({
  order,
  itemContentsMap,
  isShopifyIntegration,
}) => {
  const hasMultipleDeliveries = order.deliveries.length > 1;

  if (!hasMultipleDeliveries) {
    const delivery = order.deliveries[0];
    if (order.lines != null) {
      return (
        <React.Fragment key={`${delivery.id}-0`}>
          {order.lines.map((line, index) => (
            (isShopifyIntegration) ? (
              <OrderShopifyItem
                key={`${line.item_number}_${index}`}
                content={get(itemContentsMap, line.item_number)}
                quantity={line.quantity}
                priceLabel={line.price.label}
              />
            ) : (
              <OrderItem
                key={`${line.item_number}_${index}`}
                content={get(itemContentsMap, line.item_number)}
                quantity={line.quantity}
                priceLabel={line.price.label}
              />
            )
          ))}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={`${delivery.id}-0`}>
        {order.items.map((item, index) => (
          (isShopifyIntegration) ? (
            <OrderShopifyItem
              key={`${item.number}_${index}`}
              content={get(itemContentsMap, item.number)}
              quantity={item.quantity}
              priceLabel={getItemPriceLabel(item)}
            />
          ) : (
            <OrderItem
              key={`${item.number}_${index}`}
              content={get(itemContentsMap, item.number)}
              quantity={item.quantity}
              priceLabel={getItemPriceLabel(item)}
            />
          )
        ))}
      </React.Fragment>
    );
  }

  return (
    <>
      {order.deliveries.map((delivery, deliveryIndex) => (
        <React.Fragment key={`${delivery.id}-${deliveryIndex}`}>
          <h1>
            <FormattedMessage
              id="checkout_order_summary_shipment_heading"
              description="A line showing the delivery number in a list of deliveries"
              defaultMessage="Shipment {deliveryIndex} of {deliveryCount}"
              values={{
                deliveryIndex: deliveryIndex + 1,
                deliveryCount: order.deliveries.length,
              }}
            />
          </h1>
          {delivery.items.map((deliveryItem, index) => (
            isShopifyIntegration ? (
              <OrderShopifyItem
                key={index}
                content={get(itemContentsMap, deliveryItem.number)}
                quantity={deliveryItem.quantity}
                priceLabel={getDeliveryItemPriceLabel(deliveryItem, order)}
              />
            ) : (
              <OrderItem
                key={index}
                content={get(itemContentsMap, deliveryItem.number)}
                quantity={deliveryItem.quantity}
                priceLabel={getDeliveryItemPriceLabel(deliveryItem, order)}
              />
            )))}
        </React.Fragment>
      ))}
    </>
  );
};

export default MobileOrderDeliveryItems;
