import { getMarketingOptIns } from '../../content/selectors';
import { getOrganization } from '../../flow/selectors';
import setOrderAttributes from './set-order-attributes';
import { validateRequiredOptIns, getOptInsWithValues } from '../../../utilities/opt-in-utilities';

export default function syncOptInOrderAttributes(values = {}) {
  return function syncOptInOrderAttributesSideEffects(dispatch, getState) {
    const state = getState();
    const marketingOptIns = getMarketingOptIns(state);
    const organization = getOrganization(state);

    if (!marketingOptIns.length) {
      return Promise.resolve();
    }

    return validateRequiredOptIns(values, marketingOptIns)
      .then(() => dispatch(
        setOrderAttributes(organization, getOptInsWithValues(values, marketingOptIns)),
      ));
  };
}
