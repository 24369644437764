import { SubmissionError } from 'redux-form';
import setOrderAttributes from './set-order-attributes';
import createTransactionEvents from './create-transaction-events';
import checkHttpStatus from '../../../utilities/check-http-status';
import { getCheckoutId, isCheckoutResourceFeatureEnabled } from '../selectors';
import submitOrderCheckouts from './submit-order-checkouts';
import submitOrderBundle from './submit-order-bundle';
import checkOrderBundleStatus from '../../../utilities/check-order-bundle-status';
import checkCheckoutSubmissionStatus from '../../../utilities/check-checkout-submission-status';
import updateOrder from './update-order';

export default function submitFreeOrder(values) {
  return function submitFreeOrderEffects(dispatch, getState) {
    const { organizationId, optinPrompts, orderNumber } = values;
    const state = getState();
    const isCheckoutsEnabled = isCheckoutResourceFeatureEnabled(state);
    return Promise.resolve()
      .then(() => dispatch(setOrderAttributes(organizationId, optinPrompts)))
      .then(checkHttpStatus)
      .then(() => (isCheckoutsEnabled ? dispatch(submitOrderCheckouts(getCheckoutId(state)))
        : dispatch(submitOrderBundle(organizationId, orderNumber))))
      .then(checkHttpStatus)
      .then((res) => (isCheckoutsEnabled
        ? checkCheckoutSubmissionStatus(res) : checkOrderBundleStatus(res)))
      .then(() => dispatch(createTransactionEvents(organizationId)))
      .catch((error) => {
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          case 'OrderBundleError':
            if (error.response.result.order != null) {
              dispatch(updateOrder(error.response.result.order));
            }

            throw new SubmissionError({
              _error: error.response.result.errors[0],
            });
          case 'CheckoutSubmissionError':
            throw new SubmissionError({
              _error: error.response.result.builder.errors[0],
            });
          default:
            throw error;
        }
      });
  };
}
