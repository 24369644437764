import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./text-field.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('text-field');

const defaultProps = {
  as: 'div' as React.ElementType,
  disabled: false,
  size: 'large' as Size,
  type: 'text',
};

type Size = 'small' | 'medium' | 'large';

type OwnProps = {
  automationId?: string;
  className?: string;
  defaultValue?: string;
  errorText?: string;
  hintText?: string;
  icon?: React.ReactNode;
  id?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  labelRef?: React.Ref<HTMLLabelElement>;
  labelText?: React.ReactNode;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  required?: boolean;
  value?: string;
} & typeof defaultProps;

type UnhandledProps = Omit<
React.InputHTMLAttributes<HTMLInputElement>,
keyof OwnProps
>;

export type Props = OwnProps & UnhandledProps;

export type State = {
  empty: boolean;
  controlled: boolean;
};

export default class TextField extends React.Component<Props, State> {
  cid: string;

  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
    this.cid = `input.${uniqueId()}`;
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  getInitialState(): State {
    const { defaultValue, value } = this.props;
    return {
      empty: !value && !defaultValue,
      controlled: value !== undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const {
      errorText,
      name,
      value,
    } = nextProps;

    const {
      errorText: previousErrorText,
    } = this.props;

    const {
      controlled,
      empty,
    } = this.state;

    if (value !== undefined) {
      if (!controlled) {
        this.setState({ controlled: true });
      }

      if (empty !== !value) {
        this.setState({ empty: !value });
      }
    }

    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: name,
      });
    }
  }

  handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const { controlled, empty } = this.state;
    const { onChange } = this.props;
    const { value } = event.currentTarget;

    if (!controlled && empty !== !value) {
      this.setState({ empty: !value });
    }

    if (typeof onChange === 'function') {
      onChange(event, value);
    }
  }

  handleBlur(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { name, onBlur } = this.props;

    trackHeapEvent('field_blur', {
      field: name,
    });

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  }

  handleFocus(
    event: React.FocusEvent<HTMLInputElement>,
  ): void {
    const { name, onFocus } = this.props;

    trackHeapEvent('field_focus', {
      field: name,
    });

    if (typeof onFocus === 'function') {
      onFocus(event);
    }
  }

  renderIconIfNeeded(): React.ReactNode {
    const { icon } = this.props;

    if (!React.isValidElement(icon)) {
      return icon;
    }

    return React.cloneElement(icon, {
      className: bem.element('icon', icon.props.className),
    });
  }

  render(): React.ReactElement {
    const {
      as: ElementType,
      disabled,
      size,
      type,
      automationId,
      className,
      defaultValue,
      errorText,
      hintText,
      icon,
      id = this.cid,
      inputRef,
      labelRef,
      labelText,
      name,
      onChange,
      required,
      value,
      ...unhandledProps
    } = this.props;

    const { empty } = this.state;

    const modifiers = {
      input: {
        [size]: true,
        empty,
        invalid: errorText !== undefined,
        'with-floating-label': labelText !== undefined,
      },
    };

    return (
      <ElementType className={bem.block(className)} data-automation-id={automationId}>
        <div className={bem.element('input-container')}>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...unhandledProps}
            aria-required={required}
            className={bem.element('input', modifiers.input)}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            ref={inputRef}
            name={name}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            onBlur={this.handleBlur}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            onChange={this.handleChange}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            onFocus={this.handleFocus}
            placeholder={hintText}
            required={required}
            type={type}
            value={value}
          />
          {labelText && (
            <label
              ref={labelRef}
              htmlFor={id}
              className={bem.element('label')}
            >
              {labelText}
              {required ? null : (
                <span className={bem.element('optional')}>
                  &#40;
                  <FormattedMessage
                    id="checkout_field_optional"
                    description="A message added to form field labels to denote a form field is optional"
                    defaultMessage="Optional"
                  />
                  &#41;
                </span>
              )}
            </label>
          )}
          {this.renderIconIfNeeded()}
        </div>
        {errorText && (
          <div className={bem.element('error-container')}>
            <p className={bem.element('error-text')}>{errorText}</p>
          </div>
        )}
      </ElementType>
    );
  }
}
