// @ts-check

const ExternalEvents = {
  ADD_TO_CART: 'externalEventAddToCart',
  REMOVE_FROM_CART: 'externalEventRemoveFromCart',
  ORDER_UPSERTED: 'externalEventOrderUpserted',
  PAYMENT_INFO_SUBMITTED: 'externalEventPaymentInfoSubmitted',
  TRANSACTION: 'externalEventTransaction',
  INITIALIZED: 'externalEventInitialized',
  SHIPPING_METHOD_CHANGE: 'externalEventShippingMethodChange',
  PAYMENT_METHOD_CHANGE: 'externalEventPaymentMethodChange',
};

export default ExternalEvents;
