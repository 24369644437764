import React from 'react';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';

import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import PaypalButton from '../paypal-button';
import Section from '../section';
import { Grid, Row, Column } from '../grid';
import './express-checkout.css';
import { PayPalPayerInfo, PayPalActions } from '../../types';

interface Props {
  onPaypalSuccess: Function;
  onPaypalError: Function;
}

const bem = new BemHelper('flow-express-checkout');

const ExpressCheckout: React.FunctionComponent<Props> = (props) => {
  const {
    onPaypalSuccess,
    onPaypalError,
  } = props;

  return (
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled: isMobileUXSpacingEnabled }): React.ReactElement => (
        <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
          <Section.Header>
            <Section.Title>
              <FormattedMessage
                id="checkout_express_checkout_heading"
                description="A title denoting a section of the checkout experience for express checkout"
                defaultMessage="Express Checkout"
              />
            </Section.Title>
          </Section.Header>
          <Section.Content>
            <Grid>
              <Row>
                <Column xs={8} xsCenter>
                  <PaypalButton
                    commit={false}
                    onError={onPaypalError}
                    onSuccess={
                      (data: PayPalPayerInfo, actions: PayPalActions):
                      void => onPaypalSuccess(data, actions)
                    }
                  />
                </Column>
              </Row>
              <Row>
                <p className={bem.element('bottom-divider')}>
                  <FormattedMessage
                    id="checkout_express_checkout_divider"
                    description="A prompt for user to use standard checkout"
                    defaultMessage="Or use standard checkout below"
                  />
                </p>
              </Row>
            </Grid>
          </Section.Content>
        </Section>
      )}
    />
  );
};

export default ExpressCheckout;
