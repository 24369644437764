import { FormattedMessage } from 'react-intl';
import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import matchesProperty from 'lodash/matchesProperty';
import overSome from 'lodash/overSome';

import { autoCompleteToken, automationToken } from './utilities';
import { AdministrativeAreaType, getAdministrativeAreaType } from '../../utilities/address-utilities';
import { AddressType } from './types';
import ClearableField from '../redux-form/clearable-field';
import TextField from '../redux-form/text-field';
import SelectField from '../redux-form/select-field';

interface Props {
  addressType?: AddressType;
  children?: never;
  countryCode: string;
  locale: string;
  provinces: io.flow.v0.models.AddressConfigurationProvince[];
  required?: boolean;
}

interface FormattedMessageRenderer {
  (...messages: React.ReactNode[]): React.ReactNode;
}

const messageToOption: FormattedMessageRenderer = (message) => (
  <SelectField.Option disabled value="" text={message} />
);

function getLabelText(
  countryCode: string,
  toDOM?: FormattedMessageRenderer,
): React.ReactNode {
  switch (getAdministrativeAreaType(countryCode)) {
    case AdministrativeAreaType.DISTRICT:
      return (
        <FormattedMessage
          id="checkout_address_field_label_district"
          description="Text for administrative area field label of countries divided into districts"
          defaultMessage="District"
        >
          {toDOM}
        </FormattedMessage>
      );
    case AdministrativeAreaType.REGION:
      return (
        <FormattedMessage
          id="checkout_address_field_label_region"
          description="Text for administrative area field label of countries divided into regions"
          defaultMessage="Region"
        >
          {toDOM}
        </FormattedMessage>
      );
    case AdministrativeAreaType.STATE:
      return (
        <FormattedMessage
          id="checkout_address_field_label_states"
          description="Text for administrative area field label of countries divided into states"
          defaultMessage="State"
        >
          {toDOM}
        </FormattedMessage>
      );
    case AdministrativeAreaType.TERRITORY:
      return (
        <FormattedMessage
          id="checkout_address_field_label_territory"
          description="Text for administrative area field label of countries divided into territories"
          defaultMessage="State/Territory"
        >
          {toDOM}
        </FormattedMessage>
      );
    case AdministrativeAreaType.PREFECTURE:
      return (
        <FormattedMessage
          id="checkout_address_field_label_prefecture"
          description="Text for administrative area field label of countries divided into prefectures"
          defaultMessage="Prefecture"
        >
          {toDOM}
        </FormattedMessage>
      );
    default:
      return (
        <FormattedMessage
          id="checkout_address_field_label_province"
          description="Text for administrative area field label of countries divided into provinces"
          defaultMessage="Province"
        >
          {toDOM}
        </FormattedMessage>
      );
  }
}

/**
 * Returns the localized name for the specified address configuration province
 */
function getLocalizedName(
  addressConfigurationProvince: io.flow.v0.models.AddressConfigurationProvince,
  locale: string,
): string {
  const { name, translations } = addressConfigurationProvince;
  const translation = find(translations, overSome([
    matchesProperty('locale.id', locale),
    matchesProperty('locale.language', locale),
  ]));
  const localizedName = get(translation, 'name', name);
  return localizedName;
}

const AdministrativeArea: React.FunctionComponent<Props> = ({
  addressType,
  countryCode,
  locale,
  provinces,
  required = false,
}) => {
  const autoComplete = autoCompleteToken('address-level1', addressType);
  const automationId = automationToken('address-level1', addressType);
  const fieldName = 'administrativeArea';
  const labelText = getLabelText(countryCode);

  return provinces.length > 0 ? (
    <ClearableField
      autoComplete={autoComplete}
      automationId={automationId}
      component={SelectField}
      labelText={labelText}
      name={fieldName}
      required={required}
    >
      {getLabelText(countryCode, messageToOption)}
      {provinces
        .map((addressConfigurationProvince) => ({
          name: getLocalizedName(addressConfigurationProvince, locale),
          value: addressConfigurationProvince.value,
        }))
        .sort((a, b) => a.name.localeCompare(b.name, locale))
        .map(({ name, value }) => (
          <SelectField.Option key={value} text={name} value={value} />
        ))}
    </ClearableField>
  ) : (
    <ClearableField
      autoComplete={autoComplete}
      automationId={automationId}
      component={TextField}
      labelText={labelText}
      name={fieldName}
      required={required}
    />
  );
};

AdministrativeArea.displayName = 'AdministrativeArea';

export default AdministrativeArea;
