import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { withInlineActionConfiguration } from '@flowio/payment';

import {
  getOrderDestination,
  getDontSubmitAuthAmount,
  getOrderTotalAmount,
  getOrderTotalCurrency,
  getCustomerAddressBookContacts,
  getCustomerPaymentSources,
  isGiftCardsEnabled,
  getOrderBalanceAmount,
} from '../store/checkout/selectors';
import {
  isPayPal,
  isCard,
  isAch,
  getBillingAddress,
  isGooglePay,
  isKlarna,
  isCryptoPay,
} from '../containers/payment-form/utilities';
import { getPathPrefix } from '../store/shopify/selectors';
import { getOnlineAuthorizationCallbackUrl } from '../store/navigation/selectors';
import AuthorizationForm from '../constants/authorization-form';
import PaymentTypes from '../constants/payment-types';
import getLocationOrigin from './get-location-origin';
import getBrowserInfo from './get-browser-info';
import { isCardNumberConfirmationRequired, isCvvConfirmationRequired } from './payment-source-utilities';
import { getSessionId } from './session';

/**
 * @param {import('../store/types').RootState} state
 * @param {import('../store/checkout/types').PaymentFormValues} values
 * @param {io.flow.v0.models.Card} [card]
 * @param {boolean} [isKlarnaRedirectEnabled]
 * @returns {io.flow.v0.unions.AuthorizationForm}
 */
export default function generateAuthorizationForm(
  state,
  values,
  card,
  isKlarnaRedirectEnabled,
) {
  const {
    billingAddress,
    googlePaymentData,
    orderNumber,
    paymentMethodId,
    paymentMethodIssuerId,
    paypalPaymentId,
    paypalPayerId,
    abaRoutingNumber,
    accountHolderName,
    bankAccountNumber,
    cardSecurityCode,
    saveCard,
  } = values;
  const destination = getOrderDestination(state);
  const amount = isGiftCardsEnabled(state)
    ? getOrderBalanceAmount(state) : getOrderTotalAmount(state);
  const currency = getOrderTotalCurrency(state);
  const prefix = getPathPrefix(state);
  const origin = getLocationOrigin();
  const sessionId = getSessionId();
  const successUrl = `${origin}${prefix}${getOnlineAuthorizationCallbackUrl(sessionId)(state)}`;
  const failureUrl = `${origin}${prefix}${getOnlineAuthorizationCallbackUrl(sessionId)(state)}`;
  const issuer = paymentMethodIssuerId ? { id: paymentMethodIssuerId } : {};
  const dontSubmitAuthAmount = getDontSubmitAuthAmount(state);
  const paymentSources = getCustomerPaymentSources(state);

  /** @type {io.flow.v0.unions.AuthorizationForm).AuthorizationForm} */
  let authForm = {};

  if (isPayPal(values)) {
    authForm = {
      discriminator: AuthorizationForm.PAYPAL,
      paypal_payment_id: paypalPaymentId,
      paypal_payer_id: paypalPayerId,
    };
  } else if (isCryptoPay(values)) {
    authForm = {
      discriminator: 'online_payment_authorization_form',
      payment_id: values.cryptoPaymentId,
    };
  } else {
    authForm.order_number = orderNumber;
    authForm.amount = amount;
    authForm.currency = currency;

    const selectedPaymentSource = find(paymentSources, { id: paymentMethodId });

    if (selectedPaymentSource != null) {
      // Use MoR authorization form because it's the only way to currently
      // store a card permanently.
      if (isCardNumberConfirmationRequired(selectedPaymentSource)
        || isCvvConfirmationRequired(selectedPaymentSource)) {
        authForm.discriminator = AuthorizationForm.MERCHANT_OF_RECORD;
        authForm.token = card != null ? card.token : undefined;
        authForm.browser_info = getBrowserInfo();
        authForm.options = ['store_card'];
        authForm.redirect_urls = {
          success: successUrl,
          failure: failureUrl,
        };
      } else {
        authForm.discriminator = AuthorizationForm.CARD_PAYMENT_SOURCE;
        authForm.card_payment_source_id = selectedPaymentSource.id;
        authForm.browser_info = getBrowserInfo();
      }
    } else if (isCard(values)) {
      if (dontSubmitAuthAmount) {
        delete authForm.amount;
        delete authForm.currency;
      }

      authForm.discriminator = AuthorizationForm.MERCHANT_OF_RECORD;
      authForm.cvv = cardSecurityCode;
      authForm.token = card != null ? card.token : undefined;

      authForm.browser_info = getBrowserInfo();

      if (saveCard) {
        authForm.options = ['store_card'];
      }

      authForm.redirect_urls = {
        success: successUrl,
        failure: failureUrl,
      };
    } else if (isAch(values)) {
      authForm.discriminator = AuthorizationForm.ACH;
      authForm.account_owner_name = accountHolderName;
      authForm.account_number = bankAccountNumber;
      authForm.billing_address = getBillingAddress(
        billingAddress,
        destination,
        getCustomerAddressBookContacts(state),
      );
      authForm.routing_number = abaRoutingNumber;
    } else if (isGooglePay(values)) {
      authForm.discriminator = AuthorizationForm.INLINE;
      authForm.method = PaymentTypes.GooglePay;
      authForm.payload = {
        parameters: {
          discriminator: 'google_pay_authorization_payload',
          payload: googlePaymentData,
        },
      };
    } else if (isKlarna(values) && isKlarnaRedirectEnabled) {
      authForm.discriminator = AuthorizationForm.REDIRECT;
      authForm.method = paymentMethodId;
      authForm.redirect_urls = {
        success: successUrl,
        failure: failureUrl,
      };
      if (!isEmpty(billingAddress)) {
        authForm.billing_address = getBillingAddress(
          billingAddress,
          destination,
          getCustomerAddressBookContacts(state),
        );
      }
    } else if (isKlarna(values) && !isKlarnaRedirectEnabled) {
      authForm = {};
    } else {
      authForm.discriminator = AuthorizationForm.REDIRECT;
      authForm.method = paymentMethodId;
      authForm.redirect_urls = {
        success: successUrl,
        failure: failureUrl,
      };

      if (!isEmpty(issuer)) {
        authForm.issuer = issuer;
      }
    }
  }

  return withInlineActionConfiguration(authForm);
}
