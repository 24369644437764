import { getOrganization } from '../../flow/selectors';
import { getOrderNumber } from '../selectors';
import removeGiftCard from './remove-gift-card';

import fetchOrder from './fetch-order';
import checkHttpStatus from '../../../utilities/check-http-status';
import updatePaymentMethods from './update-payment-methods';

export default function handleRemoveGiftCard(giftCardId) {
  return function handleRemoveGiftCardEffects(dispatch, getState) {
    const state = getState();
    const organization = getOrganization(state);
    const orderNumber = getOrderNumber(state);

    dispatch(removeGiftCard(organization, orderNumber, giftCardId))
      .then(checkHttpStatus)
      .then(() => dispatch(fetchOrder(organization, orderNumber)))
      .then(checkHttpStatus)
      .then(() => dispatch(updatePaymentMethods(organization)))
      .then(checkHttpStatus)
      .catch((error) => {
        if (error.response) return error.response;
        throw error;
      });
  };
}
