/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';

import Window from '../svg-icons/window';

if (process.browser) {
  require('./payment-browser-icon.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-browser-icon');

/**
 * A PaymentBrowserIcon renders a composite icon that encloses an image within
 * an SVG browser window.
 */
const PaymentBrowserIcon = ({
  accessibilityLabel,
  className,
  source,
  ...unhandledProps
}) => (
  <div {...unhandledProps} className={bem.block(className)}>
    <Window className={bem.element('window')} />
    <img className={bem.element('image')} src={source} alt={accessibilityLabel} />
  </div>
);

PaymentBrowserIcon.displayName = 'PaymentBrowserIcon';

PaymentBrowserIcon.propTypes = {
  accessibilityLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  source: PropTypes.string.isRequired,
};

PaymentBrowserIcon.defaultProps = {
  className: '',
};

export default PaymentBrowserIcon;
