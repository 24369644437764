import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function changeCard(
  paymentMethod: string,
): RootAction {
  return {
    type: actionTypes.CHANGE_CARD,
    payload: paymentMethod,
  };
}
