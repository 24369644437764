import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.v0.models.PublicKey | null;

const initialState: State = null;

const publicKeyReducer: AppReducer<State> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_PUBLIC_KEY_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return action.payload.payment.public_key;
    default:
      return previousState;
  }
};

export default publicKeyReducer;
