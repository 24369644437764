import { combineReducers } from 'redux';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import { AuthorizationCallbackState, CallbacksState } from '../types';

const initialState: AuthorizationCallbackState = {};

const authorizationCallbackStateReducer: AppReducer<AuthorizationCallbackState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.PROCESS_ONLINE_AUTHORIZATION_FAILURE:
      return update(previousState, {
        status: { $set: 'failure' },
        error: { $set: action.payload },
      });
    case actionTypes.PROCESS_ONLINE_AUTHORIZATION_SUCCESS:
      return update(previousState, {
        status: { $set: 'success' },
      });
    default:
      return previousState;
  }
};

export default combineReducers<CallbacksState>({
  authorization: authorizationCallbackStateReducer,
});
