import { ShopifyCartsGetOrderByNumberResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import legacyErrorConverter from '../../../utilities/converters/legacy-error-converter';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { RootAction, ThunkResult } from '../../types';
import type { LegacyError, LegacyResponse } from '../../../types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';

type Result = Promise<LegacyResponse<ShopifyCartsGetOrderByNumberResponse>>;

export const fetchCartRequest = (
  orderNumber: string,
): RootAction => ({
  type: actionTypes.FETCH_CART_REQUEST,
  params: {
    orderNumber,
  },
});

export const fetchCartSuccess = (
  orderNumber: string,
  shopifyCart: io.flow.v0.models.ShopifyCart,
): RootAction => ({
  type: actionTypes.FETCH_CART_SUCCESS,
  payload: shopifyCart,
  params: {
    orderNumber,
  },
});

export const fetchCartFailure = (
  orderNumber: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_CART_FAILURE,
  payload: error,
  params: {
    orderNumber,
  },
});

export const fetchCart = (
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(fetchCartRequest(orderNumber));
  return extra.api.shopifyCarts.getOrderByNumber({
    number: orderNumber,
  }).then((response) => {
    switch (response.status) {
      case 200:
        dispatch(fetchCartSuccess(orderNumber, response.body));
        break;
      case 401:
        dispatch(fetchCartFailure(
          orderNumber,
          legacyErrorConverter.fromStatusCode(response.status),
        ));
        break;
      default:
        exhaustiveCheck(response);
        break;
    }
    return legacyResponseConverter.fromHttpResponse(response);
  });
};
