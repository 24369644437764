import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import matchesProperty from 'lodash/matchesProperty';

import Button from '../button';
import { Grid, Row, Column } from '../grid';
import { Padlock } from '../svg-icons';
import TextField from '../redux-form/text-field';
import { injectScript } from '../../utilities/script-cache';

import {
  getCardPaymentMethodRules,
  getOnlinePaymentMethodRules,
  getPaymentMethod,
  getPaymentMethodId,
  getDefaultPaymentMethodDescription,
  getPaymentMethodIssuers,
  getPaymentMethodLogo,
  getPaymentMethodName,
  isPayPal,
  messages,
} from '../../utilities/payment-method-rule-utilities';

import {
  MOBILE_UX_SPACING,
  PAYMENT_METHOD_POSITIONING,
  RETURNING_CUSTOMERS,
} from '../../../common/constants/feature-keys';
import { Dialog } from '../dialog';
import ContentItem from '../content-item';
import Card from '../card';
import Image from '../image';
import MediaQuery from '../media-query';
import NonIdealState from '../non-ideal-state';
import PaymentBrowserIcon from '../payment-browser-icon';
import PaymentIconList from '../payment-icon-list';
import PaymentMethodIssuer from '../payment-method-issuer';
import PaymentMethodTypes from '../../constants/payment-method-types';
import PaymentTypes from '../../constants/payment-types';
import RadioPanelGroup from '../redux-form/radio-panel-group';
import RadioPanel from '../radio-panel';
import Section from '../section';
import PaymentReadableName from '../../constants/payment-readable-name';
import FeatureToggle from '../feature-toggle';
import { isCardNumberConfirmationRequired, isCvvConfirmationRequired, formatSingleLinePaymentSourceLabel } from '../../utilities/payment-source-utilities';

if (process.browser) {
  require('./payment-method.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-method');

/**
 * @typedef {object} Props
 * @property {io.flow.v0.enums.PaymentMethodType[]} paymentMethodTypes
 * @property {io.flow.v0.models.PaymentMethodRule[]} paymentMethodRules
 */

/**
 * @augments React.Component<Props>
 */
class PaymentMethod extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      presentAfterpay: false,
    };
  }

  componentDidMount() {
    this.stopObservingStripeScript = injectScript('https://js.stripe.com/v3/');
  }

  componentWillUnmount() {
    this.stopObservingStripeScript();
  }

  getPaymentMethodRulesByCapability(capability) {
    const {
      paymentMethodRules,
    } = this.props;

    return paymentMethodRules.filter((paymentMethodRule) => {
      const { payment_method: paymentMethod } = paymentMethodRule;
      return paymentMethod.capabilities != null
        && paymentMethod.capabilities.indexOf(capability) >= 0;
    });
  }

  /**
   * @param {io.flow.v0.models.CardPaymentSource} paymentSource
   */
  renderSavedCardPanel(paymentSource) {
    const {
      onDeleteCardPaymentSource,
      organizationId,
      paymentMethodRules,
      paymentMethodId,
      storedReviewCard,
    } = this.props;

    const {
      card,
    } = paymentSource.summary;

    const paymentMethodRule = find(paymentMethodRules, matchesProperty('payment_method.id', card.type));

    let content;

    if (isCardNumberConfirmationRequired(paymentSource)
      || isCvvConfirmationRequired(paymentSource)) {
      content = (
        <Card saveCard={false} />
      );
    }

    // Setting it to null when not selected because the flow sandbox card will
    // technically have 2 different flow elements and it will not know how
    // to tokenize
    if (paymentMethodId !== paymentSource.id || !isEmpty(storedReviewCard)) {
      content = null;
    }

    const label = (
      <div className={bem.element('payment-source-label')}>
        <PaymentIconList paymentMethodRules={[paymentMethodRule]} />
        <span className={bem.element('payment-source-label-text')}>
          {formatSingleLinePaymentSourceLabel(paymentSource)}
        </span>
        <Button
          className={bem.element('payment-source-remove-button')}
          color="negative"
          disabled={paymentMethodId === `savedCreditCard_${paymentSource.id}`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDeleteCardPaymentSource(organizationId, paymentSource.id);
          }}
          outline
          size="small"
          type="button"
        >
          <FormattedMessage
            id="checkout_remove_card_payment_source"
            description="A message used on action to remove a card payment source"
            defaultMessage="Remove"
          />
        </Button>
      </div>
    );

    return (
      <RadioPanel
        key={paymentSource.id}
        label={label}
        value={paymentSource.id}
      >
        {content}
      </RadioPanel>
    );
  }

  /**
   * Renders a radio button for debit card payments. Note that from Flow API
   * perspective both debit and credit cards function the same way and this
   * separation is only for presentation preference.
   */
  renderDebitCardPanel() {
    const {
      customer,
      customerPaymentSources,
    } = this.props;

    const paymentMethodRules = this.getPaymentMethodRulesByCapability('debit');

    return (
      <RadioPanel
        accessory={(
          <PaymentIconList paymentMethodRules={paymentMethodRules} />
        )}
        key={PaymentTypes.DebitCard}
        label={customerPaymentSources && customerPaymentSources.length > 0
          ? (
            <FormattedMessage
              id="checkout_payment_method_field_label_new_debit_card"
              description="A message used as the label for the selected a new debit card"
              defaultMessage="New Debit Card"
            />
          ) : (
            <FormattedMessage
              id="checkout_payment_method_field_label_debit_card"
              description="A message used as the label for the debit card payment method option"
              defaultMessage="Debit Card"
            />
          )}
        unmountOnExit
        value={PaymentTypes.DebitCard}
      >
        <Card saveCard={customer != null} />
      </RadioPanel>
    );
  }

  /**
   * Renders a radio button for credit card payments. Note that from Flow API
   * perspective both debit and credit cards function the same way and this
   * separation is only for presentation preference.
   */
  renderCreditCardPanel() {
    const {
      customer,
      customerPaymentSources,
    } = this.props;

    const paymentMethodRules = this.getPaymentMethodRulesByCapability('credit');

    return (
      <RadioPanel
        accessory={(
          <PaymentIconList paymentMethodRules={paymentMethodRules} />
        )}
        key={PaymentTypes.CreditCard}
        label={customerPaymentSources && customerPaymentSources.length > 0
          ? (
            <FormattedMessage
              id="checkout_payment_method_field_label_new_credit_card"
              description="A message used as the label for the selected a new credit card"
              defaultMessage="New Credit Card"
            />
          ) : (
            <FormattedMessage
              id="checkout_payment_method_field_label_credit_card"
              description="A message used as the label for the credit card payment method option"
              defaultMessage="Credit Card"
            />
          )}
        unmountOnExit
        value={PaymentTypes.CreditCard}
      >
        <Card saveCard={customer != null} />
      </RadioPanel>
    );
  }

  /**
   * Renders a radio button for debit/credit card payments.
   */
  renderCardPanel() {
    const {
      customer,
      paymentMethodRules,
      storedReviewCard,
      onCardChange,
      paymentMethodId,
      customerPaymentSources,
    } = this.props;

    let content;

    if (!isEmpty(storedReviewCard)) {
      const storedCardType = get(storedReviewCard, 'type');
      const readableCardName = get(PaymentReadableName, storedCardType, storedCardType);
      content = (
        <div className={bem.element('review-card')} data-automation-id="stored-card">
          {`${readableCardName} ${get(storedReviewCard, 'last4')}`}
          <Button
            flat
            automationId="stored-card-change"
            className={bem.element('change-review-card')}
            onClick={onCardChange}
          >
            <FormattedMessage
              id="checkout_change_card"
              description="A message used as the label for changing the card used"
              defaultMessage="Change"
            />
          </Button>
        </div>
      );
    } else {
      content = (
        <Card saveCard={customer != null} />
      );
    }

    // Setting it to null when not selected because the flow sandbox card will
    // technically have 2 different flow elements and it will not know how to tokenize
    if (paymentMethodId !== PaymentTypes.Card) {
      content = null;
    }

    return (
      <RadioPanel
        accessory={(
          <PaymentIconList
            paymentMethodRules={getCardPaymentMethodRules(paymentMethodRules)}
          />
        )}
        key={PaymentTypes.Card}
        label={customerPaymentSources && customerPaymentSources.length > 0
          ? (
            <FormattedMessage
              id="checkout_payment_method_field_label_new_card"
              description="A message used as the label for the selected a new card"
              defaultMessage="New Credit Card"
            />
          ) : (
            <FormattedMessage
              id="checkout_payment_method_field_label_card"
              description="A message used as the label for the card payment method option"
              defaultMessage="Credit Card"
            />
          )}
        value={PaymentTypes.Card}
      >
        {content}
      </RadioPanel>
    );
  }

  /**
   * Renders one or more radio buttons for card payments depending on business
   * logic, such as capabilities by destination.
   */
  renderCardPanels() {
    const {
      cardPaymentDisplay,
    } = this.props;

    switch (cardPaymentDisplay) {
      case 'debit_card_first':
        return [
          this.renderDebitCardPanel(),
          this.renderCreditCardPanel(),
        ];
      case 'combined':
      default:
        return this.renderCardPanel();
    }
  }

  renderACHPanel = () => (
    <div key={PaymentTypes.ACH}>
      <Column xs={12} md={12}>
        <Field
          required
          className={bem.element('ach-field')}
          autoComplete="account-name"
          component={TextField}
          labelText={(
            <FormattedMessage
              id="checkout_ach_account_name"
              description="A message used as the label for the ach account name"
              defaultMessage="Bank Account Holder Name"
            />
          )}
          name="accountHolderName"
        />
      </Column>
      <Column xs={12} md={12}>
        <Field
          required
          className={bem.element('ach-field')}
          autoComplete="account-number"
          component={TextField}
          icon={(
            <Padlock className={bem.element('ach-secure-icon')} />
          )}
          labelText={(
            <FormattedMessage
              id="checkout_ach_account_number"
              description="A message used as the label for the ach account number"
              defaultMessage="Bank Account Number"
            />
          )}
          name="bankAccountNumber"
        />
      </Column>
      <Column xs={12} md={12}>
        <Field
          required
          className={bem.element('ach-field')}
          autoComplete="routing-number"
          component={TextField}
          icon={(
            <Padlock className={bem.element('ach-secure-icon')} />
          )}
          labelText={(
            <FormattedMessage
              id="checkout_ach_routing_number"
              description="A message used as the label for the ach routing number"
              defaultMessage="ABA Routing Number"
            />
          )}
          name="abaRoutingNumber"
        />
      </Column>
    </div>
  );

  renderAfterpayPanelContent(paymentMethodRule) {
    const { presentAfterpay } = this.state;
    const greyscaleLogoUrl = getPaymentMethodLogo(paymentMethodRule, 'greyscale');
    const paymentMethodName = getPaymentMethodName(paymentMethodRule);

    return (
      <>
        <NonIdealState
          heading="Pay over time in four equal fortnightly payments. Interest free, with no additional fees if you pay on time."
          description="You will be redirected to Afterpay when you confirm your order."
          visual={(
            <PaymentBrowserIcon
              accessibilityLabel={paymentMethodName}
              source={greyscaleLogoUrl}
            />
          )}
        />
        <Button
          type="button"
          flat
          onClick={(event) => {
            event.preventDefault();
            this.setState({ presentAfterpay: true });
          }}
          size="small"
        >
          Learn More
        </Button>
        <Dialog
          className={bem.element('afterpay-lightbox')}
          enableBackdropDismiss
          isOpen={presentAfterpay}
          onRequestClose={() => {
            this.setState({ presentAfterpay: false });
          }}
          showBackdrop
        >
          <MediaQuery query="(max-width: 479px)">
            {(matches) => (
              <a href="http://www.afterpay.com/terms/" target="_blank" rel="noopener noreferrer">
                {matches ? (
                  <Image source="https://static.afterpay.com/lightbox-mobile.png" />
                ) : (
                  <Image source="https://static.afterpay.com/lightbox-desktop.png" />
                )}
              </a>
            )}
          </MediaQuery>
        </Dialog>
      </>
    );
  }

  // TODO: Do not display Google Pay option when payment readiness cannot be determined.
  // Google Pay branding guidelines do not like the treatement of PaymentBrowserIcon or grayscale icons
  renderGooglePayPanelContent(paymentMethodRule) {
    const { intl, paypalExpressReady } = this.props;
    const { formatMessage } = intl;
    const description = getDefaultPaymentMethodDescription(
      paymentMethodRule, formatMessage, paypalExpressReady,
    );

    return (
      <>
        <NonIdealState
          heading={description}
        />
      </>
    );
  }

  renderApplePayPanelContent(paymentMethodRule) {
    const {
      applepayAvailability,
      intl,
      paypalExpressReady,
    } = this.props;
    const { formatMessage } = intl;
    const description = applepayAvailability.available
      ? getDefaultPaymentMethodDescription(
        paymentMethodRule, formatMessage, paypalExpressReady,
      ) : formatMessage(messages.applePayNotSupported);

    return (
      <>
        <NonIdealState
          heading={description}
        />
      </>
    );
  }

  renderDefaultOnlinePaymentMethodPanelContent(paymentMethodRule) {
    const { intl, paypalDisclaimer, paypalExpressReady } = this.props;
    const { formatMessage } = intl;
    const description = getDefaultPaymentMethodDescription(
      paymentMethodRule, formatMessage, paypalExpressReady,
    );
    const greyscaleLogoUrl = getPaymentMethodLogo(paymentMethodRule, 'greyscale');
    const paymentMethod = getPaymentMethod(paymentMethodRule);
    const paymentMethodName = getPaymentMethodName(paymentMethodRule);
    const paymentMethodIssuers = getPaymentMethodIssuers(paymentMethodRule);

    return (
      <div>
        {!isEmpty(paymentMethodIssuers) && (
          <Field
            component={PaymentMethodIssuer}
            name="paymentMethodIssuerId"
            paymentMethod={paymentMethod}
            paymentMethodIssuers={paymentMethodIssuers}
          />
        )}
        {isEmpty(paymentMethodIssuers) && (
          <NonIdealState
            description={isPayPal(paymentMethodRule) && paypalDisclaimer && (
              <ContentItem content={paypalDisclaimer} />
            )}
            heading={description}
            visual={(
              <PaymentBrowserIcon
                accessibilityLabel={paymentMethodName}
                source={greyscaleLogoUrl}
              />
            )}
          />
        )}
      </div>
    );
  }

  renderOnlinePaymentMethodPanel(paymentMethodRule) {
    const originalLogoUrl = getPaymentMethodLogo(paymentMethodRule, 'original');
    const paymentMethodId = getPaymentMethodId(paymentMethodRule);
    const paymentMethodName = getPaymentMethodName(paymentMethodRule);

    return (
      <RadioPanel
        label={(
          <img
            className={bem.element('online-payment-method-logo')}
            src={originalLogoUrl}
            alt={paymentMethodName}
          />
        )}
        key={paymentMethodId}
        value={paymentMethodId}
      >
        <div className={bem.element('online-payment-method-content')}>
          {(() => {
            switch (paymentMethodId) {
              case PaymentTypes.ACH:
                return this.renderACHPanel();
              case PaymentTypes.Afterpay:
                return this.renderAfterpayPanelContent(paymentMethodRule);
              case PaymentTypes.GooglePay:
                return this.renderGooglePayPanelContent(paymentMethodRule);
              case PaymentTypes.ApplePay:
                return this.renderApplePayPanelContent(paymentMethodRule);
              default:
                return this.renderDefaultOnlinePaymentMethodPanelContent(paymentMethodRule);
            }
          })()}
        </div>
      </RadioPanel>
    );
  }

  renderOnlinePaymentMethodPanels() {
    const { paymentMethodRules } = this.props;

    return map(getOnlinePaymentMethodRules(paymentMethodRules), (paymentMethodRule) => (
      this.renderOnlinePaymentMethodPanel(paymentMethodRule)
    ));
  }

  renderOptionsInTypeOrder() {
    const {
      paymentMethodTypes,
    } = this.props;

    return paymentMethodTypes.reduce((panels, paymentMethodType) => {
      switch (paymentMethodType) {
        case PaymentMethodTypes.Card:
          return panels.concat(this.renderCardPanels());
        case PaymentMethodTypes.Online:
          return panels.concat(this.renderOnlinePaymentMethodPanels());
        default:
          return panels;
      }
    }, []);
  }

  renderOptionsInRuleOrder() {
    const {
      paymentMethodRules,
    } = this.props;

    let isCardOptionRendered = false;

    return [...paymentMethodRules]
      .sort((a, b) => a.display_position - b.display_position)
      .reduce((panels, paymentMethodRule) => {
        const {
          payment_method: paymentMethod,
        } = paymentMethodRule;

        if (paymentMethod.type === 'card') {
          if (!isCardOptionRendered) {
            isCardOptionRendered = true;
            return panels.concat(this.renderCardPanels());
          }
        }

        if (paymentMethod.type === 'online') {
          return panels.concat(this.renderOnlinePaymentMethodPanel(paymentMethodRule));
        }

        return panels;
      }, [])
      .filter((element) => React.isValidElement(element));
  }

  render() {
    const {
      customerPaymentSources,
      onCardChange,
    } = this.props;

    return (
      <FeatureToggle
        featureKey={MOBILE_UX_SPACING}
        render={({ isFeatureEnabled: isMobileUXSpacingEnabled }) => (
          <Section className={bem.block()} fitted={isMobileUXSpacingEnabled}>
            <Section.Header>
              <Section.Title>
                <FormattedMessage
                  id="checkout_payment_method_heading"
                  description="A message used for the subheading of the payment method section"
                  defaultMessage="Payment Method"
                />
              </Section.Title>
            </Section.Header>
            <Section.Content>
              <Grid parent={isMobileUXSpacingEnabled}>
                <Row>
                  <Column xs={12}>
                    <FeatureToggle
                      featureKey={RETURNING_CUSTOMERS}
                      render={({ isFeatureEnabled: isReturningCustomersEnabled }) => (
                        <FeatureToggle
                          featureKey={PAYMENT_METHOD_POSITIONING}
                          render={({ isFeatureEnabled: isPaymentMethodPositioningEnabled }) => (
                            <Field
                              component={RadioPanelGroup}
                              name="paymentMethodId"
                              onChange={onCardChange}
                            >
                              {isReturningCustomersEnabled && map(customerPaymentSources, (
                                (paymentSource) => this.renderSavedCardPanel(paymentSource)
                              ))}
                              {isPaymentMethodPositioningEnabled
                                ? this.renderOptionsInRuleOrder()
                                : this.renderOptionsInTypeOrder()}
                            </Field>
                          )}
                        />
                      )}
                    />
                  </Column>
                </Row>
              </Grid>
            </Section.Content>
          </Section>
        )}
      />
    );
  }
}

PaymentMethod.displayName = 'PaymentMethod';

PaymentMethod.propTypes = {
  applepayAvailability: PropTypes.shape({
    available: PropTypes.bool.isRequired,
    reason: PropTypes.string,
  }).isRequired,
  customer: ApiPropTypes.customer,
  customerPaymentSources: PropTypes.arrayOf(ApiPropTypes.paymentSource),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  organizationId: PropTypes.string.isRequired,
  paymentMethodRules: PropTypes.arrayOf(ApiPropTypes.paymentMethodRule).isRequired,
  paymentMethodTypes: PropTypes.arrayOf(ApiPropTypes.paymentMethodType).isRequired,
  paypalDisclaimer: ApiInternalPropTypes.contentItem,
  paypalExpressReady: PropTypes.bool.isRequired,
  storedReviewCard: ApiPropTypes.card,
  onCardChange: PropTypes.func,
  onDeleteCardPaymentSource: PropTypes.func.isRequired,
  paymentMethodId: PropTypes.string,
  cardPaymentDisplay: PropTypes.oneOf(['debit_card_first', 'combined']).isRequired,
};

PaymentMethod.defaultProps = {
  customer: undefined,
  customerPaymentSources: undefined,
  paypalDisclaimer: undefined,
  storedReviewCard: undefined,
  onCardChange: undefined,
  paymentMethodId: undefined,
};

export default PaymentMethod;
