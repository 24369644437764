import type { ShopifyOrderDetailsDeletePromotionByNumberResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<ShopifyOrderDetailsDeletePromotionByNumberResponse>
>;

type Parameters = {
  organization: string;
  orderNumber: string;
};

const removePromotionCodeRequest = (
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.REMOVE_PROMOTION_CODE_REQUEST,
  params: parameters,
});

const removePromotionCodeSuccess = (
  order: io.flow.experience.v0.models.Order,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.REMOVE_PROMOTION_CODE_SUCCESS,
  params: parameters,
  payload: order,
});

const removePromotionCodeFailure = (
  error: io.flow.experience.v0.models.OrderError | LegacyError,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.REMOVE_PROMOTION_CODE_FAILURE,
  params: parameters,
  payload: error,
});

const removePromotionCode = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const parameters: Parameters = {
    orderNumber,
    organization: organizationId,
  };

  dispatch(removePromotionCodeRequest(parameters));

  return extra.apiInternal.shopifyOrderDetails.deletePromotionByNumber({
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(removePromotionCodeSuccess(response.result, parameters));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(removePromotionCodeFailure(response.result, parameters));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default removePromotionCode;
