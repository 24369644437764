/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getElementType from '@flowio/react-helpers/lib/getElementType';

const bem = new BemHelper('message');

class MessageHeader extends PureComponent {
  render() {
    const { children, className, text } = this.props;
    const ElementType = getElementType(MessageHeader, this.props);
    const unhandledProps = getUnhandledProps(MessageHeader, this.props);

    if (children != null) {
      return (
        <ElementType {...unhandledProps} className={bem.element('header', className)}>
          {children}
        </ElementType>
      );
    }

    return (
      <ElementType {...unhandledProps} className={bem.element('header', className)}>
        {text}
      </ElementType>
    );
  }
}

MessageHeader.displayName = 'MessageHeader';

MessageHeader.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

MessageHeader.defaultProps = {
  as: 'h3',
  children: undefined,
  className: '',
  text: undefined,
};

export default MessageHeader;
