import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'checkout_card_field_label_name',
    defaultMessage: 'Cardholder Name',
    description: 'Text indicating to insert the card holder name',
  },
  number: {
    id: 'checkout_card_field_label_number',
    defaultMessage: 'Card Number',
    description: 'Text indicating to insert the card number',
  },
  expirationMonth: {
    id: 'checkout_card_field_label_expiration_month',
    defaultMessage: 'Exp. Month (MM)',
    description: 'Text indicating to insert the card expiration month',
  },
  expirationYear: {
    id: 'checkout_card_field_label_expiration_year',
    defaultMessage: 'Exp. Year (YYYY)',
    description: 'Text indicating to insert the card expiration year',
  },
  cvv: {
    id: 'checkout_card_field_label_security_code',
    defaultMessage: 'CVV',
    description: 'A message used as the label for the saved card security code field',
  },
});

export default messages;
