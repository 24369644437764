import ExternalCheckoutClient from './checkout';

export { default as ExternalOrderClient } from './order';

function init(organization, store) {
  // Setup external client / interface to checkout
  if (typeof window.flow === 'undefined') {
    window.flow = {};
  }

  window.flow.checkout = new ExternalCheckoutClient(organization, store);
}

export default init;
