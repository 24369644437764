/* eslint-disable react/jsx-props-no-spreading */

import ApiPropTypes from '@flowio/api-prop-types';
import React from 'react';
import map from 'lodash/map';
import Message from '../message';

const GenericError = ({ error, ...unhandledProps }) => (
  <Message {...unhandledProps} color="negative">
    {map(error.messages, (message) => (
      <Message.Text key={message}>{message}</Message.Text>
    ))}
  </Message>
);

GenericError.displayName = 'GenericError';

GenericError.propTypes = {
  error: ApiPropTypes.genericError.isRequired,
};

export default GenericError;
