import { card, CallbackQuery } from '@flowio/payment';
import { AuthorizationStatus } from '@flowio/api-constants';
import { inlineAuthorizationDialogChallengeId, inlineAuthorizationDialogIdentifyId } from '../../components/inline-authorization-dialog/inline-authorization-dialog';
import { ThunkDispatcher } from '../../store/types';
import { closeInlineAuthorizationDialog, fetchAuthorization, openInlineAuthorizationDialog } from '../../store/checkout/actions';
import ThreedsError from '../errors/threeds-error';
import { hideLoadingIndicator, showLoadingIndicator } from '../../store/application';
import { trackHeapEvent } from '../heap';

export function isAuthorizationSuccessful(authorization: io.flow.v0.unions.Authorization): boolean {
  return authorization.result.status === AuthorizationStatus.AUTHORIZED
    || authorization.result.status === AuthorizationStatus.REVIEW;
}

export function refreshAuthorization(
  organization: string,
  authKey: string,
  dispatch: ThunkDispatcher,
): Promise<io.flow.v0.unions.Authorization> {
  return new Promise((resolve, reject) => {
    dispatch(fetchAuthorization(organization, authKey))
      .then((
        response,
      ) => {
        if (response.ok) {
          resolve(response.result);
        } else {
          reject();
        }
      })
      .catch(reject);
  });
}

export function handleFiserv3DS2Challenge(
  authorizationResultActionDetail: io.flow.v0.models.ThreedsChallengeActionDetails,
  organization: string,
  authKey: string,
  dispatch: ThunkDispatcher,
): Promise<io.flow.v0.models.CardAuthorization | unknown> {
  dispatch(openInlineAuthorizationDialog());
  dispatch(hideLoadingIndicator());
  trackHeapEvent('payment_authorization_progress', {
    organization_id: organization,
    authorization_key: authKey,
    authorization_summary: 'challenge_pending',
  });
  return card.handleAuthResultAction<CallbackQuery>(
    authorizationResultActionDetail,
    `#${inlineAuthorizationDialogChallengeId}`,
  )
    .then(() => refreshAuthorization(organization, authKey, dispatch))
    // Attempting refresh of Auth on rejection also, as user may have approved,
    // and it's possible the UI communication flow failed for some reason (PMNT-372)
    .catch(() => refreshAuthorization(organization, authKey, dispatch))
    .then((
      authorization,
    ) => {
      if (isAuthorizationSuccessful(authorization)) {
        trackHeapEvent('payment_authorization_progress', {
          organization_id: organization,
          authorization_key: authKey,
          authorization_result_status: authorization.result.status,
          authorization_result_decline_code: authorization.result.decline_code,
          authorization_result_action: authorization.result.action,
          authorization_summary: 'challenge_success',
        });
        return Promise.resolve();
      }
      trackHeapEvent('payment_authorization_progress', {
        organization_id: organization,
        authorization_key: authKey,
        authorization_result_status: authorization.result.status,
        authorization_result_decline_code: authorization.result.decline_code,
        authorization_result_action: authorization.result.action,
        authorization_summary: 'challenge_failure',
      });
      return Promise.reject();
    })
    .catch(() => {
      trackHeapEvent('payment_authorization_progress', {
        organization_id: organization,
        authorization_key: authKey,
        authorization_summary: 'not_found',
      });
      throw new ThreedsError('Fiserv 3DS challenge failed');
    })
    .finally(() => {
      dispatch(closeInlineAuthorizationDialog());
      dispatch(showLoadingIndicator());
    });
}

export function handleFiserv3DS2Identify(
  authorizationResultActionDetail: io.flow.v0.models.ThreedsIdentifyActionDetails,
  organization: string,
  authKey: string,
  dispatch: ThunkDispatcher,
): Promise<io.flow.v0.models.CardAuthorization | unknown> {
  trackHeapEvent('payment_authorization_progress', {
    organization_id: organization,
    authorization_key: authKey,
    authorization_summary: 'identify_pending',
  });
  return card.handleAuthResultAction<CallbackQuery>(
    authorizationResultActionDetail,
    `#${inlineAuthorizationDialogIdentifyId}`,
  )
    .then(() => refreshAuthorization(organization, authKey, dispatch))
    // PMNT-372: Attempting refresh of Auth on rejection also, as user may have approved,
    // and it's possible the UI communication flow failed for some reason (timeout?)
    .catch(() => refreshAuthorization(organization, authKey, dispatch))
    .then((
      authorization,
    ) => {
      const authResult = authorization.result;
      const challengeActionDetails = authResult.action && authResult.action.discriminator === 'authorization_result_action_native' && authResult.action.details;

      if (challengeActionDetails && challengeActionDetails.discriminator === 'threeds_challenge_action_details') {
        // pipe response back through handleAuthResultActionDetail so as to handle subsequent auth action.
        return handleFiserv3DS2Challenge(
          challengeActionDetails,
          organization,
          authKey,
          dispatch,
        );
      }

      if (isAuthorizationSuccessful(authorization)) {
        trackHeapEvent('payment_authorization_progress', {
          organization_id: organization,
          authorization_key: authKey,
          authorization_result_status: authorization.result.status,
          authorization_result_decline_code: authorization.result.decline_code,
          authorization_result_action_type: authorization.result.action?.discriminator,
          authorization_result_action_detail: authorization.result.action?.discriminator === 'authorization_result_action_native' && authorization.result.action?.details?.discriminator,
          authorization_summary: 'identify_success',
        });
        return Promise.resolve();
      }

      trackHeapEvent('payment_authorization_progress', {
        organization_id: organization,
        authorization_key: authKey,
        authorization_result_status: authorization.result.status,
        authorization_result_decline_code: authorization.result.decline_code,
        authorization_result_action_type: authorization.result.action?.discriminator,
        authorization_result_action_detail: authorization.result.action?.discriminator === 'authorization_result_action_native' && authorization.result.action?.details?.discriminator,
        authorization_summary: 'identify_failure',
      });
      return Promise.reject(new ThreedsError('Fiserv 3DS fingerprinting failed'));
    })
    .catch((e) => Promise.reject(e));
}
