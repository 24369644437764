const PageViews = {
  CART: 'cart',
  CONFIRMATION: 'confirmation',
  CONTACT_INFO: 'contactInfo',
  PAYMENT_INFO: 'paymentInfo',
  SHIPPING_METHOD: 'shippingMethod',
  ALL: 'all',
};

export default PageViews;
