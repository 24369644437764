import type { DiscountCodesDeleteByCodeResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<DiscountCodesDeleteByCodeResponse>>;

const removeGenericPromotionCodeRequest = (
  organizationId: string,
  orderNumber: string,
): RootAction => ({
  type: actionTypes.REMOVE_GENERIC_PROMOTION_CODE_REQUEST,
  params: {
    organization: organizationId,
    orderNumber,
  },
});

const removeGenericPromotionCodeSuccess = (
  organizationId: string,
  orderNumber: string,
  orderBuilder: io.flow.experience.v0.models.OrderBuilder,
): RootAction => ({
  type: actionTypes.REMOVE_GENERIC_PROMOTION_CODE_SUCCESS,
  payload: orderBuilder,
  params: {
    organization: organizationId,
    orderNumber,
  },
});

const removeGenericPromotionCodeFailure = (
  organizationId: string,
  orderNumber: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.REMOVE_GENERIC_PROMOTION_CODE_FAILURE,
  payload: error,
  params: {
    organization: organizationId,
    orderNumber,
  },
});

const removeGenericPromotionCode = (
  organizationId: string,
  orderNumber: string,
  promotionCode: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(removeGenericPromotionCodeRequest(organizationId, orderNumber));
  return extra.apiInternal.discountCodes.deleteByCode({
    code: promotionCode,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(removeGenericPromotionCodeSuccess(organizationId, orderNumber, response.result));
          break;
        case 401:
        case 404:
          dispatch(removeGenericPromotionCodeFailure(organizationId, orderNumber, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default removeGenericPromotionCode;
