import {
  AddressConfigurationsResource,
  AddressesResource,
  AuthorizationsResource,
  CardPaymentSourcesResource,
  CheckoutItemContentsResource,
  CountriesResource,
  CustomerAddressBookContactsResource,
  ExperiencePriceConversionsResource,
  ExperiencesResource,
  OrderBuildersResource,
  OrdersResource,
  OrganizationsResource,
  PaymentMethodRulesResource,
  ProvincesResource,
  PublicKeysResource,
  ShopifyCartsResource,
} from '@flowio/api-sdk';

import { V1CheckoutsResource } from '@flowio/api-internal-sdk';

import type {
  $HttpClientOptions,
} from '@flowio/api-sdk';

export interface ApiClientV2 {
  addressConfigurations: AddressConfigurationsResource;
  addresses: AddressesResource;
  authorizations: AuthorizationsResource;
  cardPaymentSources: CardPaymentSourcesResource;
  checkoutItemContents: CheckoutItemContentsResource;
  countries: CountriesResource;
  customerAddressBookContacts: CustomerAddressBookContactsResource;
  experiencePriceConversions: ExperiencePriceConversionsResource;
  experiences: ExperiencesResource;
  orderBuilders: OrderBuildersResource;
  orders: OrdersResource;
  organizations: OrganizationsResource;
  paymentMethodRules: PaymentMethodRulesResource;
  provinces: ProvincesResource;
  publicKeys: PublicKeysResource;
  shopifyCarts: ShopifyCartsResource;
  v1Checkouts: V1CheckoutsResource;
}

// eslint-disable-next-line import/prefer-default-export
export function createApiClient(
  options: $HttpClientOptions,
): ApiClientV2 {
  return {
    addressConfigurations: new AddressConfigurationsResource(options),
    addresses: new AddressesResource(options),
    authorizations: new AuthorizationsResource(options),
    cardPaymentSources: new CardPaymentSourcesResource(options),
    checkoutItemContents: new CheckoutItemContentsResource(options),
    countries: new CountriesResource(options),
    customerAddressBookContacts: new CustomerAddressBookContactsResource(options),
    experiencePriceConversions: new ExperiencePriceConversionsResource(options),
    experiences: new ExperiencesResource(options),
    orderBuilders: new OrderBuildersResource(options),
    orders: new OrdersResource(options),
    organizations: new OrganizationsResource(options),
    paymentMethodRules: new PaymentMethodRulesResource(options),
    provinces: new ProvincesResource(options),
    publicKeys: new PublicKeysResource(options),
    shopifyCarts: new ShopifyCartsResource(options),
    v1Checkouts: new V1CheckoutsResource(options),
  };
}
