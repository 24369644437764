import { getSession } from '@flowio/session';

const getDeviceDetails = (): io.flow.payment.v0.unions.DeviceDetails => {
  const session = getSession() as io.flow.session.v0.models.OrganizationSession | undefined;

  const { ip } = session || { };

  const dateNow = new Date();
  return {
    type: 'browser',
    origin: window.location.origin,
    user_agent: navigator.userAgent,
    accept_language: navigator.languages.join(','),
    ip,
    navigator_language: navigator.language,
    navigator_hardware_concurrency: navigator.hardwareConcurrency,
    date_string: dateNow.toISOString(),
    time_zone_offset: dateNow.getTimezoneOffset(),
  };
};

export default getDeviceDetails;
