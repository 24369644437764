import BemHelper from '@flowio/bem-helper';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CartWarning from '../svg-icons/cart-warning';
import Button from '../button/button';
import { getOrganizationUrl } from '../../store/flow/selectors';

const bem = new BemHelper('page-error');

if (process.browser) {
  require('./page-error.css'); // eslint-disable-line global-require
}

const PageError = ({ children, organizationUrl }) => (
  <div className={bem.block()}>
    <div className={bem.element('container')}>
      <div className={bem.element('icon-container')}>
        <CartWarning className={bem.element('icon')} />
      </div>
      <div className={bem.element('message')}>
        {children}
      </div>
      <Button
        className={bem.element('btn')}
        name="homepage"
        text="Go to Homepage"
        size="small"
        href={organizationUrl}
        outline
      />
    </div>
  </div>
);

PageError.displayName = 'Page Error';

PageError.propTypes = {
  children: PropTypes.node.isRequired,
  organizationUrl: PropTypes.string,
};

PageError.defaultProps = {
  organizationUrl: '/',
};

const mapStateToProps = createStructuredSelector({
  organizationUrl: getOrganizationUrl,
});

export default connect(mapStateToProps)(PageError);
