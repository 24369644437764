import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import noop from 'lodash/noop';

import { Grid, Row, Column } from '../grid';
import Button from '../button';
import ChevronLeft from '../svg-icons/chevron-left';
import CustomerInformation from '../customer-information';
import AddressBookContactDialog from '../address-book-contact-dialog';
import OrderError from '../../containers/order-error';
import ShippingAddress from '../shipping-address';
import ShopifyDomesticShippingUnavailableDialog from '../shopify-domestic-shipping-unavailable-dialog';
import getCorrectAdministrativeArea from '../../utilities/get-correct-administrative-area';
import { CustomerType as CustomerTypeEnum } from '../../constants/customer-type';
import optinPromptPropTypes from '../../utilities/prop-types/optin-prompt-prop-types';
import CustomerType from '../customer-type';
import FeatureToggle from '../feature-toggle';
import { OPTIMIZE_MOBILE_CTAS } from '../../../common/constants/feature-keys';

import ExpressCheckout from '../../containers/express-checkout';
import { isAdministrativeAreaVisible, matchProvince } from '../../utilities/address-utilities';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./customer-information-form.css'); // eslint-disable-line global-require
}

/**
 * @typedef {import('redux-form').InjectedFormProps<FormData, P, ErrorType>} InjectedFormProps
 * @template FormData, P, ErrorType;
 */

/**
 * @typedef {import('react-intl').WrappedComponentProps} WrappedComponentProps
 */

/**
 * @typedef {io.flow.v0.models.OrderError} OrderError
 */

/**
 * @typedef {import('../../store/checkout/types').ContactInfoFormValues} ContactInfoFormValues
 */

/**
 * @typedef {object} StateProps
 * @property {io.flow.v0.models.CustomerAddressBook} [addressBook]
 * @property {io.flow.v0.models.AddressConfiguration} [addressConfiguration]
 * @property {string} [addressToUse]
 * @property {boolean} allowInvoiceAddress
 * @property {boolean} allowVRN
 * @property {string} cartUrl
 * @property {string} [companyName]
 * @property {string} continueShoppingUrl
 * @property {io.flow.v0.models.Country[]} countries
 * @property {string} countryCode
 * @property {'individual' | 'business'} [customerType]
 * @property {boolean} [isCartStepAvailable]
 * @property {boolean} [isVrnValidating]
 * @property {io.flow.internal.v0.models.OptinPrompt[]} optinPrompts
 * @property {io.flow.v0.models.OrderError} [orderError]
 * @property {string} orderNumber
 * @property {string} organizationId
 * @property {string} locale
 * @property {io.flow.v0.models.Customer} [returningCustomer]
 * @property {string} [vrn]
 * @property {boolean} shouldCollectCustomerTaxId
 */

/**
 * @typedef {object} DispatchProps
 * @property {Function} onCustomerTypeChange
 */

/**
 * @typedef {StateProps & DispatchProps & WrappedComponentProps & InjectedFormProps<ContactInfoFormValues, StateProps & DispatchProps, OrderError> & FormInstance<ContactInfoFormValues, StateProps & DispatchProps} Props
 */

/**
 * @typedef {Props} CustomerInformationFormProps
 * @deprecated
 */

const bem = new BemHelper('customer-information-form');

const messages = defineMessages({
  formError: {
    id: 'checkout_customer_information_form_error',
    description: 'The error message for any form errors in customer info',
    defaultMessage: 'There was a problem proceeding. Please review any errors above and try again.',
  },
});

/**
 * @augments React.Component<Props>
 */
class CustomerInformationForm extends Component {
  constructor(props) {
    super(props);
    this.handleAutoCompleteAddress = this.handleAutoCompleteAddress.bind(this);
    this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
    this.handlePostalAutoComplete = this.handlePostalAutoComplete.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
  }

  handleAutoCompleteAddress(addressData) {
    const {
      addressConfiguration,
      autofill,
      locale,
    } = this.props;
    autofill('addressLine1', addressData.street);
    autofill('locality', addressData.city);
    autofill('administrativeArea', getCorrectAdministrativeArea(addressConfiguration, locale, addressData.administrativeAreas));
    autofill('postalCode', addressData.postalCode);
  }

  handlePostalAutoComplete(address) {
    const {
      addressConfiguration,
      autofill,
    } = this.props;

    if (address.city != null) {
      autofill('locality', address.city);
    }

    if (address.province != null && isAdministrativeAreaVisible(addressConfiguration)) {
      if (addressConfiguration.provinces.length) {
        const province = matchProvince(addressConfiguration, address.province);
        if (province != null) {
          autofill('administrativeArea', province.value);
        }
      } else {
        autofill('administrativeArea', address.province);
      }
    }

    if (address.postal != null) {
      autofill('postalCode', address.postal);
    }
  }

  handleCustomerTypeChange(event, value) {
    const {
      onCustomerTypeChange,
      organizationId,
      orderNumber,
      vrn,
      companyName,
      countryCode,
    } = this.props;
    onCustomerTypeChange(organizationId, orderNumber, value, vrn, companyName, countryCode);
  }

  // eslint-disable-next-line class-methods-use-this
  handleContinueClick() {
    trackHeapEvent('continue_click', {
      step: 'customer_info',
    });
  }

  render() {
    const {
      returningCustomer,
      addressBook,
      addressConfiguration,
      cartUrl,
      clearSubmitErrors,
      companyName,
      continueShoppingUrl,
      countries,
      countryCode,
      error,
      invalid,
      handleSubmit,
      isCartStepAvailable,
      isPayPalExpressCheckoutEnabled,
      customerType,
      optinPrompts,
      orderError,
      organizationId,
      pristine,
      locale,
      submitting,
      vrn,
      allowInvoiceAddress,
      isVrnValidating,
      addressToUse,
      submitFailed,
      intl,
      shouldCollectCustomerTaxId,
    } = this.props;

    return (
      <>
        {isPayPalExpressCheckoutEnabled && <ExpressCheckout /> }
        <form
          className={bem.block()}
          data-automation-id="contact-information-form"
          noValidate
          onSubmit={handleSubmit}
        >
          <CustomerInformation
            optinPrompts={optinPrompts}
            shouldCollectCustomerTaxId={shouldCollectCustomerTaxId}
            countryCode={countryCode}
          />
          {allowInvoiceAddress
            && <CustomerType onCustomerTypeChange={this.handleCustomerTypeChange} />}
          <ShippingAddress
            addressBook={addressBook}
            addressConfiguration={addressConfiguration}
            addressToUse={addressToUse}
            companyName={companyName}
            countries={countries}
            countryCode={countryCode}
            customerType={customerType}
            locale={locale}
            onAutoCompleteAddress={this.handleAutoCompleteAddress}
            onPostalAutoComplete={this.handlePostalAutoComplete}
            organizationId={organizationId}
            returningCustomer={returningCustomer}
            vrn={vrn}
          />
          <Grid parent>
            <Row>
              {(error != null) && (
                <Column xs={12}>
                  <OrderError
                    className={bem.element('error')}
                    error={error}
                    continueShoppingUrl={continueShoppingUrl}
                  />
                </Column>
              )}
              {(orderError != null && error == null && pristine) && (
                <Column xs={12}>
                  <OrderError
                    className={bem.element('error')}
                    clearSubmitErrors={clearSubmitErrors}
                    error={orderError}
                    continueShoppingUrl={continueShoppingUrl}
                  />
                </Column>
              )}
              {(invalid && orderError == null && error == null && submitFailed) && (
                <FeatureToggle
                  featureKey={OPTIMIZE_MOBILE_CTAS}
                  render={({ isFeatureEnabled: optimizeMobileCtas }) => {
                    if (optimizeMobileCtas) {
                      return (
                        <Column xs={12}>
                          <OrderError
                            className={bem.element('error')}
                            error={{
                              code: 'generic_error',
                              messages: [intl.formatMessage(messages.formError)],
                            }}
                            continueShoppingUrl={continueShoppingUrl}
                          />
                        </Column>
                      );
                    }
                    return (<span />);
                  }}
                />
              )}
              <Column xs={12} md={6} mdPush={6}>
                <Button
                  automationId="continue-button"
                  disabled={submitting || isVrnValidating}
                  className={bem.element('continue-button')}
                  color="secondary"
                  onClick={this.handleContinueClick}
                >
                  <FormattedMessage
                    id="checkout_shipping_method_action_continue"
                    description="Text describing an action to continue to the next step in the checkout process"
                    defaultMessage="Continue"
                  />
                </Button>
              </Column>
              {isCartStepAvailable && (
                <Column xs={12} md={6} mdPull={6}>
                  <Button
                    as={Link}
                    to={cartUrl}
                    disabled={submitting || isVrnValidating}
                    icon={<ChevronLeft />}
                    className={bem.element('desktop-cart-button')}
                    flat
                    text={(
                      <FormattedMessage
                        id="checkout_shipping_method_action_return_to_cart"
                        description="Text to label an action for the user to leave the checkout experience and return to the list of products in the cart"
                        defaultMessage="Return to Cart"
                      />
                    )}
                  />
                  <FeatureToggle
                    featureKey={OPTIMIZE_MOBILE_CTAS}
                    render={({ isFeatureEnabled: optimizeMobileCtas }) => {
                      if (optimizeMobileCtas) {
                        return (
                          <Button
                            as={Link}
                            to={cartUrl}
                            disabled={submitting || isVrnValidating}
                            className={bem.element('mobile-cart-button')}
                            flat
                            text={(
                              <FormattedMessage
                                id="checkout_shipping_method_action_return_to_cart"
                                description="Text to label an action for the user to leave the checkout experience and return to the list of products in the cart"
                                defaultMessage="Return to Cart"
                              />
                            )}
                          />
                        );
                      }
                      return (
                        <Button
                          as={Link}
                          to={cartUrl}
                          disabled={submitting || isVrnValidating}
                          className={bem.element('mobile-cart-button')}
                          color="tertiary"
                          text={(
                            <FormattedMessage
                              id="checkout_shipping_method_action_return_to_cart"
                              description="Text to label an action for the user to leave the checkout experience and return to the list of products in the cart"
                              defaultMessage="Return to Cart"
                            />
                          )}
                        />
                      );
                    }}
                  />
                </Column>
              )}
            </Row>
          </Grid>
        </form>
        <AddressBookContactDialog countries={countries} />
        <ShopifyDomesticShippingUnavailableDialog onCancel={clearSubmitErrors} />
      </>
    );
  }
}

CustomerInformationForm.displayName = 'CustomerInformationForm';

CustomerInformationForm.propTypes = {
  addressBook: ApiPropTypes.customerAddressBook,
  addressToUse: PropTypes.string,
  addressConfiguration: ApiPropTypes.addressConfiguration.isRequired,
  organizationId: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  allowInvoiceAddress: PropTypes.bool,
  autofill: PropTypes.func.isRequired,
  cartUrl: PropTypes.string.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  continueShoppingUrl: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  countryCode: PropTypes.string.isRequired,
  error: ApiPropTypes.orderError,
  handleSubmit: PropTypes.func.isRequired,
  isCartStepAvailable: PropTypes.bool,
  isPayPalExpressCheckoutEnabled: PropTypes.bool,
  customerType: PropTypes.oneOf([CustomerTypeEnum.INDIVIDUAL, CustomerTypeEnum.BUSINESS]),
  orderError: ApiPropTypes.orderError,
  locale: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  returningCustomer: ApiPropTypes.customer,
  onCustomerTypeChange: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  vrn: PropTypes.string,
  isVrnValidating: PropTypes.bool,
  invalid: PropTypes.bool,
  submitFailed: PropTypes.bool,
  optinPrompts: PropTypes.arrayOf(PropTypes.shape(optinPromptPropTypes)),
  shouldCollectCustomerTaxId: PropTypes.bool,
};

CustomerInformationForm.defaultProps = {
  returningCustomer: undefined,
  addressBook: undefined,
  addressToUse: undefined,
  onCustomerTypeChange: noop,
  companyName: '',
  allowInvoiceAddress: false,
  error: undefined,
  orderError: undefined,
  invalid: false,
  submitFailed: false,
  isCartStepAvailable: true,
  isPayPalExpressCheckoutEnabled: false,
  vrn: '',
  customerType: CustomerTypeEnum.INDIVIDUAL,
  isVrnValidating: false,
  optinPrompts: [],
  shouldCollectCustomerTaxId: false,
};

export default CustomerInformationForm;
