/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import noop from 'lodash/noop';

if (process.browser) {
  require('./image.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('image');

class Image extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      readyState: Image.readyState.LOADING,
    };
  }

  componentDidMount() {
    this.createImageIfNeeded();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source: prevSource } = this.props;
    const { source: nextSource } = nextProps;
    if (prevSource !== nextSource) {
      this.setState({
        readyState: Image.readyState.LOADING,
      });
    }
  }

  componentDidUpdate() {
    this.createImageIfNeeded();
  }

  componentWillUnmount() {
    this.destroyImage();
  }

  handleLoad = (event) => {
    const { onLoad, onLoadEnd } = this.props;
    this.setState({ readyState: Image.readyState.SUCCESS });
    onLoad(event);
    onLoadEnd(event);
  }

  handleError = (event) => {
    const { onError, onLoadEnd } = this.props;
    this.setState({ readyState: Image.readyState.FAILURE });
    onError(event);
    onLoadEnd(event);
  }

  createImageIfNeeded() {
    const { source } = this.props;
    const { readyState } = this.state;
    if (source && readyState === Image.readyState.LOADING) {
      this.createImage();
    }
  }

  createImage() {
    const { onLoadStart, source } = this.props;
    this.destroyImage();
    this.image = document.createElement('img');
    this.image.onload = this.handleLoad;
    this.image.onerror = this.handleError;
    this.image.src = source;
    onLoadStart();
  }

  destroyImage() {
    if (this.image) {
      this.image.onload = null;
      this.image.onerror = null;
      this.image = null;
    }
  }

  render() {
    const {
      accessibilityLabel,
      bordered,
      className,
      fit,
      rounded,
      source,
    } = this.props;
    const { readyState } = this.state;
    const ElementType = getElementType(Image, this.props);
    const unhandledProps = getUnhandledProps(Image, this.props);
    const modifiers = {
      bordered,
      rounded,
      [fit]: fit !== 'none',
    };

    return (readyState !== Image.readyState.SUCCESS) ? null : (
      <ElementType {...unhandledProps} className={bem.block(modifiers, className)}>
        {source && (
          <img className={bem.element('object')} src={source} alt={accessibilityLabel} />
        )}
      </ElementType>
    );
  }
}

Image.displayName = 'Image';

Image.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  accessibilityLabel: PropTypes.string.isRequired,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  fit: PropTypes.oneOf(['none', 'contain', 'cover', 'fill']),
  onError: PropTypes.func,
  onLoadStart: PropTypes.func,
  onLoad: PropTypes.func,
  onLoadEnd: PropTypes.func,
  rounded: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

Image.defaultProps = {
  as: 'div',
  bordered: false,
  className: '',
  fit: 'none',
  onError: noop,
  onLoadStart: noop,
  onLoad: noop,
  onLoadEnd: noop,
  rounded: false,
};

Image.readyState = {
  FAILURE: 'failure',
  LOADING: 'loading',
  SUCCESS: 'success',
};

export default Image;
