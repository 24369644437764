import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import noop from 'lodash/noop';

import { Grid, Row, Column } from '../grid';
import Button from '../button';
import CartItem from '../../containers/cart-item';
import ChevronLeft from '../svg-icons/chevron-left';
import CompleteOrder from '../complete-order';
import CustomerService from '../customer-service';
import EmptyCart from '../empty-cart';
import GenericError from '../generic-error';
import Section from '../section';

if (process.browser) {
  require('./cart.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('cart');

class Cart extends Component {
  handleRequestCheckout = () => {
    const { cart, organization, onRequestCheckout } = this.props;
    onRequestCheckout(organization, cart.id);
  }

  handleRequestQuantityChange = (cartItemVariantId, quantity) => {
    const { cart, onRequestQuantityChange } = this.props;
    onRequestQuantityChange(cart.id, cartItemVariantId, quantity);
  }

  handleRequestRemove = (cartItemVariantId) => {
    const { cart, onRequestRemove } = this.props;
    onRequestRemove(cart.id, cartItemVariantId);
  }

  render() {
    const {
      cart,
      checkoutError,
      continueShoppingUrl,
      error,
      items,
      loading,
      orderError,
    } = this.props;

    const isCartEmpty = isEmpty(items);

    return (
      <Grid container className={bem.block()}>
        <Row>
          <Column xs={12} lg={8}>
            <Section className={bem.element('items')}>
              <Section.Header>
                <Section.Title>
                  <FormattedMessage
                    id="checkout_cart_subheader_your_cart"
                    description="A subheader describing the selection of items the user has chosen"
                    defaultMessage="Your Cart"
                  />
                </Section.Title>
              </Section.Header>
              <Section.Content fitted>
                {error && (
                  <GenericError error={error} />
                )}
                {orderError && (
                  <GenericError error={orderError} />
                )}
                {checkoutError && (
                  <GenericError error={checkoutError} />
                )}
                {map(items, (item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    onRequestQuantityChange={this.handleRequestQuantityChange}
                    onRequestRemove={this.handleRequestRemove}
                  />
                ))}
                {isCartEmpty && !loading && (
                  <EmptyCart
                    continueShoppingUrl={continueShoppingUrl}
                  />
                )}
                {!isCartEmpty && (
                  <div className="hidden-md-down">
                    <Button
                      href={continueShoppingUrl}
                      className={bem.element('continue-shopping')}
                      flat
                      icon={<ChevronLeft />}
                      text={(
                        <FormattedMessage
                          id="checkout_cart_action_continue_shopping"
                          description="Text on the action to navigate back to the store experience"
                          defaultMessage="Continue Shopping"
                        />
                      )}
                    />
                  </div>
                )}
              </Section.Content>
            </Section>
          </Column>
          <Column xs={12} lg={4}>
            {!isCartEmpty && (
              <CompleteOrder
                continueShoppingUrl={continueShoppingUrl}
                onRequestCheckout={this.handleRequestCheckout}
                totalPrice={cart.local.total_price.label}
              />
            )}
            <CustomerService />
          </Column>
        </Row>
      </Grid>
    );
  }
}

Cart.displayName = 'Cart';

Cart.propTypes = {
  cart: ApiPropTypes.shopifyCart.isRequired,
  continueShoppingUrl: PropTypes.string,
  error: ApiPropTypes.genericError,
  orderError: ApiPropTypes.genericError,
  checkoutError: ApiPropTypes.genericError,
  items: PropTypes.arrayOf(ApiPropTypes.shopifyCartItem).isRequired,
  loading: PropTypes.bool.isRequired,
  onRequestCheckout: PropTypes.func,
  onRequestQuantityChange: PropTypes.func,
  onRequestRemove: PropTypes.func,
  organization: PropTypes.string.isRequired,
};

Cart.defaultProps = {
  continueShoppingUrl: '/',
  error: undefined,
  orderError: undefined,
  checkoutError: undefined,
  onRequestCheckout: noop,
  onRequestQuantityChange: noop,
  onRequestRemove: noop,
};

export default Cart;
