import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { getOrderNumber, getGiftCardRemoveError } from '../store/checkout/selectors';
import { getOrganization } from '../store/flow/selectors';
import FormName from '../constants/form-name';
import {
  fetchOrder,
  submitGiftCard,
  removeGiftCard,
} from '../store/checkout/actions';
import checkHttpStatus from '../utilities/check-http-status';
import GiftCardForm from '../components/rewards/forms/gift-card-form';

function handleSubmit(values, dispatch) {
  return dispatch(submitGiftCard(values));
}

function handleRemoveGiftCard(rewardsId) {
  return function handleRemoveGiftCardSideEffects(dispatch, getState) {
    const state = getState();
    const organization = getOrganization(state);
    const orderNumber = getOrderNumber(state);

    dispatch(removeGiftCard(organization, orderNumber, rewardsId))
      .then(checkHttpStatus)
      .then(() => dispatch(fetchOrder(organization, orderNumber)))
      .catch((error) => {
        if (error.response) return error.response;
        throw error;
      });
  };
}

const mapStateToProps = createStructuredSelector({
  removeError: getGiftCardRemoveError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRemoveGiftCard: handleRemoveGiftCard,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FormName.GIFT_CARD_FORM,
    onSubmit: handleSubmit,
  }),
)(GiftCardForm);
