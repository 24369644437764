import { KeyCodes } from '@flowio/browser-helpers';
import { isElementOfType } from '@flowio/react-helpers';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import last from 'lodash/last';
import head from 'lodash/head';
import includes from 'lodash/includes';
import union from 'lodash/union';
import without from 'lodash/without';

import EventListener from '../event-listener';
import MenuItem from '../menu-item';

import './menu.css';

type MenuItemProps = PropsOf<typeof MenuItem>;

const defaultProps = {
  autoHighlight: true,
  defaultHighlightIndex: -1,
  items: [] as MenuItemProps[],
  multiple: false,
  selectItemOnEnter: true,
  selectItemOnSpace: true,
};

type MenuValue = string | string[] | undefined;

type OwnProps = {
  children?: React.ReactNode;
  defaultValue?: MenuValue;
  highlightIndex?: number;
  onChange?: (index: number, value: MenuValue) => void;
  onItemSelection?: (index: number, value: string | undefined) => void;
  onHighlightIndexChange?: (highlightIndex: number) => void;
  value?: MenuValue;
} & typeof defaultProps;

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLUListElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

type State = {
  highlightIndex: number;
  value: MenuValue;
};

type MassagedMenuItemProps = MenuItemProps & {
  highlighted: boolean;
  index: number;
  selected: boolean;
};

const bem = new BemHelper('flow-menu');

class Menu extends React.Component<Props, State> {
  static displayName = 'Menu';

  static defaultProps = defaultProps;

  static mapChildrenToMenuItemProps(
    children: React.ReactNode,
  ): MenuItemProps[] {
    return React.Children.toArray(children)
      .filter((node): node is React.ReactElement => React.isValidElement(node))
      .filter((node): node is React.ReactElement<MenuItemProps> => isElementOfType(node, MenuItem))
      .map((node) => node.props);
  }

  static mapChildrenToMenuValue(
    children: React.ReactNode,
    multiple: boolean,
    currentValue: MenuValue,
  ): MenuValue {
    const childrenValue = Menu.mapChildrenToMenuItemProps(children)
      .filter((props) => props.selected)
      .reduce<string[]>((values, props) => {
      if (props.value != null) {
        values.push(props.value);
      }

      return values;
    }, []);

    if (!multiple) {
      return head(childrenValue);
    }

    if (currentValue == null) {
      return childrenValue;
    }

    return union(currentValue, childrenValue);
  }

  static getDerivedStateFromProps(
    props: Props,
    state: State,
  ): State | null {
    const {
      children,
      highlightIndex,
      multiple,
      value: valueOnProps,
    } = props;

    const value = children != null
      ? Menu.mapChildrenToMenuValue(children, multiple, valueOnProps)
      : valueOnProps;

    return {
      highlightIndex: highlightIndex != null ? highlightIndex : state.highlightIndex,
      value: value != null ? value : state.value,
    };
  }

  menuRef: React.RefObject<HTMLUListElement>;

  highlightedItemRef: React.RefObject<HTMLLIElement>;

  lastClientX?: number;

  lastClientY?: number;

  constructor(props: Props) {
    super(props);

    const {
      defaultHighlightIndex,
      defaultValue,
    } = this.props;

    this.state = {
      highlightIndex: defaultHighlightIndex,
      value: defaultValue,
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleItemHighlight = this.handleItemHighlight.bind(this);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.handleItemSelection = this.handleItemSelection.bind(this);

    this.menuRef = React.createRef();
    this.highlightedItemRef = React.createRef();
  }

  componentDidMount(): void {
    const { autoHighlight } = this.props;
    const { highlightIndex } = this.state;

    if (autoHighlight && highlightIndex < 0) {
      this.highlightSelectedItem();
    }

    this.scrollHighlightedItemIntoView();
  }

  componentDidUpdate(): void {
    this.scrollHighlightedItemIntoView();
  }

  handleDocumentKeyDown(event: KeyboardEvent): void {
    this.moveHighlightIndexOnKeyDown(event);
    this.selectItemOnEnter(event);
    this.selectItemOnSpace(event);
  }

  handleItemHighlight(
    event: React.MouseEvent,
    props: MenuItemProps,
  ): void {
    const { index } = props;
    // NOTE: Browsers trigger the `mouseenter` and `mousemove` events when the
    // menu scrolls the currently highlighted item into view without mouse
    // interaction (e.g. using the keyboard). To avoid highlighting the item
    // under the mouse in said scenario, we will check whether the mouse has
    // moved at all by tracking and comparing its current position.
    if (index != null && this.didMousePositionChange(event)) {
      this.highlightItemAtIndex(index);
    }
  }

  handleItemSelection(
    _event: React.MouseEvent,
    props: MenuItemProps,
  ): void {
    const { index, value } = props;
    if (index != null) {
      this.selectItem(index, value);
    }
  }

  setHighlightIndex(
    index: number,
  ): void {
    const { onHighlightIndexChange } = this.props;

    if (!this.hasControlledHighlightIndex()) {
      this.setState({ highlightIndex: index });
    }

    if (typeof onHighlightIndexChange === 'function') {
      onHighlightIndexChange(index);
    }
  }

  getElement(): HTMLUListElement | null {
    return this.menuRef.current;
  }

  getEnabledIndices(): number[] {
    const items = this.getItems();
    return items.reduce<number[]>((indeces, item) => {
      if (!item.disabled) indeces.push(item.index);
      return indeces;
    }, []);
  }

  getItems(): MassagedMenuItemProps[] {
    const { children, items, multiple } = this.props;
    const { highlightIndex, value } = this.state;

    const isHighlighted = (
      itemIndex: number,
    ): boolean => itemIndex === highlightIndex;

    const isSelected = multiple
      ? (itemValue?: string): boolean => includes(value, itemValue)
      : (itemValue?: string): boolean => value != null && itemValue === value;

    const parsedItems: MenuItemProps[] = children != null
      ? Menu.mapChildrenToMenuItemProps(children)
      : items;

    return parsedItems.map((parsedItem, index) => ({
      ...parsedItem,
      highlighted: isHighlighted(index),
      index,
      selected: isSelected(parsedItem.value),
    }));
  }

  getItemByIndex(
    index: number,
  ): MassagedMenuItemProps | undefined {
    const items = this.getItems();
    return items[index];
  }

  getIndexOfItemWithValue(
    value: string,
  ): number {
    const items = this.getItems();
    return findIndex(items, (item) => item.value === value);
  }

  getNumberOfItems(): number {
    const items = this.getItems();
    return items.length;
  }

  getSelectedIndices(): number[] {
    const items = this.getItems();
    const initialValue: number[] = [];
    return items.reduce((indeces, item) => {
      if (item.selected) indeces.push(item.index);
      return indeces;
    }, initialValue);
  }

  didMousePositionChange(
    event: React.MouseEvent,
  ): boolean {
    const hasClientXChanged = this.lastClientX == null || this.lastClientX !== event.clientX;
    const hasClientYChanged = this.lastClientY == null || this.lastClientY !== event.clientY;
    this.lastClientX = event.clientX;
    this.lastClientY = event.clientY;
    return hasClientXChanged || hasClientYChanged;
  }

  /**
   * Highlights the currently selected item. The first item will be highlighted
   * when an item is not currently selected or selected item is disabled.
   */
  highlightSelectedItem(): void {
    const enabledIndices = this.getEnabledIndices();
    const selectedIndices = this.getSelectedIndices();
    const selectedIndex = first(selectedIndices);
    const firstIndex = first(enabledIndices);

    let highlightIndex = firstIndex;

    if (includes(enabledIndices, selectedIndex)) {
      highlightIndex = selectedIndex;
    }

    if (highlightIndex != null) {
      this.setHighlightIndex(highlightIndex);
    }
  }

  /**
   * Highlights the item at the specified index.
   * The item is not highlighted when disabled.
   */
  highlightItemAtIndex(index: number): void {
    const enabledIndices = this.getEnabledIndices();
    if (includes(enabledIndices, index)) {
      this.setHighlightIndex(index);
    }
  }

  /**
   * Highlights the item with the specified value.
   * The item is not highlighted when disabled.
   */
  highlightItemWithValue(value: string): void {
    const enabledIndices = this.getEnabledIndices();
    const itemIndex = this.getIndexOfItemWithValue(value);
    if (includes(enabledIndices, itemIndex)) {
      this.setHighlightIndex(itemIndex);
    }
  }

  /**
   * Updates the highlight index by the given offset.
   */
  moveHighlightIndexBy(
    offset: number,
    startIndex: number,
  ): void {
    const enabledIndices = this.getEnabledIndices();
    const lastIndex = last(enabledIndices);
    const firstIndex = first(enabledIndices);

    // Avoid infinite loop.
    if (!enabledIndices.length) {
      return;
    }

    let nextIndex: number = startIndex + offset;

    if (firstIndex != null && lastIndex != null) {
      // Next is after last, wrap to beginning
      // Next is before first, wrap to end
      if (nextIndex > lastIndex) {
        nextIndex = firstIndex;
      } else if (nextIndex < firstIndex) {
        nextIndex = lastIndex;
      }
    }

    if (includes(enabledIndices, nextIndex)) {
      this.setHighlightIndex(nextIndex);
    } else {
      this.moveHighlightIndexBy(offset, nextIndex);
    }
  }

  moveHighlightIndexOnKeyDown(
    event: KeyboardEvent,
  ): void {
    const { highlightIndex } = this.state;
    const keyCode = event.keyCode || event.which;

    switch (keyCode) {
      case KeyCodes.ArrowDown:
        event.preventDefault();
        this.moveHighlightIndexBy(1, highlightIndex);
        break;
      case KeyCodes.ArrowUp:
        event.preventDefault();
        this.moveHighlightIndexBy(-1, highlightIndex);
        break;
      default:
        break;
    }
  }

  scrollHighlightedItemIntoView(): void {
    if (this.highlightedItemRef.current != null && this.menuRef.current != null) {
      const {
        clientHeight: itemHeight,
        offsetTop: itemOffsetTop,
      } = this.highlightedItemRef.current;

      const {
        clientHeight: menuHeight,
        scrollTop: menuScrollTop,
      } = this.menuRef.current;

      const isOutOfUpperView = itemOffsetTop < menuScrollTop;

      const isOutOfLowerView = (itemOffsetTop + itemHeight) > (menuScrollTop + menuHeight);

      if (isOutOfUpperView) {
        this.menuRef.current.scrollTop = itemOffsetTop;
      } else if (isOutOfLowerView) {
        this.menuRef.current.scrollTop = (itemOffsetTop + itemHeight) - menuHeight;
      }
    }
  }

  hasControlledHighlightIndex(): boolean {
    const { highlightIndex } = this.props;
    return highlightIndex != null;
  }

  hasControlledValue(): boolean {
    const { value } = this.props;
    return value != null;
  }

  selectItem(
    itemIndex: number,
    itemValue?: string,
  ): void {
    const { multiple, onChange, onItemSelection } = this.props;
    const { value: prevValue } = this.state;

    if (typeof onItemSelection === 'function') {
      onItemSelection(itemIndex, itemValue);
    }

    let nextValue: MenuValue = itemValue;

    if (multiple) {
      // Normalize value to string[] when necessary
      if (Array.isArray(prevValue)) {
        nextValue = prevValue;
      } else if (prevValue != null) {
        nextValue = [prevValue];
      } else {
        nextValue = [];
      }

      if (itemValue != null) {
        if (includes(nextValue, itemValue)) {
          nextValue = without(nextValue, itemValue);
        } else {
          nextValue = union(nextValue, [itemValue]);
        }
      }
    }

    if (!this.hasControlledValue()) {
      this.setState({ value: nextValue });
    }

    // Trigger onChange to notify that the user is trying to change value.
    if (typeof onChange === 'function') {
      onChange(itemIndex, nextValue);
    }
  }

  selectHighlightedItem(): boolean {
    const { highlightIndex } = this.state;
    const highlightedItem = this.getItemByIndex(highlightIndex);
    if (highlightedItem != null) {
      this.selectItem(highlightedItem.index, highlightedItem.value);
      return true;
    }

    return false;
  }

  selectItemOnEnter(
    event: KeyboardEvent,
  ): void {
    const { selectItemOnEnter } = this.props;
    const keyCode = event.keyCode || event.which;
    if (selectItemOnEnter && keyCode === KeyCodes.Enter && this.selectHighlightedItem()) {
      event.preventDefault();
    }
  }

  selectItemOnSpace(
    event: KeyboardEvent,
  ): void {
    const { selectItemOnSpace } = this.props;
    const keyCode = event.keyCode || event.which;
    if (selectItemOnSpace && keyCode === KeyCodes.Space && this.selectHighlightedItem()) {
      event.preventDefault();
    }
  }

  renderChildren(): React.ReactNode {
    const { children } = this.props;
    const items = this.getItems();

    let itemIndex = 0;

    return React.Children.map(children, (node) => {
      if (!React.isValidElement(node)) {
        return node;
      }

      if (!isElementOfType(node, MenuItem)) {
        return node;
      }

      const item = items[itemIndex];

      itemIndex += 1;

      return React.cloneElement(node, {
        ...item,
        ref: item.highlighted ? this.highlightedItemRef : undefined,
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onHighlight: this.handleItemHighlight,
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onSelect: this.handleItemSelection,
      });
    });
  }

  renderItems(): React.ReactNode {
    const items = this.getItems();
    return items.map((props) => (
      <MenuItem
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        key={props.value || props.index}
        ref={props.highlighted ? this.highlightedItemRef : undefined}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onHighlight={this.handleItemHighlight}
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onSelect={this.handleItemSelection}
      />
    ));
  }

  render(): JSX.Element {
    const {
      autoHighlight,
      children,
      className,
      defaultHighlightIndex,
      defaultValue,
      highlightIndex,
      items,
      multiple,
      onChange,
      onHighlightIndexChange,
      onItemSelection,
      selectItemOnEnter,
      selectItemOnSpace,
      value,
      ...unhandledProps
    } = this.props;

    return (
      <ul
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...unhandledProps}
        className={bem.block(className)}
        ref={this.menuRef}
        role="listbox"
      >
        <EventListener
          passive={false}
          target="document"
          type="keydown"
          // eslint-disable-next-line @typescript-eslint/unbound-method
          listener={this.handleDocumentKeyDown}
        />
        {children != null ? this.renderChildren() : this.renderItems()}
      </ul>
    );
  }
}

export default Menu;
