/* eslint-disable */
import { parseQueryString } from '@flowio/web-sdk';

/**
 * Initializes shopify.js (previously flow.js)
 */
export default function init(flowOrganization) {
  const params = parseQueryString(window.location.search);
  const { shop } = params;

  if (!flowOrganization || !shop) {
    console.error(`Expect flowOrganization[${flowOrganization}] to be defined and shop[${shop}] url parameter to be provided. ex: ?flow_integration=shopify&shop=flow-development-346.myshopify.com`);
    return;
  }

  (function (f, l, o, w, i, n, g) {
    f[i] = f[i] || {};
    f[i].set = f[i].set || function () {
      (f[i].q = f[i].q || []).push(arguments);
    };
    f[i].initialized = 0;
    n = l.createElement(o);
    n.src = w;
    n.async = 1;
    n.defer = 1;
    g = l.getElementsByTagName(o)[0];
    g.parentNode.insertBefore(n, g);
  })(window, document, 'script', `https://shopify-cdn.flow.io/${flowOrganization}/js/v0/flow.js?shop=${shop}`, 'Flow');
}
