import BemHelper from '@flowio/bem-helper';
import getComputedStyle from '@flowio/browser-helpers/lib/getStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { injectIntl } from 'react-intl';

import { messages } from '../../containers/payment-form/validation';
import labels from '../../containers/payment-form/labels';
import Spinner from '../spinner';
import TextField from '../text-field';

if (process.browser) {
  require('./flow-card-element.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('flow-card-element');

class FlowCardElement extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleCardElementMount = this.handleCardElementMount.bind(this);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    const { flow, registerElement } = this.context;
    const { genericMobileImprovements, intl } = this.props;
    const { formatMessage } = intl;

    // eslint-disable-next-line react/no-find-dom-node
    const inputStyle = getComputedStyle(findDOMNode(this.input));
    // eslint-disable-next-line react/no-find-dom-node
    const labelStyle = getComputedStyle(findDOMNode(this.label));

    this.card = flow.createElement('card', {
      genericMobileImprovements,
      styles: {
        labelColor: labelStyle.color,
        labelFontSize: labelStyle.fontSize,
        inputColor: inputStyle.color,
        inputBackgroundColor: inputStyle.backgroundColor,
        inputBorderColor: inputStyle.borderColor,
        inputFontSize: inputStyle.fontSize,
      },
      errorMessages: {
        // TODO: Remove explicit mention of "credit card" in library because it
        // accepts both debit/credit card payments.
        missingCreditCardExpiryMonth: formatMessage(messages.missingCardExpiryMonth),
        missingCreditCardExpiryYear: formatMessage(messages.missingCardExpiryYear),
        missingCreditCardName: formatMessage(messages.missingCardName),
        missingCreditCardNumber: formatMessage(messages.missingCardNumber),
        missingCreditCardSecurityCode: formatMessage(messages.missingCardSecurityCode),
        // TODO: Give localized message for card validation error:
        // "There was a problem validating the credit card data"
      },
      messages: [
        {
          class: 'name-label',
          text: formatMessage(labels.name),
        },
        {
          class: 'cc-label',
          text: formatMessage(labels.number),
        },
        {
          class: 'month-label',
          text: formatMessage(labels.expirationMonth),
        },
        {
          class: 'year-label',
          text: formatMessage(labels.expirationYear),
        },
        {
          class: 'cvv-label',
          text: formatMessage(labels.cvv),
        },
      ],
    });
    this.card.mount(this.ref, this.handleCardElementMount);
    registerElement(this.card);
  }

  componentWillUnmount() {
    const { unregisterElement } = this.context;
    this.card.unmount();
    unregisterElement(this.card);
  }

  handleCardElementMount() {
    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className={bem.block()}>
        {isLoading && (
          <div className={bem.element('spinner-wrapper')}>
            <Spinner className={bem.element('spinner')} size="small" />
          </div>
        )}
        <div
          className={bem.element('content', { loading: isLoading })}
          ref={(node) => { this.ref = node; }}
        />
        {isLoading && (
          <div style={{ display: 'none' }}>
            <TextField
              labelText="XOXO"
              inputRef={(node) => { this.input = node; }}
              labelRef={(node) => { this.label = node; }}
            />
          </div>
        )}
      </div>
    );
  }
}

FlowCardElement.displayName = 'FlowCardElement';

FlowCardElement.contextTypes = {
  flow: PropTypes.shape({
    createElement: PropTypes.func.isRequired,
    createToken: PropTypes.func.isRequired,
  }).isRequired,
  registerElement: PropTypes.func.isRequired,
  unregisterElement: PropTypes.func.isRequired,
};

FlowCardElement.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  genericMobileImprovements: PropTypes.bool,
};

FlowCardElement.defaultProps = {
  genericMobileImprovements: false,
};

export default injectIntl(FlowCardElement);
