import { push, replace } from 'react-router-redux';

import { getPaymentInfoUrlV2 } from '../selectors';
import { RedirectMethod } from '../types';
import { ThunkResult } from '../../types';

/**
 * Navigates to payment page based on the current application state.
 */
export default function visitPaymentInfoStep(
  method: RedirectMethod = 'push',
): ThunkResult<void> {
  return function visitPaymentInfoStepSideEffects(dispatch, getState): void {
    const state = getState();
    const location = getPaymentInfoUrlV2(state);

    if (method === 'push') {
      dispatch(push(location));
    } else {
      dispatch(replace(location));
    }
  };
}
