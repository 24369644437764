/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import SvgIcon from '../svg-icon';

interface GiftCardProps {
  className: string;
}

const GiftCard = (props: GiftCardProps): JSX.Element => (
  <SvgIcon viewBox="0 0 50 30" {...props}>
    <g fillRule="evenodd">
      <path fillRule="nonzero" d="M15.53 16.567h8.39v8.402h-6.292a2.101 2.101 0 0 1-2.097-2.1v-6.302zm10.488 0h8.39v6.302c0 1.158-.941 2.1-2.098 2.1h-6.292v-8.402zm7.34-6.302a2.1 2.1 0 0 1 2.098 2.1v3.15h-9.438v-4.2H23.92v4.2h-9.438v-3.15c0-1.159.94-2.1 2.097-2.1h3.266a5.247 5.247 0 0 1-1.296-.922 3.16 3.16 0 0 1 0-4.456c1.19-1.189 3.26-1.189 4.45-.001 1.716 1.72 1.948 4.956 1.969 5.32.001.02-.01.04-.01.06h.022c0-.022-.011-.04-.009-.06.021-.363.253-3.599 1.969-5.318 1.19-1.19 3.259-1.189 4.448-.001a3.16 3.16 0 0 1 0 4.457 5.242 5.242 0 0 1-1.295.921h3.266zM20.034 7.858c.614.616 1.732.979 2.656 1.172-.193-.927-.554-2.038-1.172-2.658a1.045 1.045 0 0 0-.743-.307c-.28 0-.544.11-.741.307a1.053 1.053 0 0 0 0 1.486zm7.216 1.174c.926-.192 2.036-.553 2.655-1.174.41-.41.41-1.076 0-1.487a1.041 1.041 0 0 0-.74-.306c-.28 0-.544.11-.743.308-.618.62-.98 1.732-1.172 2.66z" />
    </g>
  </SvgIcon>
);

GiftCard.displayName = 'GiftCard';

export default GiftCard;
