import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function setReviewError(
  // TODO: What's the type for this parameter?
  error: unknown,
): RootAction {
  return {
    type: actionTypes.SET_REVIEW_ERROR,
    payload: error,
  };
}
