import type { DiscountCodesPutResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<DiscountCodesPutResponse>>;

type Parameters = {
  organizationId: string;
  orderNumber: string;
  promoCode: string;
};

const addGenericPromoCodeRequest = (
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.ADD_GENERIC_PROMOTION_CODE_REQUEST,
  organization: parameters.organizationId,
  orderNumber: parameters.orderNumber,
  promotionCode: parameters.promoCode,
});

const addGenericPromoCodeSuccess = (
  orderBuilder: io.flow.experience.v0.models.OrderBuilder,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.ADD_GENERIC_PROMOTION_CODE_SUCCESS,
  organization: parameters.organizationId,
  orderNumber: parameters.orderNumber,
  payload: orderBuilder,
  promotionCode: parameters.promoCode,
});

const addGenericPromoCodeFailure = (
  error: io.flow.error.v0.models.GenericError | LegacyError,
  parameters: Parameters,
): RootAction => ({
  type: actionTypes.ADD_GENERIC_PROMOTION_CODE_FAILURE,
  organization: parameters.organizationId,
  orderNumber: parameters.orderNumber,
  payload: error,
  promotionCode: parameters.promoCode,
});

const addNonShopifyPromoCode = (
  organizationId: string,
  orderNumber: string,
  promoCode: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const parameters: Parameters = {
    organizationId,
    orderNumber,
    promoCode,
  };

  dispatch(addGenericPromoCodeRequest(parameters));

  return extra.apiInternal.discountCodes.put({
    number: orderNumber,
    organization: organizationId,
    body: {
      code: promoCode,
    },
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(addGenericPromoCodeSuccess(response.result, parameters));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(addGenericPromoCodeFailure(response.result, parameters));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default addNonShopifyPromoCode;
