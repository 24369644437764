// @ts-check

import { compose } from 'redux';
// @ts-ignore
import { withFetch } from '@flowio/redux-fetch';
import identity from 'lodash/identity';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
// @ts-ignore
import { appendQueryParameters, parseQueryString } from '@flowio/web-sdk';

import Application from './application';
import { createCheckoutOrder } from '../store/order/actions';
import { getIsOrderFulfilled, getIsOrderRejected } from '../store/checkout/selectors';
import OrderAttributes from '../constants/order-attributes';
import { getCheckoutContactInfoUrl, getCartUrl } from '../store/navigation/selectors';
import getFeatureKeys from '../utilities/get-feature-keys';
import withRedirect from '../components/with-redirect';
import { getReturnUrl } from '../store/flow/selectors';

/**
 * @typedef {import('../middlewares/redux-async-middleware').AsyncAction<R, E>} AsyncAction
 * @template R, E
 */

/**
 * @typedef {import('../store/types').RootState} RootState
 */

/**
 * @typedef {io.flow.internal.v0.models.V1Checkout} Checkout
 * @typedef {io.flow.internal.v0.unions.CheckoutForm} CheckoutForm
 * @typedef {io.flow.v0.models.OrderPutForm} OrderPutForm
 * @typedef {{ query: LocationQuery }} Location
 * @typedef {{ experience: string, currency?: string, ip?:string, language?: string }} LocationQuery
 */

/**
 * @typedef {object} FetchData
 * @property {string} [experience]
 * @property {string} [country]
 * @property {string} [currency]
 * @property {string} [ip]
 * @property {string} [language]
 */

/**
 * @param {import('redux').Dispatch<RootState>} dispatch
 * @param {function(): RootState} getState
 * @param {Object<import('react-router').RouterProps, Location>} param2
 * @return {Promise<any>}
 */
const fetchAsyncState = (dispatch, getState, { location }) => {
  /** @type {Partial<CheckoutForm>} */
  const data = {};
  /**  @type {string|undefined} */
  const returnUrl = getReturnUrl(getState());

  // Use set to convert items[0][number]=40398686663 into { items: [{ number: 40398686663 }] }
  /** @type {any} */
  const parsedQuery = {};
  forEach(location.query, (value, key) => set(parsedQuery, key, value));

  if (returnUrl) {
    set(data, `attributes.${OrderAttributes.RETURN_URL}`, decodeURIComponent(returnUrl));
  }

  const {
    flow_organization: organization,
    experience,
    country,
    currency,
    ip,
    language,
    ...orderPutForm
  } = location.query;
  orderPutForm.items = parsedQuery.items;

  return Promise.resolve().then(() => (
    dispatch(createCheckoutOrder(
      {
        organization,
        order: orderPutForm,
        feature_keys: getFeatureKeys(),
        order_parameters: {
          experience,
          country,
          currency,
          ip,
          language,
        },
        discriminator: 'order',
      },
    ))));
};

/**
 * Generate URL with allowlisted params appended
 * @param {RootState} state - Redux state
 * @returns {string}
 */
const getContactInfoUrlWithParams = (state) => {
  const queryParams = parseQueryString(window.location.search);
  const baseUrl = getCheckoutContactInfoUrl(state);
  const {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  } = queryParams;
  const params = {
    flow_integration: flowIntegration,
    flow_debug: flowDebug,
  };

  // @ts-ignore
  return appendQueryParameters(baseUrl, pickBy(params, identity));
};

export default compose(
  withFetch(fetchAsyncState),
  // @ts-ignore
  withRedirect(getContactInfoUrlWithParams, getIsOrderFulfilled, { replace: true }),
  withRedirect(getCartUrl, getIsOrderRejected, { replace: true }),
)(Application);
