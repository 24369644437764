import type { AppReducer } from '../../types';
import type { CheckoutConfigState } from '../types';

const initialState: CheckoutConfigState = {};

const checkoutConfigReducer: AppReducer<CheckoutConfigState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    default:
      return previousState;
  }
};

export default checkoutConfigReducer;
