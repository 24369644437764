import { AddressFormValues } from '../../store/checkout/types';

function fromOrderAddress(
  address: io.flow.v0.models.OrderAddress,
): Partial<AddressFormValues> {
  return {
    addressLine1: address.streets && address.streets[0],
    addressLine2: address.streets && address.streets[1],
    administrativeArea: address.province,
    country: address.country,
    firstName: address.contact && address.contact.name.first,
    lastName: address.contact && address.contact.name.last,
    locality: address.city,
    organizationName: address.contact && address.contact.company,
    phoneNumber: address.contact && address.contact.phone,
    postalCode: address.postal,
  };
}

function fromCustomerAddressBookContact(
  contact: io.flow.v0.models.CustomerAddressBookContact,
): Partial<AddressFormValues> {
  return {
    addressLine1: contact.address.streets && contact.address.streets[0],
    addressLine2: contact.address.streets && contact.address.streets[1],
    administrativeArea: contact.address.province,
    country: contact.address.country,
    firstName: contact.contact.name.first,
    lastName: contact.contact.name.last,
    locality: contact.address.city,
    organizationName: contact.contact.company,
    phoneNumber: contact.contact.phone,
    postalCode: contact.address.postal,
  };
}

export default {
  fromOrderAddress,
  fromCustomerAddressBookContact,
};
