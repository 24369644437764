import { push } from 'react-router-redux';

import { getContactInfoUrlV2, isContactInfoStep } from '../selectors';
import { ThunkResult } from '../../types';

/**
 * Creates an action responsible for redirecting customer to contact info step
 * unless the current step is already the contact info step.
 */
export default function visitContactInfoStep(): ThunkResult<void> {
  return function visitContactInfoStepSideEffects(dispatch, getState): void {
    if (!isContactInfoStep(getState())) {
      dispatch(push(getContactInfoUrlV2(getState())));
    }
  };
}
