/**
 * This utility appends an HTML form element with the provided parameters as
 * hidden HTML input elements. The form is automatically submitted, posting its
 * data to the specified location.
 * @param {string} location
 * @param {object} parameters
 */
export default function postRedirect(location, parameters) {
  const form = document.createElement('form');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', location);

  Object.keys(parameters).forEach((key) => {
    const value = parameters[key];
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', value);
    form.appendChild(input);
  });

  document.body.appendChild(form);

  form.submit();
}
