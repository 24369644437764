import { defineMessages } from 'react-intl';
import curry from 'lodash/curry';
import get from 'lodash/get';
import find from 'lodash/find';
import matchesProperty from 'lodash/matchesProperty';
import validate from '../../utilities/validate';
import {
  isCard,
  isBillingAddressSameAsShippingAddress,
  isInvoiceAddressSameAsShippingAddress,
  isAch,
  isBillingAddressSavedAddress,
  isInvoiceAddressSavedAddress,
  formValuesFromAddressBook,
} from './utilities';
import {
  addressConfigurationToConstraints,
} from '../../utilities/address-utilities';
import { getContactById } from '../../utilities/address-book-utilities';

export const messages = defineMessages({
  missingCountry: {
    id: 'checkout_address_field_error_missing_country',
    description: 'A validation error message displayed in address forms when a country is not provided',
    defaultMessage: 'Please enter country',
  },
  missingCardNumber: {
    id: 'checkout_card_field_error_missing_number',
    description: 'A validation message displayed when a card number is not provided',
    defaultMessage: 'Please enter card number',
  },
  missingCardName: {
    id: 'checkout_card_field_error_missing_cardholder',
    description: 'A validation message displayed when a card holder name is not provided',
    defaultMessage: 'Please enter cardholder name',
  },
  missingCardExpiryMonth: {
    id: 'checkout_card_field_error_missing_expiration_month',
    description: 'A validation message displayed when a card expiration month is not provided',
    defaultMessage: 'Please enter expiration month',
  },
  missingCardExpiryYear: {
    id: 'checkout_card_field_error_missing_expiration_year',
    description: 'A validation message displayed when a card expiration year is not provided',
    defaultMessage: 'Please enter expiration year',
  },
  missingCardSecurityCode: {
    id: 'checkout_card_field_error_missing_security_code',
    description: 'A validation message displayed when a card security code is not provided',
    defaultMessage: 'Please enter verification code',
  },
  missingPaymentMethod: {
    id: 'checkout_payment_method_field_error_missing_payment_method',
    defaultMessage: 'Please select a payment type',
    description: 'A validation message displayed when a payment method is not selected',
  },
  missingPaymentMethodIssuer: {
    id: 'checkout_payment_method_field_error_missing_payment_method_issuer',
    defaultMessage: 'Please select an issuing bank',
    description: 'A validation message displayed when a payment method issuer is not selected',
  },
  missingAccountHolderName: {
    id: 'checkout_payment_method_field_error_missing_account_holder_name',
    defaultMessage: 'Please enter the name of the account holder',
    description: 'A validation message displayed when the name of the account holder is missing',
  },
  missingAccountRoutingNumber: {
    id: 'checkout_payment_method_field_error_missing_account_routing_number',
    defaultMessage: 'Please enter the ABA Routing Number',
    description: 'A validation message displayed when the account routing number is missing',
  },
  missingAccountNumber: {
    id: 'checkout_payment_method_field_error_missing_account_number',
    defaultMessage: 'Please enter the account number',
    description: 'A validation message displayed when the account number is missing',
  },
  tooLong: {
    id: 'checkout_address_field_error_character_limit',
    description: 'A validation error message displayed when a field exceeds the maximum number of characters allowed',
    // This is a bug in the rule. Once fixed we can remove.
    // https://github.com/formatjs/formatjs/issues/356
    // eslint-disable-next-line formatjs/enforce-placeholders
    defaultMessage: 'Sorry, this field cannot be more than {length, plural, one {# character} other {# characters}} long',
  },
  wrongLengthRoutingNumber: {
    id: 'checkout_payment_method_field_error_incorrect_routing_number',
    defaultMessage: 'Routing number must be 9 digits long',
    description: 'A validation message displayed when the account routing number is not the right length',
  },
  wrongLengthAccountNumber: {
    id: 'checkout_payment_method_field_error_incorrect_account_number',
    defaultMessage: 'Account number must be between 4 and 17 characters long',
    description: 'A validation message displayed when the account number is not the right length',
  },
  invalidCustomerAddressBook: {
    id: 'checkout_field_error_address_book',
    description: 'An error message that is shown when an incomplete address book is selected',
    defaultMessage: 'The address you have selected is incomplete. Please update the address or select a different address.',
  },
});

const onlyCard = curry((constraints, value, attributes) => {
  if (isCard(attributes)) return constraints;
  return undefined;
});

const onlyDifferentBillingAddress = curry((constraints, value, attributes) => {
  if (
    !isBillingAddressSameAsShippingAddress(attributes)
    && !isBillingAddressSavedAddress(attributes)
  ) return constraints;
  return undefined;
});

const onlyDifferentInvoiceAddress = curry((constraints, value, attributes) => {
  if (
    !isInvoiceAddressSameAsShippingAddress(attributes)
    && !isInvoiceAddressSavedAddress(attributes)
  ) return constraints;
  return undefined;
});

const onlyACHTransfer = curry((constraints, value, attributes) => {
  if (isAch(attributes)) return constraints;
  return undefined;
});

const onlyPaymentMethodIssuerRequired = curry((props, constraints, value, attributes) => {
  const { paymentMethodId } = attributes;
  const paymentMethodRules = get(props, 'paymentMethodRules', []);
  const paymentMethodRule = find(paymentMethodRules, matchesProperty('payment_method.id', paymentMethodId));
  const paymentMethodIssuers = get(paymentMethodRule, 'issuers', []);
  if (paymentMethodIssuers.length > 0) return constraints;
  return undefined;
});

function validateAddressToUse(addressToUse, addressBook, addressConstraints, formatMessage) {
  if (addressToUse === 'newAddress' || addressToUse === 'sameAsShippingAddress' || addressBook == null) {
    return undefined;
  }

  const contact = getContactById(addressBook, addressToUse);
  const addressBookFormValues = formValuesFromAddressBook(contact);

  const result = validate(addressBookFormValues, {
    addressLine1: addressConstraints.addressLine1,
    addressLine2: addressConstraints.addressLine2,
    administrativeArea: addressConstraints.administrativeArea,
    country: {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCountry),
      },
    },
    firstName: addressConstraints.firstName,
    lastName: addressConstraints.lastName,
    locality: addressConstraints.locality,
    organizationName: {
      length: {
        maximum: 35,
        message: formatMessage(messages.tooLong, { length: 35 }),
      },
    },
    postalCode: addressConstraints.postalCode,
  });
  return result;
}

function isValidAddressToUse(addressToUse, addressBook, constraints, formatMessage) {
  const errors = validateAddressToUse(addressToUse, addressBook, constraints, formatMessage);
  return errors == null;
}

export function handleValidation(values, props) {
  const {
    addressBook,
    billingAddressConfiguration,
    invoiceAddressConfiguration,
    intl: {
      formatMessage,
    },
    marketingOptIns,
    allowInvoiceAddress,
  } = props;

  const {
    billingAddress,
    invoiceAddress,
  } = values;

  const billingAddressConstraints = addressConfigurationToConstraints(
    billingAddressConfiguration,
    formatMessage,
  );

  const invoiceAddressConstraints = addressConfigurationToConstraints(
    invoiceAddressConfiguration,
    formatMessage,
  );

  const marketingOptinConstraints = {};

  // Same logic as seen in payment-form.jsx
  const isUsingInvoiceAddress = allowInvoiceAddress && invoiceAddress != null;

  marketingOptIns.forEach((optin) => {
    if (optin.required) {
      marketingOptinConstraints[optin.name] = {
        exclusion: {
          within: { false: 'false' },
          message: 'This field is required',
        },
      };
    }
  });

  let errors = validate(values, {
    abaRoutingNumber: onlyACHTransfer({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingAccountRoutingNumber),
      },
      length: {
        minimum: 9,
        maximum: 9,
        message: formatMessage(messages.wrongLengthRoutingNumber),
      },
    }),
    accountHolderName: onlyACHTransfer({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingAccountHolderName),
      },
    }),
    bankAccountNumber: onlyACHTransfer({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingAccountNumber),
      },
      length: {
        minimum: 4,
        maximum: 17,
        message: formatMessage(messages.wrongLengthAccountNumber),
      },
    }),
    'billingAddress.addressLine1': onlyDifferentBillingAddress(billingAddressConstraints.addressLine1),
    'billingAddress.addressLine2': onlyDifferentBillingAddress(billingAddressConstraints.addressLine2),
    'billingAddress.administrativeArea': onlyDifferentBillingAddress(billingAddressConstraints.administrativeArea),
    'billingAddress.country': onlyDifferentBillingAddress({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCountry),
      },
    }),
    'billingAddress.firstName': onlyDifferentBillingAddress(billingAddressConstraints.firstName),
    'billingAddress.lastName': onlyDifferentBillingAddress(billingAddressConstraints.lastName),
    'billingAddress.locality': onlyDifferentBillingAddress(billingAddressConstraints.locality),
    'billingAddress.organizationName': onlyDifferentBillingAddress({
      length: {
        maximum: 35,
        message: formatMessage(messages.tooLong, { length: 35 }),
      },
    }),
    'billingAddress.postalCode': onlyDifferentBillingAddress(billingAddressConstraints.postalCode),
    'billingAddress.addressToUse': {
      presence: {
        allowEmpty: false,
      },
    },
    'invoiceAddress.addressLine1': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.addressLine1) : undefined,
    'invoiceAddress.addressLine2': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.addressLine2) : undefined,
    'invoiceAddress.administrativeArea': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.administrativeArea) : undefined,
    'invoiceAddress.country': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCountry),
      },
    }) : undefined,
    'invoiceAddress.firstName': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.firstName) : undefined,
    'invoiceAddress.lastName': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.lastName) : undefined,
    'invoiceAddress.locality': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.locality) : undefined,
    'invoiceAddress.organizationName': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress({
      length: {
        maximum: 35,
        message: formatMessage(messages.tooLong, { length: 35 }),
      },
    }) : undefined,
    'invoiceAddress.postalCode': isUsingInvoiceAddress ? onlyDifferentInvoiceAddress(invoiceAddressConstraints.postalCode) : undefined,
    'invoiceAddress.addressToUse': isUsingInvoiceAddress ? {
      presence: {
        allowEmpty: false,
      },
    } : undefined,
    cardExpiryMonth: onlyCard({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCardExpiryMonth),
      },
    }),
    cardExpiryYear: onlyCard({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCardExpiryYear),
      },
    }),
    cardName: onlyCard({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCardName),
      },
    }),
    cardNumber: onlyCard({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCardNumber),
      },
    }),
    cardSecurityCode: onlyCard({
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingCardSecurityCode),
      },
    }),
    orderNumber: {
      presence: {
        allowEmpty: false,
      },
    },
    organizationId: {
      presence: {
        allowEmpty: false,
      },
    },
    paymentMethodId: {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingPaymentMethod),
      },
    },
    paymentMethodIssuerId: onlyPaymentMethodIssuerRequired(props, {
      presence: {
        allowEmpty: false,
        message: formatMessage(messages.missingPaymentMethodIssuer),
      },
    }),
    ...marketingOptinConstraints,
  }, {
    format: 'zipped',
    fullMessages: false,
  });

  if (billingAddress != null) {
    const isValidBillingAddressToUse = isValidAddressToUse(
      billingAddress.addressToUse,
      addressBook,
      billingAddressConstraints,
      formatMessage,
    );

    if (!isValidBillingAddressToUse) {
      if (errors == null) errors = {};
      if (errors.billingAddress == null) errors.billingAddress = {};
      errors.billingAddress.addressToUse = formatMessage(messages.invalidCustomerAddressBook);
    }
  }

  if (invoiceAddress != null) {
    const isValidInvoiceAddressToUse = isValidAddressToUse(
      invoiceAddress.addressToUse,
      addressBook,
      invoiceAddressConstraints,
      formatMessage,
    );

    if (!isValidInvoiceAddressToUse) {
      if (errors == null) errors = {};
      if (errors.invoiceAddress == null) errors.invoiceAddress = {};
      errors.invoiceAddress.addressToUse = formatMessage(messages.invalidCustomerAddressBook);
    }
  }

  return errors;
}
