/**
 * @fileoverview
 * FlowProvider injects into context an interface to tokenize credit cards using
 * either our sandboxed or legacy strategy based on the features enabled
 * for an organization.
 */
import PropTypes from 'prop-types';
import React from 'react';
import flow from '@flowio/javascript';
import memoize from 'lodash/memoize';

// Defer legacy js.flow.io client until needed and reuse on subsequent calls.
const createLegacyClient = memoize((organizationId, publicKey) => flow.createClient({
  organization: organizationId,
  publicKey,
}));

class FlowProvider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleCreateElement = this.handleCreateElement.bind(this);
    this.handleCreateToken = this.handleCreateToken.bind(this);
    this.handleRegisterElement = this.handleRegisterElement.bind(this);
    this.handleUnregisterElement = this.handleUnregisterElement.bind(this);
    this.state = {
      registeredElements: [],
    };
  }

  getChildContext() {
    return {
      flow: {
        createElement: this.handleCreateElement,
        createToken: this.handleCreateToken,
      },
      registerElement: this.handleRegisterElement,
      unregisterElement: this.handleUnregisterElement,
    };
  }

  handleCreateElement(elementType, options = {}) {
    const { organizationId, publicKey } = this.props;
    return flow.createElement(elementType, {
      ...options,
      organization: organizationId,
      publicKey,
    });
  }

  handleCreateToken(cardForm, callback) {
    this.createSandboxedCardToken(cardForm, callback);
  }

  handleRegisterElement(element) {
    this.setState((prevState) => ({
      registeredElements: [
        ...prevState.registeredElements,
        element,
      ],
    }));
  }

  handleUnregisterElement(element) {
    this.setState((prevState) => ({
      registeredElements: prevState.registeredElements
        .filter((registeredElement) => registeredElement !== element),
    }));
  }

  createLegacyCardToken(cardForm, callback) {
    const { organizationId, publicKey } = this.props;
    const client = createLegacyClient(organizationId, publicKey);
    client.card.post(cardForm, (status, response) => {
      callback(status, response);
    });
  }

  createSandboxedCardToken(cardForm, callback) {
    const element = this.findElement();
    const options = {};

    if (cardForm.address != null) {
      options.billingAddress = cardForm.address;
    }

    if (cardForm.metadata != null) {
      options.metadata = cardForm.metadata;
    }

    if (cardForm.requested_currency != null) {
      options.requestedCurrency = cardForm.requested_currency;
    }

    element.tokenize((response, status) => {
      callback(status, response);
    }, options);
  }

  findElement() {
    const { registeredElements } = this.state;

    if (registeredElements.length === 1) {
      return registeredElements[0];
    }

    if (registeredElements.length > 1) {
      throw new Error('We could not infer which element you want to use for this operation.');
    }

    return null;
  }

  render() {
    const { children } = this.props;
    return React.Children.only(children);
  }
}

FlowProvider.displayName = 'FlowProvider';

FlowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  organizationId: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
};

FlowProvider.childContextTypes = {
  flow: PropTypes.shape({
    createElement: PropTypes.func,
    createToken: PropTypes.func,
  }),
  registerElement: PropTypes.func,
  unregisterElement: PropTypes.func,
};

export default FlowProvider;
