/* eslint-disable global-require */

import { FormattedMessage, FormattedDate } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import without from 'lodash/without';
import kebabCase from 'lodash/kebabCase';

import FeatureToggle from '../feature-toggle';
import { REMOVE_TAX_DUTY } from '../../../common/constants/feature-keys';
import { RadioButtonGroup, RadioButton } from '../radio-button';
import DeliveredDuty from '../../constants/delivered-duties';
import DeliveredDutyDisplayType from '../../constants/delivered-duty-display-type';
import TierEstimateType from '../../constants/tier-estimate-type';
import ZeroAmountIndicator from '../../constants/zero-amount-indicator';
import getFormattedDeliveryOptionLabel from '../../utilities/get-formatted-delivery-option-label';
import getTierEstimateType from '../../utilities/get-tier-estimate-type';

if (process.browser) {
  require('./delivery-options.css');
}

// Base class name differs from component name for backward compatibility.
const bem = new BemHelper('order-delivery-options');

/**
 * A component responsible for rendering delivery options based on the landed
 * cost settings configured on the current experience.
 *
 * In "single" display mode, delivery options for the specified delivered duty
 * are presented to customers. Internally, the component filters the delivery
 * options matching the specified delivered duty.
 *
 * In "all" display mode, delivery options for both paid and unpaid delivered
 * duties are presented to customers.
 */
class DeliveryOptions extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, deliveryOptionId) {
    const { delivery, onChangeValue } = this.props;
    const deliveryId = delivery.id;
    onChangeValue(deliveryId, deliveryOptionId);
  }

  getDdpDeliveryOptions() {
    const { delivery } = this.props;
    return filter(delivery.options, { delivered_duty: DeliveredDuty.Paid });
  }

  getDduDeliveryOptions() {
    const { delivery } = this.props;
    return filter(delivery.options, { delivered_duty: DeliveredDuty.Unpaid });
  }

  renderDeliveryOptions(deliveryOptions) {
    const { name, value } = this.props;
    return (
      <RadioButtonGroup bordered fluid name={name} value={value} onChange={this.handleChange}>
        {map(deliveryOptions, (deliveryOption) => {
          const deliveredDuty = kebabCase(get(deliveryOption, 'delivered_duty'));
          const tierEstimateType = getTierEstimateType(deliveryOption);
          const tierName = kebabCase(get(deliveryOption, 'tier.name'));
          const tierStrategy = kebabCase(get(deliveryOption, 'tier.strategy'));

          const isCustomTierEstimateType = tierEstimateType === TierEstimateType.Custom;
          const isFreeOfCharge = deliveryOption.price.amount === 0;
          const isFlatRate = get(deliveryOption, 'rule_outcome.discriminator') === 'flat_rate';
          const isFreeZeroAmountIndicator = get(deliveryOption, 'rule_outcome.zero_amount_indicator') === ZeroAmountIndicator.Free;
          const isSameDayDeliveryWindow = deliveryOption.window.from === deliveryOption.window.to;

          const tierMessage = get(deliveryOption, 'tier.message');

          return (
            <RadioButton
              key={deliveryOption.id}
              value={deliveryOption.id}
              data-automation-id={deliveryOption.id}
              data-flow-tier-name={tierName}
              data-flow-tier-strategy={tierStrategy}
              data-flow-delivered-duty={deliveredDuty}
            >
              <div className={bem.element('option')}>
                <div className={bem.element('option-text')}>
                  <div className={bem.element('option-primary-text')}>
                    {getFormattedDeliveryOptionLabel(deliveryOption)}
                  </div>
                  {!isCustomTierEstimateType && !isSameDayDeliveryWindow && (
                    <div className={bem.element('option-secondary-text')}>
                      <FormattedMessage
                        id="checkout_order_delivery_options_estimated_delivery_window"
                        description="A delivery window (a range of dates) telling the user when a delivery is expected to arrive"
                        defaultMessage="Estimated delivery: {windowFrom} - {windowTo}"
                        values={{
                          windowFrom: <FormattedDate value={deliveryOption.window.from} year="2-digit" month="2-digit" day="2-digit" timeZone="UTC" />,
                          windowTo: <FormattedDate value={deliveryOption.window.to} year="2-digit" month="2-digit" day="2-digit" timeZone="UTC" />,
                        }}
                      />
                    </div>
                  )}
                  {!isCustomTierEstimateType && isSameDayDeliveryWindow && (
                    <div className={bem.element('option-secondary-text')}>
                      <FormattedMessage
                        id="checkout_order_delivery_options_estimated_delivery"
                        description="Text stating the estimated delivery date"
                        defaultMessage="Estimated delivery: {deliveryDate}"
                        values={{
                          deliveryDate: <FormattedDate value={deliveryOption.window.from} year="2-digit" month="2-digit" day="2-digit" timeZone="UTC" />,
                        }}
                      />
                    </div>
                  )}
                  {tierMessage && (
                    <div className={bem.element('option-tier-message-text')}>
                      {tierMessage}
                    </div>
                  )}
                </div>
                {(isFreeOfCharge && isFlatRate && isFreeZeroAmountIndicator) ? (
                  <div className={bem.element('option-price')}>
                    <FormattedMessage
                      id="checkout_delivery_option_free"
                      description="Text stating the delivery option is free"
                      defaultMessage="FREE"
                    />
                  </div>
                ) : (
                  <div className={bem.element('option-price')}>
                    {deliveryOption.price.label}
                  </div>
                )}
              </div>
            </RadioButton>
          );
        })}
      </RadioButtonGroup>
    );
  }

  renderDdpDeliveryOptions() {
    const { deliveredDutyDisplayType } = this.props;
    const deliveryOptions = this.getDdpDeliveryOptions();

    if (deliveredDutyDisplayType === DeliveredDutyDisplayType.Single) {
      return this.renderDeliveryOptions(deliveryOptions);
    }

    return (
      <FeatureToggle
        featureKey={REMOVE_TAX_DUTY}
        render={({ isFeatureEnabled }) => (!isFeatureEnabled ? (
          <section key={DeliveredDuty.Paid} className={bem.element('delivered-duty-options')}>
            <header className={bem.element('delivered-duty-header')}>
              <h4 className={bem.element('delivered-duty-title', { positive: true })}>
                <FormattedMessage
                  id="checkout_DDP_label"
                  description="An explanation of DDP stating that the customer is choosing to pay the taxes and duties during checkout before the shipment is delivered"
                  defaultMessage="Pay Tax / Duty now"
                />
              </h4>
            </header>
            {this.renderDeliveryOptions(deliveryOptions)}
          </section>
        ) : null
        )}
      />
    );
  }

  renderDduDeliveryOptions() {
    const { deliveredDutyDisplayType } = this.props;
    const deliveryOptions = this.getDduDeliveryOptions();

    if (deliveredDutyDisplayType === DeliveredDutyDisplayType.Single) {
      return this.renderDeliveryOptions(deliveryOptions);
    }

    return (
      <section key={DeliveredDuty.Unpaid} className={bem.element('delivered-duty-options')}>
        <header className={bem.element('delivered-duty-header')}>
          <h4 className={bem.element('delivered-duty-title', { primary: true })}>
            <FeatureToggle
              featureKey={REMOVE_TAX_DUTY}
              render={({ isFeatureEnabled }) => (!isFeatureEnabled
                ? (
                  <FormattedMessage
                    id="checkout_DDU_label"
                    description="An explanation of DDU stating that the customer will be responsible for paying taxes and duties when the order is recieved"
                    defaultMessage="Pay Tax/Duty upon delivery"
                  />
                ) : null
              )}
            />
          </h4>
        </header>
        {this.renderDeliveryOptions(deliveryOptions)}
      </section>
    );
  }

  renderDeliveryOptionsInDeliveredDuty(deliveredDuty) {
    switch (deliveredDuty) {
      case DeliveredDuty.Paid:
        return this.renderDdpDeliveryOptions();
      case DeliveredDuty.Unpaid:
        return this.renderDduDeliveryOptions();
      default:
        return null;
    }
  }

  renderAllDeliveryOptions() {
    const { deliveredDutySetting } = this.props;

    const {
      default: defaultDeliveredDuty,
      available: availableDeliveredDuties,
    } = deliveredDutySetting;

    // Bubbles up the default delivered duty to the top of the list of
    // available delivered duties to control rendering order.
    const deliveredDuties = [
      defaultDeliveredDuty,
      ...without(availableDeliveredDuties, defaultDeliveredDuty),
    ];

    return (
      <div>
        {map(deliveredDuties, (deliveredDuty) => (
          this.renderDeliveryOptionsInDeliveredDuty(deliveredDuty)
        ))}
      </div>
    );
  }

  renderSingleDeliveryOptions() {
    const { deliveredDuty } = this.props;
    return this.renderDeliveryOptionsInDeliveredDuty(deliveredDuty);
  }

  render() {
    const { deliveredDutyDisplayType } = this.props;
    switch (deliveredDutyDisplayType) {
      case DeliveredDutyDisplayType.All:
        return this.renderAllDeliveryOptions();
      case DeliveredDutyDisplayType.Single:
        return this.renderSingleDeliveryOptions();
      default:
        return null;
    }
  }
}

DeliveryOptions.displayName = 'DeliveryOptions';

DeliveryOptions.propTypes = {
  /**
   * @property {DeliveredDuty} This property holds which delivered duty should
   * be default selected.
   * @see https://docs.flow.io/type/delivered-duty
   */
  deliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
  /**
   * @property {DeliveredDutyDisplayType} This property holds the preferred
   * rendering display type for this component.
   *
   * TODO: Once we fix the discrepancies with delivered duties we should
   * be able to rely solely on the delivered duty setting property and
   * this property can be removed.
   *
   * @see https://docs.flow.io/type/delivered-duty
   */
  deliveredDutyDisplayType: ApiPropTypes.deliveredDutyDisplayType.isRequired,
  /**
   * @property {DeliveryDutySetting} This property holds the landed cost
   * display preferences for the current experience.
   * @see https://docs.flow.io/type/delivered-duty-setting
   */
  deliveredDutySetting: ApiPropTypes.deliveredDutySetting.isRequired,
  /**
   * @property {Delivery} This property holds the delivery model represented
   * by this component.
   * @see https://docs.flow.io/type/delivery
   */
  delivery: ApiPropTypes.delivery.isRequired,
  /**
   * @property {String} This property holds the name for radio button fields
   * representing each available delivery options a customer can choose.
   */
  name: PropTypes.string.isRequired,
  /**
   * @property {Function} A callback property that is invoked with the delivery
   * identifier and selected delivery option idenfifier when a delivery option
   * is selected.
   */
  onChangeValue: PropTypes.func.isRequired,
  /**
   * @property {String} This property holds the selected delivery
   * option identifier.
   */
  value: PropTypes.string.isRequired,
};

export default DeliveryOptions;
