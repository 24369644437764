const CardErrorTypes = {
  ADDRESS_VERIFICATION: 'avs',
  CARD_VERIFICATION: 'cvv',
  FRAUD: 'fraud',
  INVALID_ADDRESS: 'invalid_address',
  INVALID_EXPIRATION: 'invalid_expiration',
  INVALID_NAME: 'invalid_name',
  INVALID_NUMBER: 'invalid_number',
  INVALID_TOKEN_TYPE: 'invalid_token_type',
  UNKNOWN: 'unknown',
};

export default CardErrorTypes;
