import React from 'react';

import Dict from '../dict';
import Href from '../href';
import ContentLabel from '../content-label';
import Load from '../load';

interface Props {
  className?: string;
  content: io.flow.internal.v0.unions.ContentItem;
}

const ContentItem: React.FunctionComponent<Props> = ({
  className,
  content,
}) => {
  switch (content.discriminator) {
    case 'dict':
      return <Dict className={className} content={content} />;
    case 'href':
      return <Href className={className} content={content} />;
    case 'content_label':
      return <ContentLabel className={className} content={content} />;
    case 'load':
      return <Load className={className} content={content} />;
    default:
      return null;
  }
};

ContentItem.displayName = 'ContentItem';

export default ContentItem;
