import type { ExperiencesGetPaymentMethodTypesByExperienceKeyResponse } from '@flowio/api-sdk';
import { getSessionId } from '../../../utilities/session';
import { PaginationResult, usePagingAndSorting } from '../../../utilities/use-paging-and-sorting';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { FetchPaymentMethodTypesActionParameters } from '../types';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<ExperiencesGetPaymentMethodTypesByExperienceKeyResponse>
>;

interface Options {
  entriesPerPage?: string | number;
  pageNumber?: string | number;
}

const fetchPaymentMethodTypesRequest = (
  params: FetchPaymentMethodTypesActionParameters,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_TYPES_REQUEST,
  params,
});

const fetchPaymentMethodTypesSuccess = (
  params: FetchPaymentMethodTypesActionParameters,
  paginationResult: PaginationResult<io.flow.v0.enums.PaymentMethodType>,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_TYPES_SUCCESS,
  params,
  payload: paginationResult,
});

const fetchPaymentMethodTypesFailure = (
  params: FetchPaymentMethodTypesActionParameters,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_PAYMENT_METHOD_TYPES_FAILURE,
  params,
  payload: error,
});

const fetchPaymentMethodTypes = (
  organizationId: string,
  experienceKey: string,
  options: Options = {},
): ThunkResult<Result> => (dispatch, getState, extra) => {
  const {
    entriesPerPage = 100,
    pageNumber = 1,
  } = options;

  const {
    limit,
    offset,
    paginationResult,
  } = usePagingAndSorting({
    entriesPerPage,
    pageNumber,
  });

  const params: FetchPaymentMethodTypesActionParameters = {
    entriesPerPage,
    experienceKey,
    organization: organizationId,
    pageNumber,
    sessionId: getSessionId(),
  };

  dispatch(fetchPaymentMethodTypesRequest(params));

  return extra.api.experiences.getPaymentMethodTypesByExperienceKey({
    experience_key: experienceKey,
    organization: organizationId,
    limit,
    offset,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchPaymentMethodTypesSuccess(params, paginationResult(response.result)));
          break;
        case 401:
        case 404:
          dispatch(fetchPaymentMethodTypesFailure(params, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchPaymentMethodTypes;
