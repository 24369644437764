import { safeInvoke } from '@flowio/react-helpers';
import React from 'react';
import { useRollbar, useForceUpdate } from '../../hooks';

type Props = {
  category: io.flow.internal.v0.models.KlarnaPaymentMethodCategory;
  children?: never;
  onError?: (error: any) => void;
  onLoaded?: (response: Klarna.LoadResponse) => void;
};

/**
 * Note: Don't try to mount this component without initializing
 * the Klarna SDK with the client token first.
 */
const KlarnaPaymentCategory: React.FC<Props> = ({
  category,
  onError,
  onLoaded,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const rollbar = useRollbar();
  const forceUpdate = useForceUpdate();

  const handleRef = React.useCallback((node: HTMLDivElement) => {
    ref.current = node;
    forceUpdate();
  }, []);

  React.useLayoutEffect(() => {
    if (ref.current == null) {
      return;
    }

    try {
      Klarna.Payments.load({
        container: ref.current,
        payment_method_category: category.id,
      }, (response) => {
        if (!response.show_form) {
          rollbar.error('Klarna: Payment method category cannot be used for this order', {
            payment_method_category: category,
          });
        } else if (response.error) {
          rollbar.warn('Klarna: Pre-assessment rejected due to an invalid update', {
            pre_assessment_error: response.error,
            payment_method_category: category,
          });
        }

        safeInvoke(onLoaded, response);
      });
    } catch (error) {
      safeInvoke(onError, error);
    }
  }, []);

  return (
    <div
      id={`#klarna-${category.id}-container`}
      ref={handleRef}
    />
  );
};

export default KlarnaPaymentCategory;
