import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';
import { Link } from 'react-router';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import overEvery from 'lodash/overEvery';

import Prompt from '../prompt';
import { Grid, Row, Column } from '../grid';
import { isPaymentMethodId, isPaymentMethodType } from '../../utilities/payment-method-rule-utilities';
import BillingAddress from '../billing-address';
import Button from '../button';
import ContentItem from '../content-item';
import CryptoPayButton from '../cryptopay-button';
import GooglePayButton from '../google-pay-button';
import GooglePayPropTypes from '../google-pay-button/google-pay-prop-types';
import OrderSubmissionError from '../order-submission-error';
import PayPalButton from '../paypal-button';
import PaymentMethod from '../payment-method';
import PaymentTypes from '../../constants/payment-types';
import ChevronLeft from '../svg-icons/chevron-left';

import Checkbox from '../redux-form/checkbox';
import optinPromptPropTypes from '../../utilities/prop-types/optin-prompt-prop-types';
import getCorrectAdministrativeArea from '../../utilities/get-correct-administrative-area';
import InvoiceAddress from '../invoice-address/invoice-address';
import { AddressType } from '../../constants/address-type';
import GiftCardV2Form from '../../containers/gift-card-v2-form-js';
import AddressBookContactDialog from '../address-book-contact-dialog';
import { isBillingAddressConfirmationRequired, isCardNumberConfirmationRequired, isCvvConfirmationRequired } from '../../utilities/payment-source-utilities';
import FeatureToggle from '../feature-toggle';
import Features from '../../constants/features';
import GiftMessaging from '../../containers/gift-messaging';
import MobileDiscountFormV2 from '../mobile-discount-form-v2';
import { isAdministrativeAreaVisible, matchProvince } from '../../utilities/address-utilities';
import KlarnaButtonV2 from '../klarna-button-v2';
import ApplePayButton from '../applepay-button';

if (process.browser) {
  require('./payment-form.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-form');

/**
 * @typedef {io.flow.v0.models.AddressConfiguration} AddressConfiguration
 */

/**
 * @typedef {object} PaymentFormProps
 * @property {AddressConfiguration} billingAddressConfiguration
 * @property {AddressConfiguration} invoiceAddressConfiguration
 */

/**
 * @augments React.Component<PaymentFormProps>
 */
class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.handleAutoCompleteAddress = this.handleAutoCompleteAddress.bind(this);
    this.handleBillingPostalAutoComplete = this.handleBillingPostalAutoComplete.bind(this);
    this.handleInvoicePostalAutoComplete = this.handleInvoicePostalAutoComplete.bind(this);
    this.handleKlarnaButtonV2Click = this.handleKlarnaButtonV2Click.bind(this);
  }

  handleKlarnaButtonV2Click() {
    const { onOrderCheck } = this.props;
    const fieldErrors = onOrderCheck();
    return isEmpty(fieldErrors);
  }

  handleAutoCompleteAddress(addressData, type) {
    const {
      billingAddressConfiguration,
      invoiceAddressConfiguration,
      locale,
      autofill,
    } = this.props;

    let addressConfiguration;

    if (type === AddressType.INVOICE) {
      addressConfiguration = invoiceAddressConfiguration;
    } else {
      addressConfiguration = billingAddressConfiguration;
    }

    autofill(`${type}Address.addressLine1`, addressData.street);
    autofill(`${type}Address.locality`, addressData.city);
    autofill(`${type}Address.country`, addressData.country);
    autofill(`${type}Address.administrativeArea`, getCorrectAdministrativeArea(addressConfiguration, locale, addressData.administrativeAreas));
    autofill(`${type}Address.postalCode`, addressData.postalCode);
  }

  handlePostalAutoComplete(address, addressType) {
    const {
      autofill,
      billingAddressConfiguration,
      invoiceAddressConfiguration,
    } = this.props;

    const addressConfiguration = addressType === 'billingAddress'
      ? billingAddressConfiguration
      : invoiceAddressConfiguration;

    if (address.city != null) {
      autofill(`${addressType}.locality`, address.city);
    }

    if (address.province != null && isAdministrativeAreaVisible(addressConfiguration)) {
      if (addressConfiguration.provinces.length) {
        const province = matchProvince(addressConfiguration, address.province);
        if (province != null) {
          autofill(`${addressType}.administrativeArea`, province.value);
        }
      } else {
        autofill(`${addressType}.administrativeArea`, address.province);
      }
    }

    if (address.postal != null) {
      autofill(`${addressType}.postalCode`, address.postal);
    }
  }

  handleBillingPostalAutoComplete(address) {
    this.handlePostalAutoComplete(address, 'billingAddress');
  }

  handleInvoicePostalAutoComplete(address) {
    this.handlePostalAutoComplete(address, 'invoiceAddress');
  }

  render() {
    const {
      addressBook,
      allowInvoiceAddress,
      applepayAvailability,
      authorizationCallbackError,
      billingAddressConfiguration,
      billingCountryCode,
      cardPaymentDisplay,
      companyName,
      countries,
      customerPaymentSources,
      error,
      forceEnableShopifyDiscounts,
      googlePaymentDataRequest,
      handleSubmit,
      intl,
      invoiceAddress,
      invoiceAddressConfiguration,
      invoiceCountryCode,
      is3DSPendingPageEnabled,
      isDiscountsEnabled,
      isGlobalGiftCardsEnabled,
      isShopifyIntegration,
      isVrnValidating,
      locale,
      marketingOptIns,
      onApplePayClick,
      onCardChange,
      onCryptoPayClick,
      onCryptoPaySuccess,
      onDeleteCardPaymentSource,
      onGooglePayError,
      onGooglePaySuccess,
      onKlarnaFailure,
      onKlarnaSuccess,
      onOrderCheck,
      onPayPalError,
      onPayPalSuccess,
      optinPrompts,
      orderBalance,
      orderNumber,
      organizationSummary,
      paymentMethodId,
      paymentMethodRules,
      paymentMethodTypes,
      paypalDisclaimer,
      paypalExpressReady,
      paypalPaymentInfo,
      privacyPolicy,
      returningCustomer,
      reviewEnabled,
      shippingMethodUrl,
      shouldCollectBillingAddress,
      storedReviewCard,
      submitSucceeded,
      submitting,
      terms,
      vrn,
    } = this.props;

    const selectedOnlinePaymentMethodRule = find(paymentMethodRules, overEvery([
      isPaymentMethodType('online'),
      isPaymentMethodId(paymentMethodId),
    ]));
    const paymentFormOptins = filter(optinPrompts, (optin) => get(optin, 'display.display_position', 'submission') === 'submission');

    const isInvoiceAddressRequired = invoiceAddress != null;

    // Keep actions disabled while submitting and after submission is successful.
    // Disabling actions after successful submission helps prevent multiple
    // submissions while trying to complete slow redirect transitions to
    // third-party confirmation pages.
    // https://app.clubhouse.io/flow/story/11614
    const isSubmitDisabled = submitting || submitSucceeded || isVrnValidating;

    return (
      <form
        className={bem.block()}
        data-automation-id="payment-form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Field name="customerNumber" type="hidden" component="input" />
        {/* Due to the fact that the payment form is really sluggish we hide the gift message field
          See gift-messaging.jsx for more info */}
        <Field name="giftMessage" type="hidden" component="input" />
        <Field name="orderNumber" type="hidden" component="input" />
        <Field name="organizationId" type="hidden" component="input" />
        <Field name="merchantOfRecord" type="hidden" component="input" />
        <Field name="currencyCode" type="hidden" component="input" />
        <PaymentMethod
          customer={returningCustomer}
          paymentMethodId={paymentMethodId}
          applepayAvailability={applepayAvailability}
          cardPaymentDisplay={cardPaymentDisplay}
          customerPaymentSources={customerPaymentSources}
          intl={intl}
          paymentMethodRules={paymentMethodRules}
          paymentMethodTypes={paymentMethodTypes}
          storedReviewCard={storedReviewCard}
          onCardChange={onCardChange}
          onDeleteCardPaymentSource={onDeleteCardPaymentSource}
          organizationId={organizationSummary.id}
          paypalDisclaimer={paypalDisclaimer}
          paypalExpressReady={paypalExpressReady}
        />
        {isGlobalGiftCardsEnabled && (
          <FormSection name="giftCard">
            <GiftCardV2Form />
          </FormSection>
        )}
        {(isShopifyIntegration || isDiscountsEnabled || forceEnableShopifyDiscounts) && (
          <FeatureToggle featureKey={Features.PAYMENT_PROMO_CODE}>
            <MobileDiscountFormV2 />
          </FeatureToggle>
        )}
        <FeatureToggle
          featureKey={Features.RETURNING_CUSTOMERS}
          render={({ isFeatureEnabled }) => {
            let selectedPaymentSource;
            let isBillingAddressRequired;

            if (isFeatureEnabled && customerPaymentSources != null) {
              selectedPaymentSource = customerPaymentSources.find((
                (paymentSource) => paymentSource.id === paymentMethodId
              ));
            }

            if (isFeatureEnabled && selectedPaymentSource != null) {
              // basically, if the customer must take action against the
              // selected payment source a new billing address must be
              // provided because a payment source cannot be patched (e.g.
              // you cannot just apply a new expiration date to fix the
              // payment source), instead a new card must be created.
              isBillingAddressRequired = (
                isBillingAddressConfirmationRequired(selectedPaymentSource)
                || isCardNumberConfirmationRequired(selectedPaymentSource)
                || isCvvConfirmationRequired(selectedPaymentSource)
              );
            } else if (shouldCollectBillingAddress) {
              isBillingAddressRequired = true;
            } else {
              isBillingAddressRequired = [
                PaymentTypes.Card,
                PaymentTypes.CreditCard,
                PaymentTypes.DebitCard,
                PaymentTypes.Klarna,
                PaymentTypes.ACH,
              ].indexOf(paymentMethodId) >= 0;
            }

            if (!isBillingAddressRequired) {
              return null;
            }

            return (
              <FormSection name="billingAddress">
                <BillingAddress
                  addressBook={addressBook}
                  addressConfiguration={billingAddressConfiguration}
                  countries={countries}
                  countryCode={billingCountryCode}
                  returningCustomer={returningCustomer}
                  locale={locale}
                  onAutoCompleteAddress={this.handleAutoCompleteAddress}
                  onPostalAutoComplete={this.handleBillingPostalAutoComplete}
                />
              </FormSection>
            );
          }}
        />
        {isInvoiceAddressRequired && allowInvoiceAddress && (
          <FormSection name="invoiceAddress">
            <InvoiceAddress
              addressBook={addressBook}
              addressConfiguration={invoiceAddressConfiguration}
              companyName={companyName}
              countries={countries}
              countryCode={invoiceCountryCode}
              locale={locale}
              onAutoCompleteAddress={this.handleAutoCompleteAddress}
              onPostalAutoComplete={this.handleInvoicePostalAutoComplete}
              returningCustomer={returningCustomer}
              vrn={vrn}
            />
          </FormSection>
        )}
        <FeatureToggle
          featureKey={Features.GIFT_MESSAGING}
          activeComponent={GiftMessaging}
        />
        <FeatureToggle
          featureKey={Features.MOBILE_UX_SPACING}
          render={({ isFeatureEnabled }) => (
            <Grid parent={isFeatureEnabled}>
              <Row>
                {error && (
                  <Column xs={12}>
                    <OrderSubmissionError className={bem.element('error')} error={error} />
                  </Column>
                )}
                {authorizationCallbackError && (
                  <Column xs={12}>
                    <OrderSubmissionError
                      className={bem.element('error')}
                      error={authorizationCallbackError}
                      orderNumber={orderNumber}
                    />
                  </Column>
                )}
              </Row>
              {!isEmpty(marketingOptIns) && (
                <Row>
                  <Column xs={12}>
                    <div className={bem.element('optin')}>
                      {marketingOptIns.map((optIn) => (
                        <Field
                          component={Checkbox}
                          defaultChecked={optIn.checked_by_default}
                          labelText={optIn.label}
                          name={optIn.name}
                          value={optIn.value}
                        />
                      ))}
                    </div>
                  </Column>
                </Row>
              )}
              {!isEmpty(paymentFormOptins) && (
                <Row>
                  <Column xs={12}>
                    <FormSection className={bem.element('prompt-group')} name="optinPrompts">
                      {map(paymentFormOptins, (optinPrompt) => (
                        <Prompt key={optinPrompt.id} prompt={optinPrompt} />
                      ))}
                    </FormSection>
                  </Column>
                </Row>
              )}
              {is3DSPendingPageEnabled && paymentMethodId === PaymentTypes.Card && (
                <Row className={bem.element('3ds-message-container')}>
                  <FormattedMessage
                    id="checkout_3ds_enabled_message"
                    description="Message that shows when 3ds is enabled"
                    defaultMessage="The payment method you selected may require additional verification. You may be redirected to a 3rd party site to complete the verification."
                  />
                </Row>
              )}
              <Row>
                <Column xs={12} md={6} mdPush={6}>
                  <div className={bem.element('continue-button-container')}>
                    {reviewEnabled && (
                      <Button
                        automationId="submit-payment-form"
                        type="submit"
                        color="positive"
                        className={bem.element('continue-button')}
                        fluid
                      >
                        <FormattedMessage
                          id="checkout_proceed_to_review"
                          description="A message for action that will direct the customer to review their order"
                          defaultMessage="Proceed to Review"
                        />
                      </Button>
                    )}
                    {!reviewEnabled && (() => {
                      switch (paymentMethodId) {
                        case PaymentTypes.Klarna:
                          return (
                            <FeatureToggle
                              featureKey={Features.KLARNA_REDIRECT}
                              render={({ isFeatureEnabled: klarnaRedirectEnabled }) => {
                                if (klarnaRedirectEnabled) {
                                  return (
                                    <Button
                                      automationId="submit-payment-form"
                                      type="submit"
                                      color="positive"
                                      className={bem.element('continue-button')}
                                      fluid
                                      disabled={isSubmitDisabled}
                                    >
                                      <FormattedMessage
                                        id="checkout_complete_order_with_online_payment_method"
                                        description="A message for action that will attempt to create an authorization for the selected online payment method before submitting the order"
                                        defaultMessage="Complete with {paymentMethod}"
                                        values={{ paymentMethod: get(selectedOnlinePaymentMethodRule, 'payment_method.name') }}
                                      />
                                    </Button>
                                  );
                                }

                                return (
                                  <KlarnaButtonV2
                                    onClick={this.handleKlarnaButtonV2Click}
                                    onAuthorized={onKlarnaSuccess}
                                    onError={onKlarnaFailure}
                                  />
                                );
                              }}
                            />
                          );
                        case PaymentTypes.PayPal:
                          return (paypalExpressReady ? (
                            <Button
                              automationId="submit-payment-form"
                              type="button"
                              color="positive"
                              className={bem.element('continue-button')}
                              onClick={() => onPayPalSuccess(paypalPaymentInfo)}
                              fluid
                            >
                              <FormattedMessage
                                id="checkout_complete_order_with_online_payment_method"
                                description="A message for action that will attempt to create an authorization for the selected online payment method before submitting the order"
                                defaultMessage="Complete with {paymentMethod}"
                                values={{ paymentMethod: get(selectedOnlinePaymentMethodRule, 'payment_method.name') }}
                              />
                            </Button>
                          ) : (
                            <PayPalButton
                              onOrderCheck={onOrderCheck}
                              onError={onPayPalError}
                              onSuccess={onPayPalSuccess}
                            />
                          ));
                        case PaymentTypes.GooglePay:
                          return (
                            <GooglePayButton
                              isReadyToPayRequest={googlePaymentDataRequest}
                              onError={onGooglePayError}
                              onSuccess={onGooglePaySuccess}
                              organizationSummary={organizationSummary}
                              paymentDataRequest={googlePaymentDataRequest}
                            />
                          );
                        case PaymentTypes.CryptoPay:
                          return (
                            <CryptoPayButton
                              onApprove={onCryptoPaySuccess}
                              onClick={onCryptoPayClick}
                            />
                          );
                        case PaymentTypes.ApplePay:
                          // There is a scenario where the Apple Pay authorization can succeed and another submit error may
                          // stop the user submitting after returning to checkout if this is the case we want to render the
                          // 'Complete Order with Apple Pay' button rather than request payment again, to allow the customer
                          // submit the order once they fix the form problems.
                          if (orderBalance != null && orderBalance > 0) {
                            return (
                              <ApplePayButton
                                locale={locale}
                                availability={applepayAvailability}
                                onApplePayClick={onApplePayClick}
                              />
                            );
                            /* conditionally falls through */
                          }
                        // eslint-disable-next-line no-fallthrough
                        default:
                          return (
                            <Button
                              automationId="submit-payment-form"
                              type="submit"
                              color="positive"
                              className={bem.element('continue-button')}
                              fluid
                              disabled={isSubmitDisabled}
                            >
                              {selectedOnlinePaymentMethodRule ? (
                                <FormattedMessage
                                  id="checkout_complete_order_with_online_payment_method"
                                  description="A message for action that will attempt to create an authorization for the selected online payment method before submitting the order"
                                  defaultMessage="Complete with {paymentMethod}"
                                  values={{ paymentMethod: get(selectedOnlinePaymentMethodRule, 'payment_method.name') }}
                                />
                              ) : (
                                <FormattedMessage
                                  id="checkout_complete_order_with_card"
                                  description="A message for action that will attempt to create a card authorization before submitting the order"
                                  defaultMessage="Complete Order"
                                />
                              )}
                            </Button>
                          );
                      }
                    })()}
                  </div>
                </Column>
                <Column xs={12} md={6} mdPull={6}>
                  <div className="hidden-sm-down">
                    <Button
                      as={Link}
                      automationId="return-to-shipping-method"
                      className={bem.element('return-button')}
                      disabled={isSubmitDisabled}
                      flat
                      fluid
                      icon={<ChevronLeft />}
                      to={shippingMethodUrl}
                      text={(
                        <FormattedMessage
                          id="checkout_return_to_shipping_method"
                          description="A message indicating that interactions will transition customers to the shipping method step"
                          defaultMessage="Return to Shipping Method"
                        />
                      )}
                    />
                  </div>
                  <FeatureToggle
                    featureKey={Features.OPTIMIZE_MOBILE_CTAS}
                    render={({ isFeatureEnabled: optimizeMobileCtas }) => {
                      if (optimizeMobileCtas) {
                        return (
                          <div className="hidden-md-up">
                            <Button
                              as={Link}
                              automationId="return-to-shipping-method"
                              className={bem.element('return-button')}
                              disabled={isSubmitDisabled}
                              flat
                              fluid
                              to={shippingMethodUrl}
                              text={(
                                <FormattedMessage
                                  id="checkout_return_to_shipping_method"
                                  description="A message indicating that interactions will transition customers to the shipping method step"
                                  defaultMessage="Return to Shipping Method"
                                />
                              )}
                            />
                          </div>
                        );
                      }
                      return (
                        <div className="hidden-md-up">
                          <Button
                            as={Link}
                            automationId="return-to-shipping-method"
                            className={bem.element('return-button')}
                            color="tertiary"
                            disabled={isSubmitDisabled}
                            fluid
                            to={shippingMethodUrl}
                          >
                            <FormattedMessage
                              id="checkout_return_to_shipping_method"
                              description="A message indicating that interactions will transition customers to the shipping method step"
                              defaultMessage="Return to Shipping Method"
                            />
                          </Button>
                        </div>
                      );
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  {!!privacyPolicy && !!terms && (
                    <div className={bem.element('disclaimer')}>
                      <FormattedMessage
                        id="checkout_payment_info_terms_acknowledgment"
                        description="A message indicating to customers that they agree with the organization privacy policy and conditions"
                        defaultMessage="By placing this order, I agree to the {privacyPolicy} and {termsOfUse}"
                        values={{
                          privacyPolicy: <ContentItem content={privacyPolicy} />,
                          termsOfUse: <ContentItem content={terms} />,
                        }}
                      />
                    </div>
                  )}
                </Column>
              </Row>
            </Grid>
          )}
        />
        <AddressBookContactDialog countries={countries} />
      </form>
    );
  }
}

PaymentForm.displayName = 'PaymentForm';

PaymentForm.propTypes = {
  addressBook: ApiPropTypes.customerAddressBook,
  returningCustomer: ApiPropTypes.customer,
  allowInvoiceAddress: PropTypes.bool,
  autofill: PropTypes.func.isRequired,
  applepayAvailability: PropTypes.shape({
    available: PropTypes.bool.isRequired,
    reason: PropTypes.string,
  }),
  billingAddressConfiguration: ApiPropTypes.addressConfiguration.isRequired,
  invoiceAddressConfiguration: ApiPropTypes.addressConfiguration.isRequired,
  authorizationCallbackError: PropTypes.oneOfType([
    PropTypes.shape({
      authorization: ApiPropTypes.authorization.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stack: PropTypes.string,
    }),
    ApiPropTypes.genericError,
    ApiPropTypes.orderError,
  ]),
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  // TODO: Replace with real models when they are available
  customerPaymentSources: PropTypes.arrayOf(ApiPropTypes.paymentSource),
  billingCountryCode: PropTypes.string.isRequired,
  invoiceCountryCode: PropTypes.string,
  onCardChange: PropTypes.func,
  companyName: PropTypes.string,
  error: PropTypes.oneOfType([
    // Expected when payment authorization is declined.
    PropTypes.shape({
      authorization: ApiPropTypes.authorization.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stack: PropTypes.string,
    }),
    // Expected when HTTP status error is thrown for card tokenization endpoint
    ApiPropTypes.cardError,
    // Expected when HTTP status error is thrown for any endpoint called to complete the order
    ApiPropTypes.genericError,
  ]),
  isVrnValidating: PropTypes.bool,
  isGlobalGiftCardsEnabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  googlePaymentDataRequest: GooglePayPropTypes.paymentDataRequest.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  invoiceAddress: ApiPropTypes.billingAddress,
  is3DSPendingPageEnabled: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  // TODO: Once @flowio/apibuilder-prop-types is published, use the shape from that library
  marketingOptIns: PropTypes.arrayOf(PropTypes.shape({
    checked_by_default: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
  })),
  onApplePayClick: PropTypes.func.isRequired,
  onCryptoPayClick: PropTypes.func.isRequired,
  onCryptoPaySuccess: PropTypes.func.isRequired,
  onDeleteCardPaymentSource: PropTypes.func.isRequired,
  onGooglePayError: PropTypes.func.isRequired,
  onGooglePaySuccess: PropTypes.func.isRequired,
  onKlarnaFailure: PropTypes.func.isRequired,
  onKlarnaSuccess: PropTypes.func.isRequired,
  onPayPalError: PropTypes.func.isRequired,
  onPayPalSuccess: PropTypes.func.isRequired,
  paypalPaymentInfo: PropTypes.shape({
    paymentID: PropTypes.string,
    payerID: PropTypes.string,
  }),
  optinPrompts: PropTypes.arrayOf(PropTypes.shape(optinPromptPropTypes)),
  organizationSummary: ApiPropTypes.organizationSummary.isRequired,
  orderBalance: PropTypes.number.isRequired,
  orderNumber: PropTypes.string.isRequired,
  /**
   * Unique identifier for payment method selected by the customer.
   */
  paymentMethodId: PropTypes.string.isRequired,
  paymentMethodRules: PropTypes.arrayOf(ApiPropTypes.paymentMethodRule).isRequired,
  paymentMethodTypes: PropTypes.arrayOf(ApiPropTypes.paymentMethodType).isRequired,
  paypalDisclaimer: ApiInternalPropTypes.contentItem.isRequired,
  paypalExpressReady: PropTypes.bool.isRequired,
  privacyPolicy: ApiInternalPropTypes.contentItem.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
  /**
   * Whether a billing address should be collected regardless of whether it is
   * supported by the selected payment method.
   */
  shouldCollectBillingAddress: PropTypes.bool,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  terms: ApiInternalPropTypes.contentItem.isRequired,
  vrn: PropTypes.string,
  storedReviewCard: ApiPropTypes.card,
  reviewEnabled: PropTypes.bool.isRequired,
  onOrderCheck: PropTypes.func,
  forceEnableShopifyDiscounts: PropTypes.bool.isRequired,
  isDiscountsEnabled: PropTypes.bool.isRequired,
  isShopifyIntegration: PropTypes.bool.isRequired,
  cardPaymentDisplay: PropTypes.oneOf(['debit_card_first', 'combined']).isRequired,
};

PaymentForm.defaultProps = {
  addressBook: undefined,
  applepayAvailability: {
    available: false,
    reason: 'NOT_SUPPORTED',
  },
  returningCustomer: undefined,
  allowInvoiceAddress: false,
  authorizationCallbackError: undefined,
  companyName: undefined,
  customerPaymentSources: undefined,
  error: undefined,
  invoiceAddress: undefined,
  marketingOptIns: [],
  optinPrompts: [],
  shouldCollectBillingAddress: false,
  invoiceCountryCode: undefined,
  vrn: undefined,
  isVrnValidating: false,
  storedReviewCard: undefined,
  onCardChange: undefined,
  onOrderCheck: undefined,
  paypalPaymentInfo: undefined,
};

export default PaymentForm;
