/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';

if (process.browser) {
  require('./spinner.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('spinner');

class Spinner extends PureComponent {
  render() {
    const { className, size } = this.props;
    const unhandledProps = getUnhandledProps(Spinner, this.props);

    return (
      <div {...unhandledProps} className={bem.block(className, { [size]: true })}>
        <div className={bem.element('blades')}>
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
          <div className={bem.element('blade')} />
        </div>
      </div>
    );
  }
}

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Spinner.defaultProps = {
  className: '',
  size: 'medium',
};

export default Spinner;
