/**
 * @see https://docs.flow.io/type/delivered-duty-display-type
 */
const DeliveredDutyDisplayType = {
  // Displays all available options to the customer.
  All: 'all',
  // Displays a single option (either DDU or DDP), while the other can be
  // displayed by changing duties and tax preferences at checkout.
  Single: 'single',
};

export default DeliveredDutyDisplayType;
