/**
 * @fileoverview
 * When the redirect state is updated the application redirects customers to
 * the specified location.
 */

import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { RedirectState } from '../types';

const initialState: RedirectState = {};

const redirectReducer: AppReducer<RedirectState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.REDIRECT:
      return update(previousState, {
        url: { $set: action.payload },
      });
    default:
      return previousState;
  }
};

export default redirectReducer;
