import includes from 'lodash/includes';

/**
 * A list of country codes that belong in the european union.
 * @see https://api.flow.io/reference/regions?q=europeanunion
 */
const countries = [
  'AUT',
  'BEL',
  'BGR',
  'HRV',
  'CYP',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
];

/**
 * Returns whether the specified country code belongs in the european union.
 * @param {String} countryCode
 */
export default function isEuropeanUnion(countryCode) {
  return includes(countries, countryCode);
}
