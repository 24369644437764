import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CountryState } from '../types';

const initialState: CountryState = [];

const countriesReducer: AppReducer<CountryState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRIES_SUCCESS:
      return action.payload;
    default:
      return previousState;
  }
};

export default countriesReducer;
