import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

const updateVrnVisibility = (isVisible: boolean): RootAction => ({
  type: actionTypes.UPDATE_VRN_VISIBILITY,
  payload: {
    isVisible,
  },
});

export default updateVrnVisibility;
