/* eslint-disable no-console */
import { parseQueryString } from '@flowio/web-sdk';
import events from '../events';

/**
 * Log 'debug' events to the JavaScript Console.
 *
 * Enabled when ?flow_debug=true exists in the URL.
 *
 * Intended for us in production.
 */
export default function enableDebugMode() {
  const params = parseQueryString(window.location.search);
  const { flow_debug: flowDebug } = params;
  if (flowDebug) {
    events.on('debug', (event) => {
      const { message, ...extra } = event;
      console.debug(message, extra);
    });
  }
}
