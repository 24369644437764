const PaymentReadableName = {
  ach: 'ACH',
  afterpay: 'Afterpay',
  alipay: 'Alipay',
  apple_pay: 'Apple Pay',
  bankTransfer_IBAN: 'IBAN Bank Transfer',
  bitcoin: 'BitPay',
  bitpay: 'BitPay',
  directEbanking: 'DIRECT E-Banking',
  dotpay: 'Dotpay',
  dragonpay_ebanking: 'Dragonpay E-Banking',
  dragonpay_gcash: 'Dragonpay GCash',
  dragonpay_otc_banking: 'Dragonpay OTC Banking',
  ebanking_FI: 'E-Banking FI',
  giropay: 'Giropay',
  google_pay: 'Google Pay',
  ideal: 'iDEAL',
  interac: 'Interac',
  jcb: 'JCB',
  kcp_banktransfer: 'KCP Bank Transfer',
  kcp_creditcard: 'KCP Credit Card',
  kcp_payco: 'KCP Payco',
  klarna: 'Klarna',
  molpay_points: 'MOLPay Points',
  multibanco: 'Multibanco',
  paypal: 'PayPal',
  qiwiwallet: 'QIWI Wallet',
  sepadirectdebit: 'SEPA Direct Debit',
  trustly: 'Trustly',
  trustpay: 'TrustPay',
  unionpay: 'UnionPay',
  wechatpay: 'WeChat Pay',
  american_express: 'American Express',
  china_union_pay: 'Union Pay',
  dankort: 'Dankort',
  diners_club: 'Diner\'s Club',
  discover: 'Discover',
  maestro: 'Maestro',
  mastercard: 'Mastercard',
  cartes_bancaires: 'Cartes Bancaires',
  visa: 'Visa',
};

export default PaymentReadableName;
