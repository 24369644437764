import { addEvent, removeEvent } from '@flowio/browser-helpers';
import React from 'react';

const defaultProps = {
  capture: false,
  passive: true,
};

type DefaultProps = typeof defaultProps;

type WindowProps = {
  [K in keyof WindowEventMap]: {
    listener: (event: WindowEventMap[K]) => void | EventListenerObject;
    target: 'window';
    type: K;
  } & DefaultProps;
}[keyof WindowEventMap];

type DocumentProps = {
  [K in keyof DocumentEventMap]: {
    listener: (event: DocumentEventMap[K]) => void | EventListenerObject;
    target: 'document';
    type: K;
  } & DefaultProps;
}[keyof DocumentEventMap];

type EventTargetProps = {
  listener: EventListenerOrEventListenerObject;
  target: EventTarget;
  type: string;
} & DefaultProps;

type Props = WindowProps | DocumentProps | EventTargetProps;

class EventListener extends React.Component<Props> {
  static readonly defaultProps = defaultProps;

  componentDidMount(): void {
    this.attachListener();
  }

  UNSAFE_componentWillUpdate(): void {
    this.detachListener();
  }

  componentDidUpdate(): void {
    this.attachListener();
  }

  componentWillUnmount(): void {
    this.detachListener();
  }

  getEventTarget(): EventTarget {
    const { target } = this.props;
    return typeof target === 'string' ? window[target] : target;
  }

  attachListener(): void {
    const {
      capture,
      listener,
      passive,
      type,
    } = this.props;

    const target = this.getEventTarget();

    if (target != null) {
      addEvent(
        target,
        type,
        listener as EventListenerOrEventListenerObject,
        { capture, passive },
      );
    }
  }

  detachListener(): void {
    const {
      capture,
      listener,
      passive,
      type,
    } = this.props;

    const target = this.getEventTarget();

    if (target != null) {
      removeEvent(
        target,
        type,
        listener as EventListenerOrEventListenerObject,
        { capture, passive },
      );
    }
  }

  render(): React.ReactNode {
    const {
      children,
    } = this.props;

    return children != null ? children : null;
  }
}

export default EventListener;
