import { Response } from '../services/types';

export default class HttpStatusError<T extends Response> extends Error {
  public response: T;

  public name: string;

  public constructor(response: T) {
    super('Response is outside 2xx rage.');
    this.response = response;
    this.name = 'HttpStatusError';
  }
}
