/* eslint-disable react/no-find-dom-node */

import { defineMessages, injectIntl } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

import { injectScript } from '../../utilities/script-cache';
import GooglePayPropTypes from './google-pay-prop-types';

if (process.browser) {
  require('./google-pay-button.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('flowio-google-pay-button');

const isProduction = process.env.NODE_ENV === 'production';

const messages = defineMessages({
  googlePayError: {
    id: 'checkout_error_google_pay',
    description: 'An error displayed any time something goes wrong while communicating with Google Payment API',
    defaultMessage: 'There was an error with Google Pay.',
  },
});

const createGooglePayClient = memoize(
  (
    /** @type {io.flow.v0.models.OrganizationSummary} */
    organizationSummary,
  ) => {
    const isSandbox = organizationSummary.environment === 'sandbox';
    return new google.payments.api.PaymentsClient({
      environment: (isProduction && !isSandbox) ? 'PRODUCTION' : 'TEST',
    });
  },
);

class GooglePayButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleGooglePayButtonClick = this.handleGooglePayButtonClick.bind(this);
    this.handleGooglePayError = this.handleGooglePayError.bind(this);
    this.handleGooglePayScriptError = this.handleGooglePayScriptError.bind(this);
    this.handleGooglePayScriptLoaded = this.handleGooglePayScriptLoaded.bind(this);
  }

  componentDidMount() {
    this.stopObservingScript = injectScript('https://pay.google.com/gp/p/js/pay.js', {
      onError: this.handleGooglePayScriptError,
      onLoad: this.handleGooglePayScriptLoaded,
    });
  }

  componentWillUnmount() {
    this.stopObservingScript();
  }

  handleGooglePayError(error) {
    const { intl, onCancel, onError } = this.props;
    if (error.statusCode === 'CANCELED') {
      onCancel();
    } else {
      onError(intl.formatMessage(messages.googlePayError));
      getRollbar((rollbar, extra = {}) => {
        rollbar.error(error.statusMessage, { ...extra, error });
      });
    }
  }

  handleGooglePayScriptError() {
    this.handleGooglePayError({
      statusCode: 'SCRIPT_ERROR',
      statusMessage: 'Failed to dynamically import Google Pay script.',
    });
  }

  handleGooglePayScriptLoaded() {
    const {
      isReadyToPayRequest,
      /** @type {io.flow.v0.models.OrganizationSummary} */
      organizationSummary,
    } = this.props;
    this.googlePayClient = createGooglePayClient(organizationSummary);
    this.googlePayClient.isReadyToPay(isReadyToPayRequest)
      .then((response) => {
        if (response.result) {
          this.addGooglePayButton();
          this.prefetchGooglePaymentData();
        } else {
          this.handleGooglePayError({
            statusCode: 'VISITOR_ERROR',
            statusMessage: 'Visitor is unable to provide payment information.',
          });
        }
      }, this.handleGooglePayError);
  }

  /**
   * Show Google Pay payment sheet when Google Pay payment button is clicked.
   */
  handleGooglePayButtonClick() {
    const { onSuccess, paymentDataRequest } = this.props;
    this.googlePayClient.loadPaymentData(paymentDataRequest)
      .then((paymentData) => {
        onSuccess(paymentData);
      }, this.handleGooglePayError);
  }

  /**
   * Add a Google Pay purchase button alongside an existing checkout button.
   * @see {@link https://developers.google.com/pay/api/web/reference/object#ButtonOptions}
   * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines}
   */
  addGooglePayButton() {
    const element = ReactDOM.findDOMNode(this);
    const button = this.googlePayClient.createButton({
      onClick: this.handleGooglePayButtonClick,
      buttonColor: 'default',
      buttonType: 'buy',
      buttonSizeMode: 'fill',
    });
    button.querySelector('button').setAttribute('type', 'button');
    element.appendChild(button);
  }

  /**
   * Prefetch payment data to improve performance.
   * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData}
   */
  prefetchGooglePaymentData() {
    const {
      paymentDataRequest,
    } = this.props;

    this.googlePayClient.prefetchPaymentData(paymentDataRequest);
  }

  render() {
    const {
      size,
    } = this.props;

    const modifiers = bem.block({
      [size]: true,
    });

    return (
      <div className={bem.block(modifiers)} />
    );
  }
}

GooglePayButton.displayName = 'GooglePayButton';

GooglePayButton.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isReadyToPayRequest: GooglePayPropTypes.isReadyToPayRequest.isRequired,
  onCancel: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  organizationSummary: ApiPropTypes.organizationSummary.isRequired,
  paymentDataRequest: GooglePayPropTypes.paymentDataRequest.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

GooglePayButton.defaultProps = {
  onCancel: noop,
  size: 'medium',
};

export default injectIntl(GooglePayButton);
