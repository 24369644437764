/* global cryptopay */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '../../utilities/clients';
import { getCryptoPaymentForm, getCryptoPublishableKey } from '../../store/checkout';
import { getOrganization } from '../../store/flow';
import { Response } from '../../../common/services/types';
import { trackHeapEvent } from '../../utilities/heap';
import ApiError from '../api-error';
import checkHttpStatus from '../../utilities/check-http-status';
import isHttpStatusError from '../../../common/utilities/is-http-status-error';
import useScript from '../../hooks/use-script';

interface Props {
  onApprove: (payment: CryptoPay.Payment) => void;
  onClick: () => boolean | Promise<boolean>;
}

// Create a Crypto payment
function createPayment(
  organizationId: string,
  paymentForm: io.flow.v0.unions.PaymentForm,
): Promise<io.flow.v0.unions.Payment> {
  return api.payments()
    .post(organizationId, { body: paymentForm })
    .then(checkHttpStatus)
    .then((response: any) => response.result);
}

const CryptoPayButton: React.FunctionComponent<Props> = ({
  onApprove,
  onClick,
}) => {
  const [apiError, setApiError] = useState<Response>();
  const ref = React.createRef<HTMLDivElement>();
  const organizationId = useSelector(getOrganization);
  const publishablekey = useSelector(getCryptoPublishableKey);
  const paymentForm = useSelector(getCryptoPaymentForm);

  const [scriptLoaded, scriptError] = useScript(`https://js.crypto.com/sdk?publishable-key=${publishablekey}`);

  useEffect(() => {
    if (!scriptLoaded || scriptError) {
      return;
    }

    const button = cryptopay.Button({
      createPayment(actions) {
        return createPayment(organizationId, paymentForm)
          .then((payment) => {
            if (payment.discriminator !== 'payment_cryptopay') {
              throw new TypeError('Invalid payment type');
            }

            if (payment.cryptopay.discriminator !== 'cryptopay_authorization_details') {
              throw new TypeError('Invalid payment authorization details');
            }

            return actions.payment.fetch(payment.cryptopay.payment_id);
          })
          .catch((error) => {
            if (isHttpStatusError(error)) {
              setApiError(error.response);
            }

            // Notify Crypto.com JavaScript SDK that something went wrong.
            throw new Error('Failed to create Crypto.com payment');
          });
      },
      onApprove(data) {
        trackHeapEvent('crypto_pay_approved');
        onApprove(data);
      },
      onClick,
    });

    if (ref.current != null) {
      button.render(ref.current);
    }
  }, [scriptLoaded, scriptError]);

  return (
    <>
      <div ref={ref} />
      {apiError != null && (
        <ApiError response={apiError} />
      )}
    </>
  );
};

export default CryptoPayButton;
