// Code splitting calls use promises internally.
// We must always shim promises before using code splitting.
import 'core-js/es6/promise';

function loadStandardLibrary() {
  return new Promise((resolve) => {
    require.ensure([], (require) => {
      require('core-js');
      resolve();
    }, 'polyfill-standard-library');
  });
}

function loadFetch() {
  return new Promise((resolve) => {
    require.ensure([], (require) => {
      require('whatwg-fetch');
      resolve();
    }, 'polyfill-fetch');
  });
}

function loadIntl() {
  return new Promise((resolve) => {
    require.ensure([], (require) => {
      require('intl');
      require('intl/locale-data/jsonp/da');
      require('intl/locale-data/jsonp/de');
      require('intl/locale-data/jsonp/en');
      require('intl/locale-data/jsonp/es');
      require('intl/locale-data/jsonp/fr');
      require('intl/locale-data/jsonp/hi');
      require('intl/locale-data/jsonp/it');
      require('intl/locale-data/jsonp/ja');
      require('intl/locale-data/jsonp/ko');
      require('intl/locale-data/jsonp/lv');
      require('intl/locale-data/jsonp/ms');
      require('intl/locale-data/jsonp/nb');
      require('intl/locale-data/jsonp/nl');
      require('intl/locale-data/jsonp/pl');
      require('intl/locale-data/jsonp/pt');
      require('intl/locale-data/jsonp/ru');
      require('intl/locale-data/jsonp/sv');
      require('intl/locale-data/jsonp/th');
      require('intl/locale-data/jsonp/tr');
      require('intl/locale-data/jsonp/zh-Hans-CN');
      require('intl/locale-data/jsonp/zh-Hans');
      require('intl/locale-data/jsonp/zh-Hant-TW');
      require('intl/locale-data/jsonp/zh-Hant');
      require('intl/locale-data/jsonp/zh');
      // Plural rules
      require('@formatjs/intl-pluralrules');
      require('@formatjs/intl-pluralrules');
      require('@formatjs/intl-pluralrules/dist/locale-data/da');
      require('@formatjs/intl-pluralrules/dist/locale-data/de');
      require('@formatjs/intl-pluralrules/dist/locale-data/en');
      require('@formatjs/intl-pluralrules/dist/locale-data/es');
      require('@formatjs/intl-pluralrules/dist/locale-data/fr');
      require('@formatjs/intl-pluralrules/dist/locale-data/hi');
      require('@formatjs/intl-pluralrules/dist/locale-data/it');
      require('@formatjs/intl-pluralrules/dist/locale-data/ja');
      require('@formatjs/intl-pluralrules/dist/locale-data/ko');
      require('@formatjs/intl-pluralrules/dist/locale-data/lv');
      require('@formatjs/intl-pluralrules/dist/locale-data/ms');
      require('@formatjs/intl-pluralrules/dist/locale-data/nb');
      require('@formatjs/intl-pluralrules/dist/locale-data/nl');
      require('@formatjs/intl-pluralrules/dist/locale-data/pl');
      require('@formatjs/intl-pluralrules/dist/locale-data/pt');
      require('@formatjs/intl-pluralrules/dist/locale-data/ru');
      require('@formatjs/intl-pluralrules/dist/locale-data/sv');
      require('@formatjs/intl-pluralrules/dist/locale-data/th');
      require('@formatjs/intl-pluralrules/dist/locale-data/tr');
      require('@formatjs/intl-pluralrules/dist/locale-data/zh');
      // Relative time format
      require('@formatjs/intl-relativetimeformat');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/da');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/hi');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/it');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ko');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/lv');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ms');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/nb');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/pl');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/sv');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/th');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/tr');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
      resolve();
    }, 'polyfill-intl');
  });
}

function loadIntlPluralRules() {
  return new Promise((resolve) => {
    require.ensure([], (require) => {
      require('@formatjs/intl-pluralrules');
      require('@formatjs/intl-pluralrules');
      require('@formatjs/intl-pluralrules/dist/locale-data/da');
      require('@formatjs/intl-pluralrules/dist/locale-data/de');
      require('@formatjs/intl-pluralrules/dist/locale-data/en');
      require('@formatjs/intl-pluralrules/dist/locale-data/es');
      require('@formatjs/intl-pluralrules/dist/locale-data/fr');
      require('@formatjs/intl-pluralrules/dist/locale-data/hi');
      require('@formatjs/intl-pluralrules/dist/locale-data/it');
      require('@formatjs/intl-pluralrules/dist/locale-data/ja');
      require('@formatjs/intl-pluralrules/dist/locale-data/ko');
      require('@formatjs/intl-pluralrules/dist/locale-data/lv');
      require('@formatjs/intl-pluralrules/dist/locale-data/ms');
      require('@formatjs/intl-pluralrules/dist/locale-data/nb');
      require('@formatjs/intl-pluralrules/dist/locale-data/nl');
      require('@formatjs/intl-pluralrules/dist/locale-data/pl');
      require('@formatjs/intl-pluralrules/dist/locale-data/pt');
      require('@formatjs/intl-pluralrules/dist/locale-data/ru');
      require('@formatjs/intl-pluralrules/dist/locale-data/sv');
      require('@formatjs/intl-pluralrules/dist/locale-data/th');
      require('@formatjs/intl-pluralrules/dist/locale-data/tr');
      require('@formatjs/intl-pluralrules/dist/locale-data/zh');
      resolve();
    }, 'polyfill-intl-pluralrules');
  });
}

function loadIntlRelativeTimeFormat() {
  return new Promise((resolve) => {
    require.ensure([], (require) => {
      require('@formatjs/intl-relativetimeformat');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/da');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/es');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/fr');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/hi');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/it');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ja');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ko');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/lv');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ms');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/nb');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/nl');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/pl');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/sv');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/th');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/tr');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/zh');
      resolve();
    }, 'polyfill-intl-relativetimeformat');
  });
}

export default function loadPolyfills() {
  const promises = [];

  if (!('assign' in Object && 'keys' in Object)) {
    promises.push(loadStandardLibrary());
  }

  if (global.fetch == null) {
    promises.push(loadFetch());
  }

  if (global.Intl == null) {
    promises.push(loadIntl());
  } else {
    if (global.Intl.PluralRules == null) {
      promises.push(loadIntlPluralRules());
    }

    if (global.Intl.RelativeTimeFormat == null) {
      promises.push(loadIntlRelativeTimeFormat());
    }
  }

  return Promise.all(promises);
}
