/* eslint-disable react/jsx-props-no-spreading */

import BemHelper from '@flowio/bem-helper';
import KeyCodes from '@flowio/browser-helpers/lib/KeyCodes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import getElementType from '@flowio/react-helpers/lib/getElementType';
import getKeyCode from '@flowio/browser-helpers/lib/getKeyCode';
import getUnhandledProps from '@flowio/react-helpers/lib/getUnhandledProps';
import getValueFromEvent from '@flowio/react-helpers/lib/getValueFromEvent';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import stopEvent from '@flowio/browser-helpers/lib/stopEvent';
import trim from 'lodash/trim';

import Button from '../button';
import Dash from '../svg-icons/dash';
import Plus from '../svg-icons/plus';

if (process.browser) {
  require('./spinbox.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('spinbox');

// Disallow non-numeric characters.
// Won't interfere with special character sequences (e.g. Ctrl + S)
function restrictNumeric(event) {
  const { metaKey, ctrlKey, which } = event;
  const input = String.fromCharCode(which);

  // Key event is for a browser shortcut.
  if (metaKey || ctrlKey) return;
  // Key code is a special character (WebKit)
  if (which === 0) return;
  // Key code is a special character (Firefox)
  if (which < 33) return;
  // Key code is a space
  if (which === 32) stopEvent(event);
  // Check whether character is a number
  if (!/[\d]/.test(input)) stopEvent(event);
}

// Constrains a value to be within the specified boundaries, inclusive.
function formatValue(value, min, max) {
  let number = value;

  // Remove any non-numerical characters from the value.
  if (typeof value === 'string') {
    number = value.replace(/\D*/g, '');
  }

  if (typeof value !== 'number') {
    number = Number(number);
  }

  // Check boundaries
  number = Math.max(min, number);
  number = Math.min(max, number);

  return String(number);
}

class Spinbox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const { defaultValue, value } = this.props;
    return {
      controlled: !isNil(value),
      value: value || defaultValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isNil(nextProps.value)) {
      this.setState({
        controlled: true,
        value: nextProps.value,
      });
    }
  }

  /**
   * Returns the text for the spin box, excluding any prefix, suffix,
   * or leading or trailing whitespace.
   * @returns {String}
   */
  getCleanText() {
    const { value } = this.state;
    return trim(value);
  }

  /**
   * Returns the value coerced to a number.
   * Note: If the input value is an empty string it's coerced to zero.
   * @returns {Number}
   */
  getCoercedValue() {
    const { value } = this.state;
    return Number(value);
  }

  /**
   * Returns the text to be displayed in the spin box, including
   * any prefix and suffix.
   * @returns {String}
   */
  getText() {
    const { prefix, suffix } = this.props;

    let text = this.getCleanText();

    if (prefix) text = prefix + text;
    if (suffix) text += suffix;

    return text;
  }

  setValue(event, value) {
    const { controlled } = this.state;
    const { maxValue, minValue, onChange } = this.props;

    let number = value;

    // Avoid setting a value to the input after user clears it.
    if (number !== '') {
      number = formatValue(number, minValue, maxValue);
    }

    if (!controlled) {
      this.setState({ value: number });
    }

    // For consistency with other inputs, ensure value in callback property is
    // always a string.
    onChange(event, String(number));
  }

  handleStepDownClick = (event) => {
    this.stepDown(event);
  }

  handleStepUpClick = (event) => {
    this.stepUp(event);
  }

  handleInputChange = (event) => {
    const value = getValueFromEvent(event);
    this.setValue(event, value);
  }

  handleInputKeyDown = (event) => {
    switch (getKeyCode(event)) {
      case KeyCodes.ArrowUp:
        this.stepUp(event);
        break;
      case KeyCodes.ArrowDown:
        this.stepDown(event);
        break;
      default:
        break;
    }
  }

  handleInputKeyPress = (event) => {
    restrictNumeric(event);
  }

  isStepDownEnabled() {
    const { minValue } = this.props;
    const value = this.getCoercedValue();
    return value > minValue;
  }

  isStepUpEnabled() {
    const { maxValue } = this.props;
    const value = this.getCoercedValue();
    return value < maxValue;
  }

  stepDown(event) {
    const { singleStep } = this.props;
    const value = this.getCoercedValue();
    this.setValue(event, value - singleStep);
  }

  stepUp(event) {
    const { singleStep } = this.props;
    const value = this.getCoercedValue();
    this.setValue(event, value + singleStep);
  }

  render() {
    const { className } = this.props;
    const ElementType = getElementType(Spinbox, this.props);
    const unhandledProps = getUnhandledProps(Spinbox, this.props);

    return (
      <ElementType {...unhandledProps} className={bem.block(className)}>
        <Button
          aria-label="Decrease"
          icon
          outline
          disabled={!this.isStepDownEnabled()}
          onClick={this.handleStepDownClick}
        >
          <Dash />
        </Button>
        <label>
          <input
            className={bem.element('input')}
            onChange={this.handleInputChange}
            onKeyDown={this.handleInputKeyDown}
            onKeyPress={this.handleInputKeyPress}
            type="text"
            value={this.getText()}
          />
        </label>
        <Button
          aria-label="Increase"
          icon
          outline
          disabled={!this.isStepUpEnabled()}
          onClick={this.handleStepUpClick}
        >
          <Plus />
        </Button>
      </ElementType>
    );
  }
}

Spinbox.displayName = 'Spinbox';

Spinbox.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  singleStep: PropTypes.number,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Spinbox.defaultProps = {
  as: 'div',
  className: '',
  defaultValue: '1',
  maxValue: 99,
  minValue: 1,
  onChange: noop,
  prefix: '',
  singleStep: 1,
  suffix: '',
  value: undefined,
};

export default Spinbox;
