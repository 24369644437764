import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CheckoutMetadataState } from '../types';

const initialState: CheckoutMetadataState = {
  applePayAvailabilty: {
    available: false,
    reason: 'NOT_SUPPORTED',
  },
  isCheckoutResourceEnabled: false,
  deviceDetails: undefined,
  isCheckoutPreloaded: false,
  didAddGiftCard: false,
};

const checkoutMetadataReducer: AppReducer<CheckoutMetadataState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
      return update(previousState, {
        $merge: {
          id: action.payload.id,
          platform: action.payload.platform,
        },
      });
    case actionTypes.ADD_DEVICE_DETAILS:
      return update(previousState, {
        $merge: {
          deviceDetails: action.payload,
        },
      });
    case actionTypes.UPDATE_FRAUD_REFERENCES:
      return update(previousState, {
        deviceDetails: {
          $merge: {
            fraud_references: action.payload,
          },
        },
      });
    case actionTypes.PRELOADED_CHECKOUT_CONSUMED:
      return update(previousState, {
        $unset: ['isCheckoutPreloaded'],
      });
    case actionTypes.SET_CHECKOUT_PRELOADED:
      return update(previousState, {
        $merge: {
          isCheckoutPreloaded: true,
        },
      });
    case actionTypes.ADD_GIFT_CARD_V2_SUCCESS:
      return update(previousState, {
        didAddGiftCard: {
          $set: true,
        },
      });
    case actionTypes.ADD_GIFT_CARD_V2_REQUEST:
    case actionTypes.ADD_GIFT_CARD_V2_FAILURE:
      return update(previousState, {
        didAddGiftCard: {
          $set: false,
        },
      });
    case actionTypes.APPLE_PAY_AVAILABILITY:
      return update(previousState, {
        applePayAvailabilty: {
          $set: action.payload,
        },
      });
    default:
      return previousState;
  }
};

export default checkoutMetadataReducer;
