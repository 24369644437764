import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ThunkAction } from 'redux-thunk';

import { RootState, RootActionTypes } from '../../../store/types';
import { getOrganization } from '../../../store/flow/selectors';
import { getLocale } from '../../../store/intl/selectors';
import { editAddressBookContact, removeAddressBookContact } from '../../../store/checkout/actions';
import AddressPicker from '../components/address-picker';
import { StateProps, DispatchProps } from '../types';
import { showLoadingIndicator, hideLoadingIndicator } from '../../../store/application';

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  locale: getLocale,
  organizationId: getOrganization,
});

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): DispatchProps => bindActionCreators({
  onEditAddressBookContact: editAddressBookContact,
  onRemoveAddressBookContact(
    organizationId: string,
    customerNumber: string,
    contactId: string,
  ): ThunkAction<void, RootState, void, RootActionTypes> {
    return function onRemoveAddressBookContactEffects(): void {
      dispatch(showLoadingIndicator());
      dispatch(removeAddressBookContact(organizationId, customerNumber, contactId))
        .then(() => {
          dispatch(hideLoadingIndicator());
        })
        .catch(() => {
          dispatch(hideLoadingIndicator());
        });
    };
  },
}, dispatch);

const enhanced = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhanced(AddressPicker);
