function fromLocalizedLineItemDiscount(
  localizedLineItemDiscount: io.flow.v0.models.LocalizedLineItemDiscount,
): io.flow.v0.models.DiscountForm {
  return {
    label: localizedLineItemDiscount.discount_label,
    offer: {
      discriminator: 'discount_offer_fixed',
      money: {
        amount: localizedLineItemDiscount.amount,
        currency: localizedLineItemDiscount.currency,
      },
    },
  };
}

export default {
  fromLocalizedLineItemDiscount,
};
