import React from 'react';

import { ADDRESS_CONFIGURATION_FORMATS_BY_COUNTRY } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import AddressFields from './address-fields';
import FormattedAddressFields from './formatted-address-fields';

type Props = PropsOf<typeof AddressFields>;

const Address: React.FunctionComponent<Props> = ({
  addressConfiguration,
  addressType,
  allowInvoiceAddress,
  allowVRN,
  companyName,
  countries,
  countryCode,
  customerType,
  invoice,
  isVrnValid,
  isVrnValidating,
  locale,
  onAutoCompleteAddress,
  onPostalAutoComplete,
  onVrnCheck,
  orderNumber,
  organization,
  showInvalidVrnMessage,
  showSaveAddress,
  unableToValidateVrn,
  vrn,
}) => (
  <FeatureToggle
    featureKey={ADDRESS_CONFIGURATION_FORMATS_BY_COUNTRY}
    render={({ isFeatureEnabled }): React.ReactElement => {
      if (isFeatureEnabled) {
        return (
          <FormattedAddressFields
            addressConfiguration={addressConfiguration}
            addressType={addressType}
            countries={countries}
            countryCode={countryCode}
            locale={locale}
            onAutoCompleteAddress={onAutoCompleteAddress}
            onPostalAutoComplete={onPostalAutoComplete}
          />
        );
      }

      return (
        <AddressFields
          addressConfiguration={addressConfiguration}
          addressType={addressType}
          allowInvoiceAddress={allowInvoiceAddress}
          allowVRN={allowVRN}
          companyName={companyName}
          countries={countries}
          countryCode={countryCode}
          customerType={customerType}
          invoice={invoice}
          isVrnValid={isVrnValid}
          isVrnValidating={isVrnValidating}
          locale={locale}
          onAutoCompleteAddress={onAutoCompleteAddress}
          onPostalAutoComplete={onPostalAutoComplete}
          onVrnCheck={onVrnCheck}
          orderNumber={orderNumber}
          organization={organization}
          showSaveAddress={showSaveAddress}
          showInvalidVrnMessage={showInvalidVrnMessage}
          unableToValidateVrn={unableToValidateVrn}
          vrn={vrn}
        />
      );
    }}
  />
);

export default Address;
