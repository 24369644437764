import React, { ChangeEventHandler } from 'react';
import { Field } from 'redux-form';
import BemHelper from '@flowio/bem-helper';
import {
  FormattedMessage,
  WrappedComponentProps,
  defineMessages,
  injectIntl,
} from 'react-intl';
import { Row, Column, Grid } from '../grid';
import RadioButtonGroup from '../redux-form/radio-button-group';
import { CustomerType as CustomerTypeEnum } from '../../constants/customer-type';
import Section from '../section';
import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';

if (process.browser) {
  require('./customer-type.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('customer-type');

const messages = defineMessages({
  individual: {
    id: 'checkout_individual_customer_type',
    description: 'Label for the individual customer type',
    defaultMessage: 'an individual',
  },
  business: {
    id: 'checkout_business_customer_type',
    description: 'Label for the business customer type',
    defaultMessage: 'a business',
  },
});

interface OwnProps {
  onCustomerTypeChange: ChangeEventHandler<HTMLInputElement>;
}

type Props = OwnProps & WrappedComponentProps;

const CustomerType: React.FunctionComponent<Props> = ({
  intl,
  onCustomerTypeChange,
}): React.ReactElement => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({ isFeatureEnabled: isMobileUXSpacingEnabled }): React.ReactElement => (
      <Section fitted={isMobileUXSpacingEnabled}>
        <Section.Content>
          <Grid>
            <Row>
              <div className={bem.element('type-text')}>
                <FormattedMessage
                  id="checkout_customer_type_prompt"
                  description="A label denoting where the customer chooses their type of purchase"
                  defaultMessage="I am buying as"
                />
              </div>
              <Column xs={12}>
                <Field
                  automationId="customer-type"
                  className={bem.element('type-options')}
                  onChange={onCustomerTypeChange}
                  component={RadioButtonGroup}
                  options={[
                    {
                      automationId: 'customer-type-individual',
                      value: CustomerTypeEnum.INDIVIDUAL,
                      labelText: intl.formatMessage(messages.individual),
                    },
                    {
                      automationId: 'customer-type-business',
                      value: CustomerTypeEnum.BUSINESS,
                      labelText: intl.formatMessage(messages.business),
                    },
                  ]}
                  name="customerType"
                />
              </Column>
            </Row>
          </Grid>
        </Section.Content>
      </Section>
    )}
  />
);

CustomerType.displayName = 'CustomerType';

export default injectIntl(CustomerType);
