import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getIsShopifyIntegration } from '../store/flow/selectors';
import {
  getActiveStep,
  getCartUrl,
  getContactInfoUrlV2,
  getPaymentInfoUrlV2,
  getShippingMethodUrlV2,
} from '../store/navigation/selectors';
import Breadcrumb from '../components/breadcrumb';

const mapStateToProps = createStructuredSelector({
  activeStep: getActiveStep,
  cartUrl: getCartUrl,
  contactInfoUrl: getContactInfoUrlV2,
  // Flow API does not have a concept of a cart outside of Shopify, therefore we hide the
  // breadcrumb to navigate back to the cart page for other integrations.
  isCartStepAvailable: getIsShopifyIntegration,
  paymentInfoUrl: getPaymentInfoUrlV2,
  shippingMethodUrl: getShippingMethodUrlV2,
});

export default compose(
  connect(mapStateToProps),
)(Breadcrumb);
