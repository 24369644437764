/**
 * @see https://app.apibuilder.io/flow/content-internal/latest#enum-content_status
 */
const ContentStatus = {
  ARCHIVED: 'archived',
  DRAFT: 'draft',
  LIVE: 'live',
};

export default ContentStatus;
