import type { LoyaltyProgramsDeleteByIdResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<LoyaltyProgramsDeleteByIdResponse>>;

const removeLoyaltyRewardRequest = (
  rewardId: string,
): RootAction => ({
  type: actionTypes.REMOVE_LOYALTY_REWARD_REQUEST,
  params: {
    rewardId,
  },
});

const removeLoyaltyRewardSuccess = (
  rewardId: string,
): RootAction => ({
  type: actionTypes.REMOVE_LOYALTY_REWARD_SUCCESS,
  params: {
    rewardId,
  },
});

const removeLoyaltyRewardFailure = (
  rewardId: string,
  error: LegacyError,
): RootAction => ({
  type: actionTypes.REMOVE_LOYALTY_REWARD_FAILURE,
  payload: error,
  params: {
    rewardId,
  },
});

const removeLoyaltyReward = (
  organizationId: string,
  orderNumber: string,
  rewardId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(removeLoyaltyRewardRequest(rewardId));
  return extra.apiInternal.loyaltyPrograms.deleteById({
    id: rewardId,
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 204:
          dispatch(removeLoyaltyRewardSuccess(rewardId));
          break;
        case 401:
        case 404:
          dispatch(removeLoyaltyRewardFailure(rewardId, response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default removeLoyaltyReward;
