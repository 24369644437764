import { combineReducers } from 'redux';

import { ApplicationState } from '../types';
import loadingIndicator from './loading-indicator';
import redirect from './redirect';

export default combineReducers<ApplicationState>({
  loadingIndicator,
  redirect,
});
