import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCheckout } from '../../../store/checkout/actions';
import { getCountries } from '../../../store/reference/selectors';
import { getOrganization, getOrganizationUrl } from '../../../store/flow/selectors';
import { getCheckoutContactUs } from '../../../store/content/selectors';
import {
  getAuthorizationsList,
  getContinueShoppingUrl,
  getIsDeliveredDutyCustomerChoice,
  getOrderBillingAddress,
  getOrderDeliveredDuty,
  getOrder,
  getOrderDestination,
  getSelectedDeliveryOptions,
  getOrderNumber,
  getOrderPayments,
  getVrnFromRegistration,
  allowInvoiceAddress,
  getInvoiceAddress,
} from '../../../store/checkout/selectors';
import getFeatureKeys from '../../../utilities/get-feature-keys';
import OrderConfirmationStep from '../components/order-confirmation-step';
import logInternalServerError from '../../../utilities/rollbar/log-internal-server-error';
import withExternalEvents from '../../with-external-events';
import { RootState, ThunkDispatcher } from '../../../store/types';
import { OwnProps, StateProps } from '../types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown[]> => {
  const { params } = props;
  const { checkoutId } = params;

  return Promise.all([
    dispatch(fetchCheckout(checkoutId, {
      feature_key: getFeatureKeys(),
    })),
  ])
    .catch((error) => {
      logInternalServerError(error);
      throw error;
    });
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  allowInvoiceAddress,
  invoiceAddress: getInvoiceAddress,
  authorizations: getAuthorizationsList,
  billingAddress: getOrderBillingAddress,
  countries: getCountries,
  contactUs: getCheckoutContactUs,
  continueShoppingUrl: getContinueShoppingUrl,
  deliveryOptions: getSelectedDeliveryOptions,
  deliveredDuty: getOrderDeliveredDuty,
  destination: getOrderDestination,
  isDeliveredDutyCustomerChoice: getIsDeliveredDutyCustomerChoice,
  order: getOrder,
  orderNumber: getOrderNumber,
  orderPayments: getOrderPayments,
  organization: getOrganization,
  organizationUrl: getOrganizationUrl,
  vrn: getVrnFromRegistration,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(OrderConfirmationStep);
