/**
 * @fileoverview
 * A component that renders an image for the provided payment method.
 */

import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import get from 'lodash/get';

const bem = new BemHelper('payment-icon');

const PaymentIcon = ({
  paymentMethod,
}) => (
  <img
    className={bem.block()}
    src={get(paymentMethod, 'images.medium.url')}
    alt={get(paymentMethod, 'name')}
  />
);

PaymentIcon.displayName = 'PaymentIcon';

PaymentIcon.propTypes = {
  paymentMethod: ApiPropTypes.paymentMethod.isRequired,
};

export default PaymentIcon;
