import { FormattedMessage } from 'react-intl';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '../dialog';
import Button from '../button';
import OrderError from '../../containers/order-error';
import RestrictedItem from '../../containers/restricted-item';

if (process.browser) {
  require('./restricted-items-modal.css'); // eslint-disable-line global-require
}

/**
 * @typedef {io.flow.v0.models.OrderError} OrderError
 */

/**
 * @typedef {io.flow.v0.models.Order} Order
 */

const bem = new BemHelper('restricted-items-modal');

/**
 * @typedef {Object} RestrictedItemsModalProps
 * @property {OrderError} error
 * @property {boolean} isLoading
 * @property {boolean} isOpen
 * @property {string[]} numbers
 * @property {React.MouseEventHandler} onChangeAddress
 * @property {function(string, string): void} onClearCartAndContinueShopping
 * @property {function(string, string, string[]): void} onRemoveRestrictedItems
 * @property {Order} order
 * @property {string} organizationId
 */

/**
 * @param {RestrictedItemsModalProps} props
 */
const RestrictedItemsModal = ({
  error,
  isLoading,
  isOpen,
  numbers,
  onChangeAddress,
  onClearCartAndContinueShopping,
  onRemoveRestrictedItems,
  order,
  organizationId,
}) => (
  <Dialog data-automation-id="restricted-items-modal" isOpen={isOpen} showBackdrop>
    <DialogHeader
      dividing
      text={(
        <FormattedMessage
          id="checkout_item_restrictions_header"
          description="Title for restricted items modal"
          defaultMessage="Shipping Exclusions"
        />
      )}
    />
    <DialogBody>
      <div>
        <FormattedMessage
          id="checkout_item_restrictions_description"
          description="Description of the restricted item modal"
          defaultMessage="Unfortunately, due to export restrictions, the following products cannot be shipped to the desired country. Please change your shipping address or remove these items from your cart."
        />
      </div>
      <div className={bem.element('item-list')}>
        {map(numbers, (number) => (
          <RestrictedItem key={number} number={number} />
        ))}
      </div>
    </DialogBody>
    <DialogFooter>
      {error != null && (
        <OrderError className={bem.element('order-error')} error={error} />
      )}
      <div className={bem.element('modal-actions')}>
        <Button
          disabled={isLoading}
          fluid
          onClick={() => {
            const orderNumber = order.number;
            if (order.items.every((item) => numbers.indexOf(item.number) >= 0)) {
              onClearCartAndContinueShopping(organizationId, orderNumber);
            } else {
              onRemoveRestrictedItems(organizationId, orderNumber, numbers);
            }
          }}
          color="positive"
        >
          <FormattedMessage
            id="checkout_item_restrictions_remove_items"
            description="Text for the remove item button in restrictions modal"
            defaultMessage="Remove Items from Cart and Continue"
          />
        </Button>
      </div>
      <div>
        <Button
          disabled={isLoading}
          fluid
          onClick={onChangeAddress}
        >
          <FormattedMessage
            id="checkout_item_restrictions_change_destination"
            description="Text for the chane address button in restrictions modal"
            defaultMessage="Change Address"
          />
        </Button>
      </div>
    </DialogFooter>
  </Dialog>
);

RestrictedItemsModal.propTypes = {
  error: ApiPropTypes.orderError,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  numbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onClearCartAndContinueShopping: PropTypes.func.isRequired,
  onRemoveRestrictedItems: PropTypes.func.isRequired,
  order: ApiPropTypes.order.isRequired,
  organizationId: PropTypes.string.isRequired,
};

RestrictedItemsModal.defaultProps = {
  error: undefined,
};

export default RestrictedItemsModal;
