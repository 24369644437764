import type { CardPaymentSourcesDeleteByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<CardPaymentSourcesDeleteByIdResponse>>;

const deleteCardPaymentSourceRequest = (
  organizationId: string,
  cardPaymentSourceId: string,
): RootAction => ({
  type: actionTypes.DELETE_CARD_PAYMENT_SOURCE_REQUEST,
  meta: {
    organizationId,
    paymentSourceId: cardPaymentSourceId,
  },
});

const deleteCardPaymentSourceSuccess = (
  organizationId: string,
  cardPaymentSourceId: string,
): RootAction => ({
  type: actionTypes.DELETE_CARD_PAYMENT_SOURCE_SUCCESS,
  meta: {
    organizationId,
    paymentSourceId: cardPaymentSourceId,
  },
});

const deleteCardPaymentSourceFailure = (
  organizationId: string,
  cardPaymentSourceId: string,
  error: LegacyError | io.flow.v0.models.GenericError,
): RootAction => ({
  type: actionTypes.DELETE_CARD_PAYMENT_SOURCE_FAILURE,
  payload: error,
  meta: {
    organizationId,
    paymentSourceId: cardPaymentSourceId,
  },
});

const deleteCardPaymentSource = (
  organizationId: string,
  cardPaymentSourceId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(deleteCardPaymentSourceRequest(organizationId, cardPaymentSourceId));
  return extra.api.cardPaymentSources.deleteById({
    id: cardPaymentSourceId,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 204:
          dispatch(deleteCardPaymentSourceSuccess(organizationId, cardPaymentSourceId));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(deleteCardPaymentSourceFailure(
            organizationId,
            cardPaymentSourceId,
            response.result,
          ));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default deleteCardPaymentSource;
