import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

import { RETURNING_CUSTOMERS, MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import { Grid, Row, Column } from '../grid';
import FeatureToggle from '../feature-toggle';
import Checkbox from '../redux-form/checkbox';
import FlowCardElement from '../flow-card-element';

import './card.css';

const bem = new BemHelper('credit-card');

export interface CardProps {
  saveCard: boolean;
}

const Card: React.FunctionComponent<CardProps> = ({
  saveCard,
}) => (
  <div className={bem.block()}>
    <FeatureToggle
      featureKey={MOBILE_UX_SPACING}
      render={({ isFeatureEnabled }): React.ReactElement => (
        <Grid parent={isFeatureEnabled}>
          <Row>
            <Column xs={12}>
              <FlowCardElement genericMobileImprovements={isFeatureEnabled} />
            </Column>
          </Row>
          {saveCard && (
            <FeatureToggle featureKey={RETURNING_CUSTOMERS}>
              <Row>
                <Column xs={12}>
                  <Field
                    component={Checkbox}
                    name="saveCard"
                    labelText={(
                      <FormattedMessage
                        id="checkout_save_card"
                        description="A message denoting whether to save a customer's card information"
                        defaultMessage="Save the card info above for faster checkout in future"
                      />
                    )}
                  />
                </Column>
              </Row>
            </FeatureToggle>
          )}
        </Grid>
      )}
    />
  </div>
);

Card.displayName = 'Card';

Card.defaultProps = {
  saveCard: false,
};

export default Card;
