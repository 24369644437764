import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import { createRollbarMiddleware } from '@flowio/redux-rollbar-middleware';
import { enableBatching } from 'redux-batched-actions';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import type { History } from 'history';
import type Rollbar from 'rollbar';

import { createAsyncMiddleware } from './middlewares/redux-async-middleware';
import { rootReducer } from './store';
import externalEvents from './middlewares/external-events';
import flowEvents from './middlewares/redux-flow-events';
import reduxRollbarMiddlewareExtend from './utilities/redux/redux-rollbar-middleware-extend';
import type { ApiClientV2 } from '../common/services/api-v2';
import type { ApiInternalClientV2 } from '../common/services/api-internal-v2';
import type { RootState, RootAction, ThunkExtraArgument } from './store/types';

interface StoreOptions {
  apiClient: ApiClientV2;
  apiInternalClient: ApiInternalClientV2;
  history: History;
  initialState: RootState;
  rollbar: Rollbar;
}

export default function configureStore(
  options: StoreOptions,
): Store<RootState, RootAction> {
  const {
    apiClient,
    apiInternalClient,
    history,
    initialState,
    rollbar,
  } = options;

  const middlewares = [
    thunk.withExtraArgument<ThunkExtraArgument>({
      api: apiClient,
      apiInternal: apiInternalClient,
      rollbar,
    }),
    flowEvents,
    externalEvents,
    createAsyncMiddleware(),
    createRollbarMiddleware({
      rollbar,
      extendOptionsFn: reduxRollbarMiddlewareExtend,
    }),
    routerMiddleware(history),
  ];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger({
      level: 'info',
      collapsed: true,
    }));
  }

  const store = createStore<RootState, RootAction, unknown, unknown>(
    enableBatching<RootState>(rootReducer),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./store', () => {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        store.replaceReducer(require('./store').rootReducer);
      });
    }
  }

  return store;
}
