import { getRequiredEnvVar } from '../utilities/environment';
import envelopedFetch from '../utilities/enveloped-fetch';

import Authorizations from '../../generated/api-internal/authorizations';
import CheckoutBundles from '../../generated/api-internal/checkout-bundles';
import CheckoutContentSummaries from '../../generated/api-internal/checkout-content-summaries';
import CheckoutFinalizeOrderForms from '../../generated/api-internal/checkout-finalize-order-forms';
import V1Checkouts from '../../generated/api-internal/v1-checkouts';
import CheckoutSubmitOrderBundles from '../../generated/api-internal/checkout-submit-order-bundles';
import DiscountCodes from '../../generated/api-internal/discount-codes';
import FeatureValues from '../../generated/api-internal/feature-values';
import GiftCardPrograms from '../../generated/api-internal/gift-card-programs';
import LoyaltyPrograms from '../../generated/api-internal/loyalty-programs';
import OptinPrompts from '../../generated/api-internal/optin-prompts';
import Orders from '../../generated/api-internal/orders';
import ShopifyOrderDetails from '../../generated/api-internal/shopify-order-details';

class ApiInternalClient {
  constructor(options) {
    this.authorizations = new Authorizations(options);
    this.checkoutBundles = new CheckoutBundles(options);
    this.checkoutContentSummaries = new CheckoutContentSummaries(options);
    this.checkoutFinalizeOrderForms = new CheckoutFinalizeOrderForms(options);
    this.checkouts = new V1Checkouts(options);
    this.checkoutSubmitOrderBundles = new CheckoutSubmitOrderBundles(options);
    this.discountCodes = new DiscountCodes(options);
    this.featureValues = new FeatureValues(options);
    this.giftCardPrograms = new GiftCardPrograms(options);
    this.loyaltyPrograms = new LoyaltyPrograms(options);
    this.optinPrompts = new OptinPrompts(options);
    this.orders = new Orders(options);
    this.shopifyOrderDetails = new ShopifyOrderDetails(options);
  }
}

export default new ApiInternalClient({
  host: getRequiredEnvVar('FLOW_API_URI'),
  fetch: envelopedFetch,
});
