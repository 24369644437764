/**
 * @fileoverview
 * A component that renders a set of images for the specified payment method rules. Useful for
 * displaying support payment methods during the checkout process.
 */

import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

import PaymentIcon from '../payment-icon';

if (process.browser) {
  require('./payment-icon-list.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-icon-list');

const PaymentIconList = ({
  paymentMethodRules,
}) => (
  <ul className={bem.block()}>
    {map(paymentMethodRules, (paymentMethodRule) => {
      const paymentMethod = get(paymentMethodRule, 'payment_method');
      const paymentMethodId = get(paymentMethod, 'id');
      return (
        <li key={paymentMethodId} className={bem.element('item')}>
          <PaymentIcon paymentMethod={paymentMethod} />
        </li>
      );
    })}
  </ul>
);

PaymentIconList.displayName = 'PaymentIconList';

PaymentIconList.propTypes = {
  paymentMethodRules: PropTypes.arrayOf(ApiPropTypes.paymentMethodRule).isRequired,
};

export default PaymentIconList;
