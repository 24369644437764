import PaymentReadableName from '../constants/payment-readable-name';

export function isConfirmationActionRequired(
  paymentSource: io.flow.v0.models.CardPaymentSource,
  actionType: io.flow.v0.enums.PaymentSourceConfirmationActionType,
): boolean {
  return (
    paymentSource.actions != null
    && paymentSource.actions.some((action) => action.type === actionType)
  );
}

export function isBillingAddressConfirmationRequired(
  paymentSource: io.flow.v0.unions.PaymentSource,
): boolean {
  switch (paymentSource.discriminator) {
    case 'card_payment_source':
      return isConfirmationActionRequired(paymentSource, 'billing_address');
    default:
      return false;
  }
}

export function isCardNumberConfirmationRequired(
  paymentSource: io.flow.v0.unions.PaymentSource,
): boolean {
  switch (paymentSource.discriminator) {
    case 'card_payment_source':
      return isConfirmationActionRequired(paymentSource, 'number');
    default:
      return false;
  }
}

export function isCvvConfirmationRequired(
  paymentSource: io.flow.v0.unions.PaymentSource,
): boolean {
  switch (paymentSource.discriminator) {
    case 'card_payment_source':
      return isConfirmationActionRequired(paymentSource, 'cvv');
    default:
      return false;
  }
}

export function formatSingleLineCardSummaryLabel(
  card: io.flow.v0.models.CardSummary,
): string {
  const readableName = PaymentReadableName[card.type] || card.type;
  return `${readableName} ending in ${card.last4}`;
}

export function formatSingleLinePaymentSourceLabel(
  paymentSource: io.flow.v0.unions.PaymentSource,
): string | undefined {
  switch (paymentSource.discriminator) {
    case 'card_payment_source':
      return formatSingleLineCardSummaryLabel(paymentSource.summary.card);
    default:
      return undefined;
  }
}
