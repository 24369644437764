import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { CheckoutCollapsiblesState } from '../types';
import type { AppReducer } from '../../types';

const initialState: CheckoutCollapsiblesState = {
  orderSummary: {
    visibility: 'hidden',
  },
  giftCard: {
    isOpen: false,
  },
  giftMessaging: {
    isOpen: false,
  },
};

const collapsiblesReducer: AppReducer<CheckoutCollapsiblesState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.HIDE_ORDER_SUMMARY:
      return update(previousState, {
        orderSummary: {
          visibility: { $set: 'hidden' },
        },
      });
    case actionTypes.SHOW_ORDER_SUMMARY:
      return update(previousState, {
        orderSummary: {
          visibility: { $set: 'visible' },
        },
      });
    case actionTypes.SET_GIFT_CARD_CONTENT_STATE:
      return update(previousState, {
        giftCard: {
          isOpen: { $set: action.payload },
        },
      });
    case actionTypes.SET_GIFT_MESSAGING_CONTENT_STATE:
      return update(previousState, {
        giftMessaging: {
          isOpen: { $set: action.payload.isOpen },
        },
      });
    default:
      return previousState;
  }
};

export default collapsiblesReducer;
