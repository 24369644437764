import actionTypes from '../../constants/action-types';
import type { RootAction } from '../../types';

export default function editAddressBookContact(
  organizationId: string,
  customer: io.flow.v0.models.Customer,
  contact: io.flow.v0.models.CustomerAddressBookContact,
): RootAction {
  return {
    type: actionTypes.EDIT_ADDRESS_BOOK_CONTACT,
    payload: {
      contact,
      customer,
      organizationId,
    },
  };
}
