const AdyenLocales = {
  zh: 'zh-CN',
  zh_TW: 'zh-TW',
  da: 'da-DK',
  nl: 'nl-NL',
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  ja: 'ja-JP',
  nb: 'no-NO',
  pl: 'pl-PL',
  pt: 'pt-BR',
  es: 'es-ES',
  sv: 'sv-SE',
};

export default AdyenLocales;
