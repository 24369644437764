import { injectIntl } from 'react-intl';
import {
  reduxForm,
  formValueSelector,
  change,
} from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CancellationTokenSource } from '@flowio/cancellation-token';

import { getIsShopifyIntegration, getOrganization } from '../../store/flow/selectors';
import { getCartUrl } from '../../store/navigation/selectors';

import {
  getContinueShoppingUrl,
  getCustomerAddressBook,
  getOrganizationCountries,
  getOrderError,
  getOrderNumber,
  allowInvoiceAddress,
  getIsVrnValidating,
  getIsPayPalExpressCheckoutEnabled,
  getIsPaypalExpressReady,
  hasMultipleShippingMethods,
  allowVRN,
  getReturningCustomer,
} from '../../store/checkout/selectors';

import {
  getCountryFieldValue,
  getShippingAddressConfiguration,
  getInitialContactInfoFormValues,
  getShouldCollectCustomerTaxId,
} from './selectors';

import { getLocale } from '../../store/intl/selectors';
import { handleValidate } from './validation';
import { hideLoadingIndicator, showLoadingIndicator } from '../../store/application/actions';
import { fetchAdministrativeDivisions } from '../../store/reference/actions';
import {
  checkVrnAndUpdateOrder,
  fetchAddressConfiguration,
  submitCustomerInformation,
  updateInvoiceAddress,
} from '../../store/checkout/actions';
import { visitShippingMethodStep, visitPaymentInfoStep } from '../../store/navigation/actions';
import FormName from '../../constants/form-name';
import CustomerInformationForm from '../../components/customer-information-form';
import isEuropeanUnion from '../../utilities/is-european-union';
import { CustomerType } from '../../constants/customer-type';
import { getOptinPrompts, fetchOptinPrompts } from '../../store/optin';
import PromptTarget from '../../constants/prompt-target';
import { getContactById } from '../../utilities/address-book-utilities';

const formName = FormName.CUSTOMER_INFORMATION;

/** @type {CancellationTokenSource | undefined} */
let cancellationTokenSource;

// NOTE: A bug was introduced to `redux-form` that prevents us from using
// the `clearFields` action:
// https://github.com/erikras/redux-form/issues/4101
function clearFields(dispatch, fieldNames = []) {
  fieldNames.forEach((fieldName) => {
    dispatch(change(formName, fieldName, null, false));
  });
}

function handleCountryChange(values, dispatch, props, previousValues) {
  const {
    country: previousCountry,
  } = previousValues;

  const {
    addressToUse,
    country,
    organizationName,
    organizationId,
    orderNumber,
    vrn,
  } = values;

  const {
    allowVRN: isVrnAllowed,
  } = props;

  if (cancellationTokenSource != null) {
    cancellationTokenSource.cancel();
  }

  cancellationTokenSource = new CancellationTokenSource();

  if (isVrnAllowed) {
    // If we change the country to a non EU country we want to clear out the
    // associated VRN...
    if (!isEuropeanUnion(country) && isEuropeanUnion(previousCountry)) {
      dispatch(checkVrnAndUpdateOrder({
        organizationId,
        orderNumber,
        companyName: '',
        vrn: '',
        cancellationToken: cancellationTokenSource.token,
      })).then(() => {
        cancellationTokenSource = undefined;
      });
    } else if (!isEuropeanUnion(previousCountry) && isEuropeanUnion(country)) {
      if (vrn != null && organizationName != null) {
        dispatch(checkVrnAndUpdateOrder({
          organizationId,
          orderNumber,
          vrn,
          companyName: organizationName,
          cancellationToken: cancellationTokenSource.token,
        })).then(() => {
          cancellationTokenSource = undefined;
        });
      }
    }
  }

  dispatch(fetchOptinPrompts({
    organizationId,
    updatedRegion: country,
    promptTarget: PromptTarget.CHECKOUT,
    locale: props.locale,
  }));

  // If country change is triggered by selecting an address book contact, then
  // avoid clearing the assigned administrative division...
  if (addressToUse === 'newAddress' && previousCountry) {
    clearFields(dispatch, ['administrativeArea']);
  }

  // TODO: Ideally, the action to display the loading indicator is not
  // dispatched when the address configuration does not need to be fetched.
  // Unfortunately, the state is not available in this handler and we need
  // to move all these side effects in a Redux Thunk instead.
  dispatch(showLoadingIndicator());

  Promise.all([
    dispatch(fetchAddressConfiguration(organizationId, country)),
    dispatch(fetchAdministrativeDivisions({
      countries: [country],
    })),
  ]).then(() => {
    dispatch(hideLoadingIndicator());
  }).catch(() => {
    dispatch(hideLoadingIndicator());
  });
}

function handleChange(values, dispatch, props, previousValues) {
  const {
    country: previousCountry,
  } = previousValues;

  const {
    country,
  } = values;

  if (country !== previousCountry) {
    handleCountryChange(values, dispatch, props, previousValues);
  }
}

function handleSubmit(values, dispatch, { intl }) {
  return dispatch(submitCustomerInformation(values, intl));
}

function handleSubmitSuccess(result, dispatch, props) {
  const {
    isPayPalExpressReady,
    multipleShippingMethods,
  } = props;

  if (isPayPalExpressReady && !multipleShippingMethods) {
    return dispatch(visitPaymentInfoStep());
  }
  return dispatch(visitShippingMethodStep());
}

const mapDispatchToProps = (dispatch) => ({
  // A lot going on here, let me break it down
  // Basically if they change to being an individual we want to clear out both the
  // invoice address and the tax registration for the order
  // If they switch to being a business and there is already a VRN in state, then we want
  // to update the order with a default invoice address and the tax_registration
  onCustomerTypeChange(organization, orderNumber, customerType, vrn, companyName, countryCode) {
    if (cancellationTokenSource) cancellationTokenSource.cancel();
    cancellationTokenSource = new CancellationTokenSource();
    if (customerType === CustomerType.INDIVIDUAL) {
      return dispatch(updateInvoiceAddress(organization, orderNumber, {})).then(() => dispatch(
        checkVrnAndUpdateOrder({
          organizationId: organization,
          orderNumber,
          companyName: '',
          vrn: '',
          cancellationToken: cancellationTokenSource.token,
        }),
      )).then(() => { cancellationTokenSource = undefined; });
    }

    if (customerType === CustomerType.BUSINESS && vrn) {
      return dispatch(updateInvoiceAddress(
        organization,
        orderNumber,
        {
          country: countryCode,
          company: companyName,
        },
      )).then(() => dispatch(checkVrnAndUpdateOrder({
        organizationId: organization,
        orderNumber,
        vrn,
        companyName,
        cancellationToken: cancellationTokenSource.token,
      })));
    }

    return Promise.resolve();
  },
});

const mapStateToProps = (state) => ({
  allowVRN: allowVRN(state),
  addressBook: getCustomerAddressBook(state),
  addressConfiguration: getShippingAddressConfiguration(state),
  initialValues: getInitialContactInfoFormValues(state),
  cartUrl: getCartUrl(state),
  // TODO: Refactor logic that requires this prop into a dispatch function
  orderNumber: getOrderNumber(state),
  organizationId: getOrganization(state),
  continueShoppingUrl: getContinueShoppingUrl(state),
  countries: getOrganizationCountries(state),
  countryCode: getCountryFieldValue(state),
  // Flow API does not have a concept of a cart outside of Shopify, therefore we hide the
  // button to navigate back to the cart page for other integrations.
  isCartStepAvailable: getIsShopifyIntegration(state),
  isPayPalExpressCheckoutEnabled: getIsPayPalExpressCheckoutEnabled(state),
  isPayPalExpressReady: getIsPaypalExpressReady(state),
  multipleShippingMethods: hasMultipleShippingMethods(state),
  locale: getLocale(state),
  orderError: getOrderError(state),
  // orderCountry: getOrderDestinationCountryCode(state),
  allowInvoiceAddress: allowInvoiceAddress(state),
  optinPrompts: getOptinPrompts(state),
  isVrnValidating: getIsVrnValidating(state),
  shouldCollectCustomerTaxId: getShouldCollectCustomerTaxId(state),
});

const mapFormValuesToProps = (state) => {
  const formSelector = formValueSelector(formName);
  return {
    addressToUse: formSelector(state, 'addressToUse'),
    companyName: formSelector(state, 'organizationName'),
    vrn: formSelector(state, 'vrn'),
    customerType: formSelector(state, 'customerType'),
    returningCustomer: getReturningCustomer(state),
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: false,
    keepDirtyOnReinitialize: true,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSubmitSuccess,
    validate: handleValidate,
    shouldError({
      fieldValidatorKeys,
      initialRender,
      lastFieldValidatorKeys,
      nextProps,
      props,
      structure,
      values,
    }) {
      if (initialRender) {
        return true;
      }

      const {
        addressBook,
      } = props;

      const {
        addressBook: nextAddressBook,
        values: nextValues,
      } = nextProps;

      let selectedContact;
      let nextSelectedContact;

      if (addressBook != null) {
        selectedContact = getContactById(addressBook, values.addressToUse);
      }

      if (nextAddressBook != null) {
        nextSelectedContact = getContactById(nextAddressBook, nextValues.addressToUse);
      }

      return (
        !structure.deepEqual(values, nextProps && nextProps.values)
        || !structure.deepEqual(lastFieldValidatorKeys, fieldValidatorKeys)
        || !structure.deepEqual(selectedContact, nextSelectedContact)
      );
    },
  }),
  // map form values from state after form is initialized to avoid injecting
  // undefined values.
  connect(mapFormValuesToProps),
)(CustomerInformationForm);
