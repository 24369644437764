/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import {
  Field,
  change,
  GenericFieldHTMLAttributes,
  BaseFieldProps,
  WrappedFieldProps,
} from 'redux-form';

// This component extends the Redux Form Field component with the ability to
// clear itself when unregistered on unmount.
// https://github.com/erikras/redux-form/issues/2761
class ClearableField<P extends
GenericFieldHTMLAttributes
| BaseFieldProps = GenericFieldHTMLAttributes
| BaseFieldProps
> extends React.Component<P> {
  private fieldRef: React.RefObject<Field>;

  public static displayName = 'ClearableField';

  private constructor(props: P) {
    super(props);
    this.fieldRef = React.createRef();
  }

  public componentWillUnmount(): void {
    if (super.componentWillUnmount) {
      super.componentWillUnmount();
    }
    const {
      current: currentFieldRef,
    } = this.fieldRef;

    if (currentFieldRef !== null) {
      const {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: property is not defined because is intended to be private to the module.
        _reduxForm: {
          dispatch,
          form,
        },
        name,
      } = currentFieldRef.props as (P & WrappedFieldProps);
      const action = change(form, name as string, null);
      dispatch(action);
    }
  }

  public render(): React.ReactNode {
    // TODO: It was not clear how to fix compilation error after TypeScript upgrade.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Field {...this.props} ref={this.fieldRef} />;
  }
}

export default ClearableField;
