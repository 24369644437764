import BemHelper from '@flowio/bem-helper';
import React from 'react';
import './address-book-contact.css';

import { AddressType } from '../../constants/address-type';

import { formatSingleLineAddressLabel, formatSingleLineNameLabel } from '../../utilities/address-utilities';

export interface Props {
  addressType: AddressType;
  children: {
    action?: React.ReactNode;
  };
  contact: io.flow.v0.models.CustomerAddressBookContact;
}

const bem = new BemHelper('flow-address-book-contact');

const AddressBookContact: React.FunctionComponent<Props> = ({
  addressType,
  children,
  contact,
}) => (
  <div className={bem.block()} data-automation-id={`${addressType}-address-book-contact`}>
    <div className={bem.element('content')}>
      <div className={bem.element('name-label')}>
        {formatSingleLineNameLabel(contact)}
      </div>
      <div className={bem.element('address-label')}>
        {formatSingleLineAddressLabel(contact)}
      </div>
    </div>
    {children.action != null && (
      <div className={bem.element('actions')}>
        {children.action}
      </div>
    )}
  </div>
);

AddressBookContact.displayName = 'AddressBookContact';

AddressBookContact.defaultProps = {
  children: {},
};

export default AddressBookContact;
