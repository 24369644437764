import { TaskQueue } from '@flowio/blaze-task-queue';
import { EventEmitter } from 'events';
import { Store } from 'redux';
import { getSession } from '@flowio/session';
import isNumber from 'lodash/isNumber';

import isLocalhost from './is-localhost';
import ExternalEvents from '../external/constants/external-events';
import PageViews from '../constants/pageviews';
import { CheckoutEventData } from '../types';
import { RootState, RootAction } from '../store/types';

let heapWasInstalledByUs = false;

const queue = new TaskQueue();

function isHeapInstalled(): boolean {
  return 'heap' in window;
}

function getHeap(): Heap.Client | undefined {
  // Ensures we are only using Heap when it was installed by us.
  return heapWasInstalledByUs ? window.heap : undefined;
}

function loadHeap() {
  let heapAppId: string;

  if (
    process.env.NODE_ENV === 'production'
    // Check that we are not in localhost to avoid sending data to
    // production account from CI servers or when testing the
    // production build locally.
    && !isLocalhost(window.location.hostname)
  ) {
    heapAppId = '2146140231';
  } else {
    heapAppId = '2796966019';
  }

  /* eslint-disable */
  // @ts-ignore
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  /* eslint-enable */
  window.heap.load(heapAppId, {
    disableTextCapture: true,
  });

  queue.run();
}

export function addHeapEventProperties(
  properties: Record<string, any>,
): void {
  queue.insert(() => {
    const heap = getHeap();
    if (heap != null) {
      try {
        heap.addEventProperties(properties);
      } catch (error) {
        // Heap relies on the global scope, if it's loaded twice it will fail.
      }
    }
  });
}

export function addHeapUserProperties(
  properties: Record<string, any>,
): void {
  queue.insert(() => {
    const heap = getHeap();
    if (heap != null) {
      try {
        heap.addUserProperties(properties);
      } catch (error) {
        // Heap relies on the global scope, if it's loaded twice it will fail.
      }
    }
  });
}

export function trackHeapEvent(
  event: string,
  properties?: Record<string, any>,
): void {
  queue.insert(() => {
    const heap = getHeap();
    if (heap != null) {
      try {
        heap.track(event, properties);
      } catch (error) {
        // Heap relies on the global scope, if it's loaded twice it will fail.
      }
    }
  });
}

export function installHeap(): void {
  window.addEventListener('load', () => {
    // Push to the macrotask queue to ensure any Heap installation that clients
    // may have implemented on page load is processed first.
    // NOTE: If Heap is installed past the load event our check won't work
    // (e.g. clients that use GTM to load Heap later in the lifecycle)
    setTimeout(() => {
      if (!isHeapInstalled()) {
        loadHeap();
        heapWasInstalledByUs = true;
      }
    }, 0);
  });
}

export function configureHeap(
  events: EventEmitter,
  store: Store<RootState, RootAction>,
): void {
  const addEventProperties = (data: CheckoutEventData) => {
    const session = getSession();
    const state = store.getState();
    // TODO: Not using selector to avoid cyclic dependencies.
    const checkoutId = state.checkout.metadata.id;
    const orderNumber = data.order.number;

    let organizationId: string | undefined;
    let experienceKey: string | undefined;
    let experimentKey: string | undefined;
    let experimentVariantKey: string | undefined;
    let visitorId: string | undefined;
    let visitId: string | undefined;

    if (data.organization != null) {
      organizationId = data.organization;
    } else if (session != null) {
      organizationId = session.organization;
    }

    if (data.experiment != null) {
      experimentKey = data.experiment.key;

      if (data.experiment.variant != null) {
        experimentVariantKey = data.experiment.variant.key;
      }
    } else if (session != null && session.experiment != null) {
      experimentKey = session.experiment.key;

      if (session.experiment.variant != null) {
        experimentVariantKey = session.experiment.variant.key;
      }
    }

    if (data.order.experience != null) {
      experienceKey = data.order.experience.key;
    } else if (session != null && session.experience != null) {
      experienceKey = session.experience.key;
    }

    if (session != null) {
      visitId = session.visit.id;
      visitorId = session.visitor.id;
    }

    addHeapEventProperties({
      checkout_id: checkoutId,
      order_number: orderNumber,
      organization_id: organizationId,
      experience_key: experienceKey,
      experiment_key: experimentKey,
      experiment_variant_key: experimentVariantKey,
      visitor_id: visitorId,
      visit_id: visitId,
    });

    addHeapUserProperties({
      organization_id: organizationId,
    });
  };

  // This event is only fired once per session!
  events.on(ExternalEvents.INITIALIZED, (data: CheckoutEventData) => {
    addEventProperties(data);
  });

  events.on(PageViews.CONTACT_INFO, (data: CheckoutEventData) => {
    addEventProperties(data);
    trackHeapEvent('checkout_progress', {
      step: 'customer_info',
    });
  });

  events.on(PageViews.SHIPPING_METHOD, (data: CheckoutEventData) => {
    addEventProperties(data);
    trackHeapEvent('checkout_progress', {
      step: 'shipping_method',
    });
  });

  events.on(PageViews.PAYMENT_INFO, (data: CheckoutEventData) => {
    addEventProperties(data);
    trackHeapEvent('checkout_progress', {
      step: 'payment_info',
    });
  });

  events.on(ExternalEvents.TRANSACTION, (data: CheckoutEventData) => {
    const shipping = data.order.prices.find((price) => price.key === 'shipping');
    const tax = data.order.prices.find((price) => price.key === 'vat');
    const duty = data.order.prices.find((price) => price.key === 'duty');
    trackHeapEvent('purchase', {
      // Note base currency is based on the organization,
      // so there is a chance that it won't be USD!
      currency: data.order.total.base.currency,
      revenue: data.order.total.base.amount,
      shipping: shipping != null ? shipping.base.amount : 0,
      duty: duty != null ? duty.amount : 0,
      tax: tax != null ? tax.amount : 0,
    });
  });
}
