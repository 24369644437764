/**
 * Enumarates identifiers for forms in checkout process.
 */
const FormName = {
  CUSTOMER_INFORMATION: 'customerInformation',
  COUPON: 'coupon',
  NO_PAYMENT: 'noPayment',
  PAYMENT_INFORMATION: 'paymentInformation',
  LOYALTY_FORM: 'LoyaltyForm',
  GIFT_CARD_FORM: 'GiftCardForm',
  GIFT_CARD_V2_FORM: 'GiftCardFormV2',
  ADDRESS_BOOK_CONTACT: 'addressBookContact',
};

export default FormName;
