import type { OrdersPostGiftCardsAndAuthorizeByNumberResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { AuthorizeGiftCardsError } from '../types';
import type { LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<OrdersPostGiftCardsAndAuthorizeByNumberResponse>
>;

const authorizeGiftCardsRequest = (): RootAction => ({
  type: actionTypes.AUTHORIZE_GIFT_CARDS_REQUEST,
});

const authorizeGiftCardsSuccess = (
  giftCardProgram: io.flow.internal.v0.models.GiftCardProgram,
): RootAction => ({
  type: actionTypes.AUTHORIZE_GIFT_CARDS_SUCCESS,
  payload: giftCardProgram,
});

const authorizeGiftCardsFailure = (
  error: AuthorizeGiftCardsError,
): RootAction => ({
  type: actionTypes.AUTHORIZE_GIFT_CARDS_FAILURE,
  payload: error,
});

const authorizeGiftCards = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(authorizeGiftCardsRequest());
  return extra.apiInternal.orders.postGiftCardsAndAuthorizeByNumber({
    number: orderNumber,
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
        case 201:
          dispatch(authorizeGiftCardsSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(authorizeGiftCardsFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default authorizeGiftCards;
