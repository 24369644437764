/**
 * A class to produce delays upon which to wait between ticks a backoff function.
 *
 * This does not care about the function, but simply calculates the rate at which to execute in an
 * exponential backoff scenario. Calling `next` will produce an increasing (slightly random) delay
 * value such as:
 *
 * const backoff = new ExponentialBackoff();
 * next() // 593
 * next() // 1044
 * next() // 1519
 * next() // 2033
 *
 * Increase the `delayOffset` value to widen the randomness around the base `delay`
 *
 * Use `maxDuration` to make `next()` return undefined when the value in milliseconds has been
 * surpassed. The duration is calculated from the first time `next()` is called. To reset the
 * duration calculation, call the `reset()` method.
 */

/**
 * @typedef {object} Options
 * @property {number | undefined} [delay] - the delay to add between ticks
 * @property {number | undefined} [delayOffset] - The amount of randomness applied to offset at each tick
 * @property {number | undefined} [maxDuration] - The maximum amount of time in milliseconds to continue to produce delays
 */

export default class ExponentialBackoff {
  /**
   * @param {Options} options
   */
  constructor({
    delay = 500,
    delayOffset = 0.25,
    maxDuration,
  } = {}) {
    this.delay = delay;
    this.delayOffset = delayOffset;
    this.maxDuration = maxDuration;

    this.numberAttempts = 0;
    this.initiatedAt = null;
  }

  /**
   * Backoff Algo is `(d * nt) + o`, where:
   *
   * d - the current delay value
   * nt - the current number of attempts
   * o - a random offset which is a random percentage of the current delay value
   */
  calculateDelay() {
    const offset = parseInt(Math.random() * (this.delay * this.delayOffset), 10);
    return (this.delay * this.numberAttempts) + offset;
  }

  next() {
    const delay = this.calculateDelay();
    this.numberAttempts += 1;

    if (!this.initiatedAt) {
      this.initiatedAt = Date.now();
    }

    if (this.maxDuration) {
      const endsAt = this.initiatedAt + this.maxDuration;
      if (Date.now() >= endsAt) {
        return undefined;
      }
    }

    return delay;
  }

  reset() {
    this.numberAttempts = 0;
    this.initiatedAt = null;
  }
}
