import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = Record<string, Record<string, string>>;

const initialState: State = {};

const translationsReducer: AppReducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCALE_MESSAGES_SUCCESS:
      return update(state, {
        [action.params.locale]: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
};

export default translationsReducer;
