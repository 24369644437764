import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as fetchReducer } from '@flowio/redux-fetch';
import { reducer as formReducer } from 'redux-form';
import optimist from 'redux-optimist';

import { RootState, RootAction } from './types';
import { reducer as cart } from './cart';
import { reducer as checkout } from './checkout';
import { reducer as optin } from './optin';
import { reducer as flow } from './flow';
import { reducer as shopify } from './shopify';
import { reducer as reference } from './reference';
import { reducer as application } from './application';
import { reducer as content } from './content';
import { reducer as intl } from './intl';

export const rootReducer = optimist<RootState>(
  combineReducers<RootState, RootAction>({
    application,
    cart,
    checkout,
    content,
    fetch: fetchReducer,
    flow,
    form: formReducer,
    intl,
    optin,
    reference,
    routing: routerReducer,
    shopify,
  }),
);

export default rootReducer;
