import React from 'react';
import PageError from '../page-error';

const displayName = 'NotFound';

// TODO: Add translation for hard-coded messages.
const NotFound = () => (
  <PageError>
    This is not the page you are looking for.
    <br />
    Please go back and try again.
  </PageError>
);

NotFound.displayName = displayName;

export default NotFound;
