import { MapStateToProps, MapDispatchToProps, connect } from 'react-redux';

import { StateProps, DispatchProps, OwnProps } from '../types';
import { RootState } from '../../../store/types';
import { getOrganization } from '../../../store/flow';
import {
  getOrderNumber,
  submitCoupon,
  getOrderDiscounts,
  removePromotionCodeAndUpdatePaymentMethods,
} from '../../../store/checkout';
import MobileDiscountFormV2 from '../components/mobile-discount-form-v2';

const mapStateToProps: MapStateToProps<
StateProps,
OwnProps,
RootState
> = (state) => ({
  discounts: getOrderDiscounts(state),
  organizationId: getOrganization(state),
  orderNumber: getOrderNumber(state),
});

const mapDispatchToProps: MapDispatchToProps<
DispatchProps,
OwnProps
> = (dispatch) => ({
  onRemove(formData): Promise<void> {
    return dispatch(removePromotionCodeAndUpdatePaymentMethods(
      formData.organizationId,
      formData.orderNumber,
      formData.discountCode,
    ));
  },
  onSubmit(formData): Promise<void> {
    return dispatch(submitCoupon({
      orderNumber: formData.orderNumber,
      organizationId: formData.organizationId,
      promotionCode: formData.discountCode,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileDiscountFormV2);
