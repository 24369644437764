import BemHelper from '@flowio/bem-helper';
import React from 'react';
import ContentLabel from '../content-label';

interface Props {
  className?: string;
  content: io.flow.internal.v0.models.Href;
}

const bem = new BemHelper('href');

const Href: React.FunctionComponent<Props> = ({
  className,
  content,
}) => (
  <a href={content.url} className={bem.block(className)}>
    <ContentLabel content={content.label} />
  </a>
);

Href.displayName = 'Href';

export default Href;
