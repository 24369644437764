import React from 'react';
import SvgIcon from '../svg-icon';

const Dash = () => (
  <SvgIcon viewBox="0 0 8 16">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="0 7 0 9 8 9 8 7" />
    </g>
  </SvgIcon>
);

Dash.displayName = 'Dash';

export default Dash;
