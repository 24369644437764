import { Action, State } from './types';

export default function reducer(
  previousState: State,
  action: Action,
): State {
  switch (action.type) {
    case 'REMOVE_DISCOUNT_CODE_REQUEST':
      return {
        ...previousState,
        submitting: true,
      };
    case 'REMOVE_DISCOUNT_CODE_FAILURE':
    case 'REMOVE_DISCOUNT_CODE_SUCCESS':
      return {
        ...previousState,
        submitting: false,
      };
    case 'SET_DISCOUNT_CODE':
      return {
        ...previousState,
        discountCode: action.payload,
        submitError: undefined,
      };
    case 'SUBMIT_DISCOUNT_CODE_FAILURE':
      return {
        ...previousState,
        submitting: false,
        submitError: action.payload,
        submitFailed: true,
      };
    case 'SUBMIT_DISCOUNT_CODE_REQUEST':
      return {
        ...previousState,
        submitError: undefined,
        submitFailed: false,
        submitSucceeded: false,
        submitting: true,
      };
    case 'SUBMIT_DISCOUNT_CODE_SUCCESS':
      return {
        ...previousState,
        discountCode: '',
        submitSucceeded: true,
        submitting: false,
      };
    default:
      return previousState;
  }
}
