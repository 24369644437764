import { FormattedMessage } from 'react-intl';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

import { usePrevious } from '../../hooks';
import Divider from '../divider';
import PaymentMethodIssuerOption from './payment-method-issuer-option';
import { trackHeapEvent } from '../../utilities/heap';

if (process.browser) {
  require('./payment-method-issuer.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('payment-method-issuer');

const PaymentMethodIssuer = ({
  input,
  meta,
  paymentMethod,
  paymentMethodIssuers,
}) => {
  const { touched, error, warning } = meta;

  let errorText = error || warning;

  if (!touched) {
    errorText = undefined;
  }

  const previousErrorText = usePrevious(errorText);

  React.useEffect(() => {
    if (errorText != null && errorText !== previousErrorText) {
      trackHeapEvent('field_validation', {
        error_message: typeof errorText === 'string' ? errorText : undefined,
        field: input.name,
      });
    }
  }, [errorText, previousErrorText]);

  return (
    <div className={bem.block()}>
      <div className={bem.element('prompt')}>
        <FormattedMessage
          id="checkout_payment_method_issuer_selection"
          description="A message used to instruct customer to select an issuing bank for the selected alternate payment method"
          defaultMessage="Please select your bank"
        />
      </div>
      <div className={bem.element('option-group')}>
        {map(paymentMethodIssuers, (paymentMethodIssuer) => (
          <PaymentMethodIssuerOption
            checked={input.value === paymentMethodIssuer.id}
            key={paymentMethodIssuer.id}
            name={input.name}
            onBlur={input.onBlur}
            onChange={input.onChange}
            paymentMethod={paymentMethod}
            paymentMethodIssuer={paymentMethodIssuer}
            value={paymentMethodIssuer.id}
          />
        ))}
      </div>
      {errorText && (
        <div className={bem.element('error-text')}>
          {errorText}
        </div>
      )}
      <Divider />
      <div className={bem.element('footnote')}>
        <FormattedMessage
          id="checkout_payment_method_issuer_guidance"
          description="A message used to guide customers on the next steps after an issuing bank has been selected for an alternate payment method"
          defaultMessage="You will be redirected to the selected bank webpage to complete the transaction."
        />
      </div>
    </div>
  );
};

PaymentMethodIssuer.displayName = 'PaymentMethodIssuer';

PaymentMethodIssuer.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  paymentMethod: ApiPropTypes.paymentMethod.isRequired,
  paymentMethodIssuers: PropTypes.arrayOf(ApiPropTypes.paymentMethodIssuer).isRequired,
};

export default PaymentMethodIssuer;
