import isEmpty from 'lodash/isEmpty';
import update from 'immutability-helper';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';
import type { CheckoutEntitiesVrnState } from '../types';

const initialState: CheckoutEntitiesVrnState = {
  isVrnVisible: false,
  isValidVrn: false,
  showInvalidVrnMessage: false,
  unableToValidate: false,
};

const vrnCheckReducer: AppReducer<CheckoutEntitiesVrnState> = (
  previousState = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.UPDATE_VRN_VISIBILITY: {
      return update(previousState, {
        isVrnVisible: {
          $set: action.payload.isVisible,
        },
      });
    }
    case actionTypes.CHECK_VRN_NUMBER_REQUEST: {
      return update(previousState, {
        isValidVrn: {
          $set: false,
        },
        showInvalidVrnMessage: {
          $set: false,
        },
        unableToValidate: {
          $set: false,
        },
      });
    }
    case actionTypes.CHECK_VRN_NUMBER_SUCCESS: {
      const hasVrn = !isEmpty(action.meta.vrn);
      const unableToValidate = action.payload.order?.tax_registration?.result === 'unable_to_validate';
      const isValidVrn = action.payload.order?.tax_registration?.result === 'valid';

      let showInvalidVrnMessage = false;

      // Only want to show a message if the request had a VRN and the value was not valid
      // We need to do this because when we want to 'clear' out the vrn on an order we need to pass
      // No number, and obviously don't want to show an error message when that happens
      if (hasVrn) {
        showInvalidVrnMessage = !isValidVrn;
      }

      // To avoid displaying double messages.
      if (unableToValidate) {
        showInvalidVrnMessage = false;
      }

      return update(previousState, {
        isValidVrn: {
          $set: isValidVrn,
        },
        showInvalidVrnMessage: {
          $set: showInvalidVrnMessage,
        },
        unableToValidate: {
          $set: unableToValidate,
        },
      });
    }
    case actionTypes.CHECK_VRN_NUMBER_FAILURE: {
      const hasCompanyName = !isEmpty(action.meta.companyName);
      const hasVrn = !isEmpty(action.meta.vrn);
      return update(previousState, {
        isValidVrn: {
          $set: false,
        },
        showInvalidVrnMessage: {
          $set: false,
        },
        unableToValidate: {
          $set: hasCompanyName && hasVrn,
        },
      });
    }
    case actionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS: {
      return update(previousState, {
        isValidVrn: {
          $set: false,
        },
        showInvalidVrnMessage: {
          $set: false,
        },
        unableToValidate: {
          $set: false,
        },
      });
    }
    default: {
      return previousState;
    }
  }
};

export default vrnCheckReducer;
