// @ts-check

import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import get from 'lodash/get';
import identity from 'lodash/identity';
import values from 'lodash/values';

import ReadyState from '../../../utilities/redux/ready-state';

/** @typedef {io.flow.v0.models.Country} Country */
/** @typedef {io.flow.v0.models.Province} Province */

/** @typedef {import('../../types').RootState} RootState */
/** @typedef {import('../types').ReferenceState} ReferenceState */

/**
 * Selects the reference slice from the application state
 */
export const getReferenceState = createSelector(
  identity,
  /** @type {(state: RootState) => ReferenceState} */
  (state) => get(state, 'reference'),
);

/**
 * Selects the administrative divisions indexed by identifier.
 */
export const getAdministrativeDivisions = createSelector(
  getReferenceState,
  /** @type {(state: ReferenceState) => Province[]} */
  (state) => get(state, 'entities.administrativeDivisions'),
);

/**
 * Selects the administrative divisions matching the specified country code.
 * @param {string} countryCode An ISO 3166 3 character country code.
 * @returns {(state: RootState) => Province[]} A function accepting the application state.
 */
export const getAdministrativeDivisionsByCountryCode = (countryCode) => createSelector(
  getAdministrativeDivisions,
  /** @type {(administrativeDivisions: Province[]) => Province[]} */
  (administrativeDivisions) => filter(values(administrativeDivisions), { country: countryCode }),
);

export const getCountries = createSelector(
  getReferenceState,
  /** @type {(state: ReferenceState) => Country[]} */
  (state) => get(state, 'entities.countries'),
);

export const getIsCountriesLoading = createSelector(
  getReferenceState,
  (state) => get(state, 'statuses.countries') === ReadyState.Loading,
);

export const getIsCountriesRejected = createSelector(
  getReferenceState,
  (state) => get(state, 'statuses.countries') === ReadyState.Rejected,
);

export const getIsCountriesFulfilled = createSelector(
  getReferenceState,
  (state) => get(state, 'statuses.countries') === ReadyState.Fulfilled,
);
