import { reset, SubmissionError } from 'redux-form';

import { getOrderNumber } from '../selectors';
import { getOrganization } from '../../flow/selectors';
import addLoyaltyRewards from './add-loyalty-rewards';
import fetchOrder from './fetch-order';
import FormName from '../../../constants/form-name';
import checkHttpStatus from '../../../utilities/check-http-status';
import { showLoadingIndicator, hideLoadingIndicator } from '../../application/actions';

export default function submitLoyaltyRewards(values) {
  return function submitLoyaltyRewardsSideEffects(dispatch, getState) {
    const state = getState();
    const orderNumber = getOrderNumber(state);
    const organization = getOrganization(state);
    const formName = FormName.LOYALTY_FORM;

    dispatch(showLoadingIndicator());

    return dispatch(addLoyaltyRewards(organization, orderNumber, { values }))
      .then(checkHttpStatus)
      .then(() => dispatch(fetchOrder(organization, orderNumber)))
      .then(checkHttpStatus)
      .then(dispatch(reset(formName)))
      .then(() => dispatch(hideLoadingIndicator()))
      .catch((error) => {
        dispatch(hideLoadingIndicator());
        switch (error.name) {
          case 'HttpStatusError':
            throw new SubmissionError({
              _error: error.response.result,
            });
          default:
            throw error;
        }
      });
  };
}
