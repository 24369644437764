import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getOrderDenormalized,
  getItemContentsMap,
  getIsTaxIncluded,
  getIsDutyIncluded,
  isForceEnableShopifyDiscountsEnabled,
  handleRemoveGiftCard,
  removePromotionCodeAndUpdatePaymentMethods,
} from '../../../store/checkout';
import { StateProps, DispatchProps } from '../types';
import { RootState } from '../../../store/types';
import { getIsShopifyIntegration, getOrganization } from '../../../store/flow';
import MobileOrderSummaryV2 from '../components/mobile-order-summary-v2';
import { isConfirmationStep } from '../../../store/navigation';

const mapStateToProps: MapStateToProps<
StateProps,
void,
RootState
> = (state) => ({
  forceEnableShopifyDiscounts: isForceEnableShopifyDiscountsEnabled(state),
  isConfirmationStep: isConfirmationStep(state),
  isDutyIncluded: getIsDutyIncluded(state),
  isShopifyIntegration: getIsShopifyIntegration(state),
  isTaxIncluded: getIsTaxIncluded(state),
  itemContentsMap: getItemContentsMap(state),
  order: getOrderDenormalized(state),
  organizationId: getOrganization(state),
});

const mapDispatchToProps: MapDispatchToProps<
DispatchProps,
void
> = (dispatch) => bindActionCreators({
  onRemoveCreditPayment: handleRemoveGiftCard,
  onRemoveDiscountCode: removePromotionCodeAndUpdatePaymentMethods,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrderSummaryV2);
