/* eslint-disable global-require,no-underscore-dangle */

import injectJavaScript from '@flowio/browser-helpers/lib/injectJavaScript';
import loadStyleSheet from '@flowio/browser-helpers/lib/loadStyleSheet';
import {
  createErrorsPlugin,
  createEventTimingPlugin,
  createLargestContentfulPaintPlugin,
  createLayoutInstabilityPlugin,
  createNavigationTimingPlugin,
  createNavigatorPlugin,
  createPaintTimingPlugin, createTracker, createUserTimingPlugin,
} from '@flowio/browser-metrics';
import { createHistory } from 'history';
import forEach from 'lodash/forEach';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { useRouterHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { createApiInternalClient } from '../common/services/api-internal-v2';
import { createApiClient } from '../common/services/api-v2';
import createFetch from '../common/utilities/enveloped-fetch-v2';
import configureStore from './configure-store';
import initExternalClient from './external';
import { init as agnosticTagsInit } from './external/agnostic-tags';
import {
  fetchCheckout,
  fetchOrganizationConfig,
  getCheckoutId, getCheckoutOrganization, getIsCheckoutPreloaded, getIsCheckoutResourceEnabled,
  setCheckoutPreloaded,
} from './store/checkout';
import {
  getCheckoutScriptUrl,
  getOrganization, getOrganizationScripts, getOverrideThemeUrl,
} from './store/flow';
import { getLocale } from './store/intl';
import { getPathPrefix } from './store/shopify';
import { init as sessionInit } from './utilities/beacon/beacon';
import debug from './utilities/development/debug';
import developmentSetup from './utilities/development/setup';
import events, { resetPageViewEvents } from './utilities/events';
import ExponentialBackoff from './utilities/exponential-backoff';
import getFeatureKeys from './utilities/get-feature-keys';
import {
  configureHeap, installHeap,
} from './utilities/heap';
import checkoutPlugin from './utilities/real-user-monitoring/plugins/checkout-plugin';
import sessionPlugin from './utilities/real-user-monitoring/plugins/session-plugin';
import { getInstance as getRollbarInstance } from './utilities/redux/rollbar';
import actionTypes from './store/constants/action-types';
import getDeviceDetails from '../common/utilities/get-device-details';
import { loadForter, listenForForterToken } from './utilities/forter/forter';

const getPreloadedState = () => window.__PRELOADED_STATE__;

const rootElement = document.getElementById('react-markup');
const html = document.querySelector('html');
const pathPrefix = getPathPrefix(getPreloadedState());

const browserHistory = useRouterHistory(createHistory)({
  basename: pathPrefix,
});

const fetch = createFetch();
const apiClient = createApiClient({ fetch });
const apiInternalClient = createApiInternalClient({ fetch });
const rollbar = getRollbarInstance();
const store = configureStore({
  apiClient,
  apiInternalClient,
  history: browserHistory,
  initialState: getPreloadedState(),
  rollbar,
});

const history = syncHistoryWithStore(browserHistory, store);
history.listen(() => {
  resetPageViewEvents();
});

try {
  createTracker({
    reporters: [],
    plugins: [
      checkoutPlugin,
      sessionPlugin,
      createErrorsPlugin(),
      createEventTimingPlugin(),
      createLargestContentfulPaintPlugin(),
      createLayoutInstabilityPlugin(),
      createNavigationTimingPlugin(),
      createNavigatorPlugin(),
      createPaintTimingPlugin(),
      createUserTimingPlugin(),
    ],
  });
} catch (error) {
  // Do nothing
}

/**
 * @param {string} locale
 */
function initLangAttribute(locale) {
  if (html && html.setAttribute) {
    html.setAttribute('lang', locale);
  }
}

function render() {
  // eslint-disable-next-line global-require
  const Root = require('./root').default;

  ReactDOM.render(
    <AppContainer>
      <Root
        apiClient={apiClient}
        apiInternalClient={apiInternalClient}
        history={history}
        rollbar={rollbar}
        store={store}
      />
    </AppContainer>,
    rootElement,
  );

  /**
   * An event fired after the client side application is hydrated.
   */
  events.trigger('afterBootstrap');
}

/**
 * Manual check to see when ShopifyJS has initialized. It will keep checking for up to 3 seconds
 * before executing the callback function anyway. This guarantees that with ShopifyJS present,
 * checkout will initialize within 3 seconds.
 *
 * @param {*} callback - function to execute once ShopifyJS is ready.
 */
function shopifyJSReady(callback) {
  const shopifyJSBackoff = new ExponentialBackoff({ maxDuration: 3000 });
  const check = () => {
    if (window.Flow && window.Flow.initialized) {
      callback();
      return;
    }

    const next = shopifyJSBackoff.next();
    if (next) {
      setTimeout(check, next);
    } else {
      callback();
    }
  };

  check();
}

function handleSessionReady() {
  render();
}

/**
 * Set up the session and render checkout.
 *
 * The session will not refresh if it's already been established
 * @param {string} organization
 */
function setup(organization) {
  // Account for ShopifyJS session initialization. We cannot have a race condition between checkout
  // and ShopifyJS creating sessions.
  if (window.Flow) {
    debug('ShopifyJS detected. Waiting for ready event to setup CheckoutUI.');
    shopifyJSReady(() => {
      debug('ShopifyJS is ready. Setting up CheckoutUI.');
      sessionInit({
        organization,
        onReady: handleSessionReady,
      });
    });
  } else {
    debug('Setting up CheckoutUI.');
    sessionInit({
      organization,
      onReady: handleSessionReady,
    });
  }
}

function initialize() {
  const state = store.getState();
  const checkoutId = getCheckoutId(state);
  const checkoutOrg = getCheckoutOrganization(state);
  /* Checkout should in most cases be proloaded at this point by the server, however in
   * the Shopify integration, the auth cookies will not be available on the server,
   * so we have to defer to doing the API request on the client. */
  const isCheckoutPreloaded = getIsCheckoutPreloaded(state);
  const isCheckoutResourceEnabled = getIsCheckoutResourceEnabled(state);
  /*    If the new /checkouts resource is not enabled
   * OR if checkout resouce is already preloaded from the server
   * OR if checkoutId does not exist (i.e. /checkouts/create) */
  const skipFetchCheckout = !isCheckoutResourceEnabled || isCheckoutPreloaded || !checkoutId;

  listenForForterToken((token) => {
    store.dispatch({ type: actionTypes.UPDATE_FRAUD_REFERENCES, payload: { forter: token } });
  });
  const checkoutInitPromise = skipFetchCheckout
    ? Promise.resolve()
    // Fetch Checkout id not already loaded on server (Shopify embedded checkout.)
    : store.dispatch(fetchCheckout(checkoutId, { feature_key: getFeatureKeys() }))
      // Set Checkout as preloaded so as fetch in Checkout step does not attempte the same.
      .then(() => {
        store.dispatch(setCheckoutPreloaded());
      });

  checkoutInitPromise
    .then(() => {
      const updatedState = store.getState();
      const organization = getOrganization(updatedState);
      const locale = getLocale(updatedState);
      // Setup any development related hooks
      developmentSetup(updatedState);
      installHeap();
      configureHeap(events, store);
      initLangAttribute(locale);
      initExternalClient(organization, store);
      agnosticTagsInit(window.flow.checkout);
      setup(organization);
      loadForter(checkoutOrg);
      // set device details
      store.dispatch({ type: actionTypes.ADD_DEVICE_DETAILS, payload: getDeviceDetails() });
      // When checkout hasn't preloaded we want to inject clients css and js directly from config file
      if (!isCheckoutPreloaded) {
        // If the checkout fetch was skipped above we need to explicitly load the config
        const organizationConfigPromise = skipFetchCheckout
          ? store.dispatch(fetchOrganizationConfig(organization, getPathPrefix(updatedState)))
          : Promise.resolve();

        organizationConfigPromise.then(() => {
          const overrideThemeUrl = getOverrideThemeUrl(store.getState());
          const checkoutScriptUrl = getCheckoutScriptUrl(store.getState());

          if (overrideThemeUrl) {
            loadStyleSheet(overrideThemeUrl);
          }

          if (checkoutScriptUrl) {
            injectJavaScript(checkoutScriptUrl);
          }

          forEach(getOrganizationScripts(store.getState()), (script) => {
            if (script.src !== checkoutScriptUrl) {
              injectJavaScript(script.src);
            }
          });
        });
      } else {
        // Add client scripts to page
        forEach(getOrganizationScripts(store.getState()), (script) => injectJavaScript(script.src));
      }
    });
}

initialize();

if (module.hot) {
  module.hot.accept('./root', () => {
    // Workaround to support hot swapping of routes.
    // Follow this thread to understand the reasoning behind it.
    // https://github.com/ReactTraining/react-router/issues/2182
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(rootElement);
      render();
    });
  });
}
