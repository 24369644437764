import some from 'lodash/some';
import includes from 'lodash/includes';
import PromptOptions from '../constants/prompt-options';

/**
 * Returns whether whether consent is granted for an optin prompt by default.
 * @param {OptinPrompt}
 * @returns {Boolean}
 */
export default function isOptinPromptConsent(optinPrompt) {
  return some([
    PromptOptions.NOTICE_ONLY,
    PromptOptions.CONSENT_BY_DEFAULT,
  ], (promptOption) => includes(optinPrompt.options, promptOption));
}
