import defaultTo from 'lodash/defaultTo';
import actionTypes from '../../constants/action-types';
import type { AppReducer } from '../../types';

type State = io.flow.internal.v0.models.CheckoutContentSummary | null;

const initialState: State = null;

const contentReducer: AppReducer<State> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CHECKOUT_BUNDLE_SUCCESS:
      return defaultTo(action.payload.checkout, state);
    case actionTypes.CREATE_CHECKOUT_ORDER_SUCCESS:
    case actionTypes.FETCH_CHECKOUT_SUCCESS:
      return defaultTo(action.payload.content.global, state);
    case actionTypes.FETCH_CONTENT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default contentReducer;
