import type { PublicKeysGetLatestResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<LegacyResponseWithLegacyError<PublicKeysGetLatestResponse>>;

const fetchPublicKeyRequest = (): RootAction => ({
  type: actionTypes.FETCH_PUBLIC_KEY_REQUEST,
});

const fetchPublicKeySuccess = (
  publicKey: io.flow.v0.models.PublicKey,
): RootAction => ({
  type: actionTypes.FETCH_PUBLIC_KEY_SUCCESS,
  payload: publicKey,
});

const fetchPublicKeyFailure = (
  error: LegacyError,
): RootAction => ({
  type: actionTypes.FETCH_PUBLIC_KEY_FAILURE,
  payload: error,
});

const fetchPublicKey = (
  organizationId: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(fetchPublicKeyRequest());
  return extra.api.publicKeys.getLatest({
    organization: organizationId,
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(fetchPublicKeySuccess(response.result));
          break;
        case 404:
          dispatch(fetchPublicKeyFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }
      return response;
    });
};

export default fetchPublicKey;
