import filter from 'lodash/filter';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

/**
 * @param {io.flow.v0.models.CustomerAddressBookContact[]} addressBooks
 * @param {io.flow.v0.enums.CustomerAddressType} type
 */
export default function getPreferredUserAddress(addressBooks, type) {
  return filter(addressBooks, (book) => {
    const addressPreferences = get(book, 'address_preferences', []);
    return findIndex(addressPreferences, { type }) > -1;
  });
}
