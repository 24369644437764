/* eslint-disable react/no-array-index-key */

import { FormattedMessage } from 'react-intl';
import BemHelper from '@flowio/bem-helper';
import React from 'react';
import { isDiscountsEnabled } from '../../../store/checkout';
import { Props } from '../types';
import Button from '../../button';
import ChevronLeft from '../../svg-icons/chevron-left';
import Chip from '../../chip';
import Collapse from '../../collapse';
import CouponForm from '../../../containers/coupon-form';
import FeatureToggle from '../../feature-toggle';
import { LEARN_MORE_CONTENT } from '../../../../common/constants/feature-keys';
import LearnMoreContent from '../../learn-more-content';
import OrderPrices from '../../order-prices';
import MobileOrderDeliveryItems from './mobile-order-delivery-items';
import './mobile-order-summary-v2.css';

const bem = new BemHelper('flow-mobile-order-summary');

function getOrderDiscounts(
  order: io.flow.v0.models.Order,
): io.flow.v0.models.OrderPriceDetailComponent[] {
  return order.prices
    .filter((price) => price.key === 'discount')
    .map((discount) => discount.components)
    .reduce((components, component) => components.concat(component), []);
}

const MobileOrderSummaryV2: React.FunctionComponent<Props> = ({
  forceEnableShopifyDiscounts,
  itemContentsMap,
  isConfirmationStep = false,
  isDutyIncluded = false,
  isTaxIncluded = false,
  isShopifyIntegration = false,
  onRemoveDiscountCode,
  onRemoveCreditPayment,
  order,
  organizationId,
}) => {
  const orderNumber = order.number;

  const [isVisible, setIsVisible] = React.useState(false);

  const giftCards = order.payments != null
    ? order.payments.filter((payment) => payment.type === 'credit')
    : [];

  const numberOfItems = order.items.reduce((count, item) => count + item.quantity, 0);

  const toggleVisibility = React.useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const removeDiscountCode = React.useCallback((discountCode?: string) => {
    onRemoveDiscountCode(organizationId, orderNumber, discountCode);
  }, [organizationId, orderNumber]);

  let totalLabel = order.total.label;

  if (!isConfirmationStep && order.balance != null && order.total.amount > order.balance.amount) {
    totalLabel = order.balance.label;
  }

  return (
    <section className={bem.block('collapsed-order-summary')}>
      <Collapse in={isVisible}>
        <div className={bem.element('collapse')}>
          <div className={bem.element('header')}>
            <FormattedMessage
              id="checkout_order_summary_item"
              description="A text stating 'Item'"
              defaultMessage="{numberOfItems, plural, one {# Item} other {# Items}}"
              values={{ numberOfItems }}
            />
            <Button type="button" size="small" link onClick={toggleVisibility}>
              <FormattedMessage
                id="checkout_order_summary_hide_details"
                description="A text for CTA to collapse order summary"
                defaultMessage="Hide Details"
              />
            </Button>
          </div>
          <div className={bem.element('deliveries')}>
            <MobileOrderDeliveryItems
              order={order}
              itemContentsMap={itemContentsMap}
              isShopifyIntegration={isShopifyIntegration}
            />
          </div>
          {!isConfirmationStep && (
            isShopifyIntegration
            || isDiscountsEnabled
            || forceEnableShopifyDiscounts
          ) && (
            <div className={bem.element('discounts')}>
              <CouponForm mobile />
            </div>
          )}
          <div className={bem.element('prices')}>
            <OrderPrices
              isDiscountClearable={!isConfirmationStep}
              isDutyIncluded={isDutyIncluded}
              isTaxIncluded={isTaxIncluded}
              onRequestRemoveDiscount={removeDiscountCode}
              prices={order.prices}
              discounts={getOrderDiscounts(order)}
              promotionCode={order.attributes.shopify_discount_code}
            />
            {!isConfirmationStep && giftCards.length > 0 && (
              <dl className={bem.element('payment-list')}>
                {giftCards.map((giftCard) => (
                  <>
                    <dt className={bem.element('payment-label')}>
                      <Chip
                        size="small"
                        color="light"
                        dismissible
                        text={giftCard.description}
                        onRequestDelete={(): void => {
                          onRemoveCreditPayment(giftCard.reference);
                        }}
                      />
                    </dt>
                    <dd className={bem.element('payment-value')}>
                      {`- ${giftCard.total.label}`}
                    </dd>
                  </>
                ))}
              </dl>
            )}
          </div>
        </div>
      </Collapse>
      <div
        role="button"
        tabIndex={0}
        className={bem.element('footer')}
        aria-label="View order summary"
        onClick={toggleVisibility}
        onKeyPress={(event): void => {
          if (event.which === 13 || event.keyCode === 13) {
            toggleVisibility();
          }
        }}
      >
        <dl className={bem.element('total')}>
          <dt className={bem.element('total-label')}>
            <FormattedMessage
              id="checkout_order_summary_line_total"
              description="Text identifying the total amount of the order"
              defaultMessage="Total"
            />
            <ChevronLeft className={bem.element('chevron', { expanded: isVisible })} />
          </dt>
          <dd className={bem.element('total-value')}>
            {totalLabel}
          </dd>
        </dl>
        {isConfirmationStep && order.payments != null && order.payments.length > 0 && (
          <dl className={bem.element('payment-list')}>
            {order.payments.map((payment) => (
              <>
                <dt className={bem.element('payment-label')}>
                  {payment.description}
                </dt>
                <dd className={bem.element('payment-value')}>
                  {`- ${payment.total.label}`}
                </dd>
              </>
            ))}
          </dl>
        )}
      </div>
      <FeatureToggle featureKey={LEARN_MORE_CONTENT}>
        <LearnMoreContent />
      </FeatureToggle>
    </section>
  );
};

MobileOrderSummaryV2.displayName = 'MobileOrderSummaryV2';

export default MobileOrderSummaryV2;
