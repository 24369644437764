import { safeInvoke } from '@flowio/react-helpers';
import React from 'react';
import KlarnaPaymentCategory from './klarna-payment-category';
import RadioPanelGroup from '../radio-panel-group';
import RadioPanel from '../radio-panel';

type Props = {
  categories: io.flow.internal.v0.models.KlarnaPaymentMethodCategory[];
  children?: never;
  onChange?: (category: string) => void;
  onError?: (error: any) => void;
  onLoaded?: () => void;
  selectedCategory: string;
};

const KlarnaPayment: React.FC<Props> = ({
  categories,
  onChange,
  onError,
  onLoaded,
  selectedCategory,
}) => {
  const handleChange = React.useCallback((event: Event, value: string) => {
    safeInvoke(onChange, value);
  }, [onChange]);

  return (
    <RadioPanelGroup
      onChange={handleChange}
      name="klarna_payment_method_category"
      value={selectedCategory}
    >
      {categories.map((category) => (
        <RadioPanel
          key={category.id}
          name="klarna_payment_method_category"
          label={category.name || category.id}
          value={category.id}
        >
          <KlarnaPaymentCategory
            category={category}
            onError={onError}
            onLoaded={onLoaded}
          />
        </RadioPanel>
      ))}
    </RadioPanelGroup>
  );
};

export default KlarnaPayment;
