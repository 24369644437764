import lineItemFormConverter from './line-item-form-converter';

function isValidDestination(
  orderAddress: io.flow.v0.models.OrderAddress,
): boolean {
  return orderAddress.streets != null
    && orderAddress.city != null
    && orderAddress.country != null
    && orderAddress.contact != null
    && orderAddress.contact.name != null
    && orderAddress.contact.name.first != null
    && orderAddress.contact.name.last != null;
}

function fromOrder(
  order: io.flow.v0.models.Order,
): io.flow.v0.models.OrderPutForm {
  return {
    attributes: order.attributes,
    // Cannot be inferred from the order model
    authorization_keys: undefined,
    customer: order.customer,
    delivered_duty: order.delivered_duty,
    // TODO: Checking for nil because in some unit tests a proper order is not
    // declared, which violates the expected type declarations.
    destination: order.destination != null && isValidDestination(order.destination)
      ? order.destination
      : undefined,
    // Cannot be inferred from the order model
    discount: undefined,
    // TODO: Checking for nil because in some unit tests a proper order is not
    // declared, which violates the expected type declarations.
    items: order.items != null
      ? order.items.map(lineItemFormConverter.fromLocalizedLineItem)
      : [],
    selections: order.selections,
    device_details: order.device_details,
    // Chose to err on the side of caution and not include additional attributes
    // available on the OrderPutForm since they weren't used prior to
    // refactoring this utility. However, you may find in the future that
    // these attributes can be introduced without issues.
  };
}

export default {
  fromOrder,
};
