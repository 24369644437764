import { createStructuredSelector } from 'reselect';
import { CancellationTokenSource } from '@flowio/cancellation-token';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  allowInvoiceAddress,
  allowVRN,
  getIsVrnValidating,
  isVrnValid,
  showInvalidVrnMessage,
  unableToValidateVrn,
  getOrderNumber,
  getInvoiceAddress,
} from '../store/checkout/selectors';
import Address from '../components/address';
import { checkVrnAndUpdateOrder, updateInvoiceAddress } from '../store/checkout/actions';
import { getOrganization } from '../store/flow/selectors';

/** @type {CancellationTokenSource | undefined} */
let cancellationTokenSource;

const mapDispatchToProps = (dispatch) => ({
  onVrnCheck(
    organization,
    orderNumber,
    companyName,
    vrn,
    countryCode,
    isAllowInvoiceAddress,
    hasInvoice,
  ) {
    if (cancellationTokenSource) cancellationTokenSource.cancel();
    cancellationTokenSource = new CancellationTokenSource();
    return Promise.resolve()
      .then(() => {
        // Don't want to make both calls if an invoice address already exists
        if (isAllowInvoiceAddress && !hasInvoice) {
          return dispatch(updateInvoiceAddress(organization, orderNumber, {
            company: companyName,
            country: countryCode,
          }));
        }

        return Promise.resolve();
      })
      .then(() => {
        if (vrn != null && companyName != null) {
          return dispatch(checkVrnAndUpdateOrder({
            organizationId: organization,
            orderNumber,
            vrn,
            companyName,
            cancellationToken: cancellationTokenSource.token,
          }));
        }

        return Promise.resolve();
      });
  },
});

// TODO: Since this component is connected we could simplify the props injected
// from parent components. For example, the address configuration, countries,
// form field values, etc. can be selected from the state here.
const mapStateToProps = createStructuredSelector({
  allowInvoiceAddress,
  allowVRN,
  invoice: getInvoiceAddress,
  isVrnValid,
  isVrnValidating: getIsVrnValidating,
  orderNumber: getOrderNumber,
  organization: getOrganization,
  showInvalidVrnMessage,
  unableToValidateVrn,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Address);
