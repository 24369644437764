import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ApiPropTypes from '@flowio/api-prop-types';
import BemHelper from '@flowio/bem-helper';
import React from 'react';

import { MOBILE_UX_SPACING } from '../../../common/constants/feature-keys';
import FeatureToggle from '../feature-toggle';
import { getOrderDeliveredDuty } from '../../store/checkout/selectors';
import { Grid, Row, Column } from '../grid';
import DeliveredDuties from '../../constants/delivered-duties';
import DeliveredDutySetting from '../delivered-duty-setting';

if (process.browser) {
  require('./landed-cost-settings.css'); // eslint-disable-line
}

const bem = new BemHelper('landed-cost-settings');

const LandedCostSettings = ({ deliveredDuty }) => (
  <FeatureToggle
    featureKey={MOBILE_UX_SPACING}
    render={({ isFeatureEnabled }) => (
      <Grid parent={isFeatureEnabled}>
        <Row>
          <Column xs={12}>
            <div className={bem.block()}>
              {deliveredDuty === DeliveredDuties.Paid && (
                <p className={bem.element('description')}>
                  <strong>
                    <FormattedMessage
                      id="checkout_landed_cost_paid_prefix"
                      description="Text prefixing the explaination of the customer preference for paying duties with the order"
                      defaultMessage="Duties and Taxes Paid"
                    />
                    {': '}
                  </strong>
                  <FormattedMessage
                    id="checkout_landed_cost_paid_explanation"
                    description="An explanation of the customer preference for paying duties and taxes with the order"
                    defaultMessage="You will pay duties and taxes with your order, if applicable."
                  />
                </p>
              )}
              {deliveredDuty === DeliveredDuties.Unpaid && (
                <p className={bem.element('description')}>
                  <strong>
                    <FormattedMessage
                      id="checkout_landed_cost_unpaid_prefix"
                      description="Text prefixing the explaination of the customer preference for paying duties as they are incurred by local authorities"
                      defaultMessage="Duties and Taxes Unpaid"
                    />
                    {': '}
                  </strong>
                  <FormattedMessage
                    id="checkout_landed_cost_unpaid_explanation"
                    description="An explanation of the customer preference for being responsible for paying duties and taxes as they are applicable with local customs authorities"
                    defaultMessage="You will not pay duties and taxes with your order. Your items might be subject to duties and/or taxes by your local customs authority."
                  />
                </p>
              )}
              <DeliveredDutySetting />
            </div>
          </Column>
        </Row>
      </Grid>
    )}
  />
);

LandedCostSettings.displayName = 'LandedCostSettings';

LandedCostSettings.propTypes = {
  deliveredDuty: ApiPropTypes.deliveredDuty.isRequired,
};

const mapStateToProps = createStructuredSelector({
  deliveredDuty: getOrderDeliveredDuty,
});

export default connect(mapStateToProps)(LandedCostSettings);
