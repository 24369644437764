import { createSelector } from 'reselect';
import get from 'lodash/get';
import { RootState } from '../../types';
import { IntlState } from '../types';

const getIntlState = (state: RootState): IntlState => get(state, 'intl');

/**
 * Returns the detected locale for the user-agent formatted using IETF standards (e.g. en-US).
 */
export const getLocale = createSelector(
  getIntlState,
  (state) => get(state, 'locale'),
);

// Future-proofing if we ever use locale as is normally specifed as langauge-country .. i.e. en-US
export const getLanguage = createSelector(
  getLocale,
  (locale) => (typeof locale === 'string' ? locale.substr(0, 2) : undefined),
);

export const getTranslations = createSelector<
RootState, IntlState, IntlState['translations']
>(getIntlState, (state) => get(state, 'translations'));

export const getMessages = createSelector(
  getLocale,
  getTranslations,
  (locale, translations) => get(translations, locale),
);
