import { getCart, getIsCartEmpty } from '../store/cart';
import actionTypes from '../store/constants/action-types';

/**
 * We have an idea to add porcelain methods to Flow.js
 * like: Flow.triggerCartUpdated so that this code can call
 * Flow.set('triggerCartUpdated', cart) and not have to maintain list of
 * constants
 * @type {Object}
 */
const FlowJsConstants = {
  CART_UPDATED: 'cart.updated',
  CART_REMOVE_ITEM: 'cart.removeItem',
  CART_UPDATE_ITEM: 'cart.updateItem',
  CART_CLEAR: 'cart.clear',
};

function triggerEvent(key, data) {
  if (Flow && Flow.set) {
    Flow.set('triggerEvent', key, data);
  }
}

const triggerCartItemRemoval = (action, state) => {
  triggerEvent(FlowJsConstants.CART_REMOVE_ITEM, {
    variant_id: action.params.cartItemVariantId,
    cart: getCart(state),
  });
};

const triggerCartClearIfNeeded = (action, state) => {
  if (getIsCartEmpty(state)) {
    triggerEvent(FlowJsConstants.CART_CLEAR);
  }
};

const triggerCartItemUpdate = (action, state) => {
  triggerEvent(FlowJsConstants.CART_UPDATE_ITEM, {
    variant_id: action.params.cartItemVariantId,
    quantity: action.params.quantity,
    cart: getCart(state),
  });
};

const triggerCartUpdate = (action, state) => {
  triggerEvent(FlowJsConstants.CART_UPDATED, {
    cart: getCart(state),
  });
};

const flowEventEmitter = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  if (typeof Flow === 'undefined') return result;
  if (typeof Flow.set !== 'function') return result;

  switch (action.type) {
    case actionTypes.REMOVE_CART_ITEM_SUCCESS:
      triggerCartItemRemoval(action, state);
      triggerCartUpdate(action, state);
      triggerCartClearIfNeeded(action, state);
      break;
    case actionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS:
      triggerCartItemUpdate(action, state);
      triggerCartUpdate(action, state);
      break;
    default:
      break;
  }

  return result;
};

export default flowEventEmitter;
