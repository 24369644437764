const Steps = {
  None: 'StepNone',
  Cart: 'StepCart',
  ContactInfo: 'StepContactInfo',
  ShippingMethod: 'StepShippingMethod',
  PaymentInfo: 'StepPaymentInfo',
  Confirmation: 'StepConfirmation',
  Review: 'StepReview',
};

export default Steps;
