import { replace, RouterAction } from 'react-router-redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '../../types';

/**
 * If you REALLY need the state to be present for your code to work DO NOT
 * dispatch this action since the state will be omitted whenever an error
 * is caught. Generally, this error is thrown by session storage when `history`
 * attempts to persist the state locally and the storage is full.
 */
export default function safeReplace(
  pathname: string,
  state: object,
): ThunkAction<void, RootState, void, RouterAction> {
  return function safeReplaceEffects(dispatch): void {
    try {
      dispatch(replace({ pathname, state }));
    } catch (error) {
      dispatch(replace({ pathname }));
    }
  };
}
