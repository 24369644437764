import request from '@flowio/request';
import { getIsShopifyIntegration } from '../../flow/selectors';
import actionTypes from '../../constants/action-types';
import type { RootAction, ThunkResult } from '../../types';
import type { ShopifyCart } from '../types';
import type { Response } from '../../../../common/services/types';

type Result = Promise<Response<unknown> | void>;

const clearShopifyCartRequest = (): RootAction => ({
  type: actionTypes.CLEAR_SHOPIFY_CART_REQUEST,
});

const clearShopifyCartSuccess = (
  shopifyCart: ShopifyCart,
): RootAction => ({
  type: actionTypes.CLEAR_SHOPIFY_CART_SUCCESS,
  payload: shopifyCart,
});

const clearShopifyCartFailure = (): RootAction => ({
  type: actionTypes.CLEAR_SHOPIFY_CART_FAILURE,
});

/**
 * Clears the shopify cart, if the integratin is shopify
 */
const clearShopifyCart = (): ThunkResult<Result> => (
  dispatch,
  getState,
) => new Promise((resolve) => {
  if (!getIsShopifyIntegration(getState())) {
    resolve();
    return;
  }

  dispatch(clearShopifyCartRequest());

  request.anonymous({
    method: 'GET',
    url: '/cart/clear.js',
  }, (status, response) => {
    const ok = status >= 200 && status < 300;

    if (ok) {
      dispatch(clearShopifyCartSuccess(response as ShopifyCart));
    } else {
      dispatch(clearShopifyCartFailure());
    }

    resolve({ ok, status, result: response });
  });
});

export default clearShopifyCart;
