import { createStructuredSelector } from 'reselect';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, clearFields } from 'redux-form';

import {
  submitGiftCardV2, setGiftCardContentState,
} from '../store/checkout/actions';
import {
  getGiftCardRemoveError,
  getGiftCardError,
  getOrderNumber,
  getIsGiftCardContentOpen,
  getDidAddGiftCard,
} from '../store/checkout/selectors';
import FormName from '../constants/form-name';
import GiftCardV2Form from '../components/gift-card-v2-form/gift-card-v2-form';

function handleSubmitGiftCard() {
  return function handleSubmitGiftCardSideEffects(dispatch, getState) {
    const state = getState();
    const formSelector = formValueSelector(FormName.PAYMENT_INFORMATION);
    const number = formSelector(state, 'giftCard.giftCardV2Number');
    const pin = formSelector(state, 'giftCard.giftCardV2Pin');

    clearFields(FormName.PAYMENT_INFORMATION, false, false, ['giftCard.giftCardV2Number', 'giftCard.giftCardV2Pin']);
    return dispatch(submitGiftCardV2({ number, pin }));
  };
}

const mapStateToProps = createStructuredSelector({
  removeError: getGiftCardRemoveError,
  giftCardError: getGiftCardError,
  orderNumber: getOrderNumber,
  isOpen: getIsGiftCardContentOpen,
  didAddGiftCard: getDidAddGiftCard,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  handleSubmitGiftCard,
  handleHeaderClick: (isOpen) => setGiftCardContentState(isOpen),
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(GiftCardV2Form);
