import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';

import { fetchCountries, getCountries } from '../store/reference';
import { getOptinPrompts } from '../store/optin';
import { getContactInfoUrl, getShippingMethodUrl } from '../store/navigation';

import {
  getIsPaymentRequired,
  getOrderBillingAddress,
  getOrderDestination,
  getOrderTotalAmount,
  getOrderTotalCurrency,
  getPublicKey,
  getSelectedDeliveryOptions,
  isGiftCardEnabled,
  isLoyaltyEnabled,
  isPaymentInfoSummaryEnabled,
  getIsAdyen3dsDialogOpen,
  fetchCheckoutBundle,
  fetchGiftCards,
  fetchLoyaltyPrograms,
  fetchPaymentMethodParams,
  fetchPublicKey,
  fetchPriceConversion,
  redirectSubmittedOrder,
  syncReturnUrlAttribute,
  updatePaymentMethods,
  getIsInlineAuthorizationDialogOpen,
  getCheckoutOrganization,
  fetchApplePayAvailibility,
  getIsApplepayEnabled,
} from '../store/checkout';

import checkHttpStatus from '../utilities/check-http-status';
import withExternalEvents from '../components/with-external-events';
import PaymentInfoStep from '../components/payment-info-step';
import logInternalServerError from '../utilities/rollbar/log-internal-server-error';
import generateFeatureQuery from '../utilities/generate-feature-query';

const fetchAsyncState = (dispatch, getState, props) => {
  const { params } = props;
  const { organization, orderNumber } = params;

  return Promise.all([
    dispatch(fetchCheckoutBundle(organization, orderNumber, {
      feature_q: generateFeatureQuery(organization),
    })).then(checkHttpStatus),
    dispatch(fetchCountries()),
    dispatch(fetchPublicKey(organization)),
    dispatch(fetchPriceConversion()),
  ])
    .then(() => Promise.all([
      dispatch(fetchGiftCards(organization, orderNumber)),
      dispatch(fetchLoyaltyPrograms(organization, orderNumber)),
      dispatch(fetchPaymentMethodParams(organization, {
        order_number: orderNumber,
        amount: getOrderTotalAmount(getState()),
        currency: getOrderTotalCurrency(getState()),
        billingAddress: getOrderBillingAddress(getState()),
      })),
      dispatch(syncReturnUrlAttribute(organization)),
      dispatch(updatePaymentMethods(organization)),
      getIsApplepayEnabled(getState()) ? dispatch(fetchApplePayAvailibility()) : Promise.resolve(),
    ]))
    .then(() => dispatch(redirectSubmittedOrder()))
    .catch((error) => {
      logInternalServerError(error, orderNumber);
      throw error;
    });
  // TODO: Render error page when resources cannot be loaded successfully.
};

const mapStateToProps = createStructuredSelector({
  contactInfoUrl: getContactInfoUrl,
  shippingMethodUrl: getShippingMethodUrl,
  destination: getOrderDestination,
  selectedDeliveryOptions: getSelectedDeliveryOptions,
  isGiftCardEnabled,
  isLoyaltyEnabled,
  countries: getCountries,
  isPaymentRequired: getIsPaymentRequired,
  optinPrompts: getOptinPrompts,
  organizationSummary: getCheckoutOrganization,
  publicKey: getPublicKey,
  isAdyen3dsDialogOpen: getIsAdyen3dsDialogOpen,
  isInlineAuthorizationDialogOpen: getIsInlineAuthorizationDialogOpen,
  isPaymentInfoSummaryEnabled,
});

export default compose(
  withFetch(fetchAsyncState),
  withExternalEvents(),
  connect(mapStateToProps),
)(PaymentInfoStep);
