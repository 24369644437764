/* global RProfiler */
import isObject from 'lodash/isObject';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

export default function getRProfiler(fn) {
  try {
    if (isObject(RProfiler)) {
      fn(RProfiler);
    }
  } catch (error) {
    getRollbar((rollbar) => rollbar.warning('RProfiler is not defined'));
  }
}
