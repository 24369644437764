import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import uniqueId from 'lodash/uniqueId';
import type { ShopifyCartsPostChangeByIdResponse } from '@flowio/api-sdk';
import actionTypes from '../../constants/action-types';
import legacyErrorConverter from '../../../utilities/converters/legacy-error-converter';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { RemoveCartItemActionParameters } from '../types';
import type { RootAction, ThunkResult } from '../../types';
import type { LegacyError, LegacyResponse } from '../../../types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';

type Result = Promise<LegacyResponse<ShopifyCartsPostChangeByIdResponse>>;

export const removeCartItemRequest = (
  parameters: RemoveCartItemActionParameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.REMOVE_CART_ITEM_REQUEST,
  params: parameters,
  optimist: {
    type: BEGIN,
    transactionId,
  },
});

export const removeCartItemSuccess = (
  shopifyCart: io.flow.v0.models.ShopifyCart,
  parameters: RemoveCartItemActionParameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.REMOVE_CART_ITEM_SUCCESS,
  payload: shopifyCart,
  params: parameters,
  optimist: {
    type: COMMIT,
    transactionId,
  },
});

export const removeCartItemFailure = (
  error: io.flow.v0.models.GenericError | LegacyError,
  parameters: RemoveCartItemActionParameters,
  transactionId: string,
): RootAction => ({
  type: actionTypes.REMOVE_CART_ITEM_FAILURE,
  params: parameters,
  payload: error,
  optimist: {
    type: REVERT,
    transactionId,
  },
});

export const removeCartItem = (
  cartId: string,
  cartItemVariantId: number,
): ThunkResult<Result> => (dispatch, _getState, extra) => {
  const transactionId = uniqueId('transaction');

  const parameters: RemoveCartItemActionParameters = {
    cartId,
    cartItemVariantId,
  };

  dispatch(removeCartItemRequest(parameters, transactionId));
  return extra.api.shopifyCarts.postChangeById({
    id: cartId,
    body: {
      id: cartItemVariantId,
      quantity: 0,
    },
  }).then((response) => {
    switch (response.status) {
      case 200:
        dispatch(removeCartItemSuccess(response.body, parameters, transactionId));
        break;
      case 401:
      case 404:
        dispatch(removeCartItemFailure(
          legacyErrorConverter.fromStatusCode(response.status),
          parameters,
          transactionId,
        ));
        break;
      case 422:
        dispatch(removeCartItemFailure(response.body, parameters, transactionId));
        break;
      default:
        exhaustiveCheck(response);
    }
    return legacyResponseConverter.fromHttpResponse(response);
  });
};
