import PropTypes from 'prop-types';
import ContentStatus from '../../constants/content-status';
import PromptTarget from '../../constants/prompt-target';

const optinAttributePropTypes = {
  name: PropTypes.string.isRequired,
  optin_attribute_key: PropTypes.string.isRequired,
  target: PropTypes.oneOf([
    PromptTarget.BROWSE,
    PromptTarget.CHECKOUT,
  ]),
  status: PropTypes.oneOf([
    ContentStatus.ARCHIVED,
    ContentStatus.DRAFT,
    ContentStatus.LIVE,
  ]),
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
};

export default optinAttributePropTypes;
