import type { ShopifyOrderDetailsDeletePromotionAndDeleteByNumberResponse } from '@flowio/api-internal-sdk';
import actionTypes from '../../constants/action-types';
import exhaustiveCheck from '../../../utilities/exhaustive-check';
import legacyResponseConverter from '../../../utilities/converters/legacy-response-converter';
import type { LegacyError, LegacyResponseWithLegacyError } from '../../../types';
import type { RootAction, ThunkResult } from '../../types';

type Result = Promise<
LegacyResponseWithLegacyError<ShopifyOrderDetailsDeletePromotionAndDeleteByNumberResponse>
>;

const removeShopifyPromoCodeRequest = (): RootAction => ({
  type: actionTypes.REMOVE_SHOPIFY_PROMO_CODE_REQUEST,
});

const removeShopifyPromoCodeSuccess = (
  order: io.flow.experience.v0.models.Order,
): RootAction => ({
  type: actionTypes.REMOVE_SHOPIFY_PROMO_CODE_SUCCESS,
  payload: order,
});

const removeShopifyPromoCodeFailure = (
  error: LegacyError | io.flow.experience.v0.models.OrderError,
): RootAction => ({
  type: actionTypes.REMOVE_SHOPIFY_PROMO_CODE_FAILURE,
  payload: error,
});

const removeShopifyPromoCode = (
  organizationId: string,
  orderNumber: string,
): ThunkResult<Result> => (dispatch, getState, extra) => {
  dispatch(removeShopifyPromoCodeRequest());
  return extra.apiInternal.shopifyOrderDetails.deletePromotionAndDeleteByNumber({
    number: orderNumber,
    organization: organizationId,
    expand: ['experience'],
  })
    .then(legacyResponseConverter.withLegacyError)
    .then((response) => {
      switch (response.status) {
        case 200:
          dispatch(removeShopifyPromoCodeSuccess(response.result));
          break;
        case 401:
        case 404:
        case 422:
          dispatch(removeShopifyPromoCodeFailure(response.result));
          break;
        default:
          exhaustiveCheck(response);
      }

      return response;
    });
};

export default removeShopifyPromoCode;
