/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import ApiPropTypes from '@flowio/api-prop-types';

import PaymentForm from '../../containers/payment-form';
import NoPaymentForm from '../../containers/no-payment-form';
import Rewards from '../../containers/rewards';
import FlowProvider from '../flow-provider';
import PaymentStepInfoSummary from '../payment-step-info-summary';
import AdyenChallengeDialog from '../adyen-challenge-dialog';
import InlineAuthorizationDialog from '../inline-authorization-dialog';

const PaymentInfoStep = ({
  isPaymentRequired,
  isLoyaltyEnabled,
  isGiftCardEnabled,
  isPaymentInfoSummaryEnabled,
  organizationSummary,
  publicKey,
  selectedDeliveryOptions,
  destination,
  countries,
  contactInfoUrl,
  shippingMethodUrl,
  useCheckoutsSubmission,
  isAdyen3dsDialogOpen,
  isInlineAuthorizationDialogOpen,
  ...unhandledProps
}) => (isPaymentRequired ? (
  <>
    {isPaymentInfoSummaryEnabled && (
      <PaymentStepInfoSummary
        contactInfoUrl={contactInfoUrl}
        shippingMethodUrl={shippingMethodUrl}
        selectedDeliveryOptions={selectedDeliveryOptions}
        destination={destination}
        countries={countries}
      />
    )}
    <AdyenChallengeDialog isAdyen3dsDialogOpen={isAdyen3dsDialogOpen} />
    <InlineAuthorizationDialog
      isInlineAuthorizationDialogOpen={isInlineAuthorizationDialogOpen}
    />
    <Rewards
      isLoyaltyEnabled={isLoyaltyEnabled}
      isGiftCardEnabled={isGiftCardEnabled}
    />
    <FlowProvider
      organizationId={organizationSummary.id}
      publicKey={publicKey}
    >
      <PaymentForm
        useCheckoutsSubmission={useCheckoutsSubmission}
        organizationSummary={organizationSummary}
        shippingMethodUrl={shippingMethodUrl}
        {...unhandledProps}
      />
    </FlowProvider>
  </>
) : (
  <NoPaymentForm {...unhandledProps} />
));

PaymentInfoStep.displayName = 'PaymentInfoStep';

PaymentInfoStep.propTypes = {
  isPaymentInfoSummaryEnabled: PropTypes.bool.isRequired,
  contactInfoUrl: PropTypes.string.isRequired,
  shippingMethodUrl: PropTypes.string.isRequired,
  isPaymentRequired: PropTypes.bool.isRequired,
  isLoyaltyEnabled: PropTypes.bool.isRequired,
  isGiftCardEnabled: PropTypes.bool.isRequired,
  organizationSummary: ApiPropTypes.organizationSummary.isRequired,
  publicKey: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  selectedDeliveryOptions: PropTypes.arrayOf(ApiPropTypes.deliveryOption).isRequired,
  destination: ApiPropTypes.orderAddress.isRequired,
  useCheckoutsSubmission: PropTypes.bool,
  isAdyen3dsDialogOpen: PropTypes.bool.isRequired,
  isInlineAuthorizationDialogOpen: PropTypes.bool.isRequired,
};

PaymentInfoStep.defaultProps = {
  useCheckoutsSubmission: false,
};

export default PaymentInfoStep;
