import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';

import ReadyState from '../../../utilities/redux/ready-state';

// Returns the top-level property containing the state of the shopify cart.
export const getCartState = createSelector(
  identity,
  (state) => get(state, 'cart'),
);

// Returns the current state of the shopify cart.
export const getCart = createSelector(
  getCartState,
  (state) => get(state, 'entities.cart'),
);

// Returns a map containing errors corresponding to each entity of the cart module.
export const getErrors = createSelector(
  getCartState,
  (state) => get(state, 'errors'),
);

// Returns a map containing the CRUD status of each entity of the cart module.
export const getStatuses = createSelector(
  getCartState,
  (state) => get(state, 'statuses'),
);

// Returns a map containing the CRUD status of the cart entity.
export const getCartStatus = createSelector(
  getStatuses,
  (statuses) => get(statuses, 'cart'),
);

// Returns a map containing any errors associated with the last CRUD operation
// performed on the cart entity.
export const getCartError = createSelector(
  getErrors,
  (errors) => get(errors, 'cart'),
);

// Returns the unique identifier for the cart entity.
export const getCartId = createSelector(
  getCart,
  (cart) => get(cart, 'id'),
);

// Returns a list of all items corresponding to the cart entity.
export const getCartItems = createSelector(
  getCart,
  (cart) => get(cart, 'items'),
);

export const getCartItemByVariantId = (variantId) => (state) => {
  const items = getCartItems(state);
  return find(items, { variant_id: variantId });
};

// Returns whether the cart is currently empty.
export const getIsCartEmpty = createSelector(
  getCartItems,
  (items) => isEmpty(items),
);

// Returns whether some CRUD operation on the cart entity is currently in progress.
export const getIsCartLoading = createSelector(
  getCartStatus,
  (status) => get(status, 'state') === ReadyState.Loading,
);
